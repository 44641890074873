import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "white",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#2DB8E8",
  },
}))(LinearProgress);

const ProgressBar = (props) => {
  const { width, value } = props;
  return (
    <Box width={width}>
      <BorderLinearProgress
        variant={value === 50 ? "determinate" : "indeterminate"}
        value={value}
      />
    </Box>
  );
};

ProgressBar.defaultProps = {
  width: "100%",
  value: 100,
};

ProgressBar.propTypes = {
  width: PropTypes.string,
  value: PropTypes.number,
};

export default ProgressBar;
