import React from 'react'
import './style.css'

const Unauthorized = () => {
  return (
    <div id='unauth'>
      <div class='unauth'>
        <div class='unauth-403'>
          <h1>403</h1>
          <h2>Unauthorized</h2>
        </div>
        <a href='/'>Home</a>
      </div>
    </div>

  )
}

export default Unauthorized
