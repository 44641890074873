import React, { useCallback, useState } from "react";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import DashboardIcon from "../../../assets/img/favicon.png";
import { ReactComponent as ServiceIcon } from "../../../assets/icons/service-icon.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more-icon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout-icon-gray.svg";
import { userLogout } from "../../../store/actions/userActions";
import PageSubMenu from "../../PageSubMenu/PageSubMenu";
import { toggleSyncNewPairsConfirmationModal } from "../../../store/actions/integrationActions";

const MobileBottomTabs = (props) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const location = useLocation();
  const {
    hasNotSyncedPairs,
    history,
    userLogout,
    toggleSyncNewPairsConfirmationModal,
  } = props;

  const handleLogout = useCallback(async () => {
    localStorage.clear();
    userLogout();
    if (!localStorage?.getItem("token")) {
      history.push("/");
    }
  }, [history, userLogout]);

  const handleShowMoreMenu = useCallback(() => {
    setShowMoreMenu(!showMoreMenu);
  }, [showMoreMenu]);

  const handleIntegrationIconClicked = useCallback(() => {
    if (showMoreMenu) {
      handleShowMoreMenu();
    }
  }, [handleShowMoreMenu, showMoreMenu]);

  const onClickedTab = useCallback(
    (tab) => () => {
      if (location.pathname === "/sync" && hasNotSyncedPairs) {
        toggleSyncNewPairsConfirmationModal(true, tab);
      } else {
        history.push(tab);
      }
      handleShowMoreMenu();
    },
    [
      hasNotSyncedPairs,
      history,
      location,
      toggleSyncNewPairsConfirmationModal,
      handleShowMoreMenu,
    ]
  );

  return (
    <>
      {showMoreMenu && (
        <PageSubMenu
          handleShowMoreMenu={handleShowMoreMenu}
          isMobileDevice={true}
          hasNotSyncedPairs={hasNotSyncedPairs}
        />
      )}
      <div className="mobile-menu">
        <MenuItem
          onClick={onClickedTab("/dashboard")}
          disableRipple
          classes={{
            root: "single",
          }}
        >
          <img width={34} src={DashboardIcon} alt="icon" />
          <span className="main-label">Dashboard</span>
        </MenuItem>

        <MenuItem
          disableRipple
          component={NavLink}
          to="/sync"
          classes={{
            root: "single",
          }}
          onClick={handleIntegrationIconClicked}
        >
          <ServiceIcon />
          <span className="main-label">Sync</span>
        </MenuItem>
        <MenuItem
          disableRipple
          classes={{
            root: `single ${showMoreMenu ? "active" : ""}`,
          }}
          onClick={handleShowMoreMenu}
        >
          <MoreIcon />
          <span className="main-label">More</span>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={handleLogout}
          classes={{
            root: "single",
          }}
        >
          <LogoutIcon />
          <span className="main-label">Logout</span>
        </MenuItem>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
    toggleSyncNewPairsConfirmationModal: (val, skipRoute) =>
      dispatch(toggleSyncNewPairsConfirmationModal(val, skipRoute)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(MobileBottomTabs));
