import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import MaterialButton from "../../ MaterialButton/MaterialButton";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const ConfirmationModal = (props) => {
  const {
    open,
    handleClose,
    modalHeading,
    modalContent,
    btnLabel,
    onClick,
    loading,
    btnClassName,
    showCancelBtn,
    cancelBtnLabel,
    handleDonotShowConfirmationModal,
    doNotShowModal,
    preliminaryActionLabel,
    hasPreliminaryAction,
    disabled,
  } = props;
  const breakpoint = useBreakpoint();
  const [enableSubmit, setEnableSubmit] = useState(false);

  const checkEnableSubmit = useCallback((event) => {
    setEnableSubmit(event.target.checked);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0 vw-100" : "",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">{modalHeading}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{modalContent}</DialogContentText>
        {handleDonotShowConfirmationModal && (
          <div className="flexer-start">
            <Checkbox
              checked={doNotShowModal}
              onChange={handleDonotShowConfirmationModal}
              style={{ color: "#2db8e8" }}
            />
            <DialogContentText className="mt-2">
              Do not show this confirmation message again for this session
            </DialogContentText>
          </div>
        )}
        {hasPreliminaryAction && (
          <div className="flexer-start">
            <Checkbox
              checked={enableSubmit}
              onChange={checkEnableSubmit}
              style={{ color: "#2db8e8" }}
            />
            <DialogContentText className="mt-2">
              {preliminaryActionLabel}
            </DialogContentText>
          </div>
        )}
      </DialogContent>
      <DialogActions className="flexer-column">
        <div
          className={`d-flex ${
            showCancelBtn ? "justify-content-between" : "justify-content-end"
          } w-100`}
        >
          {showCancelBtn && (
            <MaterialButton
              title={cancelBtnLabel}
              className="w-30 ml-3 dark-gray-button"
              onClick={handleClose}
            />
          )}
          <ButtonWithSpinner
            className={btnClassName}
            onClick={onClick}
            loading={loading}
            disabled={hasPreliminaryAction ? !enableSubmit : disabled}
          >
            {btnLabel}
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.defaultProps = {
  open: false,
  loading: false,
  showCancelBtn: true,
  modalHeading: "",
  modalContent: "",
  btnLabel: "",
  btnClassName: "",
  cancelBtnLabel: "Cancel",
  doNotShowModal: false,
  preliminaryActionLabel: "",
  disabled: false,
  hasPreliminaryAction: false,
};

ConfirmationModal.propType = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  modalHeading: PropTypes.string,
  modalContent: PropTypes.string,
  btnLabel: PropTypes.string,
  loading: PropTypes.bool,
  showCancelBtn: PropTypes.bool,
  btnClassName: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  handleDonotShowConfirmationModal: PropTypes.func,
  doNotShowModal: PropTypes.bool,
  preliminaryActionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  hasPreliminaryAction: PropTypes.bool,
};

export default ConfirmationModal;
