import { convertFormdataToJson } from "../../helpers";
import { ACTION_TYPES } from "../actions/types";

const initialState = {
  // Logs
  logs: [],
  logsStatus: "",
  logsPage: null,
  logsLoadedAll: null,
  logsLoadingMoreStatus: null,

  // LOG DETAILS
  logDetails: {},
  logDetailsStatus: null,
  ccbToMc: [],
  mcToCcb: [],
  accountChanges: [],
  userCcbSync: {},
  cleanupSync: {},

  // LOG STATISTICS
  logStatisticsStatus: null,
  logStatistics: {},

  //LOG SEARCH STEP ONE
  searchTerm: null,
  searchLogsStatus: null,
  searchData: {},

  // LOG SEARCH STEP TWO
  logSearchStepTwoStatus: null,
  logSearchStepTwoData: [],

  // REVERT LOG
  revertLogStatus: null,
  isLogReverting: true,

  // Logs mc required merge fields
  viewMcRequiredMergeFieldStatus: null,
  mcRequiredMergeFields: [],
};

const logsReducer = (state = initialState, action) => {
  let logs = null;
  switch (action.type) {
    case ACTION_TYPES.GET_LOGS: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        logsStatus: "loading",
        logsLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      };
    }

    case ACTION_TYPES.GET_LOGS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.logs) {
        logs = action.payload.data;
      } else {
        logs = state.logs.concat(action.payload.data);
      }
      return {
        ...state,
        logsStatus: "success",
        logs: logs,
        logsPage: formData.page,
        logsLoadingMoreStatus: formData.page > 1 ? "success" : null,
        logsLoadedAll: action.payload.data.length === 0,
      };
    }

    case ACTION_TYPES.GET_LOGS_ERROR: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        logsStatus: "error",
        logsLoadedAll: null,
        logsPage: null,
        logsLoadingMoreStatus: formData.page > 1 ? "error" : null,
      };
    }

    case ACTION_TYPES.SET_LOGS_DETAILS: {
      return {
        ...state,
        logDetails: action.payload.log,
        cleanupSync: action.payload?.cleanupSync,
      };
    }

    case ACTION_TYPES.GET_LOGS_DETAILS: {
      return {
        ...state,
        logDetailsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_LOGS_DETAILS_SUCCESS: {
      return {
        ...state,
        logDetailsStatus: "success",
        ...action.payload.data,
      };
    }

    case ACTION_TYPES.GET_LOGS_DETAILS_ERROR: {
      return {
        ...state,
        logDetailsStatus: "error",
      };
    }

    case ACTION_TYPES.GET_LOG_STATISTICS: {
      return {
        ...state,
        logStatisticsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_LOG_STATISTICS_SUCCESS: {
      return {
        ...state,
        logStatisticsStatus: "success",
        logStatistics: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_LOG_STATISTICS_ERROR: {
      return {
        ...state,
        logStatisticsStatus: "error",
      };
    }

    case ACTION_TYPES.SET_LOG_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }

    case ACTION_TYPES.SEARCH_LOGS: {
      return {
        ...state,
        searchLogsStatus: "loading",
      };
    }

    case ACTION_TYPES.SEARCH_LOGS_SUCCESS: {
      return {
        ...state,
        searchLogsStatus: "success",
        searchData: action.payload.data,
      };
    }

    case ACTION_TYPES.SEARCH_LOGS_ERROR: {
      return {
        ...state,
        searchLogsStatus: "error",
      };
    }

    case ACTION_TYPES.SEARCH_STEP_TWO: {
      return {
        ...state,
        logSearchStepTwoStatus: "loading",
      };
    }

    case ACTION_TYPES.SEARCH_STEP_TWO_SUCCESS: {
      return {
        ...state,
        logSearchStepTwoStatus: "success",
        logSearchStepTwoData: action.payload.data,
      };
    }

    case ACTION_TYPES.SEARCH_STEP_TWO_ERROR: {
      return {
        ...state,
        logSearchStepTwoStatus: "error",
      };
    }

    case ACTION_TYPES.REVERT_LOGS: {
      return {
        ...state,
        revertLogStatus: "loading",
      };
    }

    case ACTION_TYPES.REVERT_LOGS_SUCCESS: {
      return {
        ...state,
        revertLogStatus: "success",
      };
    }

    case ACTION_TYPES.REVERT_LOGS_ERROR: {
      return {
        ...state,
        revertLogStatus: "error",
      };
    }

    case ACTION_TYPES.GET_MC_REQUIRED_MERGE_FIELDS: {
      return {
        ...state,
        viewMcRequiredMergeFieldStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_MC_REQUIRED_MERGE_FIELDS_SUCCESS: {
      return {
        ...state,
        viewMcRequiredMergeFieldStatus: "success",
        mcRequiredMergeFields: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_MC_REQUIRED_MERGE_FIELDS_ERROR: {
      return {
        ...state,
        viewMcRequiredMergeFieldStatus: "error",
      };
    }

    case ACTION_TYPES.CHECK_SYNC_SUCCESS: {
      return {
        ...state,
        isLogReverting: [0, 2].includes(action.payload.status) ? true : false,
      };
    }

    case ACTION_TYPES.REVERT_LOGS_CLEAR: {
      return {
        ...state,
        isLogReverting: true,
        revertLogStatus: null,
      };
    }

    default:
      return state;
  }
};

export default logsReducer;
