import React, { useEffect, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import { withRouter } from "react-router";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  Box,
  Tabs,
  Tab,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import TabPanel from "../../TabPanel/TabPanel";
import { Close } from "@material-ui/icons";
import {
  filterRequiredMergeFieldsError,
  formatDateAndTime,
  formDataWithApiKey,
} from "../../../helpers";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import LogDetailsTableBody from "./LogDetailsTableBody";
import Loader from "../../Loader/Loader";
import { getLogDetails } from "../../../store/middlewares/logsMiddleware";
import LogBreadcrumb from "../LogBreadcrumb/LogBreadcrumb";
import ViewMcRequiredMergeFieldModal from "../../modals/ViewMcRequiredMergeFieldModal/ViewMcRequiredMergeFieldModal";
import MemberShipFiltersModal from "../../modals/MemberShipFitlersModal/MemberShipFiltersModal";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";

const cells = [
  {
    text: "Name",
  },
  {
    text: "Added",
  },
  {
    text: "Updated",
  },
  {
    text: "Duplicates",
  },
  {
    text: "Removed",
  },
  {
    text: "No Email/ unsubs",
  },
  {
    text: "Errors",
  },
];

const mcTableCells = [
  {
    text: "Name",
  },
  {
    text: "Added",
  },
  {
    text: "Removed",
  },
  {
    text: "Skipped",
  },
  {
    text: "Conflict",
  },
];

const accountChangesCells = [
  {
    text: "S.no",
  },
  {
    text: "Description",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RenderTable = ({
  data,
  tableCells,
  tableType,
  onClickedViewMcRequiredMergeField,
  handleLogStatistics,
}) => {
  return (
    <TableInfinityScroll
      className="logs-modal-table"
      loadMore={() => {}}
      fetchFunction={() => {}}
    >
      <table className="table">
        <TableHeader
          className="user-settings-greetings-table-header"
          cells={tableCells}
        />
        <LogDetailsTableBody
          data={data}
          handleItemClicked={handleLogStatistics}
          tableType={tableType}
          onClickedViewMcRequiredMergeField={onClickedViewMcRequiredMergeField}
        />
      </table>
    </TableInfinityScroll>
  );
};

const LogDetails = (props) => {
  const {
    // Redux props
    logDetails,
    ccbToMc,
    logDetailsStatus,
    mcToCcb,
    accountChanges,

    // Redux functions
    getLogDetails,

    // component props
    history,
    match,
  } = props;

  const [activeTab, setActiveTab] = useState(null);
  const [showLogsFilterMembershipModal, setShowLogsFilterMembershipModal] =
    useState(false);

  const [viewMcRequiredMergeFieldModal, setViewMcRequiredMergeFieldModal] =
    useState(false);
  const [mcIdForRequiredMergeField, setMcIdForRequiredMergeField] =
    useState("");
  const [showReqMergeFieldError, setShowReqMergeFieldError] = useState(false);

  const {
    location: { state },
  } = history;

  const getBreadCrumb = useMemo(() => {
    const breadcrumbs = [
      {
        text: `${logDetails.type} - ${formatDateAndTime(logDetails.date)}`,
        route: `/logs/details/${match.params.logId}`,
      },
    ];
    return breadcrumbs;
  }, [logDetails, match]);

  const toggleModal = useCallback(() => {
    if (state?.isBreadcrumbEnable) {
      history.goBack();
    } else {
      history.push("/logs");
    }
  }, [history, state?.isBreadcrumbEnable]);

  const toggleMcRequiredMergeFieldModal = useCallback(() => {
    setViewMcRequiredMergeFieldModal(!viewMcRequiredMergeFieldModal);
  }, [viewMcRequiredMergeFieldModal]);

  useEffect(() => {
    if (match?.params?.logId) {
      const formData = formDataWithApiKey();
      formData.append("id", match.params.logId);
      getLogDetails(formData, null);
    }
  }, [getLogDetails, match.params.logId]);

  useEffect(() => {
    if (ccbToMc.length) {
      setActiveTab(0);
    } else if (mcToCcb.length) {
      setActiveTab(1);
    }
  }, [ccbToMc.length, mcToCcb.length]);

  const handleLogStatistics = useCallback(
    (logStatistics) => {
      const isBreadcrumbEnable =
        !lodash.isEmpty(state) && state.isBreadcrumbEnable ? true : false;
      const breadcrumbsFromRoute =
        !lodash.isEmpty(state) && !lodash.isEmpty(state.breadcrumbsFromRoute)
          ? [...state.breadcrumbsFromRoute, { text: `${logStatistics?.name}` }]
          : [];
      history.push(
        `${history.location.pathname}/statistics/${logStatistics.detailId}`,
        { logDetails: logStatistics, isBreadcrumbEnable, breadcrumbsFromRoute }
      );
    },
    [history, state]
  );

  const onClickedViewMcRequiredMergeField = useCallback(
    (data) => () => {
      toggleMcRequiredMergeFieldModal();
      setMcIdForRequiredMergeField(data?.mailchimpListId);
    },
    [toggleMcRequiredMergeFieldModal]
  );

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const handleFilterRequiredMergeFieldError = useCallback(() => {
    setShowReqMergeFieldError(!showReqMergeFieldError);
  }, [showReqMergeFieldError]);

  const onClickedFiltered = () => {
    setShowLogsFilterMembershipModal(true);
  };

  const closeFitlerMembershipModal = useCallback(() => {
    setShowLogsFilterMembershipModal(null);
  }, []);

  const hasMergeFieldError = useMemo(() => {
    const filteredMergeFieldError = filterRequiredMergeFieldsError(ccbToMc);
    return !lodash.isEmpty(filteredMergeFieldError) && activeTab === 0;
  }, [ccbToMc, activeTab]);

  const renderCcbToMcTable = useMemo(() => {
    const tableData = showReqMergeFieldError
      ? filterRequiredMergeFieldsError(ccbToMc)
      : ccbToMc;
    if (!lodash.isEmpty(tableData)) {
      return (
        <div className="flexer-column mt-4">
          <div className="user-settings-greetings">
            <RenderTable
              data={tableData}
              tableCells={cells}
              tableType={"ccbToMc"}
              onClickedViewMcRequiredMergeField={
                onClickedViewMcRequiredMergeField
              }
              handleLogStatistics={handleLogStatistics}
            />
          </div>
        </div>
      );
    }
  }, [
    ccbToMc,
    handleLogStatistics,
    onClickedViewMcRequiredMergeField,
    showReqMergeFieldError,
  ]);

  const renderMcToCcbTable = useMemo(() => {
    if (!lodash.isEmpty(mcToCcb)) {
      return (
        <div className="flexer-column mt-4">
          <div className="user-settings-greetings">
            <RenderTable
              data={mcToCcb}
              tableCells={mcTableCells}
              tableType={"mcToCcb"}
              onClickedViewMcRequiredMergeField={
                onClickedViewMcRequiredMergeField
              }
              handleLogStatistics={handleLogStatistics}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flexer-column mt-4">
          <Typography variant="h5" className="flexer text-muted">
            Was not enabled.
          </Typography>
        </div>
      );
    }
  }, [mcToCcb, handleLogStatistics, onClickedViewMcRequiredMergeField]);

  const renderAccountChanges = useMemo(() => {
    if (!lodash.isEmpty(accountChanges)) {
      return (
        <div className="flexer-column">
          <div className="user-settings-greetings mt-2">
            <RenderTable
              data={accountChanges}
              tableCells={accountChangesCells}
              tableType={"accountChanges"}
              onClickedViewMcRequiredMergeField={
                onClickedViewMcRequiredMergeField
              }
              handleLogStatistics={handleLogStatistics}
            />
          </div>
        </div>
      );
    }
  }, [accountChanges, handleLogStatistics, onClickedViewMcRequiredMergeField]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Log Details</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <LogBreadcrumb
              breadcrumbs={
                state?.isBreadcrumbEnable
                  ? state?.breadcrumbsFromRoute
                  : getBreadCrumb
              }
              isBreadcrumbEnable={state?.isBreadcrumbEnable}
              breadcrumbsFromRoute={
                state &&
                !lodash.isEmpty(state?.breadcrumbsFromRoute) &&
                Array.isArray(state?.breadcrumbsFromRoute)
                  ? state?.breadcrumbsFromRoute
                  : []
              }
            />
            {hasMergeFieldError && (
              <div className="error-banner">
                <Checkbox
                  checked={showReqMergeFieldError}
                  onChange={handleFilterRequiredMergeFieldError}
                  style={{ color: "rgb(97, 26, 21)" }}
                  className="mb-1"
                  size="small"
                />
                Show Mailchimp Audience required merge fields error
              </div>
            )}

            {Boolean(logDetails?.hasFilteringData) && (
              <div className="group-membership-types-container">
                The group membership types{" "}
                <span onClick={onClickedFiltered} className="filtered-text">
                  Filtered
                </span>
              </div>
            )}
          </div>

          {(Boolean(ccbToMc.length) || Boolean(mcToCcb.length)) && (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="standard"
                aria-label="secondary tabs example"
              >
                <Tooltip
                  title={`${ccbToMc.length ? "" : "Was not enabled."}`}
                  placement="top"
                  arrow={true}
                >
                  <Tab
                    value={0}
                    label="CCB TO MC"
                    style={{ fontSize: 14, fontWeight: 600 }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${mcToCcb.length ? "" : "Was not enabled."}`}
                  placement="top"
                  arrow={true}
                >
                  <Tab
                    value={1}
                    label={`MC TO CCB`}
                    style={{ fontSize: 14, fontWeight: 600 }}
                  />
                </Tooltip>
              </Tabs>
            </Box>
          )}
          <TabPanel value={activeTab} index={0}>
            {renderCcbToMcTable}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            {renderMcToCcbTable}
          </TabPanel>

          {renderAccountChanges}

          {Boolean(!ccbToMc.length) &&
            Boolean(!mcToCcb.length) &&
            Boolean(!accountChanges.length) && (
              <Typography variant="h5" className="flexer mt-5">
                No data found
              </Typography>
            )}
        </DialogContent>
      </Dialog>
      {logDetailsStatus === "loading" && <Loader />}
      {viewMcRequiredMergeFieldModal && (
        <ViewMcRequiredMergeFieldModal
          showModal={viewMcRequiredMergeFieldModal}
          toggleModal={toggleMcRequiredMergeFieldModal}
          mcIdForRequiredMergeField={mcIdForRequiredMergeField}
        />
      )}
      {showLogsFilterMembershipModal && (
        <MemberShipFiltersModal
          open={showLogsFilterMembershipModal}
          handleClose={closeFitlerMembershipModal}
          data={logDetails}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    logDetailsStatus: store.logs.logDetailsStatus,
    ccbToMc: store.logs.ccbToMc,
    mcToCcb: store.logs.mcToCcb,
    accountChanges: store.logs.accountChanges,
    logDetails: store.logs.logDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogDetails: (data, requestId) =>
      dispatch(getLogDetails(data, requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogDetails));
