import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Sync } from "@material-ui/icons";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { MenuItem, Tooltip, Typography } from "@material-ui/core";
import DashboardIcon from "../../assets/img/favicon.png";
import MainTabSettingDropRight from "../MainTabSettingDroprRight/MainTabSettingDropRight";
import MobileBottomTabs from "../MobileDevicesComponents/MobileBottomTabs/MobileBottomTabs";
import { ReactComponent as LogsIcon } from "../../assets/icons/logs-yellow-icon.svg";
import { toggleSyncNewPairsConfirmationModal } from "../../store/actions/integrationActions";
import { ReactComponent as ToolsIcon } from "../../assets/icons/tools-yellow-icon.svg";
import { ReactComponent as SearchLogYellowIcon } from "../../assets/icons/search-log-yellow-icon.svg";
import { ReactComponent as IntegrationsIcon } from "../../assets/icons/integration-yellow-icon.svg";
import SearchLogsModal from "../modals/SearchLogsModal/SearchLogsModal";
import ExpandAndCollapseButton from "../ExpandAndCollapseButton/ExpandAndCollapseButton";
import RenderSidebarExpandedElement from "../../HelperComponents/RenderSidebarExpandedElement";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import services from "../../services";
import "../../styles/components/main-menu.scss";
import "../../styles/components/mobile-menu.scss";
import useGlobalStore from "../../store/reduxStore/globalStore";
import { stringToBoolean } from "../../helpers";

const {
  storageManager: { local },
} = services;

const Sidebar = (props) => {
  const {
    userData,
    history,
    userApiDetails,

    // Redux props
    hasNotSyncedPairs,

    // Redux functions
    toggleSyncNewPairsConfirmationModal,
  } = props;
  const [showSearchLogModal, setShowSearchLogModal] = useState(false);

  // Redux store
  const { toggleSidebarExpanded } = useGlobalStore();

  const sidebarExpandedCondition = useIsSidebarExpanded();

  const location = useLocation();

  useEffect(() => {
    // Set the sidebar expanded condition in local storage if not exisit in localstorage
    if (local.get("isSidebarExpanded") == null) {
      local.set("isSidebarExpanded", sidebarExpandedCondition);
    }
  }, [sidebarExpandedCondition]);

  useEffect(() => {
    if (local.get("isSidebarExpanded") != null) {
      toggleSidebarExpanded(stringToBoolean(local.get("isSidebarExpanded")));
    }
  }, [toggleSidebarExpanded]);

  const getTooltipTitle = useCallback(() => {
    if (!userApiDetails.ccbUsername || !userApiDetails.mailchimpApiKey) {
      return `Please setup CCB and MC Credentials before continuing.`;
    } else {
      return "Sync";
    }
  }, [userApiDetails]);

  const onClickedDashboardIcon = useCallback(() => {
    if (location.pathname === "/sync" && hasNotSyncedPairs) {
      toggleSyncNewPairsConfirmationModal(true, "/dashboard");
    } else {
      history.push(
        `/dashboard${
          Number(userData?.isActivated) === 0 ? "?non-activate-account" : ""
        }`
      );
    }
  }, [
    history,
    location,
    toggleSyncNewPairsConfirmationModal,
    hasNotSyncedPairs,
    userData,
  ]);

  const toggleSearchLogModal = useCallback(() => {
    setShowSearchLogModal(!showSearchLogModal);
  }, [showSearchLogModal]);

  return (
    <>
      <div
        className={clsx("impersonate main-menu", {
          "hover-main-menu": sidebarExpandedCondition,
        })}
      >
        <div
          id="sidebar"
          classNam={clsx({
            "sidebar-animation": sidebarExpandedCondition,
          })}
        >
          {/* Search Log Menu */}
          <Tooltip
            title="Search Logs"
            placement={window.innerWidth > 959 ? "right" : "top"}
            className="mt-3"
          >
            <MenuItem
              className={clsx(
                "d-flex align-items-center justify-content-start",
                {
                  "expanded-menu-item-container": sidebarExpandedCondition,
                  "active-menu": showSearchLogModal,
                  "collapse-menu-container": !sidebarExpandedCondition,
                }
              )}
              disableRipple
              onClick={toggleSearchLogModal}
            >
              <SearchLogYellowIcon height={24} width={24} />{" "}
              <RenderSidebarExpandedElement>
                <Typography className="ml-2 menu-text" variant="subtitle1">
                  Search Logs
                </Typography>
              </RenderSidebarExpandedElement>
            </MenuItem>
          </Tooltip>

          {/* Dashboard Menu */}
          <Tooltip
            title="Dashboard"
            placement={window.innerWidth > 959 ? "right" : "top"}
            className="mt-3"
          >
            <MenuItem
              className={clsx(
                "d-flex align-items-center justify-content-start",
                {
                  "active-menu": location.pathname === "/dashboard",
                  "collapse-menu-container": !sidebarExpandedCondition,
                  "expanded-menu-item-container": sidebarExpandedCondition,
                }
              )}
              disableRipple
              onClick={onClickedDashboardIcon}
            >
              <img src={DashboardIcon} alt="icon" height={22} />{" "}
              <RenderSidebarExpandedElement>
                <Typography className="ml-2 menu-text" variant="subtitle1">
                  Dashboard
                </Typography>
              </RenderSidebarExpandedElement>
            </MenuItem>
          </Tooltip>

          {/* Sunc Menu */}
          {Boolean(userData?.isActivated) && (
            <Tooltip
              title={getTooltipTitle()}
              placement={window.innerWidth > 959 ? "right" : "top"}
              className="mt-3"
            >
              <MenuItem
                disableRipple
                component={NavLink}
                to="/sync"
                className={clsx(
                  "d-flex align-items-center justify-content-start",
                  {
                    "active-menu": location.pathname === "/sync",
                    "collapse-menu-container": !sidebarExpandedCondition,
                    "expanded-menu-item-container": sidebarExpandedCondition,
                  }
                )}
                classes={{
                  root: "single",
                }}
              >
                <Sync htmlColor="#ffd110" />
                <RenderSidebarExpandedElement>
                  <Typography className="ml-2 menu-text" variant="subtitle1">
                    Sync
                  </Typography>
                </RenderSidebarExpandedElement>
              </MenuItem>
            </Tooltip>
          )}

          {/* Integration menu */}
          <Tooltip
            title="Integration"
            placement={window.innerWidth > 959 ? "right" : "top"}
            className="mt-3"
          >
            <MenuItem
              disableRipple
              component={NavLink}
              to="/integrations"
              className={clsx(
                "d-flex align-items-center justify-content-start",
                {
                  "active-menu": location.pathname === "/integrations",
                  "collapse-menu-container": !sidebarExpandedCondition,
                  "expanded-menu-item-container": sidebarExpandedCondition,
                }
              )}
              classes={{
                root: "single",
              }}
            >
              <IntegrationsIcon height={18} width={18} />{" "}
              <RenderSidebarExpandedElement>
                <Typography className="ml-2 menu-text" variant="subtitle1">
                  Integration
                </Typography>
              </RenderSidebarExpandedElement>
            </MenuItem>
          </Tooltip>

          {/* Tools menu */}
          <Tooltip
            title="Tools / Settings"
            placement={window.innerWidth > 959 ? "right" : "top"}
            className="mt-3"
          >
            <MenuItem
              disableRipple
              component={NavLink}
              to="/tools"
              className={clsx(
                "d-flex align-items-center justify-content-start",
                {
                  "active-menu": location.pathname === "/tools",
                  "collapse-menu-container": !sidebarExpandedCondition,
                  "expanded-menu-item-container": sidebarExpandedCondition,
                }
              )}
              classes={{
                root: "single",
              }}
            >
              <ToolsIcon height={25} width={25} />{" "}
              <RenderSidebarExpandedElement>
                <Typography className="ml-2 menu-text" variant="subtitle1">
                  Tools / Settings
                </Typography>
              </RenderSidebarExpandedElement>
            </MenuItem>
          </Tooltip>

          {/* Logs menu */}
          <Tooltip
            title="Logs"
            placement={window.innerWidth > 959 ? "right" : "top"}
            className="mt-3"
          >
            <MenuItem
              disableRipple
              component={NavLink}
              to="/logs"
              className={clsx(
                "d-flex align-items-center justify-content-start",
                {
                  "active-menu": location.pathname === "/logs",
                  "collapse-menu-container": !sidebarExpandedCondition,
                  "expanded-menu-item-container": sidebarExpandedCondition,
                }
              )}
              classes={{
                root: "single",
              }}
            >
              <LogsIcon className="mr-1" height={18} width={18} />{" "}
              <RenderSidebarExpandedElement>
                <Typography className="ml-2 menu-text" variant="subtitle1">
                  Logs
                </Typography>
              </RenderSidebarExpandedElement>
            </MenuItem>
          </Tooltip>

          <div className="bottom-icons w-100">
            <MainTabSettingDropRight
              userData={userData}
              isSidebarExpanded={sidebarExpandedCondition}
              hasNotSyncedPairs={hasNotSyncedPairs}
            />
            <div
              className={clsx(
                "w-100 d-flex align-items-center justify-content-end mt-4 ",
                {
                  "mr-5": sidebarExpandedCondition,
                }
              )}
            >
              <ExpandAndCollapseButton />
            </div>
          </div>
        </div>
      </div>
      {["/dashboard", "/sync"].includes(history.location?.pathname) && (
        <MobileBottomTabs hasNotSyncedPairs={hasNotSyncedPairs} />
      )}

      {showSearchLogModal && (
        <SearchLogsModal
          open={showSearchLogModal}
          handleClose={toggleSearchLogModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    userApiDetails: store.userReducer.userApiDetails,
    hasNotSyncedPairs: store.integrations.hasNotSyncedPairs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSyncNewPairsConfirmationModal: (val, skipRoute) =>
      dispatch(toggleSyncNewPairsConfirmationModal(val, skipRoute)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
