import React, { useState, useCallback, useMemo } from "react";
import { Help } from "@material-ui/icons";
import FilterGroupMembershipList from "./FilterGroupMembershipList";
import useUserStore from "../../../store/reduxStore/userStore";
import LightTooltip from "../../../HelperComponents/LightTooltip";
import { MEMBERSHIP_STATUS_TYPES_URL } from "../../../utils/constants";

const memberSettings = [
  "isIncludeJoinGroup",
  "isIncludeLeads",
  "isIncludeMember",
  "isIncludeInvitedToGroup",
];

const FilteredGroupMembershipType = (props) => {
  const { className } = props;
  const [showFilteredMembersipListModal, setShowFilteredMembersipListModal] =
    useState(false);

  // Redux store
  const { user } = useUserStore();
  const { userData } = user;

  const toggleFilteredMembershipModal = useCallback(() => {
    setShowFilteredMembersipListModal(!showFilteredMembersipListModal);
  }, [showFilteredMembersipListModal]);

  const isAllMembershipsSelected = useMemo(() => {
    return memberSettings.every(
      (element) => userData[element] && Number(userData[element]) === 1
    );
  }, [userData]);

  const renderTooltipContent = useMemo(() => {
    return (
      <div>
        The status is the current relationship the person has to the group. The
        "Requesting to join the group" participants are not yet members (because
        they have either not yet accepted the invitation to join the group or
        were not accepted into the group). We will sync the participants for all
        group based on the statuses choosen. The default and typical options are
        to sync just leader/assistant leaders and group members. Click on{" "}
        <b>{isAllMembershipsSelected ? "All Selected" : "Filtered"}</b> to make
        any changes.{" "}
        <a href={MEMBERSHIP_STATUS_TYPES_URL} target="__blank">
          <u>Click here for more info</u>
        </a>
      </div>
    );
  }, [isAllMembershipsSelected]);

  return (
    <>
      <div className={className}>
        The group membership types{" "}
        <span className="d-flex align-items-center justify-content-center ml-2">
          <span
            className="filtered-text"
            onClick={toggleFilteredMembershipModal}
          >
            {isAllMembershipsSelected ? "All Selected" : "Filtered"}
          </span>{" "}
          <LightTooltip
            arrow={true}
            interactive={true}
            placement="top"
            title={renderTooltipContent}
          >
            <Help fontSize="small" htmlColor="#22c1c3" className="ml-1 mb-1" />
          </LightTooltip>
        </span>
      </div>
      {showFilteredMembersipListModal && (
        <FilterGroupMembershipList
          open={showFilteredMembersipListModal}
          handleClose={toggleFilteredMembershipModal}
        />
      )}
    </>
  );
};

export default FilteredGroupMembershipType;
