import React from "react";
import { Snackbar as MaterialUiSnackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import useGlobalStore from "../../store/reduxStore/globalStore";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = () => {
  // Redux store
  const { global, setSnackbarData } = useGlobalStore();
  const {
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    action,
    autoHideDuration,
  } = global;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarData({
      snackbarSeverity: "",
      showSnackbar: false,
      snackbarMessage: "",
    });
  };

  return (
    <MaterialUiSnackbar
      open={showSnackbar}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      action={action}
    >
      <Alert action={action} onClose={handleClose} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </MaterialUiSnackbar>
  );
};

export default Snackbar;
