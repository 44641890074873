import { PROCESS_QUEUE_WARNING, SEARCH_WARNING } from "./integrationsConstants";
import ReactHtmlParser from "react-html-parser";

export const notificationProps = {
  linkToV1: {
    withLoading: false,
    className: "w-100 mb-3",
    message: `You are currently using V2. `,
    linkText: "Click here",
    afterLinkText: "to switch back to V1.",
    hasOldVersionLink: true,
  },
  paymentFailed: {
    withLoading: false,
    className: "w-100 mb-2",
    message: `You have an overdue invoice. Your plan will expire soon.`,
    severity: "error",
    hasInfoIcon: true,
    linkTextClassName: "link-text-2",
  },
  paymentFailedSecondMessage: {
    withLoading: false,
    className: "w-100 mb-2",
    message: `Don’t delay - pay your overdue plan now or risk cancellation!`,
    severity: "error",
    hasInfoIcon: true,
  },
  subscriptinCancelled: {
    withLoading: false,
    className: "w-100 mb-2 mt-2",
    severity: "warning",
    linkText: "here",
    link: "/subscription-plans",
  },
  searchWarning: {
    withLoading: false,
    className: "w-100 ",
    message: ReactHtmlParser(SEARCH_WARNING),
    linkText: "Click Here to Recheck",
    severity: "info",
  },
  processQueueWarning: {
    withLoading: false,
    className: "w-100 ",
    message: ReactHtmlParser(PROCESS_QUEUE_WARNING),
    linkText: "Click Here to Recheck",
    severity: "info",
  },
  unresolveContacts: {
    withLoading: false,
    className: "w-100 mb-2",
    innerClassName: "flexer",
    linkText: "View and fix them now ",
    severity: "info",
  },
  hasCcbError: {
    withLoading: false,
    className: "w-100 mb-3",
    innerClassName: "flexer",
    linkText: "View and fix them now ",
    severity: "error",
    message: "Invalid CCB Credentials",
  },
  hasMcError: {
    withLoading: false,
    className: "w-100 mb-3",
    innerClassName: "flexer",
    linkText: "View and fix them now ",
    severity: "error",
    message: "Invalid Mailchimp API Key",
  },
  couponMessage: {
    withLoading: false,
    className: "w-100 mb-2",
    severity: "info",
  },

  downgradingPlanMessage: {
    withLoading: false,
    className: "w-100 mt-2 mb-2",
    severity: "warning",
    message:
      "You will loose access to the synced custom fields like birthday, family positions, etc if you downgrade to this plan",
  },
  resetPasswordLinkExpired: {
    withLoading: false,
    className: "w-100 mt-2 mb-2",
    severity: "warning",
    message:
      "Your password reset link has expired. Please enter your email address below to receive a new link.",
  },
  prePopulateCcbToMcPairs: {
    withLoading: false,
    className: "w-100 ",
    linkText: "Click here",
    severity: "info",
  },
  errorMessage: {
    withLoading: false,
    className: "w-100 mb-3",
    severity: "error",
  },
};
