import React, { useEffect } from "react";
import PropType from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getMcRequiredMergeFields } from "../../../store/middlewares/logsMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";

const cells = [
  {
    text: "Name",
  },
  {
    text: "Tag",
  },
];

const ViewMcRequiredMergeFieldModal = (props) => {
  const {
    // Component props
    showModal,
    toggleModal,
    mcIdForRequiredMergeField,

    // Redux props
    viewMcRequiredMergeFieldStatus,
    mcRequiredMergeFields,

    // Redux functions
    getMcRequiredMergeFields,
  } = props;

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("mailchimpListId", mcIdForRequiredMergeField);
    getMcRequiredMergeFields(formData);
  }, [getMcRequiredMergeFields, mcIdForRequiredMergeField]);

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        classes={{
          root: "subscription-plans-page",
        }}
        fullWidth={"md"}
      >
        <DialogTitle className="pb-0">
          <div>
            <div className="d-flex align-items-center justify-content-start">
              <strong>Required Merge Fields</strong>{" "}
              <a
                className="learn-how-to-resolve-link ml-2"
                href="https://support.ccbchimp.com/en/articles/6872122-v2-merge-field-error-required-custom-fields"
                target="__blank"
              >
                Learn how to resolve
              </a>
            </div>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>
        </DialogTitle>
        <DialogContent className="mb-4">
          {viewMcRequiredMergeFieldStatus === "loading" ? (
            <div className="w-100 flexer overflow-hidden">
              <CircularProgress size={40} />
            </div>
          ) : (
            <div className="flexer-column mt-2">
              <div className="user-settings-greetings">
                <TableInfinityScroll
                  className="logs-modal-table"
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                      hideCheckBoxCell={true}
                    />
                    <tbody className="user-settings-greetings-table-body">
                      {Boolean(mcRequiredMergeFields?.length) ? (
                        mcRequiredMergeFields.map((item, idx) => (
                          <tr key={idx}>
                            <td className="font-weight-bold">{item.name}</td>
                            <td>{item.tag}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colSpan={2}>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </TableInfinityScroll>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

ViewMcRequiredMergeFieldModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
  mcIdForRequiredMergeField: PropType.string,
};

ViewMcRequiredMergeFieldModal.defaultProps = {
  mcIdForRequiredMergeField: "",
};

const mapStateToProps = (store) => {
  return {
    viewMcRequiredMergeFieldStatus: store.logs.viewMcRequiredMergeFieldStatus,
    mcRequiredMergeFields: store.logs.mcRequiredMergeFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMcRequiredMergeFields: (data) =>
      dispatch(getMcRequiredMergeFields(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewMcRequiredMergeFieldModal));
