import React from "react";
import PropTypes from "prop-types";
import BillingTableRow from "./BillingTableRow";

const BillingTableBody = (props) => {
  const { data, isLoading, handleItemClicked, tableIdentity } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <BillingTableRow
          data={elementData}
          handleItemClicked={handleItemClicked}
          index={index}
          tableIdentity={tableIdentity}
        />
      ))}
    </tbody>
  );
};

BillingTableBody.propTypes = {
  onDetailsClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleItemClicked: PropTypes.func.isRequired,
  tableIdentity: PropTypes.string,
};

BillingTableBody.defaultProps = {
  data: [],
  tableIdentity: "",
};

export default BillingTableBody;
