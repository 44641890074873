import React, { useCallback, useMemo } from "react";
import PropType from "prop-types";
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { checkForActiveFilter } from "../../../helpers";
import "../../../styles/components/more/billing.scss";

const IntegrationFilterChecklist = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { handleFilteration, identity, disabled, pairFilters } = props;
  const activeFilters = useMemo(
    () => checkForActiveFilter(pairFilters[identity]),
    [identity, pairFilters]
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = useCallback(
    (event, index) => {
      handleFilteration(identity, index);
    },
    [handleFilteration, identity]
  );

  return (
    <div>
      <IconButton
        size="small"
        className="mr-1"
        aria-controls="table-more-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        color="inherit"
      >
        <Badge
          invisible={!Boolean(activeFilters.length)}
          classes={{ badge: "icon-badge" }}
          badgeContent={activeFilters.length}
          color="primary"
        >
          <FilterList
            style={{ color: activeFilters.length ? "#FFD110" : "inherit" }}
          />
        </Badge>
      </IconButton>
      <Menu
        id="table-more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          list: "filter-select-menu",
        }}
      >
        {pairFilters[identity] &&
          pairFilters[identity].map((item, idx) => (
            <MenuItem className="w-100" key={idx}>
              <Checkbox
                value={item.key}
                checked={item.active}
                name={item.key}
                onChange={(event) => handleCheck(event, idx)}
                style={{ color: "#2db8e8" }}
              />
              <ListItemText className="label" primary={item.key} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

IntegrationFilterChecklist.propTypes = {
  filteration: PropType.func,
  checkList: PropType.array,
  disabled: PropType.bool.isRequired,
};

export default IntegrationFilterChecklist;
