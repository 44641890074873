import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
} from "../../../utils/integrationsConstants";

export const tableHeaders = {
  ccb: [
    {
      title: "Campuses / Saved Searches",
      identity: CCB_CAMPUSES,
    },
    {
      title: "Groups / Process Queues / Saved Searches",
      identity: CCB_GROUPS,
    },
  ],
  mc: [
    {
      title: "Audience",
      identity: MAILCHIMP_AUDIENCE,
    },
    {
      title: "Audience Tag / Groups",
      identity: MAILCHIMP_GROUPS,
    },
  ],
};
