import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { ArrowRight } from "@material-ui/icons";
import { MenuItem, Popover, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as PeopleIcon } from "../../assets/icons/people-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { menus as UserMenus, adminMenus } from "./menus";
import { userLogout } from "../../store/actions/userActions";
import {
  getFullName,
  hasAdminData,
  returnToAdmin,
  truncateStringWithDots,
} from "../../helpers";
import { toggleSyncNewPairsConfirmationModal } from "../../store/actions/integrationActions";
import UserAvatar from "../../HelperComponents/UserAvatar";
import TooltipWhenOverflow from "../../HelperComponents/TooltipWhenOverflow";
import RenderSidebarExpandedElement from "../../HelperComponents/RenderSidebarExpandedElement";
import "../../styles/components/profile-setting-dropright.scss";
import clsx from "clsx";

const MainTabSettingDropRight = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const {
    isSidebarExpanded,

    // Redux State
    userApiDetails,

    userData,
    history,
    hasNotSyncedPairs,

    // Redux Functions
    userLogout,
    toggleSyncNewPairsConfirmationModal,
  } = props;
  const location = useLocation();
  const menus = userData.role === 1 ? adminMenus : UserMenus;

  const toggleMenu = useCallback(
    (menuClicked, link) => {
      setShowMenu(!showMenu);
      if (typeof menuClicked === "string") {
        if (["helpdesk", "facebook"].includes(menuClicked) && link) {
          window.open(
            link,
            "_blank" // <- This is what makes it open in a new window.
          );
          return null;
        } else {
          if (location.pathname === "/sync" && hasNotSyncedPairs) {
            toggleSyncNewPairsConfirmationModal(true, menuClicked);
          } else {
            history.push(menuClicked);
          }
        }
      }
    },
    [
      history,
      showMenu,
      toggleSyncNewPairsConfirmationModal,
      location,
      hasNotSyncedPairs,
    ]
  );

  const handleLogout = useCallback(() => {
    localStorage.clear();
    userLogout();
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history, userLogout]);

  return (
    <div className="w-100 position-relative">
      <MenuItem
        onClick={toggleMenu}
        classes={{
          root: `single ${
            isSidebarExpanded ? "dropright-settings-icon-wrapper" : ""
          }`,
        }}
        className={clsx("menu-text", {
          "ml-3": isSidebarExpanded,
          "pl-2": !isSidebarExpanded,
        })}
        id={"dropright-setting-icon"}
      >
        <div className="dropright-settings-icon">
          <UserAvatar userData={userData} />
        </div>
        <RenderSidebarExpandedElement>
          <div>
            <TooltipWhenOverflow maxWidth={100} text={getFullName(userData)}>
              <Typography
                className="ml-2 menu-text m-0 p-0"
                variant="subtitle1"
              >
                {truncateStringWithDots(getFullName(userData), 12)}
              </Typography>
            </TooltipWhenOverflow>
            {userApiDetails.ccbAccount && (
              <TooltipWhenOverflow
                maxWidth={100}
                text={userApiDetails.ccbAccount}
              >
                <Typography className="ml-2 menu-text" variant="subtitle2">
                  {truncateStringWithDots(userApiDetails.ccbAccount, 18)}
                </Typography>
              </TooltipWhenOverflow>
            )}
          </div>
        </RenderSidebarExpandedElement>
        <ArrowRight className="arrow-right-icon" htmlColor="#fff" />
      </MenuItem>

      <Popover
        id="simple-popover"
        open={showMenu}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          root: `profile-info-dropright`,
          paper: `paper ${isSidebarExpanded ? "" : "collapsed-dropright-menu"}`,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem className="menuitem-wrapper name-title">
          <UserAvatar userData={userData} />
          <Tooltip title={getFullName(userData)} arrow={true}>
            <Typography variant="h3" className="name text-ellipsis ml-1">
              {truncateStringWithDots(getFullName(userData), 23)}
            </Typography>
          </Tooltip>
        </MenuItem>
        <div className="menuitem-wrapper">
          {Boolean(userData.isActivated) &&
            userData?.apiKey &&
            menus.map((menu) => (
              <MenuItem
                className="menuitem"
                onClick={() => toggleMenu(menu.route, menu.link)}
                key={menu.title}
              >
                <span className="icon">
                  <menu.icon />
                </span>
                <span className="text">{menu.title}</span>
              </MenuItem>
            ))}
          {hasAdminData() && (
            <MenuItem className="menuitem" onClick={returnToAdmin}>
              <span className="icon">
                <PeopleIcon className="mb-1" />
              </span>

              <span className="text">Return to admin</span>
            </MenuItem>
          )}
        </div>
        <MenuItem className="menuitem-wrapper">
          <div className="menuitem logout pb-3" onClick={handleLogout}>
            <span className="icon">
              <LogoutIcon />
            </span>
            <span className="text">Log out</span>
          </div>
        </MenuItem>
      </Popover>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
    toggleSyncNewPairsConfirmationModal: (val, skipRoute) =>
      dispatch(toggleSyncNewPairsConfirmationModal(val, skipRoute)),
  };
};

const mapStateToProps = (store) => {
  return {
    userApiDetails: store.userReducer.userApiDetails,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainTabSettingDropRight)
);
