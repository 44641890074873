import config from "../../config";
import { postRequest } from "../../utils/request";
import { ACTION_TYPES } from "../actions/types";

export function saveToolkitCleanup(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_TOOLKIT_CLEANUP,
      config.Path.SAVE_TOOLKIT_CLEANUP,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function saveStoreUserInfo(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_STORE_USER_INFO,
      config.Path.SAVE_STORE_USER_INFO,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function savePrimaryProfiles(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_PRIMARY_PROFILES,
      config.Path.SAVE_PRIMARY_PROFILES,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveMcToCcbRemoveInactive(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_MC_TO_CCB_REMOVE_INACTIVE,
      config.Path.SAVE_MC_TO_CCB_REMOVE_INACTIVE,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveAutoSync(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_AUTO_SYNC,
      config.Path.SAVE_AUTO_SYNC,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveEmailNotificationForUnsub(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_UBSUB,
      config.Path.SAVE_EMAIL_NOTIFICATION_FOR_UNSUB,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveEmailNotificationForPendingDupes(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES,
      config.Path.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveCheckReceivedEmail(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_CHECK_RECEIVED_EMAIL,
      config.Path.SAVE_CHECK_RECEIVED_EMAIL,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveDeleteMcListGroup(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_DELETE_MC_LIST_GROUP,
      config.Path.SAVE_DELETE_MC_LIST_GROUP,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveMemberSettings(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_MEMBERS_SETTINGS,
      config.Path.SAVE_MEMBERS_SETTINGS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveSkipMergeValidation(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_SKIP_MERGE_VALIDATION,
      config.Path.SAVE_SKIP_MERGE_VALIDATION,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function accountCleanup(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ACCOUNT_CLEANUP,
      config.Path.ACCOUNT_CLEAN_UP,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getPrimaryContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_PRIMARY_CONTACTS,
      config.Path.GET_PRIMARY_CONTACTS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function makePrimaryContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.MAKE_PRIMARY_CONTACTS,
      config.Path.MAKE_PRIMARY_CONTACTS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getIgnoredEmails(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_IGNORED_EMAILS,
      config.Path.GET_IGNORED_EMAIL,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function unignoreEmails(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.UNIGNORE_EMAIL,
      config.Path.UNIGNORE_EMAIL,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getCleanupUnsyncPairs(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_CLEANUP_UNSYNC_PAIRS,
      config.Path.GET_CLEANUP_UNSYNC_PAIR,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function deleteMcCleanupPair(data) {
  return (dispatch) =>
    postRequest(dispatch, ACTION_TYPES.DELETE_MC, config.Path.DELETE_MC, data, {
      requestDispatchData: { data },
      successDispatchData: { data },
      errorDispatchData: {},
    });
}

export function dismissDeleteMcPair(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.DISMISS_DELETE_MC,
      config.Path.DISMISS_DELETE_MC,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getHeaderMapping(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_HEADER_MAPPING,
      config.Path.GET_HEADER_MAPPING,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function saveHeaderMapping(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_HEADER_MAPPING,
      config.Path.SAVE_HEADER_MAPPING,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getMcToCcbCustomList(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_MC_TO_CCB_CUSTOM_LIST,
      config.Path.GET_MC_TO_CCB_CUSTOM_LIST,
      data
    );
}

export function addMcToCcbCustomList(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST,
      config.Path.ADD_MC_TO_CCB_CUSTOM_LIST,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function deleteMcToCcbCustomList(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST,
      config.Path.DELETE_MC_TO_CCB_CUSTOM_LIST,
      data,
      {
        requestDispatchData: { data, requestId },
        successDispatchData: { data, requestId },
        errorDispatchData: {},
      }
    );
}
