import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chip, Tooltip, Typography } from "@material-ui/core";
import { ErrorRounded } from "@material-ui/icons";
import { ReactComponent as SavedSearches } from "../../../assets/icons/saved-searches-icon.svg";
import TooltipWhenOverflow from "../../../HelperComponents/TooltipWhenOverflow";
import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
} from "../../../utils/integrationsConstants";

const SynsListText = ({
  text,
  chipLabel,
  isSavedSearch,
  identity,
  syncType,
  isObseleteClassicSearch,
}) => {
  const getPlaceholderBrokenText = useMemo(() => {
    if (identity === CCB_CAMPUSES) return "CCB Campus ABC";
    if (identity === CCB_GROUPS) return "CCb Group ABC";
    if (identity === MAILCHIMP_AUDIENCE) return "Mailchimp Audience ABC";
    if (identity === MAILCHIMP_GROUPS)
      return syncType === 1 ? "Mailchimp Tags" : "Mailchimp Group ABC";
  }, [identity, syncType]);

  const renderBrokenText = useMemo(() => {
    if (text) return text;
    return (
      <Tooltip
        interactive={true}
        arrow={true}
        placement={"left"}
        disableFocusListener
        disableTouchListener
        title={
          <span>
            {`This ${getPlaceholderBrokenText.replace(
              "ABC",
              ""
            )} is broken or has been removed.`}
            <br />{" "}
            <a
              href="https://support.ccbchimp.com/en/articles/2535782-syncing-paused-you-need-to-confirm-and-restart-syncing"
              target="__blank"
              className="link-text"
            >
              Learn more
            </a>
          </span>
        }
      >
        <span className="broken-text">
          <ErrorRounded htmlColor="#FF5F0A" fontSize="small" className="mr-1" />{" "}
          {getPlaceholderBrokenText}
        </span>
      </Tooltip>
    );
  }, [getPlaceholderBrokenText, text]);

  return (
    <Typography className="list-item-name" noWrap={true}>
      <TooltipWhenOverflow
        className="text-overflow-ellipsis"
        maxWidth={280}
        placement={"auto"}
        text={text}
      >
        {chipLabel && (
          <Chip className="chip mr-2" label={chipLabel} size="small" />
        )}
        {isSavedSearch && (
          <SavedSearches className="mr-1" width={"16px"} fill="#fff" />
        )}
        <span>
          {renderBrokenText}{" "}
          {isObseleteClassicSearch && (
            <div className="text-danger-orange-light">
              Classic Search (Obsolete)
            </div>
          )}
        </span>
      </TooltipWhenOverflow>
    </Typography>
  );
};

SynsListText.propType = {
  text: PropTypes.string,
  chipLabel: PropTypes.string,
  isSavedSearch: PropTypes.bool,
  identity: PropTypes.string,
  syncType: PropTypes.number.isRequired,
  isObseleteClassicSearch: PropTypes.bool,
};

SynsListText.defaultProps = {
  text: "",
  chipLabel: "",
  isSavedSearch: false,
  isObseleteClassicSearch: false,
  identity: "",
};

export default SynsListText;
