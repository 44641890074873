import React from "react";
import PropType from "prop-types";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { Checkbox } from "@material-ui/core";

const cancellingReasons = [
  "I don't understand how to use your product.",
  "It's too expensive.",
  "I found another option or product that I like better.",
  "I don't use it enough.",
  "Some features I need are missing.",
  "Bugs or Support Problems.",
  "Other reasons",
];

const CancelPlan = (props) => {
  const {
    showModal,
    toggleModal,
    onClick,
    handleCancellationReasonChange,
    cancellationReason,
    loading,
    stopSync,
    handleStopSyncChange,
    onCustomCancellationReasonChange,
    customCalcellationReason,
  } = props;
  return (
    <div>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="pb-0">
          <strong>Cancel subscription</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have not canceled your account yet. Please select an option
            below.
            <br /> What's the single biggest reason for you canceling?
          </DialogContentText>
          <FormControl className="w-100">
            <InputLabel id="demo-controlled-open-select-label">
              Plese select canceling reason
            </InputLabel>
            <Select
              value={cancellationReason}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              name="reason"
              onChange={(event) =>
                handleCancellationReasonChange(event.target.value)
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {cancellingReasons.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogContentText className="mt-4" style={{ fontWeight: 600 }}>
            We are sorry to see you go. You're welcome back at any time. Any
            final comments for us?
          </DialogContentText>
          <TextField
            id="outlined-multiline-static"
            label="Comments"
            name="comment"
            multiline
            rows={4}
            variant="outlined"
            onChange={onCustomCancellationReasonChange}
            value={customCalcellationReason}
            fullWidth
          />
          <div className="flexer-start">
            <Checkbox
              checked={stopSync}
              onChange={handleStopSyncChange}
              style={{ color: "#2db8e8" }}
              className="mb-1"
            />
            <DialogContentText className="mt-2">
              Disable Automatic Syncing Immediately{" "}
              <a
                href="https://support.ccbchimp.com/en/articles/2105551-cancel"
                target="__blank"
                className="link-text"
              >
                (?)
              </a>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner onClick={toggleModal} className="dark-gray-button">
            Nevermind
          </ButtonWithSpinner>
          <ButtonWithSpinner
            loading={loading}
            onClick={onClick}
            className="bg-yellow"
            disabled={!cancellationReason || loading}
          >
            Cancel
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CancelPlan.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

export default CancelPlan;
