import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { ReactComponent as GroupIcon } from "../../../assets/icons/general/group.svg";
import { ReactComponent as TagIcon } from "../../../assets/icons/general/tag.svg";
import { ArrowForward, Info, Settings } from "@material-ui/icons";
import {
  CCB_CAMPUSES,
  CCB_TO_MC,
  MC_TO_CCB,
  CCB_GROUPS,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
  INITIAL_SYNCING_IN_PROGRESS,
  SYNCING_IN_PROGRESS,
} from "../../../utils/integrationsConstants";
import {
  getIntegrationActiveTabType,
  getIntegrationListName,
  getValueFromArrayOfKeys,
  truncateStringWithDots,
} from "../../../helpers";
import SyncListText from "./SynsListText";
import { Chip, Tooltip, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  setMcToCcbDisableInactiveFor,
  setIntegrationPairForEdit,
  toggleAddNewSyncPairModal,
  toggleRemoveSyncPairModal,
} from "../../../store/actions/integrationActions";
import { connect } from "react-redux";
import { setDataForModal } from "../../../store/actions/globalAction";
import SyncListHeader from "../SyncListHeader/SyncListHeader";
import LightTooltip from "../../../HelperComponents/LightTooltip";
import MaterialButton from "../../ MaterialButton/MaterialButton";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import MergeFieldErrorArticleLink from "../../../HelperComponents/MergeFieldErrorArticleLink";
import SpanWithTooltip from "../../SpanWithTooltip/SpanWithTooltip";
import ViewMcRequiredMergeFieldModal from "../../modals/ViewMcRequiredMergeFieldModal/ViewMcRequiredMergeFieldModal";
import { UseSyncOptions } from "../../../utils/hooks/useSyncOptions";
import {
  MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_DISABLE,
  MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_ENABLE,
} from "../../../utils/constants";
import ShowSyncArrow from "../../../HelperComponents/ShowSyncArrow";
import FilteredGroupMembershipType from "../FilteredGroupMembershipType/FilteredGroupMembershipType";
import { ReactComponent as SwitchIcon } from "../../../assets/icons/switch-icon.svg";
import "../../../styles/components/sync-list.scss";
import "../../../styles/components/services/header-services.scss";

const keysArray = {
  [CCB_CAMPUSES]: ["campusName", "listName"],
  [CCB_GROUPS]: ["name", "ccbGroupName", "listGroupName", "queueName"],
  [MAILCHIMP_AUDIENCE]: ["mailchimpAudience"],
  [MAILCHIMP_GROUPS]: ["mcInterestGroupName"],
};

const SyncList = (props) => {
  const {
    // Component props
    ccbToMcSyncPairs,
    mcToCcbSyncPairs,
    notSyncedPairs,
    handleFilteration,
    pairFilters,
    handleSearch,
    handleReload,
    searchValue,
    loading,
    onClickFixBrokePair,

    // Redux props
    activeTab,
    ccbSavedSearches,
    userData,

    // Redux functions
    toggleRemoveSyncPairModal,
    setDataForModal,
    toggleAddNewSyncPairModal,
    setMcToCcbDisableInactiveFor,
    setIntegrationPairForEdit,
  } = props;
  const breakpoint = useBreakpoint();
  const [hoverActiveIndex, setHoverActiveIndex] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [mcIdForRequiredMergeField, setMcIdForRequiredMergeField] =
    useState("");
  const windowSize = useWindowSize();
  const {
    isIntegrationsDisabled,
    isSyncingInProgress,
    isAutoSyncingInProgress,
    disableSyncing,
  } = UseSyncOptions(notSyncedPairs);

  const getTooltipTitle = useMemo(() => {
    if (isIntegrationsDisabled) {
      return `Turn on Toggle for ${getIntegrationActiveTabType(
        activeTab
      )} to Start adding Sync Pairs`;
    } else if (isAutoSyncingInProgress) {
      return `Please wait, ${INITIAL_SYNCING_IN_PROGRESS}`;
    } else if (isSyncingInProgress) {
      return `Please wait, ${SYNCING_IN_PROGRESS}`;
    }
    return "";
  }, [
    activeTab,
    isAutoSyncingInProgress,
    isIntegrationsDisabled,
    isSyncingInProgress,
  ]);

  const isClassicSearchSelected = useCallback(
    (searchId = "") => {
      if (
        ccbSavedSearches &&
        Array.isArray(ccbSavedSearches) &&
        Boolean(ccbSavedSearches.length)
      ) {
        return ccbSavedSearches.some(
          (savedSearch) =>
            savedSearch?.searchId === searchId &&
            (savedSearch?.isAdvance === 0 ||
              savedSearch?.groupBy === "Classic Searches")
        );
      }
      return false;
    },
    [ccbSavedSearches]
  );

  const openRemoveSyncModal = useCallback(
    (data) => () => {
      toggleRemoveSyncPairModal(true);
      setDataForModal(data);
    },
    [setDataForModal, toggleRemoveSyncPairModal]
  );

  const getChipLabel = useCallback((pair = {}) => {
    if (Number(pair.isNew) === 1 && Number(pair?.hasMcConflict) === 0) {
      return "Not Synced";
    }
    return "";
  }, []);

  const toggleErroModal = useCallback(() => {
    setShowErrorModal(!showErrorModal);
  }, [showErrorModal]);

  const onClickedErrorChip = useCallback(
    (data) => () => {
      if (data?.mailchimpListId) {
        toggleErroModal();
        setMcIdForRequiredMergeField(data?.mailchimpListId);
      }
    },
    [toggleErroModal]
  );

  const onChangeDisableInactiveRemove = useCallback(
    (syncPair) => () => {
      setMcToCcbDisableInactiveFor(syncPair);
    },
    [setMcToCcbDisableInactiveFor]
  );

  const handleToggleAddNewSyncPairModal = useCallback(() => {
    toggleAddNewSyncPairModal(true);
  }, [toggleAddNewSyncPairModal]);

  const onClickEditIntegrationPair = useCallback(
    (pair) => () => {
      setIntegrationPairForEdit(pair);
    },
    [setIntegrationPairForEdit]
  );

  const renderAddNewSyncPairBtn = useMemo(() => {
    return (
      <div className="p-3">
        <LightTooltip arrow={true} placement={"bottom"} title={getTooltipTitle}>
          <div>
            <MaterialButton
              className={`ml-3 ${
                isIntegrationsDisabled
                  ? "add-new-sync-btn-disabled"
                  : "add-new-sync-btn"
              }`}
              title="Add New Sync pair"
              icon={<Add fontSize="small" color="white" />}
              onClick={handleToggleAddNewSyncPairModal}
              disabled={disableSyncing}
            />
          </div>
        </LightTooltip>
      </div>
    );
  }, [
    getTooltipTitle,
    isIntegrationsDisabled,
    disableSyncing,
    handleToggleAddNewSyncPairModal,
  ]);

  const renderEditBtn = useCallback(
    (index, item) => {
      if (
        !Boolean(item.hasMcConflict) &&
        (hoverActiveIndex === index || isMdOrBelowBreakpoint(breakpoint))
      ) {
        return (
          <div className="edit-btn-container">
            <ButtonWithSpinner
              onClick={onClickEditIntegrationPair(item)}
              className="bg-blue"
              disabled={isSyncingInProgress}
            >
              Edit
            </ButtonWithSpinner>
          </div>
        );
      }
    },
    [
      hoverActiveIndex,
      breakpoint,
      onClickEditIntegrationPair,
      isSyncingInProgress,
    ]
  );

  const renderMailchimpTypeIcon = useCallback((syncType) => {
    const icon = syncType ? (
      <TagIcon className="mr-1" />
    ) : (
      <GroupIcon className="mr-1" />
    );
    return icon;
  }, []);

  const renderMailchimpTags = useCallback(
    (mcTags, itemName) => {
      if (mcTags?.length) {
        return (
          <Typography
            noWrap={true}
            className="ml-2 text-ellipsis list-item-name"
          >
            <span>
              {mcTags.length > 2 ? (
                <div className="flexer">
                  <span>
                    {truncateStringWithDots(mcTags.slice(0, 2).join(), 22)}
                  </span>
                  <Tooltip arrow={true} placement={"left"} title={itemName}>
                    <div className="more-tags ml-2 cursor-pointer">
                      +{mcTags.slice(2, mcTags.length).length} More
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <span>
                  {windowSize.width <= 770
                    ? truncateStringWithDots(itemName, 29)
                    : itemName}
                </span>
              )}
            </span>
          </Typography>
        );
      }
    },
    [windowSize]
  );

  const renderTableRow = useMemo(() => {
    if (activeTab === CCB_TO_MC) {
      return ccbToMcSyncPairs.map((item, index) => (
        <tr
          key={index}
          onMouseEnter={() => setHoverActiveIndex(index)}
          onMouseLeave={() => setHoverActiveIndex(null)}
          className={`sync-table-body-row flexer-space-around ${
            Boolean(item.hasMcConflict) || Boolean(item?.hasMcMergeFieldError)
              ? "broken-sync-pair"
              : ""
          }`}
        >
          <td className="w-5 p-0 d-flex align-items-center justify-content-center">
            <SwitchIcon
              onClick={openRemoveSyncModal(item)}
              className="cursor-pointer"
            />
          </td>
          <td className="d-flex align-items-center justify-content-between  w-22">
            <div className="d-flex">
              {Boolean(item?.hasMcMergeFieldError) && (
                <MergeFieldErrorArticleLink className={"mr-2"}>
                  <Info
                    className="mb-1"
                    htmlColor="rgb(97, 26, 21)"
                    fontSize="small"
                  />
                </MergeFieldErrorArticleLink>
              )}
              <SyncListText
                text={getIntegrationListName(
                  item,
                  CCB_CAMPUSES,
                  getValueFromArrayOfKeys(item, keysArray[CCB_CAMPUSES])
                )}
                chipLabel={getChipLabel(item)}
                isSavedSearch={item.searchId}
                identity={CCB_CAMPUSES}
              />
            </div>
          </td>
          <td className="d-flex align-items-center justify-content-between  w-22">
            <SyncListText
              text={getIntegrationListName(
                item,
                CCB_GROUPS,
                getValueFromArrayOfKeys(item, keysArray[CCB_GROUPS])
              )}
              identity={CCB_GROUPS}
              isObseleteClassicSearch={isClassicSearchSelected(item.searchId)}
            />
            <ShowSyncArrow
              className={"arrow-icon"}
              color={"inherit"}
              fontSize={"default"}
              hasOneWayIcon={item?.searchId || item?.processId}
              containerClassName={"da"}
            />
          </td>
          <td>
            <div className="d-flex">
              <SyncListText
                text={getIntegrationListName(
                  item,
                  MAILCHIMP_AUDIENCE,
                  getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_AUDIENCE])
                )}
                identity={MAILCHIMP_AUDIENCE}
              />
              {Boolean(item.hasMcMergeFieldError) && (
                <SpanWithTooltip
                  className={"ml-2"}
                  title="Show required merge fields"
                  arrow={true}
                  placement="auto"
                  onClick={onClickedErrorChip(item)}
                >
                  <Chip
                    color="rgb(97, 26, 21)"
                    className="error-chip"
                    label={"Merge field error"}
                    size="small"
                    classes={{
                      label: "cursor-pointer",
                    }}
                  />
                </SpanWithTooltip>
              )}
            </div>
          </td>
          <td className="position-relative">
            {Boolean(item.hasMcConflict) ? (
              <ButtonWithSpinner
                onClick={onClickFixBrokePair}
                className="bg-blue ml-5"
                disabled={isSyncingInProgress}
              >
                Fix Now
              </ButtonWithSpinner>
            ) : (
              <>
                {renderMailchimpTypeIcon(item.sync_type)}
                {item.sync_type === 1 ? (
                  renderMailchimpTags(
                    item.mcTags,
                    getIntegrationListName(
                      item,
                      MAILCHIMP_GROUPS,
                      getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_GROUPS])
                    )
                  )
                ) : (
                  <SyncListText
                    text={getIntegrationListName(
                      item,
                      MAILCHIMP_GROUPS,
                      getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_GROUPS])
                    )}
                    identity={MAILCHIMP_GROUPS}
                    syncType={item.sync_type}
                  />
                )}
              </>
            )}
            {renderEditBtn(index, item)}
          </td>
        </tr>
      ));
    } else if (activeTab === MC_TO_CCB) {
      return mcToCcbSyncPairs.map((item, index) => (
        <tr
          key={index}
          onMouseEnter={() => setHoverActiveIndex(index)}
          onMouseLeave={() => setHoverActiveIndex(null)}
          className={`sync-table-body-row flexer-space-around ${
            Boolean(item.hasMcConflict) ? "broken-sync-pair" : ""
          }`}
        >
          <td className="w-5 p-0 d-flex align-items-center justify-content-center">
            {Boolean(userData?.isMcToCcbDisableInactiveRemove) && (
              <Tooltip
                arrow={true}
                className="cursor-pointer"
                placement="bottom"
                title={
                  Number(item?.isMcToCcbDisableInactiveRemove) === 1
                    ? MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_DISABLE
                    : MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_ENABLE
                }
              >
                <div>
                  <Settings
                    onClick={onChangeDisableInactiveRemove(item)}
                    className="mr-2 cursor-pointer"
                    htmlColor={
                      Number(item?.isMcToCcbDisableInactiveRemove) === 1
                        ? "#2DB8E8"
                        : "#959494"
                    }
                    fontSize="small"
                  />{" "}
                </div>
              </Tooltip>
            )}
            <SwitchIcon
              onClick={openRemoveSyncModal(item)}
              className="cursor-pointer"
            />
          </td>
          <td className="d-flex align-items-center justify-content-between w-22">
            <span className="d-flex align-items-center mr-1">
              <SyncListText
                text={getIntegrationListName(
                  item,
                  MAILCHIMP_AUDIENCE,
                  getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_AUDIENCE])
                )}
                chipLabel={getChipLabel(item)}
                isSavedSearch={item.searchId}
                identity={MAILCHIMP_AUDIENCE}
              />
            </span>
          </td>
          <td className="d-flex align-items-center justify-content-between w-22">
            <div className="flexer">
              {!Boolean(item.hasMcConflict) &&
                renderMailchimpTypeIcon(item.sync_type)}
              {item.sync_type === 1 ? (
                renderMailchimpTags(
                  item.mcTags,
                  getIntegrationListName(
                    item,
                    MAILCHIMP_GROUPS,
                    getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_GROUPS])
                  )
                )
              ) : (
                <SyncListText
                  text={getIntegrationListName(
                    item,
                    MAILCHIMP_GROUPS,
                    getValueFromArrayOfKeys(item, keysArray[MAILCHIMP_GROUPS])
                  )}
                  identity={MAILCHIMP_GROUPS}
                  syncType={item.sync_type}
                />
              )}
            </div>
            <ArrowForward
              className="arrow-icon"
              color={"inherit"}
              fontSize={"default"}
            />
          </td>
          <td>
            <SyncListText
              text={getIntegrationListName(
                item,
                CCB_CAMPUSES,
                getValueFromArrayOfKeys(item, keysArray[CCB_CAMPUSES])
              )}
              identity={CCB_CAMPUSES}
            />
          </td>
          <td className="position-relative">
            {Boolean(item.hasMcConflict) ? (
              <ButtonWithSpinner
                onClick={onClickFixBrokePair}
                className="bg-blue ml-5"
                disabled={isSyncingInProgress}
              >
                Fix Now
              </ButtonWithSpinner>
            ) : (
              <SyncListText
                text={getIntegrationListName(
                  item,
                  CCB_GROUPS,
                  getValueFromArrayOfKeys(item, keysArray[CCB_GROUPS])
                )}
                identity={CCB_GROUPS}
              />
            )}
            {renderEditBtn(index, item)}
          </td>
        </tr>
      ));
    }
    return null;
  }, [
    activeTab,
    ccbToMcSyncPairs,
    getChipLabel,
    onClickedErrorChip,
    onClickFixBrokePair,
    isSyncingInProgress,
    renderMailchimpTypeIcon,
    renderMailchimpTags,
    mcToCcbSyncPairs,
    onChangeDisableInactiveRemove,
    isClassicSearchSelected,
    renderEditBtn,
    userData,
    openRemoveSyncModal,
  ]);

  return (
    <>
      <div
        className={`sync-list-container ${
          isMdOrBelowBreakpoint(breakpoint) ? "w-auto" : "w-100"
        }`}
      >
        <div className="d-flex align-items-center w-50 justify-content-between">
          <div className="add-new-sync-btn-container">
            {renderAddNewSyncPairBtn}
          </div>
          <FilteredGroupMembershipType
            className={"sync-page-group-membership mr-1"}
          />
        </div>
        <table className="w-100">
          <SyncListHeader
            activeTab={activeTab}
            handleFilteration={handleFilteration}
            pairFilters={pairFilters}
            isIntegrationsDisabled={isIntegrationsDisabled}
            handleSearch={handleSearch}
            handleReload={handleReload}
            searchValue={searchValue}
          />
          {!isIntegrationsDisabled && !loading && (
            <tbody>{renderTableRow}</tbody>
          )}
        </table>
      </div>
      {showErrorModal && (
        <ViewMcRequiredMergeFieldModal
          showModal={showErrorModal}
          toggleModal={toggleErroModal}
          mcIdForRequiredMergeField={mcIdForRequiredMergeField}
        />
      )}
    </>
  );
};

SyncList.propType = {
  ccbToMcSyncPairs: PropTypes.array,
  mcToCcbSyncPairs: PropTypes.array,
  notSyncedPairs: PropTypes.array,
  loading: PropTypes.bool,
  handleFilteration: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
  onClickFixBrokePair: PropTypes.func.isRequired,
};

SyncList.defaultProps = {
  ccbToMcSyncPairs: [],
  mcToCcbSyncPairs: [],
  notSyncedPairs: [],
  loading: false,
};

const mapStateToProps = (store) => {
  return {
    activeTab: store.integrations.currentSync,
    ccbSavedSearches: store.integrations.ccbSavedSearches,
    userData: store.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRemoveSyncPairModal: (val) =>
      dispatch(toggleRemoveSyncPairModal(val)),
    setDataForModal: (val) => dispatch(setDataForModal(val)),
    toggleAddNewSyncPairModal: (val) =>
      dispatch(toggleAddNewSyncPairModal(val)),
    setMcToCcbDisableInactiveFor: (val) =>
      dispatch(setMcToCcbDisableInactiveFor(val)),
    setIntegrationPairForEdit: (val) =>
      dispatch(setIntegrationPairForEdit(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncList);
