import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const MaterialLightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #acacac",
    padding: "10px 8px",
    borderRadius: "0.25rem",
    maxWidth: 300,
    position: "left",
    fontSize: "0.9em !important",
    textAlign: "center",
  },
  popper: {
    zIndex: 999999,
  },
  popperArrow: {
    '&[x-placement*="bottom"] .MuiTooltip-arrow': {
      background: "#fff",
      borderTop: "1px solid #acacac",
      borderLeft: "1px solid #acacac",
      width: "1.25em",
      height: "1.25em",
    },
    '&[x-placement*="top"] .MuiTooltip-arrow': {
      background: "#fff",
      borderRight: "1px solid #acacac",
      borderBottom: "1px solid #acacac",
      width: "1.5em",
      height: "1.5em",
    },
  },
  arrow: {
    color: theme.palette.common.white,
    background: "#fff",
    transform: "rotate(45deg)",
    "&:before": {
      display: "none",
    },
  },
}))(Tooltip);

const LightTooltip = (props) => {
  const { title, children, className, placement, arrow, interactive } = props;
  return (
    <MaterialLightTooltip
      arrow={arrow}
      placement={placement}
      className={className}
      interactive={interactive}
      title={title}
      PopperProps={{
        disablePortal: true,
      }}
    >
      {children}
    </MaterialLightTooltip>
  );
};

LightTooltip.defaultProps = {
  title: "",
  className: "",
  arrow: true,
  placement: "",
  interactive: false,
};

LightTooltip.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  arrow: PropTypes.bool,
  interactive: PropTypes.bool,
};

export default LightTooltip;
