import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

const TooltipWhenOverflow = (props) => {
  const { text, children, className, placement, maxWidth } = props;

  const [textToShow, setTextToShow] = useState("");

  const ref = useRef();

  useEffect(() => {
    if (ref.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setTextToShow(text);
    }
  }, [text]);

  return (
    <Tooltip title={textToShow} placement={placement} arrow>
      <div
        ref={ref}
        className={`text-ellipsis ${className}`}
        style={{ maxWidth: maxWidth }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

TooltipWhenOverflow.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  placement: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TooltipWhenOverflow.defaultProps = {
  className: "",
  placement: undefined,
  maxWidth: 200,
};

export default TooltipWhenOverflow;
