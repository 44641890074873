import React from "react";
import PropType from "prop-types";
import {
  FormControl,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import "../../../styles/components/logs/logSearchBox.scss";

const LogSearchBox = (props) => {
  const { placeholder, handleChange, name, onClick, textFieldClassName } =
    props;
  return (
    <div>
      <FormControl className="form-control log-search-box">
        <form onSubmit={onClick} className={textFieldClassName}>
          <TextField
            className={`search d-flex align-items-end ${textFieldClassName}`}
            style={{ width: "95%" }}
            id="input-with-icon-textfield"
            placeholder={placeholder}
            onChange={handleChange}
            name={name}
            variant="outlined"
            InputProps={{
              classes: {
                input: "input",
                notchedOutline: "log-search-fieldset",
                root: `search-cotainer ${textFieldClassName}`,
                adornedStart: "input-abdorment-start",
                adornedEnd: "input-abdorment-end",
              },
              endAdornment: (
                <InputAdornment className="h-100" position="end">
                  <Button type="submit" className="search-btn flexer">
                    <Search />
                    <span className="search-text ml-1">Search</span>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </FormControl>
    </div>
  );
};

LogSearchBox.defaultProps = {
  placeholder: "Search",
  name: "",
};

LogSearchBox.propTypes = {
  handleChange: PropType.func.isRequired,
  toggleSearchBox: PropType.func.isRequired,
  onClick: PropType.func.isRequired,
  placeholder: PropType.string,
  name: PropType.string,
};

export default LogSearchBox;
