import React from "react";
import PropTypes from "prop-types";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import StartingPackageImg from "../../../assets/img/starting-package.png";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";

const CurrentSubsciptionDetailCard = (props) => {
  const {
    hasSubscription,
    info,
    onClick,
    loading,
    openCancelPlanModal,
    isSubcriptionCancelled,
  } = props;
  return (
    <div className="starting-package">
      {loading ? (
        <div>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <React.Fragment>
            <img src={StartingPackageImg} alt="starting-package" />
            <Typography className="large-text">{info.name}</Typography>
            <Typography className="large-text">
              ${info.amountDisplay}{" "}
            </Typography>
            {info.subAmountDisplay && (
              <Typography className="small-text">
                {info.subAmountDisplay}
              </Typography>
            )}
          </React.Fragment>
          <div className="d-flex align-items-end justify-content-between mt-5 mb-2">
            {hasSubscription && !isSubcriptionCancelled && (
              <Button onClick={openCancelPlanModal} className="text-light">
                Cancel
              </Button>
            )}
            <ButtonWithSpinner
              onClick={onClick}
              className="dark-gray-button subscribe-btn"
            >
              {hasSubscription ? "Change" : "Subscribe"}
            </ButtonWithSpinner>
          </div>
          <div className="mt-3">
            {info?.activeMembership?.startDate && (
              <Typography className="caption">
                Billing starting date on{" "}
                {new Date(info?.activeMembership?.startDate).toDateString()}{" "}
              </Typography>
            )}
            {info?.activeMembership?.endDate && (
              <Typography className="caption">
                Next Billing date on{" "}
                {new Date(info?.activeMembership?.endDate).toDateString()}{" "}
              </Typography>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CurrentSubsciptionDetailCard.defaultProps = {
  loading: false,
  isSubcriptionCancelled: false,
};

CurrentSubsciptionDetailCard.propType = {
  hasSubscription: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  openCancelPlanModal: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  isSubcriptionCancelled: PropTypes.bool,
};

export default CurrentSubsciptionDetailCard;
