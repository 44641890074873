import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const TableInfinityScroll = (props) => {
  const {
    children,
    loadMore,
    page,
    searchValue,
    fetchFunction,
    className,
    scrollToTop,
    tableRef,
    setScrollLeftPosition,
  } = props;

  const [milestoneScrollHeight, setMilestoneScrollHeight] = useState(0);

  const prevSearchValue = useRef("");
  const prevPage = useRef();

  const tableWrapperRefInner = useRef();
  let tableWrapperRef;
  if (tableRef) {
    tableWrapperRef = tableRef;
  } else {
    tableWrapperRef = tableWrapperRefInner;
  }
  const loadMoreTimer = useRef();
  const scrollLeftTimeout = useRef();

  const onScroll = useCallback(() => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
    } = tableWrapperRef.current;

    clearTimeout(scrollLeftTimeout.current);

    scrollLeftTimeout.current = setTimeout(
      () => setScrollLeftPosition(scrollLeft),
      150
    );

    const pad = 100;
    const t = scrollTop + clientHeight + pad;
    if (t > scrollHeight && scrollHeight > milestoneScrollHeight) {
      clearTimeout(loadMoreTimer.current);
      setMilestoneScrollHeight(scrollHeight);
      loadMoreTimer.current = setTimeout(loadMore, 100);
    }
  }, [loadMore, milestoneScrollHeight, setScrollLeftPosition, tableWrapperRef]);

  useEffect(() => {
    let intervalId;
    let customGroupsPage = page;

    if (searchValue !== prevSearchValue.current) {
      prevPage.current = 0;
      customGroupsPage = null;
    }

    if (
      searchValue !== prevSearchValue.current &&
      prevPage.current !== customGroupsPage
    ) {
      prevPage.current = customGroupsPage ? customGroupsPage : 1;
      prevSearchValue.current = searchValue;

      intervalId = setTimeout(() => fetchFunction(prevPage.current), 400);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchFunction, page, searchValue]);

  const handleScrollToTop = useCallback(
    (params) => {
      tableWrapperRef.current.scrollTo(0, 0);
    },
    [tableWrapperRef]
  );

  useEffect(() => {
    if (scrollToTop) {
      handleScrollToTop();
    }
  }, [handleScrollToTop, scrollToTop]);

  return (
    <div
      className={`scroll-table ${className}`}
      onScroll={onScroll}
      ref={tableWrapperRef}
    >
      {children}
    </div>
  );
};

TableInfinityScroll.propTypes = {
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func.isRequired,
  page: PropTypes.number,
  searchValue: PropTypes.string.isRequired,
  fetchFunction: PropTypes.func.isRequired,
  className: PropTypes.string,
  scrollToTop: PropTypes.bool,
  tableRef: PropTypes.any,
  setScrollLeftPosition: PropTypes.func,
};

TableInfinityScroll.defaultProps = {
  className: "",
  setScrollLeftPosition: () => {},
};

export default TableInfinityScroll;
