import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { ArrowForward, ErrorRounded } from "@material-ui/icons";
import MailchimpIcon from "../../../assets/img/mailchimp/mailchimp-small.png";
import CcbIcon from "../../../assets/img/ccb/ccb-small.png";
import CustomSwitch from "../../../HelperComponents/CustomSwitch";
import { CCB_TO_MC, MC_TO_CCB } from "../../../utils/integrationsConstants";
import { connect } from "react-redux";
import {
  disableTwoWaySyncing,
  enableTwoWaySyncing,
} from "../../../store/middlewares/integrationMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import {
  changeSyncType,
  clearDisableTwoWaySync,
  clearEnableTwoWaySync,
} from "../../../store/actions/integrationActions";
import {
  clearUpdateProfile,
  updateUserProfile,
} from "../../../store/middlewares/userMiddleware";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import { CircularProgress } from "@material-ui/core";
import { setSnackbarData } from "../../../store/actions/globalAction";

const RenderIconWithText = ({ icon, text }) => {
  const breakpoint = useBreakpoint();
  return (
    <span className="flexer">
      <img
        className={isMdOrBelowBreakpoint(breakpoint) ? "mr-1" : "mr-2"}
        src={icon}
        alt={text}
      />{" "}
      {text}
    </span>
  );
};

const Tabs = (props) => {
  const {
    // Redux props
    userData,
    currentSync,
    isCcbToMcIntegrationEnabled,
    enableTwoWaySyncingStatus,
    warnings,
    updateProfileStatus,
    enableTwoWaySyncingFor,
    disableTwoWaySyncingStatus,
    disableTwoWaySyncingFor,
    enableTwoWaySyncingErrorMsg,

    // Redux functions
    changeSyncType,
    enableTwoWaySyncing,
    disableTwoWaySyncing,
    clearEnableTwoWaySync,
    updateUserProfile,
    clearUpdateProfile,
    clearDisableTwoWaySync,
    setSnackbarData,
  } = props;

  const loadingForCcbToMcSwitch = useMemo(
    () =>
      (enableTwoWaySyncingFor === CCB_TO_MC ||
        disableTwoWaySyncingFor === "isCcbToMcIntegrationEnabled") &&
      [enableTwoWaySyncingStatus, disableTwoWaySyncingStatus].includes(
        "loading"
      ),
    [
      disableTwoWaySyncingFor,
      disableTwoWaySyncingStatus,
      enableTwoWaySyncingFor,
      enableTwoWaySyncingStatus,
    ]
  );
  const loadingForMcToCcbSwitch = useMemo(
    () =>
      (enableTwoWaySyncingFor === MC_TO_CCB ||
        disableTwoWaySyncingFor === "isMcToCcbIntegrationEnabled" ||
        updateProfileStatus["isSkipMcToCcb"] === "loading") &&
      [enableTwoWaySyncingStatus, disableTwoWaySyncingStatus].includes(
        "loading"
      ),
    [
      disableTwoWaySyncingFor,
      disableTwoWaySyncingStatus,
      enableTwoWaySyncingFor,
      enableTwoWaySyncingStatus,
      updateProfileStatus,
    ]
  );
  const breakpoint = useBreakpoint();

  const resetStatus = useCallback(
    (requestId) => () => {
      clearUpdateProfile(requestId);
    },
    [clearUpdateProfile]
  );

  useEffect(() => {
    if (enableTwoWaySyncingErrorMsg) {
      clearEnableTwoWaySync();
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(enableTwoWaySyncingErrorMsg) || "",
      });
    }
  }, [clearEnableTwoWaySync, enableTwoWaySyncingErrorMsg, setSnackbarData]);

  useEffect(() => {
    if (updateProfileStatus["isSkipMcToCcb"] === "success") {
      resetStatus("isSkipMcToCcb");
    }
  }, [resetStatus, updateProfileStatus]);

  useEffect(() => {
    if (disableTwoWaySyncingStatus === "success") {
      clearDisableTwoWaySync();
      if (disableTwoWaySyncingFor === "isMcToCcbIntegrationEnabled") {
        const formData = formDataWithApiKey();
        formData.append("isSkipMcToCcb", 1);
        updateUserProfile(formData, "isSkipMcToCcb");
      }
    }
  }, [
    clearDisableTwoWaySync,
    disableTwoWaySyncingFor,
    disableTwoWaySyncingStatus,
    updateUserProfile,
  ]);

  useEffect(() => {
    if (enableTwoWaySyncingStatus === "success") {
      clearEnableTwoWaySync();
      if (enableTwoWaySyncingFor === MC_TO_CCB) {
        const formData = formDataWithApiKey();
        formData.append("isSkipMcToCcb", 0);
        updateUserProfile(formData, "isSkipMcToCcb");
      }
    }
  }, [
    clearEnableTwoWaySync,
    enableTwoWaySyncingFor,
    enableTwoWaySyncingStatus,
    updateUserProfile,
  ]);

  const handleCcbToMcToggleChange = useCallback(
    ({ target: { checked } }) => {
      const formData = formDataWithApiKey();
      if (Number(userData?.isSkipMcToCcb) === 0 && checked) {
        enableTwoWaySyncing(formData, CCB_TO_MC);
      } else {
        disableTwoWaySyncing(formData, "isCcbToMcIntegrationEnabled");
      }
    },
    [disableTwoWaySyncing, enableTwoWaySyncing, userData]
  );

  const handleMcToCcbToggleChange = useCallback(
    ({ target: { checked } }) => {
      const formData = formDataWithApiKey();
      if (checked) {
        enableTwoWaySyncing(formData, MC_TO_CCB);
      } else {
        disableTwoWaySyncing(formData, "isMcToCcbIntegrationEnabled");
      }
    },
    [disableTwoWaySyncing, enableTwoWaySyncing]
  );

  const renderExclamanationIcon = useMemo(() => {
    return (
      <ErrorRounded htmlColor="#FF5F0A" fontSize="inherit" className="mr-2" />
    );
  }, []);

  const renderArrowForwardIcon = useMemo(() => {
    return (
      <ArrowForward
        className={
          isMdOrBelowBreakpoint(breakpoint) ? "mr-2 ml-2" : "mr-3 ml-3"
        }
        color={"white"}
        fontSize={"small"}
      />
    );
  }, [breakpoint]);

  return (
    <Fragment>
      <div
        onClick={() => changeSyncType(CCB_TO_MC)}
        className={`tab ${currentSync === CCB_TO_MC ? "active" : ""}`}
      >
        {loadingForCcbToMcSwitch ? (
          <CircularProgress color="inherit" className="mr-3" size={20} />
        ) : (
          <CustomSwitch
            onChange={handleCcbToMcToggleChange}
            checked={isCcbToMcIntegrationEnabled}
            className={Number(warnings.ccbToMcConflict) >= 1 ? "mr-2" : "mr-3"}
          />
        )}
        {Number(warnings.ccbToMcConflict) >= 1 && renderExclamanationIcon}
        <RenderIconWithText icon={CcbIcon} text={"CCB"} />
        {renderArrowForwardIcon}
        <RenderIconWithText icon={MailchimpIcon} text={"MailChimp"} />
      </div>
      <div
        onClick={() => changeSyncType(MC_TO_CCB)}
        className={`tab ${currentSync === MC_TO_CCB ? "active" : ""}`}
      >
        {loadingForMcToCcbSwitch ? (
          <CircularProgress color="inherit" className="mr-3" size={20} />
        ) : (
          <CustomSwitch
            onChange={handleMcToCcbToggleChange}
            checked={Number(userData.isSkipMcToCcb) === 0}
            className={Number(warnings.mcToCcbConflict) >= 1 ? "mr-2" : "mr-3"}
          />
        )}
        {Number(warnings.mcToCcbConflict) >= 1 && renderExclamanationIcon}
        <RenderIconWithText icon={MailchimpIcon} text={"MailChimp"} />
        {renderArrowForwardIcon}
        <RenderIconWithText icon={CcbIcon} text={"CCB"} />
      </div>
    </Fragment>
  );
};

Tabs.propTypes = {
  currentSync: PropTypes.string.isRequired,
  changeSyncType: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    currentSync: store.integrations.currentSync,
    enableTwoWaySyncingFor: store.integrations.enableTwoWaySyncingFor,
    enableTwoWaySyncingStatus: store.integrations.enableTwoWaySyncingStatus,
    isCcbToMcIntegrationEnabled: store.integrations.isCcbToMcIntegrationEnabled,
    isMcToCcbIntegrationEnabled: store.integrations.isMcToCcbIntegrationEnabled,
    updateProfileStatus: store.userReducer.updateProfileStatus,
    warnings: store.userReducer.warnings,
    disableTwoWaySyncingStatus: store.integrations.disableTwoWaySyncingStatus,
    disableTwoWaySyncingFor: store.integrations.disableTwoWaySyncingFor,
    enableTwoWaySyncingErrorMsg: store.integrations.enableTwoWaySyncingErrorMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableTwoWaySyncing: (data, toggleIdentity) =>
      dispatch(enableTwoWaySyncing(data, toggleIdentity)),
    disableTwoWaySyncing: (data, toggleIdentity) =>
      dispatch(disableTwoWaySyncing(data, toggleIdentity)),
    changeSyncType: (sync) => dispatch(changeSyncType(sync)),
    clearEnableTwoWaySync: () => dispatch(clearEnableTwoWaySync()),
    clearDisableTwoWaySync: () => dispatch(clearDisableTwoWaySync()),
    updateUserProfile: (data, requestId) =>
      dispatch(updateUserProfile(data, requestId)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearUpdateProfile: (requestId) => dispatch(clearUpdateProfile(requestId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
