export const INACTIVE_ACCOUNTS_CLEANUP_SUPPORT_URL =
  "https://support.ccbchimp.com/en/articles/2105610-inactive-accounts-cleanup";
export const AUTOMATIC_CLEANUP_SYNC = "Automatic Cleanup Sync";

export const UNPAID_ACC_DETAILS = {
  name: "No Current Plan",
  amountDisplay: "0",
  description: "",
};

export const MEMBERSHIP_STATUS_TYPES_URL =
  "https://support.ccbchimp.com/en/articles/5171169-v2-toolkit-include-the-following-membership-status-types-when-syncing";

export const MERGE_FIELD_ERROR_SUPPORT_URL =
  "https://support.ccbchimp.com/en/articles/6872122-merge-field-error-required-custom-fields";

export const AUTO_SYNCING_DESCRIPTION =
  "Once enabled, our platform will automatically sync your CCB data to Mailchimp based on your plan. Starter (Once per week - Monday mornings). Med (Once per day - mornings). Pro (Twice per day - mornings/evenings).";

export const MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_DISABLE =
  "Disable this Sync Pair? Once Disabled, our platform will not remove unsubscribed MailChimp email addresses from the corresponding CCB Group";

export const MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_ENABLE =
  "Enable this Sync Pair? Once Enabled, our platform will remove unsubscribed MailChimp email addresses from the corresponding CCB Group";

export const HELPDESK_LINK = `https://support.ccbchimp.com/en/`;
export const FACEBOOK_GROUP_LINK = `https://www.facebook.com/groups/338551669892662`;
