import React from "react";
import { Checkbox } from "@material-ui/core";

const SelectMcTagsTableRow = (props) => {
  const { data, handleCheck } = props;
  return (
    <tr>
      <td className="flexer-start font-14">
        <Checkbox
          checked={data.active}
          onChange={(event) => handleCheck(data)}
          disabled={data?.isUnselectable}
          className="mr-2 p-0"
          style={{
            color: `${data?.isUnselectable ? "rgb(190, 187, 187)" : "#2db8e8"}`,
          }}
        />
        {data.name}
      </td>
      {/* <td className="font-14">{data.tagState}</td> */}
    </tr>
  );
};

SelectMcTagsTableRow.propTypes = {};

SelectMcTagsTableRow.defaultProps = {};

export default SelectMcTagsTableRow;
