import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close, Add } from "@material-ui/icons";
import MaterialButton from "../../../ MaterialButton/MaterialButton";
import TableInfinityScroll from "../../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../../Table/TableHeader/TableHeader";
import SelectMcTagsTableBody from "./SlectMcTagsTableBody";
import { useLocalSearch } from "../../../../utils/hooks/useLocalSearch";
import { getUniqueId } from "../../../../helpers";
import { isMdOrBelowBreakpoint } from "../../../../utils/breakpoints";
import useBreakpoint from "../../../../utils/hooks/useBreakpoint";
import "../../../../styles/components/more/integrations.scss";

const useStyles = makeStyles({
  dialog: {
    maxHeight: 480,
  },
});

const cells = [
  {
    text: "Tag Name",
  },
  // {
  //   text: "Tag Type",
  // },
];

const SelectMcTagsModal = (props) => {
  const { open, handleClose, tags, handleCheck, appendNewTag } = props;
  const breakpoint = useBreakpoint();
  const [mcTags, setMcTags] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const { onChange } = useLocalSearch(
    tags,
    setMcTags,
    setSearchValue,
    (element) => `${element.name}`
  );

  useEffect(() => {
    setMcTags(tags);
  }, [tags]);

  const handleAppendNewTag = () => {
    const newTag = {
      name: searchValue,
      active: true,
      id: getUniqueId(1000),
    };
    appendNewTag(newTag);
    setSearchValue("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint)
          ? "m-0 vw-100"
          : classes.dialog,
      }}
    >
      <DialogTitle className="flexer-space-between" id="alert-dialog-title">
        <span className="modal-main-heading">Select MailChimp Tags</span>
        <Close onClick={handleClose} className="close-icon" />
      </DialogTitle>

      <DialogContent>
        <FormControl>
          <TextField
            id="input-with-icon-textfield"
            placeholder={"Search Tags"}
            className="mb-4"
            variant="outlined"
            value={searchValue || ""}
            onChange={onChange}
            InputProps={{
              classes: {
                notchedOutline: "fieldset",
                input: "modal-search",
              },
            }}
          />
        </FormControl>
        {!Boolean(mcTags.length) && !searchValue && (
          <Typography className="text-muted text-center" variant="h5">
            No tags found
          </Typography>
        )}
        {Boolean(mcTags.length) && (
          <TableInfinityScroll
            className="tag-table-container"
            loadMore={() => {}}
            fetchFunction={() => {}}
          >
            <table className="table">
              <TableHeader
                className="moadal-table-header"
                innerClassName="mc-tags-modal-header-container"
                cells={cells}
                style={{ padding: 5, border: "none" }}
                hideCheckBoxCell={true}
              />
              <SelectMcTagsTableBody data={mcTags} handleCheck={handleCheck} />
            </table>
          </TableInfinityScroll>
        )}
        {searchValue && !Boolean(mcTags.length) && (
          <Typography
            onClick={handleAppendNewTag}
            variant="subtitle1"
            className="select-mc-tag font-weight-bold"
          >
            <Add fontSize="small" /> Create Tag "{searchValue}"
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="mb-3 mr-3">
        <MaterialButton
          title="Confirm"
          className="w-25"
          onClick={() => handleClose(true)}
          disabled={!Boolean(mcTags.length)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SelectMcTagsModal;
