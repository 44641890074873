import { convertFormdataToJson } from "../../../helpers";
import { ACTION_TYPES } from "../../actions/types";

const initialState = {
  // Admin users
  users: [],
  usersStatus: "",
  usersPage: null,
  usersLoadedAll: null,
  usersLoadingMoreStatus: null,

  // Pending users
  pendingUsers: [],
  pendingUsersStatus: "",
  pendingUsersPage: null,
  pendingUsersLoadedAll: null,
  pendingUsersLoadingMoreStatus: null,

  // Create user
  createUserStatus: null,
  successMessage: null,
  errorMessage: null,

  // Approve user
  approveUserStatus: null,
  approveUserId: null,
};

const adminUsersReducer = (state = initialState, action) => {
  let users = null;
  let pendingUsers = null;
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_USERS: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        usersStatus: "loading",
        usersLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      };
    }

    case ACTION_TYPES.GET_ALL_USERS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.users) {
        users = action.payload.data;
      } else {
        users = state.users.concat(action.payload.data);
      }
      return {
        ...state,
        usersStatus: "success",
        users: users,
        usersPage: formData.page,
        usersLoadingMoreStatus: formData.page > 1 ? "success" : null,
        usersLoadedAll: action.payload.data.length === 0,
      };
    }

    case ACTION_TYPES.GET_ALL_USERS_ERROR: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        usersStatus: "error",
        usersLoadedAll: null,
        usersPage: null,
        usersLoadingMoreStatus: formData.page > 1 ? "error" : null,
      };
    }

    case ACTION_TYPES.GET_PENDING_USERS: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        pendingUsersStatus: "loading",
        pendingUsersLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      };
    }

    case ACTION_TYPES.GET_PENDING_USERS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.pendingUsers) {
        pendingUsers = action.payload.data;
      } else {
        pendingUsers = state.pendingUsers.concat(action.payload.data);
      }
      return {
        ...state,
        pendingUsersStatus: "success",
        pendingUsers: pendingUsers,
        pendingUsersPage: formData.page,
        pendingUsersLoadingMoreStatus: formData.page > 1 ? "success" : null,
        pendingUsersLoadedAll: action.payload.data.length === 0,
      };
    }

    case ACTION_TYPES.GET_PENDING_USERS_ERROR: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        pendingUsersStatus: "error",
        pendingUsersLoadedAll: null,
        pendingUsersPage: null,
        pendingUsersLoadingMoreStatus: formData.page > 1 ? "error" : null,
      };
    }

    case ACTION_TYPES.CREATE_USER: {
      return {
        ...state,
        createUserStatus: "loading",
      };
    }

    case ACTION_TYPES.CREATE_USER_SUCCESS: {
      return {
        ...state,
        createUserStatus: "success",
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      };
    }

    case ACTION_TYPES.CREATE_USER_ERROR: {
      return {
        ...state,
        createUserStatus: "error",
      };
    }

    case ACTION_TYPES.CREATE_USER_CLEAR: {
      return {
        ...state,
        createUserStatus: null,
        successMessage: null,
        errorMessage: null,
      };
    }

    case ACTION_TYPES.APPROVE_USER: {
      return {
        ...state,
        approveUserStatus: "loading",
      };
    }

    case ACTION_TYPES.APPROVE_USER_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      return {
        ...state,
        approveUserStatus: "success",
        errorMessage:
          action.payload.status === 0 ? action.payload?.message : "",
        successMessage:
          action.payload.status === 1 ? action.payload?.message : "",
        approveUserId: formData.id,
      };
    }

    case ACTION_TYPES.APPROVE_USER_ERROR: {
      return {
        ...state,
        approveUserStatus: "error",
      };
    }

    case ACTION_TYPES.APPROVE_USER_CLEAR: {
      return {
        ...state,
        approveUserStatus: null,
        successMessage: null,
        errorMessage: null,
        approveUserId: null,
      };
    }

    default:
      return state;
  }
};

export default adminUsersReducer;
