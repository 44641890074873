import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import { withRouter } from "react-router";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  capitalizeFirstLetter,
  findLogByDetailsId,
  formatDateAndTime,
  formDataWithApiKey,
} from "../../../helpers";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import LogDetailsTableBody from "../LogDetails/LogDetailsTableBody";
import Loader from "../../Loader/Loader";
import { getLogStatistics } from "../../../store/middlewares/logsMiddleware";
import TabPanel from "../../TabPanel/TabPanel";
import LogBreadcrumb from "../LogBreadcrumb/LogBreadcrumb";
import { setLogsDetails } from "../../../store/actions/logsAction";
import api from "../../../services/api";
import { setSnackbarData } from "../../../store/actions/globalAction";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LogStatistics = (props) => {
  const {
    // Redux props
    logStatisticsStatus,
    logStatistics,
    logDetails,
    cleanupSync,

    // Redux functions
    getLogStatistics,
    setLogsDetails,
    setSnackbarData,

    // Component props
    history,
    match,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    location: { state },
  } = history;
  const filterTables = useMemo(
    () =>
      Object.keys(logStatistics).filter((item) =>
        Boolean(logStatistics[item].list.length)
      ),
    [logStatistics]
  );

  const getBreadCrumb = useCallback(() => {
    const breadcrumbs = [
      {
        text: `${logDetails.type} - ${formatDateAndTime(logDetails.date)}`,
        route: `/logs/details/${match.params.logId}`,
      },
      {
        text: `${state?.logDetails?.name}`,
        route: `/logs/details/${match.params.logId}/statistics/${match.params.detailId}`,
      },
    ];
    return breadcrumbs;
  }, [logDetails, match, state]);

  const getTableCells = useCallback((cell, count) => {
    const cells = [
      {
        text: `${cell} (${count})`,
      },
    ];
    return cells;
  }, []);

  const errorSnackbar = useCallback(() => {
    setSnackbarData({
      snackbarSeverity: "error",
      showSnackbar: true,
      snackbarMessage: "Something went wrong, please try agan later!",
    });
  }, [setSnackbarData]);

  useEffect(() => {
    if (lodash.isEmpty(state)) {
      history.push("/logs");
    }
  }, [history, logDetails, state]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("id", match.params.detailId);
    getLogStatistics(formData, null);
  }, [getLogStatistics, match]);

  const navigateToCleanupSync = useCallback(
    async (currentLogStatistics) => {
      const formData = formDataWithApiKey();
      formData.append("id", cleanupSync.id);
      try {
        setLoading(true);
        const responseBack = await api.getLogDetails(formData);
        setLoading(false);
        if (responseBack.status === 1) {
          const ccbAndMcLogs = [
            ...responseBack.data?.ccbToMc,
            ...responseBack.data?.mcToCcb,
          ];
          const detailsLog = findLogByDetailsId(
            ccbAndMcLogs,
            currentLogStatistics?.cleanUpDetailId
          );
          history.push(
            `/logs/details/${cleanupSync.id}/statistics/${currentLogStatistics.cleanUpDetailId}`,
            { logDetails: detailsLog }
          );
          setLogsDetails({ log: cleanupSync, cleanupSync: {} });
        } else {
          errorSnackbar();
        }
      } catch (error) {
        setLoading(false);
        errorSnackbar();
      }
    },
    [cleanupSync, errorSnackbar, history, setLogsDetails]
  );

  const renderTable = useCallback(
    (data, tableCells, tableType, item) => {
      return (
        <TableInfinityScroll
          className="logs-modal-table"
          loadMore={() => {}}
          fetchFunction={() => {}}
        >
          <table className="table">
            <TableHeader
              className="user-settings-greetings-table-header"
              cells={tableCells}
              innerClassName={"log-statistics-header"}
            />
            <LogDetailsTableBody
              data={data}
              handleItemClicked={() => {}}
              tableType={tableType}
              tabIdentity={item}
              navigateToCleanupSync={navigateToCleanupSync}
            />
          </table>
        </TableInfinityScroll>
      );
    },
    [navigateToCleanupSync]
  );

  const renderLogStatistics = useCallback(() => {
    return filterTables.map((item, index) => {
      return (
        Boolean(logStatistics[item].list.length) && (
          <TabPanel value={activeTab} index={index}>
            <div className="flexer-column mt-4">
              <div className="user-settings-greetings">
                {renderTable(
                  logStatistics[item].list,
                  getTableCells(item, logStatistics[item].count),
                  "logStatistics",
                  item
                )}
              </div>
            </div>
          </TabPanel>
        )
      );
    });
  }, [activeTab, filterTables, getTableCells, logStatistics, renderTable]);

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Log Statistics</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <LogBreadcrumb
              breadcrumbs={
                state?.isBreadcrumbEnable
                  ? state?.breadcrumbsFromRoute
                  : getBreadCrumb()
              }
              isBreadcrumbEnable={state?.isBreadcrumbEnable}
              breadcrumbsFromRoute={
                state &&
                !lodash.isEmpty(state?.breadcrumbsFromRoute) &&
                Array.isArray(state?.breadcrumbsFromRoute)
                  ? state?.breadcrumbsFromRoute
                  : []
              }
            />
          </div>

          {Boolean(filterTables.length) && (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="standard"
                aria-label="secondary tabs example"
              >
                {filterTables.map((item, index) => (
                  <Tab
                    value={index}
                    label={`${capitalizeFirstLetter(item)} (${
                      logStatistics[item].count
                    })`}
                    style={{ fontSize: 14, fontWeight: 600 }}
                  />
                ))}
              </Tabs>
            </Box>
          )}

          {renderLogStatistics()}
        </DialogContent>
      </Dialog>
      {(logStatisticsStatus === "loading" || loading) && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    logStatisticsStatus: store.logs.logStatisticsStatus,
    logStatistics: store.logs.logStatistics,
    logDetails: store.logs.logDetails,
    cleanupSync: store.logs.cleanupSync,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogStatistics: (data, requestId) =>
      dispatch(getLogStatistics(data, requestId)),
    setLogsDetails: (log) => dispatch(setLogsDetails(log)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogStatistics));
