import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { connect } from "react-redux";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";
import SyncingProgressBar from "./components/SyncPageComponents/SyncingProgressBar/SyncingProgressBar";
import MobileHeader from "./components/MobileDevicesComponents/MobileHeader/MobileHeader";
import PageSubMenu from "./components/PageSubMenu/PageSubMenu";
import Sidebar from "./components/Sidebar/Sidebar";
import TopNotficationBanner from "./components/TopNotificationBanner/TopNotificationBanner";
import { formDataWithApiKey, getMobileHeaderProps } from "./helpers";
import services from "./services";
import {
  clearStopSyncStatus,
  clearSyncManualStatus,
} from "./store/actions/integrationActions";
import {
  clearCheckSync,
  clearIntialSync,
  userLoginSuccess,
} from "./store/actions/userActions";
import { getCurrentPlan } from "./store/middlewares/billingMiddleware";
import {
  checkAutoSyncingStatus,
  checkSyncingStatus,
  getUserApiDetails,
  getWarnings,
} from "./store/middlewares/userMiddleware";
import { CheckSyncing } from "./utils/hooks/checkSyncing";
import { CheckAutoSyncing } from "./utils/hooks/checkAutoSyncing";
import ConfirmationModal from "./components/modals/ConfirmationModal/ConfirmationModal";
import {
  getCcbCampuses,
  getCcbSavedSearches,
  getMailchimpAudience,
  getMcToCcbPairs,
  stopSync,
} from "./store/middlewares/integrationMiddleware";
import {
  INITIAL_SYNCING_IN_PROGRESS,
  SYNCING_IN_PROGRESS,
} from "./utils/integrationsConstants";
import useToolsStore from "./store/reduxStore/toolsStore";
import { toggleCardDeclineModal } from "./store/actions/billingActions";
import RenderTopNotificationBannerOverlayApp from "./HelperComponents/RenderTopNotificationBannerOverlayApp";

const {
  storageManager: { local },
  api,
} = services;

const withoutHeader = ["/dashboard", "/sync", "/subscription-plans"];

const PrivateRoute = ({
  component: Component,
  history,
  isLoggedIn,
  hidePageMenu,
  hideSidebar,

  // Redux props
  userApiDetails,
  syncManualStatus,
  syncingInProgress,
  autoSyncingStatus,
  currentPlan,
  runInitialSync,
  userData,
  currentPlanStatus,
  apiDetailsStatus,
  stopSyncStatus,
  warnings,
  warningsStatus,
  checkSyncStatus,
  syncJobAddedToQueue,

  // REDUX FUNCTIONS
  clearSyncManualStatus,
  clearCheckSync,
  checkSyncingStatus,
  getUserApiDetails,
  getCurrentPlan,
  checkAutoSyncingStatus,
  getMailchimpAudience,
  getCcbCampuses,
  getCcbSavedSearches,
  getMcToCcbPairs,
  clearIntialSync,
  getWarnings,
  stopSync,
  clearStopSyncStatus,
  toggleCardDeclineModal,
  setUserData,
  ...rest
}) => {
  const [showAutoSyncConfirmationModal, setShowAutoSyncConfirmationModal] =
    useState(false);
  const [showStopSyncConfirmationModal, setShowStopSyncConfirmationModal] =
    useState(false);
  const { saveAutoSync, tools } = useToolsStore();
  const { saveAutoSyncStatus } = tools;

  // Router
  const location = useLocation();

  const getMissingIntegrationName = useMemo(() => {
    if (!userApiDetails.ccbUsername && !userApiDetails.mailchimpApiKey) {
      return "CCB and Mailchimp";
    } else if (!userApiDetails.ccbUsername) {
      return "CCB";
    } else if (!userApiDetails.mailchimpApiKey) {
      return "Mailchimp";
    }
    return "";
  }, [userApiDetails]);

  const isLogin = useCallback(() => {
    if (local.get("token") && isLoggedIn) {
      return true;
    }

    return false;
  }, [isLoggedIn]);

  const toggleStopSyncConfirmationModal = useCallback(() => {
    setShowStopSyncConfirmationModal(!showStopSyncConfirmationModal);
  }, [showStopSyncConfirmationModal]);

  const callIntegrationPageApis = useCallback(() => {
    const formData = formDataWithApiKey();
    // Api's calls
    // Mailchimp
    getMailchimpAudience(formData);

    // CCB
    getCcbCampuses(formData);
    getCcbSavedSearches(formData);
    getMcToCcbPairs(formData);

    // Current plan
    if (lodash.isEmpty(currentPlan) && !currentPlanStatus) {
      getCurrentPlan(formData);
    }
  }, [
    currentPlan,
    currentPlanStatus,
    getCcbCampuses,
    getCcbSavedSearches,
    getCurrentPlan,
    getMailchimpAudience,
    getMcToCcbPairs,
  ]);

  const resetSyncState = useCallback(() => {
    if (checkSyncStatus != null) {
      clearSyncManualStatus();
      clearCheckSync();
      if (
        history.location.pathname === "/sync" &&
        Number(checkSyncStatus) === 1
      ) {
        callIntegrationPageApis();
      }
    }
  }, [
    callIntegrationPageApis,
    checkSyncStatus,
    clearCheckSync,
    clearSyncManualStatus,
    history,
  ]);

  const setUserDataInLocalStorage = useCallback(
    async (apiKeyFromParam) => {
      try {
        const formData = new FormData();
        formData.append("apiKey", apiKeyFromParam);
        const response = await api.getUserDetails(formData);
        if (response.status === 1) {
          local.set("user", JSON.stringify(response.data));
          local.set("token", response.data.apiKey);
          setUserData(response.data);
        }
      } catch (error) {
        console.log("INSIDE ERROR", error);
      }
    },
    [setUserData]
  );

  useEffect(() => {
    // Check if user is logged in and token is present in local storage
    if (location?.pathname?.includes("activate-account")) {
      const urlToken = location?.pathname?.split("/").pop();
      const localStorageToken = local.get("token");
      if (!isLogin() || urlToken !== localStorageToken) {
        setUserDataInLocalStorage(urlToken);
      }
    }
  }, [isLogin, location, setUserDataInLocalStorage]);

  useEffect(() => {
    if (stopSyncStatus === "success") {
      clearStopSyncStatus();
      resetSyncState();
      clearIntialSync();
      toggleStopSyncConfirmationModal();
    }
  }, [
    clearIntialSync,
    clearStopSyncStatus,
    resetSyncState,
    stopSyncStatus,
    toggleStopSyncConfirmationModal,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getWarnings(formData);
  }, [getWarnings]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (lodash.isEmpty(userApiDetails) && !apiDetailsStatus) {
      getUserApiDetails(formData);
    }
    if (lodash.isEmpty(currentPlan) && !currentPlanStatus) {
      getCurrentPlan(formData);
    }
  }, [
    apiDetailsStatus,
    currentPlan,
    currentPlanStatus,
    getCurrentPlan,
    getUserApiDetails,
    userApiDetails,
  ]);

  const { isSyncingInProgress } = CheckSyncing(
    syncManualStatus,
    syncingInProgress,
    resetSyncState,
    checkSyncingStatus,
    autoSyncingStatus,
    () => {}
  );

  const { isAutoSyncingInProgress } = CheckAutoSyncing(
    autoSyncingStatus,
    checkAutoSyncingStatus,
    setShowAutoSyncConfirmationModal,
    history.location.pathname === "/sync",
    clearIntialSync,
    runInitialSync
  );

  const hasBrokenSyncPairs = useMemo(
    () =>
      !isAutoSyncingInProgress &&
      !isSyncingInProgress &&
      warningsStatus === "success" &&
      (Number(warnings.ccbToMcConflict) >= 1 ||
        Number(warnings.mcToCcbConflict) >= 1),
    [isSyncingInProgress, warnings, warningsStatus, isAutoSyncingInProgress]
  );

  const closeAutoSyncConfirmationModal = useCallback(() => {
    setShowAutoSyncConfirmationModal(false);
  }, []);

  const { title, backRoute } = getMobileHeaderProps(history.location?.pathname);

  const getNotificationBannerCndition = useCallback(() => {
    if (
      location?.pathname !== "/integrations" &&
      apiDetailsStatus !== "loading" &&
      (!userApiDetails.ccbUsername || !userApiDetails.mailchimpApiKey)
    ) {
      return true;
    } else if (hasBrokenSyncPairs) {
      return true;
    }
    return false;
  }, [apiDetailsStatus, hasBrokenSyncPairs, userApiDetails, location]);

  const onClickedSyncConfirmation = useCallback(() => {
    if (history.location.pathname === "/sync") {
      callIntegrationPageApis();
      closeAutoSyncConfirmationModal();
    } else {
      closeAutoSyncConfirmationModal();
    }
  }, [callIntegrationPageApis, closeAutoSyncConfirmationModal, history]);

  const getSyncMessage = useMemo(() => {
    if (syncJobAddedToQueue) {
      return "Sync task added to queue";
    } else if (isSyncingInProgress) {
      return SYNCING_IN_PROGRESS;
    } else if (isAutoSyncingInProgress) {
      return INITIAL_SYNCING_IN_PROGRESS;
    }
    return "";
  }, [isAutoSyncingInProgress, isSyncingInProgress, syncJobAddedToQueue]);

  const handleStopSync = useCallback(() => {
    const formData = formDataWithApiKey();
    stopSync(formData);
  }, [stopSync]);

  const onClickEnableAutoSyncing = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("val", 1);
    saveAutoSync(formData);
  }, [saveAutoSync]);

  const onClickedRepurchasePlan = useCallback(() => {
    history.push("/billing");
    toggleCardDeclineModal(true);
  }, [history, toggleCardDeclineModal]);

  const getNotficationBannerProps = useCallback(() => {
    const bannerProps = {
      text: ``,
      buttonText: "",
      onClickButton: () => {},
      color: "",
      hasExclamationIcon: false,
      className: "",
    };
    if (
      location?.pathname !== "/integrations" &&
      (!userApiDetails.ccbUsername || !userApiDetails.mailchimpApiKey)
    ) {
      return Object.assign(bannerProps, {
        text: `Syncing has been disabled. We are missing your ${getMissingIntegrationName} API settings.`,
        buttonText: "Click here to finish setup",
        onClickButton: () => history.push("/integrations"),
        color: "red",
        hasExclamationIcon: true,
        onlyShowOnDashboard: false,
      });
    } else if (userData.isDisableSync) {
      return Object.assign(bannerProps, {
        text: `Automatic / manual syncing has been disabled.`,
        buttonText: "Click here to enable syncing",
        onClickButton: () => history.push("/integrations/setup-ccb"),
        color: "red",
        hasExclamationIcon: true,
        onlyShowOnDashboard: false,
      });
    } else if (Number(userData?.isAutoSync) === 0) {
      return Object.assign(bannerProps, {
        text: `Automatic syncing has been disabled.`,
        buttonText: "Click here to enable",
        onClickButton: onClickEnableAutoSyncing,
        color: "red",
        hasExclamationIcon: true,
        onlyShowOnDashboard: false,
        isLoading: saveAutoSyncStatus === "loading",
      });
    } else if (hasBrokenSyncPairs) {
      return Object.assign(bannerProps, {
        text: `You have ${
          Number(warnings.ccbToMcConflict) + Number(warnings.mcToCcbConflict)
        } broken sync pairs. Syncing will skip for those`,
        buttonText: "View and Resolve Broken Sync Pairs",
        onClickButton: () => history.push("/sync"),
        color: "red",
        hasExclamationIcon: false,
        className: "flexer",
        onlyShowOnDashboard: true,
      });
    } else if ([1, 2].includes(warnings?.hasBillingIssue)) {
      return Object.assign(bannerProps, {
        text: `Don’t delay - pay your overdue plan now or risk cancellation!`,
        buttonText: "Repurchase Plan",
        onClickButton: onClickedRepurchasePlan,
        color: "red",
        hasExclamationIcon: true,
        onlyShowOnDashboard: false,
      });
    }
    return bannerProps;
  }, [
    history,
    userApiDetails,
    userData,
    hasBrokenSyncPairs,
    warnings,
    onClickEnableAutoSyncing,
    saveAutoSyncStatus,
    onClickedRepurchasePlan,
    getMissingIntegrationName,
    location,
  ]);

  const renderNotificationBanner = useCallback(() => {
    if (!userData.isActivated) return null;
    const bannerCondition =
      getNotificationBannerCndition() ||
      userData.isDisableSync ||
      Number(userData?.isAutoSync) === 0 ||
      ([1, 2].includes(warnings?.hasBillingIssue) &&
        location?.pathname !== "/subscription-plans");

    const bannerProps = getNotficationBannerProps();

    if (
      bannerCondition &&
      !isSyncingInProgress &&
      !isAutoSyncingInProgress &&
      bannerProps.onlyShowOnDashboard &&
      location?.pathname !== "/dashboard"
    )
      return null;

    if (bannerCondition && !isSyncingInProgress && !isAutoSyncingInProgress) {
      return (
        <RenderTopNotificationBannerOverlayApp show={bannerCondition}>
          <TopNotficationBanner {...bannerProps} />
        </RenderTopNotificationBannerOverlayApp>
      );
    }

    return null;
  }, [
    getNotficationBannerProps,
    getNotificationBannerCndition,
    isSyncingInProgress,
    userData,
    warnings,
    location,
    isAutoSyncingInProgress,
  ]);

  return (
    <div>
      {renderNotificationBanner()}
      {(isSyncingInProgress || isAutoSyncingInProgress) && (
        <RenderTopNotificationBannerOverlayApp
          show={isSyncingInProgress || isAutoSyncingInProgress}
        >
          <SyncingProgressBar
            isCircularProgress={isAutoSyncingInProgress}
            message={getSyncMessage}
            onClickCancelSync={toggleStopSyncConfirmationModal}
            syncJobAddedToQueue={syncJobAddedToQueue}
          />
        </RenderTopNotificationBannerOverlayApp>
      )}
      {!withoutHeader.includes(history.location?.pathname) && (
        <MobileHeader title={title} backRoute={backRoute} />
      )}
      {!hideSidebar && <Sidebar />}
      {!hidePageMenu && <PageSubMenu />}
      <Route
        {...rest}
        render={(props) => {
          return isLogin() ? (
            <div className={`${!hidePageMenu ? "main-wrapper" : ""}`}>
              <Component {...props} />
            </div>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }}
      />

      {/* Confirmation modal after intial sync finished */}
      {showAutoSyncConfirmationModal && (
        <ConfirmationModal
          open={showAutoSyncConfirmationModal}
          handleClose={closeAutoSyncConfirmationModal}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30 mr-3"}
          modalHeading={"Confirm"}
          modalContent={
            "Syncing has completed successfully. It may take up to 30 minutes to appear on Mailchimp."
          }
          onClick={onClickedSyncConfirmation}
        />
      )}

      {/* Confirmation modal for stop sync */}
      {showStopSyncConfirmationModal && (
        <ConfirmationModal
          open={showStopSyncConfirmationModal}
          handleClose={toggleStopSyncConfirmationModal}
          btnLabel={"Confirm"}
          modalHeading={"Confirm"}
          btnClassName={"bg-yellow w-30 mr-3"}
          modalContent={"Are you sure want to stop sync procress?"}
          onClick={handleStopSync}
          loading={stopSyncStatus === "loading"}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSyncManualStatus: () => dispatch(clearSyncManualStatus()),
    clearCheckSync: () => dispatch(clearCheckSync()),
    getUserApiDetails: (data) => dispatch(getUserApiDetails(data)),
    checkSyncingStatus: (data) => dispatch(checkSyncingStatus(data)),
    getCurrentPlan: (data) => dispatch(getCurrentPlan(data)),
    checkAutoSyncingStatus: (data) => dispatch(checkAutoSyncingStatus(data)),
    getMailchimpAudience: (data) => dispatch(getMailchimpAudience(data)),
    getCcbCampuses: (data) => dispatch(getCcbCampuses(data)),
    getCcbSavedSearches: (data) => dispatch(getCcbSavedSearches(data)),
    getMcToCcbPairs: (data) => dispatch(getMcToCcbPairs(data)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    stopSync: (data) => dispatch(stopSync(data)),
    toggleCardDeclineModal: (val) => dispatch(toggleCardDeclineModal(val)),
    setUserData: (userData) => dispatch(userLoginSuccess(userData)),
    clearIntialSync: () => dispatch(clearIntialSync()),
    clearStopSyncStatus: () => dispatch(clearStopSyncStatus()),
  };
};

const mapStateToProps = (store) => {
  return {
    userApiDetails: store.userReducer.userApiDetails,
    warnings: store.userReducer.warnings,
    syncManualStatus: store.integrations.syncManualStatus,
    syncingInProgress: store.integrations.syncingInProgress,
    autoSyncingStatus: store.userReducer.autoSyncingStatus,
    currentPlan: store.billing.currentPlan,
    runInitialSync: store.userReducer.runInitialSync,
    userData: store.userReducer.userData,
    currentPlanStatus: store.billing.currentPlanStatus,
    apiDetailsStatus: store.userReducer.apiDetailsStatus,
    stopSyncStatus: store.integrations.stopSyncStatus,
    warningsStatus: store.userReducer.warningsStatus,
    checkSyncStatus: store.integrations.checkSyncStatus,
    syncJobAddedToQueue: store.integrations.syncJobAddedToQueue,
  };
};

PrivateRoute.propTypes = {
  hideSidebar: PropTypes.bool,
  hidePageMenu: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  hideSidebar: false,
  hidePageMenu: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoute));
