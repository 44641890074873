import { ReactComponent as LogsIcon } from "../../assets/icons/logs.svg";
import { Help } from "@material-ui/icons";
import LightTooltip from "../../HelperComponents/LightTooltip";
import {
  AUTO_SYNCING_DESCRIPTION,
  MEMBERSHIP_STATUS_TYPES_URL,
} from "../../utils/constants";

export const toolsData = {
  leftColTools: [
    {
      title: "Inactive Accounts Cleanup",
      description:
        "Once active, our script will prevent new inactive accounts from being stored on Mailchimp. If you turn this off, we will skip this process. Click on cleanup to remove any inactive accounts.",
      btnLabel: "Clean Now",
      isEnable: 1,
      hasSwitch: true,
      hasButton: true,
      isListTool: false,
      identity: "isToolkitCleanup",
      link: {
        icon: <LogsIcon />,
        label: "Logs",
      },
    },
    {
      title: "Select Primary Contacts",
      description:
        "Once enabled, our platform will assign any duplicate CCB profiles (by email) based on the family position, in the following order; Primary, if not found, then Spouse, if not found then Other. To change this association, click on the link below.",
      warning:
        "By doing this you agree to allow us to securely store your contacts (required for this feature).",
      btnLabel: "Set Primary Profiles",
      isEnable: 1,
      hasSwitch: true,
      hasButton: true,
      isListTool: false,
      identity: "isCheckPrimaryProfiles",
    },
    {
      title:
        "Update the Mailchimp Email Unsubscribed Status from CCB/PushPay (One-way)",
      description: `After enabling this feature, when a profile is flagged as "Email Unsubscribed" in CCB/PushPay, all corresponding profiles in Mailchimp will be marked as unsubscribed. This one-way process ensures that the status from CCB's "Email Unsubscribe" is appropriately updated in Mailchimp. Please note that this mechanism does not synchronize unsubscribes from Mailchimp newsletters back to CCB.`,
      warning: "",
      isEnable: 1,
      hasSwitch: true,
      hasButton: false,
      isListTool: false,
      identity: "is_check_received_email_from_ccb",
    },
    {
      title: "Include the following membership status types when syncing",
      helpIcon: (
        <LightTooltip
          arrow={true}
          placement="top"
          interactive={true}
          title={
            <span>
              The status is the current relationship the person has to the
              group. The "Requesting to join the group" participants are not yet
              members (because they have either not yet accepted the invitation
              to join the group or were not accepted into the group). We will
              sync the participants for all group based on the statuses choosen.
              The default and typical options are to sync just leader/assistant
              leaders and group members.{" "}
              <a href={MEMBERSHIP_STATUS_TYPES_URL} target="__blank">
                <u>Click here for more info</u>
              </a>
            </span>
          }
        >
          <Help className="ml-2" fontSize="small" />
        </LightTooltip>
      ),
      description: "",
      warning: "",
      btnLabel: "Save",
      isEnable: 1,
      hasSwitch: false,
      hasButton: true,
      isListTool: true,
      identity: "memberSettings",
    },
  ],
  rightColTools: [
    {
      title: "Individual Profiles / Additional Merge Fields",
      description:
        "Also turn on the option to add additional merge fields to Mailchimp e.g. birthday, anniversary etc. By doing this you agree to allow us to securely store your contacts (required for this feature).",
      btnLabel: "Edit Additional Merge Fields",
      isEnable: 1,
      hasSwitch: true,
      hasButton: true,
      isListTool: false,
      identity: "isStoreUserInfo",
    },
    {
      title: `Turn On Auto-Syncing`,
      description: AUTO_SYNCING_DESCRIPTION,
      btnLabel: "",
      isEnable: 1,
      hasSwitch: true,
      hasButton: false,
      isListTool: false,
      identity: "isAutoSync",
    },
    {
      title: `Skip Merge Validation`,
      description:
        "Skips the merge validation during syncing to Mailchimp to prevent merge field errors on contacts",
      btnLabel: "",
      isEnable: 1,
      hasSwitch: true,
      hasButton: false,
      isListTool: false,
      identity: "skipMergeValidation",
    },
    {
      title: `Remove Unsub Mailchimp Email from CCB Group`,
      description: `Once enabled, our platform will remove unsubscribed Mailchimp email addresses from the corresponding CCB group.`,
      btnLabel: "",
      isEnable: 1,
      hasSwitch: true,
      hasButton: false,
      isListTool: false,
      identity: "isMcToCcbDisableInactiveRemove",
      withMakeException: true,
    },
    {
      title:
        "Delete Corresponding MC Audience / Group when CCB Group / Saved Search is Deleted",
      description:
        "Once enabled, our platform will delete corresponding Mailchimp Audience (List) / Group when synced CCB Group / Saved Search is Deleted.",
      btnLabel: "Confirm Delete",
      isEnable: 1,
      hasSwitch: true,
      hasButton: true,
      isListTool: false,
      identity: "isDeleteMcListGroup",
    },
    {
      title: "From MC to CCB Ignored Emails",
      description:
        "View the list of email addresses you have chosen to ignore when syncing from Mailchimp to CCB.",
      btnLabel: "View Ignored Emails",
      isEnable: 1,
      hasSwitch: false,
      hasButton: true,
      isListTool: false,
      identity: "",
    },
  ],
};

export const inActiveTooltipCleanupDesc = `Easily remove inactive accounts that have made their way to Mailchimp. This is ideal for older accounts. Once this cleanup is activated, we will remove all inactive emails on MC and prevent this from happening in the future.`;
export const activeTooltipCleanupDesc = `Once active, our script will prevent new inactive accounts from being stored on Mailchimp. If you turn this off, we will skip this process. Click on cleanup to remove any inactive accounts.`;

export const membersSettings = [
  {
    id: "isIncludeJoinGroup",
    title: "Requesting to join the Group",
    checked: 1,
  },
  {
    id: "isIncludeLeads",
    title: "Group Leader/Assistant Leader",
    checked: 1,
  },
  {
    id: "isIncludeMember",
    title: "Group Member",
    checked: 1,
  },
  {
    id: "isIncludeInvitedToGroup",
    title: "Invited to the Group",
    checked: 1,
  },
];
