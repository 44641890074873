import { useCallback, useState } from "react";

export const useWithNumberSteps = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const onChangeStep = useCallback(
    (_, step) => {
      if (currentStep > step) {
        setCurrentStep(step);
      }
    },
    [currentStep]
  );

  const onClickNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onClickBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  return {
    onChangeStep,
    onClickNext,
    onClickBack,
    currentStep,
    setCurrentStep,
  };
};
