import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const SyncOptionBox = (props) => {
  const { icon, headerTitle, index, children, withConstantHeight } = props;

  return (
    <div
      className={clsx("sync-option-box-container", {
        "height-with-header": index === 0 || withConstantHeight,
        "normal-height": index !== 0 && !withConstantHeight,
      })}
    >
      {index === 0 && (
        <Typography className="sync-option-box-haeder">
          <img src={icon} className="mr-2" alt="icon" /> {headerTitle}
        </Typography>
      )}
      {children}
    </div>
  );
};

SyncOptionBox.defaultProps = {
  headerTitle: "",
  index: 0,
  identity: "",
  withConstantHeight: false,
};

SyncOptionBox.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.any,
  headerTitle: PropTypes.string,
  index: PropTypes.number,
  withConstantHeight: PropTypes.bool,
};

export default SyncOptionBox;
