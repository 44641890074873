import React from "react";
import { Dialog, Slide } from "@material-ui/core";
import "../../../styles/components/payment-form.scss";
import PaymentFormCard from "./PaymentFormCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentForm = (props) => {
  const { history } = props;
  return (
    <Dialog
      open={true}
      onClose={() => history.goBack()}
      TransitionComponent={Transition}
      classes={{
        root: "subscription-plans-page",
        paper: "payment-card-paper",
      }}
      BackdropProps={{
        classes: {
          root: "backdrop",
        },
      }}
      fullWidth={"md"}
    >
      <PaymentFormCard />
    </Dialog>
  );
};

export default PaymentForm;
