import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreditCard from "./CreditCard";
import { months, years } from "./paymentCardData";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { formDataWithApiKey, validateEmptyFields } from "../../../helpers";
import {
  addNewCard,
  updateCurrentCard,
} from "../../../store/middlewares/billingMiddleware";
import {
  clearEditCardState,
  toggleClearOverdueInvoiceModal,
} from "../../../store/actions/billingActions";
import { setSnackbarData } from "../../../store/actions/globalAction";
import "../../../styles/components/payment-form.scss";

const PaymentFormCard = (props) => {
  const {
    // Component props
    history,
    location,

    // Redux functions
    updateCurrentCard,
    clearEditCardState,
    setSnackbarData,
    addNewCard,
    toggleClearOverdueInvoiceModal,

    // Redux props
    hasCard,
    userData,
    cardErrorMessage,
    editCardStatus,
    cardSuccessMessage,
    warnings,
  } = props;
  const [isCardFlip, setIsCardFlip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputs, setInputs] = useState({
    cardNumber: "",
    cardHolder: "",
    ccv: "",
    expiryMonth: "",
    expiryYear: "",
  });
  const { state } = location;
  const isAllFieldsFiled = validateEmptyFields(inputs);

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  useEffect(() => {
    if (editCardStatus === "success") {
      showSnackbar(cardSuccessMessage, "success");
      clearEditCardState();
      history.goBack();
      if (
        [1, 2].includes(warnings?.hasBillingIssue) &&
        state?.from === "billing"
      ) {
        toggleClearOverdueInvoiceModal(true);
      }
    }
  }, [
    cardSuccessMessage,
    clearEditCardState,
    editCardStatus,
    history,
    showSnackbar,
    warnings,
    toggleClearOverdueInvoiceModal,
    state,
  ]);

  useEffect(() => {
    if (userData.firstName && userData.lastName) {
      setInputs((state) => ({
        ...state,
        cardHolder: `${userData.firstName} ${userData.lastName}`,
      }));
    }
  }, [userData.firstName, userData.lastName]);

  useEffect(() => {
    if (hasCard == null) {
      history.goBack();
    }
  }, [hasCard, history]);

  useEffect(() => {
    clearEditCardState();
  }, [clearEditCardState]);

  const validateInputMaxLenght = (name, value) => {
    if (
      (name === "cardNumber" && value.length <= 16) ||
      (name === "cardHolder" && value.length <= 20) ||
      (name === "ccv" && value.length <= 4) ||
      ["expiryMonth", "expiryYear"].includes(name)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (validateInputMaxLenght(name, value)) {
        setInputs((state) => ({ ...state, [name]: value }));
      }
    },
    [setInputs]
  );

  const handleSaveClicked = useCallback(() => {
    const formData = formDataWithApiKey();
    if (inputs.cardNumber.length < 10) {
      return setErrorMessage("Card Number is invalid!");
    }
    setLoading(true);
    window.Stripe.card.createToken(
      {
        number: inputs.cardNumber,
        exp_month: inputs.expiryMonth,
        exp_year: inputs.expiryYear,
        cvc: inputs.ccv,
      },
      (status, response) => {
        setLoading(false);
        setErrorMessage("");
        if (status === 200) {
          const cardLast4 = inputs.cardNumber.substring(
            inputs.cardNumber.length - 4
          );
          formData.append("ccYear", inputs.expiryYear);
          formData.append("ccMonth", inputs.expiryMonth);
          formData.append("stripeToken", response.id);
          formData.append("cardLast4", cardLast4);
          if (hasCard) {
            updateCurrentCard(formData);
          } else {
            addNewCard(formData);
          }
        } else {
          setLoading(false);
          setErrorMessage(response.error.message);
        }
      }
    );
  }, [addNewCard, hasCard, inputs, updateCurrentCard]);

  return (
    <>
      <CreditCard
        cardNumber={inputs.cardNumber}
        creditCardInfo={inputs}
        isCardFlip={isCardFlip}
      />
      <div className="checkout">
        <div>
          <form className="form" autoComplete="off" noValidate>
            {cardErrorMessage && (
              <p className="text-danger text-center w-100 m-0 p-0">
                {cardErrorMessage}
              </p>
            )}
            <fieldset>
              <label htmlFor="card-number">Card Number</label>
              <input
                type="number"
                name="cardNumber"
                onChange={handleChange}
                value={inputs.cardNumber}
              />
              {errorMessage && <h6 className="text-danger">{errorMessage}</h6>}
            </fieldset>
            <fieldset>
              <label htmlFor="card-holder">Card holder</label>
              <input
                type="text"
                name="cardHolder"
                onChange={handleChange}
                value={inputs.cardHolder}
                maxLength={20}
              />
            </fieldset>
            <fieldset className="fieldset-expiration">
              <label htmlFor="card-expiration-month">Expiration date</label>
              <div className="select">
                <select
                  id="card-expiration-month"
                  name="expiryMonth"
                  onChange={handleChange}
                  value={inputs.expiryMonth}
                >
                  <option />
                  {months.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select">
                <select
                  id="card-expiration-year"
                  name="expiryYear"
                  value={inputs.expiryYear}
                  onChange={handleChange}
                >
                  <option />
                  {years.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>
            <fieldset className="fieldset-ccv">
              <label htmlFor="card-ccv">CVC</label>
              <input
                onFocus={() => setIsCardFlip(true)}
                onBlur={() => setIsCardFlip(false)}
                value={inputs.ccv}
                id="card-ccv"
                name="ccv"
                onChange={handleChange}
                type="number"
              />
            </fieldset>
            <ButtonWithSpinner
              onClick={handleSaveClicked}
              disabled={isAllFieldsFiled.length || loading}
              className="bg-yellow mt-2 w-100"
              loading={loading}
            >
              Save
            </ButtonWithSpinner>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    hasCard: store.billing.hasCard,
    editCardStatus: store.billing.editCardStatus,
    cardErrorMessage: store.billing.cardErrorMessage,
    cardSuccessMessage: store.billing.cardSuccessMessage,
    warnings: store.userReducer.warnings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCard: (formData) => dispatch(updateCurrentCard(formData)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    addNewCard: (snackbarData) => dispatch(addNewCard(snackbarData)),
    toggleClearOverdueInvoiceModal: (val) =>
      dispatch(toggleClearOverdueInvoiceModal(val)),
    clearEditCardState: () => dispatch(clearEditCardState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentFormCard));
