import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import { Dialog, DialogContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MailchimpLogo from "../../../assets/img/mail-chimp.png";
import ReactHtmlParser from "react-html-parser";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner/ButtonWIthSpinner";
import { formDataWithApiKey, validateEmptyFields } from "../../../helpers";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import {
  authorizeMcAccount,
  removeMcAccountCredentials,
} from "../../../store/middlewares/userMiddleware";
import { syncManual } from "../../../store/middlewares/integrationMiddleware";
import {
  clearApiDetailsState,
  clearMcAccountSetup,
} from "../../../store/actions/userActions";
import { useComponentWillUnmount } from "../../../utils/hooks/useComponentWillUnmount";
import { setSnackbarData } from "../../../store/actions/globalAction";

const SetupMailchimpAccount = (props) => {
  const [inputs, setInputs] = useState({
    mailchimpApiKey: "",
  });
  const [errors, seterrors] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isOnEditCredentials, setIsOnEditCredentials] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState("");
  const {
    // COMPONENT PROPS
    history,

    // REDUX FUNCTIONS
    clearApiDetailsState,
    authorizeMcAccount,
    removeMcAccountCredentials,
    clearMcAccountSetup,
    syncManual,
    setSnackbarData,

    // REDUX PROPS
    userApiDetails,
    authorizeMcAccountStatus,
    mcErrorMessage,
    mcSuccessMessage,
    removeMcAccountCredentialsStatus,
  } = props;

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  useEffect(() => {
    if (authorizeMcAccountStatus === "success") {
      const formData = formDataWithApiKey();

      syncManual(formData);
      clearMcAccountSetup();
      setSnackbarData({
        snackbarSeverity: mcSuccessMessage ? "success" : "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(mcSuccessMessage || mcErrorMessage),
      });
      history.push("/integrations");
    }
  }, [
    authorizeMcAccountStatus,
    clearMcAccountSetup,
    history,
    mcErrorMessage,
    mcSuccessMessage,
    setSnackbarData,
    syncManual,
  ]);

  useEffect(() => {
    setInputs({ ...userApiDetails });
  }, [userApiDetails]);

  useComponentWillUnmount(() => {
    clearApiDetailsState();
  }, []);

  useEffect(() => {
    if (
      removeMcAccountCredentialsStatus === "success" &&
      !userApiDetails.mailchimpApiKey
    ) {
      closeConfirmationModal();
      const initialValues = {
        mailchimpApiKey: "",
      };
      setSnackbarData({
        snackbarSeverity: mcSuccessMessage ? "success" : "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(mcSuccessMessage || mcErrorMessage),
      });
      history.push("/integrations");
      setInputs((state) => ({ ...state, ...initialValues }));
    }
  }, [
    closeConfirmationModal,
    history,
    mcErrorMessage,
    mcSuccessMessage,
    removeMcAccountCredentialsStatus,
    setSnackbarData,
    userApiDetails,
  ]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleRemoveClicked = useCallback((type) => {
    const modalContent = `Are you sure you want to remove your ${type} credentials?`;
    setShowConfirmationModal(true);
    setConfirmationModalContent(modalContent);
  }, []);

  const handleSetupMcAccount = useCallback(
    (event) => {
      event.preventDefault();
      const fieldsForValidation = {
        mailchimpApiKey: inputs.mailchimpApiKey,
      };
      const emptyFields = validateEmptyFields(fieldsForValidation);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else {
        const formData = formDataWithApiKey();
        seterrors({});
        formData.append("mailchimpApiKey", inputs.mailchimpApiKey);
        authorizeMcAccount(formData);
      }
    },
    [authorizeMcAccount, inputs.mailchimpApiKey]
  );

  const handleRemoveAccountCredentials = useCallback(() => {
    const formData = formDataWithApiKey();
    removeMcAccountCredentials(formData);
  }, [removeMcAccountCredentials]);

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Dialog
      open={true}
      onClose={toggleModal}
      classes={{
        root: "logs-list-page",
        paper: "paper",
      }}
      BackdropProps={{
        classes: {
          root: "backdrop",
        },
      }}
      maxWidth={"xl"}
    >
      <DialogContent>
        <div>
          <Close className="close-icon " onClick={toggleModal} />
          <h3 className="user-note text-center mt-2 mb-0">
            Authorize your MailChimp Account!
          </h3>
        </div>

        <div className="flexer w-100" id="mailchimp-account">
          <div className="mailchimp-card-wrapper w-100">
            <div className="flexer-column w-100 card-content">
              <img src={MailchimpLogo} alt="integration-logo" />
              <form
                onSubmit={handleSetupMcAccount}
                className="w-100"
                name="form"
              >
                <InputField
                  name="mailchimpApiKey"
                  type="text"
                  placeholder="API Key"
                  className="w-100"
                  inputClass="authorize-password-field"
                  handleChange={handleChange}
                  value={inputs.mailchimpApiKey}
                  error={Boolean(errors.mailchimpApiKey)}
                  helperText={errors.mailchimpApiKey}
                  disabled={
                    userApiDetails.mailchimpApiKey && !isOnEditCredentials
                  }
                />
                <div className="mt-2 flexer-column">
                  <ButtonWithSpinner
                    loading={authorizeMcAccountStatus === "loading"}
                    disabled={authorizeMcAccountStatus === "loading"}
                    type="submit"
                    className="bg-yellow"
                  >
                    {userApiDetails.mailchimpApiKey && !isOnEditCredentials
                      ? "Re-authorize"
                      : "Authorize"}
                  </ButtonWithSpinner>
                  {userApiDetails.mailchimpApiKey && (
                    <div className="flexer mt-2">
                      {!isOnEditCredentials && (
                        <>
                          <Typography
                            onClick={() => setIsOnEditCredentials(true)}
                            className="link-text"
                            variant="subtitle1"
                          >
                            Edit&nbsp;
                          </Typography>{" "}
                          |
                        </>
                      )}
                      <Typography
                        onClick={() => handleRemoveClicked("Mailchimp")}
                        className="link-text"
                        variant="subtitle1"
                      >
                        &nbsp; Remove&nbsp;
                      </Typography>{" "}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            handleClose={closeConfirmationModal}
            open={showConfirmationModal}
            modalHeading="Confirm!"
            modalContent={confirmationModalContent}
            btnLabel="Confirm"
            loading={[removeMcAccountCredentialsStatus].includes("loading")}
            disabled={[removeMcAccountCredentialsStatus].includes("loading")}
            onClick={handleRemoveAccountCredentials}
            btnClassName="w-30 mr-3 bg-yellow"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return {
    userApiDetails: store.userReducer.userApiDetails,
    authorizeMcAccountStatus: store.userReducer.authorizeMcAccountStatus,
    mcErrorMessage: store.userReducer.mcErrorMessage,
    mcSuccessMessage: store.userReducer.mcSuccessMessage,
    removeMcAccountCredentialsStatus:
      store.userReducer.removeMcAccountCredentialsStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authorizeMcAccount: (data) => dispatch(authorizeMcAccount(data)),
    clearApiDetailsState: () => dispatch(clearApiDetailsState()),
    removeMcAccountCredentials: (data) =>
      dispatch(removeMcAccountCredentials(data)),
    clearMcAccountSetup: () => dispatch(clearMcAccountSetup()),
    syncManual: (data) => dispatch(syncManual(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupMailchimpAccount);
