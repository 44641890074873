import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropType from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { toggleClearOverdueInvoiceModal } from "../../../store/actions/billingActions";
import { getAllAvailablePlans } from "../../../store/middlewares/billingMiddleware";
import { formDataWithApiKey } from "../../../helpers";

const ClearOverdueInvoiceModal = (props) => {
  const {
    showModal,

    // Redux props
    currentSubscriptionPlan,

    // Redux functions
    toggleClearOverdueInvoiceModal,
    getAllAvailablePlans,
  } = props;
  const history = useHistory();

  useEffect(() => {
    const formData = formDataWithApiKey();
    getAllAvailablePlans(formData);
  }, [getAllAvailablePlans]);

  const toggleModal = useCallback(() => {
    toggleClearOverdueInvoiceModal(false);
  }, [toggleClearOverdueInvoiceModal]);

  const onClickedPayNow = useCallback(() => {
    toggleModal();
    history.push("/change-plan", {
      subscriptionPlan: currentSubscriptionPlan,
      isYearly: currentSubscriptionPlan?.annualAmount > 0 ? 1 : 0,
    });
  }, [currentSubscriptionPlan, history, toggleModal]);

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={"md"}
      >
        <DialogTitle id="form-dialog-title" className="pb-0">
          <strong>Clear Overdue Invoice?</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have an overdue invoice! Failure to pay will cause your account
            to expire. Would you like to clear that now?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex align-items-center justify-content-between p-4">
          <ButtonWithSpinner onClick={toggleModal} className="dark-gray-button">
            No, not now
          </ButtonWithSpinner>
          <ButtonWithSpinner onClick={onClickedPayNow} className="bg-yellow">
            Yes, pay now
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ClearOverdueInvoiceModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

const mapStateToProps = (store) => {
  return {
    currentSubscriptionPlan: store.billing.currentSubscriptionPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleClearOverdueInvoiceModal: (val) =>
      dispatch(toggleClearOverdueInvoiceModal(val)),
    getAllAvailablePlans: (formData) =>
      dispatch(getAllAvailablePlans(formData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClearOverdueInvoiceModal);
