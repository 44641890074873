import React, { useState, useCallback, useEffect } from "react";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InputField from "../../InputField/InputField";
import { validateEmptyFields } from "../../../helpers";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import "../../../styles/components/more/user-settings.scss";

const ChangePasswordModal = (props) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [simpleError, setSimpleError] = useState(false);
  const [errors, seterrors] = useState({});

  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { open, toggleModal, status, updateUserProfile, userData } = props;

  useEffect(() => {
    if (status === "success") {
      toggleModal();
    }
  }, [status, toggleModal]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleChangePassword = useCallback(() => {
    setSimpleError("");
    const formData = new FormData();
    formData.append("apiKey", userData.apiKey);
    formData.append("password", inputs.newPassword);
    updateUserProfile(formData, "password");
  }, [inputs.newPassword, updateUserProfile, userData.apiKey]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const emptyFields = validateEmptyFields(inputs);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else if (inputs.confirmPassword !== inputs.newPassword) {
        seterrors({});
        return setSimpleError("Passwords incorrect");
      } else {
        handleChangePassword();
      }
    },
    [handleChangePassword, inputs]
  );

  return (
    <Dialog
      open={open}
      onClose={toggleModal}
      aria-labelledby="change-pw-dialog-title"
      aria-describedby="change-pw-dialog-description"
      classes={{
        root: "user-dialog password",
        paper: "paper",
      }}
    >
      <DialogTitle className="dialog-title" id="change-pw-dialog-title">
        New Password <Close onClick={toggleModal} className="close-icon" />
      </DialogTitle>
      <form className="change-password">
        {simpleError && <h6 className="text-danger">{simpleError}</h6>}

        <InputField
          name="oldPassword"
          type={showOldPassword ? "text" : "password"}
          label="Old password"
          className="text-field"
          showPassword={showOldPassword}
          handlePasswordVisibility={() => setShowOldPassword(!showOldPassword)}
          handleChange={handleChange}
          value={inputs.oldPassword}
          error={Boolean(errors.oldPassword)}
          helperText={errors.oldPassword}
        />
        <InputField
          name="newPassword"
          type={showNewPassword ? "text" : "password"}
          label="New password"
          className="text-field"
          showPassword={showNewPassword}
          handlePasswordVisibility={() => setShowNewPassword(!showNewPassword)}
          handleChange={handleChange}
          value={inputs.newPassword}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword}
        />
        <InputField
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          label="Repeat new password"
          className="text-field"
          showPassword={showConfirmPassword}
          handlePasswordVisibility={() =>
            setShowConfirmPassword(!showConfirmPassword)
          }
          handleChange={handleChange}
          value={inputs.confirmPassword}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword}
        />
        <DialogActions>
          <ButtonWithSpinner
            onClick={handleSubmit}
            loading={status === "loading"}
            className="bg-yellow mt-1"
          >
            Save
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordModal;
