import axios from "axios";

export const getRequest = (
  dispatch,
  prefixAction,
  url,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...axiosConfig?.headers,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const postRequest = (
  dispatch,
  prefixAction,
  url,
  data,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...axiosConfig?.headers,
    },
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const putRequest = (
  dispatch,
  prefixAction,
  url,
  data,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...axiosConfig?.headers,
    },
  };

  return axios
    .put(url, data, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const deleteRequest = (
  dispatch,
  prefixAction,
  url,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...axiosConfig?.headers,
    },
  };

  return axios
    .delete(url, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const arrayToUrl = (url, array, queryArrayName) => {
  let newUrl = url;
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (i === array.length - 1) {
      newUrl = `${newUrl}${queryArrayName}[]=${element}`;
    } else {
      newUrl = `${newUrl}${queryArrayName}[]=${element}&`;
    }
  }

  return newUrl;
};
