import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import LogSearchBox from "../../LogsComponents/LogSearchBox/LogSearchBox";
import { formDataWithApiKey } from "../../../helpers";
import { setLogSearchTerm } from "../../../store/actions/logsAction";
import { searchLogs } from "../../../store/middlewares/logsMiddleware";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const SearchLogsModal = (props) => {
  const {
    open,
    handleClose,

    // Redux functions
    setLogSearchTerm,
    searchLogs,
  } = props;
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const [searchTerm, setSearchTerm] = useState("");

  const handleLogSearch = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (searchTerm) {
        const formData = formDataWithApiKey();
        formData.append("search", searchTerm);
        setLogSearchTerm(searchTerm);
        searchLogs(formData, null);
        handleClose();
        history.push(`/logs/search-results`, { searchTerm });
      }
    },
    [history, searchLogs, searchTerm, setLogSearchTerm, handleClose]
  );

  const handleChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0 vw-100" : "",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">Search Logs</span>
        <Close className="close-icon mr-2" onClick={handleClose} />
      </DialogTitle>
      <DialogContent className="mb-3">
        <LogSearchBox
          placeholder="Search email or name"
          onClick={handleLogSearch}
          handleChange={handleChange}
          name="log-search"
          textFieldClassName="w-100"
        />
      </DialogContent>
    </Dialog>
  );
};

SearchLogsModal.defaultProps = {
  open: false,
};

SearchLogsModal.propType = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogSearchTerm: (data) => dispatch(setLogSearchTerm(data)),
    searchLogs: (data, requestId) => dispatch(searchLogs(data, requestId)),
  };
};

export default connect(null, mapDispatchToProps)(SearchLogsModal);
