import React, { useCallback, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Chip,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import lodash from "lodash";
import {
  Close,
  Add,
  DeleteForeverOutlined,
  ArrowForward,
} from "@material-ui/icons";
import SyncOptionBox from "./SyncOptionBox";
import { dataForCcbModal, dataForMcModal } from "./addNewSyncPairModalData";
import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  CCB_TO_MC,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
  RESTRICTED_FEATURE_TOOLTIP,
} from "../../../../utils/integrationsConstants";
import CustomAutocompleteSelect from "../../../../HelperComponents/CustomAutocompleteSelect";
import McTagsAndGroupTabBox from "./McTagsAndGroupTabBox";
import CloseNewSyncPairConfirmationModal from "./CloseNewSyncPairConfirmationModal";
import SelectMcTagsModal from "../SelectMcTagsModal/SelectMcTagsModal";
import {
  addNewKeyPairInArray,
  checkIfCcbGroupExistInMcGroups,
  checkSubscribePlanAmount,
  confirmationModalContentWhenSelectAllMembersGroup,
  deepClone,
  formDataWithApiKey,
  getUniqueId,
  parseProcessQueueData,
  setArrayValuesInFormData,
} from "../../../../helpers";
import ButtonWithSpinner from "../../../ButtonWithSpinner/ButtonWIthSpinner";
import services from "../../../../services";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import NotficationBanner from "../../../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../../../utils/notifications";
import {
  clearUpdateProfile,
  updateUserProfile,
} from "../../../../store/middlewares/userMiddleware";
import TextWithRequest from "../../../TextWIthRequest/TextWithRequest";
import {
  checkIfHasSavedSearchPermissions,
  refreshList,
  saveCcbToMcSyncPair,
  saveMcToCcbSyncPair,
} from "../../../../store/middlewares/integrationMiddleware";
import {
  removeCcbToMcSyncPairSuccess,
  removeMcToCcbSyncPairSuccess,
  resetRefreshListStatus,
  setIntegrationPairForEdit,
} from "../../../../store/actions/integrationActions";
import { setSnackbarData } from "../../../../store/actions/globalAction";
import CcbCampusAndSavedSearchTabBox from "./CcbCampusAndSavedSearchTabBox";
import CcbGroupsAndProcessQueue from "./CcbGroupsAndProcessQueue";
import ShowSyncArrow from "../../../../HelperComponents/ShowSyncArrow";
import FilteredGroupMembershipType from "../../../SyncPageComponents/FilteredGroupMembershipType/FilteredGroupMembershipType";
import { usePopulateSyncPair } from "../../../../utils/hooks/usePopulateSyncPair";
import { useComponentWillUnmount } from "../../../../utils/hooks/useComponentWillUnmount";
import "../../../../styles/components/integrations/add-new-sync-pair-modal.scss";

const { api } = services;
const refreshLabels = {
  default: "Refresh Lists",
  loading: "Refreshing Lists",
  success: "Refreshed All Lists",
  error: "Refresh Lists Failed",
};

const AddNewSyncPairModal = (props) => {
  const {
    // Component props
    open,
    handleClose,
    loading,
    callIntialApis,
    prePopulatedPairs,
    mcToCcbPairs,

    // Redux props
    userData,
    updateProfileStatus,
    refreshListStatus,
    ccbCampuses,
    mailChimpAudience,
    ccbSavedSearches,
    currentSync,
    currentPlan,
    hasSavedSearchPermissions,
    hasSavedSearchPermissionsStatus,
    integrationPairForEdit,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,

    // Redux function
    updateUserProfile,
    clearUpdateProfile,
    refreshList,
    resetRefreshListStatus,
    setSnackbarData,
    saveCcbToMcSyncPair,
    saveMcToCcbSyncPair,
    checkIfHasSavedSearchPermissions,
    setIntegrationPairForEdit,
    removeCcbToMcSyncPairSuccess,
    removeMcToCcbSyncPairSuccess,
  } = props;
  const dataForModal = useMemo(
    () => (currentSync === CCB_TO_MC ? dataForCcbModal : dataForMcModal),
    [currentSync]
  );
  const isOnMedOrHigherPlan = useMemo(
    () =>
      checkSubscribePlanAmount(
        currentPlan?.amountDisplay,
        20,
        currentPlan?.name
      ),
    [currentPlan]
  );
  const [modalData, setModalData] = useState(dataForModal);
  const [mailChimpTags, setMailChimpTags] = useState([]);
  const [activeTagPairIndex, setActiveTagPairIndex] = useState(null);
  const [showConfirmationModal, setShowConfimationModal] = useState(false);
  const [error, setError] = useState(false);
  const [showAllMemberConfirmationModal, setShowAllMemberConfirmationModal] =
    useState(false);
  const [allMemberConfirmationCotent, setAllMemberConfirmationCotent] =
    useState("");
  const [
    dataForAllMemberConfirmationModal,
    setDataForAllMemberConfirmationModal,
  ] = useState({});
  const [showSelectMcTagsModal, setShowSelectMcTagsModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [selectBoxLoading, setSelectBoxLoading] = useState({});

  //Custom hooks
  const { populateSyncPairInModalData } = usePopulateSyncPair(false, true);

  // memo
  const formData = useMemo(() => formDataWithApiKey(), []);
  const isOnEdit = useMemo(
    () => !lodash.isEmpty(integrationPairForEdit),
    [integrationPairForEdit]
  );

  const getNotiMsgToPopulateCcbToMcPairs = useMemo(() => {
    if (prePopulatedPairs?.length === 1)
      return ReactHtmlParser(
        "You have a new existing <b>ccb-to-mc</b> sync pair that has not been added. Do you want to add it? "
      );
    if (prePopulatedPairs?.length > 1)
      return ReactHtmlParser(
        "You have some new existing <b>ccb-to-mc</b> sync pairs that have not been added. Do you want to add them? "
      );
  }, [prePopulatedPairs]);

  const getInitialData = useCallback(() => {
    const data = deepClone(dataForModal);
    data[0][CCB_CAMPUSES].options = ccbCampuses;
    data[0][MAILCHIMP_AUDIENCE].options = mailChimpAudience;
    return data;
  }, [ccbCampuses, dataForModal, mailChimpAudience]);

  const resetStatus = useCallback(
    (requestId) => () => {
      clearUpdateProfile(requestId);
    },
    [clearUpdateProfile]
  );

  // Populate pair for edit
  useEffect(() => {
    if (isOnEdit) {
      const syncPairsToPopulate = [deepClone(integrationPairForEdit)];
      populateSyncPairInModalData(syncPairsToPopulate, true, setModalData);
    }
  }, [integrationPairForEdit, isOnEdit, populateSyncPairInModalData]);

  useEffect(() => {
    if (!isOnEdit) {
      checkIfHasSavedSearchPermissions(formData);
    }
  }, [checkIfHasSavedSearchPermissions, formData, isOnEdit]);

  useEffect(() => {
    if (updateProfileStatus["v2_search_warning"] === "success") {
      resetStatus("v2_search_warning");
    } else if (updateProfileStatus["v2_pq_warning"] === "success") {
      resetStatus("v2_pq_warning");
    }
  }, [resetStatus, updateProfileStatus]);

  useEffect(() => {
    const updatedData = getInitialData();
    if (currentSync === CCB_TO_MC) {
      setModalData(updatedData);
    } else if (currentSync === MC_TO_CCB) {
      if (lodash.isEmpty(prePopulatedPairs)) {
        setModalData(updatedData);
      } else if (
        !lodash.isEmpty(prePopulatedPairs) &&
        lodash.isEmpty(mcToCcbPairs)
      ) {
        setModalData(prePopulatedPairs);
      } else {
        setModalData(updatedData);
      }
    }
  }, [currentSync, getInitialData, prePopulatedPairs, mcToCcbPairs]);

  useComponentWillUnmount(() => {
    setIntegrationPairForEdit({});
  }, []);

  const errorSnackbar = useCallback(
    (message = "Something went wrong, please try agan later!") => {
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const handleAddNewSyncPair = useCallback(() => {
    const formData = formDataWithApiKey();
    const ccbCampusIds = [];
    const type = [];
    const syncType = [];
    const ccbGroupIds = [];
    const mailchimpAudienceIds = [];
    const interestCategoryId = [];
    const interestId = [];
    const tags = [];
    setError(false);
    modalData.map((pair) =>
      Object.keys(pair).forEach((item) => {
        if (item === CCB_CAMPUSES) {
          ccbCampusIds.push(pair[item].selectedId);
          if (pair[item].value === "Saved Searches") {
            type.push("search");
          } else if (Boolean(pair[CCB_GROUPS].isProcessQueueSelected)) {
            type.push("process_queue");
          } else {
            type.push("group");
          }
        }
        if (item === CCB_GROUPS) ccbGroupIds.push(pair[item].selectedId);
        if (item === MAILCHIMP_AUDIENCE)
          mailchimpAudienceIds.push(pair[item].selectedId);
        if (item === MAILCHIMP_GROUPS) {
          syncType.push(pair[item].syncType);
          if (
            pair[item].selectedId != null &&
            pair[item].selectedId?.includes(":::::")
          ) {
            const splitUniqueId = pair[item].selectedId.split(":::::");
            interestCategoryId.push(splitUniqueId[0]);
            interestId.push(splitUniqueId[1]);
          } else {
            interestCategoryId.push("");
            interestId.push("");
          }
          const filterActiveTags = pair[item].tags
            .filter((tag) => tag.active)
            .map((item) => item.name);
          tags.push(filterActiveTags.join());
        }
      })
    );
    if (ccbCampusIds.includes(null)) {
      setError(true);
      return errorSnackbar("Please select a CCB campus");
    }
    if (ccbGroupIds.includes(null)) {
      setError(true);
      return errorSnackbar("Please select a CCB Group");
    }
    if (mailchimpAudienceIds.includes(null)) {
      setError(true);
      return errorSnackbar("Please select a MailChimp Audience");
    }
    setArrayValuesInFormData(syncType, formData, "syncType");

    if (currentSync === MC_TO_CCB) {
      setArrayValuesInFormData(ccbCampusIds, formData, "campusId");
      setArrayValuesInFormData(ccbGroupIds, formData, "ccbGroupId");
      setArrayValuesInFormData(mailchimpAudienceIds, formData, "mcListId");
    }

    if (currentSync === CCB_TO_MC) {
      setArrayValuesInFormData(
        mailchimpAudienceIds,
        formData,
        "mailchimpListId"
      );
      setArrayValuesInFormData(ccbGroupIds, formData, "id");
      setArrayValuesInFormData(type, formData, "type");
    }

    if (interestCategoryId.length && interestId.length) {
      syncType.forEach((item, index) => {
        if (item === 0) {
          formData.append(
            `params[${index}][${"interestCategoryId"}]`,
            interestCategoryId[index]
          );
          formData.append(
            `params[${index}][${"interestId"}]`,
            interestId[index]
          );
        }
      });
    }

    if (tags.length) {
      syncType.forEach((item, index) => {
        if (item === 1) {
          formData.append(`params[${index}][${"tags"}]`, tags[index]);
        }
      });
    }

    if (currentSync === CCB_TO_MC) {
      saveCcbToMcSyncPair(formData, false, ccbCampusIds);
    } else {
      saveMcToCcbSyncPair(formData);
    }
  }, [
    currentSync,
    errorSnackbar,
    modalData,
    saveCcbToMcSyncPair,
    saveMcToCcbSyncPair,
  ]);

  useEffect(() => {
    if (isOnEdit) {
      if (
        [removeCcbToMcPairStatus, removeMcToCcbPairStatus].includes("success")
      ) {
        handleAddNewSyncPair();
      }
    }
  }, [
    handleAddNewSyncPair,
    isOnEdit,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
  ]);

  const closeAllMemberConfirmationModal = useCallback(() => {
    setShowAllMemberConfirmationModal(false);
  }, []);

  const openAllMemberConfirmationModal = useCallback(
    (modalContent, modalData) => {
      setShowAllMemberConfirmationModal(true);
      setAllMemberConfirmationCotent(modalContent);
      setDataForAllMemberConfirmationModal(modalData);
    },
    []
  );

  const openMcTagsModal = useCallback(
    (pairIndex) => {
      setShowSelectMcTagsModal(true);
      setActiveTagPairIndex(pairIndex);
      setMailChimpTags(modalData[pairIndex][MAILCHIMP_GROUPS].tags);
    },
    [modalData]
  );

  const closeMcTagsModal = useCallback(
    (isConfirm) => {
      setShowSelectMcTagsModal(false);
      if (!isConfirm) {
        const cloneTags =
          deepClone(modalData)[activeTagPairIndex][MAILCHIMP_GROUPS].tags;
        cloneTags.forEach((item) => (item.active = false));
        setModalData(modalData);
      }
    },
    [activeTagPairIndex, modalData]
  );

  const closeModals = useCallback(() => {
    setShowConfimationModal(false);
    handleClose();
  }, [handleClose]);

  const CloseMainModal = useCallback(() => {
    if (isOnEdit) {
      handleClose();
    } else {
      setShowConfimationModal(true);
    }
  }, [handleClose, isOnEdit]);

  const handleCheck = useCallback(
    (data) => {
      const cloneModalData = deepClone(modalData);
      const tags = [...mailChimpTags];
      tags.forEach((tag, index) => {
        if (tag.id === data.id) {
          tags[index].active = !tags[index].active;
        }
      });
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setModalData(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, modalData]
  );

  const appendNewTag = useCallback(
    (newTag) => {
      const cloneModalData = deepClone(modalData);
      const tags = [...mailChimpTags];
      tags.push(newTag);
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setModalData(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, modalData]
  );

  const handleAddNewPair = useCallback(() => {
    const updatedData = getInitialData();
    setModalData((state) => [...state, ...updatedData]);
  }, [getInitialData]);

  const handleRemovePair = useCallback(
    (removeItemIndex) => {
      const updatedData = lodash.remove(
        modalData,
        (n, idx) => idx !== removeItemIndex
      );
      setModalData(updatedData);
    },
    [modalData]
  );

  const handleMcGroupAndTags = useCallback(
    (value, index) => {
      const cloneState = deepClone(modalData);
      cloneState[index][MAILCHIMP_GROUPS].syncType = value;
      if (value === 1) {
        cloneState[index][MAILCHIMP_GROUPS].value = "";
        cloneState[index][MAILCHIMP_GROUPS].selectedId = null;
      }
      setModalData(cloneState);
    },
    [modalData]
  );

  const handleMcGroupsChange = useCallback((option, state, index) => {
    if (option?.uniqueId) {
      state[index][MAILCHIMP_GROUPS].selectedId = option.uniqueId;
      state[index][MAILCHIMP_GROUPS].value = option.groupName;
      setModalData(state);
    } else {
      state[index][MAILCHIMP_GROUPS].selectedId = null;
      state[index][MAILCHIMP_GROUPS].value = "";
      setModalData(state);
    }
  }, []);

  const handleMcAudienceChange = useCallback(
    async (option, state, index) => {
      if (option?.id) {
        try {
          state[index][MAILCHIMP_AUDIENCE].selectedId = option.id;
          state[index][MAILCHIMP_AUDIENCE].value = option.name;
          formData.append("mailchimpListId", option.id);
          setSelectBoxLoading({
            identity: [MAILCHIMP_GROUPS, MAILCHIMP_AUDIENCE],
            index,
          });
          const mailChimpTags = await api.getMailchimpAudienceTag(formData);
          const mailChimpInterestedGroups =
            await api.getMailchimpInterestedGroups(formData);
          setSelectBoxLoading({});
          if (mailChimpInterestedGroups.status === 1) {
            const addkeyForGrouping = addNewKeyPairInArray(
              mailChimpInterestedGroups.data,
              { groupBy: "Sync to Existing Group" }
            );
            let filterCreatedGroup = [];
            if (state[index][CCB_GROUPS].value) {
              const isDuplicateGroupOption = checkIfCcbGroupExistInMcGroups(
                state[index][CCB_GROUPS].value,
                mailChimpInterestedGroups.data
              );
              filterCreatedGroup.push({
                groupBy: `Create a ${
                  isDuplicateGroupOption ? "Duplicate" : "new"
                } Group`,
                groupName: `${state[index][CCB_GROUPS].value} ${
                  isDuplicateGroupOption ? "- Copy" : ""
                }`,
                uniqueId: getUniqueId(1000) + "",
              });
            }
            state[index][MAILCHIMP_GROUPS].value = "";
            state[index][MAILCHIMP_GROUPS].options = [
              ...addkeyForGrouping,
              ...filterCreatedGroup,
            ];
          }
          if (mailChimpTags.status === 1) {
            const tags = addNewKeyPairInArray(
              Object.values(mailChimpTags.data),
              { active: false }
            );
            const unselectableTags = { active: true, isUnselectable: true };
            if (
              state[index][CCB_CAMPUSES].selectedId &&
              state[index][CCB_CAMPUSES].selectedId &&
              currentSync === CCB_TO_MC
            ) {
              if (state[index][CCB_CAMPUSES].value === "Saved Searches") {
                const selectedSavedSearch = state[index][
                  CCB_GROUPS
                ].options?.find(
                  (savedSearch) =>
                    savedSearch.id === state[index][CCB_GROUPS].selectedId
                );
                tags.unshift({
                  ...unselectableTags,
                  name: selectedSavedSearch.name,
                  id: selectedSavedSearch.id,
                });
              } else {
                const selectedCampusName = state[index][
                  CCB_CAMPUSES
                ].options?.find(
                  (campus) =>
                    campus.campusId === state[index][CCB_CAMPUSES].selectedId
                );
                const selectedCcbGroupOrProcessQueueOptions =
                  currentSync === CCB_TO_MC &&
                  Boolean(state[index][CCB_GROUPS].isProcessQueueSelected)
                    ? state[index][CCB_GROUPS].processQueueOptions
                    : state[index][CCB_GROUPS].options;
                const selectedGroupName =
                  selectedCcbGroupOrProcessQueueOptions?.find((group) =>
                    [group.id, group.ccbGroupId].includes(
                      state[index][CCB_GROUPS].selectedId
                    )
                  );
                tags.unshift({
                  ...unselectableTags,
                  name:
                    selectedGroupName?.ccbGroupName ||
                    selectedGroupName?.queueName,
                  id: selectedGroupName?.id,
                });
                tags.unshift({
                  ...unselectableTags,
                  name: selectedCampusName.campusName,
                  id: selectedCampusName.id,
                });
              }
            }
            const filterTags = lodash.uniqBy(tags, (e) => e.name);
            state[index][MAILCHIMP_GROUPS].tags = filterTags;
          }
          setModalData(state);
        } catch (error) {
          errorSnackbar();
          setSelectBoxLoading({});
        }
      }
    },
    [currentSync, errorSnackbar, formData]
  );

  const handleCcbGroupChange = useCallback(
    (option, state, index, isCheckAllMember) => {
      if (option?.ccbGroupId) {
        if (
          option.ccbGroupName.toLowerCase().includes("all members of", 0) &&
          !isCheckAllMember
        ) {
          return openAllMemberConfirmationModal(
            confirmationModalContentWhenSelectAllMembersGroup(
              option.ccbGroupName,
              option.numOfParticipants
            ),
            { ...option, index }
          );
        }
        if (currentSync === CCB_TO_MC) {
          state[index][CCB_GROUPS].selectedId = option.id;
        } else {
          state[index][CCB_GROUPS].selectedId = option.ccbGroupId;
        }
        state[index][CCB_GROUPS].value = option.ccbGroupName;
        if (currentSync === CCB_TO_MC) {
          state[index][MAILCHIMP_AUDIENCE].selectedId = null;
          state[index][MAILCHIMP_AUDIENCE].value = "";
          state[index][MAILCHIMP_GROUPS].options = [];
          state[index][MAILCHIMP_GROUPS].selectedId = null;
          state[index][MAILCHIMP_GROUPS].value = "";
        }
        setModalData(state);
      } else if (option?.id) {
        state[index][CCB_GROUPS].selectedId = option.id;
        state[index][CCB_GROUPS].value = option.name || option?.queueName;
        if (currentSync === CCB_TO_MC) {
          state[index][MAILCHIMP_AUDIENCE].selectedId = null;
          state[index][MAILCHIMP_AUDIENCE].value = "";
          state[index][MAILCHIMP_GROUPS].options = [];
          state[index][MAILCHIMP_GROUPS].selectedId = null;
          state[index][MAILCHIMP_GROUPS].value = "";
        }
        setModalData(state);
      }
    },
    [currentSync, openAllMemberConfirmationModal]
  );

  const handleCcbCampusChange = useCallback(
    async (option, state, index) => {
      const formData = formDataWithApiKey();
      if (option) {
        if (option.name === "Saved Searches") {
          state[index][CCB_GROUPS].options = ccbSavedSearches;
          state[index][CCB_GROUPS].placeholder = "Select Saved Searches";
          state[index][CCB_GROUPS].selectedId = null;
          state[index][CCB_GROUPS].value = "";

          if (currentSync === CCB_TO_MC) {
            state[index][MAILCHIMP_AUDIENCE].selectedId = null;
            state[index][MAILCHIMP_AUDIENCE].value = "";
            state[index][MAILCHIMP_GROUPS].selectedId = null;
            state[index][MAILCHIMP_GROUPS].value = "";
            state[index][MAILCHIMP_GROUPS].options = [];
          }
          state[index][CCB_CAMPUSES].selectedId = option.name;
          state[index][CCB_CAMPUSES].value = option.name;
          setModalData(state);
        } else {
          try {
            setSelectBoxLoading({
              identity: [CCB_GROUPS, CCB_CAMPUSES],
              index,
            });
            formData.append("campusId", option.campusId);
            const ccbGroups = await api.getCCbGroups(formData);
            if (currentSync === CCB_TO_MC) {
              const processQueues = await api.getProcessQueueByCampusId(
                formData
              );
              if (processQueues?.status === 1) {
                state[index][CCB_GROUPS].processQueueOptions =
                  parseProcessQueueData(processQueues.data);
              }
            }
            setSelectBoxLoading({});
            state[index][CCB_GROUPS].selectedId = null;
            state[index][CCB_GROUPS].value = "";
            state[index][CCB_GROUPS].options = ccbGroups.data;
            state[index][CCB_GROUPS].placeholder = state[index][CCB_GROUPS]
              .isProcessQueueSelected
              ? "Select Process Queue"
              : "Select CCB Group";
            if (currentSync === CCB_TO_MC) {
              state[index][MAILCHIMP_AUDIENCE].selectedId = null;
              state[index][MAILCHIMP_AUDIENCE].value = "";
              state[index][MAILCHIMP_GROUPS].selectedId = null;
              state[index][MAILCHIMP_GROUPS].value = "";
              state[index][MAILCHIMP_GROUPS].options = [];
            }
            state[index][CCB_CAMPUSES].selectedId = option.campusId;
            state[index][CCB_CAMPUSES].value = option.campusName;
            setModalData(state);
          } catch (error) {
            errorSnackbar();
            setSelectBoxLoading({});
          }
        }
      }
    },
    [ccbSavedSearches, currentSync, errorSnackbar]
  );

  const handleChange = useCallback(
    (event, option, identity, index) => {
      const currentState = deepClone(modalData);
      if (identity === CCB_CAMPUSES) {
        handleCcbCampusChange(option, currentState, index, identity);
      } else if (identity === CCB_GROUPS) {
        handleCcbGroupChange(option, currentState, index, false);
      } else if (identity === MAILCHIMP_AUDIENCE) {
        handleMcAudienceChange(option, currentState, index);
      } else if (identity === MAILCHIMP_GROUPS) {
        handleMcGroupsChange(option, currentState, index);
      }
    },
    [
      handleCcbCampusChange,
      handleCcbGroupChange,
      handleMcAudienceChange,
      handleMcGroupsChange,
      modalData,
    ]
  );

  const getRemoveSyncPairFormData = useCallback(() => {
    const formData = formDataWithApiKey();
    if (currentSync === CCB_TO_MC) {
      if (integrationPairForEdit?.searchId) {
        formData.append("id", integrationPairForEdit.id);
        formData.append("unsync", 1);
        formData.append("isDeleteGroup", 1);
        formData.append("type", "search");
      } else if (integrationPairForEdit?.processId) {
        formData.append("id", integrationPairForEdit.id);
        formData.append("unsync", 1);
        formData.append("isDeleteGroup", 1);
        formData.append("type", "process_queue");
      } else {
        formData.append("id", integrationPairForEdit.id);
        formData.append("unsync", 1);
        formData.append("isDeleteGroup", 1);
        formData.append("type", "group");
      }
    } else if (currentSync === MC_TO_CCB) {
      formData.append("id", integrationPairForEdit.id);
    }
    return formData;
  }, [currentSync, integrationPairForEdit]);

  const handleSubmit = useCallback(async () => {
    if (isOnEdit) {
      try {
        const editedModalData = modalData[0];
        if (
          currentSync === CCB_TO_MC &&
          editedModalData[CCB_CAMPUSES].selectedId ===
            integrationPairForEdit.campusId &&
          [
            integrationPairForEdit.ccbGroupId,
            integrationPairForEdit?.id,
          ].includes(editedModalData[CCB_GROUPS].selectedId)
        ) {
          handleAddNewSyncPair();
        } else {
          const formData = getRemoveSyncPairFormData();
          let removeSyncPairResponse = {};
          setLocalLoading(true);
          if (currentSync === CCB_TO_MC) {
            removeSyncPairResponse = await api.removeCcbToMcPairApi(formData);
            const successData = {
              data: formData,
              id: integrationPairForEdit.id,
              isRemoveFromBrokenPairModal: false,
              payload: removeSyncPairResponse,
              isFromEditPair: true,
            };
            removeCcbToMcSyncPairSuccess(successData);
          } else {
            removeSyncPairResponse = await api.removeMcToCcbPairApi(formData);
            const successData = {
              data: formData,
              id: integrationPairForEdit.id,
              isRemoveFromBrokenPairModal: false,
              payload: removeSyncPairResponse,
              isFromEditPair: true,
            };
            removeMcToCcbSyncPairSuccess(successData);
          }
          setLocalLoading(false);

          if (removeSyncPairResponse?.status === 1) {
            handleAddNewSyncPair();
          }
        }
      } catch (error) {
        setLocalLoading(false);
        errorSnackbar();
      }
    } else {
      handleAddNewSyncPair();
    }
  }, [
    currentSync,
    errorSnackbar,
    getRemoveSyncPairFormData,
    handleAddNewSyncPair,
    integrationPairForEdit,
    isOnEdit,
    modalData,
    removeCcbToMcSyncPairSuccess,
    removeMcToCcbSyncPairSuccess,
  ]);

  const getDisabledCondition = useCallback(
    (item, index) => {
      switch (item) {
        case MAILCHIMP_AUDIENCE: {
          if (
            !modalData[index][CCB_GROUPS].selectedId &&
            currentSync === CCB_TO_MC
          ) {
            return true;
          }
          break;
        }
        default:
          return false;
      }
    },
    [currentSync, modalData]
  );

  const enableSearchPermission = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("v2_search_warning", 1);
    updateUserProfile(formData, "v2_search_warning");
  }, [updateUserProfile]);

  const enableProcessQueuePermission = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("v2_pq_warning", 1);
    updateUserProfile(formData, "v2_pq_warning");
  }, [updateUserProfile]);

  const handleRefreshList = useCallback(() => {
    if (!refreshListStatus) {
      const formData = formDataWithApiKey();
      refreshList(formData);
    }
  }, [refreshList, refreshListStatus]);

  const onCcbCampusAndSavedSearchClicked = useCallback(
    (index, value) => {
      const cloneModalData = deepClone(modalData);
      cloneModalData[index][CCB_CAMPUSES].isSavedSearchSelected = value;
      if (currentSync === CCB_TO_MC) {
        cloneModalData[index][MAILCHIMP_AUDIENCE].selectedId = null;
        cloneModalData[index][MAILCHIMP_AUDIENCE].value = "";
        cloneModalData[index][MAILCHIMP_GROUPS].selectedId = null;
        cloneModalData[index][MAILCHIMP_GROUPS].value = "";
        cloneModalData[index][MAILCHIMP_GROUPS].options = [];
      }
      if (value === 1) {
        cloneModalData[index][CCB_GROUPS].options = ccbSavedSearches;
        cloneModalData[index][CCB_GROUPS].placeholder = "Select Saved Searches";
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].value = "";
        cloneModalData[index][CCB_GROUPS].headerTitle = "CCB Saved Search";
        cloneModalData[index][CCB_CAMPUSES].value = "Saved Searches";
        cloneModalData[index][CCB_CAMPUSES].selectedId = "Saved Searches";
      } else {
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].value = "";
        cloneModalData[index][CCB_GROUPS].options = [];
        cloneModalData[index][CCB_GROUPS].placeholder = cloneModalData[index][
          CCB_GROUPS
        ].isProcessQueueSelected
          ? "Select Process Queue"
          : "Select CCB Group";
        cloneModalData[index][CCB_GROUPS].headerTitle =
          "CCB Groups / Process Queues";
        cloneModalData[index][CCB_CAMPUSES].value = "";
        cloneModalData[index][CCB_CAMPUSES].selectedId = null;
      }
      setModalData(cloneModalData);
    },
    [ccbSavedSearches, currentSync, modalData]
  );

  const onCcbGroupsAndProcessQueueClicked = useCallback(
    (index, value) => {
      const cloneModalData = deepClone(modalData);
      cloneModalData[index][CCB_GROUPS].isProcessQueueSelected = value;
      cloneModalData[index][MAILCHIMP_GROUPS].selectedId = null;
      cloneModalData[index][MAILCHIMP_GROUPS].value = "";
      cloneModalData[index][MAILCHIMP_GROUPS].options = [];
      cloneModalData[index][MAILCHIMP_AUDIENCE].selectedId = null;
      cloneModalData[index][MAILCHIMP_AUDIENCE].value = "";
      if (value === 1) {
        cloneModalData[index][CCB_GROUPS].placeholder = "Select Process Queue";
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].value = "";
      } else {
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].placeholder =
          currentSync === CCB_TO_MC &&
          cloneModalData[index][CCB_GROUPS].isProcessQueueSelected
            ? "Select Process Queue"
            : "Select CCB Group";
        cloneModalData[index][CCB_GROUPS].value = "";
      }
      setModalData(cloneModalData);
    },
    [currentSync, modalData]
  );

  const populateCcbToMcSyncPairs = useCallback(() => {
    setModalData(prePopulatedPairs);
  }, [prePopulatedPairs]);

  const onClickProceedWithAllMemberGroup = useCallback(() => {
    handleCcbGroupChange(
      dataForAllMemberConfirmationModal,
      deepClone(modalData),
      dataForAllMemberConfirmationModal.index,
      true
    );
    closeAllMemberConfirmationModal();
  }, [
    closeAllMemberConfirmationModal,
    dataForAllMemberConfirmationModal,
    handleCcbGroupChange,
    modalData,
  ]);

  const renderMailchimpTags = useCallback(
    (tags, index) => {
      const filterActiveTags = tags?.filter((item) => item.active);
      if (filterActiveTags?.length && !showSelectMcTagsModal) {
        return (
          <div className="mb-2 flexer-start ml-3 flex-wrap">
            <Chip
              size="small"
              label={filterActiveTags[0].name}
              variant="outlined"
              className="tag-chip"
            />
            <div
              onClick={() => openMcTagsModal(index)}
              className="more-tags ml-2 cursor-pointer"
            >
              {filterActiveTags.length > 1
                ? `+${filterActiveTags?.length - 1}`
                : "Add"}{" "}
              more
            </div>
          </div>
        );
      } else {
        return (
          <Typography
            onClick={() => openMcTagsModal(index)}
            variant="subtitle1"
            className="select-mc-tag"
          >
            <Add fontSize="small" /> Select MC Tags
          </Typography>
        );
      }
    },
    [openMcTagsModal, showSelectMcTagsModal]
  );

  const renderDeleteOption = useCallback(
    (index) => {
      if (isOnEdit) return null;
      return (
        <DeleteForeverOutlined
          className="cursor-pointer"
          style={{ color: "#FF5F0A" }}
          fontSize="large"
          onClick={() => handleRemovePair(index)}
        />
      );
    },
    [handleRemovePair, isOnEdit]
  );

  const renderMailChimpAudienceAndTags = useCallback(
    (index, item) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1">
          <McTagsAndGroupTabBox
            handleMcGroupAndTags={handleMcGroupAndTags}
            syncType={item.syncType}
            index={index}
            currentItem={item}
            className={isOnEdit ? "w-100" : "w-75"}
            isTagsDisabled={
              !modalData[index][MAILCHIMP_AUDIENCE].selectedId ||
              (!isOnEdit && !isOnMedOrHigherPlan)
            }
            isSubscribeHigherPlan={isOnMedOrHigherPlan}
            isOnEdit={isOnEdit}
          >
            <div className="mt-2">
              {item.syncType === 0 ? (
                <CustomAutocompleteSelect
                  classes={{
                    root: "custom-autocomplete-select-container border-none",
                    input: "custom-autocomplete-input",
                  }}
                  placeholder={item.placeholder}
                  options={item.options}
                  displayKey={item.displayKey}
                  loadingText={item.loadingText}
                  identity={MAILCHIMP_GROUPS}
                  handleChange={handleChange}
                  index={index}
                  value={item.value}
                  currentSync={currentSync}
                  isCcbGroupExistInMcGroup={checkIfCcbGroupExistInMcGroups(
                    modalData[index][CCB_GROUPS].value,
                    modalData[index][MAILCHIMP_GROUPS].options
                  )}
                  disabled={refreshListStatus === "loading"}
                  error={error}
                  loading={
                    selectBoxLoading?.index === index &&
                    selectBoxLoading?.identity &&
                    selectBoxLoading.identity?.includes(MAILCHIMP_GROUPS)
                  }
                />
              ) : (
                renderMailchimpTags(item.tags, index)
              )}
            </div>
          </McTagsAndGroupTabBox>
          {currentSync === CCB_TO_MC && renderDeleteOption(index)}
          {currentSync === MC_TO_CCB && (
            <ArrowForward style={{ marginRight: "-15px" }} fontSize="large" />
          )}
        </div>
      );
    },
    [
      currentSync,
      handleChange,
      handleMcGroupAndTags,
      isOnMedOrHigherPlan,
      modalData,
      refreshListStatus,
      renderDeleteOption,
      renderMailchimpTags,
      selectBoxLoading,
      error,
      isOnEdit,
    ]
  );

  const renderCcbCampusAndSavedSearchBox = useCallback(
    (index, item) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1 ml-3">
          <CcbCampusAndSavedSearchTabBox
            isSavedSearchSelected={item.isSavedSearchSelected}
            index={index}
            currentItem={item}
            className={`w-80`}
            onClick={onCcbCampusAndSavedSearchClicked}
            isSavedSearchDisabled={
              !isOnEdit &&
              (!hasSavedSearchPermissions ||
                hasSavedSearchPermissionsStatus === "loading")
            }
            savedSearchTooltip={
              !isOnEdit &&
              !hasSavedSearchPermissions &&
              hasSavedSearchPermissionsStatus === "success"
                ? "You don't have permissions to use saved search"
                : ""
            }
          >
            <div
              className={`mt-2 ${
                item.isSavedSearchSelected === 1 ? "mb-2" : ""
              }`}
            >
              {item.isSavedSearchSelected === 0 ? (
                <CustomAutocompleteSelect
                  classes={{
                    root: "custom-autocomplete-select-container border-none",
                    input: "custom-autocomplete-input",
                  }}
                  placeholder={item.placeholder}
                  options={item.options}
                  displayKey={item.displayKey}
                  loadingText={item.loadingText}
                  identity={CCB_CAMPUSES}
                  handleChange={handleChange}
                  isSavedSearchSelected={
                    modalData[index][CCB_CAMPUSES].value === "Saved Searches"
                  }
                  index={index}
                  value={item.value}
                  disabled={
                    getDisabledCondition(item, index) ||
                    refreshListStatus === "loading"
                  }
                  currentSync={currentSync}
                  loading={
                    selectBoxLoading?.index === index &&
                    selectBoxLoading?.identity &&
                    selectBoxLoading.identity?.includes(CCB_CAMPUSES)
                  }
                  error={error}
                />
              ) : (
                <span className="text-muted ml-2">Saved Search</span>
              )}
            </div>
          </CcbCampusAndSavedSearchTabBox>
        </div>
      );
    },
    [
      currentSync,
      getDisabledCondition,
      handleChange,
      modalData,
      onCcbCampusAndSavedSearchClicked,
      refreshListStatus,
      selectBoxLoading,
      error,
      hasSavedSearchPermissions,
      hasSavedSearchPermissionsStatus,
      isOnEdit,
    ]
  );

  const getTooltipTitleForProcessQueue = useMemo(() => {
    if (isOnEdit) return "";
    if (!isOnMedOrHigherPlan) {
      return RESTRICTED_FEATURE_TOOLTIP;
    } else if (Number(userData?.v2_pq_warning) === 0) {
      return notificationProps["processQueueWarning"].message;
    }
    return "";
  }, [isOnMedOrHigherPlan, userData, isOnEdit]);

  const renderCcbGroupsAndProcessQueueBox = useCallback(
    (index, item) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1 ml-3">
          <CcbGroupsAndProcessQueue
            isProcessQueueSelected={item.isProcessQueueSelected}
            index={index}
            currentItem={item}
            className={`w-80`}
            onClick={onCcbGroupsAndProcessQueueClicked}
            isProcessQueueDisabled={
              (!isOnEdit && !isOnMedOrHigherPlan) ||
              Number(userData?.v2_pq_warning) === 0
            }
            tooltipTitle={getTooltipTitleForProcessQueue}
          >
            <div className={`mt-2`}>
              <CustomAutocompleteSelect
                classes={{
                  root: "custom-autocomplete-select-container border-none",
                  input: "custom-autocomplete-input",
                }}
                placeholder={item.placeholder}
                options={
                  Boolean(item.isProcessQueueSelected)
                    ? item.processQueueOptions
                    : item.options
                }
                displayKey={item.displayKey}
                isProcessQueueSelected={item.isProcessQueueSelected}
                loadingText={item.loadingText}
                identity={CCB_GROUPS}
                handleChange={handleChange}
                index={index}
                value={item.value}
                disabled={
                  getDisabledCondition(item, index) ||
                  refreshListStatus === "loading"
                }
                currentSync={currentSync}
                loading={
                  selectBoxLoading?.index === index &&
                  selectBoxLoading?.identity &&
                  selectBoxLoading.identity?.includes(CCB_CAMPUSES)
                }
                error={error}
              />
            </div>
          </CcbGroupsAndProcessQueue>
          <ShowSyncArrow
            className={"mb-3 mr-2"}
            fontSize={"large"}
            hasOneWayIcon={Boolean(item.isProcessQueueSelected)}
          />
        </div>
      );
    },
    [
      currentSync,
      error,
      getDisabledCondition,
      handleChange,
      onCcbGroupsAndProcessQueueClicked,
      refreshListStatus,
      selectBoxLoading,
      isOnMedOrHigherPlan,
      userData,
      getTooltipTitleForProcessQueue,
      isOnEdit,
    ]
  );

  const renderSyncBoxesContent = useCallback(
    (item, pair, index, idx, subPair) => {
      if (item === MAILCHIMP_GROUPS) {
        return renderMailChimpAudienceAndTags(index, pair);
      } else if (currentSync === CCB_TO_MC && item === CCB_CAMPUSES) {
        return renderCcbCampusAndSavedSearchBox(index, pair);
      } else if (
        currentSync === CCB_TO_MC &&
        item === CCB_GROUPS &&
        subPair[CCB_CAMPUSES].isSavedSearchSelected === 0
      ) {
        return renderCcbGroupsAndProcessQueueBox(index, pair);
      } else {
        return (
          <div className="w-100 d-flex justify-content-between align-items-end">
            <div className="select-wrapper w-85">
              <CustomAutocompleteSelect
                classes={{
                  root: "custom-autocomplete-select-container",
                  input: "custom-autocomplete-input",
                }}
                placeholder={pair.placeholder}
                options={pair.options}
                displayKey={pair.displayKey}
                loadingText={pair.loadingText}
                identity={item}
                handleChange={handleChange}
                isSavedSearchSelected={
                  subPair[CCB_CAMPUSES].value === "Saved Searches"
                }
                index={index}
                value={pair.value}
                disabled={
                  getDisabledCondition(item, index) ||
                  refreshListStatus === "loading"
                }
                currentSync={currentSync}
                loading={
                  selectBoxLoading?.index === index &&
                  selectBoxLoading?.identity &&
                  selectBoxLoading.identity?.includes(item)
                }
                error={error}
              />
            </div>
            {idx === 1 && (
              <ShowSyncArrow
                hasOneWayIcon={Boolean(
                  subPair[CCB_CAMPUSES].isSavedSearchSelected
                )}
                className={"mb-3 mr-2"}
                fontSize={"large"}
              />
            )}
            {item === CCB_GROUPS && currentSync === MC_TO_CCB && (
              <div className="mr-2 mb-4">{renderDeleteOption(index)}</div>
            )}
          </div>
        );
      }
    },
    [
      currentSync,
      error,
      getDisabledCondition,
      handleChange,
      refreshListStatus,
      renderCcbCampusAndSavedSearchBox,
      renderDeleteOption,
      renderMailChimpAudienceAndTags,
      renderCcbGroupsAndProcessQueueBox,
      selectBoxLoading,
    ]
  );

  const renderSyncBoxes = useMemo(() => {
    return modalData.map((pair, index) => (
      <div className="flexer" key={index}>
        {Object.keys(pair).map((item, idx) => (
          <SyncOptionBox
            index={index}
            headerTitle={pair[item].headerTitle}
            icon={pair[item].icon}
            key={idx}
          >
            {renderSyncBoxesContent(item, pair[item], index, idx, pair)}
          </SyncOptionBox>
        ))}
      </div>
    ));
  }, [modalData, renderSyncBoxesContent]);

  return (
    <>
      <Dialog
        open={open}
        onClose={CloseMainModal}
        fullWidth={true}
        maxWidth={"xl"}
        classes={{
          paperFullWidth: "add-new-sync-pair-mobile-wrapper",
        }}
      >
        <DialogTitle className="flexer pb-0" id="alert-dialog-title">
          <span className="modal-main-heading">
            {isOnEdit ? "Save Sync Pair" : "Add New Sync Pairs"}
          </span>
          <Close onClick={CloseMainModal} className="close-icon" />
        </DialogTitle>
        <div className="mb-2">
          <FilteredGroupMembershipType className={"flexer"} />
        </div>
        {Number(userData?.v2_search_warning) === 0 &&
          currentSync === CCB_TO_MC && (
            <NotficationBanner
              {...notificationProps["searchWarning"]}
              onClick={enableSearchPermission}
            />
          )}
        {Number(userData?.v2_pq_warning) === 0 && currentSync === CCB_TO_MC && (
          <NotficationBanner
            {...notificationProps["processQueueWarning"]}
            onClick={enableProcessQueuePermission}
          />
        )}
        {currentSync === MC_TO_CCB &&
          !lodash.isEmpty(mcToCcbPairs) &&
          !lodash.isEmpty(prePopulatedPairs) &&
          !isOnEdit && (
            <NotficationBanner
              {...notificationProps["prePopulateCcbToMcPairs"]}
              message={getNotiMsgToPopulateCcbToMcPairs}
              onClick={populateCcbToMcSyncPairs}
            />
          )}
        <DialogContent className="p-0 mt-3 content-container">
          {renderSyncBoxes}
          {!isOnEdit && (
            <div className="add-more-sync-pairs">
              <div onClick={handleAddNewPair} className="cursor-pointer w-auto">
                <Add fontSize="small" /> Add More Sync Pairs
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          className={`mb-4 mr-5 ml-5 d-flex justify-content-between align-items-center ${
            isOnEdit ? "mt-5" : ""
          }`}
        >
          <TextWithRequest
            onSuccessCall={callIntialApis}
            resetStatus={resetRefreshListStatus}
            onClick={handleRefreshList}
            labels={refreshLabels}
            status={refreshListStatus || "default"}
          />
          <ButtonWithSpinner
            onClick={handleSubmit}
            className="bg-yellow"
            loading={loading || localLoading}
            disabled={!modalData.length || loading || localLoading}
          >
            {isOnEdit ? "Save Sync Pair" : "Add to Sync List"}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>

      {showConfirmationModal && (
        <CloseNewSyncPairConfirmationModal
          open={showConfirmationModal}
          handleClose={closeModals}
          onClick={handleSubmit}
        />
      )}

      {showSelectMcTagsModal && (
        <SelectMcTagsModal
          open={showSelectMcTagsModal}
          handleClose={closeMcTagsModal}
          tags={mailChimpTags.sort((x, y) =>
            x.active === y.active ? 0 : x.active ? -1 : 1
          )}
          handleCheck={handleCheck}
          appendNewTag={appendNewTag}
        />
      )}

      {showAllMemberConfirmationModal && (
        <ConfirmationModal
          handleClose={closeAllMemberConfirmationModal}
          open={showAllMemberConfirmationModal}
          modalHeading="Confirm!"
          modalContent={ReactHtmlParser(allMemberConfirmationCotent)}
          btnLabel="Proceed"
          cancelBtnLabel="Nevermind"
          btnClassName="w-30 mr-3 bg-yellow"
          onClick={onClickProceedWithAllMemberGroup}
        />
      )}
    </>
  );
};

AddNewSyncPairModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  callIntialApis: PropTypes.func.isRequired,
  mcToCcbPairs: PropTypes.array,
  prePopulatedPairs: PropTypes.array,
};

AddNewSyncPairModal.defaultProps = {
  mcToCcbPairs: [],
  prePopulatedPairs: [],
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    updateProfileStatus: store.userReducer.updateProfileStatus,
    refreshListStatus: store.integrations.refreshListStatus,
    ccbCampuses: store.integrations.ccbCampuses,
    mailChimpAudience: store.integrations.mailChimpAudience,
    ccbSavedSearches: store.integrations.ccbSavedSearches,
    currentSync: store.integrations.currentSync,
    currentPlan: store.billing.currentPlan,
    hasSavedSearchPermissions: store.integrations.hasSavedSearchPermissions,
    integrationPairForEdit: store.integrations.integrationPairForEdit,
    hasSavedSearchPermissionsStatus:
      store.integrations.hasSavedSearchPermissionsStatus,
    removeCcbToMcPairStatus: store.integrations.removeCcbToMcPairStatus,
    removeMcToCcbPairStatus: store.integrations.removeMcToCcbPairStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (data, requestId) =>
      dispatch(updateUserProfile(data, requestId)),
    clearUpdateProfile: (formData) => dispatch(clearUpdateProfile(formData)),
    refreshList: (formData) => dispatch(refreshList(formData)),
    resetRefreshListStatus: () => dispatch(resetRefreshListStatus()),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    saveCcbToMcSyncPair: (data, isFixingBrokenPairs, ids) =>
      dispatch(saveCcbToMcSyncPair(data, isFixingBrokenPairs, ids)),
    saveMcToCcbSyncPair: (data, requestId) =>
      dispatch(saveMcToCcbSyncPair(data, requestId)),
    checkIfHasSavedSearchPermissions: (data) =>
      dispatch(checkIfHasSavedSearchPermissions(data)),
    setIntegrationPairForEdit: (data) =>
      dispatch(setIntegrationPairForEdit(data)),
    removeCcbToMcSyncPairSuccess: (data) =>
      dispatch(removeCcbToMcSyncPairSuccess(data)),
    removeMcToCcbSyncPairSuccess: (data) =>
      dispatch(removeMcToCcbSyncPairSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewSyncPairModal);
