import { useEffect, useState, useMemo } from "react";
import { formDataWithApiKey } from "../../helpers";

export const CheckSyncing = (
  syncManualStatus,
  syncingInProgress,
  clearSyncState,
  checkSyncingStatus,
  autoSyncingStatus,
  callBack
) => {
  const syncCondition = useMemo(
    () => syncManualStatus === "success" && syncingInProgress,
    [syncManualStatus, syncingInProgress]
  );
  const [isSyncingInProgress, setIsSyncingInProgress] = useState(false);

  useEffect(() => {
    if (!syncCondition && isSyncingInProgress) {
      clearSyncState();
      setIsSyncingInProgress(false);
      callBack();
    }
  }, [clearSyncState, syncCondition, callBack, isSyncingInProgress]);

  useEffect(() => {
    if (syncCondition && !autoSyncingStatus) {
      const formData = formDataWithApiKey();
      const checkSyncInterval = setInterval(() => {
        checkSyncingStatus(formData);
        setIsSyncingInProgress(true);
      }, 3000);
      return () => clearInterval(checkSyncInterval);
    }
  }, [autoSyncingStatus, checkSyncingStatus, syncCondition]);

  return {
    isSyncingInProgress,
  };
};
