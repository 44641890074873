import React, {
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Button,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Info } from "@material-ui/icons";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import IntegrationLogo2 from "../../assets/img/ccb-mc.png";
import CCBlogo from "../../assets/img/church-community.png";
import MailchimpLogo from "../../assets/img/mail-chimp.png";
import DashboardImg from "../../assets/img/welcome-banner.png";
import VideoView from "../../components/VideoView/VideoView";
import MaterialButton from "../../components/ MaterialButton/MaterialButton";
import ActivateAccountAlerts from "../../components/ActivateAccountAlerts/ActivateAccountAlerts";
import {
  formatDateAndTime,
  formDataWithApiKey,
  getCurrentDate,
  getCurrentTimezone,
  getUserName,
} from "../../helpers";
import { setSnackbarData } from "../../store/actions/globalAction";
import { syncManual } from "../../store/middlewares/integrationMiddleware";
import NotficationBanner from "../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../utils/notifications";
import {
  getDashboardData,
  getUserApiDetails,
  getWarnings,
} from "../../store/middlewares/userMiddleware";
import { UNPAID_ACC_DETAILS } from "../../utils/constants";
import MergeFieldErrorArticleLink from "../../HelperComponents/MergeFieldErrorArticleLink";
import AutoSyncToggle from "../../components/AutoSyncToggle/AutoSyncToggle";
import { toggleCardDeclineModal } from "../../store/actions/billingActions";
import { UseSyncOptions } from "../../utils/hooks/useSyncOptions";
import "../../styles/components/dashboard/dashboard-main.scss";
import LogSearchBox from "../../components/LogsComponents/LogSearchBox/LogSearchBox";
import { setLogSearchTerm } from "../../store/actions/logsAction";
import { searchLogs } from "../../store/middlewares/logsMiddleware";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";

const Dashboard = (props) => {
  const {
    // Component props
    history,
    hasCurrentPlan,

    // Redux functions
    setSnackbarData,
    syncManual,
    getWarnings,
    getUserApiDetails,
    getDashboardData,
    toggleCardDeclineModal,
    setLogSearchTerm,
    searchLogs,

    // Redux props
    userData,
    userApiDetails,
    warnings,
    apiDetailsStatus,
    currentPlanStatus,
    dashboardData,
    userCurrentPlan,
    hasCcbError,
    hasMcError,
  } = props;
  const {
    disableSyncing,
    syncDisabledReason,
    SyncButtonTooltip,
    isSyncingInProgress,
    isAutoSyncingInProgress,
  } = UseSyncOptions([]);

  const [searchTerm, setSearchTerm] = useState("");
  const currentPlan = hasCurrentPlan ? userCurrentPlan : UNPAID_ACC_DETAILS;
  const syncingInProgress = isSyncingInProgress || isAutoSyncingInProgress;

  const isSidebarExpanded = useIsSidebarExpanded();

  useEffect(() => {
    const formData = formDataWithApiKey();
    getUserApiDetails(formData);
    getWarnings(formData);
    getDashboardData(formData);
  }, [getDashboardData, getUserApiDetails, getWarnings]);

  const handleManualSync = useCallback(() => {
    const formData = formDataWithApiKey();
    syncManual(formData);
  }, [syncManual]);

  const onClickedRepurchasePlan = useCallback(() => {
    history.push("/billing");
    toggleCardDeclineModal(true);
  }, [history, toggleCardDeclineModal]);

  const getUserErrorMessage = useCallback((message = "") => {
    if (message?.includes("merged fields")) {
      return (
        <div>
          <MergeFieldErrorArticleLink className={"mr-1"}>
            <Info
              className="mb-1"
              htmlColor="rgb(97, 26, 21)"
              fontSize="small"
            />
          </MergeFieldErrorArticleLink>
          {message}
          <Link to="/logs" className="font-weight-bold ml-1">
            Click here to see logs
          </Link>
        </div>
      );
    } else if (
      message?.startsWith("Your current MailChimp setting doesn't match")
    ) {
      return (
        <div>
          <a
            href="https://support.ccbchimp.com/en/articles/2535782-syncing-paused-you-need-to-confirm-and-restart-syncing"
            target={"__blank"}
            className={"mr-1"}
          >
            <Info
              className="mb-1"
              htmlColor="rgb(97, 26, 21)"
              fontSize="small"
            />
          </a>
          {message}
          <Link to="/sync" className="font-weight-bold ml-1">
            Confirm and Restart Syncing
          </Link>
        </div>
      );
    }
    return message;
  }, []);

  const parseTime = (timeStr = "") => {
    const splitTimeStr = timeStr.trim().split(" ");
    let time = "";
    if (Array.isArray(splitTimeStr) && splitTimeStr.length > 1) {
      time = `${splitTimeStr[0]}:00 ${splitTimeStr[1]}`;
    }
    return time;
  };

  const getNextSyncTime = (nextSyncStr = "") => {
    const splitTimeFromString = nextSyncStr.includes("at")
      ? nextSyncStr.split("at")
      : [];
    let nextSyncDateAndTime = "";
    if (Array.isArray(splitTimeFromString) && splitTimeFromString.length > 1) {
      nextSyncDateAndTime = `${getCurrentDate()} ${
        splitTimeFromString[1].includes(":")
          ? splitTimeFromString[1]
          : parseTime(splitTimeFromString[1])
      }`;
    }
    if (nextSyncDateAndTime) {
      return `Next Sync at ${formatDateAndTime(
        nextSyncDateAndTime,
        true,
        true
      )}`;
    } else {
      return `${nextSyncStr} ${getCurrentTimezone()}`;
    }
  };

  const lastSyncDetails = useMemo(() => {
    if (
      dashboardData?.syncList &&
      Array.isArray(dashboardData?.syncList) &&
      Boolean(dashboardData?.syncList?.length)
    ) {
      const reversedDashboardData = [...dashboardData.syncList].reverse();
      return reversedDashboardData.map((item, index) => (
        <div className="w-100" key={index}>
          <div className="flexer-space-between sync-details-wrapper mt-3 w-100 mb-1">
            <Typography className="font-weight-bold" variant="subtitle2">
              {item.sync_type}
            </Typography>
            <Typography
              onClick={() =>
                history.push(`logs/details/${item.sync_log_id}`, {
                  isBreadcrumbEnable: true,
                  breadcrumbsFromRoute: [
                    {
                      text: item.sync_type,
                      route: `logs/details/${item.sync_log_id}`,
                    },
                  ],
                })
              }
              className="link-text"
              variant="subtitle2"
            >
              Details
            </Typography>
          </div>
          <div className="sync-details-wrapper w-100">
            <div className="flexer-space-between w-100 mt-2">
              <Typography variant="subtitle2">
                {item["group synced"]} groups synced
              </Typography>
              <Typography variant="subtitle2">
                {item.contacts_removed} saved search synced
              </Typography>
            </div>
            <div className="flexer-space-between w-100 mb-2">
              <Typography variant="subtitle2">
                {item.contact_synced} contact synced
              </Typography>
              <Typography variant="subtitle2">
                {item.contacts_removed} contact removed
              </Typography>
            </div>
          </div>
        </div>
      ));
    }
    return null;
  }, [dashboardData, history]);

  const handleLogSearch = useCallback(() => {
    if (searchTerm) {
      const formData = formDataWithApiKey();
      formData.append("search", searchTerm);
      setLogSearchTerm(searchTerm);
      searchLogs(formData, null);
      history.push(`/logs/search-results`, { searchTerm });
    }
  }, [history, searchLogs, searchTerm, setLogSearchTerm]);

  const handleChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  return (
    <div
      className={clsx("dashboard-main ", {
        "dashboard-container": isSidebarExpanded,
      })}
    >
      <div className="cards-wrapper p-0">
        <Grid container spacing={2} className="container welcome">
          {userData.role === 2 && (
            <>
              <h3 className="user-note mt-3">
                Welcome {getUserName(userData)}!
              </h3>
              {Array.isArray(warnings?.userErrorMessages) &&
                Boolean(warnings?.userErrorMessages) &&
                warnings?.userErrorMessages.map((item, idx) => (
                  <NotficationBanner
                    {...notificationProps["errorMessage"]}
                    key={idx}
                    message={getUserErrorMessage(item)}
                  />
                ))}
              {Boolean(hasCcbError) && apiDetailsStatus !== "loading" && (
                <NotficationBanner
                  {...notificationProps["hasCcbError"]}
                  onClick={() => history.push("/integrations/setup-ccb")}
                />
              )}
              {Boolean(hasMcError) && apiDetailsStatus !== "loading" && (
                <NotficationBanner
                  {...notificationProps["hasMcError"]}
                  onClick={() => history.push("/integrations/setup-mailchimp")}
                />
              )}

              {[1, 2].includes(warnings?.hasBillingIssue) && (
                <NotficationBanner
                  onClick={onClickedRepurchasePlan}
                  linkText={"Repurchase Plan "}
                  {...notificationProps["paymentFailed"]}
                />
              )}

              {warnings?.hasUnresolveContacts === 1 && (
                <NotficationBanner
                  message={`You have ${warnings?.totalHoldContacts} potential Conflict(s). `}
                  onClick={() => history.push("/resolve-conflicts")}
                  {...notificationProps["unresolveContacts"]}
                />
              )}
            </>
          )}
          {Boolean(userData.isActivated) ? (
            <Fragment>
              {userData.role === 2 ? (
                <div className="w-100 card-wrapper">
                  <div className="integration-settings-wrapper">
                    <Card className="dashboard-card welcome-card card1-width">
                      <CardContent className="card-content h-100 p-4">
                        <div className="flexer-column w-100">
                          <img
                            width={"70%"}
                            src={CCBlogo}
                            alt="integration-logo"
                          />
                          <MaterialButton
                            title={
                              userApiDetails.ccbUsername ? "Edit" : "Setup now"
                            }
                            className="mt-4 integrations-btn"
                            onClick={() => history.push("/integrations")}
                            loading={apiDetailsStatus === "loading"}
                            disabled={apiDetailsStatus === "loading"}
                          />
                        </div>
                      </CardContent>
                    </Card>
                    <Card className="dashboard-card welcome-card card1-width">
                      <CardContent className="card-content h-100 p-4">
                        <div className="flexer-column w-100">
                          <img
                            width={"70%"}
                            src={MailchimpLogo}
                            alt="integration-logo"
                          />
                          <MaterialButton
                            title={
                              userApiDetails.mailchimpApiKey
                                ? "Edit"
                                : "Setup now"
                            }
                            className="mt-4 integrations-btn"
                            onClick={() =>
                              history.push("/integrations", {
                                isMailchimpBtnClicked: true,
                              })
                            }
                            loading={apiDetailsStatus === "loading"}
                            disabled={apiDetailsStatus === "loading"}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                  <Card className="dashboard-card welcome-card card2-width h-auto">
                    <CardContent className="card-content sync-details-card-content p-4">
                      <div className="flexer-column w-100">
                        <img
                          width={"45%"}
                          src={IntegrationLogo2}
                          alt="integration-logo"
                        />
                        {currentPlanStatus === "loading" ? (
                          <div>
                            <CircularProgress color="inherit" />
                          </div>
                        ) : (
                          <div className="billing-info-wrapper flexer">
                            <Typography variant="h6">
                              {currentPlan.name}{" "}
                              <span className="amount">
                                $ {currentPlan.amountDisplay}
                              </span>
                            </Typography>
                            {currentPlan.subAmountDisplay && (
                              <Typography
                                variant="subtitle2"
                                className="small-text ml-2 font-weight-bold"
                              >
                                ({currentPlan.subAmountDisplay})
                              </Typography>
                            )}
                            <Typography
                              onClick={() => history.push("/billing")}
                              className="billing-button ml-2"
                              variant="subtitle2"
                            >
                              {hasCurrentPlan
                                ? "Manage Billing"
                                : "Purchase a Plan"}
                            </Typography>
                          </div>
                        )}
                        {dashboardData?.last_sync_utc && (
                          <Typography
                            className="text-muted text-center"
                            variant="body2"
                          >
                            Last Sync:{" "}
                            {dashboardData.last_sync_utc === "N/A"
                              ? dashboardData.last_sync_utc
                              : formatDateAndTime(
                                  dashboardData.last_sync_utc,
                                  false,
                                  true
                                )}
                          </Typography>
                        )}
                        {lastSyncDetails}

                        {dashboardData?.automatic_syncing &&
                          dashboardData?.automatic_syncing !== "N/A" && (
                            <div className="d-flex">
                              <AutoSyncToggle />
                              <Typography
                                className="text-muted mt-2"
                                variant="subtitle1"
                              >
                                Automatic Syncing -{" "}
                                {dashboardData?.automatic_syncing}
                              </Typography>
                            </div>
                          )}

                        {dashboardData?.next_sync_utc &&
                          dashboardData?.next_sync_utc !== "N/A" && (
                            <Typography
                              className="text-danger"
                              variant="subtitle2"
                            >
                              ({getNextSyncTime(dashboardData?.next_sync_utc)})
                            </Typography>
                          )}

                        {dashboardData?.unlimited_manual_syncing && (
                          <Typography
                            className="text-muted"
                            variant="subtitle2"
                          >
                            {dashboardData?.unlimited_manual_syncing}
                          </Typography>
                        )}

                        <div className="d-flex justify-content-between flex-column flex-md-row w-100 mt-3 ml-md-4">
                          <Typography
                            className="font-weight-bold mb-2 mb-md-0"
                            variant="subtitle1"
                          >
                            Logs
                          </Typography>
                          <LogSearchBox
                            placeholder="Search email or name"
                            onClick={handleLogSearch}
                            handleChange={handleChange}
                            name="log-search"
                            textFieldClassName="w-100"
                          />
                        </div>

                        <div className="flexer-space-between w-100 mt-3">
                          <div className="w-100">
                            <MaterialButton
                              title={"Manage Sync"}
                              buttonStyle={"contained-primary card2-buttons"}
                              onClick={() => history.push("/sync")}
                              labelClassName={"manage-btn label"}
                            />
                            <MaterialButton
                              title={"Manage Logs"}
                              buttonStyle={"card2-logs-btn"}
                              onClick={() => history.push("/logs")}
                              labelClassName={"manage-btn label"}
                            />
                          </div>
                          <Tooltip
                            arrow={true}
                            placement={"bottom"}
                            title={SyncButtonTooltip}
                          >
                            <div className="sync-btn-container">
                              <Button
                                color="primary"
                                variant="contained"
                                className="sync-now-btn"
                                classes={{
                                  root: "btn",
                                  label: "sync-btn-label",
                                }}
                                onClick={handleManualSync}
                                disabled={
                                  disableSyncing ||
                                  syncDisabledReason ||
                                  !hasCurrentPlan
                                }
                              >
                                {syncingInProgress ? "Syncing" : "Sync now"}
                              </Button>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              ) : (
                <div className="w-100 card-wrapper mt-5">
                  <Grid className="w-100 mt-5">
                    <Card className="dashboard-card h-auto welcome-card">
                      <CardContent className="card-content">
                        <Grid item md={6} xs={12} className="content">
                          <h3>Welcome back Admin!</h3>
                        </Grid>
                        <Grid item md={6} xs={12} className="banner">
                          <img src={DashboardImg} alt="" />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </div>
              )}
              <Grid item xs={12} className="video flexer">
                <div className="video-wrapper flexer">
                  <VideoView
                    toggler={
                      <PlayCircleFilledWhiteOutlinedIcon
                        style={{ fontSize: 120 }}
                        className="video-icon"
                      />
                    }
                  />
                </div>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <ActivateAccountAlerts
                userData={userData}
                setSnackbarData={setSnackbarData}
              />
              <Grid item xs={12} className="non-verified-user-video flexer">
                <div className="video-wrapper flexer">
                  <VideoView
                    toggler={
                      <PlayCircleFilledWhiteOutlinedIcon
                        style={{ fontSize: 120 }}
                        className="video-icon"
                      />
                    }
                  />
                </div>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    warnings: store.userReducer.warnings,
    dashboardData: store.userReducer.dashboardData,
    userApiDetails: store.userReducer.userApiDetails,
    hasMcError: store.userReducer.hasMcError,
    hasCcbError: store.userReducer.hasCcbError,
    hasCurrentPlan: store.billing.hasCurrentPlan,
    apiDetailsStatus: store.userReducer.apiDetailsStatus,
    currentPlanStatus: store.billing.currentPlanStatus,
    userCurrentPlan: store.billing.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    syncManual: (data) => dispatch(syncManual(data)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    getUserApiDetails: (data) => dispatch(getUserApiDetails(data)),
    getDashboardData: (data) => dispatch(getDashboardData(data)),
    toggleCardDeclineModal: (val) => dispatch(toggleCardDeclineModal(val)),
    setLogSearchTerm: (data) => dispatch(setLogSearchTerm(data)),
    searchLogs: (data, requestId) => dispatch(searchLogs(data, requestId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
