import * as React from "react";
import PropType from "prop-types";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

const SplitButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const {
    className,
    btnHeight,
    options,
    disabled,
    dropdownBtnClass,
    onClick,
    title,
  } = props;

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    onClick();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={{ height: btnHeight }}
      >
        <Button
          style={{ textTransform: "capitalize" }}
          className={`bg-yellow split-btn-divider ${className}`}
          onClick={onClick}
          disabled={disabled}
        >
          {title}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={dropdownBtnClass}
          disabled={disabled}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center left" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

SplitButton.defaultProps = {
  className: "",
  btnHeight: 40,
  disabled: false,
  dropdownBtnClass: "bg-yellow",
  SplitButton: "",
};

SplitButton.propTypes = {
  className: PropType.string,
  btnHeight: PropType.number,
  options: PropType.array.isRequired,
  disabled: PropType.bool,
  dropdownBtnClass: PropType.string,
  onClick: PropType.func.isRequired,
  title: PropType.string,
};

export default SplitButton;
