import React, { useCallback, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { CCB_TO_MC, MC_TO_CCB } from "../../../utils/integrationsConstants";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { formDataWithApiKey } from "../../../helpers";
import { enableTwoWaySyncing } from "../../../store/middlewares/integrationMiddleware";
import "../../../styles/components/integrations/remove-sync-pair.scss";
import {
  changeSyncType,
  clearEnableTwoWaySync,
} from "../../../store/actions/integrationActions";
import { clearUpdateProfile } from "../../../store/middlewares/userMiddleware";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: 0,
    top: 20,
  },
});

const SyncConfirmationModal = (props) => {
  const {
    // Component props
    open,
    handleClose,
    handleSyncPairs,

    // Redux functions
    enableTwoWaySyncing,
    clearEnableTwoWaySync,
    changeSyncType,
    clearUpdateProfile,

    // Redux props
    syncManualStatus,
    currentSync,
    enableTwoWaySyncingStatus,
    updateProfileStatus,
  } = props;
  const classes = useStyles();
  const breakpoint = useBreakpoint();
  const disabledButtons = useMemo(
    () => [enableTwoWaySyncingStatus, syncManualStatus].includes("loading"),
    [enableTwoWaySyncingStatus, syncManualStatus]
  );

  const resetStatus = useCallback(
    (requestId) => () => {
      clearUpdateProfile(requestId);
    },
    [clearUpdateProfile]
  );

  useEffect(() => {
    if (updateProfileStatus["isSkipMcToCcb"] === "success") {
      resetStatus("isSkipMcToCcb");
    }
  }, [resetStatus, updateProfileStatus]);

  useEffect(() => {
    if (enableTwoWaySyncingStatus === "success") {
      changeSyncType(MC_TO_CCB);
      handleClose();
    }
  }, [
    changeSyncType,
    clearEnableTwoWaySync,
    enableTwoWaySyncingStatus,
    handleClose,
  ]);

  const handleEnableTwoWaySyncing = useCallback(() => {
    const formData = formDataWithApiKey();
    enableTwoWaySyncing(formData, MC_TO_CCB);
  }, [enableTwoWaySyncing]);

  const getModalHeading = useMemo(() => {
    const heading = `Sync ${
      currentSync === CCB_TO_MC ? "MailChimp" : "CCB"
    } as well?`;
    return heading;
  }, [currentSync]);

  const getOtherSyncPair = useMemo(() => {
    if (currentSync === CCB_TO_MC) {
      return "MailChimp to CCB";
    } else if (currentSync === MC_TO_CCB) {
      return "CCB to MailChimp";
    }
  }, [currentSync]);

  const getCurrentSyncablePair = useMemo(() => {
    if (currentSync === CCB_TO_MC) {
      return "CCB to MailChimp";
    } else if (currentSync === MC_TO_CCB) {
      return "MailChimp to CCB";
    }
  }, [currentSync]);

  const getModalContent = useMemo(() => {
    const content = `Do you want to Sync ${getOtherSyncPair} as well (two-way Integration) or just proceed with ${getCurrentSyncablePair} one-way Integration.`;
    return content;
  }, [getCurrentSyncablePair, getOtherSyncPair]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0" : classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">{getModalHeading}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{getModalContent}</DialogContentText>
      </DialogContent>
      <DialogActions className="mb-1 flexer-column">
        <div className="d-flex justify-content-between w-100">
          <ButtonWithSpinner
            className="ml-3 dark-gray-button"
            loading={enableTwoWaySyncingStatus === "loading"}
            disabled={disabledButtons}
            onClick={handleEnableTwoWaySyncing}
          >
            Enable 2-way
          </ButtonWithSpinner>
          <ButtonWithSpinner
            className="bg-yellow"
            loading={syncManualStatus === "loading"}
            disabled={disabledButtons}
            onClick={handleSyncPairs}
          >
            Proceed with 1-way
          </ButtonWithSpinner>
        </div>
        <DialogContentText
          variant="subtitle2"
          className="flexer-end w-100 mr-6 mt-1"
        >
          ({getCurrentSyncablePair} only)
        </DialogContentText>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return {
    enableTwoWaySyncingStatus: store.integrations.enableTwoWaySyncingStatus,
    currentSync: store.integrations.currentSync,
    syncManualStatus: store.integrations.syncManualStatus,
    updateProfileStatus: store.userReducer.updateProfileStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableTwoWaySyncing: (data, requestId) =>
      dispatch(enableTwoWaySyncing(data, requestId)),
    changeSyncType: (sync) => dispatch(changeSyncType(sync)),
    clearEnableTwoWaySync: () => dispatch(clearEnableTwoWaySync()),
    clearUpdateProfile: (requestId) => dispatch(clearUpdateProfile(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncConfirmationModal);
