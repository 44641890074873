import config from "../../config";
import { postRequest } from "../../utils/request";
import { ACTION_TYPES } from "../actions/types";

// CCB middlewares
export function getCcbCampuses(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_CCB_CAMPUSES,
      config.Path.GET_ALL_CCB_CAMPUSES,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function getCcbSavedSearches(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_CCB_SAVED_SEARCHES,
      config.Path.GET_CCB_SAVED_SEARCHES,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function saveCcbToMcSyncPair(
  data,
  isFixingBrokenPairs = false,
  ids = []
) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR,
      config.Path.SAVE_CCB_TO_MC_SYNC_PAIR,
      data,
      {
        requestDispatchData: { isFixingBrokenPairs },
        successDispatchData: { isFixingBrokenPairs, data, ids },
        errorDispatchData: { isFixingBrokenPairs },
      }
    );
}

export function removeCcbToMcSyncPair(data, isRemoveFromBrokenPairModal, id) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR,
      config.Path.REMOVE_CCB_TO_MC_SYNC_PAIR,
      data,
      {
        requestDispatchData: { isRemoveFromBrokenPairModal },
        successDispatchData: { isRemoveFromBrokenPairModal, data, id },
        errorDispatchData: { isRemoveFromBrokenPairModal },
      }
    );
}

// Mailchimp middlewares
export function getMailchimpAudience(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_MAILCHIMP_AUDIENCE,
      config.Path.GET_MAILCHIMP_AUDIENCE,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function removeMcToCcbSyncPair(data, isRemoveFromBrokenPairModal) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR,
      config.Path.REMOVE_MC_TO_CCB_SYNC_PAIR,
      data,
      {
        requestDispatchData: { isRemoveFromBrokenPairModal },
        successDispatchData: { isRemoveFromBrokenPairModal, data },
        errorDispatchData: { isRemoveFromBrokenPairModal },
      }
    );
}

export function saveMcToCcbSyncPair(data, isFixingBrokenPairs = false) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR,
      config.Path.SAVE_MC_TO_CCB_SYNC_PAIR,
      data,
      {
        requestDispatchData: { isFixingBrokenPairs },
        successDispatchData: { isFixingBrokenPairs, data },
        errorDispatchData: { isFixingBrokenPairs },
      }
    );
}

export function getMcToCcbPairs(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_MC_TO_CCB_PAIRS,
      config.Path.GET_MC_TO_CCB_PAIRS,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

// SYNC PAIRS MIDDLEWARES

export function syncManual(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SYNC_MANUAL,
      config.Path.SYNC_MANUAL,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function syncOnlyNewPairs(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SYNC_ONLY_NEW_PAIRS,
      config.Path.SYNC_ONLY_NEW,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function enableTwoWaySyncing(data, switchIdentity = "") {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ENABLE_TWO_WAY_SYNCING,
      config.Path.ENABLE_TWO_WAY,
      data,
      {
        requestDispatchData: { switchIdentity },
        successDispatchData: { switchIdentity, data },
        errorDispatchData: { switchIdentity },
      }
    );
}

export function disableTwoWaySyncing(data, switchIdentity = "") {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.DISABLE_TWO_WAY_SYNCING,
      config.Path.DISABLE_TWO_WAY,
      data,
      {
        requestDispatchData: { switchIdentity },
        successDispatchData: { switchIdentity, data },
        errorDispatchData: { switchIdentity },
      }
    );
}

export function refreshList(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REFRESH_LIST,
      config.Path.REFRESH_LIST,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function getUnresolveConflictsPairs(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_UNRESOLVE_CONFLICTS_PAIRS,
      config.Path.GET_UNRESOLVE_CONFLICTS_PAIRS,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getHoldContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_HOLD_CONTACTS,
      config.Path.GET_HOLD_CONTACTS,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getPossibleMatches(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_POSSIBLE_MATCHES,
      config.Path.GET_POSSIBLE_MATCHES,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function matchContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.MATCH_CONTACTS,
      config.Path.MATCH_CONTACTS,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function ignoreHoldContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.IGNORE_HOLD_CONTACTS,
      config.Path.IGNORE_CONTACTS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function ignoreAllHoldContacts(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS,
      config.Path.IGNORE_ALL_HOLD_CONTACTS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function setMailchimpInterestedGroups(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SET_MAILCHIMP_INTERESTED_GROUPS,
      config.Path.GET_MAILCHIMP_INTEREST_GROUPS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function setCcbGroups(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SET_CCB_GROUP,
      config.Path.GET_CCB_GROUPS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function setMailchimpTags(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SET_MAILCHIMP_TAGS,
      config.Path.GET_MAILCHIMP_AUDIENCE_TAG,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function removeMultipleMcToCcbPairs(data, ids) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR,
      config.Path.REMOVE_MULTIPLE_MC_TO_CCB_PAIRS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data, ids },
        errorDispatchData: { data },
      }
    );
}

export function stopSync(data) {
  return (dispatch) =>
    postRequest(dispatch, ACTION_TYPES.STOP_SYNC, config.Path.STOP_SYNC, data, {
      requestDispatchData: {},
      successDispatchData: { data },
      errorDispatchData: {},
    });
}

export function removeMultipleCcbToMcbPairs(data, ids) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR,
      config.Path.REMOVE_MULTIPLE_CCB_TO_MC_PAIRS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data, ids },
        errorDispatchData: { data },
      }
    );
}

export function setCcbProcessQueue(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SET_CCB_PROCESS_QUEUES,
      config.Path.GET_PROCESS_QUEUE,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function checkIfHasSavedSearchPermissions(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS,
      config.Path.HAS_SEARCH_PERMISSION,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}
