import React, { useState, useCallback, useEffect } from "react";
import lodash from "lodash";
import { connect } from "react-redux";
import { Button, Grid, ListItem, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PaymentCard from "../../assets/img/payment-cards.png";
import StartingPackageImg from "../../assets/img/starting-package.png";
import {
  checkCoupon,
  getAllAvailablePlans,
} from "../../store/middlewares/billingMiddleware";
import { benefitsKeys, benefitLabels, formDataWithApiKey } from "../../helpers";
import Loader from "../Loader/Loader";
import CouponTextField from "./CouponTextField";

const tabs = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly (save 15%)",
    value: "yearly",
  },
];

const SubscriptionPlans = (props) => {
  const [tabValue, setTabValue] = useState("yearly");
  const {
    // Component props
    history,

    // Redux functions
    getAllAvailablePlans,
    checkCoupon,

    // Redux props
    subscriptionPlans,
    subscriptionPlansStatus,
    isValidCoupon,
    checkCouponStatus,
    couponPlan,
  } = props;

  useEffect(() => {
    const formData = formDataWithApiKey();
    getAllAvailablePlans(formData);
  }, [getAllAvailablePlans]);

  const handleTabValueChange = useCallback(
    (tabValue) => () => {
      setTabValue(tabValue);
    },
    []
  );

  return (
    <div>
      <div className="subscription-plans-page vh-100">
        <Grid container className="content" spacing={2}>
          <Grid item className="left-content">
            <div>
              <Typography className="mt-4" variant="h5">
                Subscriptions
              </Typography>
              <Close
                className="close-icon mr-2"
                onClick={() => history.goBack()}
              />
            </div>
            <Typography variant="h6" className="text-center mt-2">
              Two-way syncing is now available for ALL Accounts
            </Typography>

            <React.Fragment>
              <div className="tab-container">
                <div className="w-100 flexer mb-4 mt-4">
                  {tabs.map((tab) => (
                    <div
                      key={tab.value}
                      onClick={handleTabValueChange(tab.value)}
                      className={`billing-tabs ${
                        tabValue === tab.value ? "selected" : ""
                      }`}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>

                <Grid container className="container">
                  {!lodash.isEmpty(subscriptionPlans) &&
                    subscriptionPlans[tabValue].map((plan, idx) => (
                      <Grid
                        item
                        xs={4}
                        className={
                          plan.isRecommended
                            ? "single-card featured"
                            : "single-card"
                        }
                        key={idx}
                      >
                        {plan.isRecommended && (
                          <div className="popular-tag">Most popular</div>
                        )}
                        <Typography className="plan-title">
                          {plan.name}
                        </Typography>
                        <img src={StartingPackageImg} alt="starting-package" />
                        <Typography className="price-tag" component={"h2"}>
                          ${plan.monthlyAmount} / mo
                        </Typography>
                        <div className="features">
                          {tabValue === "yearly" && (
                            <ListItem className="single features-main">
                              <Typography
                                className={
                                  plan.isRecommended
                                    ? "text-white-bold"
                                    : "color-primary"
                                }
                                variant="subtitle1"
                              >
                                Paid Annually ${plan.annualAmount} / yr
                              </Typography>
                            </ListItem>
                          )}

                          {benefitsKeys.map((el, index) => (
                            <ListItem className="single mt-1" key={index}>
                              <div className="flexer-column">
                                <Typography variant="subtitle1">
                                  {benefitLabels[el]}
                                </Typography>
                                <Typography
                                  className={
                                    plan.isRecommended
                                      ? "text-white-bold"
                                      : "color-primary"
                                  }
                                  variant="subtitle2"
                                >
                                  {plan[el]}
                                </Typography>
                              </div>
                            </ListItem>
                          ))}
                          <CouponTextField
                            checkCoupon={checkCoupon}
                            isValidCoupon={isValidCoupon}
                            checkCouponStatus={checkCouponStatus}
                            subscriptionPlan={plan}
                            couponPlan={couponPlan}
                          />
                        </div>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: plan.isRecommended
                              ? "contained-white"
                              : "contained-primary",
                          }}
                          onClick={() =>
                            history.push("/change-plan", {
                              subscriptionPlan: plan,
                              isYearly: tabValue === "yearly" ? 1 : 0,
                            })
                          }
                        >
                          {plan.isCurrent ? "My plan" : "Choose this plan"}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </React.Fragment>
          </Grid>
          <Grid item className="right-content">
            <img src={PaymentCard} alt="payment card" />
          </Grid>
        </Grid>
      </div>

      {subscriptionPlansStatus === "loading" && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    subscriptionPlansStatus: store.billing.subscriptionPlansStatus,
    subscriptionPlans: store.billing.subscriptionPlans,
    isValidCoupon: store.billing.isValidCoupon,
    checkCouponStatus: store.billing.checkCouponStatus,
    couponPlan: store.billing.couponPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAvailablePlans: (formData) =>
      dispatch(getAllAvailablePlans(formData)),
    checkCoupon: (formData, subscriptionPlan) =>
      dispatch(checkCoupon(formData, subscriptionPlan)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);
