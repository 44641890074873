const BASE_URL = process.env.REACT_APP_API_URL;

const path = {
  // User Auth Api's

  LOGIN: `${BASE_URL}/user/login`,

  REGISTER: `${BASE_URL}/user/register`,

  RESET_PASSWORD: `${BASE_URL}/user/reset-password`,

  CHANGE_PASSWORD: `${BASE_URL}/user/change-password`,

  RESEND_ACTIVATION_EMAIL: `${BASE_URL}/user/resend-activation`,

  MARK_PENDING: `${BASE_URL}/user/mark-pending`,

  UPDATE_PROFILE: `${BASE_URL}/account/update`,

  UPDATE_COUNTRY: `${BASE_URL}/account/update-country`,

  GET_API_DETAILS: `${BASE_URL}/account/api-details`,

  GET_USER_DETAILS: `${BASE_URL}/account/details`,

  IS_AUTO_SYNCING: `${BASE_URL}/account/is-syncing`,

  GET_WARNINGS: `${BASE_URL}/account/warnings`,

  // Config
  GET_INTERCOM_APP_ID: `${BASE_URL}/config/intercom-app-id`,

  // Integration page Api's

  GET_CCB_SAVED_SEARCHES: `${BASE_URL}/ccb/all-saved-searches`,

  GET_ALL_CCB_CAMPUSES: `${BASE_URL}/ccb/all-campus`,

  GET_CCB_GROUPS: `${BASE_URL}/ccb/all-groups`,

  GET_CCB_SAVED_SEARCHES_INFO: `${BASE_URL}/ccb/saved-search`,

  GET_MC_TO_CCB_PAIRS: `${BASE_URL}/ccb/get-mc-to-ccb`,

  GET_MAILCHIMP_AUDIENCE: `${BASE_URL}/mailchimp/all-audience`,

  GET_MAILCHIMP_AUDIENCE_TAG: `${BASE_URL}/mailchimp/tags`,

  GET_MAILCHIMP_INTEREST_GROUPS: `${BASE_URL}/mailchimp/interest-groups`,

  SAVE_CCB_TO_MC_SYNC_PAIR: `${BASE_URL}/sync-pair/save-ccb-mc-multiple`,

  SAVE_MC_TO_CCB_SYNC_PAIR: `${BASE_URL}/sync-pair/save-mc-to-ccb-multiple`,

  REMOVE_CCB_TO_MC_SYNC_PAIR: `${BASE_URL}/sync-pair/remove-ccb-mc`,

  REMOVE_MULTIPLE_CCB_TO_MC_PAIRS: `${BASE_URL}/sync-pair/remove-multiple-ccb-mc`,

  REMOVE_MC_TO_CCB_SYNC_PAIR: `${BASE_URL}/sync-pair/remove-mc-to-ccb`,

  SYNC_MANUAL: `${BASE_URL}/sync/manual`,

  SYNC_ONLY_NEW: `${BASE_URL}/sync/new-only`,

  ENABLE_TWO_WAY: `${BASE_URL}/sync/enable-two-way`,

  DISABLE_TWO_WAY: `${BASE_URL}/sync/disable-two-way`,

  GET_UNRESOLVE_CONFLICTS_PAIRS: `${BASE_URL}/sync-pair/simple-view`,

  REMOVE_MULTIPLE_MC_TO_CCB_PAIRS: `${BASE_URL}/sync-pair/remove-multiple-mc-to-ccb`,

  HAS_SEARCH_PERMISSION: `${BASE_URL}/sync/has-search-permission`,

  GET_HOLD_CONTACTS: `${BASE_URL}/dupe/hold-contacts`,

  GET_ALL_HOLD_CONTACTS: `${BASE_URL}/dupe/all-hold-contacts`,

  GET_POSSIBLE_MATCHES: `${BASE_URL}/dupe/possible-matches`,

  MATCH_CONTACTS: `${BASE_URL}/dupe/match-contact`,

  IGNORE_CONTACTS: `${BASE_URL}/dupe/ignore-contact`,

  IGNORE_ALL_HOLD_CONTACTS: `${BASE_URL}/dupe/ignore-all-contact`,

  GET_DASHBOARD_DATA: `${BASE_URL}/sync/dashboard-data`,

  GET_PROCESS_QUEUE: `${BASE_URL}/ccb/all-campus-process-list`,

  // Logs API's

  GET_LOGS: `${BASE_URL}/logs/list`,

  GET_LOG_DETAILS: `${BASE_URL}/logs/log-details`,

  GET_LOG_STATISTICS: `${BASE_URL}/logs/statistic-details`,

  SEARCH_LOGS: `${BASE_URL}/logs/search`,

  SEARCH_CCB_TO_MC: `${BASE_URL}/logs/logs-search`,

  SEARCH_MC_TO_CCB: `${BASE_URL}/logs/logs-search-mc`,

  REVERT_LOGS: `${BASE_URL}/logs/revert`,

  GET_MC_REQUIRED_MERGE_FIELDS: `${BASE_URL}/logs/view-mc-required-merged-fields`,

  // Authorize page api's

  AUTHORIZE_CCB_ACCOUNT: `${BASE_URL}/ccb/authorize`,

  AUTHORIZE_MC_ACCOUNT: `${BASE_URL}/mailchimp/authorize`,

  REMOVE_CCB_CREDENTIALS: `${BASE_URL}/ccb/remove`,

  REMOVE_MC_CREDENTIALS: `${BASE_URL}/mailchimp/remove`,

  ENABLE_CCB_SYNCING: `${BASE_URL}/sync/enable`,

  DISABLE_CCB_SYNCING: `${BASE_URL}/sync/disable`,

  ENABLE_STORE_USER_INFO: `${BASE_URL}/sync/enable-store-user-info`,

  DISABLE_STORE_USER_INFO: `${BASE_URL}/sync/disable-store-user-info`,

  REFRESH_LIST: `${BASE_URL}/sync/refresh-list`,

  STOP_SYNC: `${BASE_URL}/sync/stop-sync`,

  // CHECK SYNCING PROGRESS

  CHECK_SYNC: `${BASE_URL}/sync/check`,

  // TOOLS SCREENS API'S

  SAVE_TOOLKIT_CLEANUP: `${BASE_URL}/tools/save-cleanup`,

  SAVE_STORE_USER_INFO: `${BASE_URL}/tools/save-store-user-info`,

  SAVE_AUTO_SYNC: `${BASE_URL}/tools/save-auto-sync`,

  SAVE_PRIMARY_PROFILES: `${BASE_URL}/tools/save-primary-profiles`,

  SAVE_MC_TO_CCB_REMOVE_INACTIVE: `${BASE_URL}/tools/save-mc-to-ccb-remove-inactive`,

  SAVE_EMAIL_NOTIFICATION_FOR_UNSUB: `${BASE_URL}/tools/save-email-notification-for-unsub`,

  SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES: `${BASE_URL}/tools/save-email-notification-for-pending-dupe`,

  SAVE_DELETE_MC_LIST_GROUP: `${BASE_URL}/tools/save-delete-mc-list-group`,

  SAVE_MEMBERS_SETTINGS: `${BASE_URL}/tools/save-member-settings`,

  SAVE_SKIP_MERGE_VALIDATION: `${BASE_URL}/tools/save-skip-merge-validation`,

  SAVE_CHECK_RECEIVED_EMAIL: `${BASE_URL}/tools/save-check-received-email`,

  ACCOUNT_CLEAN_UP: `${BASE_URL}/tools/clean-up`,

  GET_PRIMARY_CONTACTS: `${BASE_URL}/tools/primary-contacts`,

  MAKE_PRIMARY_CONTACTS: `${BASE_URL}/tools/primary`,

  GET_IGNORED_EMAIL: `${BASE_URL}/tools/ignored-emails`,

  UNIGNORE_EMAIL: `${BASE_URL}/tools/unignore`,

  GET_CLEANUP_UNSYNC_PAIR: `${BASE_URL}/tools/cleanup-unsync-pairs`,

  DISMISS_DELETE_MC: `${BASE_URL}/tools/dismiss-delete-mc-now`,

  DELETE_MC: `${BASE_URL}/tools/delete-mc-now`,

  GET_HEADER_MAPPING: `${BASE_URL}/tools/header-mappings`,

  SAVE_HEADER_MAPPING: `${BASE_URL}/tools/save-header-mappings`,

  GET_MC_TO_CCB_CUSTOM_LIST: `${BASE_URL}/tools/mc-to-ccb-custom-list`,

  ADD_MC_TO_CCB_CUSTOM_LIST: `${BASE_URL}/tools/add-mc-to-ccb-custom-multiple`,

  DELETE_MC_TO_CCB_CUSTOM_LIST: `${BASE_URL}/tools/delete-mc-to-ccb-custom`,

  // BILLINGS PAGE API'S

  GET_ALL_INVOICES: `${BASE_URL}/billing/invoices`,

  GET_INVOICE_DETAILS: `${BASE_URL}/billing/invoice-details`,

  GET_ALL_AVAILABLE_PLANS: `${BASE_URL}/billing/all-plans`,

  GET_CURRENT_PLAN: `${BASE_URL}/billing/current-plan`,

  CHECK_COUPON: `${BASE_URL}/billing/check-coupon`,

  CANCEL_PLAN: `${BASE_URL}/billing/cancel-plan`,

  GET_CURRENT_CARD_DETAILS: `${BASE_URL}/billing/current-card-details`,

  ADD_NEW_CARD: `${BASE_URL}/billing/new-card`,

  UPDATE_CURRENT_CARD: `${BASE_URL}/billing/update-card`,

  GET_STRIPE_PUBLISABLE_KEY: `${BASE_URL}/billing/stripe-publishable-key`,

  PURCHASE_PLAN: `${BASE_URL}/billing/purchase-plan`,

  // Admin API's

  GET_ALL_USERS: `${BASE_URL}/admin/users`,

  GET_PENDING_USERS: `${BASE_URL}/admin/pending-users`,

  CREATE_USER: `${BASE_URL}/admin/add-user`,

  APPROVE_USER: `${BASE_URL}/admin/approve-user`,

  // Countries

  GET_ALL_COUNTRIES: `${BASE_URL}/countries/list`,
};

export default path;
