import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import {
  Dialog,
  Grid,
  Slide,
  Typography,
  Button,
  ListItem,
} from "@material-ui/core";
import PaymentCard from "../../../assets/img/payment-cards.png";
import StartingPackageImg from "../../../assets/img/starting-package.png";
import { Close } from "@material-ui/icons";
import {
  benefitLabels,
  benefitsKeys,
  formDataWithApiKey,
  getUserName,
} from "../../../helpers";
import {
  getCurrentCardDetails,
  purchasePlan,
} from "../../../store/middlewares/billingMiddleware";
import { setSnackbarData } from "../../../store/actions/globalAction";
import {
  clearCouponState,
  clearPurhcasePlanState,
} from "../../../store/actions/billingActions";
import { getWarnings } from "../../../store/middlewares/userMiddleware";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import NotficationBanner from "../../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../../utils/notifications";
import ReactHtmlParser from "react-html-parser";
import "../../../styles/components/more/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const subscribtionPlanIds = {
  Starter: 1,
  Med: 2,
  Pro: 3,
};

const ChangeSubscriptionPlan = (props) => {
  const isDowngraded = false;
  const {
    // Component props
    history,
    location: { state },

    // Redux functions
    getCurrentCardDetails,
    setSnackbarData,
    purchasePlan,
    clearPurhcasePlanState,
    clearCouponState,
    getWarnings,

    // Redux props
    purchasePlanStatus,
    currentCard,
    hasCard,
    coupon,
    isValidCoupon,
    couponPlan,
    userData,
    currentPlan,
    warnings,
    successMessage,
    errorMessage,
    couponTrialDays,
  } = props;
  const [showConfirmationModal, setshowConfirmationModal] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState("");

  const closeConfirmationModal = useCallback(() => {
    setshowConfirmationModal(false);
  }, []);

  useEffect(() => {
    if (purchasePlanStatus === "success") {
      const formData = formDataWithApiKey();
      closeConfirmationModal();
      setSnackbarData({
        snackbarSeverity: successMessage ? "success" : "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(successMessage || errorMessage),
        autoHideDuration: 20000,
      });
      clearPurhcasePlanState();
      clearCouponState();
      getWarnings(formData);
      history.push("/billing");
    }
  }, [
    clearCouponState,
    clearPurhcasePlanState,
    closeConfirmationModal,
    errorMessage,
    getWarnings,
    history,
    purchasePlanStatus,
    setSnackbarData,
    successMessage,
  ]);

  useEffect(() => {
    // GET CURRENT CARD DETAILS
    const formData = formDataWithApiKey();
    getCurrentCardDetails(formData);
  }, [getCurrentCardDetails]);

  useEffect(() => {
    if (!state) {
      history.goBack();
    }
  }, [history, state]);

  const handlePayClicked = useCallback(() => {
    setshowConfirmationModal(true);
    setConfirmationModalContent(
      `Are you sure you want to subscribe to the ${
        state.subscriptionPlan.name
      } / ${
        state.subscriptionPlan.annualAmount === 0 ? "Monthly" : "Yearly"
      } plan?`
    );
  }, [state]);

  const handlePurchasePlan = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("packageId", state.subscriptionPlan.id);
    formData.append("isYearly", state.isYearly);
    formData.append("cardType", "current");
    if (coupon && isValidCoupon) {
      formData.append("couponCode", coupon);
    }
    purchasePlan(formData);
  }, [coupon, isValidCoupon, purchasePlan, state]);

  const getPlanPrice = useCallback(() => {
    return `<b>$${
      state.subscriptionPlan.annualAmount ||
      state.subscriptionPlan.monthlyAmount
    } Per ${state.subscriptionPlan.annualAmount !== 0 ? "year" : "month"}</b>`;
  }, [state]);

  const hasYearlyPlan = useCallback(() => {
    if (
      !lodash.isEmpty(currentPlan) &&
      currentPlan.amountDisplay.includes("year")
    ) {
      return true;
    }
    return false;
  }, [currentPlan]);

  const isDowngradingPlan = useMemo(() => {
    if (!state?.subscriptionPlan?.isCurrent) {
      if (
        state.isYearly === 1 &&
        hasYearlyPlan() &&
        state.subscriptionPlan?.id < subscribtionPlanIds[currentPlan.name]
      ) {
        return true;
      } else if (state.isYearly === 0 && hasYearlyPlan()) {
        return true;
      } else if (
        state.isYearly === 0 &&
        !hasYearlyPlan() &&
        state.subscriptionPlan?.id < subscribtionPlanIds[currentPlan.name]
      ) {
        return true;
      }
    }
    return false;
  }, [currentPlan, hasYearlyPlan, state]);

  const getButtonText = useMemo(() => {
    if (lodash.isEmpty(currentPlan)) {
      return "Pay";
    } else if ([1, 2].includes(warnings?.hasBillingIssue)) {
      return "Pay now";
    } else {
      if (isDowngradingPlan) {
        return "Downgrade now";
      } else {
        return "Upgrade now";
      }
    }
  }, [currentPlan, isDowngradingPlan, warnings]);

  const closeModal = () => {
    clearCouponState();
    history.goBack();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={() => history.goBack()}
        TransitionComponent={Transition}
        classes={{
          root: "subscription-plans-page order-details",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <Grid container className="content" spacing={2}>
          <Grid item className="left-content">
            <div>
              <Typography className="mt-4 mb-3" variant="h5">
                Order Details
              </Typography>
              {isValidCoupon &&
                !lodash.isEmpty(couponPlan) &&
                couponPlan.id === state.subscriptionPlan.id && (
                  <NotficationBanner
                    {...notificationProps["couponMessage"]}
                    message={ReactHtmlParser(`Congratulations, ${getUserName(
                      userData
                    )}!
              You have unlocked your ${couponTrialDays}-day, risk-free trial.
              You will pay <b>$0 today</b>.
              After Day ${couponTrialDays}, you will be billed at ${getPlanPrice()}.`)}
                  />
                )}

              {isDowngradingPlan && (
                <NotficationBanner
                  {...notificationProps["downgradingPlanMessage"]}
                />
              )}

              {[1, 2].includes(warnings?.hasBillingIssue) && (
                <NotficationBanner
                  {...notificationProps["paymentFailedSecondMessage"]}
                />
              )}

              <Close className="close-icon mr-2" onClick={closeModal} />
            </div>
            <Grid container>
              <Grid item style={{ width: "12%" }}>
                <div className="banner-image">
                  <img src={StartingPackageImg} alt="" />
                </div>
              </Grid>
              <Grid item style={{ width: "85%" }} className="offer-info">
                <Typography component="h2" className="plan-title">
                  {state.subscriptionPlan.name} /{" "}
                  {state.subscriptionPlan.annualAmount === 0
                    ? "Monthly"
                    : "Yearly"}
                </Typography>
                <Typography component="h2" className="price-tag">
                  $ {state.subscriptionPlan.monthlyAmount} / mo{" "}
                  {state.subscriptionPlan.annualAmount !== 0 && (
                    <span className="small-text">
                      Paid Annually (${state.subscriptionPlan.annualAmount} /
                      yr)
                    </span>
                  )}
                </Typography>
                <div className="features">
                  {benefitsKeys.map((item, idx) => (
                    <ListItem key={idx} className="single">
                      {benefitLabels[item]}? &nbsp;
                      <span className="font-weight-bold">
                        ({state.subscriptionPlan[item]})
                      </span>
                    </ListItem>
                  ))}
                </div>
                <div className="coupon-applied">
                  <div className="after-coupon-amount">
                    <Typography component={"h5"}>
                      You will be charged
                    </Typography>
                    <Typography component={"h5"}>
                      {ReactHtmlParser(getPlanPrice())}
                    </Typography>
                  </div>
                </div>

                <div className="payment-method">
                  <div className="payment-title">
                    <Typography component={"h5"}>Payment method</Typography>
                  </div>
                  {isDowngraded && (
                    <div className="btn-wrapper">
                      <div className="alert-warning">
                        You are about to downgrade from medium plan to starting
                        plan
                      </div>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        classes={{
                          root: "btn",
                          label: "label",
                          containedPrimary: "contained-primary",
                        }}
                      >
                        {isDowngraded ? "Pay and Downgrade" : "Pay"}
                      </Button>
                    </div>
                  )}
                  <div className="payment-detail">
                    <Typography component={"h5"}>
                      {hasCard
                        ? `Card Ending ${currentCard[0].last4}`
                        : "please add a card before payment."}
                    </Typography>
                    <Button
                      color="primary"
                      variant="text"
                      classes={{
                        root: "btn",
                        label: "text-color",
                        textPrimary: "text-primary",
                      }}
                      onClick={() => history.push("/setup-payment")}
                    >
                      {hasCard ? "Change" : "Add"}
                    </Button>
                  </div>
                  {hasCard &&
                    (!state?.subscriptionPlan?.isCurrent ||
                      [1, 2].includes(warnings?.hasBillingIssue)) && (
                      <div className="d-flex align-items-end justify-content-end mt-3">
                        <ButtonWithSpinner
                          onClick={handlePayClicked}
                          className="bg-yellow"
                          loading={purchasePlanStatus === "loading"}
                        >
                          {getButtonText || "Pay"}
                        </ButtonWithSpinner>
                      </div>
                    )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="right-content">
            <img src={PaymentCard} alt="payment card" />
          </Grid>
        </Grid>
      </Dialog>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          modalContent={confirmationModalContent}
          modalHeading={"Confirm"}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30 mr-3"}
          handleClose={closeConfirmationModal}
          onClick={handlePurchasePlan}
          loading={purchasePlanStatus === "loading"}
          disabled={purchasePlanStatus === "loading"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    hasCurrentPlan: store.billing.hasCurrentPlan,
    currentPlan: store.billing.currentPlan,
    currentCard: store.billing.currentCard,
    hasCard: store.billing.hasCard,
    purchasePlanStatus: store.billing.purchasePlanStatus,
    coupon: store.billing.coupon,
    couponPlan: store.billing.couponPlan,
    isValidCoupon: store.billing.isValidCoupon,
    couponTrialDays: store.billing.couponTrialDays || 0,
    successMessage: store.billing.successMessage,
    errorMessage: store.billing.errorMessage,
    userData: store.userReducer.userData,
    warnings: store.userReducer.warnings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getCurrentCardDetails: (formData) =>
      dispatch(getCurrentCardDetails(formData)),
    purchasePlan: (formData) => dispatch(purchasePlan(formData)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    clearPurhcasePlanState: () => dispatch(clearPurhcasePlanState()),
    clearCouponState: () => dispatch(clearCouponState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSubscriptionPlan);
