import { useSelector } from "react-redux";

export function useBillingStore() {
  const _billing = useSelector((store) => store.billing);

  return {
    billing: _billing,
  };
}

export default useBillingStore;
