import React, { useCallback, useState } from "react";
import { Alert } from "@material-ui/lab";
import services from "../../services";
import Loader from "../Loader/Loader";

const { api } = services;

const ActivateAccountAlerts = (props) => {
  const [loading, setLoading] = useState(false);
  const { userData, setSnackbarData } = props;

  const handleResendActivationEmail = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("email", userData.email);
      setLoading(true);
      const apiResponse = await api.resendActivationEmail(formData);
      if (apiResponse.status) {
        setLoading(false);
        setSnackbarData({
          snackbarSeverity: "success",
          showSnackbar: true,
          snackbarMessage: "Activation email sent successfully!!",
        });
      }
    } catch (error) {
      setLoading(false);
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: error.message,
      });
    }
  }, [setSnackbarData, userData]);

  return (
    <div className="w-100 card-wrapper flexer-column">
      <Alert variant="filled" severity="warning" className="w-100">
        Please activate your account
      </Alert>
      <Alert variant="filled" severity="info" className="w-100 mt-2 mb-4">
        Click{" "}
        <span className="span-anchor" onClick={handleResendActivationEmail}>
          here to resend
        </span>{" "}
        activation email
      </Alert>
      {loading && <Loader />}
    </div>
  );
};

export default ActivateAccountAlerts;
