import React, { Fragment, useCallback } from "react";
import PropType from "prop-types";
import {
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";

const SearchBox = (props) => {
  const { placeholder, handleChange, name, closeSearch, searchValue } = props;

  const hideSearch = useCallback(() => {
    handleChange({ target: { value: "" } });
    closeSearch("");
  }, [closeSearch, handleChange]);

  const clearSearch = useCallback(() => {
    handleChange({ target: { value: "" } });
  }, [handleChange]);

  return (
    <Fragment>
      <FormControl className="form-control">
        <TextField
          className="search"
          id="input-with-icon-textfield"
          placeholder={placeholder}
          onChange={handleChange}
          name={name}
          value={searchValue}
          autoFocus
          variant="outlined"
          InputProps={{
            classes: {
              notchedOutline: "fieldset",
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton className="icon-button">
                  <Search fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch} className="icon-button">
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <span onClick={hideSearch} className="cursor-pointer cancel-btn">
        Cancel
      </span>
    </Fragment>
  );
};

SearchBox.defaultProps = {
  placeholder: "Search",
  name: "",
};

SearchBox.propTypes = {
  handleChange: PropType.func.isRequired,
  toggleSearchBox: PropType.func.isRequired,
  placeholder: PropType.string,
  name: PropType.string,
};

export default SearchBox;
