import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dialog, Typography, Slide, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import BillingTableBody from "../BillingTableBody";
import { formDataWithApiKey } from "../../../helpers";
import EmptyMessageBox from "../../EmptyMessageBox/EmptyMessageBox";
import BillingBreadCrumb from "../BillingBreadCrumb";
import { getAllInvoices } from "../../../store/middlewares/billingMiddleware";
import Loader from "../../Loader/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cells = [
  {
    text: "Date",
  },
  {
    text: "Transaction #",
  },
  {
    text: "Amount",
  },
];

const breadcrumbs = [
  {
    text: `Invoices`,
    route: `/billing/invoices`,
  },
];

const Invoices = (props) => {
  const {
    // component props
    history,

    // Redux functions
    getAllInvoices,

    // Redux props
    invoices,
    invoicesStatus,
    invoicesPage,
    invoicesLoadedAll,
    invoicesLoadingMoreStatus,
  } = props;

  const toggleModal = useCallback(() => {
    history.push("/billing");
  }, [history]);

  useEffect(() => {
    // GET ALL INVOICES
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    if (!invoices.length) {
      getAllInvoices(formData);
    }
  }, [getAllInvoices, invoices]);

  const loadMore = useCallback(() => {
    if (
      !invoicesLoadedAll &&
      invoicesStatus !== "loading" &&
      invoicesStatus !== "error" &&
      invoices
    ) {
      const formData = formDataWithApiKey();
      const pageNum = Number(invoicesPage) + 1;
      formData.append("page", pageNum);
      getAllInvoices(formData);
    }
  }, [
    getAllInvoices,
    invoices,
    invoicesLoadedAll,
    invoicesPage,
    invoicesStatus,
  ]);

  const loadingMore = invoicesLoadingMoreStatus === "loading";

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Invoices</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <BillingBreadCrumb breadcrumbs={breadcrumbs} />
          </div>
          <div className="flexer-column mt-4">
            <div className="user-settings-greetings">
              {invoicesStatus === "loading" && !loadingMore ? (
                <div className="d-flex justify-content-center spinner-container">
                  <Loader />
                </div>
              ) : (
                <TableInfinityScroll
                  className="logs-modal-table"
                  loadMore={loadMore}
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                    />
                    <BillingTableBody
                      data={invoices}
                      tableIdentity="isInvoices"
                      isLoading={loadingMore}
                    />
                  </table>
                  {!Boolean(invoices.length) && (
                    <EmptyMessageBox message="No Invoices" hasBorder={false} />
                  )}
                </TableInfinityScroll>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    invoices: store.billing.invoices,
    invoicesStatus: store.billing.invoicesStatus,
    invoicesPage: store.billing.invoicesPage,
    invoicesLoadedAll: store.billing.invoicesLoadedAll,
    invoicesLoadingMoreStatus: store.billing.invoicesLoadingMoreStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInvoices: (formData) => dispatch(getAllInvoices(formData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoices));
