import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ErrorRounded, Close } from "@material-ui/icons";
import ButtonWithSpinner from "../../../ButtonWithSpinner/ButtonWIthSpinner";
import "../../../../styles/components/integrations/remove-sync-pair.scss";

const FixBrokenPairConfirmationModal = (props) => {
  const {
    // Component props
    open,
    handleClose,
    onClick,
    onClickedProceedSync,
    disabledSyncing,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">
          <ErrorRounded htmlColor="#FF5F0A" fontSize="snall" className="mr-1" />{" "}
          Sync without resolving broken pairs?
        </span>
        <Close onClick={handleClose} className="close-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you proceed to sync, these broken pairs will be excluded from the
          syncing process
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-1 flexer-column ">
        <div className="d-flex justify-content-between w-100">
          <div className=" flexer-column ml-3">
            <ButtonWithSpinner onClick={onClick} className="dark-gray-button">
              Resolve Broken Pairs
            </ButtonWithSpinner>
            <DialogContentText variant="subtitle2" className="mt-1">
              Recommended
            </DialogContentText>
          </div>
          <ButtonWithSpinner
            disabled={disabledSyncing}
            onClick={onClickedProceedSync}
            className="bg-yellow h-25 mr-3"
          >
            {disabledSyncing ? "Syncing in progress" : "Proceed to Sync"}
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FixBrokenPairConfirmationModal;
