import React, { useEffect, useCallback, useState } from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CircularProgress, Box, Tabs, Tab } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import MailchimpIcon from "../../../../assets/img/mailchimp/mailchimp-small.png";
import CcbIcon from "../../../../assets/img/ccb/ccb-small.png";
import { formDataWithApiKey } from "../../../../helpers";
import { getUnresolveConflictsPairs } from "../../../../store/middlewares/integrationMiddleware";
import UnresolveConflictsList from "./UnresolveConflictsList";
import { CCB_TO_MC, MC_TO_CCB } from "../../../../utils/integrationsConstants";

const UnresolveConflictsPairsOne = (props) => {
  const {
    // Component props
    onSelectConflictResolve,
    selectedConflict,

    // Redux functions
    getUnresolveConflictsPairs,

    // Redux props
    unresolveConflictsPairsStatus,
    ccbToMcConflictsPairs,
    mcToCcbConflictsPairs,
  } = props;
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (ccbToMcConflictsPairs?.length) {
      setActiveTab(0);
    } else if (mcToCcbConflictsPairs?.length) {
      setActiveTab(1);
    }
  }, [ccbToMcConflictsPairs, mcToCcbConflictsPairs]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (lodash.isEmpty(selectedConflict)) {
      getUnresolveConflictsPairs(formData);
    }
  }, [getUnresolveConflictsPairs, selectedConflict]);

  const renderTextWithIcon = useCallback((icon, text) => {
    return (
      <span className="flexer">
        <img className="mr-1" src={icon} alt={text} /> {text}
      </span>
    );
  }, []);

  const renderArrowForwardIcon = useCallback(() => {
    return (
      <ArrowForward className="mr-2 ml-2" color={"white"} fontSize={"small"} />
    );
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const renderTabs = useCallback(() => {
    if (
      Boolean(ccbToMcConflictsPairs?.length) ||
      Boolean(mcToCcbConflictsPairs?.length)
    ) {
      return (
        <Box className="ml-4" sx={{ width: "100%" }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="standard"
            aria-label="secondary tabs example"
          >
            {ccbToMcConflictsPairs?.length && (
              <Tab
                value={0}
                label={
                  <div className="flexer">
                    <span className="mr-2">
                      ({ccbToMcConflictsPairs?.length})
                    </span>
                    {renderTextWithIcon(CcbIcon, "CCB")}
                    {renderArrowForwardIcon()}
                    {renderTextWithIcon(MailchimpIcon, "MailChimp")}
                  </div>
                }
                className="tab-text mr-2"
              />
            )}
            {mcToCcbConflictsPairs?.length && (
              <Tab
                value={1}
                label={
                  <div className="flexer">
                    <span className="mr-2">
                      ({mcToCcbConflictsPairs?.length})
                    </span>
                    {renderTextWithIcon(MailchimpIcon, "MailChimp")}
                    {renderArrowForwardIcon()}
                    {renderTextWithIcon(CcbIcon, "CCB")}
                  </div>
                }
                className="tab-text"
              />
            )}
          </Tabs>
        </Box>
      );
    }
  }, [
    activeTab,
    ccbToMcConflictsPairs,
    handleTabChange,
    mcToCcbConflictsPairs,
    renderArrowForwardIcon,
    renderTextWithIcon,
  ]);

  if (unresolveConflictsPairsStatus === "loading") {
    return (
      <div className="w-100 flexer overflow-hidden">
        <CircularProgress size={40} />
      </div>
    );
  }

  if (unresolveConflictsPairsStatus === "success") {
    return (
      <div className="unresolve-conflicts-container">
        {renderTabs()}
        <UnresolveConflictsList
          activeTab={activeTab === 0 ? CCB_TO_MC : MC_TO_CCB}
          ccbToMcConflictsPairs={ccbToMcConflictsPairs}
          mcToCcbConflictsPairs={mcToCcbConflictsPairs}
          selectedConflict={selectedConflict}
          onSelectConflictResolve={onSelectConflictResolve}
        />
      </div>
    );
  }

  if (unresolveConflictsPairsStatus === "error") {
    return (
      <div className="text-danger text-center">
        Something went wrong, please try again later
      </div>
    );
  }
  return null;
};

UnresolveConflictsPairsOne.propType = {
  onSelectConflictResolve: PropTypes.func.isRequired,
  selectedConflict: PropTypes.object,
};

UnresolveConflictsPairsOne.defaultProps = {
  selectedConflict: {},
};

const mapStateToProps = (store) => {
  return {
    unresolveConflictsPairsStatus:
      store.integrations.unresolveConflictsPairsStatus,
    ccbToMcConflictsPairs: store.integrations.ccbToMcConflictsPairs,
    mcToCcbConflictsPairs: store.integrations.mcToCcbConflictsPairs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUnresolveConflictsPairs: (formData) =>
      dispatch(getUnresolveConflictsPairs(formData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnresolveConflictsPairsOne);
