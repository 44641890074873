import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { CCB_TO_MC } from "../../../../utils/integrationsConstants";
import { Typography } from "@material-ui/core";
import CcbIcon from "../../../../assets/img/ccb/ccb-small.png";
import MailchimpIcon from "../../../../assets/img/mailchimp/mailchimp-small.png";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/general/group.svg";
import { ArrowForward } from "@material-ui/icons";

const UnresolveConflictsList = (props) => {
  const {
    activeTab,
    ccbToMcConflictsPairs,
    mcToCcbConflictsPairs,
    selectedConflict,
    onSelectConflictResolve,
  } = props;

  const getCcbHeaders = useCallback(() => {
    return (
      <>
        {["CCB Campuses", "CCB Groups"].map((item, idx) => (
          <td key={idx}>
            <Typography className={`title`} component="h2">
              <img src={CcbIcon} className="mr-1" alt="icon" /> {item}
            </Typography>
          </td>
        ))}
      </>
    );
  }, []);

  const getMcHeaders = useCallback(() => {
    return (
      <>
        {["Mailchimp Audience", "Audience Tag/Groups"].map((item, idx) => (
          <td key={idx}>
            <Typography className={`title`} component="h2">
              <img src={MailchimpIcon} className="mr-1" alt="icon" /> {item}
            </Typography>
          </td>
        ))}
      </>
    );
  }, []);

  const renderTableHeader = useCallback(() => {
    return (
      <thead>
        {activeTab === CCB_TO_MC ? (
          <tr className="unresolve-conflict-table-header flexer-space-around">
            {getCcbHeaders()}
            {getMcHeaders()}
          </tr>
        ) : (
          <tr className="unresolve-conflict-table-header flexer-space-around">
            {getMcHeaders()}
            {getCcbHeaders()}
          </tr>
        )}
      </thead>
    );
  }, [activeTab, getCcbHeaders, getMcHeaders]);

  const renderTableRow = useCallback(() => {
    if (activeTab === CCB_TO_MC) {
      return ccbToMcConflictsPairs.map((item) => (
        <tr
          onClick={() => onSelectConflictResolve(item)}
          key={item.ccbToMcId}
          className={`unresolve-conflict-table-body-row flexer-space-around ${
            item.ccbToMcId === selectedConflict?.ccbToMcId ? "active" : ""
          }`}
        >
          <td>
            <Typography noWrap={true}>{item.campusName}</Typography>
          </td>
          <td>
            <Typography style={{ width: "85%" }} noWrap={true}>
              <GroupIcon className="mr-1" /> {item.groupName}
            </Typography>{" "}
            <ArrowForward
              className="arrow-icon"
              color={"inherit"}
              fontSize={"default"}
            />
          </td>
          <td>
            <Typography noWrap={true}>{item.mcAudienceName}</Typography>
          </td>
          <td>
            <Typography noWrap={true}>{item.mcGroupOrTagDisplay}</Typography>
          </td>
        </tr>
      ));
    } else {
      return mcToCcbConflictsPairs.map((item) => (
        <tr
          onClick={() => onSelectConflictResolve(item)}
          key={item.mcToCcbId}
          className={`unresolve-conflict-table-body-row flexer-space-around ${
            item.mcToCcbId === selectedConflict?.mcToCcbId ? "active" : ""
          }`}
        >
          <td>
            <Typography noWrap={true}>{item.mcAudienceName}</Typography>
          </td>
          <td>
            <Typography style={{ width: "85%" }} noWrap={true}>
              {item.mcGroupOrTagDisplay}
            </Typography>
            <ArrowForward
              className="arrow-icon"
              color={"inherit"}
              fontSize={"default"}
            />
          </td>
          <td>
            <Typography noWrap={true}>{item.campusName}</Typography>
          </td>
          <td>
            <Typography noWrap={true}>
              <GroupIcon className="mr-1" /> {item.groupName}
            </Typography>
          </td>
        </tr>
      ));
    }
  }, [
    activeTab,
    ccbToMcConflictsPairs,
    mcToCcbConflictsPairs,
    onSelectConflictResolve,
    selectedConflict,
  ]);

  return (
    <div className="mt-4">
      <table className="w-100">
        {renderTableHeader()}
        <tbody>{renderTableRow()}</tbody>
      </table>
    </div>
  );
};

UnresolveConflictsList.propType = {
  mcToCcbConflictsPairs: PropTypes.array,
  ccbToMcConflictsPairs: PropTypes.array,
  onSelectConflictResolve: PropTypes.func.isRequired,
  selectedConflict: PropTypes.object,
};

UnresolveConflictsList.defaultProps = {
  mcToCcbConflictsPairs: [],
  ccbToMcConflictsPairs: [],
  selectedConflict: {},
};

export default UnresolveConflictsList;
