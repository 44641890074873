import React from "react";
import PropTypes from "prop-types";
import PendingUsersTableRow from "./PendingUsersTableRow";

const PendingUsersTableBody = (props) => {
  const {
    data,
    isLoading,
    onLoginClick,
    onClickApproveUser,
    approveUserStatus,
    approveUserId,
  } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <PendingUsersTableRow
          data={elementData}
          index={index}
          onLoginClick={onLoginClick}
          onClickApproveUser={onClickApproveUser}
          approveUserStatus={approveUserStatus}
          approveUserId={approveUserId}
        />
      ))}
    </tbody>
  );
};

PendingUsersTableBody.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onClickApproveUser: PropTypes.func.isRequired,
};

PendingUsersTableBody.defaultProps = {
  data: [],
};

export default PendingUsersTableBody;
