import { ACTION_TYPES } from "./types";

export const clearMakePrimaryContactState = () => ({
  type: ACTION_TYPES.MAKE_PRIMARY_CONTACTS_CLEAR,
});

export const clearUnignoredEmailStatus = () => ({
  type: ACTION_TYPES.UNIGNORE_EMAIL_CLEAR,
});

export const clearDeleteMcCleanupPair = () => ({
  type: ACTION_TYPES.DELETE_MC_CLEAR,
});

export const clearDismissDeleteMc = () => ({
  type: ACTION_TYPES.DISMISS_DELETE_MC_CLEAR,
});

export const clearToolPageState = () => ({
  type: ACTION_TYPES.CLEAR_TOOL_PAGE_STATE,
});

export const clearSaveHeaderMapping = () => ({
  type: ACTION_TYPES.SAVE_HEADER_MAPPING_CLEAR,
});

export const clearDeleteMcToCcbCustomListOption = () => ({
  type: ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST_CLEAR,
});

export const clearAddMcToCcbCustomListOption = () => ({
  type: ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST_CLEAR,
});
