import useIsSidebarExpanded from "../utils/hooks/useIsSidebarExpanded";

const RenderSidebarExpandedElement = (props) => {
  const { children } = props;

  const isSidebarExpanded = useIsSidebarExpanded();

  if (!isSidebarExpanded) {
    return null;
  }

  return children;
};

export default RenderSidebarExpandedElement;
