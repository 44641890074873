import React, { useState, useCallback, Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import lodash from "lodash";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import SideScreenBanner from "../../../components/SideScreenBanner/SideScreenBanner";
import InputField from "../../../components/InputField/InputField";
import LogoWithHeading from "../../../components/LogoWithHeading/LogoWithHeading";
import MaterialButton from "../../../components/ MaterialButton/MaterialButton";
import { validateEmptyFields } from "../../../helpers";
import { userLoginMiddleware } from "../../../store/middlewares/userMiddleware";
import Loader from "../../../components/Loader/Loader";
import "../../../styles/views/login.scss";

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const [errors, seterrors] = useState({});
  const [simpleError, setSimpleError] = useState("");

  const {
    // Redux functions
    loginUser,

    // Redux props
    loading,
    userData,
    errorMessage,
    isLoggedIn,

    // Route props
    history,
    location,
  } = props;

  useEffect(() => {
    if (isLoggedIn) {
      if (
        location.state?.from &&
        location.state?.from?.pathname &&
        !lodash.isEmpty(userData) &&
        Number(userData?.isActivated) === 1
      ) {
        history.push(location.state?.from?.pathname);
      } else if (
        location.state?.from &&
        location.state?.from?.pathname &&
        location.state?.from?.pathname?.includes("activate-account")
      ) {
        history.push(location.state?.from?.pathname);
      } else {
        history.push(
          `/dashboard${
            Number(userData?.isActivated) === 0 ? "?non-activate-account" : ""
          }`
        );
      }
    }
  }, [history, isLoggedIn, location, userData]);

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const emptyFields = validateEmptyFields(inputs);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else {
        const formData = new FormData();
        seterrors({});
        setSimpleError("");
        formData.append("email", inputs.email);
        formData.append("password", inputs.password);
        loginUser(formData);
      }
    },
    [inputs, loginUser]
  );

  return (
    <Fragment>
      <div className="flexer vh-100">
        <div className="login-page">
          <LogoWithHeading title="Login" width={40} />
          <form name="form" onSubmit={handleSubmit}>
            {errorMessage && <h6 className="text-danger">{errorMessage}</h6>}
            {simpleError && <h6 className="text-danger">{simpleError}</h6>}
            <InputField
              name="email"
              type="text"
              label="Email"
              className="text-field"
              handleChange={handleChange}
              value={inputs.email}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
            <InputField
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              className="text-field"
              showPassword={showPassword}
              handlePasswordVisibility={handlePasswordVisibility}
              handleChange={handleChange}
              value={inputs.password}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
            <Button
              color="primary"
              variant="text"
              component={Link}
              to="/forgot-password"
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-end",
              }}
            >
              <span className="forgot-pw">Forgot your password?</span>
            </Button>
            <MaterialButton title="Login" />
            <Button
              color="primary"
              variant="text"
              component={Link}
              to="/signup"
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              <span className="light">Don't have an account?</span> &nbsp;
              Create account
            </Button>
          </form>
        </div>
        <SideScreenBanner />
      </div>
      {loading && <Loader />}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (userData) => dispatch(userLoginMiddleware(userData)),
  };
};

const mapStateToProps = (store) => {
  return {
    loading: store.globalReducer.isLoading,
    errorMessage: store.userReducer.errorMessage,
    isLoggedIn: store.userReducer.isLoggedIn,
    userData: store.userReducer.userData,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
