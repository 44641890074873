import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: 0,
    top: 20,
  },
});

const PurchasePlanToSync = (props) => {
  const { open, handleClose, history } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title" className="flexer">
        <span className="modal-main-heading">Purchase a Plan to Sync</span>
      </DialogTitle>
      <DialogContent className="flexer">
        <DialogContentText>
          You need to purchase a plan for this feature.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-4 flexer">
        <div className="flexer w-100">
          <ButtonWithSpinner
            className="bg-yellow"
            onClick={() => history.push("/billing")}
          >
            Go to Pricing
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(PurchasePlanToSync);
