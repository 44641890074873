import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Switch, Typography, CardContent, Card } from "@material-ui/core";
import {
  saveEmailNotificationForPendingDupes,
  saveEmailNotificationForUnsub,
} from "../../store/middlewares/toolsMiddleware";
import { setSnackbarData } from "../../store/actions/globalAction";
import Loader from "../../components/Loader/Loader";
import { clearToolPageState } from "../../store/actions/toolsActions";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import { formDataWithApiKey } from "../../helpers";
import "../../styles/components/dashboard/dashboard-main.scss";
import "../../styles/views/authorize-account.scss";
import "../../styles/views/login.scss";

const Notifications = (props) => {
  const {
    // Redux props
    userData,
    isLoading,
    successMessage,

    // Redux functions
    setSnackbarData,
    clearToolPageState,
    saveEmailNotificationForUnsub,
    saveEmailNotificationForPendingDupes,
  } = props;

  const isSidebarExpanded = useIsSidebarExpanded();

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  useEffect(() => {
    if (successMessage) {
      showSnackbar(successMessage, "success");
      clearToolPageState();
    }
  }, [clearToolPageState, showSnackbar, successMessage]);

  const onChangeEmailNotificationForUnsubMc = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append(
      "val",
      userData?.isEmailNotificatonForUnsubMc === 0 ? 1 : 0
    );
    saveEmailNotificationForUnsub(formData);
  }, [saveEmailNotificationForUnsub, userData]);

  const onChangeEmailNotficationForPendingDupes = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append(
      "val",
      userData?.isEmailNotificatonForPendingDupes === 0 ? 1 : 0
    );
    saveEmailNotificationForPendingDupes(formData);
  }, [saveEmailNotificationForPendingDupes, userData]);

  return (
    <div
      className={clsx("dashboard-main", {
        "with-page-sub-menu-expanded": isSidebarExpanded,
      })}
    >
      <div className="cards-wrapper w-100">
        {window.innerWidth > 770 && (
          <h3 className="user-note text-center mt-4">Notifications</h3>
        )}
        <div className="tools-page-container">
          <div
            className={clsx(
              "w-100 d-flex align-items-center justify-content-start",
              {
                "flex-column": window.innerWidth < 770,
              }
            )}
          >
            <Card
              variant="outlined"
              className={clsx("account-activation-card mt-4 w-48", {
                "mr-4": window.innerWidth > 770,
              })}
            >
              <CardContent>
                <div className="flexer-start">
                  <Switch
                    checked={Boolean(userData?.isEmailNotificatonForUnsubMc)}
                    className="align-self-start mr-2"
                    onChange={onChangeEmailNotificationForUnsubMc}
                  />
                  <Typography variant="subtitle2">
                    Email Notification for Unsub MC Emails
                  </Typography>
                </div>
                <Typography className="mt-1" variant="subtitle2">
                  Once enabled, our platform will send email notifications after
                  every sync for a summary of unsubscribed Mailchimp emails.
                </Typography>
              </CardContent>
            </Card>

            <Card
              variant="outlined"
              className="account-activation-card mt-4 w-48"
            >
              <CardContent>
                <div className="flexer-start">
                  <Switch
                    checked={Boolean(
                      userData?.isEmailNotificatonForPendingDupes
                    )}
                    className="align-self-start mr-2"
                    onChange={onChangeEmailNotficationForPendingDupes}
                  />
                  <Typography variant="subtitle2">
                    Email Notification for Pending Duplicates
                  </Typography>
                </div>
                <Typography className="mt-1" variant="subtitle2">
                  Once enabled, our platform will send email notifications after
                  every sync for a summary of pending duplicate email
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    isLoading: store.tools.isLoading,
    successMessage: store.tools.successMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearToolPageState: () => dispatch(clearToolPageState()),
    saveEmailNotificationForUnsub: (data) =>
      dispatch(saveEmailNotificationForUnsub(data)),
    saveEmailNotificationForPendingDupes: (data) =>
      dispatch(saveEmailNotificationForPendingDupes(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
