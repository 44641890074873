import { convertFormdataToJson } from "../../helpers";
import { ACTION_TYPES } from "../actions/types";
import services from "../../services";
const {
  storageManager: { local },
} = services;

const authorizeCcbAccountSetupFailedErrorMessage = (isNewUser) => {
  if (isNewUser) {
    return `<span>
          Recent update unsuccessful - incorrect password credentials. Check that you’re using your API (NOT CCB/Pushpay) user login details. None of your data was saved.<br/><br/>
          Check out this section “Setting up / Re-authorizing / Editing / Removing / Disabling the CCB API” in <a href="https://support.ccbchimp.com/en/articles/6349325-integrations-page-v2-users"  target="__blank"><u class="font-weight-bold text-white">this article</u></a>.
        </span>`;
  }
  return `<span>
      Recent update unsuccessful - incorrect password credentials. Check that you’re using your API (NOT CCB/Pushpay) user login details. We’re using the ‘old’ version of your data.<br/><br/>
      Check out this section “Setting up / Re-authorizing / Editing / Removing / Disabling the CCB API” in <a href="https://support.ccbchimp.com/en/articles/6349325-integrations-page-v2-users"  target="__blank"><u class="font-weight-bold text-white">this article</u></a>.
    </span>`;
};

const initialState = {
  userData: {},
  isLoggedIn: false,
  errorMessage: null,
  successMessage: null,

  updateProfileStatus: {},
  updateProfileError: "",
  updateProfileErrorMessage: null,

  // AUTHORIZE ACCOUNT PAGE STATE

  userApiDetails: {},
  apiDetailsStatus: "",
  hasCcbError: false,
  hasMcError: false,

  authorizeCcbAccountStatus: "",

  authorizeMcAccountStatus: "",
  mcSuccessMessage: null,
  mcErrorMessage: null,

  removeCcbCredentialsStatus: "",

  removeMcAccountCredentialsStatus: "",

  enableDisableSyncingStatus: "",

  userStoreInfoStatus: "",

  userStoreInfoErrorMessage: "",
  userStoreInfoSuccessMessage: "",

  checkSyncStatus: null,

  // auto syncing status
  autoSyncingStatus: null,

  // Run intial sync
  runInitialSync: null,

  // Warnings
  warningsStatus: null,
  warnings: {},

  // Dashboard data
  dashboardDataStatus: null,
  dashboardData: {},

  // Intercom app Id
  intercomAppIdStatus: null,
  intercomAppId: {},

  // Country update
  updateCountryStatus: "",
  defaultCountry: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_LOGIN_SUCCESS:
      local.set("user", JSON.stringify(action.payload));
      return Object.assign({}, state, {
        isLoggedIn: true,
        errorMessage: null,
        userData: action.payload,
      });

    case ACTION_TYPES.USER_LOGIN_FAIL:
      return Object.assign({}, state, {
        isLoggedIn: false,
        errorMessage: action.payload.message,
      });

    case ACTION_TYPES.UPDATE_PROFILE: {
      return {
        ...state,
        updateProfileStatus: {
          [action.requestId]: "loading",
        },
      };
    }

    case ACTION_TYPES.UPDATE_PROFILE_SUCCESS: {
      const updatedData = convertFormdataToJson(action.data);
      if (Boolean(action?.payload?.status)) {
        local.set(
          "user",
          JSON.stringify({ ...state.userData, ...updatedData })
        );
      }
      return {
        ...state,
        updateProfileStatus: {
          [action.requestId]: Boolean(action?.payload?.status)
            ? "success"
            : "error",
        },
        updateProfileErrorMessage: Boolean(action?.payload?.status)
          ? ""
          : action?.payload?.message ||
            "Something went wrong, please try again",
        userData: Boolean(action?.payload?.status)
          ? { ...state.userData, ...updatedData }
          : { ...state.userData },
      };
    }

    case ACTION_TYPES.UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        updateProfileStatus: {
          [action.requestId]: "error",
        },
        updateProfileErrorMessage:
          "Something went, wrong please try again later",
      };
    }

    case ACTION_TYPES.UPDATE_PROFILE_CLEAR: {
      const newUpdateProfileStatus = { ...state.updateProfileStatus };
      newUpdateProfileStatus[action.requestId] = undefined;

      return {
        ...state,
        updateProfileStatus: newUpdateProfileStatus,
      };
    }

    case ACTION_TYPES.UPDATE_PROFILE_CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        updateProfileErrorMessage: null,
      };
    }

    case ACTION_TYPES.GET_API_DETAILS: {
      return {
        ...state,
        apiDetailsStatus: "loading",
        mcSuccessMessage: null,
        mcErrorMessage: null,
        errorMessage: null,
        successMessage: null,
      };
    }

    case ACTION_TYPES.GET_API_DETAILS_SUCCESS: {
      return {
        ...state,
        apiDetailsStatus: action.payload?.status === 1 ? "success" : "error",
        userApiDetails:
          action.payload.status === 1 ? { ...action.payload } : {},
        hasMcError: action.payload?.hasMcError === 1,
        hasCcbError: action.payload?.hasCcbError === 1,
      };
    }

    case ACTION_TYPES.GET_API_DETAILS_ERROR: {
      return {
        ...state,
        apiDetailsStatus: "error",
        hasMcError: false,
        hasCcbError: false,
      };
    }

    case ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT: {
      return {
        ...state,
        authorizeCcbAccountStatus: "loading",
        errorMessage: "",
        successMessage: "",
        runInitialSync: null,
      };
    }

    case ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT_SUCCESS: {
      const ccbCredentials = convertFormdataToJson(action.data);
      return {
        ...state,
        authorizeCcbAccountStatus: "success",
        errorMessage:
          action.payload.status === 0 &&
          action.payload.message === "Invalid username or password."
            ? authorizeCcbAccountSetupFailedErrorMessage(
                !state.userApiDetails?.ccbAccount
              )
            : action.payload.message,
        successMessage:
          action.payload.status === 1 ? action.payload.message : "",
        userApiDetails:
          action.payload.status === 1
            ? { ...state.userApiDetails, ...ccbCredentials }
            : state.userApiDetails,
        runInitialSync: true,
      };
    }

    case ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT_ERROR: {
      return {
        ...state,
        authorizeCcbAccountStatus: "error",
        errorMessage: "Something went wrong, please try again later",
        successMessage: "",
        runInitialSync: null,
      };
    }

    case ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT_CLEAR: {
      return {
        ...state,
        authorizeCcbAccountStatus: null,
      };
    }

    case ACTION_TYPES.AUTHORIZE_MC_ACCOUNT: {
      return {
        ...state,
        authorizeMcAccountStatus: "loading",
        mcErrorMessage: "",
        mcSuccessMessage: "",
        runInitialSync: null,
      };
    }

    case ACTION_TYPES.AUTHORIZE_MC_ACCOUNT_SUCCESS: {
      const mcData = convertFormdataToJson(action.data);
      return {
        ...state,
        authorizeMcAccountStatus: "success",
        mcErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        mcSuccessMessage:
          action.payload.status === 1 ? action.payload.message : "",
        userApiDetails:
          action.payload.status === 1
            ? { ...state.userApiDetails, ...mcData }
            : state.userApiDetails,
        runInitialSync: true,
      };
    }

    case ACTION_TYPES.AUTHORIZE_MC_ACCOUNT_ERROR: {
      return {
        ...state,
        authorizeMcAccountStatus: "error",
        mcErrorMessage: "Something went wrong, please try again later",
        mcSuccessMessage: "",
        runInitialSync: null,
      };
    }

    case ACTION_TYPES.AUTHORIZE_MC_ACCOUNT_CLEAR: {
      return {
        ...state,
        authorizeMcAccountStatus: null,
      };
    }

    case ACTION_TYPES.REMOVE_CCB_CREDENTIALS: {
      return {
        ...state,
        removeCcbCredentialsStatus: "loading",
        errorMessage: "",
        successMessage: "",
      };
    }

    case ACTION_TYPES.REMOVE_CCB_CREDENTIALS_SUCCESS: {
      let userApiDetails = state.userApiDetails;
      userApiDetails.ccbUsername = "";
      userApiDetails.ccbPassword = "";
      userApiDetails.ccbAccount = "";
      return {
        ...state,
        removeCcbCredentialsStatus: "success",
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage:
          action.payload.status === 1 ? action.payload.message : "",
        userApiDetails: userApiDetails,
      };
    }

    case ACTION_TYPES.REMOVE_CCB_CREDENTIALS_ERROR: {
      return {
        ...state,
        removeCcbCredentialsStatus: "error",
        errorMessage: "Something went wrong, please try again later",
        successMessage: "",
      };
    }

    case ACTION_TYPES.REMOVE_MC_CREDENTIALS: {
      return {
        ...state,
        removeMcAccountCredentialsStatus: "loading",
        mcErrorMessage: "",
        mcSuccessMessage: "",
      };
    }

    case ACTION_TYPES.REMOVE_MC_CREDENTIALS_SUCCESS: {
      let userApiDetails = state.userApiDetails;
      userApiDetails.mailchimpApiKey = "";
      return {
        ...state,
        removeMcAccountCredentialsStatus: "success",
        mcErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        mcSuccessMessage:
          action.payload.status === 1 ? action.payload.message : "",
        userApiDetails: userApiDetails,
      };
    }

    case ACTION_TYPES.REMOVE_MC_CREDENTIALS_ERROR: {
      return {
        ...state,
        removeMcAccountCredentialsStatus: "error",
        mcErrorMessage: "Something went wrong, please try again later",
        mcSuccessMessage: "",
      };
    }

    case ACTION_TYPES.ENABLE_CCB_SYNCING: {
      return {
        ...state,
        enableDisableSyncingStatus: "loading",
      };
    }

    case ACTION_TYPES.ENABLE_CCB_SYNCING_SUCCESS: {
      let userData = state.userData;
      userData.isDisableSync = 0;
      local.set("user", JSON.stringify(userData));
      return {
        ...state,
        enableDisableSyncingStatus: "success",
        userData,
      };
    }

    case ACTION_TYPES.ENABLE_CCB_SYNCING_ERROR: {
      return {
        ...state,
        enableDisableSyncingStatus: "error",
        errorMessage: "Something went wrong, please try again later",
      };
    }

    case ACTION_TYPES.DISABLE_CCB_SYNCING: {
      return {
        ...state,
        enableDisableSyncingStatus: "loading",
      };
    }

    case ACTION_TYPES.DISABLE_CCB_SYNCING_SUCCESS: {
      let userData = state.userData;
      userData.isDisableSync = 1;
      local.set("user", JSON.stringify(userData));
      return {
        ...state,
        enableDisableSyncingStatus: "success",
        userData,
      };
    }

    case ACTION_TYPES.DISABLE_CCB_SYNCING_ERROR: {
      return {
        ...state,
        enableDisableSyncingStatus: "error",
        errorMessage: "Something went wrong, please try again later",
      };
    }

    case ACTION_TYPES.ENABLE_DISABLE_CCB_SYNCING_CLEAR: {
      return {
        ...state,
        enableDisableSyncingStatus: "",
      };
    }

    case ACTION_TYPES.ENABLE_USER_STORE_INFO: {
      return {
        ...state,
        userStoreInfoStatus: "loading",
      };
    }

    case ACTION_TYPES.ENABLE_USER_STORE_INFO_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isStoreUserInfo = 1;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userStoreInfoStatus: "success",
        userStoreInfoErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        userStoreInfoSuccessMessage:
          action.payload.status === 1 ? "Saved successfully" : "",
        userData,
      };
    }

    case ACTION_TYPES.ENABLE_USER_STORE_INFO_ERROR: {
      return {
        ...state,
        userStoreInfoStatus: "error",
        userStoreInfoErrorMessage:
          "Something went wrong, please try again later",
      };
    }

    case ACTION_TYPES.DISABLE_USER_STORE_INFO: {
      return {
        ...state,
        userStoreInfoStatus: "loading",
      };
    }

    case ACTION_TYPES.DISABLE_USER_STORE_INFO_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isStoreUserInfo = 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userStoreInfoStatus: "success",
        userStoreInfoErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        userStoreInfoSuccessMessage:
          action.payload.status === 1 ? "Saved successfully" : "",
        userData,
      };
    }

    case ACTION_TYPES.DISABLE_USER_STORE_INFO_ERROR: {
      return {
        ...state,
        userStoreInfoStatus: "error",
        userStoreInfoErrorMessage:
          "Something went wrong, please try again later",
      };
    }

    case ACTION_TYPES.ENABLE_DISABLE_USER_STORE_INFO_CLEAR: {
      return {
        ...state,
        userStoreInfoStatus: null,
      };
    }

    // TOOLS PAGE REDUCERS
    case ACTION_TYPES.SAVE_TOOLKIT_CLEANUP_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isToolkitCleanup = userData.isToolkitCleanup === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_STORE_USER_INFO_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isStoreUserInfo = userData.isStoreUserInfo === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_PRIMARY_PROFILES_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isCheckPrimaryProfiles =
          userData.isCheckPrimaryProfiles === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_MC_TO_CCB_REMOVE_INACTIVE_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isMcToCcbDisableInactiveRemove =
          userData.isMcToCcbDisableInactiveRemove === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_AUTO_SYNC_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isAutoSync = userData.isAutoSync === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_SKIP_MERGE_VALIDATION_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.skipMergeValidation =
          userData.skipMergeValidation === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_UBSUB_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isEmailNotificatonForUnsubMc =
          userData.isEmailNotificatonForUnsubMc === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isEmailNotificatonForPendingDupes =
          userData.isEmailNotificatonForPendingDupes === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_CHECK_RECEIVED_EMAIL_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.is_check_received_email_from_ccb =
          userData.is_check_received_email_from_ccb === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_DELETE_MC_LIST_GROUP_SUCCESS: {
      let userData = state.userData;
      if (action.payload.status === 1) {
        userData.isDeleteMcListGroup =
          userData.isDeleteMcListGroup === 0 ? 1 : 0;
        local.set("user", JSON.stringify(userData));
      }
      return {
        ...state,
        userData,
      };
    }

    case ACTION_TYPES.SAVE_MEMBERS_SETTINGS_SUCCESS: {
      const updatedData = convertFormdataToJson(action.data);
      if (action.payload.status === 1) {
        local.set(
          "user",
          JSON.stringify({ ...state.userData, ...updatedData })
        );
      }
      return {
        ...state,
        userData: { ...state.userData, ...updatedData },
      };
    }

    case ACTION_TYPES.CLEAR_API_DETAILS_STATE: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        mcSuccessMessage: null,
        mcErrorMessage: null,
        authorizeCcbAccountStatus: "",
        authorizeMcAccountStatus: "",
        removeCcbCredentialsStatus: "",
        removeMcAccountCredentialsStatus: "",
        enableDisableSyncingStatus: "",
        userStoreInfoErrorMessage: "",
        userStoreInfoSuccessMessage: "",
      };
    }

    case ACTION_TYPES.IS_AUTO_SYNCING_SUCCESS: {
      return {
        ...state,
        autoSyncingStatus: action.payload.data.isSyncing,
      };
    }

    case ACTION_TYPES.IS_AUTO_SYNCING_ERROR: {
      return {
        ...state,
        autoSyncingStatus: null,
      };
    }

    case ACTION_TYPES.IS_AUTO_SYNCING_CLEAR: {
      return {
        ...state,
        autoSyncingStatus: null,
      };
    }

    case ACTION_TYPES.CLEAR_INTIAL_SYNCING: {
      return {
        ...state,
        runInitialSync: null,
      };
    }

    case ACTION_TYPES.GET_WARNINGS: {
      return {
        ...state,
        warningsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_WARNINGS_SUCCESS: {
      return {
        ...state,
        warningsStatus: "success",
        warnings: action.payload,
      };
    }

    case ACTION_TYPES.GET_WARNINGS_ERROR: {
      return {
        ...state,
        warningsStatus: "error",
      };
    }

    case ACTION_TYPES.GET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardDataStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        dashboardDataStatus: "success",
        dashboardData: action.payload,
      };
    }

    case ACTION_TYPES.GET_DASHBOARD_DATA_ERROR: {
      return {
        ...state,
        dashboardDataStatus: "error",
      };
    }

    case ACTION_TYPES.GET_INTERCOM_APP_ID: {
      return {
        ...state,
        intercomAppIdStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_INTERCOM_APP_ID_SUCCESS: {
      return {
        ...state,
        intercomAppIdStatus: "success",
        intercomAppId: action?.payload?.intercomAppId,
      };
    }

    case ACTION_TYPES.GET_INTERCOM_APP_ID_ERROR: {
      return {
        ...state,
        intercomAppIdStatus: "error",
      };
    }

    case ACTION_TYPES.GET_INTERCOM_APP_ID_CLEAR: {
      return {
        ...state,
        intercomAppIdStatus: "",
      };
    }

    case ACTION_TYPES.UPDATE_COUNTRY:
      return Object.assign({}, state, {
        updateCountryStatus: "loading",
      });

    case ACTION_TYPES.UPDATE_COUNTRY_SUCCESS:
      return Object.assign({}, state, {
        updateCountryStatus: "success",
      });

    case ACTION_TYPES.UPDATE_COUNTRY_ERROR:
      return Object.assign({}, state, {
        updateCountryStatus: "error",
      });

    default:
      return state;
  }
};

export default userReducer;
