import React from "react";
import PropTypes from "prop-types";
import { ErrorOutline } from "@material-ui/icons";

const EmptyMessageBox = (props) => {
  const { message, hasBorder } = props;
  return (
    <div
      className={`error-box-container ${hasBorder ? "error-box-border" : ""} `}
    >
      <h6 className="text-muted">
        <ErrorOutline fontSize="default" /> {message}
      </h6>
    </div>
  );
};

EmptyMessageBox.defaultProps = {
  hasBorder: true,
};

EmptyMessageBox.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyMessageBox;
