import React, { Fragment, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner/ButtonWIthSpinner";
import Loader from "../../../components/Loader/Loader";
import TableInfinityScroll from "../../../components/Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../../components/Table/TableHeader/TableHeader";
import {
  approveUser,
  getPendingUsers,
} from "../../../store/middlewares/adminMiddlewares/adminUsersMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import services from "../../../services";
import { setSnackbarData } from "../../../store/actions/globalAction";
import useIsSidebarExpanded from "../../../utils/hooks/useIsSidebarExpanded";
import PendingUsersTableBody from "../../../components/AdminComponents/PendingUsers/PendingUsersTableBody";
import { clearApproveUser } from "../../../store/actions/adminActions/adminUserActions";
import "../../../styles/components/more/user-settings.scss";
const {
  storageManager: { local },
} = services;

const cells = [
  {
    text: "Email",
  },
  {
    text: "App version",
  },
  {
    text: "API key",
  },
  {
    text: "Actions",
  },
];

const PendingUsers = (props) => {
  const {
    // REDUX FUNCTIONS
    getPendingUsers,
    setSnackbarData,
    approveUser,
    clearApproveUser,

    // Redux props
    pendingUsers,
    pendingUsersStatus,
    pendingUsersPage,
    pendingUsersLoadedAll,
    pendingUsersLoadingMoreStatus,
    approveUserStatus,
    successMessage,
    errorMessage,
    approveUserId,
  } = props;
  const [inputs, setInputs] = useState({
    email: "",
  });

  const isSidebarExpanded = useIsSidebarExpanded();

  const getUsers = useCallback(
    (pageNum) => {
      const formData = formDataWithApiKey();
      formData.append("page", pageNum);
      formData.append("firstName", "");
      formData.append("lastName", "");
      formData.append("email", inputs.email);
      formData.append("ccbAccount", "");
      getPendingUsers(formData);
    },
    [getPendingUsers, inputs]
  );

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    formData.append("firstName", "");
    formData.append("lastName", "");
    formData.append("email", "");
    formData.append("ccbAccount", "");
    getPendingUsers(formData);
  }, [getPendingUsers]);

  useEffect(() => {
    if (approveUserStatus === "success") {
      clearApproveUser();
      getUsers(pendingUsersPage);
      setSnackbarData({
        snackbarSeverity: "success",
        showSnackbar: true,
        snackbarMessage:
          successMessage || "An activation link has been sent to the user",
      });
    } else if (approveUserStatus === "error") {
      clearApproveUser();
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage:
          errorMessage || "Something went wrong! Please try again",
      });
    }
  }, [
    approveUserStatus,
    clearApproveUser,
    errorMessage,
    getUsers,
    pendingUsersPage,
    setSnackbarData,
    successMessage,
  ]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const loadMore = useCallback(() => {
    if (
      !pendingUsersLoadedAll &&
      pendingUsersStatus !== "loading" &&
      pendingUsersStatus !== "error" &&
      pendingUsers
    ) {
      const pageNum = Number(pendingUsersPage) + 1;
      getUsers(pageNum);
    }
  }, [
    pendingUsersLoadedAll,
    pendingUsersStatus,
    pendingUsers,
    pendingUsersPage,
    getUsers,
  ]);

  const handleUserSearch = useCallback(() => {
    getUsers(1);
  }, [getUsers]);

  const handleLoginClicked = useCallback(
    (user) => {
      if (!user.apiKey) {
        return setSnackbarData({
          snackbarSeverity: "error",
          showSnackbar: true,
          snackbarMessage: "API key missing!",
        });
      }
      const adminData = JSON.parse(local.get("user"));
      local.set("admin", JSON.stringify(adminData));
      local.set("token", user.apiKey);
      local.set("user", JSON.stringify(user));
      window.location = "dashboard";
    },
    [setSnackbarData]
  );

  const handleApproveUser = useCallback(
    (data) => () => {
      const formData = formDataWithApiKey();
      formData.append("id", data.id);
      approveUser(formData);
    },
    [approveUser]
  );

  const renderSearchFields = useCallback(() => {
    return (
      <div className="flexer-start">
        <form className="form flexer">
          <fieldset>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              onChange={handleChange}
              value={inputs.email}
              type="text"
              id="email"
            />
          </fieldset>
        </form>
        <ButtonWithSpinner
          onClick={handleUserSearch}
          className="bg-yellow mt-3 ml-4"
        >
          Search
        </ButtonWithSpinner>
      </div>
    );
  }, [handleChange, handleUserSearch, inputs]);

  const loadingMore = pendingUsersLoadingMoreStatus === "loading";
  return (
    <Fragment>
      <div
        className={clsx("page-wrapper my-profile", {
          "with-page-sub-menu-expanded": isSidebarExpanded,
        })}
      >
        <div className="desktop-design">
          <div className="flexer-space-between admin-user-header ">
            <Typography className="desktop-title mb-0" component={"h2"}>
              Pending Users
            </Typography>
          </div>
          {renderSearchFields()}
          <div className="user-settings-greetings mt-2">
            {pendingUsersStatus === "loading" && !loadingMore ? (
              <div className="d-flex justify-content-center spinner-container">
                <Loader />
              </div>
            ) : (
              <TableInfinityScroll
                loadMore={loadMore}
                page={1}
                fetchFunction={() => {}}
                className="table-with-progress-bar"
              >
                <table className="table">
                  <TableHeader
                    className="user-settings-greetings-table-header"
                    cells={cells}
                  />
                  <PendingUsersTableBody
                    data={pendingUsers}
                    handleItemClicked={() => {}}
                    isLoading={loadingMore}
                    onLoginClick={handleLoginClicked}
                    approveUserStatus={approveUserStatus}
                    onClickApproveUser={handleApproveUser}
                    approveUserId={approveUserId}
                  />
                </table>
              </TableInfinityScroll>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    pendingUsers: store.admin.pendingUsers,
    pendingUsersStatus: store.admin.pendingUsersStatus,
    pendingUsersPage: store.admin.pendingUsersPage,
    pendingUsersLoadedAll: store.admin.pendingUsersLoadedAll,
    pendingUsersLoadingMoreStatus: store.admin.pendingUsersLoadingMoreStatus,
    approveUserStatus: store.admin.approveUserStatus,
    successMessage: store.admin.successMessage,
    errorMessage: store.admin.errorMessage,
    approveUserId: store.admin.approveUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingUsers: (data) => dispatch(getPendingUsers(data)),
    approveUser: (data) => dispatch(approveUser(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearApproveUser: () => dispatch(clearApproveUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingUsers);
