import React from "react";
import PropTypes from "prop-types";
import UsersTableRow from "./UsersTableRow";

const UsersTableBody = (props) => {
  const { data, isLoading, onViewClick, onLoginClick } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <UsersTableRow
          data={elementData}
          index={index}
          onViewClick={onViewClick}
          onLoginClick={onLoginClick}
        />
      ))}
    </tbody>
  );
};

UsersTableBody.propTypes = {
  onViewClick: PropTypes.func.isRequired,
  onLoginClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

UsersTableBody.defaultProps = {
  data: [],
};

export default UsersTableBody;
