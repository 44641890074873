import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const InformationModal = (props) => {
  const { open, handleClose, modalHeading, modalContent } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">{modalHeading}</span>{" "}
        <Close onClick={handleClose} className="close-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{ReactHtmlParser(modalContent)}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

InformationModal.defaultProps = {
  open: false,
  modalHeading: "",
  modalContent: "",
};

InformationModal.propType = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  modalHeading: PropTypes.string,
  modalContent: PropTypes.string,
};

export default InformationModal;
