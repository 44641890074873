import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import UserAvatar from "../../../../assets/img/user-set.png";
import { simplifyAvatarUrl, wordOccurenceCount } from "../../../../helpers";
import "../../../../styles/components/userInfoCard.scss";

const UserInfoCard = (props) => {
  const { open, toggleModal, data, autoLogin } = props;
  const renderListItem = useCallback((label, item) => {
    if (item) {
      return (
        <div className="flexer">
          <b>{label}</b>:&nbsp; <li>{item}</li>
        </div>
      );
    }
  }, []);

  const renderAvatar = useCallback((data) => {
    if (!data.imageFileBase64) return data.imageFileBase64;
    const wordOccur = wordOccurenceCount(data.imageFileBase64, "data");
    if (wordOccur === 2) {
      const avatarUrl = simplifyAvatarUrl(data.imageFileBase64);
      return avatarUrl;
    }
    return data.imageFileBase64;
  }, []);

  return (
    <Dialog
      open={open}
      onClose={toggleModal}
      classes={{
        root: "subscription-plans-page flexer",
        paper: "payment-card-paper",
      }}
      BackdropProps={{
        classes: {
          root: "backdrop",
        },
      }}
      fullWidth={"md"}
    >
      <div className="card-container">
        <span className="pro">
          {data.isActivated === 1 ? "Activated" : "Non-activate"}
        </span>
        <div className="flexer">
          <div className="avatar-upper-container flexer">
            <img
              className="round"
              src={renderAvatar(data) || UserAvatar}
              alt="user"
            />
          </div>
        </div>
        <h3>
          {data.firstName} {data.lastName}
        </h3>
        <h6>{data.email}</h6>
        <div className="skills mb-2">
          <ul>
            {renderListItem("Address", data.address)}
            {renderListItem("City", data.city)}
            {renderListItem("State", data.state)}
            {renderListItem("Postal Code", data.zip)}
          </ul>
        </div>
        <div className="buttons  mb-4">
          {data.role === 2 && (
            <button className="primary mr-4" onClick={() => autoLogin(data)}>
              Auto-login
            </button>
          )}
          <button onClick={toggleModal} className="primary ghost">
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

UserInfoCard.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

UserInfoCard.defaultProps = {
  opem: false,
};

export default UserInfoCard;
