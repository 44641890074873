import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  Box,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";
import TabPanel from "../../TabPanel/TabPanel";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import LogDetailsTableBody from "../LogDetails/LogDetailsTableBody";
import Loader from "../../Loader/Loader";
import LogBreadcrumb from "../LogBreadcrumb/LogBreadcrumb";
import { getSearchStepTwoType } from "../../../helpers";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const searchCcbToMcCells = [
  {
    text: "Email",
  },
  {
    text: "Full name",
  },
  {
    text: "CCB #",
  },
  {
    text: "Logs",
  },
];

const searchMcToCcbCells = [
  {
    text: "Email",
  },
  {
    text: "CCB #",
  },
  {
    text: "Logs",
  },
];

const LogsSearchStepOne = (props) => {
  const {
    // Redux props
    searchTerm,
    searchData,
    searchLogsStatus,

    // component props
    history,
  } = props;
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (!searchTerm) {
      history.push("/logs");
    }
  }, [history, searchTerm]);

  useEffect(() => {
    if (searchData?.ccb_to_mc?.length) {
      setActiveTab(0);
    } else if (searchData?.mc_to_ccb?.length) {
      setActiveTab(1);
    }
  }, [searchData?.ccb_to_mc?.length, searchData?.mc_to_ccb?.length]);

  const toggleModal = useCallback(() => {
    history.push("/logs");
  }, [history]);

  const handleItemClicked = useCallback(
    (data, type) => {
      const query = data.email || data.fullName;
      history.push(
        `${history.location.pathname}/${getSearchStepTwoType(type)}/${query}`,
        { data }
      );
    },
    [history]
  );

  const renderTable = useCallback(
    (data, tableCells, tableType) => {
      return (
        <TableInfinityScroll
          className="logs-modal-table"
          loadMore={() => {}}
          fetchFunction={() => {}}
        >
          <table className="table">
            <TableHeader
              className="user-settings-greetings-table-header"
              cells={tableCells}
            />
            <LogDetailsTableBody
              data={data}
              handleItemClicked={(data) => handleItemClicked(data, tableType)}
              tableType={tableType}
            />
          </table>
        </TableInfinityScroll>
      );
    },
    [handleItemClicked]
  );

  const renderCcbToMcTable = useCallback(() => {
    if (searchData?.ccb_to_mc?.length) {
      return (
        <div className="flexer-column mt-4">
          <div className="user-settings-greetings">
            {renderTable(
              searchData?.ccb_to_mc,
              searchCcbToMcCells,
              "searchResultsCcbToMc"
            )}
          </div>
        </div>
      );
    }
  }, [renderTable, searchData?.ccb_to_mc]);

  const renderMcToCcbTable = useCallback(() => {
    if (searchData?.mc_to_ccb?.length) {
      return (
        <div className="flexer-column mt-4">
          <div className="user-settings-greetings">
            {renderTable(
              searchData?.mc_to_ccb,
              searchMcToCcbCells,
              "searchResultsMcToCcb"
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flexer-column mt-4">
          <Typography variant="h5" className="flexer text-muted">
            Was not enabled.
          </Typography>
        </div>
      );
    }
  }, [renderTable, searchData?.mc_to_ccb]);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const getBreadcrumData = useCallback(() => {
    const breadcrumbs = [
      {
        text: searchTerm,
        route: `/logs/search-results`,
      },
    ];
    return breadcrumbs;
  }, [searchTerm]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Logs Search Results</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <LogBreadcrumb breadcrumbs={getBreadcrumData()} />
          </div>

          {(Boolean(searchData?.ccb_to_mc?.length) ||
            Boolean(searchData?.mc_to_ccb?.length)) && (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="standard"
                aria-label="secondary tabs example"
              >
                <Tooltip
                  title={`${
                    searchData?.ccb_to_mc?.length ? "" : "Was not enabled."
                  }`}
                  placement="top"
                  arrow={true}
                >
                  <Tab
                    value={0}
                    label="CCB TO MC"
                    style={{ fontSize: 14, fontWeight: 600 }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${
                    searchData?.mc_to_ccb?.length ? "" : "Was not enabled."
                  }`}
                  placement="top"
                  arrow={true}
                >
                  <Tab
                    value={1}
                    label={`MC TO CCB`}
                    style={{ fontSize: 14, fontWeight: 600 }}
                  />
                </Tooltip>
              </Tabs>
            </Box>
          )}
          <TabPanel value={activeTab} index={0}>
            {renderCcbToMcTable()}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            {renderMcToCcbTable()}
          </TabPanel>
        </DialogContent>
      </Dialog>
      {searchLogsStatus === "loading" && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    searchTerm: store.logs.searchTerm,
    searchLogsStatus: store.logs.searchLogsStatus,
    searchData: store.logs.searchData,
  };
};

export default connect(mapStateToProps, null)(withRouter(LogsSearchStepOne));
