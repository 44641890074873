import React from "react";
import PropType from "prop-types";
import Logo from "../../assets/img/favicon.png";

const LogoWithHeading = (props) => {
  const { title, width } = props;
  return (
    <div className="flexer-start">
      <img src={Logo} alt="logo" className="mr-2" width={width} />
      <h3>{title}</h3>
    </div>
  );
};

LogoWithHeading.defaultProps = {
  width: 40,
};

LogoWithHeading.propTypes = {
  title: PropType.string,
  width: PropType.number,
};

export default LogoWithHeading;
