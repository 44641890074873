import React, { useMemo } from "react";
import Avatar from "react-avatar";
import PropTypes from "prop-types";
import {
  isEmptyObject,
  simplifyAvatarUrl,
  wordOccurenceCount,
} from "../helpers";

const UserAvatar = (props) => {
  const { userData } = props;

  const renderAvatar = useMemo(() => {
    if (!isEmptyObject(userData)) {
      const wordOccur = wordOccurenceCount(userData.imageFileBase64, "data");
      if (wordOccur === 2) {
        const avatarUrl = simplifyAvatarUrl(userData.imageFileBase64);
        return avatarUrl;
      }
      return userData.imageFileBase64;
    }
    return null;
  }, [userData]);

  if (isEmptyObject(userData)) return null;

  return (
    <>
      {userData.imageFileBase64 ? (
        <Avatar src={renderAvatar} className="avatar" />
      ) : (
        <Avatar
          name={userData.firstName || userData.lastName}
          email={userData.email}
          className="avatar"
        />
      )}
    </>
  );
};

UserAvatar.propTypes = {
  userData: PropTypes.object,
};

UserAvatar.defaultProps = {
  userData: {},
};

export default UserAvatar;
