import { ACTION_TYPES } from "../actions/types";

const initialState = {
  countriesList: {},
  countriesListStatus: "",
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_COUNTRIES: {
      return {
        ...state,
        countriesListStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_ALL_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countriesListStatus: "success",
        countriesList: action.payload.countries,
      };
    }

    case ACTION_TYPES.GET_ALL_COUNTRIES_ERROR: {
      return {
        ...state,
        countriesListStatus: "error",
      };
    }

    default:
      return state;
  }
};

export default countriesReducer;
