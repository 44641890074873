import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Lock, Sync } from "@material-ui/icons";
import Tabs from "../Tabs/Tabs";
import MaterialButton from "../../ MaterialButton/MaterialButton";
import SplitButton from "../../../HelperComponents/SplitButton";
import LightTooltip from "../../../HelperComponents/LightTooltip";
import { formDataWithApiKey } from "../../../helpers";
import { syncManual } from "../../../store/middlewares/integrationMiddleware";
import NotficationBanner from "../../../HelperComponents/NotficationBanner";
import { UseSyncOptions } from "../../../utils/hooks/useSyncOptions";
import "../../../styles/components/integrations/topbar.scss";

const Topbar = (props) => {
  const {
    // Component props
    openSyncConfirmationModal,
    openSyncNewPairsConfirmationModal,
    notSyncedPairs,
    togglePurchasePlanToSyncModal,
    openModalIfBrokenPairs,

    // Redux props
    currentPlan,
    warnings,
    showSyncingProgress,

    // Redux functions
    syncManual,
  } = props;
  const {
    disableSyncing,
    currentIntegrationModeText,
    isSyncingInProgress,
    SyncButtonTooltip,
    isAutoSyncingInProgress,
    syncDisabledReason,
    isTwoWaySyncingEnabled,
  } = UseSyncOptions(notSyncedPairs);
  const syncingInProgress = isSyncingInProgress || isAutoSyncingInProgress;

  const syncNewPairBtnClicked = useCallback(() => {
    if (lodash.isEmpty(currentPlan)) {
      togglePurchasePlanToSyncModal();
    } else {
      openSyncNewPairsConfirmationModal(true);
    }
  }, [
    currentPlan,
    openSyncNewPairsConfirmationModal,
    togglePurchasePlanToSyncModal,
  ]);

  const syncPairBtnClicked = useCallback(() => {
    const formData = formDataWithApiKey();
    if (lodash.isEmpty(currentPlan)) {
      togglePurchasePlanToSyncModal();
    } else if (
      Number(warnings.ccbToMcConflict) >= 1 ||
      Number(warnings.mcToCcbConflict) >= 1
    ) {
      openModalIfBrokenPairs();
    } else if (isTwoWaySyncingEnabled) {
      syncManual(formData);
    } else {
      openSyncConfirmationModal(true);
    }
  }, [
    currentPlan,
    isTwoWaySyncingEnabled,
    openModalIfBrokenPairs,
    openSyncConfirmationModal,
    syncManual,
    togglePurchasePlanToSyncModal,
    warnings,
  ]);

  const renderTopBannerMessage = useMemo(() => {
    return (
      <span>
        You have <b>{notSyncedPairs.length}</b> new{" "}
        {notSyncedPairs?.length === 1 ? "pair" : "pairs"} saved and{" "}
        {notSyncedPairs?.length === 1 ? "is" : "are"} now available to sync
        either manually or automatically at a later time.{" "}
        <u
          onClick={syncNewPairBtnClicked}
          className="cursor-pointer font-weight-bold"
        >
          CONFIRM AND SYNC NOW
        </u>
      </span>
    );
  }, [notSyncedPairs, syncNewPairBtnClicked]);

  const renderSyncButtonIcon = useMemo(() => {
    if (lodash.isEmpty(currentPlan)) {
      return <Lock fontSize="small" className="mr-2" />;
    } else if (syncingInProgress) {
      return <Sync fontSize="small" className="mr-2" color="disabled" />;
    }
    return "";
  }, [currentPlan, syncingInProgress]);

  const renderNotificationBanner = useMemo(() => {
    if (!syncingInProgress && !lodash.isEmpty(notSyncedPairs)) {
      return (
        <div className="sync-page-notfication-banner-wrapper">
          <NotficationBanner
            className="sync-page-noti-banner"
            message={renderTopBannerMessage}
          />
        </div>
      );
    }
    return null;
  }, [syncingInProgress, notSyncedPairs, renderTopBannerMessage]);

  const topMargin = useMemo(() => {
    if (!syncingInProgress && !lodash.isEmpty(notSyncedPairs)) {
      return "mt-2";
    } else if (!showSyncingProgress) {
      return "mt-4";
    }
    return "";
  }, [syncingInProgress, notSyncedPairs, showSyncingProgress]);

  return (
    <>
      {renderNotificationBanner}
      <div className="service-topbar-wrapper">
        <div className={`service-topbar-inner-wrapper ${topMargin}`}>
          <Tabs />
        </div>
        <div className="mr-2 flexer">
          <span className="service-topbar-select-tab-text">
            {currentIntegrationModeText}{" "}
          </span>
          <LightTooltip
            arrow={true}
            placement={"bottom-end"}
            title={SyncButtonTooltip}
          >
            <div>
              {Boolean(notSyncedPairs?.length) ? (
                <SplitButton
                  options={[`Sync New (${notSyncedPairs.length})`]}
                  className={`service-topbar-sync-button ${
                    disableSyncing || syncDisabledReason ? "disabled" : ""
                  }`}
                  dropdownBtnClass={`${
                    disableSyncing || syncDisabledReason
                      ? "disabled"
                      : "bg-yellow"
                  }`}
                  disabled={disableSyncing || syncDisabledReason}
                  onClick={syncNewPairBtnClicked}
                  title={
                    <span>
                      {renderSyncButtonIcon}{" "}
                      {syncingInProgress ? "Syncing" : "Sync"}
                    </span>
                  }
                />
              ) : (
                <MaterialButton
                  onClick={syncPairBtnClicked}
                  title={syncingInProgress ? "Syncing" : "Sync"}
                  className={`service-topbar-sync-button ${
                    disableSyncing || syncDisabledReason ? "disabled" : ""
                  }`}
                  disabled={disableSyncing || syncDisabledReason}
                  icon={renderSyncButtonIcon}
                />
              )}
            </div>
          </LightTooltip>
        </div>
      </div>
    </>
  );
};

Topbar.propTypes = {
  openSyncConfirmationModal: PropTypes.func.isRequired,
  openSyncNewPairsConfirmationModal: PropTypes.func.isRequired,
  togglePurchasePlanToSyncModal: PropTypes.func.isRequired,
};

Topbar.defaultProps = {};

const mapStateToProps = (store) => {
  return {
    isMcToCcbIntegrationEnabled: store.integrations.isMcToCcbIntegrationEnabled,
    currentPlan: store.billing.currentPlan,
    showSyncingProgress: store.integrations.showSyncingProgress,
    warnings: store.userReducer.warnings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    syncManual: (data) => dispatch(syncManual(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
