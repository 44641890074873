const ACTION_TYPES = {
  // Global actions
  LOADING: "LOADING",
  USER_LOGOUT: "USER_LOGOUT",
  SET_DATA_FOR_MODAL: "SET_DATA_FOR_MODAL",
  TOGGE_IS_SIDEBAR_EXPANDED: "TOGGE_IS_SIDEBAR_EXPANDED",

  CHECK_SYNC: "CHECK_SYNC",
  CHECK_SYNC_SUCCESS: "CHECK_SYNC.SUCCESS",
  CHECK_SYNC_ERROR: "CHECK_SYNC.ERROR",
  CHECK_SYNC_CLEAR: "CHECK_SYNC.CLEAR",
  CLEAR_API_DETAILS_STATE: "CLEAR_API_DETAILS_STATE.CLEAR",
  ADD_TO_QUEUE_CLEAR: "ADD_TO_QUEUE_CLEAR",

  IS_AUTO_SYNCING: "IS_AUTO_SYNCING",
  IS_AUTO_SYNCING_SUCCESS: "IS_AUTO_SYNCING.SUCCESS",
  IS_AUTO_SYNCING_ERROR: "IS_AUTO_SYNCING.ERROR",
  IS_AUTO_SYNCING_CLEAR: "IS_AUTO_SYNCING.CLEAR",

  GET_WARNINGS: "GET_WARNINGS",
  GET_WARNINGS_SUCCESS: "GET_WARNINGS.SUCCESS",
  GET_WARNINGS_ERROR: "GET_WARNINGS.ERROR",

  // Snackbar action
  SET_SNACKBAR_DATA: "SET_SNACKBAR_DATA",

  // User login actions
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  // Profile actions
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE.SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE.ERROR",

  UPDATE_PROFILE_CLEAR: "UPDATE_PROFILE_CLEAR",
  UPDATE_PROFILE_CLEAR_ERROR_MESSAGE: "UPDATE_PROFILE_CLEAR_ERROR_MESSAGE",

  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY.SUCCESS",
  UPDATE_COUNTRY_ERROR: "UPDATE_COUNTRY.ERROR",

  // API details
  GET_API_DETAILS: "GET_API_DETAILS",
  GET_API_DETAILS_SUCCESS: "GET_API_DETAILS.SUCCESS",
  GET_API_DETAILS_ERROR: "GET_API_DETAILS.ERROR",

  // Logs page actions
  GET_LOGS: "GET_LOGS",
  GET_LOGS_SUCCESS: "GET_LOGS.SUCCESS",
  GET_LOGS_ERROR: "GET_LOGS.ERROR",

  GET_LOGS_DETAILS: "GET_LOGS_DETAILS",
  GET_LOGS_DETAILS_SUCCESS: "GET_LOGS_DETAILS.SUCCESS",
  GET_LOGS_DETAILS_ERROR: "GET_LOGS_DETAILS.ERROR",

  GET_LOG_STATISTICS: "GET_LOG_STATISTICS",
  GET_LOG_STATISTICS_SUCCESS: "GET_LOG_STATISTICS.SUCCESS",
  GET_LOG_STATISTICS_ERROR: "GET_LOG_STATISTICS.ERROR",

  SEARCH_LOGS: "SEARCH_LOGS",
  SEARCH_LOGS_SUCCESS: "SEARCH_LOGS.SUCCESS",
  SEARCH_LOGS_ERROR: "SEARCH_LOGS.ERROR",

  SEARCH_STEP_TWO: "SEARCH_STEP_TWO",
  SEARCH_STEP_TWO_SUCCESS: "SEARCH_STEP_TWO.SUCCESS",
  SEARCH_STEP_TWO_ERROR: "SEARCH_STEP_TWO.ERROR",

  REVERT_LOGS: "REVERT_LOGS",
  REVERT_LOGS_SUCCESS: "REVERT_LOGS.SUCCESS",
  REVERT_LOGS_ERROR: "REVERT_LOGS.ERROR",
  REVERT_LOGS_CLEAR: "REVERT_LOGS.CLEAR",

  SET_LOGS_DETAILS: "SET_LOGS_DETAILS",

  SET_LOG_SEARCH_TERM: "SET_LOG_SEARCH_TERM",

  GET_MC_REQUIRED_MERGE_FIELDS: "GET_MC_REQUIRED_MERGE_FIELDS",
  GET_MC_REQUIRED_MERGE_FIELDS_SUCCESS: "GET_MC_REQUIRED_MERGE_FIELDS.SUCCESS",
  GET_MC_REQUIRED_MERGE_FIELDS_ERROR: "GET_MC_REQUIRED_MERGE_FIELDS.ERROR",

  // Integration page actions
  CLEAR_INTEGRATION_PAGE_STATE: "CLEAR_INTEGRATION_PAGE_STATE",

  // CCB Actions
  GET_CCB_CAMPUSES: "GET_CCB_CAMPUSES",
  GET_CCB_CAMPUSES_SUCCESS: "GET_CCB_CAMPUSES.SUCCESS",
  GET_CCB_CAMPUSES_ERROR: "GET_CCB_CAMPUSES.ERROR",

  GET_CCB_SAVED_SEARCHES: "GET_CCB_SAVED_SEARCHES",
  GET_CCB_SAVED_SEARCHES_SUCCESS: "GET_CCB_SAVED_SEARCHES.SUCCESS",
  GET_CCB_SAVED_SEARCHES_ERROR: "GET_CCB_SAVED_SEARCHES.ERROR",

  GET_MC_TO_CCB_PAIRS: "GET_MC_TO_CCB_PAIRS",
  GET_MC_TO_CCB_PAIRS_SUCCESS: "GET_MC_TO_CCB_PAIRS.SUCCESS",
  GET_MC_TO_CCB_PAIRS_ERROR: "GET_MC_TO_CCB_PAIRS.ERROR",

  SAVE_CCB_TO_MC_SYNC_PAIR: "SAVE_CCB_TO_MC_SYNC_PAIR",
  SAVE_CCB_TO_MC_SYNC_PAIR_SUCCESS: "SAVE_CCB_TO_MC_SYNC_PAIR.SUCCESS",
  SAVE_CCB_TO_MC_SYNC_PAIR_ERROR: "SAVE_CCB_TO_MC_SYNC_PAIR.ERROR",
  SAVE_CCB_TO_MC_SYNC_PAIR_CLEAR: "SAVE_CCB_TO_MC_SYNC_PAIR_CLEAR",

  REMOVE_CCB_TO_MC_SYNC_PAIR: "REMOVE_CCB_TO_MC_SYNC_PAIR",
  REMOVE_CCB_TO_MC_SYNC_PAIR_SUCCESS: "REMOVE_CCB_TO_MC_SYNC_PAIR.SUCCESS",
  REMOVE_CCB_TO_MC_SYNC_PAIR_ERROR: "REMOVE_CCB_TO_MC_SYNC_PAIR.ERROR",
  REMOVE_CCB_TO_MC_SYNC_PAIR_CLEAR: "REMOVE_CCB_TO_MC_SYNC_PAIR_CLEAR",

  REMOVE_MULTIPLE_CCB_TO_MC_PAIR: "REMOVE_MULTIPLE_CCB_TO_MC_PAIR",
  REMOVE_MULTIPLE_CCB_TO_MC_PAIR_SUCCESS:
    "REMOVE_MULTIPLE_CCB_TO_MC_PAIR.SUCCESS",
  REMOVE_MULTIPLE_CCB_TO_MC_PAIR_ERROR: "REMOVE_MULTIPLE_CCB_TO_MC_PAIR.ERROR",
  REMOVE_MULTIPLE_CCB_TO_MC_PAIR_CLEAR: "REMOVE_MULTIPLE_CCB_TO_MC_PAIR_CLEAR",

  // Mailchimp Actions
  GET_MAILCHIMP_AUDIENCE: "GET_MAILCHIMP_AUDIENCE",
  GET_MAILCHIMP_AUDIENCE_SUCCESS: "GET_MAILCHIMP_AUDIENCE.SUCCESS",
  GET_MAILCHIMP_AUDIENCE_ERROR: "GET_MAILCHIMP_AUDIENCE.ERROR",
  GET_MAILCHIMP_AUDIENCE_CLEAR: "GET_MAILCHIMP_AUDIENCE_CLEAR",

  SET_MAILCHIMP_INTERESTED_GROUPS: "SET_MAILCHIMP_INTERESTED_GROUPS",
  SET_MAILCHIMP_INTERESTED_GROUPS_SUCCESS:
    "SET_MAILCHIMP_INTERESTED_GROUPS.SUCCESS",
  SET_MAILCHIMP_INTERESTED_GROUPS_ERROR:
    "SET_MAILCHIMP_INTERESTED_GROUPS.ERROR",

  SET_CCB_GROUP: "SET_CCB_GROUP",
  SET_CCB_GROUP_SUCCESS: "SET_CCB_GROUP.SUCCESS",
  SET_CCB_GROUP_ERROR: "SET_CCB_GROUP.ERROR",

  SET_CCB_PROCESS_QUEUES: "SET_CCB_PROCESS_QUEUES",
  SET_CCB_PROCESS_QUEUES_SUCCESS: "SET_CCB_PROCESS_QUEUES.SUCCESS",
  SET_CCB_PROCESS_QUEUES_ERROR: "SET_CCB_PROCESS_QUEUES.ERROR",

  SET_MAILCHIMP_TAGS: "SET_MAILCHIMP_TAGS",
  SET_MAILCHIMP_TAGS_SUCCESS: "SET_MAILCHIMP_TAGS.SUCCESS",
  SET_MAILCHIMP_TAGS_ERROR: "SET_MAILCHIMP_TAGS.ERROR",

  SAVE_MC_TO_CCB_SYNC_PAIR: "SAVE_MC_TO_CCB_SYNC_PAIR",
  SAVE_MC_TO_CCB_SYNC_PAIR_SUCCESS: "SAVE_MC_TO_CCB_SYNC_PAIR.SUCCESS",
  SAVE_MC_TO_CCB_SYNC_PAIR_ERROR: "SAVE_MC_TO_CCB_SYNC_PAIR.ERROR",
  SAVE_MC_TO_CCB_SYNC_PAIR_CLEAR: "SAVE_MC_TO_CCB_SYNC_PAIR_CLEAR",

  SAVE_SKIP_MERGE_VALIDATION: "SAVE_SKIP_MERGE_VALIDATION",
  SAVE_SKIP_MERGE_VALIDATION_SUCCESS: "SAVE_SKIP_MERGE_VALIDATION.SUCCESS",
  SAVE_SKIP_MERGE_VALIDATION_ERROR: "SAVE_SKIP_MERGE_VALIDATION.ERROR",
  SAVE_SKIP_MERGE_VALIDATION_CLEAR: "SAVE_SKIP_MERGE_VALIDATION_CLEAR",

  REMOVE_MC_TO_CCB_SYNC_PAIR: "REMOVE_MC_TO_CCB_SYNC_PAIR",
  REMOVE_MC_TO_CCB_SYNC_PAIR_SUCCESS: "REMOVE_MC_TO_CCB_SYNC_PAIR.SUCCESS",
  REMOVE_MC_TO_CCB_SYNC_PAIR_ERROR: "REMOVE_MC_TO_CCB_SYNC_PAIR.ERROR",
  REMOVE_MC_TO_CCB_SYNC_PAIR_CLEAR: "REMOVE_MC_TO_CCB_SYNC_PAIR_CLEAR",

  REMOVE_MULTIPLE_MC_TO_CCB_PAIR: "REMOVE_MULTIPLE_MC_TO_CCB_PAIR",
  REMOVE_MULTIPLE_MC_TO_CCB_PAIR_SUCCESS:
    "REMOVE_MULTIPLE_MC_TO_CCB_PAIR.SUCCESS",
  REMOVE_MULTIPLE_MC_TO_CCB_PAIR_ERROR: "REMOVE_MULTIPLE_MC_TO_CCB_PAIR.ERROR",
  REMOVE_MULTIPLE_MC_TO_CCB_PAIR_CLEAR: "REMOVE_MULTIPLE_MC_TO_CCB_PAIR_CLEAR",

  REFRESH_LIST: "REFRESH_LIST",
  REFRESH_LIST_SUCCESS: "REFRESH_LIST.SUCCESS",
  REFRESH_LIST_ERROR: "REFRESH_LIST.ERROR",
  REFRESH_LIST_CLEAR: "REFRESH_LIST_CLEAR",

  HAS_SAVED_SEARCH_PERMISSIONS: "HAS_SAVED_SEARCH_PERMISSIONS",
  HAS_SAVED_SEARCH_PERMISSIONS_SUCCESS: "HAS_SAVED_SEARCH_PERMISSIONS.SUCCESS",
  HAS_SAVED_SEARCH_PERMISSIONS_ERROR: "HAS_SAVED_SEARCH_PERMISSIONS.ERROR",
  HAS_SAVED_SEARCH_PERMISSIONS_CLEAR: "HAS_SAVED_SEARCH_PERMISSIONS_CLEAR",

  // SYNC PAIRS
  SYNC_MANUAL: "SYNC_MANUAL",
  SYNC_MANUAL_SUCCESS: "SYNC_MANUAL.SUCCESS",
  SYNC_MANUAL_ERROR: "SYNC_MANUAL.ERROR",
  SYNC_MANUAL_CLEAR: "SYNC_MANUAL.CLEAR",

  // SYNC NEW PAIRS
  SYNC_ONLY_NEW_PAIRS: "SYNC_ONLY_NEW_PAIRS",
  SYNC_ONLY_NEW_PAIRS_SUCCESS: "SYNC_ONLY_NEW_PAIRS.SUCCESS",
  SYNC_ONLY_NEW_PAIRS_ERROR: "SYNC_ONLY_NEW_PAIRS.ERROR",

  // ENABLE TWO WAY SYNCING
  ENABLE_TWO_WAY_SYNCING: "ENABLE_TWO_WAY_SYNCING",
  ENABLE_TWO_WAY_SYNCING_SUCCESS: "ENABLE_TWO_WAY_SYNCING.SUCCESS",
  ENABLE_TWO_WAY_SYNCING_ERROR: "ENABLE_TWO_WAY_SYNCING.ERROR",
  ENABLE_TWO_WAY_SYNCING_CLEAR: "ENABLE_TWO_WAY_SYNCING.CLEAR",

  // DISABLE TWO WAY SYNCING
  DISABLE_TWO_WAY_SYNCING: "DISABLE_TWO_WAY_SYNCING",
  DISABLE_TWO_WAY_SYNCING_SUCCESS: "DISABLE_TWO_WAY_SYNCING.SUCCESS",
  DISABLE_TWO_WAY_SYNCING_ERROR: "DISABLE_TWO_WAY_SYNCING.ERROR",
  DISABLE_TWO_WAY_SYNCING_CLEAR: "DISABLE_TWO_WAY_SYNCING_CLEAR",

  // UNRESOLVE CONFLICTS PAIR
  GET_UNRESOLVE_CONFLICTS_PAIRS: "GET_UNRESOLVE_CONFLICTS_PAIRS",
  GET_UNRESOLVE_CONFLICTS_PAIRS_SUCCESS:
    "GET_UNRESOLVE_CONFLICTS_PAIRS.SUCCESS",
  GET_UNRESOLVE_CONFLICTS_PAIRS_ERROR: "GET_UNRESOLVE_CONFLICTS_PAIRS.ERROR",
  GET_UNRESOLVE_CONFLICTS_PAIRS_CLEAR: "GET_UNRESOLVE_CONFLICTS_PAIRS.CLEAR",

  // HOLD CONTACTS
  GET_HOLD_CONTACTS: "GET_HOLD_CONTACTS",
  GET_HOLD_CONTACTS_SUCCESS: "GET_HOLD_CONTACTS.SUCCESS",
  GET_HOLD_CONTACTS_ERROR: "GET_HOLD_CONTACTS.ERROR",
  GET_HOLD_CONTACTS_CLEAR: "GET_HOLD_CONTACTS.CLEAR",

  // GET POSSIBLE MATCHES
  GET_POSSIBLE_MATCHES: "GET_POSSIBLE_MATCHES",
  GET_POSSIBLE_MATCHES_SUCCESS: "GET_POSSIBLE_MATCHES.SUCCESS",
  GET_POSSIBLE_MATCHES_ERROR: "GET_POSSIBLE_MATCHES.ERROR",
  GET_POSSIBLE_MATCHES_CLEAR: "GET_POSSIBLE_MATCHES.CLEAR",

  // MATCH CONTACTS
  MATCH_CONTACTS: "MATCH_CONTACTS",
  MATCH_CONTACTS_SUCCESS: "MATCH_CONTACTS.SUCCESS",
  MATCH_CONTACTS_ERROR: "MATCH_CONTACTS.ERROR",
  MATCH_CONTACTS_CLEAR: "MATCH_CONTACTS.CLEAR",

  // IFNORE HOLD CONTACTS
  IGNORE_HOLD_CONTACTS: "IGNORE_HOLD_CONTACTS",
  IGNORE_HOLD_CONTACTS_SUCCESS: "IGNORE_HOLD_CONTACTS.SUCCESS",
  IGNORE_HOLD_CONTACTS_ERROR: "IGNORE_HOLD_CONTACTS.ERROR",
  IGNORE_HOLD_CONTACTS_CLEAR: "IGNORE_HOLD_CONTACTS.CLEAR",

  // IGNORE ALL HOLD CONTACTS
  IGNORE_ALL_HOLD_CONTACTS: "IGNORE_ALL_HOLD_CONTACTS",
  IGNORE_ALL_HOLD_CONTACTS_SUCCESS: "IGNORE_ALL_HOLD_CONTACTS.SUCCESS",
  IGNORE_ALL_HOLD_CONTACTS_ERROR: "IGNORE_ALL_HOLD_CONTACTS.ERROR",
  IGNORE_ALL_HOLD_CONTACTS_CLEAR: "IGNORE_ALL_HOLD_CONTACTS.CLEAR",

  // Stop sync
  STOP_SYNC: "STOP_SYNC",
  STOP_SYNC_SUCCESS: "STOP_SYNC.SUCCESS",
  STOP_SYNC_ERROR: "STOP_SYNC.ERROR",
  STOP_SYNC_CLEAR: "STOP_SYNC.CLEAR",

  SET_SHOW_SEARCH_FOR: "SET_SHOW_SEARCH_FOR",

  CHANGE_SYNC_TYPE: "CHANGE_SYNC_TYPE",

  TOGGLE_SWITCH_CCB_TO_MC: "TOGGLE_SWITCH_CCB_TO_MC",

  TOGGLE_SWITCH_MC_TO_CCB: "TOGGLE_SWITCH_MC_TO_CCB",

  SHOW_REMOVE_SYNC_PAIR_MODAL: "SHOW_REMOVE_SYNC_PAIR_MODAL",

  SHOW_SYNC_CONFIRMATION_MODAL: "SHOW_SYNC_CONFIRMATION_MODAL",

  SHOW_NEW_SYNC_PAIRS_CONFIRMATION_MODAL:
    "SHOW_NEW_SYNC_PAIRS_CONFIRMATION_MODAL",

  SHOW_SYNCING_PROGRESS: "SHOW_SYNCING_PROGRESS",

  SHOW_ADD_NEW_SYNC_PAIRS_MODAL: "SHOW_ADD_NEW_SYNC_PAIRS_MODAL",

  HOVER_INTEGRATION_ROW: "HOVER_INTEGRATION_ROW",

  // AUTHORIZE ACCOUNT PAGE
  AUTHORIZE_CCB_ACCOUNT: "AUTHORIZE_CCB_ACCOUNT",
  AUTHORIZE_CCB_ACCOUNT_SUCCESS: "AUTHORIZE_CCB_ACCOUNT.SUCCESS",
  AUTHORIZE_CCB_ACCOUNT_ERROR: "AUTHORIZE_CCB_ACCOUNT.ERROR",
  AUTHORIZE_CCB_ACCOUNT_CLEAR: "AUTHORIZE_CCB_ACCOUNT_CLEAR",

  REMOVE_CCB_CREDENTIALS: "REMOVE_CCB_CREDENTIALS",
  REMOVE_CCB_CREDENTIALS_SUCCESS: "REMOVE_CCB_CREDENTIALS.SUCCESS",
  REMOVE_CCB_CREDENTIALS_ERROR: "REMOVE_CCB_CREDENTIALS.ERROR",

  AUTHORIZE_MC_ACCOUNT: "AUTHORIZE_MC_ACCOUNT",
  AUTHORIZE_MC_ACCOUNT_SUCCESS: "AUTHORIZE_MC_ACCOUNT.SUCCESS",
  AUTHORIZE_MC_ACCOUNT_ERROR: "AUTHORIZE_MC_ACCOUNT.ERROR",
  AUTHORIZE_MC_ACCOUNT_CLEAR: "AUTHORIZE_MC_ACCOUNT_CLEAR",

  REMOVE_MC_CREDENTIALS: "REMOVE_MC_CREDENTIALS",
  REMOVE_MC_CREDENTIALS_SUCCESS: "REMOVE_MC_CREDENTIALS.SUCCESS",
  REMOVE_MC_CREDENTIALS_ERROR: "REMOVE_MC_CREDENTIALS.ERROR",

  DISABLE_CCB_SYNCING: "DISABLE_CCB_SYNCING",
  DISABLE_CCB_SYNCING_SUCCESS: "DISABLE_CCB_SYNCING.SUCCESS",
  DISABLE_CCB_SYNCING_ERROR: "DISABLE_CCB_SYNCING.ERROR",

  ENABLE_CCB_SYNCING: "ENABLE_CCB_SYNCING",
  ENABLE_CCB_SYNCING_SUCCESS: "ENABLE_CCB_SYNCING.SUCCESS",
  ENABLE_CCB_SYNCING_ERROR: "ENABLE_CCB_SYNCING.ERROR",

  ENABLE_DISABLE_CCB_SYNCING_CLEAR: "ENABLE_DISABLE_CCB_SYNCING_CLEAR",

  ENABLE_USER_STORE_INFO: "ENABLE_USER_STORE_INFO",
  ENABLE_USER_STORE_INFO_SUCCESS: "ENABLE_USER_STORE_INFO.SUCCESS",
  ENABLE_USER_STORE_INFO_ERROR: "ENABLE_USER_STORE_INFO.ERROR",

  DISABLE_USER_STORE_INFO: "DISABLE_USER_STORE_INFO",
  DISABLE_USER_STORE_INFO_SUCCESS: "DISABLE_USER_STORE_INFO.SUCCESS",
  DISABLE_USER_STORE_INFO_ERROR: "DISABLE_USER_STORE_INFO.ERROR",
  ENABLE_DISABLE_USER_STORE_INFO_CLEAR: "DISABLE_USER_STORE_INFO.CLEAR",

  SET_HAS_NOT_SYNCED_PAIRS: "SET_HAS_NOT_SYNCED_PAIRS",

  MC_TO_CCB_DISABLE_INACTIVE_REMOVE_FOR:
    "MC_TO_CCB_DISABLE_INACTIVE_REMOVE_FOR",

  // TOOLS page Actions
  CLEAR_TOOL_PAGE_STATE: "CLEAR_TOOL_PAGE_STATE",

  SAVE_TOOLKIT_CLEANUP: "SAVE_TOOLKIT_CLEANUP",
  SAVE_TOOLKIT_CLEANUP_SUCCESS: "SAVE_TOOLKIT_CLEANUP.SUCCESS",
  SAVE_TOOLKIT_CLEANUP_ERROR: "SAVE_TOOLKIT_CLEANUP.ERROR",

  SAVE_STORE_USER_INFO: "SAVE_STORE_USER_INFO",
  SAVE_STORE_USER_INFO_SUCCESS: "SAVE_STORE_USER_INFO.SUCCESS",
  SAVE_STORE_USER_INFO_ERROR: "SAVE_STORE_USER_INFO.ERROR",

  SAVE_PRIMARY_PROFILES: "SAVE_PRIMARY_PROFILES",
  SAVE_PRIMARY_PROFILES_SUCCESS: "SAVE_PRIMARY_PROFILES.SUCCESS",
  SAVE_PRIMARY_PROFILES_ERROR: "SAVE_PRIMARY_PROFILES.ERROR",

  SAVE_MC_TO_CCB_REMOVE_INACTIVE: "SAVE_MC_TO_CCB_REMOVE_INACTIVE",
  SAVE_MC_TO_CCB_REMOVE_INACTIVE_SUCCESS:
    "SAVE_MC_TO_CCB_REMOVE_INACTIVE.SUCCESS",
  SAVE_MC_TO_CCB_REMOVE_INACTIVE_ERROR: "SAVE_MC_TO_CCB_REMOVE_INACTIVE.ERROR",

  SAVE_AUTO_SYNC: "SAVE_AUTO_SYNC",
  SAVE_AUTO_SYNC_SUCCESS: "SAVE_AUTO_SYNC.SUCCESS",
  SAVE_AUTO_SYNC_ERROR: "SAVE_AUTO_SYNC.ERROR",

  SAVE_EMAIL_NOTIFICATION_FOR_UBSUB: "SAVE_EMAIL_NOTIFICATION_FOR_UBSUB",
  SAVE_EMAIL_NOTIFICATION_FOR_UBSUB_SUCCESS:
    "SAVE_EMAIL_NOTIFICATION_FOR_UBSUB.SUCCESS",
  SAVE_EMAIL_NOTIFICATION_FOR_UBSUB_ERROR:
    "SAVE_EMAIL_NOTIFICATION_FOR_UBSUB.ERROR",

  SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES:
    "SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES",
  SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES_SUCCESS:
    "SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES.SUCCESS",
  SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES_ERROR:
    "SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES.ERROR",

  SAVE_CHECK_RECEIVED_EMAIL: "SAVE_CHECK_RECEIVED_EMAIL",
  SAVE_CHECK_RECEIVED_EMAIL_SUCCESS: "SAVE_CHECK_RECEIVED_EMAIL.SUCCESS",
  SAVE_CHECK_RECEIVED_EMAIL_ERROR: "SAVE_EMAIL_NOTIFICATION_FOR_UBSUB.ERROR",

  SAVE_DELETE_MC_LIST_GROUP: "SAVE_DELETE_MC_LIST_GROUP",
  SAVE_DELETE_MC_LIST_GROUP_SUCCESS: "SAVE_DELETE_MC_LIST_GROUP.SUCCESS",
  SAVE_DELETE_MC_LIST_GROUP_ERROR: "SAVE_DELETE_MC_LIST_GROUP.ERROR",

  SAVE_MEMBERS_SETTINGS: "SAVE_MEMBERS_SETTINGS",
  SAVE_MEMBERS_SETTINGS_SUCCESS: "SAVE_MEMBERS_SETTINGS.SUCCESS",
  SAVE_MEMBERS_SETTINGS_ERROR: "SAVE_MEMBERS_SETTINGS.ERROR",

  ACCOUNT_CLEANUP: "ACCOUNT_CLEANUP",
  ACCOUNT_CLEANUP_SUCCESS: "ACCOUNT_CLEANUP.SUCCESS",
  ACCOUNT_CLEANUP_ERROR: "ACCOUNT_CLEANUP.ERROR",

  GET_PRIMARY_CONTACTS: "GET_PRIMARY_CONTACTS",
  GET_PRIMARY_CONTACTS_SUCCESS: "GET_PRIMARY_CONTACTS.SUCCESS",
  GET_PRIMARY_CONTACTS_ERROR: "GET_PRIMARY_CONTACTS.ERROR",

  MAKE_PRIMARY_CONTACTS: "MAKE_PRIMARY_CONTACTS",
  MAKE_PRIMARY_CONTACTS_SUCCESS: "MAKE_PRIMARY_CONTACTS.SUCCESS",
  MAKE_PRIMARY_CONTACTS_ERROR: "MAKE_PRIMARY_CONTACTS.ERROR",
  MAKE_PRIMARY_CONTACTS_CLEAR: "MAKE_PRIMARY_CONTACTS.CLEAR",

  GET_IGNORED_EMAILS: "GET_IGNORED_EMAILS",
  GET_IGNORED_EMAILS_SUCCESS: "GET_IGNORED_EMAILS.SUCCESS",
  GET_IGNORED_EMAILS_ERROR: "GET_IGNORED_EMAILS.ERROR",

  UNIGNORE_EMAIL: "UNIGNORE_EMAIL",
  UNIGNORE_EMAIL_SUCCESS: "UNIGNORE_EMAIL.SUCCESS",
  UNIGNORE_EMAIL_ERROR: "UNIGNORE_EMAIL.ERROR",
  UNIGNORE_EMAIL_CLEAR: "UNIGNORE_EMAIL.CLEAR",

  GET_CLEANUP_UNSYNC_PAIRS: "GET_CLEANUP_UNSYNC_PAIRS",
  GET_CLEANUP_UNSYNC_PAIRS_SUCCESS: "GET_CLEANUP_UNSYNC_PAIRS.SUCCESS",
  GET_CLEANUP_UNSYNC_PAIRS_ERROR: "GET_CLEANUP_UNSYNC_PAIRS.ERROR",

  DELETE_MC: "DELETE_MC",
  DELETE_MC_SUCCESS: "DELETE_MC.SUCCESS",
  DELETE_MC_ERROR: "DELETE_MC.ERROR",
  DELETE_MC_CLEAR: "DELETE_MC.CLEAR",

  DISMISS_DELETE_MC: "DISMISS_DELETE_MC",
  DISMISS_DELETE_MC_SUCCESS: "DISMISS_DELETE_MC.SUCCESS",
  DISMISS_DELETE_MC_ERROR: "DISMISS_DELETE_MC.ERROR",
  DISMISS_DELETE_MC_CLEAR: "DISMISS_DELETE_MC.CLEAR",

  GET_HEADER_MAPPING: "GET_HEADER_MAPPING",
  GET_HEADER_MAPPING_SUCCESS: "GET_HEADER_MAPPING.SUCCESS",
  GET_HEADER_MAPPING_ERROR: "GET_HEADER_MAPPING.ERROR",

  SAVE_HEADER_MAPPING: "SAVE_HEADER_MAPPING",
  SAVE_HEADER_MAPPING_SUCCESS: "SAVE_HEADER_MAPPING.SUCCESS",
  SAVE_HEADER_MAPPING_ERROR: "SAVE_HEADER_MAPPING.ERROR",
  SAVE_HEADER_MAPPING_CLEAR: "SAVE_HEADER_MAPPING.CLEAR",

  GET_MC_TO_CCB_CUSTOM_LIST: "GET_MC_TO_CCB_CUSTOM_LIST",
  GET_MC_TO_CCB_CUSTOM_LIST_SUCCESS: "GET_MC_TO_CCB_CUSTOM_LIST.SUCCESS",
  GET_MC_TO_CCB_CUSTOM_LIST_ERROR: "GET_MC_TO_CCB_CUSTOM_LIST.ERROR",

  ADD_MC_TO_CCB_CUSTOM_LIST: "ADD_MC_TO_CCB_CUSTOM_LIST",
  ADD_MC_TO_CCB_CUSTOM_LIST_SUCCESS: "ADD_MC_TO_CCB_CUSTOM_LIST.SUCCESS",
  ADD_MC_TO_CCB_CUSTOM_LIST_ERROR: "ADD_MC_TO_CCB_CUSTOM_LIST.ERROR",
  ADD_MC_TO_CCB_CUSTOM_LIST_CLEAR: "ADD_MC_TO_CCB_CUSTOM_LIST.CLEAR",

  DELETE_MC_TO_CCB_CUSTOM_LIST: "DELETE_MC_TO_CCB_CUSTOM_LIST",
  DELETE_MC_TO_CCB_CUSTOM_LIST_SUCCESS: "DELETE_MC_TO_CCB_CUSTOM_LIST.SUCCESS",
  DELETE_MC_TO_CCB_CUSTOM_LIST_ERROR: "DELETE_MC_TO_CCB_CUSTOM_LIST.ERROR",
  DELETE_MC_TO_CCB_CUSTOM_LIST_CLEAR: "DELETE_MC_TO_CCB_CUSTOM_LIST.CLEAR",

  // BILLINGS PAGE ACTIONS
  GET_ALL_INVOICES: "GET_ALL_INVOICES",
  GET_ALL_INVOICES_SUCCESS: "GET_ALL_INVOICES.SUCCESS",
  GET_ALL_INVOICES_ERROR: "GET_ALL_INVOICES.ERROR",

  GET_ALL_AVAILABLE_PLANS: "GET_ALL_AVAILABLE_PLANS",
  GET_ALL_AVAILABLE_PLANS_SUCCESS: "GET_ALL_AVAILABLE_PLANS.SUCCESS",
  GET_ALL_AVAILABLE_PLANS_ERROR: "GET_ALL_AVAILABLE_PLANS.ERROR",

  GET_CURRENT_PLAN: "GET_CURRENT_PLAN",
  GET_CURRENT_PLAN_SUCCESS: "GET_CURRENT_PLAN.SUCCESS",
  GET_CURRENT_PLAN_ERROR: "GET_CURRENT_PLAN.ERROR",

  CHECK_COUPON: "CHECK_COUPON",
  CHECK_COUPON_SUCCESS: "CHECK_COUPON.SUCCESS",
  CHECK_COUPON_ERROR: "CHECK_COUPON.ERROR",
  CHECK_COUPON_CLEAR: "CHECK_COUPON.CLEAR",

  CANCEL_PLAN: "CANCEL_PLAN",
  CANCEL_PLAN_SUCCESS: "CANCEL_PLAN.SUCCESS",
  CANCEL_PLAN_ERROR: "CANCEL_PLAN.ERROR",
  CANCEL_PLAN_CLEAR: "CANCEL_PLAN.CLEAR",

  GET_CURRENT_CARD_DETAILS: "GET_CURRENT_CARD_DETAILS",
  GET_CURRENT_CARD_DETAILS_SUCCESS: "GET_CURRENT_CARD_DETAILS.SUCCESS",
  GET_CURRENT_CARD_DETAILS_ERROR: "GET_CURRENT_CARD_DETAILS.ERROR",

  ADD_NEW_CARD: "ADD_NEW_CARD",
  ADD_NEW_CARD_SUCCESS: "ADD_NEW_CARD.SUCCESS",
  ADD_NEW_CARD_ERROR: "ADD_NEW_CARD.ERROR",

  UPDATE_CURRENT_CARD: "UPDATE_CURRENT_CARD",
  UPDATE_CURRENT_CARD_SUCCESS: "UPDATE_CURRENT_CARD.SUCCESS",
  UPDATE_CURRENT_CARD_ERROR: "UPDATE_CURRENT_CARD.ERROR",
  EDIT_CARD_STATE_CLEAR: "EDIT_CARD_STATE.CLEAR",

  GET_STRIPE_PUBLISHABLE_KEY: "GET_STRIPE_PUBLISHABLE_KEY",
  GET_STRIPE_PUBLISHABLE_KEY_SUCCESS: "GET_STRIPE_PUBLISHABLE_KEY.SUCCESS",
  GET_STRIPE_PUBLISHABLE_KEY_ERROR: "GET_STRIPE_PUBLISHABLE_KEY.ERROR",

  PURCHASE_PLAN: "PURCHASE_PLAN",
  PURCHASE_PLAN_SUCCESS: "PURCHASE_PLAN.SUCCESS",
  PURCHASE_PLAN_ERROR: "PURCHASE_PLAN.ERROR",
  PURCHASE_PLAN_CLEAR: "PURCHASE_PLAN.CLEAR",

  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA.SUCCESS",
  GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA.ERROR",
  GET_DASHBOARD_DATA_CLEAR: "GET_DASHBOARD_DATA.CLEAR",

  // Intercom types
  GET_INTERCOM_APP_ID: "GET_INTERCOM_APP_ID",
  GET_INTERCOM_APP_ID_SUCCESS: "GET_INTERCOM_APP_ID.SUCCESS",
  GET_INTERCOM_APP_ID_ERROR: "GET_INTERCOM_APP_ID.ERROR",
  GET_INTERCOM_APP_ID_CLEAR: "GET_INTERCOM_APP_ID.CLEAR",

  // Admin types
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS.SUCCESS",
  GET_ALL_USERS_ERROR: "GET_ALL_USERS.ERROR",
  GET_ALL_USERS_CLEAR: "GET_ALL_USERS.CLEAR",

  GET_PENDING_USERS: "GET_PENDING_USERS",
  GET_PENDING_USERS_SUCCESS: "GET_PENDING_USERS.SUCCESS",
  GET_PENDING_USERS_ERROR: "GET_PENDING_USERS.ERROR",
  GET_PENDING_USERS_CLEAR: "GET_PENDING_USERS.CLEAR",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER.SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER.ERROR",
  CREATE_USER_CLEAR: "CREATE_USER.CLEAR",

  APPROVE_USER: "APPROVE_USER",
  APPROVE_USER_SUCCESS: "APPROVE_USER.SUCCESS",
  APPROVE_USER_ERROR: "APPROVE_USER.ERROR",
  APPROVE_USER_CLEAR: "APPROVE_USER.CLEAR",

  CLEAR_INTIAL_SYNCING: "CLEAR_INTIAL_SYNCING",

  TOGGLE_BILLING_DECLINE_CARD: "TOGGLE_BILLING_DECLINE_CARD",

  TOGGLE_CLEAR_OVERDUE_INVOICE_MODAL: "TOGGLE_CLEAR_OVERDUE_INVOICE_MODAL",

  // Countries types
  GET_ALL_COUNTRIES: "GET_ALL_COUNTRIES",
  GET_ALL_COUNTRIES_SUCCESS: "GET_ALL_COUNTRIES.SUCCESS",
  GET_ALL_COUNTRIES_ERROR: "GET_ALL_COUNTRIES.ERROR",

  SET_INTEGRATION_PAIR_FOR_EDIT: "SET_INTEGRATION_PAIR_FOR_EDIT",
};

export { ACTION_TYPES };
