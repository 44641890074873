import React, { useState, useCallback, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputField from "../../components/InputField/InputField";
import ChimpLogo from "../../assets/img/favicon.png";
import MaterialButton from "../../components/ MaterialButton/MaterialButton";
import { userLoginSuccess } from "../../store/actions/userActions";
import { formDataWithApiKey, validateEmptyFields } from "../../helpers";
import api from "../../services/api";
import Loader from "../../components/Loader/Loader";
import "../../styles/components/dashboard/dashboard-main.scss";
import "../../styles/views/login.scss";

const AccountActivation = (props) => {
  const [inputs, setInputs] = useState({
    treasurerEmail: "",
    firstName: "",
    lastName: "",
  });
  const [errors, seterrors] = useState({});
  const [isSameEmail, setIsSameEmail] = useState(true);
  const [simpleError, setSimpleError] = useState("");
  const [loading, setLoading] = useState(false);

  const { userData, setUserData, history } = props;

  useEffect(() => {
    // Redirect to dashboard if account is already activated
    if (userData.isActivated) {
      return history.push("/dashboard");
    }
  }, [history, userData.isActivated]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const fieldToValidate = !isSameEmail
        ? inputs
        : (({ firstName, lastName }) => ({ firstName, lastName }))(inputs);
      const emptyFields = validateEmptyFields(fieldToValidate);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else {
        try {
          const formData = formDataWithApiKey();
          const checkEmail = isSameEmail ? 1 : 0;
          seterrors({});
          if (!isSameEmail) {
            formData.append("treasurerEmail", inputs.treasurerEmail);
          }
          formData.append("firstName", inputs.firstName);
          formData.append("lastName", inputs.lastName);
          formData.append("isSameEmail", checkEmail);
          formData.append("isSendActivationEmail", 1);
          formData.append("isActivated", 1);
          setLoading(true);
          const responseBack = await api.updateProfile(formData);
          setLoading(false);
          if (responseBack.status) {
            setUserData({
              ...userData,
              firstName: inputs.firstName,
              lastName: inputs.lastName,
              isActivated: 1,
            });
            history.push("/dashboard");
          } else {
            setSimpleError(responseBack.message);
          }
        } catch (error) {
          setLoading(false);
          setSimpleError(error.message);
        }
      }
    },
    [history, inputs, isSameEmail, setUserData, userData]
  );

  const handleCheckBoxChange = (event) => {
    setIsSameEmail(event.target.checked);
  };

  return (
    <div className="dashboard-main">
      <div className="cards-wrapper">
        <h3 className="user-note flexer">
          One more step. Love to know who you are!
        </h3>
        <div className="flexer w-100 mt-4">
          <Card variant="outlined" className="account-activation-card">
            <CardContent className="flexer-column">
              <div className="flexer-column">
                <img src={ChimpLogo} alt="chimp-logo" width="50%" />
                <h4>
                  <b>CCB CHIMP</b>
                </h4>
              </div>
              {simpleError && <h6 className="text-danger">{simpleError}</h6>}
              <form onSubmit={handleSubmit} name="form" className="w-75">
                <label className="input-label">First name</label>
                <InputField
                  name="firstName"
                  type="text"
                  placeholder="Enter your first name"
                  className="w-100 mt-0"
                  handleChange={handleChange}
                  value={inputs.firstName}
                  inputClass="input-box"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                />
                <label className="input-label">Last name</label>
                <InputField
                  name="lastName"
                  type="text"
                  placeholder="Enter your last name"
                  className="w-100 mt-0"
                  inputClass="input-box"
                  handleChange={handleChange}
                  value={inputs.lastName}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                />
                {!Boolean(isSameEmail) && (
                  <Fragment>
                    <label className="input-label">Tresaurer's Email</label>
                    <InputField
                      name="treasurerEmail"
                      type="email"
                      placeholder="Email"
                      className="w-100 mt-0"
                      inputClass="input-box"
                      handleChange={handleChange}
                      value={inputs.treasurerEmail}
                      error={Boolean(errors.treasurerEmail)}
                      helperText={errors.treasurerEmail}
                    />
                  </Fragment>
                )}
                <label className="input-label mb-0">Email:</label>
                <h6>{userData.email}</h6>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSameEmail}
                      onChange={handleCheckBoxChange}
                      color="primary"
                    />
                  }
                  label="Send invoice to the same email above"
                />
                <div className="flexer-end">
                  <MaterialButton title="Save" className="account-active-btn" />
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData) => dispatch(userLoginSuccess(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountActivation));
