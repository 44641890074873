import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Tooltip, Typography } from "@material-ui/core";
import useUserStore from "../../store/reduxStore/userStore";
import { AUTO_SYNCING_DESCRIPTION } from "../../utils/constants";
import useToolsStore from "../../store/reduxStore/toolsStore";
import { formDataWithApiKey } from "../../helpers";
import useGlobalStore from "../../store/reduxStore/globalStore";

const AutoSyncToggle = (props) => {
  const { hasLabel } = props;
  const { user } = useUserStore();
  const { tools, saveAutoSync, clearToolPageState } = useToolsStore();
  const { setSnackbarData } = useGlobalStore();
  const { isLoading, errorMessage, successMessage } = tools;
  const { userData } = user;

  useEffect(() => {
    if (successMessage) {
      clearToolPageState();
      setSnackbarData({
        snackbarSeverity: "success",
        showSnackbar: true,
        snackbarMessage: `Auto-Syncing has been ${
          userData?.isAutoSync === 0 ? "Disabled" : "Enabled"
        }`,
      });
    } else if (errorMessage) {
      clearToolPageState();
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: errorMessage,
      });
    }
  }, [
    clearToolPageState,
    errorMessage,
    setSnackbarData,
    successMessage,
    userData,
  ]);

  const handleChange = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("val", userData?.isAutoSync === 0 ? 1 : 0);
    saveAutoSync(formData);
  }, [saveAutoSync, userData]);

  return (
    <div className="d-flex align-items-center">
      <Tooltip arrow={true} title={AUTO_SYNCING_DESCRIPTION}>
        <Switch
          checked={userData?.isAutoSync === 1}
          onChange={handleChange}
          disabled={isLoading}
        />
      </Tooltip>
      {hasLabel && <Typography variant="subtitle2">Auto-Syncing</Typography>}
    </div>
  );
};

AutoSyncToggle.propTypes = {
  hasLabel: PropTypes.bool,
};

AutoSyncToggle.defaultProps = {
  hasLabel: false,
};

export default AutoSyncToggle;
