import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as PeopleIcon } from "../../assets/icons/people-icon.svg";

import { NavLink, withRouter } from "react-router-dom";
import { MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import {
  menus as UserMenus,
  adminMenus,
  mobileMenus,
} from "../MainTabSettingDroprRight/menus";
import { userLogout } from "../../store/actions/userActions";
import {
  getUserName,
  hasAdminData,
  returnToAdmin,
  truncateStringWithDots,
} from "../../helpers";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { toggleSyncNewPairsConfirmationModal } from "../../store/actions/integrationActions";
import UserAvatar from "../../HelperComponents/UserAvatar";
import "../../styles/components/more/pages-submenu.scss";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";

const PageSubMenu = (props) => {
  const {
    userData,
    userLogout,
    history,
    isMobileDevice,
    handleShowMoreMenu,
    hasNotSyncedPairs,
    toggleSyncNewPairsConfirmationModal,
  } = props;
  const menus = useMemo(() => {
    if (userData.role === 1) {
      return adminMenus;
    } else if (window.innerWidth < 770) {
      return mobileMenus;
    }
    return UserMenus;
  }, [userData]);
  const windowSize = useWindowSize();
  const location = useLocation();

  const isSidebarExpanded = useIsSidebarExpanded();

  const handleLogout = useCallback(async () => {
    localStorage.clear();
    userLogout();
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history, userLogout]);

  const handleMenuClicked = useCallback(
    (menu) => () => {
      if (windowSize.width <= 770) {
        handleShowMoreMenu();
        if (location.pathname === "/sync" && hasNotSyncedPairs) {
          toggleSyncNewPairsConfirmationModal(true, menu);
        } else {
          history.push(menu);
        }
      }
    },
    [
      handleShowMoreMenu,
      windowSize,
      toggleSyncNewPairsConfirmationModal,
      history,
      hasNotSyncedPairs,
      location,
    ]
  );

  const onClickExternalLink = useCallback(
    (link) => () => {
      window.open(link, "_blank");
    },
    []
  );

  const renderDesktopPageSubMenu = useCallback(
    (item, idx) => {
      if (["helpdesk", "facebook"].includes(item.route) && item?.link) {
        return (
          <MenuItem
            key={idx}
            className="menuitem"
            onClick={onClickExternalLink(item.link)}
          >
            <span className="icon">
              <item.icon />
            </span>
            <span className="text">{item.title}</span>
          </MenuItem>
        );
      }
      return (
        <MenuItem
          key={idx}
          className="menuitem"
          to={item.route}
          component={NavLink}
        >
          <span className="icon">
            <item.icon />
          </span>
          <span className="text">{item.title}</span>
        </MenuItem>
      );
    },
    [onClickExternalLink]
  );

  return (
    <div
      className={`sub-menu ${
        isSidebarExpanded ? "with-expanded-submenu" : ""
      } ${
        window.innerWidth < 770 && !isMobileDevice
          ? "hide-menu"
          : "mobile-sub-menu"
      }`}
    >
      <MenuItem className="menuitem-wrapper name-title">
        <UserAvatar userData={userData} />
        <div>
          <Tooltip title={getUserName(userData)} arrow>
            <h3 className="name">
              {truncateStringWithDots(getUserName(userData), 17)}
            </h3>
          </Tooltip>
        </div>
      </MenuItem>
      <div className="menuitem-wrapper number">
        <MenuItem className=" addNumberLink" component={NavLink} to="/sync">
          <Typography className="number-text">
            <>
              <span>Manage Syncing</span>
              <span className="icon">
                <Sync fontSize="small" />
              </span>
            </>
          </Typography>
        </MenuItem>
      </div>
      <div className="menuitem-wrapper">
        {menus.map((item, idx) =>
          windowSize.width <= 770 ? (
            <MenuItem
              key={idx}
              onClick={handleMenuClicked(item.route)}
              className="menuitem"
            >
              <span className="icon">
                <item.icon />
              </span>
              <span className="text">{item.title}</span>
            </MenuItem>
          ) : (
            renderDesktopPageSubMenu(item, idx)
          )
        )}
      </div>
      {hasAdminData() && (
        <div className="menuitem-wrapper m-0 p-0">
          <MenuItem onClick={returnToAdmin} className="menuitem">
            <span className="icon">
              <PeopleIcon />
            </span>
            <span className="text">Return to Admin</span>
          </MenuItem>
        </div>
      )}
      <MenuItem className="menuitem-wrapper">
        <div className="menuitem logout" onClick={handleLogout}>
          <span className="icon">
            <LogoutIcon />
          </span>
          <span className="text">Log out</span>
        </div>
      </MenuItem>
    </div>
  );
};

PageSubMenu.defaultProps = {
  isMobileDevice: false,
};

PageSubMenu.propTypes = {
  isMobileDevice: PropTypes.bool,
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
    toggleSyncNewPairsConfirmationModal: (val, skipRoute) =>
      dispatch(toggleSyncNewPairsConfirmationModal(val, skipRoute)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageSubMenu)
);
