import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as userMiddleware from "../middlewares/userMiddleware";

export function useUserStore() {
  const dispatch = useDispatch();
  const _user = useSelector((store) => store.userReducer);

  const getIntercomAppId = useCallback(
    async (data) => {
      await dispatch(userMiddleware.getIntercomAppId(data));
    },
    [dispatch]
  );

  return {
    user: _user,
    getIntercomAppId,
  };
}

export default useUserStore;
