import { useMemo } from "react";
import lodash from "lodash";
import { useLocation } from "react-router-dom";
import {
  getIntegrationActiveTabType,
  handleDisabledIntegration,
} from "../../helpers";
import {
  CCB_TO_MC,
  INITIAL_SYNCING_IN_PROGRESS,
  MC_TO_CCB,
  SYNCING_IN_PROGRESS,
} from "../integrationsConstants";
import useIntegrationStore from "../../store/reduxStore/integrationsStore";
import useUserStore from "../../store/reduxStore/userStore";
import useBillingStore from "../../store/reduxStore/billingStore";

export const UseSyncOptions = (notSyncedPairs) => {
  const { integrations } = useIntegrationStore();
  const { user } = useUserStore();
  const { billing } = useBillingStore();
  const { hasCurrentPlan } = billing;

  const {
    syncManualStatus,
    ccbCampusesStatus,
    ccbSavedSearchesStatus,
    ccbGroupStatus,
    mailchimpTagsStatus,
    mailchimpInterestedGroupsStatus,
    syncingInProgress,
    currentSync,
    isCcbToMcIntegrationEnabled,
  } = integrations;

  const { userData, autoSyncingStatus, userApiDetails, warnings } = user;
  const location = useLocation();
  const isOnDashboard = location?.pathname === "/dashboard";

  const isCcbToMcSyncEnabled = useMemo(
    () => isCcbToMcIntegrationEnabled && currentSync === CCB_TO_MC,
    [currentSync, isCcbToMcIntegrationEnabled]
  );

  const isMcToCcbSyncEnabled = useMemo(
    () => Number(userData?.isSkipMcToCcb) === 0 && currentSync === MC_TO_CCB,
    [currentSync, userData]
  );

  const isTwoWaySyncingEnabled = useMemo(
    () => Number(userData?.isSkipMcToCcb) === 0,
    [userData]
  );

  const isCcbToMcPairsExist = useMemo(
    () =>
      (warnings?.syncData &&
        Number(warnings?.syncData?.ccbToMcCcbGroup) !== 0) ||
      (warnings?.syncData &&
        Number(warnings?.syncData?.ccbToMcSavedSearch) !== 0),
    [warnings]
  );

  const isMcToCcbPairsExist = useMemo(
    () => warnings?.syncData && Number(warnings?.syncData?.mcToCcb) !== 0,
    [warnings]
  );

  const isSyncingInProgress = useMemo(
    () => syncManualStatus === "success" && syncingInProgress,
    [syncManualStatus, syncingInProgress]
  );

  const isAutoSyncingInProgress = useMemo(
    () => autoSyncingStatus,
    [autoSyncingStatus]
  );

  const isIntegrationsDisabled = useMemo(
    () =>
      handleDisabledIntegration(
        currentSync,
        isCcbToMcIntegrationEnabled,
        Number(userData?.isSkipMcToCcb) === 0
      ),
    [currentSync, isCcbToMcIntegrationEnabled, userData]
  );

  const loadingForMcToCcb = useMemo(
    () =>
      [
        mailchimpInterestedGroupsStatus,
        ccbGroupStatus,
        mailchimpTagsStatus,
      ].includes("loading"),
    [ccbGroupStatus, mailchimpInterestedGroupsStatus, mailchimpTagsStatus]
  );

  const loadingForCcbToMc = useMemo(
    () =>
      [ccbGroupStatus, ccbSavedSearchesStatus, ccbCampusesStatus].includes(
        "loading"
      ),
    [ccbCampusesStatus, ccbGroupStatus, ccbSavedSearchesStatus]
  );

  const isUserSetCcbAndMcApiCredentials = useMemo(() => {
    if (userApiDetails?.ccbUsername || userApiDetails?.mailchimpApiKey) {
      return true;
    }
    return false;
  }, [userApiDetails]);

  const syncDisabledReason = useMemo(() => {
    if (isTwoWaySyncingEnabled) {
      if (!isCcbToMcPairsExist && !isMcToCcbPairsExist) {
        return "Please add at least one ccb-to-mc and mc-to-ccb pair for 2-way syncing";
      } else if (!isCcbToMcPairsExist && isMcToCcbPairsExist) {
        return "Please add at least one ccb-to-mc pair for 2-way syncing";
      } else if (!isMcToCcbPairsExist && isCcbToMcPairsExist) {
        return "Please add at least one mc-to-ccb pair for 2-way syncing";
      }
    } else if (!isMcToCcbPairsExist && !isCcbToMcPairsExist) {
      return `Please add at least one ccb-to-mc or mc-to-ccb sync pair`;
    }
    return "";
  }, [isTwoWaySyncingEnabled, isCcbToMcPairsExist, isMcToCcbPairsExist]);

  const disableSyncing = useMemo(
    () =>
      isIntegrationsDisabled ||
      isSyncingInProgress ||
      isAutoSyncingInProgress ||
      Number(userData.isDisableSync) === 1 ||
      !isUserSetCcbAndMcApiCredentials,
    [
      isAutoSyncingInProgress,
      isIntegrationsDisabled,
      isSyncingInProgress,
      userData,
      isUserSetCcbAndMcApiCredentials,
    ]
  );

  const SyncButtonTooltip = useMemo(() => {
    if (isIntegrationsDisabled && !isOnDashboard) {
      return `Turn on Toggle for ${getIntegrationActiveTabType(
        currentSync
      )} to Sync ${lodash.isEmpty(notSyncedPairs) ? "New" : ""} Pairs`;
    } else if (isAutoSyncingInProgress) {
      return `Please wait, ${INITIAL_SYNCING_IN_PROGRESS}`;
    } else if (isSyncingInProgress) {
      return SYNCING_IN_PROGRESS;
    } else if (Number(userData.isDisableSync) === 1) {
      return "Automatic / manual syncing is disabled";
    } else if (!isUserSetCcbAndMcApiCredentials) {
      return `Please setup CCB and MC Credentials first.`;
    } else if (userData.isDisableSync) {
      return "Automatic / manual syncing is disabled";
    } else if (!hasCurrentPlan) {
      return "You need to purchase a plan for this feature";
    } else if (syncDisabledReason) {
      return syncDisabledReason;
    }
    return "";
  }, [
    currentSync,
    isAutoSyncingInProgress,
    isIntegrationsDisabled,
    notSyncedPairs,
    syncDisabledReason,
    isSyncingInProgress,
    userData,
    isOnDashboard,
    isUserSetCcbAndMcApiCredentials,
    hasCurrentPlan,
  ]);

  const currentIntegrationModeText = useMemo(() => {
    if (isSyncingInProgress && isTwoWaySyncingEnabled) {
      return "2 Way Sync in Progress";
    } else if (isSyncingInProgress) {
      return SYNCING_IN_PROGRESS;
    } else if (isTwoWaySyncingEnabled) {
      return "Two Way Integration Selected";
    } else if (isCcbToMcSyncEnabled && !isMcToCcbSyncEnabled) {
      return "CCB to Mailchimp Sync Selected";
    } else if (isMcToCcbSyncEnabled && !isCcbToMcSyncEnabled) {
      return "Mailchimp to CCB Sync Selected";
    } else {
      return "No Integrations Selected";
    }
  }, [
    isCcbToMcSyncEnabled,
    isMcToCcbSyncEnabled,
    isSyncingInProgress,
    isTwoWaySyncingEnabled,
  ]);

  return {
    isSyncingInProgress,
    isAutoSyncingInProgress,
    isIntegrationsDisabled,
    loadingForMcToCcb,
    loadingForCcbToMc,
    disableSyncing,
    syncDisabledReason,
    SyncButtonTooltip,
    currentIntegrationModeText,
    isTwoWaySyncingEnabled,
  };
};
