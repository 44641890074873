import { ACTION_TYPES } from "./types";

export const globalLoading = (payload) => ({
  type: ACTION_TYPES.LOADING,
  payload,
});

export const setSnackbarData = (payload) => ({
  type: ACTION_TYPES.SET_SNACKBAR_DATA,
  payload,
});

export const setDataForModal = (payload) => ({
  type: ACTION_TYPES.SET_DATA_FOR_MODAL,
  payload,
});

export const toggleSidebarExpanded = (payload) => ({
  type: ACTION_TYPES.TOGGE_IS_SIDEBAR_EXPANDED,
  payload,
});
