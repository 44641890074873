import React, { useCallback, useEffect, useState } from "react";
import { Dialog, Typography, Slide, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import BillingBreadCrumb from "../BillingBreadCrumb";
import Loader from "../../Loader/Loader";
import { formDataWithApiKey } from "../../../helpers";
import api from "../../../services/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cells = [
  {
    text: "Item",
  },
  {
    text: "Amount",
  },
];

const breadcrumbs = [
  {
    text: `Invoices`,
    route: `/billing/invoices`,
  },
  {
    text: `Invoice Details`,
  },
];

const InvoiceDetails = (props) => {
  const {
    // component props
    history,
    match,
  } = props;
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInvoiceDetails = useCallback(async (formData) => {
    setLoading(true);
    const response = await api.getInvoiceDetailsApi(formData);
    setLoading(false);
    if (response.status === 1) {
      setInvoiceDetails(response.data);
    }
  }, []);

  const toggleModal = useCallback(() => {
    history.push("/billing/invoices");
  }, [history]);

  useEffect(() => {
    const { params } = match;
    const formData = formDataWithApiKey();
    if (params?.invoiceId) {
      formData.append("id", params?.invoiceId);
      getInvoiceDetails(formData);
    } else {
      toggleModal();
    }
  }, [getInvoiceDetails, match, toggleModal]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Invoice Details</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <BillingBreadCrumb breadcrumbs={breadcrumbs} />
          </div>
          <div className="flexer-column mt-4">
            <div className="user-settings-greetings">
              {loading ? (
                <div className="d-flex justify-content-center spinner-container">
                  <Loader />
                </div>
              ) : (
                <TableInfinityScroll
                  className="logs-modal-table"
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                    />
                    <tbody className="user-settings-greetings-table-body">
                      {Boolean(invoiceDetails?.length) &&
                        invoiceDetails.map((item, idx) => (
                          <tr key={idx}>
                            <td></td>
                            <td
                              className={`${
                                invoiceDetails.length - 1 === idx
                                  ? "font-weight-bold"
                                  : ""
                              }`}
                            >
                              {item.item}
                            </td>
                            <td>{item.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableInfinityScroll>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvoiceDetails;
