import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ccbGroupsAndProcessQueue } from "./addNewSyncPairModalData";
import { Tooltip } from "@material-ui/core";

const CcbGroupsAndProcessQueue = (props) => {
  const {
    isProcessQueueSelected,
    index,
    className,
    children,
    onClick,
    isProcessQueueDisabled,
    tooltipTitle,
  } = props;

  const handleTabClicked = useCallback(
    (currentValue) => {
      if (isProcessQueueDisabled) return null;
      onClick(index, currentValue);
    },
    [index, isProcessQueueDisabled, onClick]
  );

  return (
    <div className={`mc-tags-group-box-container ${className}`}>
      <div className="mc-tabs">
        {ccbGroupsAndProcessQueue.map((item, idx) => (
          <Tooltip arrow={true} placement={"top"} title={tooltipTitle}>
            <div
              key={idx}
              onClick={() => handleTabClicked(item.value)}
              className={`tab ${
                isProcessQueueSelected === item.value ? "active" : ""
              } ${isProcessQueueDisabled && item.value === 1 ? "disable" : ""}`}
            >
              {item.title}
            </div>
          </Tooltip>
        ))}
      </div>
      {children}
    </div>
  );
};

CcbGroupsAndProcessQueue.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  isProcessQueueDisabled: PropTypes.bool,
  tooltipTitle: PropTypes.string,
};

CcbGroupsAndProcessQueue.defaultProps = {
  isProcessQueueDisabled: false,
  tooltipTitle: "",
};

export default CcbGroupsAndProcessQueue;
