import React from "react";
import PropTypes from "prop-types";
import SelectMcTagsTableRow from "./SelectMcTagsTableRow";

const SelectMcTagsTableBody = (props) => {
  const { data, isLoading, handleCheck } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <SelectMcTagsTableRow
          data={elementData}
          handleCheck={handleCheck}
          index={index}
        />
      ))}
    </tbody>
  );
};

SelectMcTagsTableBody.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

SelectMcTagsTableBody.defaultProps = {
  data: [],
};

export default SelectMcTagsTableBody;
