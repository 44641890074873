import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import CurrentSubsciptionDetailCard from "../../components/BillingPageComponents/PaymentComponents/CurrentSubsciptionDetailCard";
import EmptyMessageBox from "../../components/EmptyMessageBox/EmptyMessageBox";
import {
  cancelPlan,
  getAllAvailablePlans,
  getAllInvoices,
  getCurrentCardDetails,
  getCurrentPlan,
  getStripePublishableKey,
} from "../../store/middlewares/billingMiddleware";
import { formDataWithApiKey } from "../../helpers";
import CancelPlan from "../../components/modals/CancelPlan/CancelPlan";
import {
  clearCancelPlanStatus,
  toggleCardDeclineModal,
} from "../../store/actions/billingActions";
import NotficationBanner from "../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../utils/notifications";
import {
  disableCcbSyncing,
  getWarnings,
} from "../../store/middlewares/userMiddleware";
import { setSnackbarData } from "../../store/actions/globalAction";
import { clearEnableDisableSyncing } from "../../store/actions/userActions";
import "../../styles/components/more/billing.scss";
import { UNPAID_ACC_DETAILS } from "../../utils/constants";
import CardDeclineModal from "../../components/modals/CardDeclineModal/CardDeclineModal";
import ClearOverdueInvoiceModal from "../../components/modals/ClearOverdueInvoiceModal/ClearOverdueInvoiceModal";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";

const lastInvoiceCells = [
  {
    text: "Date",
  },
  {
    text: "Transaction #",
  },
  {
    text: "Amount",
  },
];

const errorMessageForCard = `Please add payment method`;
const Billing = (props) => {
  const {
    // Component props
    history,

    // Redux Functions
    getAllInvoices,
    getCurrentPlan,
    clearCancelPlanStatus,
    cancelPlan,
    getCurrentCardDetails,
    disableCcbSyncing,
    setSnackbarData,
    getStripePublishableKey,
    clearEnableDisableSyncing,
    getWarnings,
    toggleCardDeclineModal,
    getAllAvailablePlans,

    // Redux props
    invoices,
    hasCurrentPlan,
    currentPlan,
    invoicesStatus,
    currentPlanStatus,
    cancelPlanStatus,
    getCardStatus,
    currentCard,
    hasCard,
    warnings,
    userData,
    enableDisableSyncingStatus,
    stripePublishableKey,
    showCardDeclineModal,
    showClearOverdueInvoiceModal,
    subscriptionPlansStatus,
  } = props;
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [customCalcellationReason, setCustomCancellationReason] = useState("");
  const [stopSync, setStopSync] = useState(false);

  const isSidebarExpanded = useIsSidebarExpanded();

  const closeCancelSubscriptionModal = useCallback(() => {
    setShowCancelPlanModal(false);
  }, []);

  const setErrorSnackbar = useCallback(() => {
    setSnackbarData({
      snackbarSeverity: "error",
      showSnackbar: true,
      snackbarMessage: "Something went wrong, please try again later!",
    });
  }, [setSnackbarData]);

  const loadStripe = useCallback(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(stripePublishableKey);
      };
      window.document.body.appendChild(s);
    }
  }, [stripePublishableKey]);

  useEffect(() => {
    if (stripePublishableKey) {
      loadStripe();
    }
  }, [loadStripe, stripePublishableKey]);

  useEffect(() => {
    if (!subscriptionPlansStatus) {
      const formData = formDataWithApiKey();
      getAllAvailablePlans(formData);
    }
  }, [getAllAvailablePlans, subscriptionPlansStatus]);

  useEffect(() => {
    // GET STRIPE PUBLISHABLE KEY
    const formData = formDataWithApiKey();
    getStripePublishableKey(formData);
  }, [getStripePublishableKey]);

  useEffect(() => {
    if (enableDisableSyncingStatus === "success") {
      clearEnableDisableSyncing();
      setSnackbarData({
        snackbarSeverity: "success",
        showSnackbar: true,
        snackbarMessage: `CCB Syncing ${
          userData.isDisableSync === 1 ? "Disabled" : "Enabled"
        } Successfully!`,
      });
    } else if (enableDisableSyncingStatus === "error") {
      setErrorSnackbar();
    }
  }, [
    clearEnableDisableSyncing,
    enableDisableSyncingStatus,
    setErrorSnackbar,
    setSnackbarData,
    userData,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (cancelPlanStatus === "success") {
      closeCancelSubscriptionModal();
      clearCancelPlanStatus();
      getCurrentPlan(formData);
      getWarnings(formData);
      if (stopSync) {
        disableCcbSyncing(formData);
      }
    }
  }, [
    cancelPlanStatus,
    clearCancelPlanStatus,
    closeCancelSubscriptionModal,
    getCurrentPlan,
    disableCcbSyncing,
    stopSync,
    getWarnings,
  ]);

  useEffect(() => {
    // GET CURRENT CARD DETAILS AND INVOICES
    const formData = formDataWithApiKey();
    getCurrentCardDetails(formData);
    getCurrentPlan(formData);
    formData.append("page", 1);
    getAllInvoices(formData);
  }, [getAllInvoices, getCurrentCardDetails, getCurrentPlan]);

  const onClickedPayNow = useCallback(() => {
    history.push("/billing");
    toggleCardDeclineModal(true);
  }, [history, toggleCardDeclineModal]);

  const handleCancellationReasonChange = useCallback((value) => {
    setCancellationReason(value);
  }, []);

  const onCustomCancellationReasonChange = useCallback((event) => {
    setCustomCancellationReason(event.target.value);
  }, []);

  const handleCancelPlan = useCallback(() => {
    const formData = formDataWithApiKey();
    let actualCancellationReason = "";
    if (cancellationReason === "Other reasons" && customCalcellationReason) {
      actualCancellationReason = customCalcellationReason;
    } else {
      actualCancellationReason = cancellationReason;
    }
    formData.append("cancelReason", actualCancellationReason);
    formData.append("stopSync", stopSync ? 1 : 0);
    cancelPlan(formData);
  }, [cancelPlan, cancellationReason, stopSync, customCalcellationReason]);

  const handleStopSyncChange = useCallback(() => {
    setStopSync(!stopSync);
  }, [stopSync]);

  const getCardDetailsTableHeaderCells = useCallback(() => {
    const cardTableCells = [
      {
        text: "Card last 4 digits",
      },
      {
        text: "Expiry month",
      },
      {
        text: "Expiry Year",
      },
      {
        text: "",
      },
    ];
    const newItem = {
      text: "Credits",
    };
    if (userData?.credits > 0) {
      cardTableCells.splice(3, 0, newItem);
    }
    return cardTableCells;
  }, [userData]);

  const renderErrorMessage = useCallback((message, data = []) => {
    if (!data.length) {
      return <EmptyMessageBox message={message} />;
    }
  }, []);

  const renderTableRow = useCallback(
    (tableData, identity) => {
      if (identity === "isInvoices") {
        return (
          <TableRow>
            <TableCell>{tableData.date}</TableCell>
            <TableCell className="link-text">
              {tableData.stripeLink ? (
                <a
                  className="link-text"
                  href={tableData.stripeLink || "#"}
                  target="__blank"
                >
                  {tableData.transactionNum}
                </a>
              ) : (
                <span
                  onClick={() =>
                    history.push(`/billing/invoices/details/${tableData.id}`)
                  }
                  className="link-text"
                >
                  {tableData.transactionNum}
                </span>
              )}
            </TableCell>
            <TableCell>{tableData.amount}</TableCell>
          </TableRow>
        );
      } else if (identity === "cardTable") {
        return (
          <TableRow>
            <TableCell>**** **** **** {tableData.last4}</TableCell>
            <TableCell>{tableData.expMonth}</TableCell>
            <TableCell>{tableData.expYear}</TableCell>
            {userData?.credits > 0 && (
              <TableCell>${userData?.credits}</TableCell>
            )}
            <TableCell
              className="link-text"
              onClick={() =>
                history.push("/setup-payment", { from: "billing" })
              }
            >
              Change Card
            </TableCell>
          </TableRow>
        );
      }
    },
    [history, userData]
  );

  const renderTable = useCallback(
    (tableCells = [], tableData = [], identity) => {
      return (
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="simple table" className="cards-table">
            <TableHead className="table-head">
              <TableRow>
                {tableCells.map((cell, idx) => (
                  <TableCell key={idx}>{cell.text}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {Boolean(tableData.length) && (
              <TableBody className="table-body">
                {tableData
                  .slice(0, identity === "isInvoices" ? 5 : 3)
                  .map((item) => renderTableRow(item, identity))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      );
    },
    [renderTableRow]
  );

  return (
    <>
      <div
        className={clsx("page-wrapper billing-page", {
          "with-page-sub-menu-expanded": isSidebarExpanded,
        })}
      >
        <div className="desktop-design">
          <Typography className="desktop-title" component={"h2"}>
            Billing
          </Typography>
          <Grid container spacing={2} className="cards">
            {Boolean(warnings?.isCancelled) && (
              <NotficationBanner
                {...notificationProps["subscriptinCancelled"]}
                message={`${warnings?.cancelMessage} Please purchase a plan `}
              />
            )}
            <Grid item md={3} xs={12} sm={12}>
              <CurrentSubsciptionDetailCard
                info={hasCurrentPlan ? currentPlan : UNPAID_ACC_DETAILS}
                hasSubscription={hasCurrentPlan}
                onClick={() => history.push("/subscription-plans")}
                loading={currentPlanStatus === "loading"}
                openCancelPlanModal={() => setShowCancelPlanModal(true)}
                isSubcriptionCancelled={Boolean(warnings?.isCancelled)}
              />
            </Grid>
            <Grid item md={9} xs={12} sm={12} className="mt-2">
              <div className="payment-method">
                {[1, 2].includes(warnings?.hasBillingIssue) && (
                  <NotficationBanner
                    onClick={onClickedPayNow}
                    linkText={"Pay Now "}
                    {...notificationProps["paymentFailed"]}
                  />
                )}
                <div className="section-title">
                  <Typography variant="h6">
                    <span>Payment method</span>
                    {!hasCard && (
                      <Fab
                        color="primary"
                        aria-label="Add"
                        className="fab payment bg-yellow"
                        onClick={() =>
                          history.push("/setup-payment", { from: "billing" })
                        }
                      >
                        <Add />
                      </Fab>
                    )}
                  </Typography>
                </div>
                {getCardStatus === "loading" ? (
                  <div className="flexer mt-3">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {renderTable(
                      getCardDetailsTableHeaderCells(),
                      currentCard,
                      "cardTable"
                    )}
                    {renderErrorMessage(errorMessageForCard, currentCard)}
                  </>
                )}
              </div>

              <div className="payment-method">
                <div className="section-title">
                  <Typography variant="h6">
                    <span>Last Invoices </span>
                  </Typography>
                  {invoices.length > 5 && (
                    <Typography
                      onClick={() => history.push("/billing/invoices")}
                      className="link-text"
                      variant="subtitle1"
                    >
                      View All
                    </Typography>
                  )}
                </div>
                {invoicesStatus === "loading" ? (
                  <div className="flexer mt-3">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {renderTable(lastInvoiceCells, invoices, "isInvoices")}
                    {renderErrorMessage(`No Invoices`, invoices)}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {showCancelPlanModal && (
        <CancelPlan
          showModal={showCancelPlanModal}
          toggleModal={closeCancelSubscriptionModal}
          onClick={handleCancelPlan}
          handleCancellationReasonChange={handleCancellationReasonChange}
          onCustomCancellationReasonChange={onCustomCancellationReasonChange}
          customCalcellationReason={customCalcellationReason}
          cancellationReason={cancellationReason}
          stopSync={stopSync}
          handleStopSyncChange={handleStopSyncChange}
          loading={cancelPlanStatus === "loading"}
        />
      )}

      {showCardDeclineModal && (
        <CardDeclineModal showModal={showCardDeclineModal} />
      )}

      {showClearOverdueInvoiceModal && (
        <ClearOverdueInvoiceModal showModal={showClearOverdueInvoiceModal} />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    warnings: store.userReducer.warnings,
    invoices: store.billing.invoices,
    hasCurrentPlan: store.billing.hasCurrentPlan,
    currentPlan: store.billing.currentPlan,
    invoicesStatus: store.billing.invoicesStatus,
    currentPlanStatus: store.billing.currentPlanStatus,
    cancelPlanStatus: store.billing.cancelPlanStatus,
    getCardStatus: store.billing.getCardStatus,
    currentCard: store.billing.currentCard,
    hasCard: store.billing.hasCard,
    userData: store.userReducer.userData,
    enableDisableSyncingStatus: store.userReducer.enableDisableSyncingStatus,
    stripePublishableKey: store.billing.stripePublishableKey,
    showCardDeclineModal: store.billing.showCardDeclineModal,
    showClearOverdueInvoiceModal: store.billing.showClearOverdueInvoiceModal,
    subscriptionPlansStatus: store.billing.subscriptionPlansStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInvoices: (formData) => dispatch(getAllInvoices(formData)),
    getCurrentPlan: (formData) => dispatch(getCurrentPlan(formData)),
    cancelPlan: (formData) => dispatch(cancelPlan(formData)),
    getStripePublishableKey: (formData) =>
      dispatch(getStripePublishableKey(formData)),
    getCurrentCardDetails: (formData) =>
      dispatch(getCurrentCardDetails(formData)),
    clearCancelPlanStatus: () => dispatch(clearCancelPlanStatus()),
    disableCcbSyncing: (data) => dispatch(disableCcbSyncing(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    clearEnableDisableSyncing: () => dispatch(clearEnableDisableSyncing()),
    toggleCardDeclineModal: (val) => dispatch(toggleCardDeclineModal(val)),
    getAllAvailablePlans: (formData) =>
      dispatch(getAllAvailablePlans(formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
