import React, { useMemo } from "react";
import { ReactComponent as VisaIcon } from "../../../assets/icons/visa-icon.svg";
import { ReactComponent as MCcard } from "../../../assets/icons/mc-icon.svg";

function CreditCard(props) {
  const { isCardFlip, creditCardInfo, cardNumber } = props;

  const renderCardIcon = useMemo(() => {
    if (cardNumber) {
      if (cardNumber[0] === "4") {
        return <VisaIcon />;
      } else if (cardNumber[0] === "5") {
        return <MCcard />;
      }
    }
  }, [cardNumber]);

  return (
    <div className="credit-card-box">
      <div className={isCardFlip ? "flip-card" : "flip"}>
        <div className="front">
          <div className="chip" />
          <div className="logo">{renderCardIcon}</div>
          <div className="number">{creditCardInfo.cardNumber}</div>
          <div className="card-holder">
            <label>Card holder</label>
            <div>{creditCardInfo.cardHolder}</div>
          </div>
          <div className="card-expiration-date">
            <label>Expires</label>
            {(creditCardInfo.expiryMonth || creditCardInfo.expiryYear) && (
              <div>
                {creditCardInfo.expiryMonth} / {creditCardInfo.expiryYear}
              </div>
            )}
          </div>
        </div>
        <div className="back">
          <div className="strip" />
          <div className="logo">{renderCardIcon}</div>
          <div className="ccv">
            <label>CVC</label>
            <div>{creditCardInfo.ccv}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditCard;
