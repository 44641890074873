import * as React from "react";
import PropTypes from "prop-types";
import { FormGroup, Switch, styled } from "@material-ui/core";

const AntSwitch = styled(Switch)(({ theme, color }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: color,
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
      "& .MuiSwitch-thumb": {
        color: color,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    color: "#BEBBBB",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
}));

const CustomSwitch = (props) => {
  const { color, className, checked, onChange, size, disabled } = props;
  return (
    <FormGroup>
      <AntSwitch
        className={className}
        color={color}
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "ant design" }}
        size={size}
        disabled={disabled}
      />
    </FormGroup>
  );
};

CustomSwitch.defaultProps = {
  color: "#37C97A",
  className: "",
  size: "",
  disabled: false,
};

CustomSwitch.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomSwitch;
