import { ACTION_TYPES } from "../actions/types";
const initialState = {
  isLoading: null,
  snackbarSeverity: "",
  showSnackbar: null,
  autoHideDuration: 6000,
  snackbarMessage: "",
  action: null,
  modalData: {},

  // Sidebar expanded
  isSidebarExpanded: true,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload,
      });
    case ACTION_TYPES.SET_SNACKBAR_DATA:
      return Object.assign({}, state, {
        ...action.payload,
      });

    case ACTION_TYPES.SET_DATA_FOR_MODAL:
      return Object.assign({}, state, {
        modalData: action.payload,
      });

    case ACTION_TYPES.TOGGE_IS_SIDEBAR_EXPANDED:
      return Object.assign({}, state, {
        isSidebarExpanded: action.payload,
      });

    default:
      return state;
  }
};

export default userReducer;
