import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as integrationMiddleware from "../middlewares/integrationMiddleware";
import * as integrationActions from "../actions/integrationActions";

export function useIntegrationStore() {
  const dispatch = useDispatch();
  const _integrations = useSelector((store) => store.integrations);

  const removeCcbToMcSyncPair = useCallback(
    async (data, isRemoveFromBrokenPairModal, id) => {
      await dispatch(
        integrationMiddleware.removeCcbToMcSyncPair(
          data,
          isRemoveFromBrokenPairModal,
          id
        )
      );
    },
    [dispatch]
  );

  const removeMcToCcbSyncPair = useCallback(
    async (data, isRemoveFromBrokenPairModal) => {
      await dispatch(
        integrationMiddleware.removeMcToCcbSyncPair(
          data,
          isRemoveFromBrokenPairModal
        )
      );
    },
    [dispatch]
  );

  const setShowSearchFor = useCallback(
    async (payload) => {
      await dispatch(integrationActions.setShowSearchFor(payload));
    },
    [dispatch]
  );

  const setMailchimpInterestedGroups = useCallback(
    async (data) => {
      await dispatch(integrationMiddleware.setMailchimpInterestedGroups(data));
    },
    [dispatch]
  );

  const setCcbGroups = useCallback(
    async (data) => {
      await dispatch(integrationMiddleware.setCcbGroups(data));
    },
    [dispatch]
  );

  const setMailchimpTags = useCallback(
    async (data) => {
      await dispatch(integrationMiddleware.setMailchimpTags(data));
    },
    [dispatch]
  );

  const setCcbProcessQueue = useCallback(
    async (data) => {
      await dispatch(integrationMiddleware.setCcbProcessQueue(data));
    },
    [dispatch]
  );

  return {
    integrations: _integrations,
    removeCcbToMcSyncPair,
    removeMcToCcbSyncPair,
    setShowSearchFor,
    setMailchimpInterestedGroups,
    setCcbGroups,
    setMailchimpTags,
    setCcbProcessQueue,
  };
}

export default useIntegrationStore;
