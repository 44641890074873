import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import InputField from "../../../components/InputField/InputField";
import CCBlogo from "../../../assets/img/church-community.png";
import { Dialog, DialogContent, Tooltip } from "@material-ui/core";
import { InputAdornment, Switch, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner/ButtonWIthSpinner";
import {
  formDataWithApiKey,
  validateEmptyFields,
  checkSubscribePlanAmount,
} from "../../../helpers";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import {
  authorizeCcbAccount,
  removeCcbAccountCredentials,
  disableCcbSyncing,
  enableCcbSyncing,
  enableUserStoreInfo,
  disableUserStoreInfo,
} from "../../../store/middlewares/userMiddleware";
import {
  clearApiDetailsState,
  clearCcbAccountSetup,
  clearEnableDisableSyncing,
  clearEnableDisableUserStoreInfo,
} from "../../../store/actions/userActions";
import { useComponentWillUnmount } from "../../../utils/hooks/useComponentWillUnmount";
import { getCurrentPlan } from "../../../store/middlewares/billingMiddleware";
import { syncManual } from "../../../store/middlewares/integrationMiddleware";
import { setSnackbarData } from "../../../store/actions/globalAction";

const userStoreInfoWarning = `This option is only available for our $20 / m plan and up. Turning it on will mean you are in agreement.`;

const SetupCcbAccount = (props) => {
  const [inputs, setInputs] = useState({
    ccbUsername: "",
    ccbPassword: "",
    ccbAccount: "",
  });
  const [errors, seterrors] = useState({});
  const [showCcbPassword, setShowCcbPassword] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isOnEditCredentials, setIsOnEditCredentials] = useState(false);
  const [
    showEnableDisableSyncConfirmModal,
    setShowEnableDisableSyncConfirmModal,
  ] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState("");
  const [showConfirmationModalCancelBtn, setShowConfirmationModalCancelBtn] =
    useState(true);
  const {
    // COMPONENT PROPS
    history,

    // REDUX FUNCTIONS
    authorizeCcbAccount,
    clearApiDetailsState,
    removeCcbAccountCredentails,
    enableCcbSyncing,
    disableCcbSyncing,
    enableUserStoreInfo,
    disableUserStoreInfo,
    getCurrentPlan,
    clearEnableDisableUserStoreInfo,
    clearCcbAccountSetup,
    clearEnableDisableSyncing,
    syncManual,
    setSnackbarData,

    // REDUX PROPS
    errorMessage,
    authorizeCcbAccountStatus,
    successMessage,
    userApiDetails,
    removeCcbCredentialsStatus,
    userData,
    enableDisableSyncingStatus,
    userStoreInfoSuccessMessage,
    userStoreInfoErrorMessage,
    userStoreInfoStatus,
    currentPlan,
  } = props;
  const isSubscribeHigherPlan = useMemo(
    () =>
      checkSubscribePlanAmount(
        currentPlan?.amountDisplay,
        20,
        currentPlan?.name
      ),
    [currentPlan]
  );

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const closeEnableDisableSyncModal = useCallback(() => {
    setShowEnableDisableSyncConfirmModal(false);
  }, []);

  const resetConfirmationModalState = useCallback(() => {
    setShowConfirmationModal(false);
    setConfirmationModalContent("");
    setShowConfirmationModalCancelBtn(false);
    setShowEnableDisableSyncConfirmModal(false);
  }, []);

  const setErrorSnackbar = useCallback(() => {
    setSnackbarData({
      snackbarSeverity: "error",
      showSnackbar: true,
      snackbarMessage: "Something went wrong, please try again later!",
    });
  }, [setSnackbarData]);

  useEffect(() => {
    if (authorizeCcbAccountStatus === "success") {
      const formData = formDataWithApiKey();
      if (!errorMessage) {
        syncManual(formData);
      }
      setSnackbarData({
        snackbarSeverity: successMessage ? "success" : "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(successMessage || errorMessage),
        autoHideDuration: 20000,
      });
      clearCcbAccountSetup();
      history.push("/integrations");
    } else if (authorizeCcbAccountStatus === "error") {
      clearCcbAccountSetup();
      setErrorSnackbar();
    }
  }, [
    authorizeCcbAccountStatus,
    clearCcbAccountSetup,
    syncManual,
    history,
    setSnackbarData,
    setErrorSnackbar,
    successMessage,
    errorMessage,
  ]);

  useEffect(() => {
    if (enableDisableSyncingStatus === "success") {
      clearEnableDisableSyncing();
      resetConfirmationModalState();
      setSnackbarData({
        snackbarSeverity: "success",
        showSnackbar: true,
        snackbarMessage: `CCB Syncing ${
          userData.isDisableSync === 1 ? "Disabled" : "Enabled"
        } Successfully!`,
      });
      history.push("/integrations");
    } else if (enableDisableSyncingStatus === "error") {
      resetConfirmationModalState();
      setErrorSnackbar();
    }
  }, [
    clearEnableDisableSyncing,
    enableDisableSyncingStatus,
    resetConfirmationModalState,
    setErrorSnackbar,
    setSnackbarData,
    userData,
    history,
  ]);

  useEffect(() => {
    if (userStoreInfoStatus === "success") {
      resetConfirmationModalState();
      clearEnableDisableUserStoreInfo();
    } else if (userStoreInfoStatus === "error") {
      resetConfirmationModalState();
    }
  }, [
    clearEnableDisableUserStoreInfo,
    resetConfirmationModalState,
    userStoreInfoStatus,
  ]);

  useEffect(() => {
    // GET CURRENT CARD DETAILS
    const formData = formDataWithApiKey();
    getCurrentPlan(formData);
  }, [getCurrentPlan]);

  useEffect(() => {
    setInputs({
      ccbAccount: userApiDetails?.ccbAccount,
      ccbPassword: userApiDetails?.ccbPassword,
      ccbUsername: userApiDetails?.ccbUsername,
    });
  }, [userApiDetails]);

  useComponentWillUnmount(() => {
    clearApiDetailsState();
  }, []);

  useEffect(() => {
    if (removeCcbCredentialsStatus === "success") {
      closeConfirmationModal();
      const initialValues = {
        ccbUsername: "",
        ccbPassword: "",
        ccbAccount: "",
      };
      setInputs((state) => ({ ...state, ...initialValues }));
      setSnackbarData({
        snackbarSeverity: successMessage ? "success" : "error",
        showSnackbar: true,
        snackbarMessage: ReactHtmlParser(successMessage || errorMessage),
        autoHideDuration: 20000,
      });
      history.push("/integrations");
    } else if (removeCcbCredentialsStatus === "error") {
      setErrorSnackbar();
    }
  }, [
    closeConfirmationModal,
    history,
    removeCcbCredentialsStatus,
    setSnackbarData,
    setErrorSnackbar,
    successMessage,
    errorMessage,
  ]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleConfirmPasswordVisibility = useCallback(() => {
    setShowCcbPassword(!showCcbPassword);
  }, [showCcbPassword]);

  const handleSetupCcbAccount = useCallback(
    (event) => {
      event.preventDefault();
      const fieldsForValidation = {
        ccbUsername: inputs.ccbUsername,
        ccbPassword: inputs.ccbPassword,
        ccbAccount: inputs.ccbAccount,
      };
      const emptyFields = validateEmptyFields(fieldsForValidation);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else {
        const formData = formDataWithApiKey();
        seterrors({});
        formData.append("ccbUsername", inputs.ccbUsername);
        formData.append("ccbPassword", inputs.ccbPassword);
        formData.append("ccbAccount", inputs.ccbAccount);
        authorizeCcbAccount(formData);
      }
    },
    [authorizeCcbAccount, inputs]
  );

  const handleRemoveClicked = useCallback((type) => {
    const modalContent = `Are you sure you want to remove your ${type} credentials?`;
    setShowConfirmationModal(true);
    setConfirmationModalContent(modalContent);
  }, []);

  const handleRemoveAccountCredentials = useCallback(() => {
    const formData = formDataWithApiKey();
    if (confirmationModalContent.includes("CCB")) {
      removeCcbAccountCredentails(formData);
    }
  }, [confirmationModalContent, removeCcbAccountCredentails]);

  const handleEnableDisableSyncing = useCallback(
    (isDisableSync) => {
      const formData = formDataWithApiKey();
      if (isDisableSync === 1) {
        enableCcbSyncing(formData);
      } else {
        disableCcbSyncing(formData);
      }
    },
    [disableCcbSyncing, enableCcbSyncing]
  );

  const handleUserStoreInfoChange = useCallback(() => {
    const formData = formDataWithApiKey();
    const emptyFields = validateEmptyFields(inputs);
    if (userData.isStoreUserInfo === 1) {
      disableUserStoreInfo(formData);
    } else {
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        resetConfirmationModalState();
        return seterrors(errors);
      } else {
        seterrors({});
        formData.append("ccbUsername", inputs.ccbUsername);
        formData.append("ccbPassword", inputs.ccbPassword);
        formData.append("ccbAccount", inputs.ccbAccount);
        enableUserStoreInfo(formData);
      }
    }
  }, [
    disableUserStoreInfo,
    enableUserStoreInfo,
    inputs,
    resetConfirmationModalState,
    userData,
  ]);

  const handleStoreUserInfoSwitch = useCallback(() => {
    if (isSubscribeHigherPlan) {
      setShowConfirmationModal(true);
      setConfirmationModalContent(userStoreInfoWarning);
      setShowConfirmationModalCancelBtn(true);
    } else {
      setShowConfirmationModal(true);
      setConfirmationModalContent("This is only available on higher plans.");
      setShowConfirmationModalCancelBtn(false);
    }
  }, [isSubscribeHigherPlan]);

  const clickedConfirmForUserStoreInfo = useCallback(() => {
    if (isSubscribeHigherPlan) {
      handleUserStoreInfoChange();
    } else {
      resetConfirmationModalState();
    }
  }, [
    handleUserStoreInfoChange,
    isSubscribeHigherPlan,
    resetConfirmationModalState,
  ]);

  const handleEnableDisableConfirmationModal = useCallback(() => {
    const modalContent = `Are you sure you want to ${
      userData.isDisableSync === 1 ? "enable" : "disable"
    } CCB Syncing?`;
    setShowEnableDisableSyncConfirmModal(true);
    setConfirmationModalContent(modalContent);
  }, [userData.isDisableSync]);

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  const renderCcbInstructions = useMemo(() => {
    return (
      <div className="card-content w-45">
        <Typography variant="subtitle2">
          You will need to have a valid Church Community Builder (CCB) account
          and be able to create a CCB API user.{" "}
          <a
            href="https://support.ccbchimp.com/en/articles/2168036-step-1a"
            target="__blank"
          >
            To create a new API
          </a>{" "}
          user, visit and login to <b>YOURCCBACCOUNT</b>.ccbchurch.com →
          Settings (Gear icon) → API. Create a new API user and make sure the
          following services are turned on:
        </Typography>
        <div className="code-box">
          <Typography className="text-center" variant="subtitle2">
            Execute_search, group_profiles, group_profile_from_id,
            group_participants, search_list, individual_search,
            execute_advanced_search, saved_advanced_search_list, process_list,
            add_individual_to_queue, individual_queues and queue_individuals
          </Typography>
        </div>
        <Typography className="mt-3" variant="subtitle2">
          Enter your CCB API Username, CCB API Password and your CCB Account
          name in the fields next to this box and click on 'Authorize'.
        </Typography>
        <div className="box flexer">
          <Tooltip
            title={userData.isStoreUserInfo === 1 ? "" : userStoreInfoWarning}
            placement="top"
            arrow={true}
          >
            <Switch
              onChange={
                userData.isStoreUserInfo === 1
                  ? handleUserStoreInfoChange
                  : handleStoreUserInfoSwitch
              }
              checked={userData.isStoreUserInfo === 1 ? true : false}
              className="align-self-start mr-2"
              disabled={userStoreInfoStatus === "loading"}
            />
          </Tooltip>
          <Typography variant="subtitle2">
            {userStoreInfoErrorMessage && (
              <h6 className="text-danger text-center">
                {userStoreInfoErrorMessage}
              </h6>
            )}
            {userStoreInfoSuccessMessage && (
              <h6 className="text-success text-center">
                {userStoreInfoSuccessMessage}
              </h6>
            )}
            Also turn on the option to add additional merge fields to MailChimp
            e.g. birthday, anniversary etc. By doing this you agree to allow us
            to securely store your contacts (required for this feature).
          </Typography>
        </div>
      </div>
    );
  }, [
    handleStoreUserInfoSwitch,
    handleUserStoreInfoChange,
    userData,
    userStoreInfoErrorMessage,
    userStoreInfoStatus,
    userStoreInfoSuccessMessage,
  ]);

  return (
    <Dialog
      open={true}
      onClose={toggleModal}
      classes={{
        root: "logs-list-page",
        paper: "paper",
      }}
      BackdropProps={{
        classes: {
          root: "backdrop",
        },
      }}
      maxWidth={"lg"}
    >
      <DialogContent>
        <div>
          <h3 className="user-note text-center m-0">
            Authorize your CCB Account!
          </h3>
          <Close className="close-icon mr-4 mt-3" onClick={toggleModal} />
        </div>
        <div className="flexer w-100">
          <div className="card-inner-wrapper">
            <div className="flexer-column w-45 card-content">
              <img src={CCBlogo} alt="integration-logo" />
              <Typography className="text-center mt-3" variant="subtitle2">
                Create then enter your CCB API Username/Password (NOT your
                regular CCB login credential)
              </Typography>
              <form name="form" onSubmit={handleSetupCcbAccount}>
                <InputField
                  name="ccbUsername"
                  type="text"
                  placeholder="CCB API Username"
                  className="w-100 mt-4"
                  inputClass="authorize-password-field"
                  handleChange={handleChange}
                  value={inputs.ccbUsername}
                  error={Boolean(errors.ccbUsername)}
                  helperText={errors.ccbUsername}
                  disabled={userApiDetails?.ccbUsername && !isOnEditCredentials}
                />
                <InputField
                  name="ccbPassword"
                  type={showCcbPassword ? "text" : "password"}
                  showPassword={showCcbPassword}
                  handlePasswordVisibility={handleConfirmPasswordVisibility}
                  placeholder="CCB API Password"
                  className="w-100 mt-3"
                  inputClass="authorize-password-field"
                  handleChange={handleChange}
                  value={inputs.ccbPassword}
                  error={Boolean(errors.ccbPassword)}
                  helperText={errors.ccbPassword}
                  disabled={userApiDetails?.ccbPassword && !isOnEditCredentials}
                />
                <Fragment>
                  <InputField
                    name="ccbAccount"
                    type="text"
                    placeholder="CCB Account Name"
                    className="mt-3"
                    startAdornment={
                      <InputAdornment position="start">https://</InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="start">
                        .ccbchurch.com
                      </InputAdornment>
                    }
                    inputClass="authorize-password-field"
                    handleChange={handleChange}
                    value={inputs.ccbAccount}
                    error={Boolean(errors.ccbAccount)}
                    helperText={errors.ccbAccount}
                    disabled={
                      userApiDetails?.ccbAccount && !isOnEditCredentials
                    }
                  />
                </Fragment>
                <div className="mt-3 flexer-column">
                  <ButtonWithSpinner
                    loading={authorizeCcbAccountStatus === "loading"}
                    disabled={authorizeCcbAccountStatus === "loading"}
                    type="submit"
                    className="bg-yellow"
                  >
                    {userApiDetails?.ccbAccount &&
                    userApiDetails?.ccbUsername &&
                    !isOnEditCredentials
                      ? "Re-authorize"
                      : "Authorize"}
                  </ButtonWithSpinner>
                  {userApiDetails.ccbAccount && (
                    <div className="flexer mt-2">
                      {!isOnEditCredentials && (
                        <>
                          <Typography
                            onClick={() => setIsOnEditCredentials(true)}
                            className="link-text"
                            variant="subtitle1"
                          >
                            Edit&nbsp;
                          </Typography>{" "}
                          |
                        </>
                      )}
                      <Typography
                        onClick={() => handleRemoveClicked("CCB")}
                        className="link-text"
                        variant="subtitle1"
                      >
                        &nbsp; Remove&nbsp;
                      </Typography>{" "}
                      |
                      <Typography
                        onClick={handleEnableDisableConfirmationModal}
                        className="link-text"
                        variant="subtitle1"
                      >
                        &nbsp;
                        {userData.isDisableSync === 1 ? "Enable" : "Disable"}
                      </Typography>
                    </div>
                  )}
                </div>
              </form>
            </div>
            {renderCcbInstructions}
          </div>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            handleClose={closeConfirmationModal}
            open={showConfirmationModal}
            modalHeading="Confirm!"
            modalContent={confirmationModalContent}
            btnLabel="Confirm"
            loading={[removeCcbCredentialsStatus, userStoreInfoStatus].includes(
              "loading"
            )}
            onClick={
              confirmationModalContent.includes("available")
                ? clickedConfirmForUserStoreInfo
                : handleRemoveAccountCredentials
            }
            btnClassName="w-30 mr-3 bg-yellow"
            showCancelBtn={showConfirmationModalCancelBtn}
            disabled={[
              removeCcbCredentialsStatus,
              userStoreInfoStatus,
            ].includes("loading")}
          />
        )}
        {showEnableDisableSyncConfirmModal && (
          <ConfirmationModal
            handleClose={closeEnableDisableSyncModal}
            open={showEnableDisableSyncConfirmModal}
            modalHeading="Confirm!"
            modalContent={confirmationModalContent}
            btnLabel="Confirm"
            loading={enableDisableSyncingStatus === "loading"}
            btnClassName="w-30 mr-3 bg-yellow"
            onClick={() => handleEnableDisableSyncing(userData.isDisableSync)}
            disabled={enableDisableSyncingStatus === "loading"}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    errorMessage: store.userReducer.errorMessage,
    authorizeCcbAccountStatus: store.userReducer.authorizeCcbAccountStatus,
    successMessage: store.userReducer.successMessage,
    userApiDetails: store.userReducer.userApiDetails,
    removeCcbCredentialsStatus: store.userReducer.removeCcbCredentialsStatus,
    enableDisableSyncingStatus: store.userReducer.enableDisableSyncingStatus,
    userStoreInfoErrorMessage: store.userReducer.userStoreInfoErrorMessage,
    userStoreInfoSuccessMessage: store.userReducer.userStoreInfoSuccessMessage,
    userStoreInfoStatus: store.userReducer.userStoreInfoStatus,
    currentPlan: store.billing.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authorizeCcbAccount: (userData) => dispatch(authorizeCcbAccount(userData)),
    disableCcbSyncing: (data) => dispatch(disableCcbSyncing(data)),
    enableCcbSyncing: (data) => dispatch(enableCcbSyncing(data)),
    enableUserStoreInfo: (data) => dispatch(enableUserStoreInfo(data)),
    disableUserStoreInfo: (data) => dispatch(disableUserStoreInfo(data)),
    clearApiDetailsState: () => dispatch(clearApiDetailsState()),
    getCurrentPlan: (formData) => dispatch(getCurrentPlan(formData)),
    clearEnableDisableUserStoreInfo: () =>
      dispatch(clearEnableDisableUserStoreInfo()),
    removeCcbAccountCredentails: (data) =>
      dispatch(removeCcbAccountCredentials(data)),
    clearCcbAccountSetup: () => dispatch(clearCcbAccountSetup()),
    syncManual: (data) => dispatch(syncManual(data)),
    clearEnableDisableSyncing: () => dispatch(clearEnableDisableSyncing()),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupCcbAccount);
