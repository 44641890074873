import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  Select,
  MenuItem,
  Switch,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { formDataWithApiKey } from "../../../helpers";
import Loader from "../../Loader/Loader";
import { setSnackbarData } from "../../../store/actions/globalAction";
import {
  getHeaderMapping,
  saveHeaderMapping,
} from "../../../store/middlewares/toolsMiddleware";
import MaterialButton from "../../ MaterialButton/MaterialButton";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { clearSaveHeaderMapping } from "../../../store/actions/toolsActions";
import { getAllCountries } from "../../../store/middlewares/countriesMiddleware";
import { updateUserCountry } from "../../../store/middlewares/userMiddleware";
import TwoAndOneWayIcon from "../../../HelperComponents/TwoAndOneWayIcon";
import SubscriptionPlanTags from "../../../HelperComponents/SubscriptionPlanTags";
import "../../../styles/components/headerMapping.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ONE_WAY_HEADER_MAPPING_TOOLTIP =
  "If two-way syncing is enabled and a conflict arises between Mailchimp and CCB, you'll have the option to choose which data to sync. By default, the CCB data remains unaltered.";

const HeaderMapping = (props) => {
  const {
    // component props
    history,

    // Redux funcions
    setSnackbarData,
    getHeaderMapping,
    saveHeaderMapping,
    clearSaveHeaderMapping,
    getAllCountries,
    updateUserCountry,

    // Redux props
    mcFieldsFromGlobalState,
    userHeaderMappingFromGlobalState,
    headerMappingStatus,
    saveHeaderMappingStatus,
    countriesList,
    defaultCountry,
    defaultAddressTypeFromRedux,
    addressOptions,
  } = props;
  const [mcFields, setMcFields] = useState([]);
  const [countryFields, setCountryFields] = useState([]);
  const [userHeaderMapping, setUserHeaderMapping] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [currentCountry, setCurrentCountry] = useState("");
  const [defaultAddressType, setDefaultAddressType] = useState("");

  useEffect(() => {
    setMcFields(mcFieldsFromGlobalState);
    setUserHeaderMapping(userHeaderMappingFromGlobalState);
  }, [mcFieldsFromGlobalState, userHeaderMappingFromGlobalState]);

  useEffect(() => {
    setCurrentCountry(defaultCountry);
    setDefaultAddressType(defaultAddressTypeFromRedux);
  }, [defaultAddressTypeFromRedux, defaultCountry]);

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (saveHeaderMappingStatus === "success") {
      toggleModal();
      showSnackbar(`Header Mappings Saved Successfully!`, "success");
      clearSaveHeaderMapping();
    }
  }, [
    clearSaveHeaderMapping,
    saveHeaderMappingStatus,
    showSnackbar,
    toggleModal,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getHeaderMapping(formData);
  }, [getHeaderMapping]);

  const handleSwitchToggle = useCallback(
    (index) => {
      const headerMapping = [...userHeaderMapping];
      headerMapping[index].isEnabled = !headerMapping[index].isEnabled;
      if (headerMapping[index].code === "MOBILE_PHONE") {
        headerMapping[index].mcTagName = headerMapping[index].isEnabled
          ? "PHONE"
          : "";
      }
      setUserHeaderMapping(headerMapping);
    },
    [userHeaderMapping]
  );

  const handleSelectChange = (event, index, item) => {
    const headerMapping = [...userHeaderMapping];
    headerMapping[index].mcTagName = event.target.value;
    setUserHeaderMapping(headerMapping);
  };

  const handleSaveHeaderMapping = useCallback(() => {
    const formData = formDataWithApiKey();
    const countryFormData = formDataWithApiKey();
    let errorMessage = {};
    userHeaderMapping.forEach((item) => {
      if (
        item.code === "MOBILE_PHONE" &&
        item.isEnabled &&
        currentCountry === "PHONE"
      ) {
        errorMessage[item.code] = "Please select a country";
      } else if (
        item.isEnabled &&
        item.isEditable &&
        (!item.mcTagName || item.mcTagName === "Please Select")
      ) {
        errorMessage[item.code] = "Please select a field";
      } else {
        errorMessage[item.code] = "";
      }
    });
    if (Object.values(errorMessage).some((item) => item !== "")) {
      setErrorMessage(errorMessage);
      return;
    }
    userHeaderMapping.forEach((item) => {
      if (item.isEditable) {
        if (item.isEnabled && item.mcTagName) {
          formData.append(`params[mapping][${item.code}]`, item.mcTagName);
        } else {
          formData.append(`params[mapping][${item.code}]`, "");
        }
      }
    });
    if (defaultAddressTypeFromRedux !== defaultAddressType) {
      formData.append(`default_address_type`, defaultAddressType);
    }
    setErrorMessage({});
    saveHeaderMapping(formData);
    if (currentCountry !== "PHONE" && currentCountry !== defaultCountry) {
      countryFormData.append(`default_country`, currentCountry);
      updateUserCountry(countryFormData);
    }
  }, [
    currentCountry,
    saveHeaderMapping,
    updateUserCountry,
    userHeaderMapping,
    defaultCountry,
    defaultAddressType,
    defaultAddressTypeFromRedux,
  ]);

  const showTooltipOnMobilePhoneField = useCallback((item) => {
    if (!item.isEditable && !item.isEnabled && item.code === "MOBILE_PHONE") {
      return false;
    }
    return true;
  }, []);

  const menuItems = useCallback(() => {
    return mcFields;
  }, [mcFields]);

  useEffect(() => {
    getAllCountries();
  }, [getAllCountries]);

  useEffect(() => {
    const mappedCountries = Object.keys(countriesList).map((key) => ({
      code: key,
      name: countriesList[key],
    }));
    mappedCountries.unshift({
      code: "PHONE",
      name: "Please Select",
    });
    setCountryFields(mappedCountries);
  }, [countriesList]);

  const fieldValue = useCallback((item) => {
    if (item.mcTagName !== "" && item.mcTagName !== undefined) {
      return item.mcTagName;
    }
    return item.code === "MOBILE_PHONE" ? "PHONE" : "Please Select";
  }, []);

  const handleCountryChange = useCallback((event) => {
    setCurrentCountry(event.target.value);
  }, []);

  const handleDefaultAddressTypeChange = useCallback((event) => {
    setDefaultAddressType(event.target.value);
  }, []);

  const selectedFields = userHeaderMapping
    .filter((item) => !item.isEditable)
    .map((el) => el.mcTagName);

  return (
    <div>
      <Dialog
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          paper: "w-100",
        }}
        maxWidth="md"
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Custom Fields Mapping</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>
          <div className="flexer-column mt-4">
            {Boolean(userHeaderMapping.length) &&
              userHeaderMapping.map((item, index) => (
                <Tooltip
                  title={item?.isTwoWay ? ONE_WAY_HEADER_MAPPING_TOOLTIP : ""}
                  arrow={true}
                  placement="right"
                >
                  <div key={index} className={`header-mapping-box w-100`}>
                    <div className="header-mapping-name-container">
                      <Switch
                        checked={item.isEnabled}
                        disabled={!item.isEditable}
                        onChange={() => handleSwitchToggle(index)}
                      />
                      <SubscriptionPlanTags className="ml-2" plan={item.plan} />
                      <Typography variant="subtitle1" className="ml-3">
                        {item.name}
                      </Typography>
                      <div className="two-one-way-icon-container">
                        <TwoAndOneWayIcon isTwoWay={item?.isTwoWay} />
                      </div>
                    </div>

                    <div className="header-mapping-select-box-container">
                      <div
                        className={
                          ["MOBILE_PHONE", "ADDRESS"].includes(item.code)
                            ? "w-50"
                            : "w-100"
                        }
                      >
                        {errorMessage[item.code] && (
                          <span className="text-danger header-field-error-message">
                            {errorMessage[item.code]}
                          </span>
                        )}
                        <Select
                          variant="outlined"
                          disabled={
                            !item.isEditable || item.code === "MOBILE_PHONE"
                          }
                          value={
                            item.code === "MOBILE_PHONE"
                              ? "PHONE"
                              : fieldValue(item)
                          }
                          onChange={(event) =>
                            handleSelectChange(event, index, item)
                          }
                          className="w-100 select-box"
                          id={JSON.stringify(item)}
                        >
                          {menuItems(item.code).map((field, idx) => (
                            <MenuItem
                              key={idx}
                              disabled={selectedFields.includes(field.code)}
                              value={field.code}
                            >
                              {field.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {countryFields.length !== 0 &&
                        item.code === "MOBILE_PHONE" && (
                          <div className="w-50 ml-3 d-flex align-items-start justify-content-center flex-column">
                            <Typography variant="body2">Country</Typography>
                            {errorMessage[item.code] && (
                              <span className="text-danger header-field-error-message">
                                {errorMessage[item.code]}
                              </span>
                            )}
                            <Tooltip
                              title="This new merge field is only available for the highest plan"
                              placement="top"
                              disableHoverListener={showTooltipOnMobilePhoneField(
                                item
                              )}
                              disableTouchListener={showTooltipOnMobilePhoneField(
                                item
                              )}
                              disableFocusListener={true}
                            >
                              <Select
                                variant="outlined"
                                disabled={!item.isEditable}
                                value={currentCountry}
                                onChange={handleCountryChange}
                                className="w-100 select-box"
                                id={JSON.stringify(item)}
                              >
                                {countryFields.map((field, idx) => (
                                  <MenuItem
                                    key={idx}
                                    disabled={selectedFields.includes(
                                      field.code
                                    )}
                                    value={field.code}
                                  >
                                    {field.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Tooltip>
                          </div>
                        )}
                      {item.code === "ADDRESS" && (
                        <div className="w-50 ml-3 d-flex align-items-start justify-content-center flex-column">
                          <Typography variant="body2">
                            Default address type
                          </Typography>
                          <Tooltip
                            title="This new merge field is only available for the highest plan"
                            placement="top"
                            disableHoverListener={showTooltipOnMobilePhoneField(
                              item
                            )}
                            disableTouchListener={showTooltipOnMobilePhoneField(
                              item
                            )}
                            disableFocusListener={true}
                          >
                            <Select
                              variant="outlined"
                              disabled={!item.isEditable}
                              value={defaultAddressType}
                              onChange={handleDefaultAddressTypeChange}
                              className="w-100 select-box"
                              id={JSON.stringify(item)}
                            >
                              {addressOptions.map((addressType, idx) => (
                                <MenuItem key={idx} value={addressType}>
                                  {addressType}
                                </MenuItem>
                              ))}
                            </Select>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              ))}
          </div>
        </DialogContent>
        <DialogActions className="flexer-column mt-3">
          <div className="d-flex justify-content-between w-100">
            <MaterialButton
              title="Close"
              className="w-30 ml-3 dark-gray-button"
              onClick={toggleModal}
            />
            <ButtonWithSpinner
              loading={saveHeaderMappingStatus === "loading"}
              disabled={saveHeaderMappingStatus === "loading"}
              className={"bg-yellow w-30 mr-3"}
              onClick={handleSaveHeaderMapping}
            >
              Save
            </ButtonWithSpinner>
          </div>
        </DialogActions>
      </Dialog>
      {headerMappingStatus === "loading" && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    userHeaderMappingFromGlobalState: store.tools.userHeaderMapping,
    mcFieldsFromGlobalState: store.tools.mcFields,
    headerMappingStatus: store.tools.headerMappingStatus,
    saveHeaderMappingStatus: store.tools.saveHeaderMappingStatus,
    defaultCountry: store.tools.defaultCountry,
    defaultAddressTypeFromRedux: store.tools.defaultAddressType,
    addressOptions: store.tools.addressOptions,
    countriesList: store.countries.countriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getHeaderMapping: (formData) => dispatch(getHeaderMapping(formData)),
    saveHeaderMapping: (formData) => dispatch(saveHeaderMapping(formData)),
    updateUserCountry: (formData) => dispatch(updateUserCountry(formData)),
    clearSaveHeaderMapping: () => dispatch(clearSaveHeaderMapping()),
    getAllCountries: () => dispatch(getAllCountries()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderMapping));
