import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dialog, Typography, Slide, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import {
  searchCcbToMc,
  searchMcToCcb,
} from "../../../store/middlewares/logsMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import TableHeader from "../../Table/TableHeader/TableHeader";
import LogDetailsTableBody from "../LogDetails/LogDetailsTableBody";
import Loader from "../../Loader/Loader";
import LogBreadcrumb from "../LogBreadcrumb/LogBreadcrumb";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableCells = [
  {
    text: "Type",
  },
  {
    text: "Result",
  },
  {
    text: "Logs",
  },
];

const LogsSearchStepTwo = (props) => {
  const {
    // Redux props
    searchTerm,
    logSearchStepTwoData,
    logSearchStepTwoStatus,

    // Redux functions
    searchCcbToMc,
    searchMcToCcb,

    // component props
    history,
    match,
  } = props;

  const toggleModal = useCallback(() => {
    history.push("/logs/search-results");
  }, [history]);

  useEffect(() => {
    if (!searchTerm) {
      toggleModal();
    }
  }, [searchTerm, toggleModal]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    const {
      location: { state },
    } = history;
    if (match.params.type === "ccbToMc" && match.params.query) {
      formData.append("email", match.params.query);
      formData.append("fullName", state?.data?.fullName);
      searchCcbToMc(formData, null);
    } else if (match.params.type === "mcToCcb" && match.params.query) {
      formData.append("email", match.params.query);
      searchMcToCcb(formData, null);
    }
  }, [
    history,
    match.params.query,
    match.params.type,
    searchCcbToMc,
    searchMcToCcb,
  ]);

  const getBreadcrumData = useCallback(
    (newBreadcrumb) => {
      const breadcrumbs = [
        {
          text: searchTerm,
          route: `/logs/search-results`,
        },
        {
          text: `${match.params.type} - ${match.params.query}`,
          route: `/logs/search-results/${match.params.type}/${match.params.query}`,
        },
      ];
      const finalBreadcrumbs = newBreadcrumb
        ? [...breadcrumbs, newBreadcrumb]
        : breadcrumbs;
      return finalBreadcrumbs;
    },
    [match.params.query, match.params.type, searchTerm]
  );

  const handleLogStatistics = useCallback(
    (logDetails) => {
      history.push(
        `${history.location.pathname}/statistics/${logDetails.detailId}`,
        { logDetails }
      );
    },
    [history]
  );

  const navigateToLogDetailsScreen = useCallback(
    (data) => {
      const newBreadcrumb = {
        text: data.logName,
        route: `/logs/details/${data.logId}`,
      };
      history.push(`/logs/details/${data.logId}`, {
        isBreadcrumbEnable: true,
        breadcrumbsFromRoute: getBreadcrumData(newBreadcrumb),
      });
    },
    [getBreadcrumData, history]
  );

  const navigateToLogStatisticsScreen = useCallback(
    (data) => {
      const newBreadcrumb = {
        text: data.detailName,
        route: `/logs/details/${data.logId}/statistics/${data.detailId}`,
      };
      history.push(`/logs/details/${data.logId}/statistics/${data.detailId}`, {
        isBreadcrumbEnable: true,
        breadcrumbsFromRoute: getBreadcrumData(newBreadcrumb),
      });
    },
    [getBreadcrumData, history]
  );

  const renderTable = useCallback(
    (data, tableCells, tableType) => {
      return (
        <TableInfinityScroll
          className="logs-modal-table"
          loadMore={() => {}}
          fetchFunction={() => {}}
        >
          <table className="table">
            <TableHeader
              className="user-settings-greetings-table-header"
              cells={tableCells}
            />
            <LogDetailsTableBody
              data={data}
              handleItemClicked={handleLogStatistics}
              tableType={tableType}
              navigateToLogDetailsScreen={navigateToLogDetailsScreen}
              navigateToLogStatisticsScreen={navigateToLogStatisticsScreen}
            />
          </table>
        </TableInfinityScroll>
      );
    },
    [
      handleLogStatistics,
      navigateToLogDetailsScreen,
      navigateToLogStatisticsScreen,
    ]
  );

  const renderSearchStepTwoTable = useCallback(() => {
    if (logSearchStepTwoData?.length) {
      return (
        <div className="flexer-column mt-4">
          <div className="user-settings-greetings">
            {renderTable(logSearchStepTwoData, tableCells, "logSearchStepTwo")}
          </div>
        </div>
      );
    }
  }, [renderTable, logSearchStepTwoData]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Logs Search Results</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <LogBreadcrumb breadcrumbs={getBreadcrumData()} />
          </div>

          {renderSearchStepTwoTable()}
        </DialogContent>
      </Dialog>
      {logSearchStepTwoStatus === "loading" && <Loader />}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    searchTerm: store.logs.searchTerm,
    logSearchStepTwoStatus: store.logs.logSearchStepTwoStatus,
    logSearchStepTwoData: store.logs.logSearchStepTwoData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchCcbToMc: (data, requestId) =>
      dispatch(searchCcbToMc(data, requestId)),
    searchMcToCcb: (data, requestId) =>
      dispatch(searchMcToCcb(data, requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogsSearchStepTwo));
