import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import ToolsBreadCrumb from "../ToolsBreadCrumb/ToolsBreadCrumb";
import ToolsTableBody from "../ToolsTableBody";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import {
  getPrimaryContacts,
  makePrimaryContacts,
} from "../../../store/middlewares/toolsMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import EmptyMessageBox from "../../EmptyMessageBox/EmptyMessageBox";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import Loader from "../../Loader/Loader";
import { clearMakePrimaryContactState } from "../../../store/actions/toolsActions";
import { setSnackbarData } from "../../../store/actions/globalAction";
import "../../../styles/components/more/billing.scss";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/payment-form.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cells = [
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Email",
  },
  {
    text: "Family Position",
  },
  {
    text: "CCB Profiles",
  },
];

const breadcrumbs = [
  {
    text: `Set primary profiles`,
    route: `/tools/primary-contacts`,
  },
];

const PrimaryContacts = (props) => {
  const {
    // component props
    history,

    // Redux functions
    getPrimaryConacts,
    makePrimaryConacts,
    clearPrimaryContactState,
    setSnackbarData,

    // Redux props
    primaryContacts,
    primaryContactsStatus,
    primaryContactsPage,
    primaryContactsLoadedAll,
    primaryContactsLoadingMoreStatus,
    makePrimaryContactStatus,
  } = props;
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    showDuplicateOnly: 1,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [contactToMakePrimary, setContactToMakePrimary] = useState({});

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const appendFiltersInFormdata = useCallback(
    (formData) => {
      formData.append("filter[firstName]", inputs.firstName);
      formData.append("filter[lastName]", inputs.lastName);
      formData.append("filter[email]", inputs.email);
      formData.append("filter[showDuplicateOnly]", inputs.showDuplicateOnly);
    },
    [inputs.email, inputs.firstName, inputs.lastName, inputs.showDuplicateOnly]
  );

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (makePrimaryContactStatus === "success") {
      closeConfirmationModal();
      showSnackbar(`This contact has been successfully updated.`, "success");
      formData.append("page", Number(primaryContactsPage || 1));
      appendFiltersInFormdata(formData);
      getPrimaryConacts(formData);
      clearPrimaryContactState();
    }
  }, [
    appendFiltersInFormdata,
    clearPrimaryContactState,
    closeConfirmationModal,
    getPrimaryConacts,
    makePrimaryContactStatus,
    primaryContactsPage,
    showSnackbar,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    getPrimaryConacts(formData);
  }, [getPrimaryConacts]);

  const toggleModal = useCallback(() => {
    history.push("/tools", { isSubRoute: true });
  }, [history]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (name === "showDuplicateOnly") {
        setInputs((state) => ({
          ...state,
          [name]: state.showDuplicateOnly === 1 ? 0 : 1,
        }));
      } else {
        setInputs((state) => ({ ...state, [name]: value }));
      }
    },
    [setInputs]
  );

  const handleSearch = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    appendFiltersInFormdata(formData);
    getPrimaryConacts(formData);
  }, [appendFiltersInFormdata, getPrimaryConacts]);

  const loadMore = useCallback(() => {
    if (
      !primaryContactsLoadedAll &&
      primaryContactsStatus !== "loading" &&
      primaryContactsStatus !== "error" &&
      primaryContacts
    ) {
      const formData = formDataWithApiKey();
      const pageNum = Number(primaryContactsPage) + 1;
      formData.append("page", pageNum);
      appendFiltersInFormdata(formData);
      getPrimaryConacts(formData);
    }
  }, [
    appendFiltersInFormdata,
    getPrimaryConacts,
    primaryContacts,
    primaryContactsLoadedAll,
    primaryContactsPage,
    primaryContactsStatus,
  ]);

  const handleMakePrimaryContact = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("id", contactToMakePrimary.id);
    makePrimaryConacts(formData);
  }, [contactToMakePrimary, makePrimaryConacts]);

  const handleItemClicked = useCallback((item) => {
    const modalContent = `Are you sure you want to switch the primary contact to #${item.ccbParticipantId} ${item.firstName} ${item.lastName} (${item.email})?`;
    setModalContent(modalContent);
    setContactToMakePrimary(item);
    setShowConfirmationModal(true);
  }, []);

  const loadingMore = primaryContactsLoadingMoreStatus === "loading";

  const renderSearchFields = useCallback(() => {
    return (
      <div className="flexer">
        <form className="form flexer">
          <fieldset className="mr-4">
            <label htmlFor="first-name">First Name</label>
            <input
              name="firstName"
              onChange={handleChange}
              value={inputs.firstName}
              type="text"
              id="first-name"
            />
          </fieldset>
          <fieldset className="mr-4">
            <label htmlFor="last-name">Last Name</label>
            <input
              name="lastName"
              onChange={handleChange}
              value={inputs.lastName}
              type="text"
              id="last-name"
            />
          </fieldset>
          <fieldset>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              onChange={handleChange}
              value={inputs.email}
              type="text"
              id="email"
            />
          </fieldset>
        </form>
        <div className="flexer mt-3">
          <Checkbox
            checked={inputs.showDuplicateOnly === 1 ? true : false}
            onChange={handleChange}
            name="showDuplicateOnly"
            style={{ color: "#2db8e8" }}
          />{" "}
          Show duplicates only
        </div>
        <ButtonWithSpinner
          onClick={handleSearch}
          className="bg-yellow mt-3 ml-4"
        >
          Search
        </ButtonWithSpinner>
      </div>
    );
  }, [
    handleChange,
    handleSearch,
    inputs.email,
    inputs.firstName,
    inputs.lastName,
    inputs.showDuplicateOnly,
  ]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Contacts</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <ToolsBreadCrumb breadcrumbs={breadcrumbs} />
          </div>
          {renderSearchFields()}
          <div className="flexer-column mt-1">
            <div className="user-settings-greetings">
              {primaryContactsStatus === "loading" && !loadingMore ? (
                <div className="d-flex justify-content-center spinner-container">
                  <Loader />
                </div>
              ) : (
                <TableInfinityScroll
                  className="logs-modal-table"
                  loadMore={loadMore}
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                    />
                    <ToolsTableBody
                      data={primaryContacts}
                      handleItemClicked={handleItemClicked}
                      isLoading={loadingMore}
                      tableIdentity="primaryContacts"
                    />
                  </table>
                  {!Boolean(primaryContacts.length) && (
                    <EmptyMessageBox
                      message="No data found"
                      hasBorder={false}
                    />
                  )}
                </TableInfinityScroll>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          modalHeading={"Confirmation"}
          handleClose={closeConfirmationModal}
          modalContent={modalContent}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30"}
          onClick={handleMakePrimaryContact}
          loading={makePrimaryContactStatus === "loading"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    primaryContacts: store.tools.primaryContacts,
    primaryContactsStatus: store.tools.primaryContactsStatus,
    primaryContactsPage: store.tools.primaryContactsPage,
    primaryContactsLoadedAll: store.tools.primaryContactsLoadedAll,
    primaryContactsLoadingMoreStatus:
      store.tools.primaryContactsLoadingMoreStatus,
    makePrimaryContactStatus: store.tools.makePrimaryContactStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPrimaryConacts: (data) => dispatch(getPrimaryContacts(data)),
    makePrimaryConacts: (data) => dispatch(makePrimaryContacts(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearPrimaryContactState: (data) =>
      dispatch(clearMakePrimaryContactState(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrimaryContacts));
