export const breakpoints = {
  468: "xs",
  768: "sm",
  992: "md",
  1200: "lg",
  1500: "xl",
};

export const isSmOrXsBreakpoint = (currentBreakpoint) => {
  return ["sm", "xs"].includes(currentBreakpoint);
};

export const isLgOrBelowBreakpoint = (currentBreakpoint) => {
  return ["sm", "xs", "md", "lg"].includes(currentBreakpoint);
};

export const isMdOrBelowBreakpoint = (currentBreakpoint) => {
  return ["sm", "xs", "md"].includes(currentBreakpoint);
};

export const isLgBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "lg";
};

export const isXsBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "xs";
};
