import React, { useCallback, useState, useEffect, useRef } from "react";
import lodash from "lodash";
import { connect } from "react-redux";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { useWithNumberSteps } from "../../../utils/hooks/useWithNumberSteps";
import UnresolveConflictsPairsStepOne from "./UnresolveConflictsPairsStepOne/UnresolveConflictsPairsStepOne";
import HoldContactsStepTwo from "./HoldContactsStepTwo/HoldContactsStepTwo";
import PossibleMatchesStepThree from "./PossibleMatchesStepThree/PossibleMatchesStepThree";
import MatchContactsStepFour from "./MatchContactsStepFour/MatchContactsStepFour";
import {
  getHoldContacts,
  ignoreAllHoldContacts,
  ignoreHoldContacts,
  matchContacts,
} from "../../../store/middlewares/integrationMiddleware";
import {
  formDataWithApiKey,
  parsingHoldContactsForCsv,
} from "../../../helpers";
import { setSnackbarData } from "../../../store/actions/globalAction";
import {
  clearIgnoreAllHoldContactStatus,
  clearIgnoreHoldContactStatus,
  clearMatchContactStatus,
} from "../../../store/actions/integrationActions";
import { getWarnings } from "../../../store/middlewares/userMiddleware";
import { useComponentWillUnmount } from "../../../utils/hooks/useComponentWillUnmount";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { Link } from "react-router-dom";
import { getIgnoredEmails } from "../../../store/middlewares/toolsMiddleware";
import { CSVLink } from "react-csv";
import "../../../styles/components/resolve-conflicts.scss";
import api from "../../../services/api";

const headers = [
  { label: "Type", key: "type" },
  { label: "CCB ID", key: "id" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
];

const UnresolveConflictsModal = (props) => {
  const {
    // Router
    history,

    // Redux props
    matchContactsStatus,
    ignoreHoldContactsStatus,
    holdContacts,
    ignoredEmails,
    ignoreAllHoldContactsStatus,
    errorMessage,

    // Redux functions
    matchContacts,
    setSnackbarData,
    clearMatchContactStatus,
    getWarnings,
    ignoreHoldContacts,
    clearIgnoreHoldContactStatus,
    getHoldContacts,
    getIgnoredEmails,
    ignoreAllHoldContacts,
    clearIgnoreAllHoldContactStatus,
  } = props;
  const [selectedConflict, setSelectedConflict] = useState({});
  const [selectedHoldContacts, setSelectedHoldContacts] = useState({});
  const [selectedPossibleMatch, setSelectedPossibleMatch] = useState({});
  const [allHoldContacts, setAllHoldContacts] = useState([]);
  const [isCreateNewContact, setIsCreateNewContact] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [
    openIgnoreContactConfirmationModal,
    setOpenIgnoreContactConfirmationModal,
  ] = useState(false);
  const [
    openIgnoreAllContactConfirmationModal,
    setOpenIgnoreAllContactConfirmationModal,
  ] = useState(false);
  const [
    ignoreAllConfirmationModalContent,
    setIgnoreAllConfirmationModalContent,
  ] = useState("");
  const [doNotShowConfirmationModal, setDoNotShowConfirmationModal] =
    useState(false);
  const [ignoreContactModalContent, setIgnoreContactModalContent] =
    useState("");
  const [inputFields, setInputFields] = useState({
    firstName: {
      value: "",
      checked: 0,
    },
    lastName: {
      value: "",
      checked: 0,
    },
    email: {
      value: "",
      checked: 0,
    },
  });
  const csvRef = useRef();
  const { onClickNext, onClickBack, currentStep, setCurrentStep } =
    useWithNumberSteps();

  const closeIgnoreContactModal = useCallback(() => {
    setOpenIgnoreContactConfirmationModal(false);
    if (currentStep === 2) {
      setSelectedHoldContacts({});
    }
  }, [currentStep]);

  useComponentWillUnmount(() => {
    setSnackbarData({
      snackbarSeverity: "",
      showSnackbar: null,
      snackbarMessage: "",
      action: null,
    });
    clearMatchContactStatus();
  }, []);

  const setDataInStateForCreateNewContact = useCallback(
    (inputs) => {
      inputs["firstName"].value = selectedHoldContacts?.firstName || "";
      inputs["lastName"].value = selectedHoldContacts?.lastName || "";
      inputs["email"].value = selectedHoldContacts?.email || "";
      return inputs;
    },
    [selectedHoldContacts]
  );

  const clearLocalStateAndMoveToStepTwo = useCallback(() => {
    const cloneInputFields = {
      firstName: {
        value: "",
        checked: 0,
      },
      lastName: {
        value: "",
        checked: 0,
      },
      email: {
        value: "",
        checked: 0,
      },
    };
    setCurrentStep(2);
    setInputFields((prevState) => ({
      ...prevState,
      ...cloneInputFields,
    }));
    setSelectedHoldContacts({});
    setSelectedPossibleMatch({});
    setIsCreateNewContact(false);
  }, [setCurrentStep]);

  const clearDataAndCloseModal = useCallback(() => {
    clearMatchContactStatus();
    clearIgnoreHoldContactStatus();
    if (holdContacts?.length === 1) {
      history.push("/dashboard");
    } else {
      clearLocalStateAndMoveToStepTwo();
    }
  }, [
    clearIgnoreHoldContactStatus,
    clearLocalStateAndMoveToStepTwo,
    clearMatchContactStatus,
    history,
    holdContacts,
  ]);

  const getHoldContactsAfterIgnoreContact = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append(
      "id",
      selectedConflict?.mcToCcbId || selectedConflict?.ccbToMcId
    );
    getHoldContacts(formData);
  }, [getHoldContacts, selectedConflict]);

  useEffect(() => {
    if (!lodash.isEmpty(allHoldContacts)) {
      csvRef.current.link.click();
      setAllHoldContacts([]);
    }
  }, [allHoldContacts]);

  useEffect(() => {
    if (ignoreAllHoldContactsStatus === "success") {
      const action = (
        <Button
          onClick={() => history.push("/tools/ignored-emails")}
          className="view-link"
        >
          View
        </Button>
      );
      setSnackbarData({
        snackbarSeverity: ignoreAllHoldContactsStatus,
        showSnackbar: true,
        snackbarMessage: `You ignored ${holdContacts?.length}`,
        action: action,
      });
      clearIgnoreAllHoldContactStatus();
      history.push("/dashboard");
    }
  }, [
    clearIgnoreAllHoldContactStatus,
    holdContacts,
    ignoreAllHoldContactsStatus,
    setSnackbarData,
    history,
  ]);

  useEffect(() => {
    if (!lodash.isEmpty(selectedHoldContacts)) {
      setInputFields((prevState) => ({
        ...prevState,
        ...setDataInStateForCreateNewContact(prevState),
      }));
    }
  }, [selectedHoldContacts, setDataInStateForCreateNewContact]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (ignoreHoldContactsStatus === "success") {
      setSnackbarData({
        snackbarSeverity: "success",
        showSnackbar: true,
        snackbarMessage: `You ignored a Conflict for ${selectedHoldContacts.firstName} ${selectedHoldContacts?.lastName}`,
      });
      if (holdContacts?.length === 1) {
        history.push("/dashboard");
      } else {
        setCurrentStep(2);
        getHoldContactsAfterIgnoreContact();
      }
      getWarnings(formData);
      if (openIgnoreContactConfirmationModal) {
        closeIgnoreContactModal();
      }
      clearIgnoreHoldContactStatus();
      setSelectedHoldContacts({});
    } else if (ignoreHoldContactsStatus === "error") {
      setSnackbarData({
        snackbarSeverity: ignoreHoldContactsStatus,
        showSnackbar: true,
        snackbarMessage:
          errorMessage || "Something went wrong, please try again later!",
      });
      if (openIgnoreContactConfirmationModal) {
        closeIgnoreContactModal();
      }
      setSelectedHoldContacts({});
      clearIgnoreHoldContactStatus();
    }
  }, [
    clearIgnoreHoldContactStatus,
    getHoldContactsAfterIgnoreContact,
    getWarnings,
    history,
    holdContacts,
    ignoreHoldContactsStatus,
    openIgnoreContactConfirmationModal,
    setSnackbarData,
    closeIgnoreContactModal,
    selectedHoldContacts,
    setCurrentStep,
    errorMessage,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (matchContactsStatus === "success") {
      setSnackbarData({
        snackbarSeverity: matchContactsStatus,
        showSnackbar: true,
        snackbarMessage: "Profile merged successfully!",
      });
      getWarnings(formData);
      clearDataAndCloseModal();
    } else if (matchContactsStatus === "error") {
      setSnackbarData({
        snackbarSeverity: matchContactsStatus,
        showSnackbar: true,
        snackbarMessage:
          errorMessage || "Something went wrong, please try again later!",
      });
      clearMatchContactStatus();
    }
  }, [
    clearDataAndCloseModal,
    getWarnings,
    matchContactsStatus,
    setSnackbarData,
    errorMessage,
    clearMatchContactStatus,
  ]);

  useEffect(() => {
    if (currentStep === 1) {
      const formData = formDataWithApiKey();
      formData.append("page", 1);
      getIgnoredEmails(formData);
    }
  }, [getIgnoredEmails, currentStep]);

  const downloadHoldContactsAsCsv = useCallback(async () => {
    if (!csvLoading) {
      setCsvLoading(true);
    }
    try {
      const formData = formDataWithApiKey();
      const allHoldContactsResponve = await api.getAllHoldContacts(formData);
      setCsvLoading(false);
      if (allHoldContactsResponve.status === 1) {
        const parseHoldContacts = parsingHoldContactsForCsv(
          allHoldContactsResponve.holdContacts
        );
        setAllHoldContacts(parseHoldContacts);
      } else {
        setAllHoldContacts([]);
      }
    } catch (error) {
      setCsvLoading(false);
      setAllHoldContacts([]);
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: "Something went wrong, please try again later!",
      });
    }
  }, [csvLoading, setSnackbarData]);

  const setDataInStateOnChange = useCallback((inputs, name, value) => {
    inputs[name].value = value;
    return inputs;
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputFields((prevState) => ({
        ...prevState,
        ...setDataInStateOnChange(prevState, name, value),
      }));
    },
    [setDataInStateOnChange]
  );

  const setDataInStateOnRadioBtnChanged = useCallback((inputs, name, value) => {
    inputs[name].value = value;
    inputs[name].checked = inputs[name].checked === 1 ? 0 : 1;
    return inputs;
  }, []);

  const handleRadioBtnChange = useCallback(
    ({ target: { name, value } }) => {
      setInputFields((prevState) => ({
        ...prevState,
        ...setDataInStateOnRadioBtnChanged(prevState, name, value),
      }));
    },
    [setDataInStateOnRadioBtnChanged]
  );

  const toggleModal = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  const onSelectConflictResolve = useCallback((obj) => {
    setSelectedConflict(obj);
  }, []);

  const getModalSubheading = useCallback(() => {
    if (currentStep === 1) {
      return "Select to start resolving";
    } else if (currentStep === 2) {
      return "These contacts have conflicts. Click on a contact to resolve it.";
    } else if (currentStep === 4) {
      return `You are about to associate the email ${selectedHoldContacts?.email} captured (left) to a different CCB profile (right).`;
    }
    return null;
  }, [currentStep, selectedHoldContacts]);

  const onClickedCreateNewContact = useCallback(() => {
    setIsCreateNewContact(true);
    onClickNext();
  }, [onClickNext]);

  const resolveConflictByCreateNewContact = useCallback(() => {
    const formData = formDataWithApiKey();
    if (
      !inputFields["firstName"].value ||
      !inputFields["lastName"].value ||
      !inputFields["email"].value
    ) {
      return setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: "Fields cannot be empty!",
      });
    }
    formData.append("id", selectedHoldContacts.id);
    formData.append("matchContactId", 0);
    formData.append("firstName", inputFields["firstName"].value);
    formData.append("lastName", inputFields["lastName"].value);
    formData.append("email", inputFields["email"].value);
    formData.append("leftFirstName", inputFields["firstName"].value);
    formData.append("leftLastName", inputFields["lastName"].value);
    formData.append("leftEmail", inputFields["email"].value);
    matchContacts(formData);
  }, [inputFields, matchContacts, selectedHoldContacts.id, setSnackbarData]);

  const resolveConflictByMatchContact = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("id", selectedHoldContacts.id);
    formData.append("matchContactId", selectedPossibleMatch.id);
    formData.append("firstName", inputFields["firstName"].checked);
    formData.append("lastName", inputFields["lastName"].checked);
    formData.append("email", inputFields["email"].checked);
    if (Number(inputFields["firstName"].checked) === 0) {
      formData.append("leftFirstName", inputFields["firstName"].value);
    }
    if (Number(inputFields["lastName"].checked) === 0) {
      formData.append("leftLastName", inputFields["lastName"].value);
    }
    if (Number(inputFields["email"].checked) === 0) {
      formData.append("leftEmail", inputFields["email"].value);
    }
    matchContacts(formData);
  }, [inputFields, matchContacts, selectedHoldContacts, selectedPossibleMatch]);

  const handleMatchContacts = useCallback(() => {
    if (isCreateNewContact) {
      resolveConflictByCreateNewContact();
    } else {
      resolveConflictByMatchContact();
    }
  }, [
    resolveConflictByCreateNewContact,
    isCreateNewContact,
    resolveConflictByMatchContact,
  ]);

  const handleIgnoreContact = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("id", selectedHoldContacts.id);
    ignoreHoldContacts(formData);
  }, [ignoreHoldContacts, selectedHoldContacts.id]);

  const onClickedIgnore = useCallback(
    (contact) => {
      const formData = formDataWithApiKey();
      if (!doNotShowConfirmationModal) {
        const ignoreContactModalContent = `Are you sure you want to ignore '${contact?.email}' from syncing to the corresponding CCB group?`;
        setOpenIgnoreContactConfirmationModal(true);
        setIgnoreContactModalContent(ignoreContactModalContent);
      } else {
        formData.append("id", contact.id);
        ignoreHoldContacts(formData);
      }
    },
    [doNotShowConfirmationModal, ignoreHoldContacts]
  );

  const onClickedIgnoreAll = useCallback(() => {
    const modalContent = `Are you sure you want to ignore all of ${holdContacts?.length} conflicts from syncing to the corresponding CCB group? Please tick the checkbox to proceed with this mass action.`;
    setOpenIgnoreAllContactConfirmationModal(true);
    setIgnoreAllConfirmationModalContent(modalContent);
  }, [holdContacts]);

  const handleDonotShowConfirmationModal = useCallback((event) => {
    setDoNotShowConfirmationModal(event.target.checked);
  }, []);

  const handleIgnoreAllContacts = useCallback(() => {
    const formData = formDataWithApiKey();
    ignoreAllHoldContacts(formData);
  }, [ignoreAllHoldContacts]);

  const renderIgnoreBtn = useCallback(() => {
    return (
      <ButtonWithSpinner
        loading={ignoreHoldContactsStatus === "loading"}
        onClick={() => onClickedIgnore(selectedHoldContacts)}
        className="border-warning-btn w-15 h-40px mr-4"
      >
        Ignore
      </ButtonWithSpinner>
    );
  }, [ignoreHoldContactsStatus, onClickedIgnore, selectedHoldContacts]);

  const ignoreAllButton = useCallback(() => {
    return (
      <ButtonWithSpinner
        loading={ignoreAllHoldContactsStatus === "loading"}
        onClick={onClickedIgnoreAll}
        className="border-warning-btn w-15 h-40px mr-4"
      >
        Ignore All
      </ButtonWithSpinner>
    );
  }, [ignoreAllHoldContactsStatus, onClickedIgnoreAll]);

  const renderFooterButtons = useCallback(() => {
    if (currentStep === 1) {
      return (
        <div
          className={`w-100 d-flex align-items-center ${
            ignoredEmails?.length
              ? "justify-content-between"
              : "justify-content-end"
          }`}
        >
          {Boolean(ignoredEmails?.length) && (
            <Typography variant="caption">
              This list is not showing the {ignoredEmails?.length} conflicts you
              had ignored. You can{" "}
              <Link to="/tools/ignored-emails" className="view-them-link">
                View Them Here
              </Link>
            </Typography>
          )}
          <ButtonWithSpinner
            onClick={onClickNext}
            className="bg-blue w-15 h-40px"
            disabled={lodash.isEmpty(selectedConflict)}
          >
            Next
          </ButtonWithSpinner>
        </div>
      );
    } else if (currentStep === 2) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-between">
          <ButtonWithSpinner
            onClick={onClickBack}
            className="border-blue-btn w-15 h-40px"
            disabled={csvLoading}
          >
            Back
          </ButtonWithSpinner>
          <div className="w-100 flexer-end">
            {ignoreAllButton()}
            <ButtonWithSpinner
              onClick={onClickNext}
              className="bg-blue w-15 h-40px"
              disabled={lodash.isEmpty(selectedHoldContacts) || csvLoading}
            >
              Next
            </ButtonWithSpinner>
          </div>
        </div>
      );
    } else if (currentStep === 3) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-between">
          <ButtonWithSpinner
            onClick={onClickBack}
            className="border-blue-btn w-15 h-40px"
          >
            Back
          </ButtonWithSpinner>
          <div className="w-100 flexer-end">
            {renderIgnoreBtn()}
            <ButtonWithSpinner
              onClick={() => {
                onClickNext();
                setIsCreateNewContact(false);
              }}
              className="bg-blue w-15 h-40px"
              disabled={lodash.isEmpty(selectedPossibleMatch)}
            >
              Next
            </ButtonWithSpinner>
          </div>
        </div>
      );
    } else if (currentStep === 4) {
      return (
        <div className="w-100 d-flex align-items-center justify-content-between">
          <ButtonWithSpinner
            onClick={onClickBack}
            className="border-blue-btn w-15 h-40px"
          >
            Back
          </ButtonWithSpinner>
          <div className="w-100 flexer-end">
            {renderIgnoreBtn()}
            <ButtonWithSpinner
              className="bg-yellow w-15 h-40px"
              loading={matchContactsStatus === "loading"}
              onClick={handleMatchContacts}
              disabled={matchContactsStatus === "loading"}
            >
              Merge
            </ButtonWithSpinner>
          </div>
        </div>
      );
    }
  }, [
    currentStep,
    handleMatchContacts,
    matchContactsStatus,
    onClickBack,
    onClickNext,
    selectedConflict,
    selectedHoldContacts,
    selectedPossibleMatch,
    ignoreAllButton,
    ignoredEmails,
    renderIgnoreBtn,
    csvLoading,
  ]);

  return (
    <>
      <Dialog
        open={true}
        onClose={toggleModal}
        fullWidth={true}
        maxWidth={"xl"}
        classes={{
          paperFullWidth: "resolve-conflict-mobile-wrapper",
        }}
      >
        <DialogTitle
          className={`${currentStep === 2 ? "" : "flexer-start"} ${
            currentStep === 3 ? "pb-0" : ""
          }`}
          id="alert-dialog-title"
        >
          <div
            className={`${
              currentStep === 2 ? "flexer-space-between w-100" : ""
            }`}
          >
            <span className="modal-main-heading">
              Resolve potential Conflict(s)
            </span>
            {currentStep === 2 && (
              <div>
                <span
                  className="link-text export-hold-contact-link"
                  onClick={downloadHoldContactsAsCsv}
                >
                  {csvLoading ? (
                    <span>
                      <CircularProgress size={20} /> Generating CSV...
                    </span>
                  ) : (
                    "Export list"
                  )}
                </span>
                <CSVLink
                  target="_blank"
                  ref={csvRef}
                  filename={"contact-list.csv"}
                  className="d-none"
                  data={allHoldContacts}
                  headers={headers}
                ></CSVLink>
              </div>
            )}
          </div>
          <Typography className="text-muted">{getModalSubheading()}</Typography>
          <Close onClick={toggleModal} className="close-icon" />
        </DialogTitle>
        <DialogContent
          className={`overflow-hidden content-container ${
            [2, 3].includes(currentStep) ? "p-0" : ""
          } ${currentStep === 3 ? "mt-0" : "mt-2"}`}
        >
          {currentStep === 1 && (
            <UnresolveConflictsPairsStepOne
              onSelectConflictResolve={onSelectConflictResolve}
              selectedConflict={selectedConflict}
            />
          )}
          {currentStep === 2 && (
            <HoldContactsStepTwo
              setSelectedHoldContacts={setSelectedHoldContacts}
              selectedHoldContacts={selectedHoldContacts}
              selectedConflict={selectedConflict}
              onClickedIgnore={onClickedIgnore}
            />
          )}
          {currentStep === 3 && (
            <PossibleMatchesStepThree
              selectedHoldContacts={selectedHoldContacts}
              selectedPossibleMatch={selectedPossibleMatch}
              setSelectedPossibleMatch={setSelectedPossibleMatch}
              onClickedCreateNewContact={onClickedCreateNewContact}
            />
          )}
          {currentStep === 4 && (
            <MatchContactsStepFour
              inputFields={inputFields}
              handleChange={handleChange}
              selectedPossibleMatch={selectedPossibleMatch}
              selectedHoldContacts={selectedHoldContacts}
              isCreateNewContact={isCreateNewContact}
              handleRadioBtnChange={handleRadioBtnChange}
            />
          )}
        </DialogContent>
        <DialogActions className="pl-5 pr-5 mt-5 mb-5 w-100">
          {renderFooterButtons()}
        </DialogActions>
        {openIgnoreContactConfirmationModal && (
          <ConfirmationModal
            open={openIgnoreContactConfirmationModal}
            handleClose={closeIgnoreContactModal}
            modalHeading={`Ignore ${selectedHoldContacts.email || "Unknown"}`}
            modalContent={ignoreContactModalContent}
            btnLabel={"Ignore"}
            btnClassName="w-30 mr-3 bg-yellow"
            onClick={handleIgnoreContact}
            disabled={ignoreHoldContactsStatus === "loading"}
            loading={ignoreHoldContactsStatus === "loading"}
            handleDonotShowConfirmationModal={handleDonotShowConfirmationModal}
            doNotShowModal={doNotShowConfirmationModal}
          />
        )}
        {openIgnoreAllContactConfirmationModal && (
          <ConfirmationModal
            open={openIgnoreAllContactConfirmationModal}
            handleClose={() => setOpenIgnoreAllContactConfirmationModal(false)}
            modalHeading={`Ignore All?`}
            modalContent={ignoreAllConfirmationModalContent}
            btnLabel={"Ignore"}
            btnClassName="w-30 mr-3 bg-yellow"
            preliminaryActionLabel={`Yes, I agree to ignore all ${holdContacts?.length} conflicts`}
            hasPreliminaryAction={true}
            onClick={handleIgnoreAllContacts}
            loading={ignoreAllHoldContactsStatus === "loading"}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    unresolveConflictsPairsStatus:
      store.integrations.unresolveConflictsPairsStatus,
    matchContactsStatus: store.integrations.matchContactsStatus,
    ignoreHoldContactsStatus: store.integrations.ignoreHoldContactsStatus,
    holdContacts: store.integrations.holdContacts,
    ignoredEmails: store.tools.ignoredEmails,
    ignoreAllHoldContactsStatus: store.integrations.ignoreAllHoldContactsStatus,
    errorMessage: store.integrations.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    matchContacts: (formData) => dispatch(matchContacts(formData)),
    ignoreHoldContacts: (formData) => dispatch(ignoreHoldContacts(formData)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearMatchContactStatus: () => dispatch(clearMatchContactStatus()),
    clearIgnoreAllHoldContactStatus: () =>
      dispatch(clearIgnoreAllHoldContactStatus()),
    clearIgnoreHoldContactStatus: () =>
      dispatch(clearIgnoreHoldContactStatus()),
    getWarnings: (data) => dispatch(getWarnings(data)),
    getHoldContacts: (formData) => dispatch(getHoldContacts(formData)),
    getIgnoredEmails: (data) => dispatch(getIgnoredEmails(data)),
    ignoreAllHoldContacts: (data) => dispatch(ignoreAllHoldContacts(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnresolveConflictsModal);
