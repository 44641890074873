import React from "react";
import PropTypes from "prop-types";

const TableHeaderCell = (props) => {
  const { text, style, className, isFlex, innerClassName } = props;

  return (
    <th style={style} className={className}>
      <div
        className={`${
          isFlex ? "d-flex flex-nowrap" : ""
        } align-items-center text-nowrap ${innerClassName}`}
      >
        <span>{text}</span>
      </div>
    </th>
  );
};

TableHeaderCell.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  isFlex: PropTypes.bool,
  innerClassName: PropTypes.string,
};

TableHeaderCell.defaultProps = {
  style: {},
  className: "",
  isFlex: true,
  innerClassName: "",
};

export default TableHeaderCell;
