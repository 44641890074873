import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import CardInfo from "../../../../HelperComponents/CardInfo";
import ListGroupItem from "../../../ListGroupItem/ListGroupItem";

const CreateNewMergeContact = (props) => {
  const { handleChange, selectedHoldContacts, inputFields } = props;
  return (
    <div className="w-100 flexer">
      <CardInfo
        className="w-40 no-right-border-radius pb-5"
        headerClassName={"gray-bg-card-header no-right-border-radius"}
        title="Current"
      >
        <div className="list-group">
          <ListGroupItem
            label="First Name"
            currentValueToShow={selectedHoldContacts?.firstName || ""}
            withEditMode={false}
          />
          <ListGroupItem
            label="Last Name"
            currentValueToShow={selectedHoldContacts?.lastName || ""}
            withEditMode={false}
            className="mt-1"
          />
          <ListGroupItem
            label="Email"
            currentValueToShow={selectedHoldContacts?.email || ""}
            withEditMode={false}
            className="mt-1"
          />
        </div>
      </CardInfo>
      <CardInfo
        className="w-60 no-left-border-radius pb-5"
        headerClassName={"blue-bg-card-header no-left-border-radius"}
        title="Create New"
      >
        <div className="list-group">
          <TextField
            required
            id="standard-required"
            label="First Name"
            value={inputFields["firstName"].value}
            variant="standard"
            onChange={handleChange}
            name="firstName"
          />
          <TextField
            required
            id="standard-required"
            label="Last Name"
            defaultValue="Hello World"
            variant="standard"
            className="mt-3"
            onChange={handleChange}
            value={inputFields["lastName"].value}
            name="lastName"
          />
          <TextField
            required
            id="standard-required"
            label="Email"
            defaultValue="Hello World"
            variant="standard"
            type="email"
            className="mt-3"
            onChange={handleChange}
            value={inputFields["email"].value}
            name="email"
          />
        </div>
      </CardInfo>
    </div>
  );
};

CreateNewMergeContact.propType = {
  selectedHoldContacts: PropTypes.object,
  inputFields: PropTypes.object,
};

CreateNewMergeContact.defaultProps = {
  selectedHoldContacts: {},
  inputFields: {},
};

export default CreateNewMergeContact;
