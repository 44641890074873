import React from "react";
import SecongLogo from "../../assets/img/site-logo-signup.png";

const SideScreenBanner = () => {
  return (
    <div className="auth-banner flexer">
      <img src={SecongLogo} alt="logo" width={400} />
    </div>
  );
};

export default SideScreenBanner;
