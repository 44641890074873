import React from "react";
import PropTypes from "prop-types";

const ButtonWithSpinner = (props) => {
  const {
    onClick,
    disabled,
    loading,
    children,
    className,
    bootstrapButtonClassName,
    type,
  } = props;

  return (
    <button
      type={type}
      className={`btn ${bootstrapButtonClassName} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      <span className="m-2">{children}</span>
    </button>
  );
};

ButtonWithSpinner.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bootstrapButtonClassName: PropTypes.string,
  type: PropTypes.string,
};

ButtonWithSpinner.defaultProps = {
  disabled: false,
  loading: false,
  className: "",
  bootstrapButtonClassName: "btn-primary",
  type: "button",
};

export default ButtonWithSpinner;
