import React, { useMemo, useCallback, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  ArrowForward,
  Close,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons";
import { formDataWithApiKey, getValueFromArrayOfKeys } from "../../../helpers";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import { CCB_TO_MC, MC_TO_CCB } from "../../../utils/integrationsConstants";
import useIntegrationStore from "../../../store/reduxStore/integrationsStore";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const RemoveSyncPairConfirmationModal = (props) => {
  const {
    // Component props
    open,
    handleClose,
    pairToRemove,
    loading,
    currentSync,
  } = props;
  const [unsyncMcContact, setUnsyncMcContact] = useState(false);
  const [isDeleteMcGroupOrTag, setIsDeleteMcGroupOrTag] = useState(false);
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
  const breakpoint = useBreakpoint();

  // Redux store
  const { removeCcbToMcSyncPair, removeMcToCcbSyncPair } =
    useIntegrationStore();

  const getCcbCampuses = useMemo(() => {
    if (pairToRemove?.searchId) {
      return "Saved Search";
    } else {
      return getValueFromArrayOfKeys(pairToRemove, ["campusName", "listName"]);
    }
  }, [pairToRemove]);

  const renderMailchimpGroupAndTag = useMemo(() => {
    if (pairToRemove.sync_type === 1) {
      return Object.values(pairToRemove.mcTags).join();
    } else {
      return getValueFromArrayOfKeys(pairToRemove, ["mcInterestGroupName"]);
    }
  }, [pairToRemove]);

  const handleRemoveSyncPair = useCallback(() => {
    const formData = formDataWithApiKey();
    if (currentSync === CCB_TO_MC) {
      const unsync = unsyncMcContact ? 1 : 0;
      const isDeleteGroup = isDeleteMcGroupOrTag ? 1 : 0;
      if (pairToRemove?.searchId) {
        formData.append("id", pairToRemove.id);
        formData.append("unsync", unsync);
        formData.append("isDeleteGroup", isDeleteGroup);
        formData.append("type", "search");
      } else if (pairToRemove?.processId) {
        formData.append("id", pairToRemove.id);
        formData.append("unsync", unsync);
        formData.append("isDeleteGroup", isDeleteGroup);
        formData.append("type", "process_queue");
      } else {
        formData.append("id", pairToRemove.id);
        formData.append("unsync", unsync);
        formData.append("isDeleteGroup", isDeleteGroup);
        formData.append("type", "group");
      }
      removeCcbToMcSyncPair(formData, false, pairToRemove.id);
    } else if (currentSync === MC_TO_CCB) {
      formData.append("id", pairToRemove.id);
      removeMcToCcbSyncPair(formData, false);
    }
  }, [
    currentSync,
    pairToRemove,
    removeCcbToMcSyncPair,
    removeMcToCcbSyncPair,
    unsyncMcContact,
    isDeleteMcGroupOrTag,
  ]);

  const onChangeUnsyncMcContact = useCallback(({ target }) => {
    setUnsyncMcContact(target.checked);
  }, []);

  const onChangeIsRemoveMcGroup = useCallback(({ target }) => {
    setIsDeleteMcGroupOrTag(target.checked);
  }, []);

  const onClickedAdvanceSettings = useCallback(() => {
    setShowAdvanceSettings(!showAdvanceSettings);
  }, [showAdvanceSettings]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0 vw-100" : "",
      }}
    >
      <DialogTitle className="flexer-space-between" id="alert-dialog-title">
        <span className="modal-main-heading">Un-Sync Pair?</span>
        <Close onClick={handleClose} className="close-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to Un-Sync the following pair?
          <div className="remove-pair-container mt-4">
            {getCcbCampuses} (
            {getValueFromArrayOfKeys(pairToRemove, [
              "name",
              "ccbGroupName",
              "listGroupName",
              "queueName",
            ]) || "Unknown"}
            ) <ArrowForward color={"white"} fontSize={"default"} />{" "}
            {getValueFromArrayOfKeys(pairToRemove, ["mailchimpAudience"]) ||
              "Unknwon"}{" "}
            ({renderMailchimpGroupAndTag || "Unknown"})
          </div>
        </DialogContentText>
        {currentSync === CCB_TO_MC && Number(pairToRemove?.isNew) === 0 && (
          <>
            <span
              onClick={onClickedAdvanceSettings}
              className="advance-settings-text"
            >
              Advanced Settings{" "}
              {showAdvanceSettings ? (
                <ExpandLess htmlColor="#2db8e8" />
              ) : (
                <ExpandMore htmlColor="#2db8e8" />
              )}
            </span>
            {showAdvanceSettings && (
              <>
                <div className="flexer-start mb-3 mt-2">
                  <Checkbox
                    checked={isDeleteMcGroupOrTag}
                    onChange={onChangeIsRemoveMcGroup}
                    style={{ color: "#2db8e8" }}
                    className="p-0 pr-1"
                  />
                  <DialogContentText className="m-0">
                    <span className="warning-text">Delete</span> the Mailchimp{" "}
                    <b>
                      {Number(pairToRemove?.sync_type) === 1
                        ? pairToRemove.mcTags?.join()
                        : pairToRemove?.mcInterestGroupName}
                    </b>{" "}
                    {Number(pairToRemove?.sync_type) === 1 ? "Tags" : "Group"}
                  </DialogContentText>
                </div>
                <div className="flexer-start">
                  <Checkbox
                    checked={unsyncMcContact}
                    onChange={onChangeUnsyncMcContact}
                    style={{ color: "#2db8e8" }}
                    className="p-0 pr-1"
                  />
                  <DialogContentText className="m-0">
                    <span className="warning-text">Archive</span> the matching
                    Mailchimp synced contacts from the{" "}
                    <b>
                      {Number(pairToRemove?.sync_type) === 1
                        ? pairToRemove.mcTags?.join()
                        : pairToRemove?.mcInterestGroupName}
                    </b>{" "}
                    {Number(pairToRemove?.sync_type) === 1 ? "Tags" : "Group"}
                  </DialogContentText>
                </div>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className="mb-3 mr-3">
        <ButtonWithSpinner
          onClick={handleRemoveSyncPair}
          className="bg-yellow"
          loading={loading}
          disabled={loading}
        >
          Confirm
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveSyncPairConfirmationModal;
