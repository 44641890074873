import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "../../styles/components/dashboard/dashboard-main.scss";

const VideoView = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="flexer" onClick={handleClickOpen}>
        {props.toggler}
      </span>
      <Dialog
        className="video-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          root: "video-modal",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent className="modal-content">
          <div className="video-wrapper">
            <iframe
              src="https://player.vimeo.com/video/704394971?app_id=122963"
              width="100%"
              height="100%"
              frameborder="0"
              allow="autoplay; fullscreen"
              title="welcome video"
              loading="lazy"
              allowfullscreen
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoView;
