import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as StarterPlan } from "../assets/icons/plans/starter-plan.svg";
import { ReactComponent as MediumPlan } from "../assets/icons/plans/medium-plan.svg";
import { ReactComponent as ProPlan } from "../assets/icons/plans/pro-plan.svg";

const SubscriptionPlanTags = (props) => {
  const { plan, className } = props;

  if (plan === "starter") return <StarterPlan className={className} />;
  if (plan === "medium") return <MediumPlan className={className} />;
  if (plan === "pro") return <ProPlan className={className} />;
  return null;
};

SubscriptionPlanTags.propTypes = {
  plan: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SubscriptionPlanTags.defaultProps = {
  className: "",
};

export default SubscriptionPlanTags;
