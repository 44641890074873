import React, { useEffect } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { ReactComponent as ExpandIconPurpleLight } from "../../assets/icons/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/collapse-icon.svg";
import useGlobalStore from "../../store/reduxStore/globalStore";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import services from "../../services";

const {
  storageManager: { local },
} = services;

const ExpandAndCollapseButton = () => {
  // Redux store
  const { global, toggleSidebarExpanded } = useGlobalStore();
  const { isSidebarExpanded } = global;

  // Routes
  const location = useLocation();

  const sidebarExpandedCondition = useIsSidebarExpanded();

  useEffect(() => {
    if (location.pathname === "/sync") {
      toggleSidebarExpanded(false);
      local.set("isSidebarExpanded", false);
    }
  }, [location, toggleSidebarExpanded]);

  const onClickToggleSidebarExpanded = () => {
    toggleSidebarExpanded(!isSidebarExpanded);
    local.set("isSidebarExpanded", !isSidebarExpanded);
  };

  return (
    <div
      className={clsx("bottom-container-collapse cusror-pointer", {
        minimized: !isSidebarExpanded,
      })}
      onClick={onClickToggleSidebarExpanded}
    >
      {sidebarExpandedCondition ? (
        <span>
          <CollapseIcon height={18} width={18} /> Collapse
        </span>
      ) : (
        <div className="expand-container mr-3">
          <ExpandIconPurpleLight
            height={18}
            width={18}
            className={clsx({
              "mr-1": isSidebarExpanded,
            })}
          />
          <span>Expand</span>
        </div>
      )}
    </div>
  );
};

export default ExpandAndCollapseButton;
