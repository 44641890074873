import React, { Fragment, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner/ButtonWIthSpinner";
import Loader from "../../../components/Loader/Loader";
import TableInfinityScroll from "../../../components/Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../../components/Table/TableHeader/TableHeader";
import UsersTableBody from "../../../components/AdminComponents/Users/UsersTableBody";
import { getAllUsers } from "../../../store/middlewares/adminMiddlewares/adminUsersMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import UserInfoCard from "../../../components/AdminComponents/Users/UserInfoCard/UserInfoCard";
import services from "../../../services";
import CreateUserModal from "../../../components/modals/CreateUserModal/CreateUserModal";
import { setSnackbarData } from "../../../store/actions/globalAction";
import useIsSidebarExpanded from "../../../utils/hooks/useIsSidebarExpanded";
import "../../../styles/components/more/user-settings.scss";
const {
  storageManager: { local },
} = services;

const cells = [
  {
    text: "Email",
  },
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Ccb Account",
  },
  {
    text: "App version",
  },
  {
    text: "API key",
  },
  {
    text: "Actions",
  },
];

const Users = (props) => {
  const {
    // REDUX FUNCTIONS
    getAllUsers,
    setSnackbarData,

    // Redux props
    users,
    usersStatus,
    usersPage,
    usersLoadedAll,
    usersLoadingMoreStatus,
  } = props;
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    ccbAccount: "",
  });
  const [showProfInfoModal, setShowProfInfoModal] = useState(false);
  const [dataForInfoModal, setDataForInfoModal] = useState({});
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const isSidebarExpanded = useIsSidebarExpanded();

  const closeProfileInfoModal = useCallback(() => {
    setShowProfInfoModal(false);
  }, []);

  const closeCreateUserModal = useCallback(() => {
    setShowCreateUserModal(false);
  }, []);

  const getUsers = useCallback(
    (pageNum) => {
      const formData = formDataWithApiKey();
      formData.append("page", pageNum);
      formData.append("firstName", inputs.firstName);
      formData.append("lastName", inputs.lastName);
      formData.append("email", inputs.email);
      formData.append("ccbAccount", inputs.ccbAccount);
      getAllUsers(formData);
    },
    [
      getAllUsers,
      inputs.ccbAccount,
      inputs.email,
      inputs.firstName,
      inputs.lastName,
    ]
  );

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    formData.append("firstName", "");
    formData.append("lastName", "");
    formData.append("email", "");
    formData.append("ccbAccount", "");
    getAllUsers(formData);
  }, [getAllUsers]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const loadMore = useCallback(() => {
    if (
      !usersLoadedAll &&
      usersStatus !== "loading" &&
      usersStatus !== "error" &&
      users
    ) {
      const pageNum = Number(usersPage) + 1;
      getUsers(pageNum);
    }
  }, [usersLoadedAll, usersStatus, users, usersPage, getUsers]);

  const handleUserSearch = useCallback(() => {
    getUsers(1);
  }, [getUsers]);

  const handleViewClicked = useCallback((data) => {
    setShowProfInfoModal(true);
    setDataForInfoModal(data);
  }, []);

  const handleLoginClicked = useCallback(
    (user) => {
      if (!user.apiKey) {
        return setSnackbarData({
          snackbarSeverity: "error",
          showSnackbar: true,
          snackbarMessage: "API key missing!",
        });
      }
      const adminData = JSON.parse(local.get("user"));
      local.set("admin", JSON.stringify(adminData));
      local.set("token", user.apiKey);
      local.set("user", JSON.stringify(user));
      window.location = "dashboard";
    },
    [setSnackbarData]
  );

  const renderSearchFields = useCallback(() => {
    return (
      <div className="flexer-start">
        <form className="form flexer">
          <fieldset className="mr-4">
            <label htmlFor="first-name">First Name</label>
            <input
              name="firstName"
              onChange={handleChange}
              value={inputs.firstName}
              type="text"
              id="first-name"
            />
          </fieldset>
          <fieldset className="mr-4">
            <label htmlFor="last-name">Last Name</label>
            <input
              name="lastName"
              onChange={handleChange}
              value={inputs.lastName}
              type="text"
              id="last-name"
            />
          </fieldset>
          <fieldset>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              onChange={handleChange}
              value={inputs.email}
              type="text"
              id="email"
            />
          </fieldset>
          <fieldset className="ml-4">
            <label htmlFor="ccbAccount">CCB Account</label>
            <input
              name="ccbAccount"
              onChange={handleChange}
              value={inputs.ccbAccount}
              type="text"
              id="ccbAccount"
            />
          </fieldset>
        </form>
        <ButtonWithSpinner
          onClick={handleUserSearch}
          className="bg-yellow mt-3 ml-4"
        >
          Search
        </ButtonWithSpinner>
      </div>
    );
  }, [
    handleChange,
    handleUserSearch,
    inputs.ccbAccount,
    inputs.email,
    inputs.firstName,
    inputs.lastName,
  ]);

  const loadingMore = usersLoadingMoreStatus === "loading";
  return (
    <Fragment>
      <div
        className={clsx("page-wrapper my-profile", {
          "with-page-sub-menu-expanded": isSidebarExpanded,
        })}
      >
        <div className="desktop-design">
          <div className="flexer-space-between admin-user-header ">
            <Typography className="desktop-title mb-0" component={"h2"}>
              Users
            </Typography>
            <ButtonWithSpinner
              onClick={() => setShowCreateUserModal(true)}
              className="dark-gray-button"
            >
              Add User
            </ButtonWithSpinner>
          </div>
          {renderSearchFields()}
          <div className="user-settings-greetings mt-2">
            {usersStatus === "loading" && !loadingMore ? (
              <div className="d-flex justify-content-center spinner-container">
                <Loader />
              </div>
            ) : (
              <TableInfinityScroll
                loadMore={loadMore}
                page={1}
                fetchFunction={() => {}}
                className="table-with-progress-bar"
              >
                <table className="table">
                  <TableHeader
                    className="user-settings-greetings-table-header"
                    cells={cells}
                  />
                  <UsersTableBody
                    data={users}
                    handleItemClicked={() => {}}
                    isLoading={loadingMore}
                    onViewClick={handleViewClicked}
                    onLoginClick={handleLoginClicked}
                  />
                </table>
              </TableInfinityScroll>
            )}
          </div>
        </div>
      </div>
      {showProfInfoModal && (
        <UserInfoCard
          open={showProfInfoModal}
          toggleModal={closeProfileInfoModal}
          data={dataForInfoModal}
          autoLogin={handleLoginClicked}
        />
      )}
      {showCreateUserModal && (
        <CreateUserModal
          toggleModal={closeCreateUserModal}
          showModal={showCreateUserModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    users: store.admin.users,
    usersStatus: store.admin.usersStatus,
    usersPage: store.admin.usersPage,
    usersLoadedAll: store.admin.usersLoadedAll,
    usersLoadingMoreStatus: store.admin.usersLoadingMoreStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (data) => dispatch(getAllUsers(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
