import React, { useCallback, useEffect, useState } from "react";
import { TextField, Tooltip, Radio } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as CloseSvg } from "../../assets/icons/close-gray.svg";
import SpanWithTooltip from "../SpanWithTooltip/SpanWithTooltip";
import { truncateStringWithDots } from "../../helpers";
import "../../styles/components/list-group-item.scss";

const ListGroupItem = (props) => {
  const {
    label,
    currentValue, // value to be held in this component memory
    currentValueToShow, // value that will be shown on the screen
    apiStatus, // status for showing different statuses (saving, saved, error)
    onSave, // call back when clicking save
    beforeTextFieldElements, // component before the text field
    customEditElements, // custom component that will show when in edit mode
    withEditMode, // show or not the edit button
    emptyCurrentValueToShow, // if the currentValueToShow does not have a value, this will take place
    successCallback, // on editing success callback
    validationFunc, // validation function for validating the data written in the text field
    className,
    customHoverButton, // show different component on hover
    disableEdit, // disable the edit button, but still show it
    onChange,
    onPasteHandler, // callback for on past for text field
    errorMessageForToolTip, // the error that would be show in the error tooltip if the API returns error
    editText, // Change the text of the edit button
    onClickEditButton, // overwrite onclick edit button
    charactersLimit, // limit to show the characters
    onChangeRadioButton, // show radio button or not,
    radioBtnValue, // Value of radio button
    radioBtnChecked, // Checked of radio button
    radioBtnName, // Name of radio button
  } = props;

  const [showEditButton, setShowEditButton] = useState(false);
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (window.innerWidth < 770) {
      setShowEditButton(true);
    }
  }, []);

  useEffect(() => {
    if (currentValue) {
      setTextFieldValue(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    if (apiStatus === "success") {
      setIsOnEditMode(false);
      successCallback();
    }
  }, [apiStatus, successCallback]);

  useEffect(() => {
    if (apiStatus === "error") {
      setTimeout(() => {
        setIsOnEditMode(false);
        successCallback();
      }, 1000);
    }
  }, [apiStatus, successCallback]);

  const onMouseEnter = useCallback(() => {
    if (window.innerWidth > 770) {
      setShowEditButton(true);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    if (window.innerWidth > 770) {
      setShowEditButton(false);
    }
  }, []);

  const renderEditButton = useCallback(() => {
    if (customHoverButton && showEditButton) return customHoverButton;
    else if (withEditMode && showEditButton) {
      let onClick = undefined;
      if (onClickEditButton) {
        onClick = onClickEditButton;
      } else if (!disableEdit) {
        onClick = () => setIsOnEditMode(true);
      }
      return (
        <span
          className={`simple-purple-button-text ml-2 mt-1 edit ${
            disableEdit ? "cursor-not-allowed" : ""
          }`}
          onClick={onClick}
          style={{
            color: "#2e3fad",
            cursor: "pointer",
          }}
        >
          {editText}
        </span>
      );
    }
    return null;
  }, [
    customHoverButton,
    disableEdit,
    editText,
    onClickEditButton,
    showEditButton,
    withEditMode,
  ]);

  const onClickSave = useCallback(() => {
    if (validationFunc) {
      const validationString = validationFunc(textFieldValue);
      // because the validation functions return a string if is valid and empty string if not valid
      if (!validationString) {
        onSave(textFieldValue);
      } else {
        setIsError(true);
        setHelperText(validationString);
      }
    } else {
      onSave(textFieldValue);
    }
  }, [onSave, textFieldValue, validationFunc]);

  const renderSaveButton = useCallback(() => {
    if (apiStatus === "loading") {
      return <span className="edit text-muted">Saving</span>;
    } else if (apiStatus === "success") {
      return <span className="edit text-success">Saved</span>;
    } else if (apiStatus === "error") {
      return (
        <span className="edit text-danger d-flex flex-nowrap text-nowrap align-items-center">
          <SpanWithTooltip title={errorMessageForToolTip}>
            <span className="cursor-pointer">error </span>
          </SpanWithTooltip>
          <CloseSvg
            width={14}
            height={14}
            className="cursor-pointer ml-2"
            onClick={successCallback}
          />
        </span>
      );
    } else {
      return (
        <div className="d-flex flex-nowrap text-nowrap align-items-center">
          <span
            style={{
              color: "#2e3fad",
              cursor: "pointer",
            }}
            className="save edit"
            onClick={onClickSave}
          >
            Save
          </span>
          <CloseSvg
            width={14}
            height={14}
            className="cursor-pointer ml-2"
            onClick={() => setIsOnEditMode(false)}
          />
        </div>
      );
    }
  }, [apiStatus, errorMessageForToolTip, onClickSave, successCallback]);

  const onChangeTextFieldValue = useCallback(
    (event) => {
      setTextFieldValue(event.target.value);
      onChange(event.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    const onPaste = () => {
      onPasteHandler && onPasteHandler();
    };

    document.addEventListener("paste", onPaste);

    return () => document.removeEventListener("paste", onPaste);
  }, [onPasteHandler]);

  const renderEditMode = useCallback(() => {
    return (
      <>
        <div
          className={`d-flex align-items-center ${
            className ? className : "ml-n2"
          }`}
        >
          {customEditElements ? (
            <>
              <div className="mr-2">{beforeTextFieldElements}</div>
              {customEditElements}
            </>
          ) : (
            <>
              <div className="mr-2">{beforeTextFieldElements}</div>
              <TextField
                InputLabelProps={{ shrink: false }}
                // classes={{ root: 'text-field' }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "settings-input",
                  },
                }}
                disabled={apiStatus === "loading"}
                type="text"
                value={textFieldValue}
                onChange={onChangeTextFieldValue}
                error={isError}
                helperText={helperText}
                variant="filled"
              />
            </>
          )}
          <div className="ml-2">{renderSaveButton()}</div>
        </div>
        {customEditElements && isError && (
          <span className="text-danger-smaller">{helperText}</span>
        )}
      </>
    );
  }, [
    apiStatus,
    beforeTextFieldElements,
    className,
    customEditElements,
    helperText,
    isError,
    onChangeTextFieldValue,
    renderSaveButton,
    textFieldValue,
  ]);

  return (
    <li
      className="list-group-item"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div>{label}</div>
      {isOnEditMode ? (
        renderEditMode()
      ) : (
        <>
          {currentValueToShow ? (
            <p className={`list-group-item-text`}>
              <Tooltip
                title={
                  charactersLimit &&
                  currentValueToShow.length >= charactersLimit
                    ? currentValueToShow
                    : ""
                }
                arrow
                placement="top"
              >
                <span>
                  {onChangeRadioButton && (
                    <Radio
                      checked={radioBtnChecked}
                      onChange={onChangeRadioButton}
                      value={radioBtnValue}
                      size="small"
                      name={radioBtnName}
                      className="p-0"
                      style={{ color: "#2DB8E8" }}
                    />
                  )}{" "}
                  {truncateStringWithDots(currentValueToShow, charactersLimit)}
                </span>
              </Tooltip>{" "}
              {renderEditButton()}
            </p>
          ) : (
            <p className="list-group-item-text unknown">
              {emptyCurrentValueToShow} {renderEditButton()}
            </p>
          )}
        </>
      )}
    </li>
  );
};

ListGroupItem.propTypes = {
  label: PropTypes.string.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentValueToShow: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withEditMode: PropTypes.bool,
  emptyCurrentValueToShow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  apiStatus: PropTypes.string,
  onSave: PropTypes.func,
  beforeTextFieldElements: PropTypes.node,
  customEditElements: PropTypes.node,
  successCallback: PropTypes.func,
  validationFunc: PropTypes.func,
  className: PropTypes.string,
  customHoverButton: PropTypes.node,
  disableEdit: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessageForToolTip: PropTypes.string,
  editText: PropTypes.string,
  charactersLimit: PropTypes.number,
  radioBtnChecked: PropTypes.bool,
  radioBtnValue: PropTypes.string,
  onChangeRadioButton: PropTypes.func,
  radioBtnName: PropTypes.string,
};

ListGroupItem.defaultProps = {
  withEditMode: true,
  disableEdit: false,
  currentValueToShow: "",
  emptyCurrentValueToShow: "Unknown",
  onSave: undefined,
  beforeTextFieldElements: undefined,
  customEditElements: undefined,
  currentValue: undefined,
  successCallback: () => {},
  validationFunc: undefined,
  className: "",
  apiStatus: undefined,
  customHoverButton: undefined,
  onChange: () => {},
  errorMessageForToolTip: "",
  editText: "Edit",
  charactersLimit: 0,
  radioBtnChecked: false,
  radioBtnName: "",
  radioBtnValue: "",
};

export default ListGroupItem;
