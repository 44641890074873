import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SpanWithTooltip from "../../../SpanWithTooltip/SpanWithTooltip";
import { CircularProgress } from "@material-ui/core";

const HoldContactsTableRow = (props) => {
  const {
    data,
    onClickRow,
    selectedRow,
    onClickedIgnore,
    ignoreHoldContactId,
    ignoreHoldContactsStatus,
  } = props;
  const handleRowClick = useCallback(() => {
    if (onClickRow) {
      onClickRow(data);
    }
    return null;
  }, [data, onClickRow]);

  return (
    <tr
      onClick={handleRowClick}
      className={selectedRow?.id === data?.id ? "active" : ""}
    >
      <td></td>
      <td>{data.id}</td>
      <td>{data.firstName}</td>
      <td>{data.lastName}</td>
      <td>{data.email}</td>
      <td className="text-danger">
        {ignoreHoldContactsStatus === "loading" &&
        Number(ignoreHoldContactId) === data.id ? (
          <div className="w-100 flexer overflow-hidden">
            <CircularProgress size={20} />
          </div>
        ) : (
          <SpanWithTooltip
            className="ignore-contact"
            onClick={() => onClickedIgnore(data)}
            title="This will ignore this conflict and will not show up in your sync list"
          >
            Ignore
          </SpanWithTooltip>
        )}
      </td>
    </tr>
  );
};

HoldContactsTableRow.propTypes = {
  onClickRow: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
};

HoldContactsTableRow.defaultProps = {
  selectedRow: {},
};

export default HoldContactsTableRow;
