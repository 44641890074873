import { useEffect, useState } from "react";
import { formDataWithApiKey } from "../../helpers";

export const CheckAutoSyncing = (
  autoSyncingStatus,
  checkAutoSyncingStatus,
  setShowAutoSyncConfirmationModal,
  isOnSyncPage,
  clearIntialSync,
  runInitialSync
) => {
  const [isAutoSyncingInProgress, setIsAutoSyncingInProgress] = useState(null);

  useEffect(() => {
    if (isOnSyncPage && !autoSyncingStatus && runInitialSync) {
      const formData = formDataWithApiKey();
      checkAutoSyncingStatus(formData);
    }
  }, [autoSyncingStatus, checkAutoSyncingStatus, isOnSyncPage, runInitialSync]);

  useEffect(() => {
    if (autoSyncingStatus) {
      const syncInterval = setInterval(() => {
        const formData = formDataWithApiKey();
        checkAutoSyncingStatus(formData);
        setIsAutoSyncingInProgress(autoSyncingStatus);
      }, 3000);
      return () => clearInterval(syncInterval);
    } else {
      if (
        isAutoSyncingInProgress &&
        autoSyncingStatus != null &&
        !autoSyncingStatus
      ) {
        setShowAutoSyncConfirmationModal(true);
        clearIntialSync();
      }
      setIsAutoSyncingInProgress(autoSyncingStatus);
    }
  }, [
    autoSyncingStatus,
    checkAutoSyncingStatus,
    clearIntialSync,
    isAutoSyncingInProgress,
    setShowAutoSyncConfirmationModal,
  ]);

  return {
    isAutoSyncingInProgress,
  };
};
