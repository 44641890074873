import MailchimpIcon from "../../../assets/img/mailchimp/mailchimp-small.png";
import {
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
} from "../../../utils/integrationsConstants";

export const toolExceptionModalData = [
  {
    [MAILCHIMP_AUDIENCE]: {
      headerTitle: "MailChimp Audience",
      icon: MailchimpIcon,
      placeholder: "Select Audience",
      syncType: 0,
      options: [],
      loadingText: "No Options",
      displayKey: ["name"],
      selectedId: null,
      value: "",
      tags: [],
      identity: MAILCHIMP_AUDIENCE,
      isExistingPair: false,
      id: null,
    },
    [MAILCHIMP_GROUPS]: {
      headerTitle: "Audience Tag / Groups",
      icon: MailchimpIcon,
      placeholder: "Select MC Groups",
      syncType: 0,
      options: [],
      loadingText: "No Options",
      displayKey: ["groupName"],
      selectedId: null,
      value: "",
      tags: [],
      identity: MAILCHIMP_GROUPS,
      isExistingPair: false,
      id: null,
    },
  },
];
