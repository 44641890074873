import React, { useCallback, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Close, Check } from "@material-ui/icons";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import "../../../styles/components/integrations/remove-sync-pair.scss";
import "../../../styles/components/services/log-list.scss";

const RenderItem = ({ item, text }) => {
  return (
    <div className="mt-2">
      {Number(item) === 1 ? (
        <Check fontSize="small" htmlColor={"#22c1c3"} />
      ) : (
        <Close fontSize="small" color="gray" />
      )}{" "}
      <span className={`ml-2 ${Number(item) === 1 ? "text-dark" : ""}`}>
        {text}
      </span>
    </div>
  );
};

const MemberShipFiltersModal = (props) => {
  const { open, handleClose, data } = props;
  const history = useHistory();

  const getMembershipFilters = useMemo(() => {
    const filterData = [
      {
        id: "isIncludeJoinGroup",
        title: "Requesting to join the Group",
      },
      {
        id: "isIncludeLeads",
        title: "Group Leader/Assistant Leader",
      },
      {
        id: "isIncludeMember",
        title: "Group Member",
      },
      {
        id: "isIncludeInvitedToGroup",
        title: "Invited to the Group",
      },
    ];
    const filters = filterData.map((filter) => {
      return {
        ...filter,
        value: data[filter?.id],
      };
    });
    return filters.sort((a) => {
      if (Number(a.value) === 1) {
        return -1;
      }
      return 0;
    });
  }, [data]);

  const onClickedManage = useCallback(() => {
    history.push("/tools");
  }, [history]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="log-membership-filter-modal"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className="log-membership-filter-modal-container">
        <div>
          <Close className="close-icon mb-2" onClick={handleClose} />
          <h6 className="log-membership-filter-modal-title">
            For this sync logs the group membership types were filter
          </h6>
        </div>
        <DialogContent>
          <DialogContentText>
            <div>
              {getMembershipFilters &&
                Array.isArray(getMembershipFilters) &&
                Boolean(getMembershipFilters?.length) &&
                getMembershipFilters.map((filter) => (
                  <RenderItem text={filter?.title} item={filter?.value} />
                ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flexer-column">
          <div className={`d-flex justify-content-center w-100`}>
            <ButtonWithSpinner
              className={"bg-yellow"}
              onClick={onClickedManage}
            >
              Manage
            </ButtonWithSpinner>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default MemberShipFiltersModal;
