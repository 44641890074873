import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as CloseWhiteSvg } from "../../assets/img/icons/close-white.svg";
import { ReactComponent as CloseGreySvg } from "../../assets/img/icons/close-grey.svg";
import { ReactComponent as RoundedExclamationWhiteSvg } from "../../assets/img/icons/show-info-white.svg";
import { ReactComponent as RoundedExclamationGraySvg } from "../../assets/img/icons/show-info-gray.svg";
import { CircularProgress } from "@material-ui/core";
import "../../styles/components/notification-banner.scss";

const TopNotficationBanner = (props) => {
  const {
    // Component props
    text,
    buttonText,
    onClickButton,
    withMargin,
    onClickClose,
    color,
    className,
    containerClassName,
    hasExclamationIcon,
    isLoading,
  } = props;
  const withWhiteSvg = useMemo(
    () => color === "blue" || color === "red",
    [color]
  );
  const withGreySvg = useMemo(() => color === "yellow", [color]);

  const renderCloseIcon = useMemo(() => {
    if (onClickClose) {
      if (withWhiteSvg) {
        return (
          <CloseWhiteSvg
            width={11}
            height={11}
            className="close-notification-button"
            onClick={onClickClose}
          />
        );
      } else if (withGreySvg) {
        return (
          <CloseGreySvg
            width={11}
            height={11}
            className="close-notification-button"
            onClick={onClickClose}
          />
        );
      }
    }
  }, [onClickClose, withGreySvg, withWhiteSvg]);

  const renderExclamationIcon = useMemo(() => {
    if (hasExclamationIcon) {
      if (withWhiteSvg) {
        return (
          <RoundedExclamationWhiteSvg width={23} height={23} className="mr-2" />
        );
      } else if (withGreySvg) {
        return (
          <RoundedExclamationGraySvg width={23} height={23} className="mr-2" />
        );
      }
    }
  }, [hasExclamationIcon, withGreySvg, withWhiteSvg]);

  return (
    <div
      className={`notification-banner-container ${
        withMargin ? "with-margin" : ""
      } ${color} ${className}`}
    >
      {renderExclamationIcon}
      <div className={`${containerClassName} flex-wrap`}>
        {text}
        {isLoading ? (
          <CircularProgress color="inherit" size={20} className="ml-1" />
        ) : (
          <span
            className="action-button ml-1 cursor-pointer font-bold"
            onClick={onClickButton}
          >
            {buttonText}
          </span>
        )}
      </div>
      {renderCloseIcon}
    </div>
  );
};

TopNotficationBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  onClickButton: PropTypes.func,
  withMargin: PropTypes.bool,
  onClickClose: PropTypes.func,
  color: PropTypes.oneOf(["blue", "yellow", "red"]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hasExclamationIcon: PropTypes.bool,
  onlyShowOnDashboard: PropTypes.bool,
  isLoading: PropTypes.bool,
};

TopNotficationBanner.defaultProps = {
  withMargin: true,
  color: "blue",
  className: "",
  containerClassName: "d-flex align-items-center",
  hasExclamationIcon: false,
  onlyShowOnDashboard: false,
  isLoading: false,
};

export default withRouter(TopNotficationBanner);
