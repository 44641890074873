import {
  CCB_CAMPUSES,
  MAILCHIMP_GROUPS,
} from "../../../utils/integrationsConstants";

export const filters = {
  [CCB_CAMPUSES]: [
    {
      key: "Campuses",
      active: true,
      id: "campuses",
    },
    {
      key: "Saved Searches",
      active: true,
      id: "savedSearches",
    },
  ],
  [MAILCHIMP_GROUPS]: [
    {
      key: "Tags",
      active: true,
      id: "tags",
    },
    {
      key: "Groups",
      active: true,
      id: "groups",
    },
  ],
};
