import React from "react";
import PropType from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";

const MaterialButton = (props) => {
  const {
    title,
    className,
    buttonStyle,
    icon,
    disabled,
    onClick,
    loading,
    labelClassName,
  } = props;
  return (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      type="submit"
      className={className}
      disabled={disabled}
      onClick={onClick}
      classes={{
        root: "btn",
        label: labelClassName,
        containedPrimary: buttonStyle,
      }}
    >
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <>
          {icon} {title}
        </>
      )}
    </Button>
  );
};

MaterialButton.defaultProps = {
  buttonStyle: "contained-primary",
  disabled: false,
  loading: false,
  onClick: () => {},
  labelClassName: "label",
};

MaterialButton.propTypes = {
  title: PropType.string,
  buttonStyle: PropType.string,
  labelClassName: PropType.string,
  disabled: PropType.bool,
  onClick: PropType.func,
  loading: PropType.bool,
};

export default MaterialButton;
