import React from "react";
import PropTypes from "prop-types";
import HoldContactsTableRow from "./HoldContactsTableRow";

const HoldContactsTableBody = (props) => {
  const {
    data,
    onClickRow,
    selectedRow,
    onClickedIgnore,
    ignoreHoldContactId,
    ignoreHoldContactsStatus,
  } = props;

  return (
    <tbody className="user-settings-greetings-table-body">
      {data.map((elementData, index) => (
        <HoldContactsTableRow
          data={elementData}
          onClickRow={onClickRow}
          selectedRow={selectedRow}
          index={index}
          onClickedIgnore={onClickedIgnore}
          ignoreHoldContactId={ignoreHoldContactId}
          ignoreHoldContactsStatus={ignoreHoldContactsStatus}
        />
      ))}
    </tbody>
  );
};

HoldContactsTableBody.propTypes = {
  data: PropTypes.array,
  onClickRow: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
};

HoldContactsTableBody.defaultProps = {
  data: [],
  selectedRow: {},
};

export default HoldContactsTableBody;
