import { ACTION_TYPES } from "./types";

export const setShowSearchFor = (payload) => ({
  type: ACTION_TYPES.SET_SHOW_SEARCH_FOR,
  payload,
});

export const changeSyncType = (payload) => ({
  type: ACTION_TYPES.CHANGE_SYNC_TYPE,
  payload,
});

export const toggleSwitchCcbToMc = (payload) => ({
  type: ACTION_TYPES.TOGGLE_SWITCH_CCB_TO_MC,
  payload,
});

export const toggleSwitchMcToCcb = (payload) => ({
  type: ACTION_TYPES.TOGGLE_SWITCH_MC_TO_CCB,
  payload,
});

export const toggleRemoveSyncPairModal = (payload) => ({
  type: ACTION_TYPES.SHOW_REMOVE_SYNC_PAIR_MODAL,
  payload,
});

export const toggleSyncConfirmationModal = (payload) => ({
  type: ACTION_TYPES.SHOW_SYNC_CONFIRMATION_MODAL,
  payload,
});

export const toggleSyncNewPairsConfirmationModal = (payload, skipRoue) => ({
  type: ACTION_TYPES.SHOW_NEW_SYNC_PAIRS_CONFIRMATION_MODAL,
  payload,
  skipRoue,
});

export const handleSyncingProgressVisibility = (payload) => ({
  type: ACTION_TYPES.SHOW_SYNCING_PROGRESS,
  payload,
});

export const toggleAddNewSyncPairModal = (payload) => ({
  type: ACTION_TYPES.SHOW_ADD_NEW_SYNC_PAIRS_MODAL,
  payload,
});

export const setHasNotSyncedPairs = (payload) => ({
  type: ACTION_TYPES.SET_HAS_NOT_SYNCED_PAIRS,
  payload,
});

export const clearSyncManualStatus = () => ({
  type: ACTION_TYPES.SYNC_MANUAL_CLEAR,
});

export const clearIntegrationPageState = () => ({
  type: ACTION_TYPES.CLEAR_INTEGRATION_PAGE_STATE,
});

export const clearRemoveCcbToMcPair = () => ({
  type: ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR_CLEAR,
});

export const clearRemoveMcToCcbPair = () => ({
  type: ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR_CLEAR,
});

export const clearMailchimpAudience = () => ({
  type: ACTION_TYPES.GET_MAILCHIMP_AUDIENCE_CLEAR,
});

export const resetRefreshListStatus = () => ({
  type: ACTION_TYPES.REFRESH_LIST_CLEAR,
});

export const clearMatchContactStatus = () => ({
  type: ACTION_TYPES.MATCH_CONTACTS_CLEAR,
});

export const clearIgnoreHoldContactStatus = () => ({
  type: ACTION_TYPES.IGNORE_HOLD_CONTACTS_CLEAR,
});

export const clearStopSyncStatus = () => ({
  type: ACTION_TYPES.STOP_SYNC_CLEAR,
});

export const clearEnableTwoWaySync = () => ({
  type: ACTION_TYPES.ENABLE_TWO_WAY_SYNCING_CLEAR,
});

export const clearIgnoreAllHoldContactStatus = () => ({
  type: ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS_CLEAR,
});

export const clearSaveMcToCcbPairStatus = () => ({
  type: ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR_CLEAR,
});

export const clearSaveCcbToMcPairsSataus = () => ({
  type: ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR_CLEAR,
});

export const clearRemoveMultipleMcToCcbPairStatus = () => ({
  type: ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR_CLEAR,
});

export const clearRemoveMultipleCcbToMcPairStatus = () => ({
  type: ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR_CLEAR,
});

export const clearDisableTwoWaySync = () => ({
  type: ACTION_TYPES.DISABLE_TWO_WAY_SYNCING_CLEAR,
});

export const clearAddToQueue = () => ({
  type: ACTION_TYPES.ADD_TO_QUEUE_CLEAR,
});

export const setMcToCcbDisableInactiveFor = (payload) => ({
  type: ACTION_TYPES.MC_TO_CCB_DISABLE_INACTIVE_REMOVE_FOR,
  payload,
});

export const clearHasSavedSearchPermissionStatus = () => ({
  type: ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS_CLEAR,
});

export const setIntegrationPairForEdit = (integrationPair) => ({
  type: ACTION_TYPES.SET_INTEGRATION_PAIR_FOR_EDIT,
  payload: integrationPair,
});

export const removeCcbToMcSyncPairSuccess = (data) => ({
  type: ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR_SUCCESS,
  ...data,
});

export const removeMcToCcbSyncPairSuccess = (data) => ({
  type: ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR_SUCCESS,
  ...data,
});
