import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { withRouter } from "react-router";

const LogBreadcrumb = (props) => {
  const { history, breadcrumbs, isBreadcrumbEnable, breadcrumbsFromRoute } =
    props;

  const handleNavigate = useCallback(
    (route, index) => {
      if (index + 1 === breadcrumbs.length) return null;
      history.push(route, {
        isBreadcrumbEnable,
        breadcrumbsFromRoute: breadcrumbsFromRoute?.slice(0, -1),
      });
    },
    [breadcrumbs, breadcrumbsFromRoute, history, isBreadcrumbEnable]
  );

  return (
    <div role="presentation" className="mt-4 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          onClick={() => history.push("/logs")}
          color="inherit"
          className="cursor-pointer breadcrumb-link"
        >
          Logs
        </Typography>
        {Boolean(breadcrumbs.length) &&
          breadcrumbs.map((breadcrumb, index) => (
            <Typography
              onClick={() => handleNavigate(breadcrumb.route, index)}
              color="inherit"
              className="cursor-pointer breadcrumb-link"
            >
              {breadcrumb.text}
            </Typography>
          ))}
      </Breadcrumbs>
    </div>
  );
};

LogBreadcrumb.defaultProps = {
  breadcrumbsFromRoute: [],
  isBreadcrumbEnable: false,
};

LogBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  breadcrumbsFromRoute: PropTypes.array,
  isBreadcrumbEnable: PropTypes.bool,
};

export default withRouter(LogBreadcrumb);
