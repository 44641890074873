import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import validator from "validator";
import { Button } from "@material-ui/core";
import SideScreenBanner from "../../../components/SideScreenBanner/SideScreenBanner";
import InputField from "../../../components/InputField/InputField";
import LogoWithHeading from "../../../components/LogoWithHeading/LogoWithHeading";
import MaterialButton from "../../../components/ MaterialButton/MaterialButton";
import { validateEmptyFields } from "../../../helpers";
import api from "../../../services/api";
import "../../../styles/views/login.scss";
import services from "../../../services";
import NotficationBanner from "../../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../../utils/notifications";
import { globalLoading } from "../../../store/actions/globalAction";
import Loader from "../../../components/Loader/Loader";
const {
  storageManager: { local },
} = services;

const ForgotPassword = (props) => {
  const { history, isLoggedIn, setLoading, loading } = props;
  const [inputs, setInputs] = useState({
    email: "",
  });
  const [errors, seterrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [simpleError, setSimpleError] = useState("");
  const {
    location: { state },
  } = history;

  useEffect(() => {
    if (isLoggedIn || Boolean(local.get("token"))) {
      history.push("/dashboard");
    }
  }, [history, isLoggedIn]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const emptyFields = validateEmptyFields(inputs);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else if (inputs.email && !validator.isEmail(inputs.email)) {
        return setSimpleError("Invalid Email");
      } else {
        try {
          const formData = new FormData();
          seterrors({});
          setSimpleError("");
          formData.append("email", inputs.email);
          setLoading(true);
          const apiResponse = await api.resetPassword(formData);
          setLoading(false);
          if (!apiResponse.status) {
            setSimpleError(apiResponse.message);
          } else {
            setSuccessMessage("Recover email sent");
            setInputs({ email: "" });
            seterrors({});
          }
        } catch (error) {
          setLoading(false);
          setSimpleError(error.message);
        }
      }
    },
    [inputs, setLoading]
  );

  return (
    <div className="flexer vh-100">
      <div className="login-page">
        <LogoWithHeading title="Reset Password" width={40} />
        <form name="form" onSubmit={handleSubmit}>
          {successMessage && <h6 className="text-success">{successMessage}</h6>}
          {simpleError && <h6 className="text-danger">{simpleError}</h6>}
          {state?.isRecoveryPasswordUrlExpired && (
            <NotficationBanner
              {...notificationProps["resetPasswordLinkExpired"]}
            />
          )}
          <InputField
            name="email"
            type="email"
            label="Email"
            className="text-field"
            handleChange={handleChange}
            value={inputs.email}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <Button
            color="primary"
            variant="text"
            component={Link}
            to="/"
            classes={{
              root: "btn",
              label: "label",
              textPrimary: "text-end",
            }}
          >
            <span className="forgot-pw">Back to login</span>
          </Button>
          <MaterialButton title="Send" />
        </form>
      </div>
      <SideScreenBanner />
      {loading && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (val) => dispatch(globalLoading(val)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.globalReducer.isLoading,
    isLoggedIn: state.userReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
