import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Cached } from "@material-ui/icons";
import ProgressBar from "../../../HelperComponents/ProgressBar";
import { CircularProgress } from "@material-ui/core";
import { SYNCING_IN_PROGRESS } from "../../../utils/integrationsConstants";
import "../../../styles/components/integrations/syncing-progress-bar.scss";

const SyncingProgressBar = (props) => {
  const {
    message,
    isCircularProgress,
    onClickCancelSync,
    syncJobAddedToQueue,
  } = props;

  // const isSyncingCompleted = false; //To be changed

  // const getSyncMessage = useCallback(() => {
  //   if (!isSyncingCompleted) return "Syncing in Progress";
  //   return (
  //     <Fragment>
  //       Sync Completed on{" "}
  //       <span className="text-black">&nbsp;2.20.2020 3:30 pm EST&nbsp;</span> on
  //       CCB’s end. Depending on the size of your sync, tt will take up to 30
  //       Minutes for MailChimp App to finish Sync!
  //     </Fragment>
  //   );
  // }, [isSyncingCompleted]);

  return (
    <div className="syncing-progress-bar-container">
      <div className="syncing-progress-bar-wrapper w-100">
        <Typography className={`title`} variant="subtitle2">
          {isCircularProgress ? (
            <CircularProgress className="ml-2 mr-2" size={20} />
          ) : (
            <Cached fontSize="small" className="mr-2" />
          )}{" "}
          {message}
        </Typography>
        {!isCircularProgress && (
          <div className="sync-progress">
            <ProgressBar value={syncJobAddedToQueue ? 50 : 100} />
          </div>
        )}
        <Typography
          onClick={onClickCancelSync}
          className={`cancel-sync`}
          variant="subtitle2"
        >
          Cancel Sync
        </Typography>
      </div>
    </div>
  );
};

SyncingProgressBar.defaultProps = {
  message: SYNCING_IN_PROGRESS,
  isCircularProgress: false,
  syncJobAddedToQueue: false,
};

SyncingProgressBar.propTypes = {
  message: PropTypes.string,
  isCircularProgress: PropTypes.bool,
  syncJobAddedToQueue: PropTypes.bool,
  onClickCancelSync: PropTypes.func.isRequired,
};

export default SyncingProgressBar;
