import React from "react";
import PropTypes from "prop-types";
import LightTooltip from "./LightTooltip";
import { ReactComponent as OneWayIcon } from "../assets/icons/one-way-icon.svg";
import { ArrowForward } from "@material-ui/icons";

const ShowSyncArrow = ({
  hasOneWayIcon,
  color,
  className,
  fontSize,
  containerClassName,
}) => {
  return (
    <div className={containerClassName}>
      {hasOneWayIcon && (
        <LightTooltip
          title="This is one-way sync"
          placement={"bottom"}
          arrow={true}
        >
          <OneWayIcon />
        </LightTooltip>
      )}
      <ArrowForward color={color} className={className} fontSize={fontSize} />
    </div>
  );
};

ShowSyncArrow.propTypes = {
  hasOneWayIcon: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  containerClassName: PropTypes.string,
};

ShowSyncArrow.defaultProps = {
  hasOneWayIcon: false,
  color: "",
  fontSize: "",
  containerClassName: "arrow-icon-wrapper",
};

export default ShowSyncArrow;
