import React from "react";
import PropType from "prop-types";
import { CCB_TO_MC } from "../../../utils/integrationsConstants";
import SyncHeaderCell from "./SyncHeaderCell";
import "../../../styles/components/services/header-services.scss";

const SyncListHeader = (props) => {
  const {
    // Component props
    activeTab,
    isIntegrationsDisabled,
    searchValue,
    pairFilters,
    handleSearch,
    handleReload,
    handleFilteration,
  } = props;

  return (
    <thead>
      <tr className="sync-list-table-header flexer-space-around">
        {activeTab === CCB_TO_MC ? (
          <>
            <td className="w-5"></td>
            <SyncHeaderCell
              headerIdentity={"ccb"}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleReload={handleReload}
              isIntegrationsDisabled={isIntegrationsDisabled}
              handleFilteration={handleFilteration}
              pairFilters={pairFilters}
              className={"w-22"}
              activeTab={activeTab}
            />
            <SyncHeaderCell
              headerIdentity={"mc"}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleReload={handleReload}
              isIntegrationsDisabled={isIntegrationsDisabled}
              handleFilteration={handleFilteration}
              pairFilters={pairFilters}
              className={"w-25"}
              activeTab={activeTab}
            />
          </>
        ) : (
          <>
            <td className="w-5"></td>
            <SyncHeaderCell
              headerIdentity={"mc"}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleReload={handleReload}
              isIntegrationsDisabled={isIntegrationsDisabled}
              handleFilteration={handleFilteration}
              pairFilters={pairFilters}
              className={"w-22"}
              activeTab={activeTab}
            />
            <SyncHeaderCell
              headerIdentity={"ccb"}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleReload={handleReload}
              isIntegrationsDisabled={isIntegrationsDisabled}
              handleFilteration={handleFilteration}
              pairFilters={pairFilters}
              className={"w-25"}
              activeTab={activeTab}
            />
          </>
        )}
      </tr>
    </thead>
  );
};

SyncListHeader.defaultProps = {
  activeTab: "",
};

SyncListHeader.propTypes = {
  activeTab: PropType.string,
  handleFilteration: PropType.func.isRequired,
  handleSearch: PropType.func.isRequired,
  handleReload: PropType.func.isRequired,
};

export default SyncListHeader;
