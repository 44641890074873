import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import validator from "validator";
import SideScreenBanner from "../../../components/SideScreenBanner/SideScreenBanner";
import ReCAPTCHA from "react-google-recaptcha";
import InputField from "../../../components/InputField/InputField";
import LogoWithHeading from "../../../components/LogoWithHeading/LogoWithHeading";
import MaterialButton from "../../../components/ MaterialButton/MaterialButton";
import { validateEmptyFields } from "../../../helpers";
import services from "../../../services";
import { globalLoading } from "../../../store/actions/globalAction";
import Loader from "../../../components/Loader/Loader";
import { userLoginMiddleware } from "../../../store/middlewares/userMiddleware";
import "../../../styles/views/login.scss";
import clsx from "clsx";

const {
  storageManager: { local },
} = services;

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const { api } = services;

const Signup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, seterrors] = useState({});
  const [simpleError, setSimpleError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isEmailPending, setIsEmailPending] = useState(false);
  const [validateRecaptcha, setValidateRecaptcha] = useState(false);
  const { loading, setLoading, loginUser, isLoggedIn, history } = props;

  useEffect(() => {
    if (isLoggedIn || Boolean(local.get("token"))) {
      history.push("/dashboard?non-activate-account");
    }
  }, [history, isLoggedIn]);

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleConfirmPasswordVisibility = useCallback(() => {
    setShowConfirmPassword(!showConfirmPassword);
  }, [showConfirmPassword]);

  const recaptchaOnchange = useCallback((value) => {
    if (value) {
      setValidateRecaptcha(true);
    }
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleMarkUserPending = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("email", inputs.email);
      setLoading(true);
      const markPendingResponse = await api.markUserPending(formData);
      setLoading(false);
      if (markPendingResponse.status) {
        setIsEmailPending(false);
        setSuccessMessage(
          "Confirmation email has been sent to admin for approval. We will notify you once your account is approved."
        );
      }
    } catch (error) {
      setLoading(false);
      setSimpleError(error.message);
    }
  }, [inputs, setLoading]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const emptyFields = validateEmptyFields(inputs);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else if (inputs.email && !validator.isEmail(inputs.email)) {
        seterrors({});
        return setSimpleError("Invalid Email");
      } else if (inputs.confirmPassword !== inputs.password) {
        seterrors({});
        return setSimpleError("Passwords incorrect");
      } else if (!validateRecaptcha) {
        seterrors({});
        return setSimpleError("Recaptcha should be filled");
      } else {
        try {
          const formData = new FormData();
          seterrors({});
          setSimpleError("");
          formData.append("email", inputs.email);
          formData.append("password", inputs.password);
          formData.append("confirmPassword", inputs.confirmPassword);
          setLoading(true);
          const responseBack = await api.userSignup(formData);
          setLoading(false);
          if (Boolean(responseBack?.isPending)) {
            setIsEmailPending(true);
            setSuccessMessage("");
          } else if (!responseBack.status) {
            setSimpleError(responseBack.message);
            setIsEmailPending(false);
          } else {
            setIsEmailPending(false);
            setSuccessMessage("Signup successfully");
            loginUser(formData);
            setInputs({
              email: "",
              password: "",
              confirmPassword: "",
            });
          }
        } catch (error) {
          setLoading(false);
          setSimpleError(error.message);
        }
      }
    },
    [inputs, loginUser, setLoading, validateRecaptcha]
  );
  return (
    <div className="flexer vh-100">
      <div className="login-page">
        <LogoWithHeading title="Create Account" width={40} />
        <form name="form" onSubmit={handleSubmit}>
          {simpleError && <h6 className="text-danger">{simpleError}</h6>}
          {successMessage && <h6 className="text-success">{successMessage}</h6>}
          <InputField
            name="email"
            type="email"
            label="Email"
            className={clsx("text-field", {
              "mb-0": isEmailPending,
            })}
            handleChange={handleChange}
            value={inputs.email}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          {isEmailPending && (
            <label className="mb-1 mt-1 text-muted">
              Is the email valid? If valid,{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={handleMarkUserPending}
              >
                please confirm
              </span>
            </label>
          )}
          <InputField
            name="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            className="text-field"
            showPassword={showPassword}
            handlePasswordVisibility={handlePasswordVisibility}
            handleChange={handleChange}
            value={inputs.password}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
          <InputField
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            className="text-field"
            showPassword={showConfirmPassword}
            handlePasswordVisibility={handleConfirmPasswordVisibility}
            handleChange={handleChange}
            value={inputs.confirmPassword}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <div className="mt-2">
            <ReCAPTCHA
              //For development purpose sitekey
              // sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={recaptchaOnchange}
            />
          </div>

          <div className="mt-4">
            <h6 className="term-privacy">
              By clicking on the sign up button you agree to sync.ccbchimp.com{" "}
              <a
                href="https://ccbchimp.com/terms/"
                rel="noreferrer"
                target="_blank"
                className="text-link"
              >
                Terms of Use
              </a>{" "}
              &{" "}
              <a
                href="https://ccbchimp.com/privacy/"
                rel="noreferrer"
                target="_blank"
                className="text-link"
              >
                Privacy
              </a>
            </h6>
            <MaterialButton title="Sign Up" />
          </div>
          <Button
            color="primary"
            variant="text"
            component={Link}
            to="/"
            classes={{
              root: "btn",
              label: "label",
              textPrimary: "text-primary",
            }}
          >
            <span className="light">Already have an account?</span> &nbsp; Login
            here
          </Button>
        </form>
      </div>
      <SideScreenBanner />
      {loading && <Loader />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (val) => dispatch(globalLoading(val)),
    loginUser: (userData) => dispatch(userLoginMiddleware(userData)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.globalReducer.isLoading,
    isLoggedIn: state.userReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
