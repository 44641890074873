import config from "../../config";
import { postRequest } from "../../utils/request";
import { ACTION_TYPES } from "../actions/types";

export function getAllInvoices(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_ALL_INVOICES,
      config.Path.GET_ALL_INVOICES,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getAllAvailablePlans(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_ALL_AVAILABLE_PLANS,
      config.Path.GET_ALL_AVAILABLE_PLANS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getCurrentPlan(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_CURRENT_PLAN,
      config.Path.GET_CURRENT_PLAN,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function checkCoupon(data, subscriptionPlan) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.CHECK_COUPON,
      config.Path.CHECK_COUPON,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data, subscriptionPlan },
        errorDispatchData: {},
      }
    );
}

export function cancelPlan(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.CANCEL_PLAN,
      config.Path.CANCEL_PLAN,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getCurrentCardDetails(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_CURRENT_CARD_DETAILS,
      config.Path.GET_CURRENT_CARD_DETAILS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function addNewCard(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ADD_NEW_CARD,
      config.Path.ADD_NEW_CARD,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function updateCurrentCard(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.UPDATE_CURRENT_CARD,
      config.Path.UPDATE_CURRENT_CARD,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getStripePublishableKey(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_STRIPE_PUBLISHABLE_KEY,
      config.Path.GET_STRIPE_PUBLISABLE_KEY,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function purchasePlan(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.PURCHASE_PLAN,
      config.Path.PURCHASE_PLAN,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}
