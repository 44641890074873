import { useMemo } from "react";
import useGlobalStore from "../../store/reduxStore/globalStore";

const useIsSidebarExpanded = () => {
  const { global } = useGlobalStore();
  const { isSidebarExpanded } = global;

  const sidebarExpnadedCondition = useMemo(
    () => isSidebarExpanded,
    [isSidebarExpanded]
  );

  return sidebarExpnadedCondition;
};

export default useIsSidebarExpanded;
