import { useCallback, useRef } from "react";

export const useLocalSearch = (
  arrayToSearch, // the array where search is done
  setSearchedArrayState, // set state function for the searched data
  setSearchValueState, // set state for the search value
  valueMapper // a callback which maps which elements should be searched in the array in case the elements of the array are objects
) => {
  const intervalRef = useRef();

  const getElement = useCallback(
    (element) => {
      if (valueMapper) return valueMapper(element);
      else return element;
    },
    [valueMapper]
  );

  const onChange = useCallback(
    ({ target: { value } }) => {
      clearTimeout(intervalRef.current);
      setSearchValueState(value);

      if (value === "") {
        intervalRef.current = setTimeout(
          () => setSearchedArrayState(arrayToSearch),
          300
        );
        return;
      }

      const searchedArray = [];

      for (const element of arrayToSearch) {
        if (
          getElement(element).toLowerCase().search(value.toLowerCase()) !== -1
        ) {
          searchedArray.push(element);
        }
      }

      intervalRef.current = setTimeout(
        () => setSearchedArrayState(searchedArray),
        300
      );
    },
    [arrayToSearch, getElement, setSearchValueState, setSearchedArrayState]
  );

  return {
    onChange,
  };
};
