// Sync pairs
export const CCB_TO_MC = "ccbToMc";
export const MC_TO_CCB = "mcToCcb";

// Integrations columns identites
// CCB
export const CCB_CAMPUSES = "ccbCampuses";
export const CCB_GROUPS = "ccbGroups";

// For mailchimps
export const MAILCHIMP_AUDIENCE = "mailchimpAudience";
export const MAILCHIMP_GROUPS = "mailchimpGroups";

// Tooltip message
export const INITIAL_SYNCING_IN_PROGRESS =
  "Initial Syncing has started. The process can take up to 15 minutes. An email will be sent on confirmation";
export const SYNCING_IN_PROGRESS = "Syncing in progress";

// SEARCH WARNING FOR NEW USER
export const SEARCH_WARNING = `To sync with the new People Searches, you need to enable the following API services <b>execute_advanced_search</b> and <b>saved_advanced_search_list</b> via the CCB dashboard.`;

// PROCESS QUEUE WARNING FOR NEW USER
export const PROCESS_QUEUE_WARNING = `To be able to use Process Queue, you need to enable the following API permissions <b>process_list</b>, <b>add_individual_to_queue</b>, <b>individual_queues</b> and <b>queue_individuals</b> via the CCB dashboard.`;

export const RESTRICTED_FEATURE_TOOLTIP =
  "This option is only available for our $20 / m plan and up. Please upgrade you plan.";

export const NEW_CCB_TO_MC_PAIRS_ID = "new-ccb-to-mc-pairs-id";
export const GROUPS = "group";
export const SEARCH = "search";
