import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import "../../../styles/components/more/header-more.scss";

const MobileHeader = (props) => {
  const { title, backRoute } = props;
  return (
    <AppBar position="static" className="header-more">
      <Toolbar className="toolbar">
        <IconButton
          component={Link}
          to={backRoute}
          edge="start"
          color="inherit"
          aria-label="Menu"
          className="icon-button"
        >
          <ArrowBackIos />
        </IconButton>
        <Typography className="title" component="h2">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

MobileHeader.propTypes = {
  title: "",
  backRoute: "",
};

MobileHeader.propTypes = {
  title: PropTypes.string,
  backRoute: PropTypes.string,
};

export default MobileHeader;
