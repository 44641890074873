import React from "react";
import PropTypes from "prop-types";
import TableHeaderCell from "../TableHeaderCell/TableHeaderCell";

const TableHeader = (props) => {
  const {
    cells,
    hideCheckBoxCell,
    className,
    firstCell,
    additionalEndCells,
    style,
    innerClassName,
  } = props;

  return (
    <thead className={className}>
      <tr>
        {firstCell && !hideCheckBoxCell && <th>{firstCell}</th>}
        {!hideCheckBoxCell && !firstCell && <th style={{ width: 1 }} />}
        {cells.map((cell, index) => {
          return (
            <TableHeaderCell
              key={cell.text}
              className={`${!hideCheckBoxCell && index === 0 ? "pl-0" : ""}`}
              text={cell.text}
              style={style}
              innerClassName={innerClassName}
            />
          );
        })}
        {additionalEndCells && additionalEndCells}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      stateProp: PropTypes.bool.isRequired,
      sortName: PropTypes.string,
      showSortArrows: PropTypes.bool,
    })
  ).isRequired,
  onSort: PropTypes.func,
  hideCheckBoxCell: PropTypes.bool,
  className: PropTypes.string,
  firstCell: PropTypes.node,
  additionalEndCells: PropTypes.arrayOf(PropTypes.node),
};

TableHeader.defaultProps = {
  onSort: undefined,
  hideCheckBoxCell: false,
  className: "",
  additionalEndCells: undefined,
};

export default TableHeader;
