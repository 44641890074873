import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import MaterialButton from "../../../ MaterialButton/MaterialButton";
import { isMdOrBelowBreakpoint } from "../../../../utils/breakpoints";
import useBreakpoint from "../../../../utils/hooks/useBreakpoint";
import "../../../../styles/components/integrations/remove-sync-pair.scss";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: 0,
    top: 60,
  },
});

const CloseNewSyncPairConfirmationModal = (props) => {
  const { open, handleClose, onClick } = props;
  const breakpoint = useBreakpoint();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={"md"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint)
          ? "m-0 vw-100"
          : classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">
          Close without adding to Sync List?
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The Sync Pairs you just added will not be saved!
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-4 flexer-column">
        <div className="d-flex justify-content-between w-100">
          <MaterialButton
            onClick={onClick}
            title="Add to Sync List"
            className={`${
              isMdOrBelowBreakpoint(breakpoint) ? "w-auto h-100" : "w-30"
            } ml-3 dark-gray-button`}
          />
          <MaterialButton
            onClick={handleClose}
            title="Close Without Adding"
            className={`${
              isMdOrBelowBreakpoint(breakpoint) ? "w-auto h-100" : "w-30"
            } mr-3`}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CloseNewSyncPairConfirmationModal;
