import React, { useCallback, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FixedSizeList as List } from "react-window";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import lodash from "lodash";
import {
  Close,
  Add,
  DeleteForeverOutlined,
  ArrowForward,
} from "@material-ui/icons";
import {
  dataForCcbModal,
  dataForMcModal,
} from "../IntegrationsPageModal/AddNewSyncPairModal/addNewSyncPairModalData";
import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  CCB_TO_MC,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
} from "../../../utils/integrationsConstants";
import {
  addNewKeyPairInArray,
  checkIfCcbGroupExistInMcGroups,
  checkSubscribePlanAmount,
  confirmationModalContentWhenSelectAllMembersGroup,
  deepClone,
  filterResolvedBrokenPair,
  filterResolvedPairs,
  formDataWithApiKey,
  getUniqueId,
  populateSyncpairsForBrokenPairs,
  resolvedNumberOfPairs,
  setArrayValuesInFormData,
} from "../../../helpers";
import services from "../../../services";
import {
  saveCcbToMcSyncPair,
  saveMcToCcbSyncPair,
  setCcbGroups,
  setCcbProcessQueue,
  setMailchimpInterestedGroups,
  setMailchimpTags,
} from "../../../store/middlewares/integrationMiddleware";
import {
  globalLoading,
  setDataForModal,
  setSnackbarData,
} from "../../../store/actions/globalAction";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import SelectMcTagsModal from "../IntegrationsPageModal/SelectMcTagsModal/SelectMcTagsModal";
import CcbCampusAndSavedSearchTabBox from "../IntegrationsPageModal/AddNewSyncPairModal/CcbCampusAndSavedSearchTabBox";
import McTagsAndGroupTabBox from "../IntegrationsPageModal/AddNewSyncPairModal/McTagsAndGroupTabBox";
import SyncOptionBox from "../IntegrationsPageModal/AddNewSyncPairModal/SyncOptionBox";
import CustomAutocompleteSelect from "../../../HelperComponents/CustomAutocompleteSelect";
import {
  changeSyncType,
  clearRemoveCcbToMcPair,
  clearRemoveMcToCcbPair,
  clearSaveCcbToMcPairsSataus,
  clearSaveMcToCcbPairStatus,
} from "../../../store/actions/integrationActions";
import MailchimpIcon from "../../../assets/img/mailchimp/mailchimp-small.png";
import CcbIcon from "../../../assets/img/ccb/ccb-small.png";
import NumberSteps from "../../../HelperComponents/NumberSteps";
import LightTooltip from "../../../HelperComponents/LightTooltip";
import RemoveSyncPairConfirmationModal from "../IntegrationsPageModal/RemoveSyncPairConfirmationModal";
import { getWarnings } from "../../../store/middlewares/userMiddleware";
import "../../../styles/components/integrations/add-new-sync-pair-modal.scss";
import CcbGroupsAndProcessQueue from "../IntegrationsPageModal/AddNewSyncPairModal/CcbGroupsAndProcessQueue";
import ShowSyncArrow from "../../../HelperComponents/ShowSyncArrow";

const { api } = services;

const ResolveBrokenPairsModal = (props) => {
  const {
    // ROuter props
    history,

    // Redux props
    ccbSavedSearches,
    currentSync,
    currentPlan,
    globalModalData,
    loading,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
    saveMcToCcbSyncPairStatus,
    saveCcbToMcSyncPairStatus,
    mailchimpInterestedGroups,
    ccbGroups,
    mailchimpTags,
    mailchimpListIdForTags,
    mailchimpTagsStatus,
    ccbGroupStatus,
    mailchimpInterestedGroupsStatus,
    mailchimplistIdForInterestedGroups,
    ccbProcessQueues,

    // Redux function
    setSnackbarData,
    saveCcbToMcSyncPair,
    saveMcToCcbSyncPair,
    changeSyncType,
    setLoading,
    clearRemoveCcbToMcPair,
    clearRemoveMcToCcbPair,
    clearSaveMcToCcbPairStatus,
    clearSaveCcbToMcPairsSataus,
    setDataForModal,
    getWarnings,
    setMailchimpInterestedGroups,
    setCcbGroups,
    setMailchimpTags,
    setCcbProcessQueue,
  } = props;
  const isTwoWayBrokenSyncPairs = useMemo(
    () =>
      !lodash.isEmpty(globalModalData?.ccbToMcBrokenPairs) &&
      !lodash.isEmpty(globalModalData?.mcToCcbBrokenPairs),
    [globalModalData]
  );
  const isSubscribeHigherPlan = useMemo(
    () =>
      checkSubscribePlanAmount(
        currentPlan?.amountDisplay,
        20,
        currentPlan?.name
      ),
    [currentPlan]
  );
  const loadingForPopulatePairs = [
    mailchimpInterestedGroupsStatus,
    ccbGroupStatus,
    mailchimpTagsStatus,
  ].includes("loading");
  const [modalData, setModalData] = useState([]);
  const [mailChimpTags, setMailChimpTags] = useState([]);
  const [resolvedPairsIds, setResolvedPairsIds] = useState([]);
  const [activeTagPairIndex, setActiveTagPairIndex] = useState(null);
  const [showAllMemberConfirmationModal, setShowAllMemberConfirmationModal] =
    useState(false);
  const [allMemberConfirmationCotent, setAllMemberConfirmationCotent] =
    useState("");
  const [
    dataForAllMemberConfirmationModal,
    setDataForAllMemberConfirmationModal,
  ] = useState({});
  const [showSelectMcTagsModal, setShowSelectMcTagsModal] = useState(false);
  const [showRemoveSyncPairModal, setShowRemoveSyncPairModal] = useState(false);
  const [selectBoxLoading, setSelectBoxLoading] = useState({});
  const [dataForRemoveSyncPairModal, setDataForRemoveSyncPairModal] = useState(
    {}
  );
  const formData = useMemo(() => formDataWithApiKey(), []);
  const disabledCondition = useMemo(
    () =>
      [saveCcbToMcSyncPairStatus, saveMcToCcbSyncPairStatus].includes(
        "loading"
      ),
    [saveCcbToMcSyncPairStatus, saveMcToCcbSyncPairStatus]
  );

  const closeMainModal = useCallback(() => {
    const { syncType } = globalModalData;
    history.push("/sync");
    setDataForModal({});
    changeSyncType(syncType || CCB_TO_MC);
  }, [changeSyncType, globalModalData, history, setDataForModal]);

  const toggleRemoveSyncPairModal = useCallback(() => {
    setShowRemoveSyncPairModal(!showRemoveSyncPairModal);
  }, [showRemoveSyncPairModal]);

  const populateBrokenSyncPairs = useCallback(
    async (brokenSyncPairs, mailChimpAudience, ccbCampuses) => {
      setLoading(true);
      const prePopulateModalData = await populateSyncpairsForBrokenPairs(
        deepClone(
          currentSync === CCB_TO_MC ? dataForCcbModal[0] : dataForMcModal[0]
        ),
        brokenSyncPairs,
        ccbCampuses,
        mailChimpAudience,
        true,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        setCcbGroups,
        ccbGroups,
        mailchimpTags,
        setMailchimpTags,
        mailchimpListIdForTags,
        mailchimplistIdForInterestedGroups,
        ccbSavedSearches,
        ccbProcessQueues,
        setCcbProcessQueue,
        false,
        currentSync
      );
      if (!lodash.isEmpty(prePopulateModalData)) {
        setLoading(false);
        setModalData(prePopulateModalData);
      }
    },
    [
      setLoading,
      currentSync,
      mailchimpInterestedGroups,
      setMailchimpInterestedGroups,
      setCcbGroups,
      ccbGroups,
      mailchimpTags,
      setMailchimpTags,
      mailchimpListIdForTags,
      mailchimplistIdForInterestedGroups,
      ccbSavedSearches,
      ccbProcessQueues,
      setCcbProcessQueue,
    ]
  );

  useEffect(() => {
    if (
      [removeCcbToMcPairStatus, removeMcToCcbPairStatus].includes("success")
    ) {
      const { index } = dataForRemoveSyncPairModal;
      const updatedData = lodash.remove(modalData, (n, idx) => idx !== index);
      clearRemoveCcbToMcPair();
      clearRemoveMcToCcbPair();
      setShowRemoveSyncPairModal(false);
      if (lodash.isEmpty(updatedData)) {
        closeMainModal();
      } else {
        setModalData(updatedData);
      }
      getWarnings(formData);
      setDataForRemoveSyncPairModal({});
    }
  }, [
    clearRemoveCcbToMcPair,
    clearRemoveMcToCcbPair,
    dataForRemoveSyncPairModal,
    modalData,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
    closeMainModal,
    getWarnings,
    formData,
  ]);

  useEffect(() => {
    const {
      ccbToMcBrokenPairs,
      mcToCcbBrokenPairs,
      mailChimpAudience,
      ccbCampuses,
    } = globalModalData;
    if (
      !saveCcbToMcSyncPairStatus &&
      !saveMcToCcbSyncPairStatus &&
      !loadingForPopulatePairs
    ) {
      if (
        ccbToMcBrokenPairs &&
        !lodash.isEmpty(ccbToMcBrokenPairs) &&
        currentSync === CCB_TO_MC
      ) {
        populateBrokenSyncPairs(
          ccbToMcBrokenPairs,
          mailChimpAudience,
          ccbCampuses
        );
      } else if (
        mcToCcbBrokenPairs &&
        !lodash.isEmpty(mcToCcbBrokenPairs) &&
        currentSync === MC_TO_CCB
      ) {
        populateBrokenSyncPairs(
          mcToCcbBrokenPairs,
          mailChimpAudience,
          ccbCampuses
        );
      }
    }
  }, [
    changeSyncType,
    currentSync,
    globalModalData,
    populateBrokenSyncPairs,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
    loadingForPopulatePairs,
  ]);

  useEffect(() => {
    const { ccbToMcBrokenPairs } = globalModalData;
    if (saveMcToCcbSyncPairStatus === "success") {
      clearSaveMcToCcbPairStatus();
      getWarnings(formData);
      closeMainModal();
    } else if (saveCcbToMcSyncPairStatus === "success") {
      const resolvedCcbToMcPairs = filterResolvedBrokenPair(
        ccbToMcBrokenPairs,
        resolvedPairsIds
      );
      clearSaveCcbToMcPairsSataus();
      getWarnings(formData);
      setResolvedPairsIds([]);
      const updateGlobalModalData = Object.assign(globalModalData, {
        ccbToMcBrokenPairs: resolvedCcbToMcPairs,
      });
      setDataForModal(deepClone(updateGlobalModalData));
      if (
        !isTwoWayBrokenSyncPairs &&
        (modalData?.length === 1 ||
          resolvedPairsIds?.length === modalData?.length)
      ) {
        closeMainModal();
      } else if (isTwoWayBrokenSyncPairs) {
        changeSyncType(MC_TO_CCB);
      }
    }
  }, [
    changeSyncType,
    clearSaveCcbToMcPairsSataus,
    clearSaveMcToCcbPairStatus,
    closeMainModal,
    globalModalData,
    isTwoWayBrokenSyncPairs,
    modalData,
    resolvedPairsIds,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
    setDataForModal,
    getWarnings,
    formData,
  ]);

  const errorSnackbar = useCallback(
    (message = "Something went wrong, please try agan later!") => {
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const closeAllMemberConfirmationModal = useCallback(() => {
    setShowAllMemberConfirmationModal(false);
  }, []);

  const openAllMemberConfirmationModal = useCallback(
    (modalContent, modalData) => {
      setShowAllMemberConfirmationModal(true);
      setAllMemberConfirmationCotent(modalContent);
      setDataForAllMemberConfirmationModal(modalData);
    },
    []
  );

  const openMcTagsModal = useCallback(
    (pairIndex) => {
      setShowSelectMcTagsModal(true);
      setActiveTagPairIndex(pairIndex);
      setMailChimpTags(modalData[pairIndex][MAILCHIMP_GROUPS].tags);
    },
    [modalData]
  );

  const closeMcTagsModal = useCallback(
    (isConfirm) => {
      setShowSelectMcTagsModal(false);
      if (!isConfirm) {
        const cloneTags =
          deepClone(modalData)[activeTagPairIndex][MAILCHIMP_GROUPS].tags;
        cloneTags.forEach((item) => (item.active = false));
        setModalData(modalData);
      }
    },
    [activeTagPairIndex, modalData]
  );

  const handleCheck = useCallback(
    (data) => {
      const cloneModalData = deepClone(modalData);
      const tags = [...mailChimpTags];
      tags.forEach((tag, index) => {
        if (tag.id === data.id) {
          tags[index].active = !tags[index].active;
        }
      });
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setModalData(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, modalData]
  );

  const appendNewTag = useCallback(
    (newTag) => {
      const cloneModalData = deepClone(modalData);
      const tags = [...mailChimpTags];
      tags.push(newTag);
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setModalData(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, modalData]
  );

  const onClickedDltIcon = useCallback(
    (removeItemIndex) => {
      const { ccbToMcBrokenPairs, mcToCcbBrokenPairs } = globalModalData;
      let currentBrokenPair =
        currentSync === CCB_TO_MC ? ccbToMcBrokenPairs : mcToCcbBrokenPairs;
      toggleRemoveSyncPairModal(true);
      setDataForRemoveSyncPairModal({
        ...currentBrokenPair[removeItemIndex],
        index: removeItemIndex,
      });
    },
    [currentSync, globalModalData, toggleRemoveSyncPairModal]
  );

  const handleMcGroupAndTags = useCallback(
    (value, index) => {
      const cloneState = deepClone(modalData);
      cloneState[index][MAILCHIMP_GROUPS].syncType = value;
      if (value === 1) {
        cloneState[index][MAILCHIMP_GROUPS].value = "";
        cloneState[index][MAILCHIMP_GROUPS].selectedId = null;
      }
      setModalData(cloneState);
    },
    [modalData]
  );

  const handleMcGroupsChange = useCallback((option, state, index) => {
    if (option?.uniqueId) {
      state[index][MAILCHIMP_GROUPS].selectedId = option.uniqueId;
      state[index][MAILCHIMP_GROUPS].value = option.groupName;
      setModalData(state);
    } else {
      state[index][MAILCHIMP_GROUPS].selectedId = null;
      state[index][MAILCHIMP_GROUPS].value = "";
      setModalData(state);
    }
  }, []);

  const handleMcAudienceChange = useCallback(
    async (option, state, index) => {
      if (option?.id) {
        try {
          state[index][MAILCHIMP_AUDIENCE].selectedId = option.id;
          state[index][MAILCHIMP_AUDIENCE].value = option.name;
          formData.append("mailchimpListId", option.id);
          setSelectBoxLoading({
            identity: [MAILCHIMP_GROUPS, MAILCHIMP_AUDIENCE],
            index,
          });
          const mailChimpTags = await api.getMailchimpAudienceTag(formData);
          const mailChimpInterestedGroups =
            await api.getMailchimpInterestedGroups(formData);
          setSelectBoxLoading({});
          if (mailChimpInterestedGroups.status === 1) {
            const addkeyForGrouping = addNewKeyPairInArray(
              mailChimpInterestedGroups.data,
              { groupBy: "Sync to Existing Group" }
            );
            let filterCreatedGroup = [];
            if (state[index][CCB_GROUPS].value) {
              const isDuplicateGroupOption = checkIfCcbGroupExistInMcGroups(
                state[index][CCB_GROUPS].value,
                mailChimpInterestedGroups.data
              );
              filterCreatedGroup.push({
                groupBy: `Create a ${
                  isDuplicateGroupOption ? "Duplicate" : "new"
                } Group`,
                groupName: `${state[index][CCB_GROUPS].value} ${
                  isDuplicateGroupOption ? "- Copy" : ""
                }`,
                uniqueId: getUniqueId(1000) + "",
              });
            }
            state[index][MAILCHIMP_GROUPS].value = "";
            state[index][MAILCHIMP_GROUPS].options = [
              ...filterCreatedGroup,
              ...addkeyForGrouping,
            ];
          }
          if (mailChimpTags.status === 1) {
            const tags = addNewKeyPairInArray(
              Object.values(mailChimpTags.data),
              { active: false }
            );
            const unselectableTags = { active: true, isUnselectable: true };
            if (
              state[index][CCB_CAMPUSES].selectedId &&
              state[index][CCB_CAMPUSES].selectedId &&
              currentSync === CCB_TO_MC
            ) {
              if (state[index][CCB_CAMPUSES].value === "Saved Searches") {
                const selectedSavedSearch = state[index][
                  CCB_GROUPS
                ].options?.find(
                  (savedSearch) =>
                    savedSearch.id === state[index][CCB_GROUPS].selectedId
                );
                tags.unshift({
                  ...unselectableTags,
                  name: selectedSavedSearch.name,
                  id: selectedSavedSearch.id,
                });
              } else {
                const selectedCampusName = state[index][
                  CCB_CAMPUSES
                ].options?.find(
                  (campus) =>
                    campus.campusId === state[index][CCB_CAMPUSES].selectedId
                );
                const selectedCcbGroupOrProcessQueueOptions =
                  currentSync === CCB_TO_MC &&
                  Boolean(state[index][CCB_GROUPS].isProcessQueueSelected)
                    ? state[index][CCB_GROUPS].processQueueOptions
                    : state[index][CCB_GROUPS].options;
                const selectedGroupName =
                  selectedCcbGroupOrProcessQueueOptions?.find((group) => {
                    if (
                      Boolean(state[index][CCB_GROUPS].isProcessQueueSelected)
                    ) {
                      return group.id === state[index][CCB_GROUPS].selectedId;
                    }
                    return (
                      group.ccbGroupId === state[index][CCB_GROUPS].selectedId
                    );
                  });
                tags.unshift({
                  ...unselectableTags,
                  name:
                    selectedGroupName?.ccbGroupName ||
                    selectedGroupName?.queueName,
                  id: selectedGroupName?.id,
                });
                tags.unshift({
                  ...unselectableTags,
                  name: selectedCampusName.campusName,
                  id: selectedCampusName.id,
                });
              }
            }
            const filterTags = lodash.uniqBy(tags, (e) => e.name);
            state[index][MAILCHIMP_GROUPS].tags = filterTags;
          }
          setModalData(state);
        } catch (error) {
          errorSnackbar();
          setSelectBoxLoading({});
        }
      }
    },
    [currentSync, errorSnackbar, formData]
  );

  const handleCcbGroupChange = useCallback(
    (option, state, index, isCheckAllMember) => {
      if (option?.ccbGroupId) {
        if (
          option.ccbGroupName.toLowerCase().includes("all members of", 0) &&
          !isCheckAllMember
        ) {
          return openAllMemberConfirmationModal(
            confirmationModalContentWhenSelectAllMembersGroup(
              option.ccbGroupName,
              option.numOfParticipants
            ),
            { ...option, index }
          );
        }
        if (currentSync === CCB_TO_MC) {
          state[index][CCB_GROUPS].selectedId = option.id;
        } else {
          state[index][CCB_GROUPS].selectedId = option.ccbGroupId;
        }
        state[index][CCB_GROUPS].value = option.ccbGroupName;
        if (currentSync === CCB_TO_MC) {
          state[index][MAILCHIMP_AUDIENCE].selectedId = null;
          state[index][MAILCHIMP_AUDIENCE].value = "";
          state[index][MAILCHIMP_GROUPS].options = [];
          state[index][MAILCHIMP_GROUPS].selectedId = null;
          state[index][MAILCHIMP_GROUPS].value = "";
        }
        setModalData(state);
      } else if (option?.id) {
        state[index][CCB_GROUPS].selectedId = option.id;
        state[index][CCB_GROUPS].value = option.name;
        if (currentSync === CCB_TO_MC) {
          state[index][MAILCHIMP_AUDIENCE].selectedId = null;
          state[index][MAILCHIMP_AUDIENCE].value = "";
          state[index][MAILCHIMP_GROUPS].options = [];
          state[index][MAILCHIMP_GROUPS].selectedId = null;
          state[index][MAILCHIMP_GROUPS].value = "";
        }
        setModalData(state);
      }
    },
    [currentSync, openAllMemberConfirmationModal]
  );

  const handleCcbCampusChange = useCallback(
    async (option, state, index) => {
      const formData = formDataWithApiKey();
      if (option) {
        if (option.name === "Saved Searches") {
          state[index][CCB_GROUPS].options = ccbSavedSearches;
          state[index][CCB_GROUPS].placeholder = "Select Saved Searches";
          state[index][CCB_GROUPS].selectedId = null;
          state[index][CCB_GROUPS].value = "";

          if (currentSync === CCB_TO_MC) {
            state[index][MAILCHIMP_AUDIENCE].selectedId = null;
            state[index][MAILCHIMP_AUDIENCE].value = "";
            state[index][MAILCHIMP_GROUPS].selectedId = null;
            state[index][MAILCHIMP_GROUPS].value = "";
            state[index][MAILCHIMP_GROUPS].options = [];
          }
          state[index][CCB_CAMPUSES].selectedId = option.name;
          state[index][CCB_CAMPUSES].value = option.name;
          setModalData(state);
        } else {
          try {
            setSelectBoxLoading({
              identity: [CCB_GROUPS, CCB_CAMPUSES],
              index,
            });
            formData.append("campusId", option.campusId);
            const ccbGroups = await api.getCCbGroups(formData);
            setSelectBoxLoading({});
            state[index][CCB_GROUPS].selectedId = null;
            state[index][CCB_GROUPS].value = "";
            state[index][CCB_GROUPS].options = ccbGroups.data;
            state[index][CCB_GROUPS].placeholder = "Select CCB Group";
            if (currentSync === CCB_TO_MC) {
              state[index][MAILCHIMP_AUDIENCE].selectedId = null;
              state[index][MAILCHIMP_AUDIENCE].value = "";
              state[index][MAILCHIMP_GROUPS].selectedId = null;
              state[index][MAILCHIMP_GROUPS].value = "";
              state[index][MAILCHIMP_GROUPS].options = [];
            }
            state[index][CCB_CAMPUSES].selectedId = option.campusId;
            state[index][CCB_CAMPUSES].value = option.campusName;
            setModalData(state);
          } catch (error) {
            errorSnackbar();
            setSelectBoxLoading({});
          }
        }
      }
    },
    [ccbSavedSearches, currentSync, errorSnackbar]
  );

  const handleChange = useCallback(
    (event, option, identity, index) => {
      const currentState = deepClone(modalData);
      if (identity === CCB_CAMPUSES) {
        handleCcbCampusChange(option, currentState, index, identity);
      } else if (identity === CCB_GROUPS) {
        handleCcbGroupChange(option, currentState, index, false);
      } else if (identity === MAILCHIMP_AUDIENCE) {
        handleMcAudienceChange(option, currentState, index);
      } else if (identity === MAILCHIMP_GROUPS) {
        handleMcGroupsChange(option, currentState, index);
      }
    },
    [
      handleCcbCampusChange,
      handleCcbGroupChange,
      handleMcAudienceChange,
      handleMcGroupsChange,
      modalData,
    ]
  );

  const handleSubmit = useCallback(() => {
    const resolvedPairs = filterResolvedPairs(modalData);
    if (lodash.isEmpty(resolvedPairs)) {
      if (currentSync === MC_TO_CCB) return closeMainModal();
      if (currentSync === CCB_TO_MC) {
        if (isTwoWayBrokenSyncPairs) {
          changeSyncType(MC_TO_CCB);
          return;
        } else {
          closeMainModal();
          return;
        }
      }
    }
    const formData = formDataWithApiKey();
    const ccbCampusIds = [];
    const type = [];
    const syncType = [];
    const ccbGroupIds = [];
    const mailchimpAudienceIds = [];
    const interestCategoryId = [];
    const interestId = [];
    const tags = [];
    const ids = [];
    resolvedPairs.map((pair) =>
      Object.keys(pair).forEach((item) => {
        if (item === CCB_CAMPUSES) {
          ccbCampusIds.push(pair[item].selectedId);
          ids.push(pair[item].id);
          if (pair[item].value === "Saved Searches") {
            type.push("search");
          } else if (Boolean(pair[CCB_GROUPS].isProcessQueueSelected)) {
            type.push("process_queue");
          } else {
            type.push("group");
          }
        }
        if (item === CCB_GROUPS) ccbGroupIds.push(pair[item].selectedId);
        if (item === MAILCHIMP_AUDIENCE)
          mailchimpAudienceIds.push(pair[item].selectedId);
        if (item === MAILCHIMP_GROUPS) {
          syncType.push(pair[item].syncType);
          if (
            pair[item].selectedId != null &&
            pair[item].selectedId?.includes(":::::")
          ) {
            const splitUniqueId = pair[item].selectedId.split(":::::");
            interestCategoryId.push(splitUniqueId[0]);
            interestId.push(splitUniqueId[1]);
          } else {
            interestCategoryId.push("");
            interestId.push("");
          }
          const filterActiveTags = pair[item].tags
            .filter((tag) => tag.active)
            .map((item) => item.name);
          tags.push(filterActiveTags.join());
        }
      })
    );
    setArrayValuesInFormData(syncType, formData, "syncType");

    if (currentSync === MC_TO_CCB) {
      setArrayValuesInFormData(ids, formData, "id");
      setArrayValuesInFormData(ccbGroupIds, formData, "ccbGroupId");
      setArrayValuesInFormData(ccbCampusIds, formData, "campusId");
      setArrayValuesInFormData(mailchimpAudienceIds, formData, "mcListId");
    }

    if (currentSync === CCB_TO_MC) {
      setArrayValuesInFormData(
        mailchimpAudienceIds,
        formData,
        "mailchimpListId"
      );
      setArrayValuesInFormData(ids, formData, "id");
      setArrayValuesInFormData(type, formData, "type");
    }

    if (interestCategoryId.length && interestId.length) {
      syncType.forEach((item, index) => {
        if (item === 0) {
          formData.append(
            `params[${index}][${"interestCategoryId"}]`,
            interestCategoryId[index]
          );
          formData.append(
            `params[${index}][${"interestId"}]`,
            interestId[index]
          );
        }
      });
    }

    if (tags.length) {
      syncType.forEach((item, index) => {
        if (item === 1) {
          formData.append(`params[${index}][${"tags"}]`, tags[index]);
        }
      });
    }
    setResolvedPairsIds(ids);
    if (currentSync === CCB_TO_MC) {
      saveCcbToMcSyncPair(formData, true, ccbCampusIds);
    } else {
      saveMcToCcbSyncPair(formData, true);
    }
  }, [
    changeSyncType,
    closeMainModal,
    currentSync,
    isTwoWayBrokenSyncPairs,
    modalData,
    saveCcbToMcSyncPair,
    saveMcToCcbSyncPair,
  ]);

  const getDisabledCondition = useCallback(
    (item, index) => {
      switch (item) {
        case MAILCHIMP_AUDIENCE: {
          if (
            !modalData[index][CCB_GROUPS].selectedId &&
            currentSync === CCB_TO_MC
          ) {
            return true;
          }
          break;
        }
        case CCB_CAMPUSES: {
          if (currentSync === CCB_TO_MC) {
            return true;
          }
          break;
        }
        case CCB_GROUPS: {
          if (currentSync === CCB_TO_MC) {
            return true;
          }
          break;
        }
        default:
          return false;
      }
    },
    [currentSync, modalData]
  );

  const onCcbCampusAndSavedSearchClicked = useCallback(
    (index, value) => {
      const cloneModalData = deepClone(modalData);
      cloneModalData[index][CCB_CAMPUSES].isSavedSearchSelected = value;
      if (currentSync === CCB_TO_MC) {
        cloneModalData[index][MAILCHIMP_AUDIENCE].selectedId = null;
        cloneModalData[index][MAILCHIMP_AUDIENCE].value = "";
        cloneModalData[index][MAILCHIMP_GROUPS].selectedId = null;
        cloneModalData[index][MAILCHIMP_GROUPS].value = "";
        cloneModalData[index][MAILCHIMP_GROUPS].options = [];
      }
      if (value === 1) {
        cloneModalData[index][CCB_GROUPS].options = ccbSavedSearches;
        cloneModalData[index][CCB_GROUPS].placeholder = "Select Saved Searches";
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].value = "";
        cloneModalData[index][CCB_GROUPS].headerTitle = "CCB Saved Search";
        cloneModalData[index][CCB_CAMPUSES].value = "Saved Searches";
        cloneModalData[index][CCB_CAMPUSES].selectedId = "Saved Searches";
      } else {
        cloneModalData[index][CCB_GROUPS].selectedId = null;
        cloneModalData[index][CCB_GROUPS].value = "";
        cloneModalData[index][CCB_GROUPS].options = [];
        cloneModalData[index][CCB_GROUPS].placeholder = "Select CCB Group";
        cloneModalData[index][CCB_GROUPS].headerTitle = "CCB Groups";
        cloneModalData[index][CCB_CAMPUSES].value = "";
        cloneModalData[index][CCB_CAMPUSES].selectedId = null;
      }
      setModalData(cloneModalData);
    },
    [ccbSavedSearches, currentSync, modalData]
  );

  const getNumberOfResolvedPairs = useMemo(() => {
    if (loading || loadingForPopulatePairs)
      return (
        <div className="flexer ml-2 mr-1">
          <CircularProgress size={15} />
        </div>
      );
    return (
      <b>
        {resolvedNumberOfPairs(modalData)}/{modalData.length}&nbsp;
      </b>
    );
  }, [loading, modalData, loadingForPopulatePairs]);

  const renderActionButtons = useMemo(() => {
    return (
      <div
        className={`mt-5 w-100 d-flex align-items-center ${
          isTwoWayBrokenSyncPairs && currentSync === MC_TO_CCB
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {isTwoWayBrokenSyncPairs && currentSync === MC_TO_CCB && (
          <ButtonWithSpinner
            className="ml-3 dark-gray-button w-15"
            onClick={() => changeSyncType(CCB_TO_MC)}
          >
            Back
          </ButtonWithSpinner>
        )}
        <ButtonWithSpinner
          className="bg-yellow  w-15 mr-3"
          onClick={handleSubmit}
          disabled={loading || disabledCondition}
          loading={disabledCondition}
        >
          {isTwoWayBrokenSyncPairs && currentSync === CCB_TO_MC
            ? "Save And Proceed"
            : "Save"}
        </ButtonWithSpinner>
      </div>
    );
  }, [
    changeSyncType,
    currentSync,
    disabledCondition,
    handleSubmit,
    isTwoWayBrokenSyncPairs,
    loading,
  ]);

  const renderHeaderTextWithIcon = useCallback((icon, text) => {
    return (
      <span className="flexer">
        <img className="mr-1" src={icon} alt={text} /> {text}
      </span>
    );
  }, []);

  const renderArrowForwardIcon = useMemo(() => {
    return <ArrowForward className="mr-2 ml-2" fontSize={"medium"} />;
  }, []);

  const renderCurrentBrokenPairsType = useMemo(() => {
    if (currentSync === CCB_TO_MC) {
      return (
        <span className="flexer ml-2">
          {renderHeaderTextWithIcon(CcbIcon, "CCB")}
          {renderArrowForwardIcon}
          {renderHeaderTextWithIcon(MailchimpIcon, "MailChimp")}
        </span>
      );
    } else {
      return (
        <span className="flexer ml-2">
          {renderHeaderTextWithIcon(MailchimpIcon, "MailChimp")}
          {renderArrowForwardIcon}
          {renderHeaderTextWithIcon(CcbIcon, "CCB")}
        </span>
      );
    }
  }, [currentSync, renderArrowForwardIcon, renderHeaderTextWithIcon]);

  const renderMailchimpTags = useCallback(
    (tags, index) => {
      const filterActiveTags = tags?.filter((item) => item.active);
      if (filterActiveTags?.length && !showSelectMcTagsModal) {
        return (
          <div className="mb-2 flexer-start ml-3 flex-wrap">
            <Chip
              size="small"
              label={filterActiveTags[0].name}
              variant="outlined"
              className="tag-chip"
            />
            <div
              onClick={() => openMcTagsModal(index)}
              className="more-tags ml-2 cursor-pointer"
            >
              {filterActiveTags.length > 1
                ? `+${filterActiveTags?.length - 1}`
                : "Add"}{" "}
              more
            </div>
          </div>
        );
      } else {
        return (
          <Typography
            onClick={() => openMcTagsModal(index)}
            variant="subtitle1"
            className="select-mc-tag"
          >
            <Add fontSize="small" /> Select MC Tags
          </Typography>
        );
      }
    },
    [openMcTagsModal, showSelectMcTagsModal]
  );

  const renderDeleteOption = useCallback(
    (index) => {
      return (
        <LightTooltip
          arrow={true}
          placement={"bottom-end"}
          title="This will Unsync and Remove this Sync pair"
        >
          <DeleteForeverOutlined
            className="cursor-pointer"
            style={{ color: "#FF5F0A" }}
            fontSize="large"
            onClick={() => onClickedDltIcon(index)}
          />
        </LightTooltip>
      );
    },
    [onClickedDltIcon]
  );

  const renderMailChimpAudienceAndTags = useCallback(
    (index, item, itemType) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1">
          <McTagsAndGroupTabBox
            handleMcGroupAndTags={handleMcGroupAndTags}
            syncType={item.syncType}
            index={index}
            currentItem={item}
            className={`w-75`}
            isTagsDisabled={
              !modalData[index][MAILCHIMP_AUDIENCE].selectedId ||
              !isSubscribeHigherPlan
            }
            isSubscribeHigherPlan={isSubscribeHigherPlan}
          >
            <div className="mt-2">
              {item.syncType === 0 ? (
                <CustomAutocompleteSelect
                  classes={{
                    root: "custom-autocomplete-select-container border-none",
                    input: "custom-autocomplete-input",
                  }}
                  Custom
                  placeholder={item.placeholder}
                  options={item.options}
                  displayKey={item.displayKey}
                  loadingText={item.loadingText}
                  identity={MAILCHIMP_GROUPS}
                  handleChange={handleChange}
                  index={index}
                  value={item.value}
                  currentSync={currentSync}
                  isCcbGroupExistInMcGroup={checkIfCcbGroupExistInMcGroups(
                    modalData[index][CCB_GROUPS].value,
                    modalData[index][MAILCHIMP_GROUPS].options
                  )}
                  error={true}
                  loading={
                    selectBoxLoading?.index === index &&
                    selectBoxLoading?.identity &&
                    selectBoxLoading.identity?.includes(MAILCHIMP_GROUPS)
                  }
                  hasExclamanationIcon={true}
                  disabled={
                    getDisabledCondition(itemType, index) || disabledCondition
                  }
                />
              ) : (
                renderMailchimpTags(item.tags, index)
              )}
            </div>
          </McTagsAndGroupTabBox>
          {currentSync === CCB_TO_MC && renderDeleteOption(index)}
          {currentSync === MC_TO_CCB && (
            <ArrowForward style={{ marginRight: "-15px" }} fontSize="large" />
          )}
        </div>
      );
    },
    [
      currentSync,
      handleChange,
      handleMcGroupAndTags,
      isSubscribeHigherPlan,
      modalData,
      renderDeleteOption,
      renderMailchimpTags,
      selectBoxLoading,
      disabledCondition,
      getDisabledCondition,
    ]
  );

  const renderCcbCampusAndSavedSearchBox = useCallback(
    (index, item, itemType) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1 ml-3">
          <CcbCampusAndSavedSearchTabBox
            isSavedSearchSelected={item.isSavedSearchSelected}
            index={index}
            currentItem={item}
            className={`w-80`}
            onClick={onCcbCampusAndSavedSearchClicked}
            isSavedSearchDisabled={
              currentSync === CCB_TO_MC &&
              [CCB_CAMPUSES, CCB_GROUPS].includes(itemType)
            }
          >
            <div
              className={`mt-2 ${
                item.isSavedSearchSelected === 1 ? "mb-2" : ""
              }`}
            >
              {item.isSavedSearchSelected === 0 ? (
                <CustomAutocompleteSelect
                  classes={{
                    root: "custom-autocomplete-select-container border-none",
                    input: "custom-autocomplete-input",
                  }}
                  placeholder={item.placeholder}
                  options={item.options}
                  displayKey={item.displayKey}
                  loadingText={item.loadingText}
                  identity={CCB_CAMPUSES}
                  handleChange={handleChange}
                  isSavedSearchSelected={
                    modalData[index][CCB_CAMPUSES].value === "Saved Searches"
                  }
                  index={index}
                  value={item.value}
                  disabled={
                    getDisabledCondition(itemType, index) || disabledCondition
                  }
                  currentSync={currentSync}
                  loading={
                    selectBoxLoading?.index === index &&
                    selectBoxLoading?.identity &&
                    selectBoxLoading.identity?.includes(CCB_CAMPUSES)
                  }
                  error={true}
                  hasExclamanationIcon={true}
                />
              ) : (
                <span className="text-muted ml-2">Saved Search</span>
              )}
            </div>
          </CcbCampusAndSavedSearchTabBox>
        </div>
      );
    },
    [
      currentSync,
      getDisabledCondition,
      handleChange,
      modalData,
      onCcbCampusAndSavedSearchClicked,
      selectBoxLoading,
      disabledCondition,
    ]
  );

  const renderCcbGroupsAndProcessQueueBox = useCallback(
    (index, item) => {
      return (
        <div className="w-100 flexer-space-between select-wrapper mt-1 ml-3">
          <CcbGroupsAndProcessQueue
            isProcessQueueSelected={item.isProcessQueueSelected}
            index={index}
            currentItem={item}
            className={`w-80`}
            onClick={() => {}}
            isProcessQueueDisabled={true}
          >
            <div className={`mt-2`}>
              <CustomAutocompleteSelect
                classes={{
                  root: "custom-autocomplete-select-container border-none",
                  input: "custom-autocomplete-input",
                }}
                placeholder={item.placeholder}
                options={
                  Boolean(item.isProcessQueueSelected)
                    ? item.processQueueOptions
                    : item.options
                }
                displayKey={item.displayKey}
                isProcessQueueSelected={item.isProcessQueueSelected}
                loadingText={item.loadingText}
                identity={CCB_GROUPS}
                handleChange={handleChange}
                index={index}
                value={item.value}
                disabled={true}
                currentSync={currentSync}
                loading={
                  selectBoxLoading?.index === index &&
                  selectBoxLoading?.identity &&
                  selectBoxLoading.identity?.includes(CCB_CAMPUSES)
                }
              />
            </div>
          </CcbGroupsAndProcessQueue>
          <ShowSyncArrow
            className={"mb-3 mr-2"}
            fontSize={"large"}
            hasOneWayIcon={Boolean(item.isProcessQueueSelected)}
          />
        </div>
      );
    },
    [currentSync, handleChange, selectBoxLoading]
  );

  const renderSyncBoxesContent = useCallback(
    (item, pair, index, idx, subPair) => {
      if (item === MAILCHIMP_GROUPS) {
        return renderMailChimpAudienceAndTags(index, pair, item);
      } else if (currentSync === CCB_TO_MC && item === CCB_CAMPUSES) {
        return renderCcbCampusAndSavedSearchBox(index, pair, item);
      } else if (
        currentSync === CCB_TO_MC &&
        item === CCB_GROUPS &&
        subPair[CCB_CAMPUSES].isSavedSearchSelected === 0
      ) {
        return renderCcbGroupsAndProcessQueueBox(index, pair);
      } else {
        return (
          <div className="w-100 d-flex justify-content-between align-items-end">
            <div className="select-wrapper w-85">
              <CustomAutocompleteSelect
                classes={{
                  root: "custom-autocomplete-select-container",
                  input: "custom-autocomplete-input",
                }}
                placeholder={pair.placeholder}
                options={pair.options}
                displayKey={pair.displayKey}
                loadingText={pair.loadingText}
                identity={item}
                handleChange={handleChange}
                isSavedSearchSelected={
                  subPair[CCB_CAMPUSES].value === "Saved Searches"
                }
                index={index}
                value={pair.value}
                disabled={
                  getDisabledCondition(item, index) || disabledCondition
                }
                currentSync={currentSync}
                loading={
                  selectBoxLoading?.index === index &&
                  selectBoxLoading?.identity &&
                  selectBoxLoading.identity?.includes(item)
                }
                error={true}
                hasExclamanationIcon={true}
              />
            </div>
            {idx === 1 && (
              <ShowSyncArrow
                hasOneWayIcon={Boolean(
                  subPair[CCB_CAMPUSES].isSavedSearchSelected
                )}
                className={"mb-3 mr-2"}
                fontSize={"large"}
              />
            )}
            {item === CCB_GROUPS && currentSync === MC_TO_CCB && (
              <div className="mr-2 mb-4">{renderDeleteOption(index)}</div>
            )}
          </div>
        );
      }
    },
    [
      currentSync,
      disabledCondition,
      getDisabledCondition,
      handleChange,
      renderCcbCampusAndSavedSearchBox,
      renderCcbGroupsAndProcessQueueBox,
      renderDeleteOption,
      renderMailChimpAudienceAndTags,
      selectBoxLoading,
    ]
  );

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {}}
        fullWidth={true}
        maxWidth={"xl"}
        classes={{
          paperFullWidth: "add-new-sync-pair-mobile-wrapper",
        }}
      >
        <DialogTitle className="flexer" id="alert-dialog-title">
          <span className="modal-main-heading flexer">
            Resolve Broken Sync Pair For {renderCurrentBrokenPairsType}
          </span>
          <Close onClick={closeMainModal} className="close-icon" />
          {isTwoWayBrokenSyncPairs && (
            <NumberSteps
              currentStep={currentSync === CCB_TO_MC ? 1 : 2}
              steps={2}
              onClickStep={() => {}}
              showNumbers={false}
              barType={"small"}
              type="small"
              ulClassName="mt-3"
            />
          )}
          <Typography
            variant="caption"
            className="text-muted w-100 flexer mb-3"
          >
            Resolved: {getNumberOfResolvedPairs} Pairs
          </Typography>
          <Typography variant="subtitle1" className="text-muted text-center">
            The following lists are broken or have been removed. Please fix this
            issue to sync these pairs
          </Typography>
        </DialogTitle>

        <>
          <DialogContent className="p-0 mt-2 content-container">
            {loading || loadingForPopulatePairs ? (
              <div className="w-100 flexer overflow-hidden">
                <CircularProgress size={40} />
              </div>
            ) : (
              <List
                height={600} // Adjust according to your UI
                itemCount={modalData.length}
                itemSize={180} // Adjust based on item height
                width={"100%"}
              >
                {({ index, style }) => (
                  <div
                    style={style}
                    className="flexer"
                    key={modalData[index]?.id}
                  >
                    {Object.keys(modalData[index]).map((item, idx) => (
                      <SyncOptionBox
                        index={index}
                        headerTitle={modalData[index][item].headerTitle}
                        icon={modalData[index][item].icon}
                        key={idx}
                        withConstantHeight={true}
                      >
                        {renderSyncBoxesContent(
                          item,
                          modalData[index][item],
                          index,
                          idx,
                          modalData[index]
                        )}
                      </SyncOptionBox>
                    ))}
                  </div>
                )}
              </List>
            )}
          </DialogContent>
          <DialogActions className="mb-4">{renderActionButtons}</DialogActions>
        </>
      </Dialog>

      {showSelectMcTagsModal && (
        <SelectMcTagsModal
          open={showSelectMcTagsModal}
          handleClose={closeMcTagsModal}
          tags={mailChimpTags.sort((x, y) =>
            x.active === y.active ? 0 : x.active ? -1 : 1
          )}
          handleCheck={handleCheck}
          appendNewTag={appendNewTag}
        />
      )}

      {showAllMemberConfirmationModal && (
        <ConfirmationModal
          handleClose={closeAllMemberConfirmationModal}
          open={showAllMemberConfirmationModal}
          modalHeading="Confirm!"
          modalContent={ReactHtmlParser(allMemberConfirmationCotent)}
          btnLabel="Proceed"
          cancelBtnLabel="Nevermind"
          btnClassName="w-30 mr-3 bg-yellow"
          onClick={() => {
            handleCcbGroupChange(
              dataForAllMemberConfirmationModal,
              modalData,
              dataForAllMemberConfirmationModal.index,
              true
            );
            closeAllMemberConfirmationModal();
          }}
        />
      )}

      {/* Remove sync pair modal */}
      {showRemoveSyncPairModal && (
        <RemoveSyncPairConfirmationModal
          open={showRemoveSyncPairModal}
          handleClose={toggleRemoveSyncPairModal}
          pairToRemove={dataForRemoveSyncPairModal}
          currentSync={currentSync}
          loading={[removeCcbToMcPairStatus, removeMcToCcbPairStatus].includes(
            "loading"
          )}
        />
      )}
    </>
  );
};

ResolveBrokenPairsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  callIntialApis: PropTypes.func.isRequired,
  mcToCcbPairs: PropTypes.array,
  prePopulatedPairs: PropTypes.array,
};

ResolveBrokenPairsModal.defaultProps = {
  mcToCcbPairs: [],
  prePopulatedPairs: [],
};

const mapStateToProps = (store) => {
  return {
    ccbSavedSearches: store.integrations.ccbSavedSearches,
    currentSync: store.integrations.currentSync,
    currentPlan: store.billing.currentPlan,
    globalModalData: store.globalReducer.modalData,
    loading: store.globalReducer.isLoading,
    removeCcbToMcPairStatus: store.integrations.removeCcbToMcPairStatus,
    removeMcToCcbPairStatus: store.integrations.removeMcToCcbPairStatus,
    saveMcToCcbSyncPairStatus: store.integrations.saveMcToCcbSyncPairStatus,
    saveCcbToMcSyncPairStatus: store.integrations.saveCcbToMcSyncPairStatus,
    mailchimpInterestedGroups: store.integrations.mailchimpInterestedGroups,
    ccbGroups: store.integrations.ccbGroups,
    mailchimpTags: store.integrations.mailchimpTags,
    mailchimpListIdForTags: store.integrations.mailchimpListIdForTags,
    mailchimpTagsStatus: store.integrations.mailchimpTagsStatus,
    ccbGroupStatus: store.integrations.ccbGroupStatus,
    mailchimpInterestedGroupsStatus:
      store.integrations.mailchimpInterestedGroupsStatus,
    mailchimplistIdForInterestedGroups:
      store.integrations.mailchimplistIdForInterestedGroups,
    ccbProcessQueues: store.integrations.ccbProcessQueues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    saveCcbToMcSyncPair: (data, isFixingBrokenPairs, ids) =>
      dispatch(saveCcbToMcSyncPair(data, isFixingBrokenPairs, ids)),
    saveMcToCcbSyncPair: (data, isFixingBrokenPairs) =>
      dispatch(saveMcToCcbSyncPair(data, isFixingBrokenPairs)),
    changeSyncType: (sync) => dispatch(changeSyncType(sync)),
    setLoading: (val) => dispatch(globalLoading(val)),
    clearRemoveCcbToMcPair: () => dispatch(clearRemoveCcbToMcPair()),
    clearRemoveMcToCcbPair: () => dispatch(clearRemoveMcToCcbPair()),
    clearSaveMcToCcbPairStatus: () => dispatch(clearSaveMcToCcbPairStatus()),
    clearSaveCcbToMcPairsSataus: () => dispatch(clearSaveCcbToMcPairsSataus()),
    setDataForModal: (val) => dispatch(setDataForModal(val)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    setMailchimpInterestedGroups: (data) =>
      dispatch(setMailchimpInterestedGroups(data)),
    setCcbGroups: (data) => dispatch(setCcbGroups(data)),
    setMailchimpTags: (data) => dispatch(setMailchimpTags(data)),
    setCcbProcessQueue: (data) => dispatch(setCcbProcessQueue(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResolveBrokenPairsModal);
