import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { formDataWithApiKey } from "../../helpers";

function CouponTextField(props) {
  const [coupon, setCouponValue] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    isValidCoupon,
    checkCoupon,
    checkCouponStatus,
    subscriptionPlan,
    couponPlan,
  } = props;

  useEffect(() => {
    if (["success", "error"].includes(checkCouponStatus)) {
      setLoading(false);
    }
  }, [checkCouponStatus]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    const timeoutId = setTimeout(() => {
      if (coupon) {
        setLoading(true);
        formData.append("code", coupon);
        checkCoupon(formData, subscriptionPlan);
      }
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [checkCoupon, coupon, subscriptionPlan]);

  const handleOnChange = (event) => {
    setCouponValue(event.target.value);
  };

  const getHelperText = useCallback(() => {
    if (
      isValidCoupon != null &&
      coupon &&
      checkCouponStatus !== "loading" &&
      checkCouponStatus === "success" &&
      couponPlan?.id === subscriptionPlan.id
    ) {
      if (!isValidCoupon) {
        return <strong className="text-danger">Invalid Coupon</strong>;
      } else {
        return <strong className="text-success">Valid Coupon</strong>;
      }
    }
    return "";
  }, [checkCouponStatus, coupon, couponPlan, isValidCoupon, subscriptionPlan]);

  return (
    <>
      <TextField
        placeholder="Coupon code"
        fullWidth
        name={"coupon"}
        value={coupon}
        classes={{
          root: "text-field mt-2",
        }}
        InputProps={{
          classes: {
            root: "input-wrapper-coupan",
            input: "input",
          },
          endAdornment: loading ? (
            <InputAdornment className="mr-2" position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : null,
        }}
        onChange={handleOnChange}
        color="success"
        error={
          !isValidCoupon &&
          isValidCoupon != null &&
          coupon &&
          checkCouponStatus !== "loading"
        }
        helperText={getHelperText()}
      />
    </>
  );
}

export default CouponTextField;
