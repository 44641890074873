import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dialog, Typography, Slide, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import ToolsBreadCrumb from "../ToolsBreadCrumb/ToolsBreadCrumb";
import ToolsTableBody from "../ToolsTableBody";
import { formDataWithApiKey } from "../../../helpers";
import Loader from "../../Loader/Loader";
import EmptyMessageBox from "../../EmptyMessageBox/EmptyMessageBox";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import {
  deleteMcCleanupPair,
  dismissDeleteMcPair,
  getCleanupUnsyncPairs,
} from "../../../store/middlewares/toolsMiddleware";
import { setSnackbarData } from "../../../store/actions/globalAction";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";
import "../../../styles/components/payment-form.scss";
import {
  clearDeleteMcCleanupPair,
  clearDismissDeleteMc,
} from "../../../store/actions/toolsActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cells = [
  {
    text: "CCB Campus Name",
  },
  {
    text: "CCB Group(s) / Saved Searches",
  },
  {
    text: "MailChimp List Name",
  },
  {
    text: "MC Group(s) / Tag(s) / Audiences (groups, tags, etc)",
  },
  {
    text: "Action",
  },
];

const breadcrumbs = [
  {
    text: `Confirm Delete`,
    route: `/tools/confirm-delete`,
  },
];

const CleanupUnsyncPair = (props) => {
  const {
    // component props
    history,

    // Redux funcions
    setSnackbarData,
    getCleanupUnsyncPairs,
    deleteMcCleanupPair,
    dismissDeleteMcPair,
    clearDeleteMcCleanupPair,
    clearDismissDeleteMc,

    // Redux props
    cleanupUnsyncPairs,
    cleanupUnsyncPairsStatus,
    deleteMcSyncPairStatus,
    dismissDeleteMcStatus,
  } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [confirmationModalItem, setConfirmationModalItem] = useState({});

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if ([deleteMcSyncPairStatus, dismissDeleteMcStatus].includes("success")) {
      closeConfirmationModal();
      showSnackbar(`Operation Successful!`, "success");
      getCleanupUnsyncPairs(formData);
      clearDeleteMcCleanupPair();
      clearDismissDeleteMc();
    }
  }, [
    clearDeleteMcCleanupPair,
    clearDismissDeleteMc,
    closeConfirmationModal,
    deleteMcSyncPairStatus,
    dismissDeleteMcStatus,
    getCleanupUnsyncPairs,
    showSnackbar,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getCleanupUnsyncPairs(formData);
  }, [getCleanupUnsyncPairs]);

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleItemClicked = useCallback((item, isDismissClicked) => {
    let modalContent;
    let modalHeading;
    if (isDismissClicked) {
      modalContent = `Are you sure want to dismiss this delete?`;
      modalHeading = `Confirm Dismiss`;
    } else {
      modalContent = `This would delete '${item.mcGroupTagDisplay}' group AND the email addresses in ${item.mcListName} audience. This can't be undone`;
      modalHeading = `Confirm Mailchimp Cleanup`;
    }
    setModalContent(modalContent);
    setModalHeading(modalHeading);
    setConfirmationModalItem(item);
    setShowConfirmationModal(true);
  }, []);

  const onConfirmClicked = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("id", confirmationModalItem.id);
    formData.append("type", confirmationModalItem.type);
    if (modalHeading.includes("Dismiss")) {
      dismissDeleteMcPair(formData);
    } else {
      deleteMcCleanupPair(formData);
    }
  }, [
    confirmationModalItem.id,
    confirmationModalItem.type,
    deleteMcCleanupPair,
    dismissDeleteMcPair,
    modalHeading,
  ]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">
              Confirm Cleanup of Unsynced CCB-MC pairs
            </Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <ToolsBreadCrumb breadcrumbs={breadcrumbs} />
          </div>
          <div className="flexer-column mt-4">
            <div className="user-settings-greetings">
              {cleanupUnsyncPairsStatus === "loading" ? (
                <div className="d-flex justify-content-center spinner-container">
                  <Loader />
                </div>
              ) : (
                <TableInfinityScroll
                  className="logs-modal-table"
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                    />
                    <ToolsTableBody
                      data={cleanupUnsyncPairs}
                      tableIdentity="cleanupUnsyncPair"
                      handleItemClicked={handleItemClicked}
                    />
                  </table>
                  {!Boolean(cleanupUnsyncPairs.length) && (
                    <EmptyMessageBox
                      message="No CCB to MC Settings"
                      hasBorder={false}
                    />
                  )}
                </TableInfinityScroll>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          modalHeading={modalHeading}
          handleClose={closeConfirmationModal}
          modalContent={modalContent}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30"}
          onClick={onConfirmClicked}
          loading={[deleteMcSyncPairStatus, dismissDeleteMcStatus].includes(
            "loading"
          )}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    cleanupUnsyncPairsStatus: store.tools.cleanupUnsyncPairsStatus,
    cleanupUnsyncPairs: store.tools.cleanupUnsyncPairs,
    deleteMcSyncPairStatus: store.tools.deleteMcSyncPairStatus,
    dismissDeleteMcStatus: store.tools.dismissDeleteMcStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getCleanupUnsyncPairs: (data) => dispatch(getCleanupUnsyncPairs(data)),
    deleteMcCleanupPair: (data) => dispatch(deleteMcCleanupPair(data)),
    dismissDeleteMcPair: (data) => dispatch(dismissDeleteMcPair(data)),
    clearDeleteMcCleanupPair: () => dispatch(clearDeleteMcCleanupPair()),
    clearDismissDeleteMc: () => dispatch(clearDismissDeleteMc()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CleanupUnsyncPair));
