import React, { useMemo } from "react";
import { tableHeaders } from "./syncListHeaderConstant";
import useIntegrationStore from "../../../store/reduxStore/integrationsStore";
import PropType from "prop-types";
import { Typography, IconButton } from "@material-ui/core";
import { Search, Refresh } from "@material-ui/icons";
import CcbIcon from "../../../assets/img/ccb/ccb-small.png";
import MailchimpIcon from "../../../assets/img/mailchimp/mailchimp-small.png";
import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
} from "../../../utils/integrationsConstants";
import SearchBox from "../../SearchBox/SearchBox";
import IntegrationFilterChecklist from "../IntegrationFilterChecklist/IntegrationFilterChecklist";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import "../../../styles/components/services/header-services.scss";

const SyncHeaderCell = (props) => {
  const {
    headerIdentity,
    handleSearch,
    searchValue,
    handleReload,
    isIntegrationsDisabled,
    handleFilteration,
    pairFilters,
    className,
    activeTab,
  } = props;
  const idxToShowReloadIcon = useMemo(
    () => (headerIdentity === "ccb" ? 0 : 1),
    [headerIdentity]
  );

  const breakpoint = useBreakpoint();

  // Redux props
  const { integrations, setShowSearchFor } = useIntegrationStore();
  const { showSearchFor } = integrations;

  const getHeaderTitlle = (item, activeTab) => {
    if (activeTab === MC_TO_CCB && item.identity === CCB_CAMPUSES) {
      return "Campuses";
    } else if (activeTab === MC_TO_CCB && item.identity === CCB_GROUPS) {
      return "Groups";
    }
    return item.title;
  };

  return tableHeaders[headerIdentity].map((item, idx) => {
    if (showSearchFor === item.identity) {
      return (
        <td
          key={idx}
          className={`d-flex align-items-center justify-content-between ${className}`}
        >
          <div className="header-services">
            <div className="search-bar flexer w-100">
              <SearchBox
                handleChange={handleSearch}
                name={item.identity}
                closeSearch={setShowSearchFor}
                searchValue={searchValue}
              />
              <IconButton
                color="inherit"
                className={isMdOrBelowBreakpoint(breakpoint) ? "ml-0" : "ml-3"}
                onClick={handleReload}
              >
                <Refresh color="inherit" />
              </IconButton>
            </div>
          </div>
        </td>
      );
    } else {
      return (
        <td
          key={idx}
          className={`d-flex align-items-center justify-content-between ${className}`}
        >
          <Typography noWrap={true} className={`title`} component="h2">
            <img
              src={headerIdentity === "ccb" ? CcbIcon : MailchimpIcon}
              className="mr-1"
              alt="icon"
            />{" "}
            {getHeaderTitlle(item, activeTab)}
          </Typography>
          <div className="flexer">
            <IconButton
              disabled={isIntegrationsDisabled}
              size="small"
              className={idx === idxToShowReloadIcon ? "" : "mr-2"}
              color="inherit"
              onClick={() => setShowSearchFor(item.identity)}
            >
              <Search color="inherit" />
            </IconButton>
            {idx === idxToShowReloadIcon && (
              <IntegrationFilterChecklist
                handleFilteration={handleFilteration}
                identity={
                  headerIdentity === "ccb" ? CCB_CAMPUSES : MAILCHIMP_GROUPS
                }
                disabled={isIntegrationsDisabled}
                pairFilters={pairFilters}
              />
            )}
          </div>
        </td>
      );
    }
  });
};

SyncHeaderCell.defaultProps = {
  headerIdentity: "",
  searchValue: "",
  isIntegrationsDisabled: false,
  className: "",
};

SyncHeaderCell.propTypes = {
  handleFilteration: PropType.func.isRequired,
  handleSearch: PropType.func.isRequired,
  handleReload: PropType.func.isRequired,
  headerIdentity: PropType.string,
  searchValue: PropType.string,
  className: PropType.string,
  isIntegrationsDisabled: PropType.bool,
};

export default SyncHeaderCell;
