import { useEffect, useState } from "react";
import { getBreakPoints } from "../../helpers";
import { useWindowSize } from "./useWindowSize";

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState("");
  const { width } = useWindowSize();

  useEffect(() => {
    const newBreakPoint = getBreakPoints(width);
    setBreakPoint(newBreakPoint);
  }, [width]);
  return breakpoint;
};

export default useBreakpoint;
