import { userLoginFail, userLoginSuccess } from "../actions/userActions";
import config from "../../config";
import { postRequest } from "../../utils/request";
import services from "../../services";
import { globalLoading } from "../actions/globalAction";
import { ACTION_TYPES } from "../actions/types";
const {
  api,
  storageManager: { local },
} = services;

export const userLoginMiddleware = (userData) => async (dispatch) => {
  try {
    dispatch(globalLoading(true));
    const responseBack = await api.userLogin(userData);
    dispatch(globalLoading(false));
    if (responseBack.status) {
      local.set("token", responseBack.data.apiKey);
      local.set("user", JSON.stringify(responseBack.data));
      dispatch(userLoginSuccess(responseBack.data));
    } else {
      dispatch(
        userLoginFail({
          message: responseBack.message,
        })
      );
    }
  } catch (err) {
    dispatch(globalLoading(false));
    dispatch(
      userLoginFail({
        message: "Server Error please try again",
        err: err.message,
      })
    );
  }
};

export function updateUserProfile(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.UPDATE_PROFILE,
      config.Path.UPDATE_PROFILE,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function updateUserCountry(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.UPDATE_COUNTRY,
      config.Path.UPDATE_COUNTRY,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getUserApiDetails(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_API_DETAILS,
      config.Path.GET_API_DETAILS,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function clearUpdateProfile(requestId) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PROFILE_CLEAR,
      requestId,
    });
  };
}

export function authorizeCcbAccount(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT,
      config.Path.AUTHORIZE_CCB_ACCOUNT,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function authorizeMcAccount(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.AUTHORIZE_MC_ACCOUNT,
      config.Path.AUTHORIZE_MC_ACCOUNT,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function removeCcbAccountCredentials(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_CCB_CREDENTIALS,
      config.Path.REMOVE_CCB_CREDENTIALS,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function removeMcAccountCredentials(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REMOVE_MC_CREDENTIALS,
      config.Path.REMOVE_MC_CREDENTIALS,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function disableCcbSyncing(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.DISABLE_CCB_SYNCING,
      config.Path.DISABLE_CCB_SYNCING,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function enableCcbSyncing(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ENABLE_CCB_SYNCING,
      config.Path.ENABLE_CCB_SYNCING,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function enableUserStoreInfo(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.ENABLE_USER_STORE_INFO,
      config.Path.ENABLE_STORE_USER_INFO,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function disableUserStoreInfo(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.DISABLE_USER_STORE_INFO,
      config.Path.DISABLE_STORE_USER_INFO,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function checkSyncingStatus(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.CHECK_SYNC,
      config.Path.CHECK_SYNC,
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function checkAutoSyncingStatus(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.IS_AUTO_SYNCING,
      config.Path.IS_AUTO_SYNCING,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getWarnings(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_WARNINGS,
      config.Path.GET_WARNINGS,
      data,
      {
        requestDispatchData: {},
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function getDashboardData(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_DASHBOARD_DATA,
      config.Path.GET_DASHBOARD_DATA,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}

export function getIntercomAppId(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_INTERCOM_APP_ID,
      config.Path.GET_INTERCOM_APP_ID,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: { data },
      }
    );
}
