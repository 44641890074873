import config from "../../../config";
import { postRequest } from "../../../utils/request";
import { ACTION_TYPES } from "../../actions/types";

export function getAllUsers(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_ALL_USERS,
      config.Path.GET_ALL_USERS,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function getPendingUsers(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_PENDING_USERS,
      config.Path.GET_PENDING_USERS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function createUser(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.CREATE_USER,
      config.Path.CREATE_USER,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}

export function approveUser(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.APPROVE_USER,
      config.Path.APPROVE_USER,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}
