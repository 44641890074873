import React, { useEffect, useCallback, useState, Fragment } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import Loader from "../../components/Loader/Loader";
import TableInfinityScroll from "../../components/Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../components/Table/TableHeader/TableHeader";
import LogsTableBody from "../../components/LogsComponents/LogsTableBody";
import {
  getLogDetails,
  getLogs,
  revertLogs,
  searchLogs,
} from "../../store/middlewares/logsMiddleware";
import {
  formatDateAndTime,
  formDataWithApiKey,
  getRecentLogIds,
} from "../../helpers";
import {
  clearLogReverting,
  setLogsDetails,
  setLogSearchTerm,
} from "../../store/actions/logsAction";
import LogSearchBox from "../../components/LogsComponents/LogSearchBox/LogSearchBox";
import ConfirmationModal from "../../components/modals/ConfirmationModal/ConfirmationModal";
import { useComponentWillUnmount } from "../../utils/hooks/useComponentWillUnmount";
import { syncManual } from "../../store/middlewares/integrationMiddleware";
import "../../styles/components/more/user-settings.scss";
import { AUTOMATIC_CLEANUP_SYNC } from "../../utils/constants";
import MemberShipFiltersModal from "../../components/modals/MemberShipFitlersModal/MemberShipFiltersModal";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";

const logRollbackSuccessMessage = `Syncing has completed successfully. It may take up to 30 minutes to appear on Mailchimp. Do you want to reload the page now?`;

const cells = [
  {
    text: "Date",
  },
  {
    text: "Type",
  },
  {
    text: "Action",
  },
  {
    text: "Details",
  },
  {},
];

const Logs = (props) => {
  const {
    // Component props
    history,

    // Redux functions
    getLogs,
    setLogsDetails,
    setLogSearchTerm,
    searchLogs,
    revertLogs,
    clearLogReverting,
    syncManual,

    // Redux Props
    logs,
    logsStatus,
    logsLoadedAll,
    logsPage,
    logsLoadingMoreStatus,
    userData,
    revertLogStatus,
    isLogReverting,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [showRevertLogsConfirmationModal, setShowRevertLogsConfirmationModal] =
    useState(false);
  const [modalContent, setModalContent] = useState("");
  const [logToRevert, setLogToRevert] = useState({});
  const [rollbackLogsIds, setRollbackLogsIds] = useState([]);
  const [logFilterMembershipData, setLogFilterMembershipData] = useState(null);

  const isSidebarExpanded = useIsSidebarExpanded();

  const closeRevertLogConfirmationModal = useCallback(() => {
    setShowRevertLogsConfirmationModal(false);
  }, []);

  useComponentWillUnmount(() => {
    clearLogReverting();
  }, []);

  useEffect(() => {
    // SET ROLLBACK LOGS IDS
    if (logsStatus === "success") {
      const recentLogIds = getRecentLogIds(
        logs,
        userData?.numberOfAllowedRestore
      );
      setRollbackLogsIds(recentLogIds);
    }
  }, [logs, logsStatus, userData?.numberOfAllowedRestore]);

  useEffect(() => {
    if (revertLogStatus === "loading") {
      closeRevertLogConfirmationModal();
    }
  }, [closeRevertLogConfirmationModal, revertLogStatus]);

  useEffect(() => {
    if (!isLogReverting) {
      setShowRevertLogsConfirmationModal(true);
      setModalContent(logRollbackSuccessMessage);
      clearLogReverting();
    }
  }, [clearLogReverting, isLogReverting]);

  useEffect(() => {
    if (revertLogStatus === "success") {
      const formData = formDataWithApiKey();
      syncManual(formData);
      clearLogReverting();
    }
  }, [clearLogReverting, revertLogStatus, syncManual]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("page", logsPage ? Number(logsPage) : 1);
    getLogs(formData);
    clearLogReverting();
  }, [clearLogReverting, getLogs, logsPage]);

  const loadMore = useCallback(() => {
    if (
      !logsLoadedAll &&
      logsStatus !== "loading" &&
      logsStatus !== "error" &&
      logs
    ) {
      const formData = formDataWithApiKey();
      const pageNum = Number(logsPage) + 1;
      formData.append("page", pageNum);
      getLogs(formData);
    }
  }, [getLogs, logs, logsLoadedAll, logsPage, logsStatus]);

  const handleLogDetailsClicked = useCallback(
    (log, index) => {
      const cleanupSync = logs[index + 1];
      history.push(`/logs/details/${log.id}`);
      setLogsDetails({
        log,
        cleanupSync:
          !lodash.isEmpty(cleanupSync) &&
          cleanupSync.type === AUTOMATIC_CLEANUP_SYNC
            ? cleanupSync
            : {},
      });
    },
    [history, logs, setLogsDetails]
  );

  const handleLogSearch = useCallback(() => {
    if (searchTerm) {
      const formData = formDataWithApiKey();
      formData.append("search", searchTerm);
      setLogSearchTerm(searchTerm);
      searchLogs(formData, null);
      history.push(`/logs/search-results`, { searchTerm });
    }
  }, [history, searchLogs, searchTerm, setLogSearchTerm]);

  const handleChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleRevertLogs = useCallback((log) => {
    const content = `You are attempting to roll back all the changes you made from this sync ${formatDateAndTime(
      log.date
    )}. We recommend that you contact your CCB rep to increase your API limit temporary to allow this process to occur.`;
    setModalContent(content);
    setShowRevertLogsConfirmationModal(true);
    setLogToRevert(log);
  }, []);

  const onRevertLogConfirm = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("logId", logToRevert.id);
    revertLogs(formData);
  }, [logToRevert, revertLogs]);

  const onClickFiltering = useCallback(
    (data) => () => {
      setLogFilterMembershipData(data);
    },
    []
  );

  const closeFitlerMembershipModal = useCallback(() => {
    setLogFilterMembershipData(null);
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  const loadingMore = logsLoadingMoreStatus === "loading";

  return (
    <Fragment>
      <div
        className={clsx("page-wrapper my-profile", {
          "with-sidebar-expanded": isSidebarExpanded,
          "with-sidebar-collapsed": !isSidebarExpanded,
        })}
      >
        <div className="desktop-design">
          <Typography className="desktop-title ml-2" component={"h2"}>
            Logs
          </Typography>
          <LogSearchBox
            placeholder="Search email or name"
            onClick={handleLogSearch}
            handleChange={handleChange}
            name="log-search"
          />
          <div className="user-settings-greetings mt-2">
            {logsStatus === "loading" && !loadingMore ? (
              <div className="d-flex justify-content-center spinner-container">
                <Loader />
              </div>
            ) : (
              <TableInfinityScroll
                loadMore={loadMore}
                page={1}
                fetchFunction={() => {}}
              >
                <table className="table">
                  <TableHeader
                    className="user-settings-greetings-table-header"
                    cells={cells}
                  />
                  {Boolean(logs?.length) ? (
                    <LogsTableBody
                      data={logs}
                      handleItemClicked={() => {}}
                      isLoading={loadingMore}
                      onDetailsClick={handleLogDetailsClicked}
                      rollbackLogsIds={rollbackLogsIds}
                      handleRevertLogs={handleRevertLogs}
                      onClickFiltering={onClickFiltering}
                    />
                  ) : (
                    <tr className="text-center">
                      <td colSpan={5}>No Logs found</td>
                    </tr>
                  )}
                </table>
              </TableInfinityScroll>
            )}
          </div>
        </div>
      </div>
      {showRevertLogsConfirmationModal && (
        <ConfirmationModal
          open={showRevertLogsConfirmationModal}
          handleClose={closeRevertLogConfirmationModal}
          modalHeading={"Confirmation"}
          modalContent={modalContent}
          btnLabel={modalContent.includes("Mailchimp") ? "Reload" : "Proceed"}
          onClick={
            modalContent.includes("Mailchimp") ? reloadPage : onRevertLogConfirm
          }
          btnClassName={"bg-yellow w-30 mr-3"}
          loading={revertLogStatus === "loading"}
        />
      )}
      {logFilterMembershipData && (
        <MemberShipFiltersModal
          open={typeof logFilterMembershipData === "object"}
          handleClose={closeFitlerMembershipModal}
          data={logFilterMembershipData}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    logs: store.logs.logs,
    logsStatus: store.logs.logsStatus,
    logsLoadedAll: store.logs.logsLoadedAll,
    logsPage: store.logs.logsPage,
    logsLoadingMoreStatus: store.logs.logsLoadingMoreStatus,
    logDetails: store.logs.logDetails,
    isLogReverting: store.logs.isLogReverting,
    revertLogStatus: store.logs.revertLogStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogs: (data, requestId) => dispatch(getLogs(data, requestId)),
    setLogsDetails: (log) => dispatch(setLogsDetails(log)),
    getLogDetails: (data, requestId) =>
      dispatch(getLogDetails(data, requestId)),
    setLogSearchTerm: (data) => dispatch(setLogSearchTerm(data)),
    searchLogs: (data, requestId) => dispatch(searchLogs(data, requestId)),
    revertLogs: (data, requestId) => dispatch(revertLogs(data, requestId)),
    clearLogReverting: () => dispatch(clearLogReverting()),
    syncManual: (data) => dispatch(syncManual(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
