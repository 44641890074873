import React, { useMemo, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import {
  Dialog,
  Typography,
  Slide,
  DialogContent,
  DialogActions,
  DialogTitle,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { Close, Add, DeleteForeverOutlined } from "@material-ui/icons";
import { setSnackbarData } from "../../../store/actions/globalAction";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import {
  addNewKeyPairInArray,
  checkSubscribePlanAmount,
  deepClone,
  formDataWithApiKey,
  getCurrentException,
  populateMcToCcbSyncPairsOnly,
  setArrayValuesInFormData,
} from "../../../helpers";
import CustomAutocompleteSelect from "../../../HelperComponents/CustomAutocompleteSelect";
import { toolExceptionModalData } from "./toolExceptionModalData";
import {
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
} from "../../../utils/integrationsConstants";
import McTagsAndGroupTabBox from "../../modals/IntegrationsPageModal/AddNewSyncPairModal/McTagsAndGroupTabBox";
import SelectMcTagsModal from "../../modals/IntegrationsPageModal/SelectMcTagsModal/SelectMcTagsModal";
import "../../../styles/components/headerMapping.scss";
import {
  getMailchimpAudience,
  setMailchimpInterestedGroups,
  setMailchimpTags,
} from "../../../store/middlewares/integrationMiddleware";
import { UseSyncOptions } from "../../../utils/hooks/useSyncOptions";
import services from "../../../services";
import {
  addMcToCcbCustomList,
  deleteMcToCcbCustomList,
  getMcToCcbCustomList,
} from "../../../store/middlewares/toolsMiddleware";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import {
  clearAddMcToCcbCustomListOption,
  clearDeleteMcToCcbCustomListOption,
} from "../../../store/actions/toolsActions";

const { api } = services;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ToolsExceptionModal = (props) => {
  const {
    // component props
    open,
    onClose,

    // Redux funcions
    setSnackbarData,
    getMailchimpAudience,
    setMailchimpInterestedGroups,
    setMailchimpTags,
    deleteMcToCcbCustomList,
    clearDeleteMcToCcbCustomListOption,
    addMcToCcbCustomList,
    clearAddMcToCcbCustomListOption,
    getMcToCcbCustomList,

    // Redux props
    mcToCcbCustomList,
    currentPlan,
    mailChimpAudience,
    mailchimpInterestedGroups,
    mailchimpTags,
    mailchimpListIdForTags,
    mailchimplistIdForInterestedGroups,
    deleteMcToCcbCustomListStatus,
    addMcToCcbCustomListStatus,
  } = props;

  const [mailChimpTags, setMailChimpTags] = useState([]);
  const [showSelectMcTagsModal, setShowSelectMcTagsModal] = useState(false);
  const [activeTagPairIndex, setActiveTagPairIndex] = useState(null);
  const [selectBoxLoading, setSelectBoxLoading] = useState({});
  const [removePairConfirmationModalData, setRemovePairConfirmationModalData] =
    useState({});

  const [toolExceptionsPairs, setToolExceptionPairs] = useState(
    toolExceptionModalData
  );

  const { loadingForMcToCcb } = UseSyncOptions([]);
  const formData = useMemo(() => formDataWithApiKey(), []);

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const currentExceptions = useMemo(() => {
    return getCurrentException(mcToCcbCustomList);
  }, [mcToCcbCustomList]);

  const isOnMedOrHigherPlan = useMemo(
    () =>
      checkSubscribePlanAmount(
        currentPlan?.amountDisplay,
        20,
        currentPlan?.name
      ),
    [currentPlan]
  );

  const errorSnackbar = useCallback(
    (message = "Something went wrong, please try agan later!") => {
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const getInitialModalData = useCallback(() => {
    const data = deepClone(toolExceptionModalData);
    data[0][MAILCHIMP_AUDIENCE].options = mailChimpAudience;
    return data;
  }, [mailChimpAudience]);

  useEffect(() => {
    if (
      lodash.isEmpty(mcToCcbCustomList) &&
      !loadingForMcToCcb &&
      !lodash.isEmpty(mailChimpAudience)
    ) {
      setToolExceptionPairs(getInitialModalData());
    }
  }, [
    getInitialModalData,
    loadingForMcToCcb,
    mailChimpAudience,
    mcToCcbCustomList,
  ]);

  const prePopulateMcToCcbPairs = useCallback(() => {
    if (!loadingForMcToCcb && !lodash.isEmpty(mcToCcbCustomList)) {
      const populatedMcToCcbPairs = populateMcToCcbSyncPairsOnly(
        deepClone(toolExceptionModalData[0]),
        mcToCcbCustomList,
        mailChimpAudience,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        mailchimpTags,
        setMailchimpTags,
        mailchimpListIdForTags,
        mailchimplistIdForInterestedGroups
      );
      setToolExceptionPairs(deepClone(populatedMcToCcbPairs));
    }
  }, [
    loadingForMcToCcb,
    mailChimpAudience,
    mailchimpInterestedGroups,
    mailchimpListIdForTags,
    mailchimpTags,
    mailchimplistIdForInterestedGroups,
    mcToCcbCustomList,
    setMailchimpInterestedGroups,
    setMailchimpTags,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getMailchimpAudience(formData);
  }, [getMailchimpAudience]);

  useEffect(() => {
    prePopulateMcToCcbPairs();
  }, [prePopulateMcToCcbPairs]);

  useEffect(() => {
    if (deleteMcToCcbCustomListStatus === "success") {
      clearDeleteMcToCcbCustomListOption();
      setRemovePairConfirmationModalData({});
      const updatedData = toolExceptionsPairs.filter(
        (item, index) =>
          index !== removePairConfirmationModalData?.removeItemIndex
      );
      setToolExceptionPairs(updatedData);
      showSnackbar("Sync pair removed successfully", "success");
    }
  }, [
    clearDeleteMcToCcbCustomListOption,
    deleteMcToCcbCustomListStatus,
    removePairConfirmationModalData,
    showSnackbar,
    toolExceptionsPairs,
  ]);

  useEffect(() => {
    if (addMcToCcbCustomListStatus === "success") {
      clearAddMcToCcbCustomListOption();
      showSnackbar("MC to CCB custom list updated successfully!!", "success");
      getMcToCcbCustomList(formData);
      onClose();
    }
  }, [
    addMcToCcbCustomListStatus,
    clearAddMcToCcbCustomListOption,
    formData,
    getMcToCcbCustomList,
    onClose,
    showSnackbar,
  ]);

  const openMcTagsModal = useCallback(
    (pairIndex) => {
      setShowSelectMcTagsModal(true);
      setActiveTagPairIndex(pairIndex);
      setMailChimpTags(toolExceptionsPairs[pairIndex][MAILCHIMP_GROUPS].tags);
    },
    [toolExceptionsPairs]
  );

  const handleCheck = useCallback(
    (data) => {
      const cloneModalData = deepClone(toolExceptionsPairs);
      const tags = [...mailChimpTags];
      tags.forEach((tag, index) => {
        if (tag.id === data.id) {
          tags[index].active = !tags[index].active;
        }
      });
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setToolExceptionPairs(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, toolExceptionsPairs]
  );

  const appendNewTag = useCallback(
    (newTag) => {
      const cloneModalData = deepClone(toolExceptionsPairs);
      const tags = [...mailChimpTags];
      tags.push(newTag);
      cloneModalData[activeTagPairIndex][MAILCHIMP_GROUPS].tags = tags;
      setToolExceptionPairs(cloneModalData);
      setMailChimpTags(tags);
    },
    [activeTagPairIndex, mailChimpTags, toolExceptionsPairs]
  );

  const closeMcTagsModal = useCallback(
    (isConfirm) => {
      setShowSelectMcTagsModal(false);
      if (!isConfirm) {
        const cloneTags =
          deepClone(toolExceptionsPairs)[activeTagPairIndex][MAILCHIMP_GROUPS]
            .tags;
        cloneTags.forEach((item) => (item.active = false));
        setToolExceptionPairs(toolExceptionsPairs);
      }
    },
    [activeTagPairIndex, toolExceptionsPairs]
  );

  const handleAddNewPair = useCallback(() => {
    const data = deepClone(toolExceptionsPairs);
    data.push(getInitialModalData()[0]);
    setToolExceptionPairs(data);
  }, [getInitialModalData, toolExceptionsPairs]);

  const handleMcGroupAndTags = useCallback(
    (value, index) => {
      const cloneState = deepClone(toolExceptionsPairs);
      cloneState[index][MAILCHIMP_GROUPS].syncType = value;
      if (value === 1) {
        cloneState[index][MAILCHIMP_GROUPS].value = "";
        cloneState[index][MAILCHIMP_GROUPS].selectedId = null;
      }
      setToolExceptionPairs(cloneState);
    },
    [toolExceptionsPairs]
  );

  const handleRemovePair = useCallback(
    (pairToBeRemove) => () => {
      if (pairToBeRemove?.isExistingPair) {
        const formData = formDataWithApiKey();
        formData.append("id", pairToBeRemove.id);
        deleteMcToCcbCustomList(formData, pairToBeRemove.id);
      }
    },
    [deleteMcToCcbCustomList]
  );

  const onClickedRemovedPairBtn = useCallback(
    (removeItemIndex, pairToBeRemove) => {
      if (pairToBeRemove?.isExistingPair) {
        setRemovePairConfirmationModalData({
          ...pairToBeRemove,
          removeItemIndex,
        });
      } else {
        const updatedData = toolExceptionsPairs.filter(
          (item, index) => index !== removeItemIndex
        );
        setToolExceptionPairs(updatedData);
      }
    },
    [toolExceptionsPairs]
  );

  const handleMcAudienceChange = useCallback(
    async (option, state, index) => {
      if (option?.id) {
        try {
          state[index][MAILCHIMP_AUDIENCE].selectedId = option.id;
          state[index][MAILCHIMP_AUDIENCE].value = option.name;
          formData.append("mailchimpListId", option.id);
          setSelectBoxLoading({
            identity: [MAILCHIMP_GROUPS, MAILCHIMP_AUDIENCE],
            index,
          });
          const mailChimpTags = await api.getMailchimpAudienceTag(formData);
          const mailChimpInterestedGroups =
            await api.getMailchimpInterestedGroups(formData);
          setSelectBoxLoading({});
          if (mailChimpInterestedGroups.status === 1) {
            const addkeyForGrouping = addNewKeyPairInArray(
              mailChimpInterestedGroups.data,
              { groupBy: "Sync to Existing Group" }
            );
            state[index][MAILCHIMP_GROUPS].value = "";
            state[index][MAILCHIMP_GROUPS].options = addkeyForGrouping;
          }
          if (mailChimpTags.status === 1) {
            const tags = addNewKeyPairInArray(
              Object.values(mailChimpTags.data),
              { active: false }
            );
            const filterTags = lodash.uniqBy(tags, (e) => e.name);
            state[index][MAILCHIMP_GROUPS].tags = filterTags;
          }
          setToolExceptionPairs(state);
        } catch (error) {
          setSelectBoxLoading({});
        }
      }
    },
    [formData]
  );

  const handleMcGroupsChange = useCallback((option, state, index) => {
    if (option?.uniqueId) {
      state[index][MAILCHIMP_GROUPS].selectedId = option.uniqueId;
      state[index][MAILCHIMP_GROUPS].value = option.groupName;
      setToolExceptionPairs(state);
    } else {
      state[index][MAILCHIMP_GROUPS].selectedId = null;
      state[index][MAILCHIMP_GROUPS].value = "";
      setToolExceptionPairs(state);
    }
  }, []);

  const handleChange = useCallback(
    (event, option, identity, index) => {
      const currentState = deepClone(toolExceptionsPairs);
      if (identity === MAILCHIMP_AUDIENCE) {
        handleMcAudienceChange(option, currentState, index);
      } else if (identity === MAILCHIMP_GROUPS) {
        handleMcGroupsChange(option, currentState, index);
      }
    },
    [handleMcAudienceChange, handleMcGroupsChange, toolExceptionsPairs]
  );

  const getMailchimpAudienceNameById = useCallback(
    (id) => {
      const audience = mailChimpAudience.find((item) => item.id === id);
      return audience?.name || "";
    },
    [mailChimpAudience]
  );

  const getMailchimpListGroupName = useCallback((id, options) => {
    const mcGroup = options.find((item) => item.uniqueId === id);
    return mcGroup?.groupName || "";
  }, []);

  const handleSubmit = useCallback(() => {
    const formData = formDataWithApiKey();
    const syncType = [];
    const mailchimpAudienceIds = [];
    const interestCategoryId = [];
    const interestId = [];
    const tags = [];
    const id = [];
    toolExceptionsPairs.map((pair) =>
      Object.keys(pair).forEach((item) => {
        if (item === MAILCHIMP_AUDIENCE)
          mailchimpAudienceIds.push(pair[item].selectedId);

        if (item === MAILCHIMP_GROUPS) {
          syncType.push(pair[item].syncType);
          id.push(pair[item].id);
          if (
            pair[item].selectedId != null &&
            pair[item].selectedId?.includes(":::::")
          ) {
            const splitUniqueId = pair[item].selectedId.split(":::::");
            interestCategoryId.push(splitUniqueId[0]);
            interestId.push(splitUniqueId[1]);
          } else {
            interestCategoryId.push("");
            interestId.push("");
          }
          const filterActiveTags = pair[item].tags
            .filter((tag) => tag.active)
            .map((item) => item.name);
          tags.push(filterActiveTags.join());
        }
      })
    );
    if (mailchimpAudienceIds.includes(null)) {
      return errorSnackbar("Please select a MailChimp Audience");
    }
    setArrayValuesInFormData(syncType, formData, "syncType");

    setArrayValuesInFormData(mailchimpAudienceIds, formData, "mcListId");

    setArrayValuesInFormData(id, formData, "id");

    if (interestCategoryId.length && interestId.length) {
      syncType.forEach((item, index) => {
        if (item === 0) {
          formData.append(
            `params[${index}][${"interestCategoryId"}]`,
            interestCategoryId[index]
          );
          formData.append(
            `params[${index}][${"interestId"}]`,
            interestId[index]
          );
        }
      });
    }

    if (tags.length) {
      syncType.forEach((item, index) => {
        if (item === 1) {
          formData.append(`params[${index}][${"tags"}]`, tags[index]);
        }
      });
    }

    addMcToCcbCustomList(formData);
  }, [addMcToCcbCustomList, errorSnackbar, toolExceptionsPairs]);

  const renderMailchimpTags = useCallback(
    (tags, index) => {
      const filterActiveTags = tags?.filter((item) => item.active);
      if (filterActiveTags?.length && !showSelectMcTagsModal) {
        return (
          <div className="mb-2 flexer-start ml-3 flex-wrap">
            <Chip
              size="small"
              label={filterActiveTags[0].name}
              variant="outlined"
              className="tag-chip"
            />
            <div
              onClick={() => openMcTagsModal(index)}
              className="more-tags ml-2 cursor-pointer"
            >
              {filterActiveTags.length > 1
                ? `+${filterActiveTags?.length - 1}`
                : "Add"}{" "}
              more
            </div>
          </div>
        );
      } else {
        return (
          <Typography
            onClick={() => openMcTagsModal(index)}
            variant="subtitle1"
            className="select-mc-tag"
          >
            <Add fontSize="small" /> Select MC Tags
          </Typography>
        );
      }
    },
    [openMcTagsModal, showSelectMcTagsModal]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        classes={{
          paper: "tool-exception-modal-container",
        }}
        maxWidth="md"
      >
        <DialogTitle className="flexer-start pb-0" id="alert-dialog-title">
          <Typography variant="h5">
            {" "}
            <span className="modal-main-heading">Select MailChimp Groups</span>
          </Typography>
          <Typography className="text-muted mt-2">
            Our platform will not remove unsubscribed Mailchimp email <br />{" "}
            addresses from the corresponding CCB group
          </Typography>

          <Close className="close-icon mr-2" onClick={onClose} />
        </DialogTitle>
        {loadingForMcToCcb ? (
          <div className="w-100 flexer overflow-hidden mt-5">
            <CircularProgress size={40} />
          </div>
        ) : (
          <>
            <DialogContent className="flexer flex-wrap mt-5">
              {toolExceptionsPairs.map((pair, index) => (
                <div key={index} className="flexer w-100">
                  {Object.keys(pair).map((item, idx) => (
                    <div
                      key={idx}
                      className="tools-exception-body-container w-100"
                    >
                      {pair[item].identity === MAILCHIMP_AUDIENCE && (
                        <div className="f-flex align-content-start justify-content-center flex-column w-75">
                          <Typography className="d-flex align-items-center justify-content-start mb-2">
                            <img
                              src={pair[item].icon}
                              width={25}
                              className="mr-2"
                              height={25}
                              alt="icon"
                            />{" "}
                            {pair[item].headerTitle}
                          </Typography>
                          <CustomAutocompleteSelect
                            classes={{
                              root: "custom-autocomplete-select-container",
                              input: "custom-autocomplete-input",
                            }}
                            placeholder={pair[item].placeholder}
                            options={pair[item].options}
                            displayKey={pair[item].displayKey}
                            loadingText={pair[item].loadingText}
                            identity={pair[item].identity}
                            handleChange={handleChange}
                            index={index}
                            value={getMailchimpAudienceNameById(
                              pair[item].selectedId
                            )}
                            disabled={false}
                            loading={
                              selectBoxLoading?.index === index &&
                              selectBoxLoading?.identity &&
                              selectBoxLoading.identity?.includes(item)
                            }
                          />
                        </div>
                      )}

                      {pair[item].identity === MAILCHIMP_GROUPS && (
                        <div className="w-100 d-flex align-items-center justify-content-center">
                          <div className="f-flex align-content-start justify-content-center flex-column w-75 mt-4">
                            <Typography className="d-flex align-items-center justify-content-start mb-2">
                              <img
                                src={pair[item].icon}
                                width={25}
                                className="mr-2"
                                height={25}
                                alt="icon"
                              />{" "}
                              {pair[item].headerTitle}
                            </Typography>
                            <McTagsAndGroupTabBox
                              handleMcGroupAndTags={handleMcGroupAndTags}
                              syncType={pair[item].syncType}
                              index={index}
                              className={"w-100"}
                              isTagsDisabled={!isOnMedOrHigherPlan}
                              isSubscribeHigherPlan={isOnMedOrHigherPlan}
                              isOnEdit={false}
                            >
                              <div className="mt-2">
                                {pair[item].syncType === 0 ? (
                                  <CustomAutocompleteSelect
                                    classes={{
                                      root: "custom-autocomplete-select-container border-none",
                                      input: "custom-autocomplete-input",
                                    }}
                                    placeholder={pair[item].placeholder}
                                    options={pair[item].options}
                                    displayKey={pair[item].displayKey}
                                    loadingText={pair[item].loadingText}
                                    identity={MAILCHIMP_GROUPS}
                                    handleChange={handleChange}
                                    index={index}
                                    value={getMailchimpListGroupName(
                                      pair[item].selectedId,
                                      pair[item].options
                                    )}
                                    isCcbGroupExistInMcGroup={false}
                                    loading={
                                      selectBoxLoading?.index === index &&
                                      selectBoxLoading?.identity &&
                                      selectBoxLoading.identity?.includes(
                                        MAILCHIMP_GROUPS
                                      )
                                    }
                                  />
                                ) : (
                                  renderMailchimpTags(pair[item].tags, index)
                                )}
                              </div>
                            </McTagsAndGroupTabBox>
                          </div>
                          <DeleteForeverOutlined
                            className="cursor-pointer delete-pair-icon mt-5 ml-4"
                            style={{ color: "#FF5F0A" }}
                            onClick={() =>
                              onClickedRemovedPairBtn(index, pair[item])
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </DialogContent>
            <div className="add-more-sync-pairs">
              <div className="cursor-pointer w-auto" onClick={handleAddNewPair}>
                <Add fontSize="small" /> Select Groups and tags from a different
                Audience
              </div>
            </div>
          </>
        )}
        <DialogActions className="flexer-column mt-5">
          <div className="d-flex justify-content-between align-items-center w-100 mb-4">
            <label className="ml-4">{currentExceptions} Selected</label>
            <ButtonWithSpinner
              className={"bg-yellow mr-4 "}
              onClick={handleSubmit}
              disabled={
                addMcToCcbCustomListStatus === "loading" || loadingForMcToCcb
              }
              loading={addMcToCcbCustomListStatus === "loading"}
            >
              Confirm
            </ButtonWithSpinner>
          </div>
        </DialogActions>
      </Dialog>
      {showSelectMcTagsModal && (
        <SelectMcTagsModal
          open={showSelectMcTagsModal}
          handleClose={closeMcTagsModal}
          tags={mailChimpTags.sort((x, y) =>
            x.active === y.active ? 0 : x.active ? -1 : 1
          )}
          handleCheck={handleCheck}
          appendNewTag={appendNewTag}
        />
      )}

      {!lodash.isEmpty(removePairConfirmationModalData) && (
        <ConfirmationModal
          open={true}
          handleClose={() => setRemovePairConfirmationModalData({})}
          modalHeading="Remove Sync Pair"
          modalContent={`Are you sure you want to remove the sync pair?`}
          btnLabel={"Yes, Remove"}
          btnClassName={"bg-yellow mr-3"}
          onClick={handleRemovePair(removePairConfirmationModalData)}
          loading={deleteMcToCcbCustomListStatus === "loading"}
          disabled={deleteMcToCcbCustomListStatus === "loading"}
          showCancelBtn={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    mcToCcbCustomList: store.tools.mcToCcbCustomList,
    currentPlan: store.billing.currentPlan,
    mailChimpAudience: store.integrations.mailChimpAudience,
    mailchimpInterestedGroups: store.integrations.mailchimpInterestedGroups,
    mailchimpTags: store.integrations.mailchimpTags,
    mailchimpListIdForTags: store.integrations.mailchimpListIdForTags,
    mailchimplistIdForInterestedGroups:
      store.integrations.mailchimplistIdForInterestedGroups,
    deleteMcToCcbCustomListStatus: store.tools.deleteMcToCcbCustomListStatus,
    deleteMcToCcbListId: store.tools.deleteMcToCcbListId,
    addMcToCcbCustomListStatus: store.tools.addMcToCcbCustomListStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getMailchimpAudience: (data, requestId) =>
      dispatch(getMailchimpAudience(data, requestId)),
    setMailchimpInterestedGroups: (data) =>
      dispatch(setMailchimpInterestedGroups(data)),
    setMailchimpTags: (data) => dispatch(setMailchimpTags(data)),
    deleteMcToCcbCustomList: (data, requestId) =>
      dispatch(deleteMcToCcbCustomList(data, requestId)),
    addMcToCcbCustomList: (data) => dispatch(addMcToCcbCustomList(data)),
    clearDeleteMcToCcbCustomListOption: () =>
      dispatch(clearDeleteMcToCcbCustomListOption()),
    clearAddMcToCcbCustomListOption: () =>
      dispatch(clearAddMcToCcbCustomListOption()),
    getMcToCcbCustomList: (data) => dispatch(getMcToCcbCustomList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolsExceptionModal);
