import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Info } from "@material-ui/icons";
import OldVersionLink from "../components/OldVersionLink/OldVersionLink";

const NotficationBanner = (props) => {
  const {
    severity,
    icon,
    message,
    className,
    innerClassName,
    isSkipBtn,
    withLoading,
    linkText,
    linkTextClassName,
    afterLinkText,
    hasOldVersionLink,
    link,
    hasInfoIcon,
    onClick,
  } = props;

  const renderLink = useMemo(() => {
    if (hasOldVersionLink) {
      return (
        <OldVersionLink>
          <b className="cursor-pointer">&nbsp;{linkText}&nbsp;</b>
        </OldVersionLink>
      );
    } else if (link) {
      return (
        <Link to={link}>
          <b className="cursor-pointer">&nbsp;{linkText}&nbsp;</b>
        </Link>
      );
    } else if (onClick) {
      return (
        <b onClick={onClick} className={`cursor-pointer ${linkTextClassName}`}>
          &nbsp;{linkText}&nbsp;
        </b>
      );
    }
  }, [hasOldVersionLink, link, linkText, onClick, linkTextClassName]);

  return (
    <Alert
      action={
        isSkipBtn ? (
          <Button color="inherit" size="small">
            Skip
          </Button>
        ) : (
          false
        )
      }
      severity={severity}
      icon={icon}
      className={className}
      classes={{ message: `w-100 ${innerClassName}` }}
    >
      <div className="flexer-start">
        {withLoading && <CircularProgress className="ml-2 mr-4" size={30} />}{" "}
        <div className="flexer flex-wrap">
          <span>
            {hasInfoIcon && (
              <Info
                className="mb-1"
                htmlColor="rgb(97, 26, 21)"
                fontSize="small"
              />
            )}{" "}
            {message}
          </span>{" "}
          {linkText && renderLink}{" "}
          {afterLinkText && <span>{afterLinkText}</span>}
        </div>
      </div>
    </Alert>
  );
};

NotficationBanner.defaultProps = {
  severity: "info",
  icon: false,
  message: "",
  className: "",
  isSkipBtn: false,
  withLoading: false,
  afterLinkText: "",
  linkText: "",
  linkTextClassName: "",
  hasOldVersionLink: false,
  link: "",
  innerClassName: "",
  hasInfoIcon: false,
};

NotficationBanner.propType = {
  severity: PropTypes.string,
  icon: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
  afterLinkText: PropTypes.string,
  linkText: PropTypes.string,
  linkTextClassName: PropTypes.string,
  link: PropTypes.string,
  isSkipBtn: PropTypes.bool,
  withLoading: PropTypes.bool,
  hasOldVersionLink: PropTypes.bool,
  hasInfoIcon: PropTypes.bool,
  innerClassName: PropTypes.string,
};

export default NotficationBanner;
