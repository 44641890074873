import React, { useCallback, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { formDataWithApiKey, integrationsCardInfoText } from "../../helpers";
import { getUserApiDetails } from "../../store/middlewares/userMiddleware";
import { ReactComponent as CcbIcon } from "../../assets/icons/ccb/ccb-gray-large.svg";
import { ReactComponent as MailChimpIconGray } from "../../assets/icons/mailchimp/mc-gray-large.svg";
import { ReactComponent as MailChimpIconBlack } from "../../assets/icons/mailchimp/mc-black-large.svg";
import InformationModal from "../../components/modals/InformationModal/InformationModal";
import SearchWarning from "../../components/modals/SearchWarning/SearchWarning";
import AutoSyncToggle from "../../components/AutoSyncToggle/AutoSyncToggle";
import NotficationBanner from "../../HelperComponents/NotficationBanner";
import { notificationProps } from "../../utils/notifications";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import "../../styles/components/dashboard/dashboard-main.scss";
import "../../styles/components/more/integrations.scss";
import "../../styles/views/authorize-account.scss";
import "../../styles/views/login.scss";

const Services = (props) => {
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalContent, setInformationModalContent] = useState("");
  const [showSearchWarning, setShowSearchWarning] = useState(
    Number(props.userData?.v2_search_warning) === 0
  );

  const isSidebarExpanded = useIsSidebarExpanded();

  const {
    // Component props
    history,

    // REDUX FUNCTIONS
    getUserApiDetails,

    // Redux props
    apiDetailsStatus,
    userApiDetails,
    hasCcbError,
    hasMcError,
    syncManualMessage,
  } = props;

  const getMissingIntegrationName = useMemo(() => {
    if (!userApiDetails.ccbUsername && !userApiDetails.mailchimpApiKey) {
      return "CCB and Mailchimp";
    } else if (!userApiDetails.ccbUsername) {
      return "CCB";
    } else if (!userApiDetails.mailchimpApiKey) {
      return "Mailchimp";
    }
    return "";
  }, [userApiDetails]);

  const closeInformationModal = useCallback(() => {
    setShowInformationModal(false);
  }, []);

  useEffect(() => {
    // GET API DATA IF THERE IS EMPTY OBJECT IN REDUX
    const formData = formDataWithApiKey();
    getUserApiDetails(formData);
  }, [getUserApiDetails]);

  const readMoreClicked = useCallback(
    (service) => () => {
      setInformationModalContent(integrationsCardInfoText[service]);
      setShowInformationModal(true);
    },
    []
  );

  const renderMcAccountDetails = useMemo(() => {
    if (userApiDetails.mailchimpApiKey) {
      return (
        <span className="d-flex align-items-center justify-content-center flex-column">
          {userApiDetails?.mailchimpInfo?.account_name && (
            <span>
              <b>Account Name:&nbsp;</b>
              {userApiDetails?.mailchimpInfo?.account_name}
            </span>
          )}
          {userApiDetails?.mailchimpInfo?.email && (
            <span>
              <b>Email:&nbsp;</b>
              {userApiDetails?.mailchimpInfo?.email}
            </span>
          )}
          <span>
            <b>API key:&nbsp;</b>*********
            {userApiDetails.mailchimpApiKey?.substr(
              userApiDetails.mailchimpApiKey?.length - 10
            )}
          </span>
        </span>
      );
    }
    return (
      <span>
        Once integrated, You will be able to use a free or paid Mailchimp
        account
      </span>
    );
  }, [userApiDetails]);

  const renderCcbAccountDetails = useMemo(() => {
    if (userApiDetails.ccbAccount) {
      return (
        <span className="d-flex align-items-center justify-content-center flex-column">
          {userApiDetails.ccbUsername && (
            <span>
              <b>CCB Username:&nbsp;</b>
              {userApiDetails.ccbUsername}
            </span>
          )}
          {userApiDetails.ccbAccount && (
            <span>
              <b>CCB Acount:&nbsp;</b>
              {userApiDetails.ccbAccount}
            </span>
          )}
        </span>
      );
    }
    return (
      <span>
        Once integrated, You will be able to sync your Church Community Builder
        (CCB) account with Mailchimp (MC).
      </span>
    );
  }, [userApiDetails]);

  const renderNotificationBanner = useMemo(() => {
    if (syncManualMessage !== "")
      return <NotficationBanner message={syncManualMessage} />;
    return null;
  }, [syncManualMessage]);

  return (
    <div
      className={clsx("page-wrapper integration-page", {
        "with-sidebar-expanded": isSidebarExpanded,
        "with-sidebar-collapsed": !isSidebarExpanded,
      })}
    >
      <div className="desktop-view pl-3 ">
        <Typography className="desktop-title" component={"h2"}>
          Integrations
        </Typography>
        {Boolean(hasCcbError) && apiDetailsStatus !== "loading" && (
          <NotficationBanner
            {...notificationProps["hasCcbError"]}
            onClick={() => history.push("/integrations/setup-ccb")}
          />
        )}
        {Boolean(hasMcError) && apiDetailsStatus !== "loading" && (
          <NotficationBanner
            {...notificationProps["hasMcError"]}
            onClick={() => history.push("/integrations/setup-mailchimp")}
          />
        )}
        <div className="d-flex title w-100 align-items-center justify-content-between">
          <Typography component={"h2"}>Active</Typography>
          <div className="mr-5">
            <AutoSyncToggle hasLabel={true} />
          </div>
        </div>
        {renderNotificationBanner}
        {apiDetailsStatus !== "loading" &&
          (!userApiDetails.ccbUsername || !userApiDetails.mailchimpApiKey) && (
            <Alert
              variant="filled"
              severity="error"
              className="w-100 mt-2 integration-missing-alert"
            >
              Syncing has been disabled. We are missing your{" "}
              {getMissingIntegrationName} API settings.
            </Alert>
          )}
        <div className="integration-card-active">
          <Grid container spacing={2} className="cards">
            <Grid item md={4} xs={12}>
              <Box
                className={`single ${
                  userApiDetails.ccbUsername ? "ccb-active" : "ccb-inactive"
                }`}
              >
                <div className="box-title w-100">
                  <div className="flexer">
                    <CcbIcon />
                    <Typography className="icon-text" variant="inherit">
                      Church Community Builder
                    </Typography>
                    {apiDetailsStatus === "loading" && (
                      <CircularProgress className="icon-text ml-2" size={20} />
                    )}
                  </div>
                </div>
                <Typography className="content mt-3">
                  <div>{renderCcbAccountDetails}</div>
                </Typography>
                <div className="box-title w-100">
                  <Button
                    color="primary"
                    variant="text"
                    classes={{
                      root: "btn",
                      label: "label",
                      textPrimary: `${
                        userApiDetails.ccbUsername
                          ? "text-white"
                          : "text-primary"
                      }`,
                    }}
                    onClick={readMoreClicked("ccb")}
                  >
                    <span className="forgot-pw">Read more</span>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    classes={{
                      root: "btn",
                      label: "label",
                      containedPrimary: "contained-primary",
                    }}
                    onClick={() => history.push("/integrations/setup-ccb")}
                    disabled={apiDetailsStatus === "loading"}
                  >
                    {userApiDetails.ccbUsername ? "Edit" : "Connect"}
                  </Button>
                </div>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                className={`single ${
                  userApiDetails.mailchimpApiKey
                    ? "mailchimp-active"
                    : "mailchimp-inactive"
                }`}
              >
                <div className="box-title w-100">
                  <div className="flexer">
                    {userApiDetails.mailchimpApiKey ? (
                      <MailChimpIconBlack />
                    ) : (
                      <MailChimpIconGray />
                    )}
                    <Typography className="icon-text ml-1" variant="inherit">
                      Mailchimp
                    </Typography>
                    {apiDetailsStatus === "loading" && (
                      <CircularProgress className="icon-text ml-2" size={20} />
                    )}
                  </div>
                </div>
                <Typography className="content mt-3">
                  <div>{renderMcAccountDetails}</div>
                </Typography>
                <div className="box-title w-100">
                  <Button
                    color="primary"
                    variant="text"
                    classes={{
                      root: "btn",
                      label: "label",
                      textPrimary: `${
                        userApiDetails.mailchimpApiKey
                          ? "text-black"
                          : "text-primary"
                      }`,
                    }}
                    onClick={readMoreClicked("mailchimp")}
                  >
                    <span className="forgot-pw">Read more</span>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    classes={{
                      root: "btn",
                      label: "label",
                      containedPrimary: "contained-primary",
                    }}
                    onClick={() =>
                      history.push("/integrations/setup-mailchimp")
                    }
                    disabled={apiDetailsStatus === "loading"}
                  >
                    {userApiDetails.mailchimpApiKey ? "Edit" : "Connect"}
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      {showInformationModal && (
        <InformationModal
          open={showInformationModal}
          handleClose={closeInformationModal}
          modalHeading={"How it works?"}
          modalContent={informationModalContent}
        />
      )}
      {/* Search warning modal */}
      {showSearchWarning && userApiDetails.ccbUsername && (
        <SearchWarning
          open={showSearchWarning}
          handleClose={() => setShowSearchWarning(false)}
        />
      )}
    </div>
  );
};
const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    userApiDetails: store.userReducer.userApiDetails,
    apiDetailsStatus: store.userReducer.apiDetailsStatus,
    hasMcError: store.userReducer.hasMcError,
    hasCcbError: store.userReducer.hasCcbError,
    syncManualMessage: store.integrations.syncManualMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserApiDetails: (data) => dispatch(getUserApiDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
