import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropType from "prop-types";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Info } from "@material-ui/icons";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { toggleCardDeclineModal } from "../../../store/actions/billingActions";
import { isEmptyObject } from "../../../helpers";

const CardDeclineModal = (props) => {
  const {
    showModal,

    // Redux props
    currentSubscriptionPlan,

    // Redux functions
    toggleCardDeclineModal,
  } = props;
  const history = useHistory();

  const toggleModal = useCallback(() => {
    toggleCardDeclineModal(false);
  }, [toggleCardDeclineModal]);

  const onClickedUpdateCard = useCallback(() => {
    toggleModal();
    history.push("/setup-payment", { from: "billing" });
  }, [history, toggleModal]);

  const onClickedRetry = useCallback(() => {
    toggleModal();
    if (currentSubscriptionPlan && !isEmptyObject(currentSubscriptionPlan)) {
      history.push("/change-plan", {
        subscriptionPlan: currentSubscriptionPlan,
        isYearly: currentSubscriptionPlan?.annualAmount > 0 ? 1 : 0,
      });
    } else {
      history.push("/subscription-plans");
    }
  }, [currentSubscriptionPlan, history, toggleModal]);

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={"md"}
      >
        <DialogTitle id="form-dialog-title" className="pb-0">
          <strong className="card-decline-title">
            <Info className="mb-1" htmlColor="#FF552C" fontSize="small" /> Card
            declined!
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color="#FF552C"
            className="card-decline-text-wrapper"
          >
            Your card was declined{" "}
            <b onClick={onClickedRetry} className="cursor-pointer">
              <u>RETRY</u>
            </b>
            . Please update your details and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex align-items-center justify-content-between p-4">
          <ButtonWithSpinner onClick={toggleModal} className="dark-gray-button">
            Not now
          </ButtonWithSpinner>
          <ButtonWithSpinner
            onClick={onClickedUpdateCard}
            className="bg-yellow"
          >
            Update Card
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CardDeclineModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCardDeclineModal: (val) => dispatch(toggleCardDeclineModal(val)),
  };
};

const mapStateToProps = (store) => {
  return {
    currentSubscriptionPlan: store.billing.currentSubscriptionPlan,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDeclineModal);
