import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Check, Help, Visibility, Info } from "@material-ui/icons";
import { useWithHover } from "../../../utils/hooks/useWithHover";
import { INACTIVE_ACCOUNTS_CLEANUP_SUPPORT_URL } from "../../../utils/constants";
import SpanWithTooltip from "../../SpanWithTooltip/SpanWithTooltip";
import MergeFieldErrorArticleLink from "../../../HelperComponents/MergeFieldErrorArticleLink";

const LogDetailsTableBodyRow = (props) => {
  const {
    data,
    tableType,
    index,
    handleItemClicked,
    navigateToLogDetailsScreen,
    navigateToLogStatisticsScreen,
    tabIdentity,
    navigateToCleanupSync,
    onClickedViewMcRequiredMergeField,
  } = props;

  const isArchiveContact =
    [data?.type?.toLowerCase(), tabIdentity].includes("added") &&
    data?.isArchived;
  const isUpdatedArchivedContact = data.cleanUpDetailId && data?.isArchived;
  const { hoverActive, onMouseLeave, onMouseEnter } = useWithHover();

  const getTableRowClassName = useCallback(() => {
    let className = "";
    if (tableType === "logStatistics" && data?.isPrimaryDuplicate) {
      className += "duplicated";
    } else {
      className += "nonDuplicate";
    }
    if (Number(data?.hasError) === 1) {
      className += ` error-log-row`;
    }
    return className;
  }, [data, tableType]);

  return (
    <tr className={getTableRowClassName()}>
      <td></td>
      {![
        "accountChanges",
        "logStatistics",
        "searchResultsCcbToMc",
        "searchResultsMcToCcb",
        "logSearchStepTwo",
      ].includes(tableType) && (
        <td
          className="text-primary cursor-pointer w-25"
          onClick={() => handleItemClicked(data)}
        >
          {data.name}
        </td>
      )}
      {tableType === "ccbToMc" && (
        <>
          {Number(data?.hasError) === 1 ? (
            <td className="text-center" colSpan={6}>
              {data?.errorMessage}
              {data?.errorMessage &&
                data?.errorMessage?.includes("merge fields") && (
                  <span>
                    <MergeFieldErrorArticleLink className={"ml-2 mr-1"}>
                      <Info
                        className="mb-1"
                        fontSize="small"
                        htmlColor="rgb(97, 26, 21)"
                      />
                    </MergeFieldErrorArticleLink>
                    <SpanWithTooltip
                      title="View required merge fields"
                      arrow={true}
                      className="ml-2 cursor-pointer"
                      onClick={onClickedViewMcRequiredMergeField(data)}
                    >
                      <Visibility fontSize="small" />
                    </SpanWithTooltip>
                  </span>
                )}
            </td>
          ) : (
            <>
              <td>{data.added}</td>
              <td>{data.updated}</td>
              <td>{data.duplicates}</td>
              <td>{data.removed}</td>
              <td>{data.skipped}</td>
              <td>{data.errors}</td>
            </>
          )}
        </>
      )}

      {tableType === "mcToCcb" && (
        <>
          <td>{data.added}</td>
          <td>{data.removed}</td>
          <td>{data.skipped}</td>
          <td>{data.conflict}</td>
        </>
      )}

      {tableType === "accountChanges" && (
        <>
          <td>{index + 1}</td>
          <td>{data}</td>
        </>
      )}

      {tableType === "logStatistics" && (
        <>
          <td
            onMouseEnter={isArchiveContact ? () => onMouseEnter() : null}
            onMouseLeave={isArchiveContact ? () => onMouseLeave() : null}
            className="text-center text-primary cursor-pointer flexer"
          >
            {data.isPrimaryDuplicate && (
              <Check
                color={"red"}
                className="mr-1 text-danger"
                fontSize={"small"}
              />
            )}
            <a
              className={`${
                isArchiveContact || isUpdatedArchivedContact
                  ? "text-line-through"
                  : ""
              }`}
              href={data.participantUrl}
              target="blank"
            >
              {data.detail}
            </a>
            {(isArchiveContact || isUpdatedArchivedContact) && (
              <span className="flexer">
                {hoverActive && isArchiveContact && (
                  <span className="text-danger cursor-initial ml-2">
                    Inactive profile with email.Removed
                  </span>
                )}
                <a
                  className="ml-2"
                  href={INACTIVE_ACCOUNTS_CLEANUP_SUPPORT_URL}
                  target="__blank"
                >
                  <Help fontSize="small" />
                </a>
                <Visibility
                  onClick={() => navigateToCleanupSync(data)}
                  className="ml-2"
                  fontSize="small"
                />
              </span>
            )}
          </td>
        </>
      )}

      {tableType === "searchResultsCcbToMc" && (
        <>
          <td>{data.email}</td>
          <td>{data.fullName}</td>
          <td>
            <a href={data.ccbUrl} target="blank">
              # {data.ccbId}
            </a>
          </td>
          <td
            onClick={() => handleItemClicked(data)}
            className="text-primary cursor-pointer"
          >
            View
          </td>
        </>
      )}
      {tableType === "searchResultsMcToCcb" && (
        <>
          <td>{data.email}</td>
          <td>
            <a href={data.ccbUrl} target="blank">
              # {data.ccbId}
            </a>
          </td>
          <td
            onClick={() => handleItemClicked(data)}
            className="text-primary cursor-pointer"
          >
            View
          </td>
        </>
      )}

      {tableType === "logSearchStepTwo" && (
        <>
          <td>
            <div
              onMouseEnter={isArchiveContact ? () => onMouseEnter() : null}
              onMouseLeave={isArchiveContact ? () => onMouseLeave() : null}
              className="d-flex align-items-center"
            >
              <span
                className={`${
                  isUpdatedArchivedContact || isArchiveContact
                    ? "text-line-through"
                    : ""
                }`}
              >
                {data.type}
              </span>
              {(isUpdatedArchivedContact || isArchiveContact) && (
                <div className="flexer">
                  {hoverActive && isArchiveContact && (
                    <span className="text-danger cursor-initial ml-2">
                      Inactive profile with email.Removed
                    </span>
                  )}
                  <a
                    className="ml-1"
                    href={INACTIVE_ACCOUNTS_CLEANUP_SUPPORT_URL}
                    target="__blank"
                  >
                    <Help fontSize="small" />
                  </a>
                </div>
              )}
            </div>
          </td>
          <td>
            {data.result}&nbsp;
            <a href={data.ccbUrl} target="blank">
              # {data.ccbId}
            </a>
          </td>
          <td className="w-50">
            <span
              onClick={() => navigateToLogDetailsScreen(data)}
              className="text-primary cursor-pointer"
            >
              {data.logName}
            </span>
            &nbsp;/&nbsp;{" "}
            <span
              onClick={() => navigateToLogStatisticsScreen(data)}
              className="text-primary cursor-pointer"
            >
              {data.detailName}
            </span>
          </td>
        </>
      )}
    </tr>
  );
};

LogDetailsTableBodyRow.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
  navigateToLogDetailsScreen: PropTypes.func,
  navigateToLogStatisticsScreen: PropTypes.func,
  navigateToCleanupSync: PropTypes.func,
  onClickedViewMcRequiredMergeField: PropTypes.func,
};

LogDetailsTableBodyRow.defaultProps = {
  isActive: false,
  onClickedViewMcRequiredMergeField: () => {},
};

export default withRouter(LogDetailsTableBodyRow);
