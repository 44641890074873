import React from "react";
import PropTypes from "prop-types";
import "../styles/components/card-info.scss";

const CardInfo = (props) => {
  const {
    children,
    style,
    title, // title of the card
    showSaveButton, // should we show the save button or not
    onClickSave, // callback when clicking save
    onClickCancel, // callback when clicking cancel
    className,
    contentClassName,
    headerClassName,
  } = props;

  return (
    <div
      className={`card-info-container d-flex flex-column ${className}`}
      style={style}
    >
      <div className={`card-info-title-container ${headerClassName}`}>
        {title}
      </div>
      <div
        className={`card-info-content-container flex-grow-1 ${contentClassName}`}
      >
        {children}
        {showSaveButton && (
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={onClickSave}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm ml-2"
              onClick={onClickCancel}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

CardInfo.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]).isRequired,
  showSaveButton: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickCancel: PropTypes.func,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

CardInfo.defaultProps = {
  style: undefined,
  showSaveButton: false,
  contentClassName: "",
};

export default CardInfo;
