import React, {
  useEffect,
  useCallback,
  Fragment,
  useMemo,
  useState,
} from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import lodash from "lodash";
import Topbar from "../../components/SyncPageComponents/Topbar/Topbar";
import {
  setDataForModal,
  setSnackbarData,
} from "../../store/actions/globalAction";
import {
  changeSyncType,
  toggleRemoveSyncPairModal,
  toggleSyncConfirmationModal,
  toggleSyncNewPairsConfirmationModal,
  handleSyncingProgressVisibility,
  toggleAddNewSyncPairModal,
  setShowSearchFor,
  clearIntegrationPageState,
  clearSyncManualStatus,
  clearRemoveCcbToMcPair,
  clearRemoveMcToCcbPair,
  clearMailchimpAudience,
  clearSaveMcToCcbPairStatus,
  setHasNotSyncedPairs,
  setMcToCcbDisableInactiveFor,
} from "../../store/actions/integrationActions";
import {
  CCB_CAMPUSES,
  CCB_TO_MC,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
} from "../../utils/integrationsConstants";
import {
  deepClone,
  filterBrokenPairs,
  getCampusGroupByid,
  getFitlerConditionByKey,
  getMcInterestedGroupByUniqueId,
  getSearchingFilters,
  getSyncedData,
  populateSyncpairs,
  removeSameSyncPairs,
} from "../../helpers";
import RemoveSyncPairConfirmationModal from "../../components/modals/IntegrationsPageModal/RemoveSyncPairConfirmationModal";
import SyncConfirmationModal from "../../components/modals/IntegrationsPageModal/SyncConfirmationModal";
import SyncNewPairConfirmationModal from "../../components/modals/IntegrationsPageModal/SyncNewPairConfirmationModal";
import AddNewSyncPairModal from "../../components/modals/IntegrationsPageModal/AddNewSyncPairModal/AddNewSyncPairModal";
import {
  getCcbCampuses,
  getCcbSavedSearches,
  getMcToCcbPairs,
  getMailchimpAudience,
  syncManual,
  syncOnlyNewPairs,
  setMailchimpInterestedGroups,
  setCcbGroups,
  setMailchimpTags,
  saveMcToCcbSyncPair,
  setCcbProcessQueue,
} from "../../store/middlewares/integrationMiddleware";
import { formDataWithApiKey } from "../../helpers";
import { getWarnings } from "../../store/middlewares/userMiddleware";
import { useLocalSearch } from "../../utils/hooks/useLocalSearch";
import Loader from "../../components/Loader/Loader";
import { filters } from "../../components/SyncPageComponents/IntegrationFilterChecklist/integrationFilters";
import { useComponentWillUnmount } from "../../utils/hooks/useComponentWillUnmount";
import PurchasePlanToSync from "../../components/modals/IntegrationsPageModal/PurchasePlanToSyncModal";
import { getCurrentPlan } from "../../store/middlewares/billingMiddleware";
import { clearCheckSync } from "../../store/actions/userActions";
import SearchWarning from "../../components/modals/SearchWarning/SearchWarning";
import SyncList from "../../components/SyncPageComponents/SyncList/SyncList";
import { dataForMcModal } from "../../components/modals/IntegrationsPageModal/AddNewSyncPairModal/addNewSyncPairModalData";
import FixBrokenPairConfirmationModal from "../../components/modals/IntegrationsPageModal/AddNewSyncPairModal/FixBrokenPairConfirmatonModal";
import { UseSyncOptions } from "../../utils/hooks/useSyncOptions";
import ConfirmationModal from "../../components/modals/ConfirmationModal/ConfirmationModal";
import {
  MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_ENABLE,
  MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_DISABLE,
} from "../../utils/constants";
import clsx from "clsx";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import "../../styles/components/responsive-design.scss";

const Integrations = (props) => {
  const {
    // COMPONENT PROPS
    history,

    // Redux functions
    changeSyncType,
    toggleRemoveSyncPairModal,
    toggleSyncConfirmationModal,
    toggleSyncNewPairsConfirmationModal,
    handleSyncingProgressVisibility,
    toggleAddNewSyncPairModal,
    getCcbCampuses,
    getCcbSavedSearches,
    getMcToCcbPairs,
    getMailchimpAudience,
    setSnackbarData,
    setShowSearchFor,
    syncNewPairs,
    syncManual,
    clearIntegrationPageState,
    getCurrentPlan,
    clearSyncManualStatus,
    clearCheckSync,
    clearRemoveCcbToMcPair,
    clearRemoveMcToCcbPair,
    clearMailchimpAudience,
    clearSaveMcToCcbPairStatus,
    setMailchimpInterestedGroups,
    setCcbGroups,
    setMailchimpTags,
    setHasNotSyncedPairs,
    setMcToCcbDisableInactiveFor,
    saveMcToCcbSyncPair,
    setCcbProcessQueue,
    getWarnings,

    // Redux props
    userData,
    currentSync,
    showRemoveSyncPairModal,
    showSyncConfirmationModal,
    showSyncNewPairConfirmationModal,
    showAddNewSyncPairModal,
    mailChimpAudience,
    ccbSavedSearches,
    ccbSyncedSavedsearches,
    syncedCcbCampuses,
    mcToCcbpairs,
    showSearchFor,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
    loading,
    modalData,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
    ccbCampuses,
    syncNewPairsStatus,
    syncManualStatus,
    userApiDetails,
    runInitialSync,
    mcToCcbpairsStatus,
    setDataForModal,
    mailchimpInterestedGroups,
    ccbGroups,
    ccbGroupStatus,
    mailchimpTags,
    mailchimpListIdForTags,
    mailchimplistIdForInterestedGroups,
    showSyncNewPairSkipRoute,
    mcToCcbDisableInactiveRemoveFor,
    ccbProcessQueues,
    ccbProcessQueueStatus,
    errorMessage,
  } = props;
  const [ccbSyncPairs, setCcbSyncPairs] = useState([]);
  const [cloneCcbSyncPairs, setCloneCcbSyncPairs] = useState([]);
  const [mcSyncPairs, setMcSyncPairs] = useState([]);
  const [cloneMcSyncPairs, setCloneMcSyncPairs] = useState([]);
  const [pairFilters, setPairFilters] = useState(filters);
  const [searchValue, setSearchValue] = useState("");
  const [prePopulatedPairs, setPrePopulatedPairs] = useState([]);
  const [
    showFixBorkenPairConfirmationModal,
    setShowFixBorkenPairConfirmationModal,
  ] = useState(false);
  const [showPurchasePlanToSyncModal, setShowPurchasePlanToSyncModal] =
    useState(false);
  const [showSearchWarning, setShowSearchWarning] = useState(
    Number(userData?.v2_search_warning) === 0
  );

  const dataForSearching = useMemo(
    () => (currentSync === CCB_TO_MC ? cloneCcbSyncPairs : cloneMcSyncPairs),
    [cloneCcbSyncPairs, cloneMcSyncPairs, currentSync]
  );

  const { onChange } = useLocalSearch(
    dataForSearching,
    currentSync === CCB_TO_MC ? setCcbSyncPairs : setMcSyncPairs,
    setSearchValue,
    getSearchingFilters(showSearchFor)
  );

  const notSyncedPairs = useMemo(
    () =>
      [...cloneCcbSyncPairs, ...mcToCcbpairs].filter(
        (item) => Number(item.isNew) === 1 && Number(item?.hasMcConflict) === 0
      ),
    [cloneCcbSyncPairs, mcToCcbpairs]
  );

  const {
    isSyncingInProgress,
    disableSyncing,
    isIntegrationsDisabled,
    loadingForCcbToMc,
    loadingForMcToCcb,
  } = UseSyncOptions(notSyncedPairs);

  const isSidebarExpanded = useIsSidebarExpanded();

  useEffect(() => {
    if (!lodash.isEmpty(notSyncedPairs) && !isSyncingInProgress) {
      setHasNotSyncedPairs(true);
    } else {
      setHasNotSyncedPairs(false);
    }
  }, [isSyncingInProgress, notSyncedPairs, setHasNotSyncedPairs]);

  const prePopulateCcbToMcPairs = useCallback(() => {
    if (currentSync === MC_TO_CCB && !loadingForMcToCcb) {
      const filterSavedSearches = cloneCcbSyncPairs.filter(
        (pair) =>
          !pair?.searchId &&
          Number(pair?.hasMcConflict) === 0 &&
          !pair?.processId
      );
      const filteredSyncPairs = removeSameSyncPairs(
        filterSavedSearches,
        cloneMcSyncPairs
      );
      const syncPairsToPopulate =
        lodash.isEmpty(mcToCcbpairs) &&
        !["error", "loading"].includes(mcToCcbpairsStatus)
          ? filterSavedSearches
          : filteredSyncPairs;

      if (
        !lodash.isEmpty(filterSavedSearches) &&
        !isIntegrationsDisabled &&
        !lodash.isEmpty(syncPairsToPopulate) &&
        !saveMcToCcbSyncPairStatus &&
        !removeMcToCcbPairStatus &&
        !["error", "loading"].includes(mcToCcbpairsStatus)
      ) {
        const draftsPairs = populateSyncpairs(
          deepClone(dataForMcModal[0]),
          syncPairsToPopulate,
          ccbCampuses,
          mailChimpAudience,
          false,
          mailchimpInterestedGroups,
          setMailchimpInterestedGroups,
          setCcbGroups,
          ccbGroups,
          mailchimpTags,
          setMailchimpTags,
          mailchimpListIdForTags,
          mailchimplistIdForInterestedGroups,
          ccbSavedSearches,
          ccbProcessQueues,
          setCcbProcessQueue,
          false,
          currentSync
        );
        if (draftsPairs.length) {
          setPrePopulatedPairs(draftsPairs);
          if (
            lodash.isEmpty(cloneMcSyncPairs) &&
            !["error", "loading"].includes(mcToCcbpairsStatus)
          ) {
            toggleAddNewSyncPairModal(true);
          }
        } else {
          setPrePopulatedPairs([]);
        }
      } else {
        setPrePopulatedPairs([]);
      }
    }
  }, [
    currentSync,
    loadingForMcToCcb,
    cloneCcbSyncPairs,
    cloneMcSyncPairs,
    mcToCcbpairs,
    mcToCcbpairsStatus,
    isIntegrationsDisabled,
    saveMcToCcbSyncPairStatus,
    removeMcToCcbPairStatus,
    ccbCampuses,
    mailChimpAudience,
    mailchimpInterestedGroups,
    setMailchimpInterestedGroups,
    setCcbGroups,
    ccbGroups,
    mailchimpTags,
    setMailchimpTags,
    mailchimpListIdForTags,
    toggleAddNewSyncPairModal,
    mailchimplistIdForInterestedGroups,
    ccbSavedSearches,
    ccbProcessQueues,
    setCcbProcessQueue,
  ]);

  // NAVIGATE USER TO ACCOUNT SETUP PAGE IF ACCOUNT NOT SETTING UP
  useEffect(() => {
    if (
      lodash.isEmpty(userApiDetails) ||
      (!lodash.isEmpty(userApiDetails) &&
        (!userApiDetails.mailchimpApiKey || !userApiDetails.ccbAccount))
    ) {
      history.push("/integrations");
    }
  }, [history, userApiDetails]);

  const closeRemoveSyncModal = useCallback(() => {
    toggleRemoveSyncPairModal(false);
  }, [toggleRemoveSyncPairModal]);

  const closeSyncConfirmationModal = useCallback(() => {
    toggleSyncConfirmationModal(false);
  }, [toggleSyncConfirmationModal]);

  const closeSyncNewPairsConfirmationModal = useCallback(() => {
    toggleSyncNewPairsConfirmationModal(false, "");
  }, [toggleSyncNewPairsConfirmationModal]);

  const resetSyncState = useCallback(() => {
    clearSyncManualStatus();
    clearCheckSync();
  }, [clearCheckSync, clearSyncManualStatus]);

  const callIntialApis = useCallback(() => {
    const formData = formDataWithApiKey();
    // Api's calls
    // Mailchimp
    getMailchimpAudience(formData);

    // CCB
    getCcbCampuses(formData);
    getCcbSavedSearches(formData);
    getMcToCcbPairs(formData);

    // Current plan
    getCurrentPlan(formData);
  }, [
    getCcbCampuses,
    getCcbSavedSearches,
    getCurrentPlan,
    getMailchimpAudience,
    getMcToCcbPairs,
  ]);

  useComponentWillUnmount(() => {
    clearIntegrationPageState();
    clearMailchimpAudience();
    if (history.location?.pathname !== "/sync/resolve-broken-pairs") {
      changeSyncType(CCB_TO_MC);
      setHasNotSyncedPairs(false);
    }
  }, []);

  useEffect(() => {
    if (syncManualStatus === "success") {
      closeSyncConfirmationModal();
    }
  }, [closeSyncConfirmationModal, syncManualStatus]);

  useEffect(() => {
    if (syncNewPairsStatus === "success") {
      closeSyncNewPairsConfirmationModal();
    }
  }, [closeSyncNewPairsConfirmationModal, syncNewPairsStatus]);

  useEffect(() => {
    // CLEAR FILTERS WHEN SEARCHING
    if (searchValue) {
      setPairFilters(filters);
    }
  }, [searchValue]);

  useEffect(() => {
    callIntialApis();
    if (runInitialSync) {
      resetSyncState();
    }
  }, [callIntialApis, resetSyncState, runInitialSync]);

  const errorSnackbar = useCallback(
    (errorMessage) => {
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage:
          errorMessage || "Something went wrong, please try agan later!",
      });
    },
    [setSnackbarData]
  );

  const successSnackbar = useCallback(() => {
    const formData = formDataWithApiKey();
    let action = (
      <Button className="view-link" onClick={() => syncManual(formData)}>
        Click Here to Sync New Pairs
      </Button>
    );
    const snackbarMessage = !lodash.isEmpty(mcToCcbDisableInactiveRemoveFor)
      ? "MC-TO-CCB Pair Updated Successfully"
      : `New ${
          currentSync === CCB_TO_MC ? "CCB-TO-MC" : "MC-TO-CCB"
        } Sync Pair Added Successfully`;
    if (
      disableSyncing ||
      lodash.isEmpty(cloneMcSyncPairs) ||
      lodash.isEmpty(cloneCcbSyncPairs) ||
      Number(userData?.isSkipMcToCcb) === 1
    ) {
      action = null;
    }
    setSnackbarData({
      snackbarSeverity: "success",
      showSnackbar: true,
      snackbarMessage,
      action: action,
    });
  }, [
    cloneCcbSyncPairs,
    currentSync,
    disableSyncing,
    cloneMcSyncPairs,
    setSnackbarData,
    syncManual,
    userData,
    mcToCcbDisableInactiveRemoveFor,
  ]);

  const closeAddNewSyncPairModal = useCallback(() => {
    toggleAddNewSyncPairModal(false);
  }, [toggleAddNewSyncPairModal]);

  const handleAppendCampuses = useCallback(
    (data) => {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < ccbCampuses.length; j++) {
          if (data[i].campusId === ccbCampuses[j].campusId) {
            Object.assign(data[i], { campusName: ccbCampuses[j].campusName });
          }
        }
      }
      return data;
    },
    [ccbCampuses]
  );

  const appendMcAudience = useCallback((data, mcAudience) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < mcAudience.length; j++) {
        if (data[i].mailchimpListId === mcAudience[j].id) {
          Object.assign(data[i], { mailchimpAudience: mcAudience[j].name });
        }
      }
    }
    return data;
  }, []);

  const handleAppendMcCampusGroups = useCallback(
    (data) => {
      const updatedData = [];
      for (let i = 0; i < data.length; i++) {
        const filterCcbGroupByCampusId = lodash.filter(
          ccbGroups,
          (group) => group != null && group.campusId === data[i].campusId
        );
        if (lodash.isEmpty(filterCcbGroupByCampusId)) {
          const formData = formDataWithApiKey();
          formData.append("campusId", data[i].campusId);
          setCcbGroups(formData);
          break;
        } else if (!lodash.isEmpty(filterCcbGroupByCampusId)) {
          const ccbGroup = getCampusGroupByid(ccbGroups, data[i].ccbGroupId);
          Object.assign(data[i], {
            ccbGroupName: ccbGroup?.ccbGroupName || "",
          });
          updatedData.push(data[i]);
        }
      }
      return updatedData;
    },
    [ccbGroups, setCcbGroups]
  );

  const handleAppendMcTags = useCallback((campusGroups) => {
    const updatedData = [];
    for (let i = 0; i < campusGroups.length; i++) {
      if (campusGroups[i].sync_type === 1) {
        if (campusGroups[i].tags) {
          Object.assign(campusGroups[i], {
            mcTags: JSON.parse(campusGroups[i].tags),
          });
        }
        updatedData.push(campusGroups[i]);
      } else {
        updatedData.push(campusGroups[i]);
      }
    }
    return updatedData;
  }, []);

  const handleAppendMcInterestGroup = useCallback(
    (data) => {
      const updatedData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].sync_type === 0) {
          const mcGroup = getMcInterestedGroupByUniqueId(
            mailchimpInterestedGroups,
            data[i]
          );
          if (lodash.isEmpty(mcGroup)) {
            if (
              !mailchimplistIdForInterestedGroups.includes(
                data[i].mailchimpListId
              )
            ) {
              const formData = formDataWithApiKey();
              formData.append("mailchimpListId", data[i].mailchimpListId);
              setMailchimpInterestedGroups(formData);
              break;
            } else if (data[i]?.hasMcConflict === 1) {
              updatedData.push(data[i]);
            }
          } else if (!lodash.isEmpty(mcGroup)) {
            Object.assign(data[i], {
              mcInterestGroupName: mcGroup?.groupName,
            });
            updatedData.push(data[i]);
          }
        } else {
          updatedData.push(data[i]);
        }
      }
      return updatedData;
    },
    [
      mailchimpInterestedGroups,
      mailchimplistIdForInterestedGroups,
      setMailchimpInterestedGroups,
    ]
  );

  const getCampusesGroups = useCallback(
    (campuses) => {
      try {
        const campusGroups = [];
        for (let i = 0; i < campuses.length; i++) {
          const filterCcbGroupByCampusId = lodash.filter(
            ccbGroups,
            (group) => group != null && group.campusId === campuses[i].campusId
          );
          if (lodash.isEmpty(filterCcbGroupByCampusId)) {
            const formData = formDataWithApiKey();
            formData.append("campusId", campuses[i].campusId);
            setCcbGroups(formData);
            break;
          } else if (!lodash.isEmpty(filterCcbGroupByCampusId)) {
            const syncPairs = getSyncedData(filterCcbGroupByCampusId);
            campusGroups.push(...syncPairs);
          }
        }
        return campusGroups;
      } catch (error) {
        errorSnackbar();
      }
    },
    [ccbGroups, errorSnackbar, setCcbGroups]
  );

  const getCampusProcessQueues = useCallback(
    (campuses) => {
      try {
        const campusProcessQueues = [];
        for (let i = 0; i < campuses.length; i++) {
          if (Number(campuses[i]?.hasPqSync) === 1) {
            const filterProcessQueueByCampusId = lodash.filter(
              ccbProcessQueues,
              (ccbPQ) =>
                ccbPQ != null &&
                ccbPQ?.processList?.campusId === campuses[i].campusId
            );
            if (lodash.isEmpty(filterProcessQueueByCampusId)) {
              const formData = formDataWithApiKey();
              formData.append("campusId", campuses[i].campusId);
              setCcbProcessQueue(formData);
              break;
            } else if (!lodash.isEmpty(filterProcessQueueByCampusId)) {
              const syncPairs = getSyncedData(filterProcessQueueByCampusId);
              const mergedCampus = syncPairs.map((syncPair) => {
                return {
                  ...syncPair,
                  campusName: campuses[i]?.campusName,
                  campusId: campuses[i]?.campusId,
                };
              });
              campusProcessQueues.push(...mergedCampus);
            }
          }
        }
        return campusProcessQueues;
      } catch (error) {
        errorSnackbar();
      }
    },
    [ccbProcessQueues, errorSnackbar, setCcbProcessQueue]
  );

  const setCcbToMcSyncPairs = useCallback(() => {
    try {
      if (
        ccbGroupStatus !== "loading" &&
        ccbProcessQueueStatus !== "loading" &&
        (!lodash.isEmpty(ccbSyncedSavedsearches) ||
          !lodash.isEmpty(syncedCcbCampuses))
      ) {
        const appendCcbGroup = !lodash.isEmpty(syncedCcbCampuses)
          ? getCampusesGroups(syncedCcbCampuses)
          : [];
        const appendCcbProcessQueues = !lodash.isEmpty(syncedCcbCampuses)
          ? getCampusProcessQueues(syncedCcbCampuses)
          : [];
        if (
          !lodash.isEmpty(appendCcbGroup) ||
          !lodash.isEmpty(ccbSyncedSavedsearches) ||
          !lodash.isEmpty(appendCcbProcessQueues)
        ) {
          const mergedPairs = [
            ...appendCcbGroup,
            ...ccbSyncedSavedsearches,
            ...appendCcbProcessQueues,
          ];
          const appendTags = handleAppendMcTags(mergedPairs);
          const finalData = appendMcAudience(appendTags, mailChimpAudience);
          setCcbSyncPairs(finalData);
          setCloneCcbSyncPairs(finalData);
        }
      }
    } catch (error) {
      errorSnackbar();
    }
  }, [
    appendMcAudience,
    ccbSyncedSavedsearches,
    errorSnackbar,
    getCampusesGroups,
    handleAppendMcTags,
    mailChimpAudience,
    syncedCcbCampuses,
    ccbGroupStatus,
    getCampusProcessQueues,
    ccbProcessQueueStatus,
  ]);

  const setMcToCcbSyncPairs = useCallback(
    (doNotSetState = false) => {
      if (
        !lodash.isEmpty(mailChimpAudience) &&
        !lodash.isEmpty(mcToCcbpairs) &&
        !loadingForMcToCcb
      ) {
        try {
          const appendCampuses = handleAppendCampuses(mcToCcbpairs);
          const appendMcTags = handleAppendMcTags(appendCampuses);
          const appendMcGroups = handleAppendMcInterestGroup(appendMcTags);
          const appendCcbGroup = handleAppendMcCampusGroups(
            lodash.isEmpty(appendMcGroups) ? appendMcTags : appendMcGroups
          );
          const mcCcbPairs = appendMcAudience(
            appendCcbGroup,
            mailChimpAudience
          );
          if (!doNotSetState) {
            setMcSyncPairs(mcCcbPairs);
            setCloneMcSyncPairs(mcCcbPairs);
          } else {
            return mcCcbPairs;
          }
        } catch (error) {
          errorSnackbar();
        }
      }
    },
    [
      appendMcAudience,
      errorSnackbar,
      handleAppendCampuses,
      handleAppendMcCampusGroups,
      handleAppendMcInterestGroup,
      handleAppendMcTags,
      loadingForMcToCcb,
      mailChimpAudience,
      mcToCcbpairs,
    ]
  );

  const closeMcToCcbDisableSyncConfirmationModal = useCallback(() => {
    setMcToCcbDisableInactiveFor({});
  }, [setMcToCcbDisableInactiveFor]);

  useEffect(() => {
    // this useEffect will call the warnings API after adding or removing sync pair
    if (
      [
        saveCcbToMcSyncPairStatus,
        removeCcbToMcPairStatus,
        saveMcToCcbSyncPairStatus,
        removeMcToCcbPairStatus,
      ].includes("success")
    ) {
      const formData = formDataWithApiKey();
      getWarnings(formData);
    }
  }, [
    getWarnings,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
  ]);

  useEffect(() => {
    // this useEffect set CCB_TO_MC pairs again after adding or removing sync pair
    if (
      currentSync === CCB_TO_MC &&
      [saveCcbToMcSyncPairStatus, removeCcbToMcPairStatus].includes(
        "success"
      ) &&
      !lodash.isEmpty(ccbCampuses) &&
      !lodash.isEmpty(ccbSavedSearches)
    ) {
      setCcbToMcSyncPairs();
      if (removeCcbToMcPairStatus === "success") {
        clearRemoveCcbToMcPair();
      }
    }
  }, [
    ccbCampuses,
    ccbSavedSearches,
    clearRemoveCcbToMcPair,
    currentSync,
    removeCcbToMcPairStatus,
    saveCcbToMcSyncPairStatus,
    setCcbToMcSyncPairs,
  ]);

  useEffect(() => {
    // SET CCB SYNC PAIRS
    if (
      currentSync === CCB_TO_MC &&
      !lodash.isEmpty(mailChimpAudience) &&
      (!lodash.isEmpty(ccbSyncedSavedsearches) ||
        !lodash.isEmpty(syncedCcbCampuses))
    ) {
      setCcbToMcSyncPairs();
    }
  }, [
    ccbSyncedSavedsearches,
    currentSync,
    mailChimpAudience,
    setCcbToMcSyncPairs,
    syncedCcbCampuses,
  ]);

  useEffect(() => {
    // SET MAILCHIMP SYNC PAIRS
    if (
      currentSync === MC_TO_CCB &&
      !lodash.isEmpty(mailChimpAudience) &&
      !lodash.isEmpty(mcToCcbpairs)
    ) {
      setMcToCcbSyncPairs();
    }
  }, [currentSync, mailChimpAudience, mcToCcbpairs, setMcToCcbSyncPairs]);

  // Toggle snackbar on save new sync pair
  useEffect(() => {
    if (
      [saveMcToCcbSyncPairStatus, saveCcbToMcSyncPairStatus].includes("success")
    ) {
      successSnackbar();
      if (saveMcToCcbSyncPairStatus === "success") {
        clearSaveMcToCcbPairStatus();
        closeMcToCcbDisableSyncConfirmationModal();
      }
      if (saveCcbToMcSyncPairStatus === "success") {
        clearIntegrationPageState();
      }
    } else if (
      [saveMcToCcbSyncPairStatus, saveCcbToMcSyncPairStatus].includes("error")
    ) {
      errorSnackbar(errorMessage);
      if (saveMcToCcbSyncPairStatus === "error") {
        clearSaveMcToCcbPairStatus();
      }
      if (saveCcbToMcSyncPairStatus === "error") {
        clearIntegrationPageState();
      }
    }
  }, [
    clearIntegrationPageState,
    clearSaveMcToCcbPairStatus,
    loadingForCcbToMc,
    loadingForMcToCcb,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
    successSnackbar,
    closeMcToCcbDisableSyncConfirmationModal,
    errorSnackbar,
    errorMessage,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (
      currentSync === CCB_TO_MC &&
      [saveCcbToMcSyncPairStatus, removeCcbToMcPairStatus].includes(
        "success"
      ) &&
      !Boolean(ccbCampuses.length) &&
      !Boolean(ccbSavedSearches.length)
    ) {
      getCcbCampuses(formData);
      getCcbSavedSearches(formData);
      if (removeCcbToMcPairStatus === "success") {
        closeRemoveSyncModal();
        if (cloneCcbSyncPairs.length === 1) {
          setCcbSyncPairs([]);
          setCloneCcbSyncPairs([]);
        }
      } else {
        closeAddNewSyncPairModal();
      }
    } else if (
      currentSync === MC_TO_CCB &&
      [saveMcToCcbSyncPairStatus, removeMcToCcbPairStatus].includes(
        "success"
      ) &&
      !Boolean(mcToCcbpairs.length)
    ) {
      getMcToCcbPairs(formData);
      if (removeMcToCcbPairStatus === "success") {
        closeRemoveSyncModal();
        clearRemoveMcToCcbPair();
        if (cloneMcSyncPairs.length === 1) {
          setMcSyncPairs([]);
          setCloneMcSyncPairs([]);
        }
      } else {
        closeAddNewSyncPairModal();
      }
    }
  }, [
    ccbCampuses,
    ccbSavedSearches,
    clearRemoveMcToCcbPair,
    cloneCcbSyncPairs,
    cloneMcSyncPairs,
    closeAddNewSyncPairModal,
    closeRemoveSyncModal,
    currentSync,
    getCcbCampuses,
    getCcbSavedSearches,
    getMcToCcbPairs,
    mcToCcbpairs,
    removeCcbToMcPairStatus,
    removeMcToCcbPairStatus,
    saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus,
  ]);

  useEffect(() => {
    prePopulateCcbToMcPairs();
  }, [prePopulateCcbToMcPairs]);

  const handleReload = useCallback(() => {
    if (currentSync === CCB_TO_MC) {
      setCcbToMcSyncPairs();
      setShowSearchFor("");
    } else {
      setMcToCcbSyncPairs();
      setShowSearchFor("");
    }
  }, [currentSync, setCcbToMcSyncPairs, setMcToCcbSyncPairs, setShowSearchFor]);

  const activeAllOptions = useCallback((data) => {
    data.forEach((el) => {
      el.active = true;
    });
  }, []);

  const filteringData = useCallback(
    (cloneFilterPairs, identity) => {
      const cloneSyncPairs =
        currentSync === CCB_TO_MC ? cloneCcbSyncPairs : cloneMcSyncPairs;
      const activeFilter = cloneFilterPairs[identity].filter(
        (item) => item.active
      );
      if (activeFilter.length === 2) {
        return cloneSyncPairs;
      } else if (activeFilter.length === 1) {
        const filteredData = cloneSyncPairs.filter(
          getFitlerConditionByKey(activeFilter[0].id)
        );
        return filteredData;
      }
      return cloneSyncPairs;
    },
    [cloneCcbSyncPairs, cloneMcSyncPairs, currentSync]
  );

  const handleFilteration = useCallback(
    (identity, index) => {
      const cloneFilterPairs = deepClone(pairFilters);
      cloneFilterPairs[identity][index].active =
        !cloneFilterPairs[identity][index].active;
      if (identity === CCB_CAMPUSES) {
        activeAllOptions(cloneFilterPairs[MAILCHIMP_GROUPS]);
      } else if (identity === MAILCHIMP_GROUPS) {
        activeAllOptions(cloneFilterPairs[CCB_CAMPUSES]);
      }
      const filteredData = filteringData(cloneFilterPairs, identity);
      if (currentSync === CCB_TO_MC) {
        setCcbSyncPairs(filteredData);
      } else {
        setMcSyncPairs(filteredData);
      }
      setPairFilters(cloneFilterPairs);
    },
    [activeAllOptions, currentSync, filteringData, pairFilters]
  );

  const handleSyncPairs = useCallback(
    (event, isNew) => {
      const formData = formDataWithApiKey();
      if (isNew) {
        syncNewPairs(formData);
      } else {
        syncManual(formData);
      }
    },
    [syncManual, syncNewPairs]
  );

  const openSyncConfirmationModal = useCallback(
    (val) => {
      toggleSyncConfirmationModal(val);
    },
    [toggleSyncConfirmationModal]
  );

  const openSyncNewPairsConfirmationModal = useCallback(
    (val) => {
      toggleSyncNewPairsConfirmationModal(val, "");
    },
    [toggleSyncNewPairsConfirmationModal]
  );

  const togglePurchasePlanToSyncModal = useCallback(() => {
    setShowPurchasePlanToSyncModal(!showPurchasePlanToSyncModal);
  }, [showPurchasePlanToSyncModal]);

  const openModalIfBrokenPairs = useCallback(() => {
    setShowFixBorkenPairConfirmationModal(true);
  }, []);

  const onClickedFixBrokenPairs = useCallback(() => {
    const ccbToMcBrokenPairs = filterBrokenPairs(cloneCcbSyncPairs);
    let mcToCcbBrokenPairs;
    if (!cloneMcSyncPairs.length) {
      const mcToCcbPairs = setMcToCcbSyncPairs(true);
      mcToCcbBrokenPairs = filterBrokenPairs(mcToCcbPairs);
    } else {
      mcToCcbBrokenPairs = filterBrokenPairs(cloneMcSyncPairs);
    }
    setDataForModal({
      ccbToMcBrokenPairs,
      mcToCcbBrokenPairs,
      mailChimpAudience,
      ccbCampuses,
      syncType: currentSync,
    });
    if (!lodash.isEmpty(ccbToMcBrokenPairs)) {
      changeSyncType(CCB_TO_MC);
    } else if (!lodash.isEmpty(mcToCcbBrokenPairs)) {
      changeSyncType(MC_TO_CCB);
    }
    history.push("/sync/resolve-broken-pairs");
  }, [
    cloneCcbSyncPairs,
    cloneMcSyncPairs,
    history,
    mailChimpAudience,
    setDataForModal,
    setMcToCcbSyncPairs,
    ccbCampuses,
    changeSyncType,
    currentSync,
  ]);

  const onClickedProceedSync = useCallback(() => {
    handleSyncPairs();
    setShowFixBorkenPairConfirmationModal(false);
  }, [handleSyncPairs]);

  const onClickedMcToCcbDisableInactiveRemove = useCallback(() => {
    const formData = formDataWithApiKey();
    const isMcToCcbDisableInactiveRemoveVal =
      Number(
        mcToCcbDisableInactiveRemoveFor?.isMcToCcbDisableInactiveRemove
      ) === 1
        ? 0
        : 1;
    formData.append(
      "params[0][mcListId]",
      mcToCcbDisableInactiveRemoveFor?.mailchimpListId
    );
    formData.append("params[0][id]]", mcToCcbDisableInactiveRemoveFor?.id);
    formData.append(
      "params[0][isMcToCcbDisableInactiveRemove]",
      isMcToCcbDisableInactiveRemoveVal
    );
    formData.append(
      "params[0][syncType]",
      mcToCcbDisableInactiveRemoveFor?.sync_type
    );
    formData.append(
      "params[0][campusId]",
      mcToCcbDisableInactiveRemoveFor?.campusId
    );
    formData.append(
      "params[0][ccbGroupId]",
      mcToCcbDisableInactiveRemoveFor?.ccbGroupId
    );
    formData.append(
      "params[0][interestCategoryId]",
      mcToCcbDisableInactiveRemoveFor?.mailchimpListInterestCategoryId
    );
    formData.append(
      "params[0][interestId]",
      mcToCcbDisableInactiveRemoveFor?.mailchimpListGroupId
    );
    saveMcToCcbSyncPair(formData);
  }, [mcToCcbDisableInactiveRemoveFor, saveMcToCcbSyncPair]);

  const sortLisByConditions = useCallback((syncPairs = []) => {
    return syncPairs.sort(function (a, b) {
      if (a.hasMcConflict && !b.hasMcConflict) {
        return -1;
      } else if (!a.hasMcConflict && b.hasMcConflict) {
        return 1;
      } else if (a.isNew && !b.isNew) {
        return -1;
      } else if (!a.isNew && b.isNew) {
        return 1;
      } else {
        return 0;
      }
    });
  }, []);

  return (
    <Fragment>
      <div
        className={clsx("page-wrapper sync-page-wrapper", {
          "with-sync-page-sidebar-expanded": isSidebarExpanded,
        })}
      >
        <div className="desktop-design services">
          <Topbar
            openSyncConfirmationModal={openSyncConfirmationModal}
            openSyncNewPairsConfirmationModal={
              openSyncNewPairsConfirmationModal
            }
            notSyncedPairs={notSyncedPairs}
            togglePurchasePlanToSyncModal={togglePurchasePlanToSyncModal}
            openModalIfBrokenPairs={openModalIfBrokenPairs}
          />
          <Grid container className="service-container-wrapper">
            <SyncList
              ccbToMcSyncPairs={sortLisByConditions(ccbSyncPairs)}
              mcToCcbSyncPairs={sortLisByConditions(mcSyncPairs)}
              notSyncedPairs={notSyncedPairs}
              handleFilteration={handleFilteration}
              pairFilters={pairFilters}
              handleSearch={onChange}
              handleReload={handleReload}
              searchValue={searchValue}
              loading={loading}
              onClickFixBrokePair={onClickedFixBrokenPairs}
            />
          </Grid>
        </div>
      </div>
      {/* Remove sync pair modal */}
      {showRemoveSyncPairModal && (
        <RemoveSyncPairConfirmationModal
          open={showRemoveSyncPairModal}
          handleClose={closeRemoveSyncModal}
          pairToRemove={modalData}
          currentSync={currentSync}
          loading={[removeCcbToMcPairStatus, removeMcToCcbPairStatus].includes(
            "loading"
          )}
        />
      )}

      {/* Syncing confirmation modal */}
      {showSyncConfirmationModal && (
        <SyncConfirmationModal
          open={showSyncConfirmationModal}
          handleClose={closeSyncConfirmationModal}
          handleSyncPairs={handleSyncPairs}
        />
      )}

      {/* sync new pairs confirmation modal */}
      {showSyncNewPairConfirmationModal && (
        <SyncNewPairConfirmationModal
          open={showSyncNewPairConfirmationModal}
          handleClose={closeSyncNewPairsConfirmationModal}
          handleSyncingProgressVisibility={handleSyncingProgressVisibility}
          handleSyncPairs={handleSyncPairs}
          syncPairs={[...cloneCcbSyncPairs, ...mcToCcbpairs]}
          syncManualStatus={syncManualStatus}
          syncNewPairsStatus={syncNewPairsStatus}
          showSyncNewPairSkipRoute={showSyncNewPairSkipRoute}
        />
      )}

      {/* Add new sync pair modal */}
      {showAddNewSyncPairModal && (
        <AddNewSyncPairModal
          open={showAddNewSyncPairModal}
          handleClose={closeAddNewSyncPairModal}
          loading={[
            saveCcbToMcSyncPairStatus,
            saveMcToCcbSyncPairStatus,
          ].includes("loading")}
          callIntialApis={callIntialApis}
          mcToCcbPairs={cloneMcSyncPairs}
          prePopulatedPairs={prePopulatedPairs}
        />
      )}

      {/* Purchase a plan to sync notfier modal */}
      {showPurchasePlanToSyncModal && (
        <PurchasePlanToSync
          open={showPurchasePlanToSyncModal}
          handleClose={togglePurchasePlanToSyncModal}
        />
      )}
      {/* Search warning modal */}
      {showSearchWarning && (
        <SearchWarning
          open={showSearchWarning}
          handleClose={() => setShowSearchWarning(false)}
        />
      )}
      {/* Fix broken pair confirmation modal */}
      {showFixBorkenPairConfirmationModal && (
        <FixBrokenPairConfirmationModal
          open={showFixBorkenPairConfirmationModal}
          handleClose={() => setShowFixBorkenPairConfirmationModal(false)}
          onClick={onClickedFixBrokenPairs}
          onClickedProceedSync={onClickedProceedSync}
          disabledSyncing={disableSyncing}
        />
      )}

      {/* MC TO CCB disable inactive remove confirmation modal */}
      {!lodash.isEmpty(mcToCcbDisableInactiveRemoveFor) && (
        <ConfirmationModal
          open={!lodash.isEmpty(mcToCcbDisableInactiveRemoveFor)}
          handleClose={closeMcToCcbDisableSyncConfirmationModal}
          modalContent={
            Number(
              mcToCcbDisableInactiveRemoveFor?.isMcToCcbDisableInactiveRemove
            ) === 1
              ? MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_DISABLE
              : MC_TO_CCB_DISABLE_INACTIVE_REMOVE_MESSAGE_FOR_ENABLE
          }
          modalHeading={
            Number(
              mcToCcbDisableInactiveRemoveFor?.isMcToCcbDisableInactiveRemove
            ) === 1
              ? "Retain Unsubscribed MailChimp Email?"
              : "Remove Unsubscribed MailChimp Email?"
          }
          onClick={onClickedMcToCcbDisableInactiveRemove}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30 mr-3"}
          loading={saveMcToCcbSyncPairStatus === "loading"}
          disabled={saveMcToCcbSyncPairStatus === "loading"}
        />
      )}
      {(loadingForCcbToMc || loadingForMcToCcb) && <Loader />}
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    userApiDetails: store.userReducer.userApiDetails,
    modalData: store.globalReducer.modalData,
    loading: store.globalReducer.isLoading,
    currentSync: store.integrations.currentSync,
    showRemoveSyncPairModal: store.integrations.showRemoveSyncPairModal,
    showSyncConfirmationModal: store.integrations.showSyncConfirmationModal,
    showSyncNewPairConfirmationModal:
      store.integrations.showSyncNewPairConfirmationModal,
    showAddNewSyncPairModal: store.integrations.showAddNewSyncPairModal,
    ccbSavedSearches: store.integrations.ccbSavedSearches,
    ccbCampuses: store.integrations.ccbCampuses,
    mailChimpAudience: store.integrations.mailChimpAudience,
    syncedCcbCampuses: store.integrations.syncedCcbCampuses,
    ccbSyncedSavedsearches: store.integrations.ccbSyncedSavedsearches,
    mcToCcbpairs: store.integrations.mcToCcbpairs,
    showSearchFor: store.integrations.showSearchFor,
    removeCcbToMcPairStatus: store.integrations.removeCcbToMcPairStatus,
    removeMcToCcbPairStatus: store.integrations.removeMcToCcbPairStatus,
    saveCcbToMcSyncPairStatus: store.integrations.saveCcbToMcSyncPairStatus,
    saveMcToCcbSyncPairStatus: store.integrations.saveMcToCcbSyncPairStatus,
    syncManualStatus: store.integrations.syncManualStatus,
    syncNewPairsStatus: store.integrations.syncNewPairsStatus,
    runInitialSync: store.userReducer.runInitialSync,
    mcToCcbpairsStatus: store.integrations.mcToCcbpairsStatus,
    mailchimpInterestedGroups: store.integrations.mailchimpInterestedGroups,
    ccbGroups: store.integrations.ccbGroups,
    ccbGroupStatus: store.integrations.ccbGroupStatus,
    mailchimpTags: store.integrations.mailchimpTags,
    mailchimpListIdForTags: store.integrations.mailchimpListIdForTags,
    mailchimplistIdForInterestedGroups:
      store.integrations.mailchimplistIdForInterestedGroups,
    showSyncNewPairSkipRoute: store.integrations.showSyncNewPairSkipRoute,
    mcToCcbDisableInactiveRemoveFor:
      store.integrations.mcToCcbDisableInactiveRemoveFor,
    ccbProcessQueues: store.integrations.ccbProcessQueues,
    ccbProcessQueueStatus: store.integrations.ccbProcessQueueStatus,
    errorMessage: store.integrations.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    getCurrentPlan: (formData) => dispatch(getCurrentPlan(formData)),
    getMailchimpAudience: (data, requestId) =>
      dispatch(getMailchimpAudience(data, requestId)),
    changeSyncType: (sync) => dispatch(changeSyncType(sync)),
    toggleRemoveSyncPairModal: (val) =>
      dispatch(toggleRemoveSyncPairModal(val)),
    toggleSyncConfirmationModal: (val) =>
      dispatch(toggleSyncConfirmationModal(val)),
    toggleSyncNewPairsConfirmationModal: (val, skipRoute) =>
      dispatch(toggleSyncNewPairsConfirmationModal(val, skipRoute)),
    handleSyncingProgressVisibility: (val) =>
      dispatch(handleSyncingProgressVisibility(val)),
    toggleAddNewSyncPairModal: (val) =>
      dispatch(toggleAddNewSyncPairModal(val)),
    getCcbCampuses: (data, requestId) =>
      dispatch(getCcbCampuses(data, requestId)),
    getCcbSavedSearches: (data, requestId) =>
      dispatch(getCcbSavedSearches(data, requestId)),
    getMcToCcbPairs: (data, requestId) =>
      dispatch(getMcToCcbPairs(data, requestId)),
    setShowSearchFor: (identity) => dispatch(setShowSearchFor(identity)),
    syncManual: (data, requestId) => dispatch(syncManual(data, requestId)),
    syncNewPairs: (data, requestId) =>
      dispatch(syncOnlyNewPairs(data, requestId)),
    setDataForModal: (val) => dispatch(setDataForModal(val)),
    setMailchimpInterestedGroups: (data) =>
      dispatch(setMailchimpInterestedGroups(data)),
    setCcbGroups: (data) => dispatch(setCcbGroups(data)),
    setCcbProcessQueue: (data) => dispatch(setCcbProcessQueue(data)),
    setMailchimpTags: (data) => dispatch(setMailchimpTags(data)),
    setHasNotSyncedPairs: (data) => dispatch(setHasNotSyncedPairs(data)),
    saveMcToCcbSyncPair: (data, requestId) =>
      dispatch(saveMcToCcbSyncPair(data, requestId)),
    setMcToCcbDisableInactiveFor: (val) =>
      dispatch(setMcToCcbDisableInactiveFor(val)),
    getWarnings: (data) => dispatch(getWarnings(data)),
    clearIntegrationPageState: () => dispatch(clearIntegrationPageState()),
    clearSyncManualStatus: () => dispatch(clearSyncManualStatus()),
    clearCheckSync: () => dispatch(clearCheckSync()),
    clearRemoveCcbToMcPair: () => dispatch(clearRemoveCcbToMcPair()),
    clearRemoveMcToCcbPair: () => dispatch(clearRemoveMcToCcbPair()),
    clearMailchimpAudience: () => dispatch(clearMailchimpAudience()),
    clearSaveMcToCcbPairStatus: () => dispatch(clearSaveMcToCcbPairStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
