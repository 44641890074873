import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
} from "../../../../utils/integrationsConstants";
import CcbIcon from "../../../../assets/img/ccb/ccb-small.png";
import MailchimpIcon from "../../../../assets/img/mailchimp/mailchimp-small.png";
import { Group, LocalOffer } from "@material-ui/icons";

export const dataForCcbModal = [
  {
    [CCB_CAMPUSES]: {
      headerTitle: "CCB Campuses / Saved Search",
      icon: CcbIcon,
      placeholder: "Select CCB Campus",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["campusName", "name"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      id: null,
      isProcessQueueSelected: 0,
    },
    [CCB_GROUPS]: {
      headerTitle: "CCB Groups / Process Queues",
      icon: CcbIcon,
      placeholder: "Select CCB Group",
      syncType: 0,
      hasArrowForwardIcon: true,
      options: [],
      processQueueOptions: [],
      loadingText: "No Options",
      displayKey: ["ccbGroupName", "name", "queueName"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
    [MAILCHIMP_AUDIENCE]: {
      headerTitle: "MailChimp Audience",
      icon: MailchimpIcon,
      placeholder: "Select Audience",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["name"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
    [MAILCHIMP_GROUPS]: {
      headerTitle: "Audience Tag / Groups",
      icon: MailchimpIcon,
      placeholder: "Select MC Groups",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["groupName"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
  },
];

export const dataForMcModal = [
  {
    [MAILCHIMP_AUDIENCE]: {
      headerTitle: "MailChimp Audience",
      icon: MailchimpIcon,
      placeholder: "Select Audience",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["name"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
    [MAILCHIMP_GROUPS]: {
      headerTitle: "Audience Tag / Groups",
      icon: MailchimpIcon,
      placeholder: "Select MC Groups",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["groupName"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
    [CCB_CAMPUSES]: {
      headerTitle: "CCB Campuses",
      icon: CcbIcon,
      placeholder: "Select CCB Campus",
      syncType: 0,
      hasArrowForwardIcon: false,
      options: [],
      loadingText: "No Options",
      displayKey: ["campusName", "name"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
      id: null,
    },
    [CCB_GROUPS]: {
      headerTitle: "CCB Groups",
      icon: CcbIcon,
      placeholder: "Select CCB Group",
      syncType: 0,
      hasArrowForwardIcon: true,
      options: [],
      loadingText: "No Options",
      displayKey: ["ccbGroupName", "name"],
      selectedId: null,
      value: "",
      tags: [],
      isSavedSearchSelected: 0,
      isProcessQueueSelected: 0,
    },
  },
];

export const mcGroupsAndTagsTab = [
  {
    title: "Groups",
    icon: Group,
    value: 0,
  },
  {
    title: "Tags",
    icon: LocalOffer,
    value: 1,
  },
];

export const ccbCampusAndSavedSearch = [
  {
    title: "Campus",
    value: 0,
  },
  {
    title: "Saved Search",
    value: 1,
  },
];

export const ccbGroupsAndProcessQueue = [
  {
    title: "Group",
    value: 0,
  },
  {
    title: "Process Queue",
    value: 1,
  },
];
