import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as toolsMiddleware from "../middlewares/toolsMiddleware";
import * as toolsActions from "../actions/toolsActions";

export function useToolsStore() {
  const dispatch = useDispatch();
  const _tools = useSelector((store) => store.tools);

  const saveMcToCcbRemoveInactive = useCallback(
    async (data) => {
      await dispatch(toolsMiddleware.saveMcToCcbRemoveInactive(data));
    },
    [dispatch]
  );

  const clearToolPageState = useCallback(async () => {
    await dispatch(toolsActions.clearToolPageState());
  }, [dispatch]);

  const saveAutoSync = useCallback(
    async (data) => {
      await dispatch(toolsMiddleware.saveAutoSync(data));
    },
    [dispatch]
  );

  const saveMemberSettings = useCallback(
    async (data) => {
      await dispatch(toolsMiddleware.saveMemberSettings(data));
    },
    [dispatch]
  );

  return {
    tools: _tools,
    saveMcToCcbRemoveInactive,
    clearToolPageState,
    saveAutoSync,
    saveMemberSettings,
  };
}

export default useToolsStore;
