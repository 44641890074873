import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import SideScreenBanner from "../../../components/SideScreenBanner/SideScreenBanner";
import InputField from "../../../components/InputField/InputField";
import LogoWithHeading from "../../../components/LogoWithHeading/LogoWithHeading";
import MaterialButton from "../../../components/ MaterialButton/MaterialButton";
import { getUrlParameterByName, validateEmptyFields } from "../../../helpers";
import api from "../../../services/api";
import Loader from "../../../components/Loader/Loader";
import "../../../styles/views/login.scss";

const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, seterrors] = useState({});
  const [simpleError, setSimpleError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    match: { params },
    history,
  } = props;

  const checkIsRecoveryUrlExpires = useCallback((valDateTime) => {
    const validityDate = new Date();
    validityDate.setTime(valDateTime * 1000);
    const currentDateAndTime = new Date();
    if (currentDateAndTime > validityDate) {
      // Check is validity time older than 15 minues
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const recoveryEmailValidityTimestamp = getUrlParameterByName("v");
    if (recoveryEmailValidityTimestamp) {
      const isRecoveryPasswordUrlExpired = checkIsRecoveryUrlExpires(
        recoveryEmailValidityTimestamp
      );
      if (isRecoveryPasswordUrlExpired) {
        history.push("/forgot-password", { isRecoveryPasswordUrlExpired });
      }
    }
  }, [checkIsRecoveryUrlExpires, history]);

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleConfirmPasswordVisibility = useCallback(() => {
    setShowConfirmPassword(!showConfirmPassword);
  }, [showConfirmPassword]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const emptyFields = validateEmptyFields(inputs);
      if (emptyFields.length) {
        const errors = emptyFields.reduce(
          (occ, key) => Object.assign(occ, { [key]: "Field cannot be empty" }),
          {}
        );
        return seterrors(errors);
      } else if (inputs.confirmPassword !== inputs.password) {
        return setSimpleError("Passwords incorrect");
      } else {
        try {
          const formData = new FormData();
          seterrors({});
          setSimpleError("");
          setInputs({
            password: "",
            confirmPassword: "",
          });
          setLoading(true);
          formData.append("apiKey", params?.key);
          const userDetailsResponse = await api.getUserDetails(formData);
          if (userDetailsResponse.status === 1) {
            formData.append("email", userDetailsResponse?.data?.email);
          } else {
            setLoading(false);
            setSimpleError(userDetailsResponse.message);
            return;
          }
          formData.delete("apiKey");
          formData.append("password", inputs.password);
          formData.append("confirmPassword", inputs.confirmPassword);
          const apiResponseBack = await api.changePassword(formData);
          setLoading(false);
          if (apiResponseBack.status === 1) {
            setSuccessMessage(apiResponseBack.message);
          } else {
            setSimpleError(apiResponseBack.message);
          }
        } catch (error) {
          setLoading(false);
          setSimpleError(error.message);
        }
      }
    },
    [inputs, params]
  );

  return (
    <div className="flexer vh-100">
      <div className="login-page">
        <LogoWithHeading title="Reset Password" width={40} />
        <form name="form" onSubmit={handleSubmit}>
          {simpleError && <h6 className="text-danger">{simpleError}</h6>}
          {successMessage && <h6 className="text-success">{successMessage}</h6>}
          <InputField
            name="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            className="text-field"
            showPassword={showPassword}
            handlePasswordVisibility={handlePasswordVisibility}
            value={inputs.password}
            error={Boolean(errors.password)}
            helperText={errors.password}
            handleChange={handleChange}
          />
          <InputField
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            className="text-field"
            showPassword={showConfirmPassword}
            handlePasswordVisibility={handleConfirmPasswordVisibility}
            value={inputs.confirmPassword}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            handleChange={handleChange}
          />
          <Button
            color="primary"
            variant="text"
            component={Link}
            to="/"
            classes={{
              root: "btn",
              label: "label",
              textPrimary: "text-end",
            }}
          >
            <span className="forgot-pw">Back to login</span>
          </Button>
          <MaterialButton title="Reset password" className={"w-50"} />
        </form>
      </div>
      <SideScreenBanner />
      {loading && <Loader />}
    </div>
  );
};

export default ResetPassword;
