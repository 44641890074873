import { useState, useCallback } from "react";
import { deepClone, populateSyncpairs } from "../../helpers";
import lodash from "lodash";
import {
  dataForCcbModal,
  dataForMcModal,
} from "../../components/modals/IntegrationsPageModal/AddNewSyncPairModal/addNewSyncPairModalData";
import useIntegrationStore from "../../store/reduxStore/integrationsStore";
import { CCB_TO_MC } from "../integrationsConstants";

export const usePopulateSyncPair = (isOnBrokenSyncPair) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    integrations,
    setMailchimpInterestedGroups,
    setCcbGroups,
    setMailchimpTags,
    setCcbProcessQueue,
  } = useIntegrationStore();
  const {
    currentSync,
    ccbCampuses,
    mailChimpAudience,
    mailchimpInterestedGroups,
    ccbGroups,
    mailchimpTags,
    mailchimpListIdForTags,
    mailchimplistIdForInterestedGroups,
    ccbSavedSearches,
    ccbProcessQueues,
  } = integrations;

  const populateSyncPairInModalData = useCallback(
    async (syncPairsToPopulate, isWithPopulateForBoth, setModalData) => {
      setIsLoading(true);
      const populateModalData = await populateSyncpairs(
        deepClone(
          currentSync === CCB_TO_MC ? dataForCcbModal[0] : dataForMcModal[0]
        ),
        syncPairsToPopulate,
        ccbCampuses,
        mailChimpAudience,
        isOnBrokenSyncPair,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        setCcbGroups,
        ccbGroups,
        mailchimpTags,
        setMailchimpTags,
        mailchimpListIdForTags,
        mailchimplistIdForInterestedGroups,
        ccbSavedSearches,
        ccbProcessQueues,
        setCcbProcessQueue,
        isWithPopulateForBoth,
        currentSync
      );
      if (!lodash.isEmpty(populateModalData)) {
        setIsLoading(false);
        setModalData(populateModalData);
      }
    },
    [
      ccbCampuses,
      ccbGroups,
      ccbProcessQueues,
      ccbSavedSearches,
      isOnBrokenSyncPair,
      mailChimpAudience,
      mailchimpInterestedGroups,
      mailchimpListIdForTags,
      mailchimpTags,
      mailchimplistIdForInterestedGroups,
      setCcbGroups,
      setCcbProcessQueue,
      setMailchimpInterestedGroups,
      setMailchimpTags,
      currentSync,
    ]
  );

  return {
    populateSyncPairInModalData,
    isLoading,
    setIsLoading,
  };
};
