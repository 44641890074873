import { ACTION_TYPES } from "./types";

export const clearCancelPlanStatus = () => ({
  type: ACTION_TYPES.CANCEL_PLAN_CLEAR,
});

export const clearEditCardState = () => ({
  type: ACTION_TYPES.EDIT_CARD_STATE_CLEAR,
});

export const clearPurhcasePlanState = () => ({
  type: ACTION_TYPES.PURCHASE_PLAN_CLEAR,
});

export const clearCouponState = () => ({
  type: ACTION_TYPES.CHECK_COUPON_CLEAR,
});

export const toggleCardDeclineModal = (payload) => ({
  type: ACTION_TYPES.TOGGLE_BILLING_DECLINE_CARD,
  payload,
});

export const toggleClearOverdueInvoiceModal = (payload) => ({
  type: ACTION_TYPES.TOGGLE_CLEAR_OVERDUE_INVOICE_MODAL,
  payload,
});
