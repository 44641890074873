import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import MaterialButton from "../../ MaterialButton/MaterialButton";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import {
  clearUpdateProfile,
  updateUserProfile,
} from "../../../store/middlewares/userMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import { SEARCH_WARNING } from "../../../utils/integrationsConstants";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const SearchWarning = (props) => {
  const {
    //Component props
    open,
    handleClose,

    // Redux props
    updateProfileStatus,

    // Redux function
    updateUserProfile,
    clearUpdateProfile,

    // Router props
    history,
  } = props;
  const breakpoint = useBreakpoint();

  const resetStatus = useCallback(
    (requestId) => () => {
      clearUpdateProfile(requestId);
    },
    [clearUpdateProfile]
  );

  useEffect(() => {
    if (updateProfileStatus["v2_search_warning"] === "success") {
      resetStatus("v2_search_warning");
      handleClose();
    }
  }, [handleClose, resetStatus, updateProfileStatus]);

  const onClick = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("v2_search_warning", 1);
    updateUserProfile(formData, "v2_search_warning");
  }, [updateUserProfile]);

  const onClickedLearnMore = useCallback(() => {
    handleClose();
    history.push("/integrations/setup-ccb");
  }, [handleClose, history]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0" : "",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="modal-main-heading">
          Action Required: New People Searches Requirements!
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {ReactHtmlParser(SEARCH_WARNING)}{" "}
          <span onClick={onClickedLearnMore} className="link-text">
            (Learn more)
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-between">
        <MaterialButton
          title="Do Later"
          className="ml-3 w-30 dark-gray-button"
          onClick={handleClose}
        />
        <ButtonWithSpinner
          className="w-30 bg-yellow mr-3"
          onClick={onClick}
          loading={updateProfileStatus["v2_search_warning"] === "loading"}
        >
          Recheck
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};

SearchWarning.defaultProps = {
  open: false,
  loading: false,
};

SearchWarning.propType = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    updateProfileStatus: store.userReducer.updateProfileStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (data, requestId) =>
      dispatch(updateUserProfile(data, requestId)),
    clearUpdateProfile: (requestId) => dispatch(clearUpdateProfile(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchWarning));
