import React from "react";
import PropTypes from "prop-types";
import { SupervisorAccountRounded } from "@material-ui/icons";
import { ReactComponent as CopyClipboard } from "../../../assets/icons/copy-clipboard-blue.svg";
import useGlobalStore from "../../../store/reduxStore/globalStore";

const UsersTableRow = (props) => {
  const { data, onViewClick, onLoginClick } = props;

  // Redux actions
  const { setSnackbarData } = useGlobalStore();

  const handleCopyToClipboard = async (textToCopy) => {
    if (textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        setSnackbarData({
          snackbarSeverity: "success",
          showSnackbar: true,
          snackbarMessage: "Activation link copied to clipboard",
        });
      } catch (err) {
        setSnackbarData({
          snackbarSeverity: "error",
          showSnackbar: true,
          snackbarMessage: `Failed to copy text to clipboard`,
        });
      }
    }
  };

  return (
    <tr>
      <td></td>
      <td>
        <div>
          <span className="flexer-start">
            {data.email}{" "}
            {data.role === 1 && (
              <SupervisorAccountRounded fontSize="small" className="ml-1" />
            )}
          </span>
          {data.role === 2 && (
            <span onClick={() => onLoginClick(data)} className="link-text">
              Login
            </span>
          )}
        </div>
      </td>
      <td>{data.firstName}</td>
      <td>{data.lastName}</td>
      <td>{data.ccbAccount || `(Not set)`}</td>
      <td className="pl-5">{data.appVersion}</td>
      <td>{data.apiKey || "Unknown"}</td>
      <td className="w-15">
        <div>
          <div onClick={() => onViewClick(data)} className="link-text">
            View
          </div>
          {data?.activationLink && (
            <div
              onClick={() => handleCopyToClipboard(data?.activationLink)}
              className="link-text mt-1 d-flex align-items-center"
            >
              <CopyClipboard height={16} width={16} className="mr-1" /> Copy
              Activation Link
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

UsersTableRow.propTypes = {
  data: PropTypes.object,
  onViewClick: PropTypes.func.isRequired,
};

UsersTableRow.defaultProps = {
  data: {},
};

export default UsersTableRow;
