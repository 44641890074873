import config from "../../config";
import { getRequest } from "../../utils/request";
import { ACTION_TYPES } from "../actions/types";

export function getAllCountries() {
  return (dispatch) => {
    getRequest(
      dispatch,
      ACTION_TYPES.GET_ALL_COUNTRIES,
      config.Path.GET_ALL_COUNTRIES
    );
  };
}
