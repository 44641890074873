import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg";

const TwoAndOneWayIcon = (props) => {
  const { isTwoWay } = props;
  return (
    <div className="one-two-way-icon">
      {isTwoWay && <ArrowLeft className="mr-1" height={16} width={16} />}
      <span>{isTwoWay ? "2-way" : "1-way"}</span>
      <ArrowRight className="ml-1" height={16} width={16} />
    </div>
  );
};

TwoAndOneWayIcon.propTypes = {
  isTwoWay: PropTypes.bool,
};

TwoAndOneWayIcon.defaultProps = {
  isTwoWay: false,
};

export default TwoAndOneWayIcon;
