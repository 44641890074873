import React from "react";
import PropType from "prop-types";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const InputField = (props) => {
  const {
    name,
    className,
    label,
    type,
    showPassword,
    error,
    value,
    helperText,
    handlePasswordVisibility,
    handleChange,
    placeholder,
    inputClass,
    startAdornment,
    endAdornment,
    disabled,
  } = props;
  return (
    <TextField
      label={label}
      name={name}
      type={type}
      value={value}
      error={error}
      onChange={handleChange}
      helperText={helperText}
      placeholder={placeholder}
      disabled={disabled}
      margin="normal"
      variant="filled"
      classes={{
        root: className,
      }}
      InputLabelProps={{
        classes: {
          root: "label",
        },
      }}
      InputProps={{
        classes: {
          root: inputClass,
          input: "input",
        },
        startAdornment: startAdornment,
        endAdornment: handlePasswordVisibility ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handlePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : (
          endAdornment
        ),
      }}
    />
  );
};

InputField.defaultProps = {
  className: "text-field",
  type: "text",
  error: false,
  helperText: "",
  placeholder: "",
  inputClass: "",
  disabled: false,
};

InputField.propTypes = {
  name: PropType.string,
  className: PropType.string,
  label: PropType.string,
  type: PropType.string,
  showPassword: PropType.bool,
  error: PropType.string,
  value: PropType.string,
  handlePasswordVisibility: PropType.func,
  handleChange: PropType.func,
  placeholder: PropType.string,
  inputClass: PropType.string,
  startAdornment: PropType.node,
  endAdornment: PropType.node,
  disabled: PropType.bool,
};

export default InputField;
