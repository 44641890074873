import { ReactComponent as SettingsIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as BillingIcon } from "../../assets/icons/billing-icon.svg";
import { ReactComponent as ToolsIcon } from "../../assets/icons/tools-blue-icon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification-blue.svg";
import { Facebook, Help } from "@material-ui/icons";
import { FACEBOOK_GROUP_LINK, HELPDESK_LINK } from "../../utils/constants";
import { ReactComponent as IntegrationsIcon } from "../../assets/icons/integration-icon.svg";
import { ReactComponent as LogsIcon } from "../../assets/icons/logs.svg";

export const menus = [
  {
    title: "My Profile",
    route: "/settings",
    icon: SettingsIcon,
  },
  {
    title: "Billing",
    route: "/billing",
    icon: BillingIcon,
  },
  {
    title: "Notfications",
    route: "/notifications",
    icon: NotificationIcon,
  },
  {
    title: "Helpdek",
    route: "helpdesk",
    icon: Help,
    link: HELPDESK_LINK,
  },
  {
    title: "Facebook Group",
    route: "facebook",
    icon: Facebook,
    link: FACEBOOK_GROUP_LINK,
  },
];

export const mobileMenus = [
  {
    title: "My Profile",
    route: "/settings",
    icon: SettingsIcon,
  },
  {
    title: "Billing",
    route: "/billing",
    icon: BillingIcon,
  },
  {
    title: "Integrations",
    route: "/integrations",
    icon: IntegrationsIcon,
  },
  {
    title: "Logs",
    route: "/logs",
    icon: LogsIcon,
  },
  {
    title: "Tools",
    route: "/tools",
    icon: ToolsIcon,
  },
  {
    title: "Notfications",
    route: "/notifications",
    icon: NotificationIcon,
  },
  {
    title: "Helpdek",
    route: "helpdesk",
    icon: Help,
    link: HELPDESK_LINK,
  },
  {
    title: "Facebook Group",
    route: "facebook",
    icon: Facebook,
    link: FACEBOOK_GROUP_LINK,
  },
];

export const adminMenus = [
  {
    title: "My Profile",
    route: "/settings",
    icon: ToolsIcon,
  },
  {
    title: "Users",
    route: "/users",
    icon: SettingsIcon,
  },
  {
    title: "Pending Users",
    route: "/pending-users",
    icon: SettingsIcon,
  },
];
