import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getCurrentException } from "../../helpers";

const ToolsMakeExceptions = (props) => {
  const { mcToCcbCustomList, getMcToCcbCustomListStatus, onClick } = props;

  const currentExceptions = useMemo(() => {
    return getCurrentException(mcToCcbCustomList);
  }, [mcToCcbCustomList]);

  if (getMcToCcbCustomListStatus === "loading") {
    return <label className="mt-3 text-muted">Loading...</label>;
  }

  if (
    mcToCcbCustomList &&
    Array.isArray(mcToCcbCustomList) &&
    mcToCcbCustomList.length === 0
  ) {
    return (
      <label onClick={onClick} className="link-text cursor-pointer mt-3">
        <u>Make Exceptions</u>
      </label>
    );
  }

  return (
    <label className="mt-3">
      Except:{" "}
      <span onClick={onClick} className="link-text cursor-pointer">
        <u>{currentExceptions}</u>
      </span>
    </label>
  );
};

ToolsMakeExceptions.propTypes = {
  mcToCcbCustomList: PropTypes.array,
};

ToolsMakeExceptions.defaultProps = {
  mcToCcbCustomList: [],
};

export default ToolsMakeExceptions;
