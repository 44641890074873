import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableInfinityScroll from "../../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../../Table/TableHeader/TableHeader";
import EmptyMessageBox from "../../../EmptyMessageBox/EmptyMessageBox";
import HoldContactsTableBody from "./HoldContactsTableBody";
import { CircularProgress } from "@material-ui/core";
import { getHoldContacts } from "../../../../store/middlewares/integrationMiddleware";
import { formDataWithApiKey } from "../../../../helpers";

const cells = [
  {
    text: "CCB ID",
  },
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Email",
  },
  {
    text: "",
  },
];

const HoldContactsStepTwo = (props) => {
  const {
    // Components
    selectedConflict,
    selectedHoldContacts,
    setSelectedHoldContacts,
    onClickedIgnore,

    // Redux functions
    getHoldContacts,

    // Redux props
    holdContacts,
    holdContactsStatus,
    ignoreHoldContactId,
    ignoreHoldContactsStatus,
  } = props;

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append(
      "id",
      selectedConflict?.mcToCcbId || selectedConflict?.ccbToMcId
    );
    getHoldContacts(formData);
  }, [getHoldContacts, selectedConflict]);

  if (holdContactsStatus === "loading") {
    return (
      <div className="w-100 flexer overflow-hidden">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div>
      <div className="flexer-column">
        <div className="user-settings-greetings w-80 hold-contact-table-container">
          <TableInfinityScroll
            className="hold-contacts-table"
            page={1}
            fetchFunction={() => {}}
          >
            <table className="table">
              <TableHeader
                className="hold-contacts-header-container"
                cells={cells}
              />
              <HoldContactsTableBody
                onClickRow={setSelectedHoldContacts}
                selectedRow={selectedHoldContacts}
                data={holdContacts}
                onClickedIgnore={onClickedIgnore}
                ignoreHoldContactId={ignoreHoldContactId}
                ignoreHoldContactsStatus={ignoreHoldContactsStatus}
              />
            </table>
            {!Boolean(holdContacts.length) && (
              <EmptyMessageBox message="No Hold contacts" hasBorder={false} />
            )}
            {holdContactsStatus === "error" && (
              <div className="text-danger text-center">
                Something went wrong, please try again later
              </div>
            )}
          </TableInfinityScroll>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    holdContactsStatus: store.integrations.holdContactsStatus,
    holdContacts: store.integrations.holdContacts,
    ignoreHoldContactId: store.integrations.ignoreHoldContactId,
    ignoreHoldContactsStatus: store.integrations.ignoreHoldContactsStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHoldContacts: (formData) => dispatch(getHoldContacts(formData)),
  };
};

HoldContactsStepTwo.propType = {
  selectedConflict: PropTypes.object,
  selectedHoldContacts: PropTypes.object,
  setSelectedHoldContacts: PropTypes.func.isRequired,
};

HoldContactsStepTwo.defaultProps = {
  selectedConflict: {},
  selectedHoldContacts: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoldContactsStepTwo);
