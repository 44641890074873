import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formDataWithApiKey, transferToLegacy } from "../../helpers";
import { updateUserProfile } from "../../store/middlewares/userMiddleware";

const REDIRECT_TO_OLD_VERSION = "appVersion";

const OldVersionLink = (props) => {
  const {
    children,
    // Redux props
    updateProfileStatus,
    userData,
    // Redux func
    updateUserProfile,
  } = props;

  useEffect(() => {
    if (updateProfileStatus[REDIRECT_TO_OLD_VERSION] === "success") {
      transferToLegacy(userData);
    }
  }, [updateProfileStatus, userData]);

  const onClick = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append(REDIRECT_TO_OLD_VERSION, 1);
    updateUserProfile(formData, REDIRECT_TO_OLD_VERSION);
  }, [updateUserProfile]);

  return <div onClick={onClick}>{children} </div>;
};

OldVersionLink.propTypes = {};

function mapStateToProps(store) {
  return {
    userData: store.userReducer.userData,
    updateProfileStatus: store.userReducer.updateProfileStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserProfile: (data, requestId) =>
      dispatch(updateUserProfile(data, requestId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OldVersionLink);
