import Path from "./path";

const root = document.getElementById("root");
const isDev = process.env.NODE_ENV === "development";
const getToken = () => localStorage.getItem("token");

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  root,
  Path,
  isDev,
  getToken,
};
