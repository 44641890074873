import React from "react";
import PropTypes from "prop-types";
import LogDetailsTableBodyRow from "./LogDetailsTableBodyRow";

const LogDetailsTableBody = (props) => {
  const {
    data,
    isActive,
    isLoading,
    tableType,
    handleItemClicked,
    onClickedViewMcRequiredMergeField,
    navigateToLogDetailsScreen,
    navigateToLogStatisticsScreen,
    tabIdentity,
    navigateToCleanupSync,
  } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <LogDetailsTableBodyRow
          data={elementData}
          isActive={isActive}
          index={index}
          tableType={tableType}
          handleItemClicked={handleItemClicked}
          navigateToLogDetailsScreen={navigateToLogDetailsScreen}
          navigateToLogStatisticsScreen={navigateToLogStatisticsScreen}
          tabIdentity={tabIdentity}
          navigateToCleanupSync={navigateToCleanupSync}
          onClickedViewMcRequiredMergeField={onClickedViewMcRequiredMergeField}
        />
      ))}
    </tbody>
  );
};

LogDetailsTableBody.propTypes = {
  onDetailsClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  navigateToLogDetailsScreen: PropTypes.func,
  navigateToLogStatisticsScreen: PropTypes.func,
  onClickedViewMcRequiredMergeField: PropTypes.func,
  navigateToCleanupSync: PropTypes.func,
  tabIdentity: PropTypes.string,
};

LogDetailsTableBody.defaultProps = {
  data: [],
  navigateToCleanupSync: () => {},
  onClickedViewMcRequiredMergeField: () => {},
  tabIdentity: "",
};

export default LogDetailsTableBody;
