import config from "../config";
import axios from "axios";

const userLogin = async (opts = {}) => {
  const { data } = await axios.post(config.Path.LOGIN, opts);
  return data;
};

const userSignup = async (opts = {}) => {
  const { data } = await axios.post(config.Path.REGISTER, opts);
  return data;
};

const markUserPending = async (opts = {}) => {
  const { data } = await axios.post(config.Path.MARK_PENDING, opts);
  return data;
};

const resetPassword = async (opts = {}) => {
  const { data } = await axios.post(config.Path.RESET_PASSWORD, opts);
  return data;
};

const changePassword = async (opts = {}) => {
  const { data } = await axios.post(config.Path.CHANGE_PASSWORD, opts);
  return data;
};

const updateProfile = async (opts = {}) => {
  const { data } = await axios.post(config.Path.UPDATE_PROFILE, opts);
  return data;
};

const resendActivationEmail = async (opts = {}) => {
  const { data } = await axios.post(config.Path.RESEND_ACTIVATION_EMAIL, opts);
  return data;
};

const getCCbGroups = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_CCB_GROUPS, opts);
  return data;
};

const getProcessQueueByCampusId = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_PROCESS_QUEUE, opts);
  return data;
};

const getCcbSavedSearchInfo = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.GET_CCB_SAVED_SEARCHES_INFO,
    opts
  );
  return data;
};

const getMailchimpAudienceTag = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.GET_MAILCHIMP_AUDIENCE_TAG,
    opts
  );
  return data;
};

const getMailchimpInterestedGroups = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.GET_MAILCHIMP_INTEREST_GROUPS,
    opts
  );
  return data;
};

const getUserDetails = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_USER_DETAILS, opts);
  return data;
};

const getIntercomAppId = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_INTERCOM_APP_ID, opts);
  return data;
};

const getInvoiceDetailsApi = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_INVOICE_DETAILS, opts);
  return data;
};

const getLogDetails = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_LOG_DETAILS, opts);
  return data;
};

const getAllHoldContacts = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_ALL_HOLD_CONTACTS, opts);
  return data;
};

const getAllMcToCcbPairs = async (opts = {}) => {
  const { data } = await axios.post(config.Path.GET_MC_TO_CCB_PAIRS, opts);
  return data;
};

const removeMultipleMcToCcbPair = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.REMOVE_MULTIPLE_MC_TO_CCB_PAIRS,
    opts
  );
  return data;
};

const hasSearchPermissions = async (opts = {}) => {
  const { data } = await axios.post(config.Path.HAS_SEARCH_PERMISSION, opts);
  return data;
};

const removeCcbToMcPairApi = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.REMOVE_CCB_TO_MC_SYNC_PAIR,
    opts
  );
  return data;
};

const removeMcToCcbPairApi = async (opts = {}) => {
  const { data } = await axios.post(
    config.Path.REMOVE_MC_TO_CCB_SYNC_PAIR,
    opts
  );
  return data;
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  userLogin,
  userSignup,
  resetPassword,
  updateProfile,
  resendActivationEmail,
  getCCbGroups,
  getProcessQueueByCampusId,
  getMailchimpAudienceTag,
  getMailchimpInterestedGroups,
  getCcbSavedSearchInfo,
  getUserDetails,
  getIntercomAppId,
  getInvoiceDetailsApi,
  getLogDetails,
  changePassword,
  getAllHoldContacts,
  getAllMcToCcbPairs,
  removeMultipleMcToCcbPair,
  hasSearchPermissions,
  removeCcbToMcPairApi,
  removeMcToCcbPairApi,
  markUserPending,
};
