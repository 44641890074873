import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ccbCampusAndSavedSearch } from "./addNewSyncPairModalData";
import useIntegrationStore from "../../../../store/reduxStore/integrationsStore";
import { Tooltip } from "@material-ui/core";

const CcbCampusAndSavedSearchTabBox = (props) => {
  const {
    isSavedSearchSelected,
    index,
    className,
    children,
    onClick,
    isSavedSearchDisabled,
    savedSearchTooltip,
  } = props;
  // redux store
  const { integrations } = useIntegrationStore();
  const { hasSavedSearchPermissionsStatus } = integrations;

  const handleTabClicked = useCallback(
    (currentValue) => {
      if (isSavedSearchDisabled) return null;
      onClick(index, currentValue);
    },
    [index, isSavedSearchDisabled, onClick]
  );

  return (
    <div className={`mc-tags-group-box-container ${className}`}>
      <div className="mc-tabs">
        {ccbCampusAndSavedSearch.map((item, idx) => (
          <Tooltip
            title={
              item.value === 1 && savedSearchTooltip ? savedSearchTooltip : ""
            }
          >
            <div
              key={idx}
              onClick={() => handleTabClicked(item.value)}
              className={`tab ${
                isSavedSearchSelected === item.value ? "active" : ""
              } ${isSavedSearchDisabled && item.value === 1 ? "disable" : ""}`}
            >
              {item.title}{" "}
              {hasSavedSearchPermissionsStatus === "loading" &&
                item.value === 1 && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
            </div>
          </Tooltip>
        ))}
      </div>
      {children}
    </div>
  );
};

CcbCampusAndSavedSearchTabBox.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  isSavedSearchDisabled: PropTypes.bool,
  savedSearchTooltip: PropTypes.string,
};

CcbCampusAndSavedSearchTabBox.defaultProps = {
  isSavedSearchDisabled: false,
  savedSearchTooltip: "",
};

export default CcbCampusAndSavedSearchTabBox;
