import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Typography,
  Tooltip,
  withStyles,
  ListSubheader,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import { AttachFile, ErrorRounded } from "@material-ui/icons";
import { getValueFromArrayOfKeys } from "../helpers";
import { VariableSizeList } from "react-window";
import {
  CCB_GROUPS,
  CCB_TO_MC,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
} from "../utils/integrationsConstants";

const LISTBOX_PADDING = 8; // px

const MaterialLightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #acacac",
    padding: "10px 8px",
    borderRadius: "0.25rem",
    fontSize: "0.9em !important",
    textAlign: "center",
  },
}))(Tooltip);

const renderRow = (props) => {
  const { data, index, style } = props;

  const renderSyncedIndicator = (option, identity, currentSync) => {
    if (
      identity === CCB_GROUPS &&
      option?.isSync === 1 &&
      currentSync === CCB_TO_MC
    ) {
      return <AttachFile className="color-black " />;
    }
  };

  const getTooltipTitle = (option, title, currentSync) => {
    if (
      option?.ccbGroupName?.toLowerCase().includes("all members of", 0) &&
      currentSync !== CCB_TO_MC
    )
      return "You would NOT be able to sync back to this CCB system group. In most cases, if you want to sync all members of a campus, it might be better to create and use a saved search instead.";
    else if (title?.length > 25) return title;
    else return "";
  };

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  if (dataSet.props?.children) {
    const {
      props,
      displayKey,
      identity,
      isCcbGroupExistInMcGroup,
      currentSync,
    } = dataSet.props?.children;
    return (
      <MaterialLightTooltip
        placement="right"
        className="w-100"
        title={getTooltipTitle(
          props,
          getValueFromArrayOfKeys(props, displayKey),
          currentSync
        )}
        arrow={true}
      >
        <Typography
          style={{
            color:
              identity === MAILCHIMP_GROUPS &&
              props?.groupBy === "Create a Duplicate Group" &&
              isCcbGroupExistInMcGroup
                ? "red"
                : "#7B7B7B",
            ...inlineStyle,
            pointerEvents: "auto",
            fontWeight: 300,
          }}
          noWrap
          {...dataSet.props}
        >
          {renderSyncedIndicator(props, identity, currentSync)}{" "}
          {getValueFromArrayOfKeys(props, displayKey)}
        </Typography>
      </MaterialLightTooltip>
    );
  }

  return null;
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const CustomAutocompleteSelect = (props) => {
  const [inputValue, setInputValue] = useState("");
  const {
    classes,
    placeholder,
    options,
    displayKey,
    loadingText,
    identity,
    handleChange,
    index,
    value,
    disabled,
    currentSync,
    isCcbGroupExistInMcGroup,
    isSavedSearchSelected,
    loading,
    error,
    hasExclamanationIcon,
    isProcessQueueSelected,
  } = props;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => getValueFromArrayOfKeys(option, displayKey),
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = useCallback((newInputValue, reason) => {
    if (reason === "input") {
      setInputValue(newInputValue);
    }
  }, []);

  const optionDisabledConditions = useCallback(
    (option) => {
      if (currentSync === CCB_TO_MC) {
        if (identity === CCB_GROUPS) {
          return option?.isSync === 1;
        }
      } else if (currentSync === MC_TO_CCB) {
        if (identity === CCB_GROUPS) {
          return option.ccbGroupName
            .toLowerCase()
            .includes("all members of", 0);
        }
      }
    },
    [currentSync, identity]
  );

  const hasGroupedOptions = useCallback(() => {
    if (identity === MAILCHIMP_AUDIENCE) {
      return false;
    } else if (identity === CCB_GROUPS) {
      if (isProcessQueueSelected || isSavedSearchSelected) {
        return true;
      }
      return false;
    }
    return true;
  }, [identity, isProcessQueueSelected, isSavedSearchSelected]);

  const handleOnClose = useCallback(() => {
    if (inputValue !== value) {
      setInputValue(value);
    }
  }, [inputValue, value]);

  return (
    <Autocomplete
      name={identity}
      onClose={handleOnClose}
      ListboxComponent={ListboxComponent}
      filterOptions={inputValue === value ? (option) => option : filterOptions}
      options={options}
      disabled={disabled}
      inputValue={inputValue}
      groupBy={hasGroupedOptions() ? (option) => option?.groupBy : null}
      getOptionLabel={(option) => getValueFromArrayOfKeys(option, displayKey)}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          error={error && !inputValue}
          {...params}
          InputProps={{
            ...params.InputProps,
            classes: {
              input: hasExclamanationIcon ? "error-placeholder" : "",
            },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: (
              <>
                {hasExclamanationIcon && error && !inputValue ? (
                  <ErrorRounded
                    htmlColor="#FF5F0A"
                    fontSize="inherit"
                    className="ml-2"
                  />
                ) : null}
              </>
            ),
          }}
        />
      )}
      onChange={(event, options) =>
        !optionDisabledConditions(options) &&
        handleChange(event, options, identity, index)
      }
      onInputChange={(event, newInputValue, reason) =>
        handleInputChange(newInputValue, reason)
      }
      forcePopupIcon={true}
      classes={classes}
      loadingText={loadingText}
      disabledItemsFocusable={true}
      getOptionDisabled={(option) => optionDisabledConditions(option)}
      disableClearable={true}
      loading={loading}
      renderGroup={(params) => params}
      role="listbox"
      renderOption={(props, option) => {
        return {
          props,
          isCcbGroupExistInMcGroup,
          identity,
          displayKey,
          option,
          currentSync,
        };
      }}
    />
  );
};

CustomAutocompleteSelect.defaultProps = {
  placeholder: "Select",
  options: [],
  displayKey: [],
  loadingText: "No Options",
  identity: "",
  disabled: false,
  isCcbGroupExistInMcGroup: false,
  isSavedSearchSelected: false,
  loading: false,
  error: false,
  hasExclamanationIcon: false,
  isProcessQueueSelected: false,
};

CustomAutocompleteSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  displayKey: PropTypes.array,
  loadingText: PropTypes.string,
  identity: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  isCcbGroupExistInMcGroup: PropTypes.bool,
  disabled: PropTypes.bool,
  isSavedSearchSelected: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  hasExclamanationIcon: PropTypes.bool,
  isProcessQueueSelected: PropTypes.bool,
};

export default CustomAutocompleteSelect;
