import React, { useCallback, useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FormControl,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import TooltipWhenOverflow from "./TooltipWhenOverflow";
import { ReactComponent as ExpandDownArrow } from "../assets/icons/expand/expand-black.svg";
import { ReactComponent as ExpandUpArrow } from "../assets/icons/expand/expand-black-up.svg";
import { findValueRecursively } from "../helpers";

const useStyles = makeStyles((theme) => ({
  selectParent: {
    "&&&:before": {
      border: "none",
    },
    "&&&:after": {
      border: "none",
    },
    "&:hover:not($disabled):before": {
      borderBottom: "none !important",
    },
  },
  select: {
    "&:focus": {
      background: "transparent",
    },
  },
  messagesSettingsHeight: {
    height: 226,
  },
  voicemailSettingsHeight: {
    height: 130,
  },
  inputBase: {
    fontSize: "16px",
  },
  disabledSelect: {
    color: "#C9CECD",
  },
  svgIcon: {
    color: "rgba(0, 0, 0, 0.9)",
  },
  svgIconDisabled: {
    color: "#C9CECD",
  },
  listSubHeader: {
    color: "#565656",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "30px",
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  menuItemForSubHeader: {
    paddingLeft: 32,
  },
  subMenuTitle: {
    color: "rgba(0, 0, 0, 0.9)",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    position: "relative",
  },
  subMenuFirstOption: {
    marginTop: 8,
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const CustomSelect = (props) => {
  const {
    value,
    onChange,
    options,
    disabled,
    fullWidth,
    underline,
    placeholder,
    className,
    subHeaders, // non clickable sub headers that organize the options
    withTooltip, // if the option is too long show tooltip for the full text of the option
    icon, // used to display an icon on every select option
    hasNestedOptions, // if true, there are nested options with clickable sub headers
    selectPopupHeight, // exact hight for displaying the custom scrollbar | it only works for nested options
    customPopoverComponent, // custom component after clicking on select component
    customPopoverScrollbarsProps, // props for custom component wrapper scrollbar
    customPopoverContainerStyle, // props for custom popover div wrapper container
  } = props;

  const selectRef = React.useRef();

  const [openCustomPopover, setOpenCustomPopover] = useState(false);
  const [popOverWidth, setPopOverWidth] = useState(0);

  const [maxWidthMenuItem, setMaxWidthMenuItem] = useState("auto");
  const [nestedOptions, setNestedOptions] = useState([]);
  const classes = useStyles();
  const inputRef = useRef();

  const getMaxWidthMenuItem = useCallback(() => {
    if (typeof maxWidthMenuItem === "number" && subHeaders?.length > 0) {
      return maxWidthMenuItem - 48; // 48 means the padding that menu item has 32px left and 16px right
    }

    if (typeof maxWidthMenuItem === "number") {
      return maxWidthMenuItem - 32; // 32 means the padding that menu item has 16px left and 16px right
    }

    return maxWidthMenuItem;
  }, [maxWidthMenuItem, subHeaders]);

  const renderOptionWithIcon = useCallback((option, icon) => {
    if (!!icon) {
      return (
        <span className="d-flex align-items-center">
          {icon} {option}
        </span>
      );
    } else {
      return option;
    }
  }, []);

  const renderOptions = useCallback(
    (optionsToRender) => {
      return optionsToRender?.map((option) => (
        <MenuItem
          disabled={option.disabled}
          value={option.value}
          key={option.value}
          className={`${classes.menuItem} ${
            subHeaders?.length > 0 ? classes.menuItemForSubHeader : ""
          } ${
            hasNestedOptions && option.value === "1" && option.level === 0
              ? classes.subMenuFirstOption
              : ""
          }`}
          style={{
            paddingLeft:
              !!option.level && option.level !== 0 && option.level * 32, //compute custom padding for nested option
          }}
        >
          {withTooltip ? (
            <TooltipWhenOverflow
              maxWidth={getMaxWidthMenuItem()}
              text={option.name}
            >
              {renderOptionWithIcon(option.name, icon)}
            </TooltipWhenOverflow>
          ) : (
            renderOptionWithIcon(option.name, icon)
          )}
        </MenuItem>
      ));
    },
    [
      classes.menuItem,
      classes.menuItemForSubHeader,
      classes.subMenuFirstOption,
      hasNestedOptions,
      getMaxWidthMenuItem,
      subHeaders,
      withTooltip,
      icon,
      renderOptionWithIcon,
    ]
  );

  const renderNestedOptions = useCallback(
    (optionsToRender) => {
      let result = [];
      optionsToRender.map((option, index) => {
        if (option.startIndex !== undefined) {
          result = result.concat(
            <ListSubheader
              className={classes.subMenuTitle}
              key={option.name}
              style={{
                paddingLeft:
                  !!option.level && option.level !== 0 ? option.level * 32 : 16,
                marginTop: option.level === 0 && index === 0 ? 8 : 0,
              }}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  option.isSubMenuOpen = !option.isSubMenuOpen;
                  setNestedOptions([].concat(renderNestedOptions(options)));
                }}
              >
                {option.name}{" "}
                {option.isSubMenuOpen ? <ExpandUpArrow /> : <ExpandDownArrow />}
              </div>
            </ListSubheader>
          );
          if (!!option.isSubMenuOpen) {
            result = result.concat(renderNestedOptions(option.children));
          }
        } else {
          result = result.concat(renderOptions([option]));
        }
        return null;
      });
      return result;
    },
    [classes.subMenuTitle, renderOptions, options]
  );

  const closeAllSubMenus = useCallback(() => {
    options.map((option) => {
      if (option.isSubMenuOpen) {
        option.isSubMenuOpen = false;
      }
      return null;
    });
  }, [options]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange({ target: { value } });
      hasNestedOptions && closeAllSubMenus();
    },
    [hasNestedOptions, onChange, closeAllSubMenus]
  );

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && withTooltip)
        setMaxWidthMenuItem(inputRef.current.offsetWidth);
    }, 500);
  }, [withTooltip]);

  useEffect(() => {
    if (hasNestedOptions && nestedOptions.length === 0) {
      setNestedOptions([].concat(renderNestedOptions(options)));
    }
  }, [
    nestedOptions,
    setNestedOptions,
    renderNestedOptions,
    options,
    hasNestedOptions,
  ]);

  const renderOption = useCallback(
    (value) => {
      if (hasNestedOptions) {
        const option = findValueRecursively(options, value);
        const valueToRender = option.nameToRender || option.name;
        return renderOptionWithIcon(valueToRender, icon);
      } else {
        const option =
          value !== undefined &&
          options?.length > 0 &&
          options.find((option) =>
            typeof option.value === "string"
              ? option.value === value?.toString()
              : Number(option.value) === Number(value)
          );
        return renderOptionWithIcon(option.name, icon);
      }
    },
    [hasNestedOptions, icon, options, renderOptionWithIcon]
  );

  const renderValue = useCallback(
    (value) => {
      if (value !== "") {
        return renderOption(value);
      } else {
        return <Placeholder>{placeholder}</Placeholder>;
      }
    },
    [placeholder, renderOption]
  );

  useEffect(() => {
    if (customPopoverComponent && selectRef.current?.offsetWidth > 0) {
      setPopOverWidth(selectRef.current.offsetWidth);
    }
  }, [customPopoverComponent]);

  return (
    <>
      <FormControl
        className={`${classes.formControl} ${className}`}
        fullWidth={fullWidth || withTooltip}
        ref={inputRef}
      >
        <Select
          displayEmpty
          placeholder={placeholder}
          value={hasNestedOptions ? value.value : value}
          onChange={handleChange}
          classes={{
            select: `${classes.select} ${
              disabled ? classes.disabledSelect : ""
            }`,
            icon: disabled ? classes.svgIconDisabled : classes.svgIcon,
          }}
          disabled={disabled}
          className={!underline ? classes.selectParent : undefined}
          inputProps={{
            className: classes.inputBase,
          }}
          renderValue={(value) => renderValue(value)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            getContentAnchorEl: null,
            MenuListProps: hasNestedOptions && { component: Scrollbars },
            classes: hasNestedOptions
              ? { paper: classes[selectPopupHeight] }
              : null,
          }}
          open={customPopoverComponent ? false : undefined}
          onOpen={
            customPopoverComponent
              ? () => setOpenCustomPopover(true)
              : undefined
          }
        >
          {/* If there are non clickable sub headers and not nested options */}
          {subHeaders?.length > 0 &&
            !hasNestedOptions &&
            subHeaders.map((subHeader, index) => [
              <ListSubheader
                className={classes.listSubHeader}
                key={subHeader.text}
              >
                {subHeader.text}
              </ListSubheader>,
              ...renderOptions(
                options.slice(subHeader.startIndex, subHeader.endIndex + 1)
              ),
            ])}
          {/* If there are no sub headers and has nested options */}
          {subHeaders?.length === 0 && hasNestedOptions && nestedOptions}
          {/* If there are no sub headers and no nested options */}
          {subHeaders.length === 0 &&
            !hasNestedOptions &&
            renderOptions(options)}
        </Select>
      </FormControl>
      <Popover
        id="custom-select-popover"
        open={openCustomPopover}
        anchorEl={selectRef.current}
        onClose={() => setOpenCustomPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Scrollbars
          autoHide
          hideTracksWhenNotNeeded
          autoHeight
          autoHeightMax={300}
          {...customPopoverScrollbarsProps}
        >
          <div
            style={{
              width: popOverWidth,
              padding: "1rem",
              ...customPopoverContainerStyle,
            }}
          >
            {customPopoverComponent}
          </div>
        </Scrollbars>
      </Popover>
    </>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOf(optionPropType, PropTypes.arrayOf(optionPropType))
  ).isRequired,
  fullWidth: PropTypes.bool,
  underline: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  subHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      startIndex: PropTypes.number.isRequired,
      endIndex: PropTypes.number.isRequired,
    })
  ),
  withTooltip: PropTypes.bool,
  hasNestedOptions: PropTypes.bool,
  customPopoverComponent: PropTypes.node,
  customPopoverScrollbarsProps: PropTypes.any,
};

CustomSelect.defaultProps = {
  disabled: false,
  fullWidth: false,
  underline: false,
  placeholder: "",
  className: "",
  subHeaders: [],
  withTooltip: false,
  hasNestedOptions: false,
  icon: null,
  selectPopupHeight: "",
};

export default CustomSelect;
