const { ACTION_TYPES } = require("./types");

export const setLogsDetails = (payload) => ({
  type: ACTION_TYPES.SET_LOGS_DETAILS,
  payload,
});

export const setLogSearchTerm = (payload) => ({
  type: ACTION_TYPES.SET_LOG_SEARCH_TERM,
  payload,
});

export const clearLogReverting = () => ({
  type: ACTION_TYPES.REVERT_LOGS_CLEAR,
});
