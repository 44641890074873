import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { withRouter } from "react-router";

const BillingBreadcrumb = (props) => {
  const { history, breadcrumbs } = props;

  const handleNavigate = useCallback(
    (route, index) => {
      if (index + 1 === breadcrumbs.length) return null;
      history.push(route);
    },
    [breadcrumbs.length, history]
  );

  return (
    <div role="presentation" className="mt-3 mb-2">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          onClick={() => history.push("/billing")}
          color="inherit"
          className="cursor-pointer breadcrumb-link"
        >
          Billing
        </Typography>
        {Boolean(breadcrumbs.length) &&
          breadcrumbs.map((breadcrumb, index) => (
            <Typography
              onClick={() => handleNavigate(breadcrumb.route, index)}
              color="inherit"
              className="cursor-pointer breadcrumb-link"
            >
              {breadcrumb.text}
            </Typography>
          ))}
      </Breadcrumbs>
    </div>
  );
};

BillingBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default withRouter(BillingBreadcrumb);
