import React from "react";
import PropTypes from "prop-types";
import { MERGE_FIELD_ERROR_SUPPORT_URL } from "../utils/constants";

const MergeFieldErrorArticleLink = (props) => {
  const { children, className } = props;
  return (
    <a
      className={className}
      href={MERGE_FIELD_ERROR_SUPPORT_URL}
      target="__blank"
    >
      {children}
    </a>
  );
};

MergeFieldErrorArticleLink.propTypes = {
  className: PropTypes.string,
};

MergeFieldErrorArticleLink.defaultProps = {
  className: "",
};

export default MergeFieldErrorArticleLink;
