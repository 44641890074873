import { convertFormdataToJson, sortArrayByPlans } from "../../helpers";
import { ACTION_TYPES } from "../actions/types";

const initialState = {
  isLoading: false,

  errorMessage: "",
  successMessage: "",

  // Primary contacts tools
  primaryContacts: [],
  primaryContactsStatus: "",
  primaryContactsPage: null,
  primaryContactsLoadedAll: null,
  primaryContactsLoadingMoreStatus: null,

  makePrimaryContactStatus: "",

  // Ignored emails tools
  ignoredEmails: [],
  ignoredEmailsStatus: "",
  ignoredEmailsPage: null,
  ignoredEmailsLoadedAll: null,
  ignoredEmailsLoadingMoreStatus: null,

  unignoreEmailStatus: "",

  // Cleanup unsync pairs tools
  cleanupUnsyncPairsStatus: "",
  cleanupUnsyncPairs: [],

  deleteMcSyncPairStatus: "",
  dismissDeleteMcStatus: "",

  // Mapping header
  headerMappingStatus: "",
  mcFields: [],
  userHeaderMapping: [],
  defaultCountry: "",
  defaultAddressType: "",
  addressOptions: [],

  saveHeaderMappingStatus: "",

  // Autosync status
  saveAutoSyncStatus: "",

  // Tools Custom Field
  getMcToCcbCustomListStatus: "",
  mcToCcbCustomList: [],

  // Add MC to CCB custom list
  addMcToCcbCustomListStatus: "",

  // Delete MC to CCB Custom List
  deleteMcToCcbCustomListStatus: "",
  deleteMcToCcbListId: null,
};

const toolsReducer = (state = initialState, action) => {
  let primaryContacts = null;
  let ignoredEmails = null;
  switch (action.type) {
    case ACTION_TYPES.CLEAR_TOOL_PAGE_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_TOOLKIT_CLEANUP:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_TOOLKIT_CLEANUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_TOOLKIT_CLEANUP_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_STORE_USER_INFO:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_STORE_USER_INFO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_STORE_USER_INFO_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_PRIMARY_PROFILES:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_PRIMARY_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_PRIMARY_PROFILES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_MC_TO_CCB_REMOVE_INACTIVE:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_MC_TO_CCB_REMOVE_INACTIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_MC_TO_CCB_REMOVE_INACTIVE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_AUTO_SYNC:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
        saveAutoSyncStatus: "loading",
      });

    case ACTION_TYPES.SAVE_AUTO_SYNC_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
        saveAutoSyncStatus: "success",
      });

    case ACTION_TYPES.SAVE_AUTO_SYNC_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
        saveAutoSyncStatus: "error",
      });

    case ACTION_TYPES.SAVE_SKIP_MERGE_VALIDATION:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_SKIP_MERGE_VALIDATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_SKIP_MERGE_VALIDATION_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_UBSUB:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_UBSUB_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_UBSUB_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_EMAIL_NOTIFICATION_FOR_PENDING_DUPES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_CHECK_RECEIVED_EMAIL:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
        saveAutoSyncStatus: "",
      });

    case ACTION_TYPES.SAVE_CHECK_RECEIVED_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_CHECK_RECEIVED_EMAIL_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_DELETE_MC_LIST_GROUP:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_DELETE_MC_LIST_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage: action.payload.status === 1 ? "Saved successfully" : "",
      });

    case ACTION_TYPES.SAVE_DELETE_MC_LIST_GROUP_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.SAVE_MEMBERS_SETTINGS:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.SAVE_MEMBERS_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage:
          action.payload.status === 1 ? action.payload.message : "",
      });

    case ACTION_TYPES.SAVE_MEMBERS_SETTINGS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.ACCOUNT_CLEANUP:
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: "",
        successMessage: "",
      });

    case ACTION_TYPES.ACCOUNT_CLEANUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage:
          action.payload.status === 0
            ? action.payload.message ||
              "Something went wrong, please try again later"
            : "",
        successMessage:
          action.payload.status === 1 ? "Account cleanup in progress" : "",
      });

    case ACTION_TYPES.ACCOUNT_CLEANUP_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: "Something went wrong, please try again later",
      });

    case ACTION_TYPES.GET_PRIMARY_CONTACTS: {
      const formData = convertFormdataToJson(action.data);
      return Object.assign({}, state, {
        primaryContactsStatus: "loading",
        primaryContactsLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      });
    }

    case ACTION_TYPES.GET_PRIMARY_CONTACTS_SUCCESS:
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.primaryContacts) {
        primaryContacts = action.payload.data;
      } else {
        primaryContacts = state.primaryContacts.concat(action.payload.data);
      }
      return Object.assign({}, state, {
        primaryContactsStatus: "success",
        primaryContacts: primaryContacts,
        primaryContactsPage: formData.page,
        primaryContactsLoadingMoreStatus: formData.page > 1 ? "success" : null,
        primaryContactsLoadedAll: action.payload.data.length === 0,
      });

    case ACTION_TYPES.GET_PRIMARY_CONTACTS_ERROR:
      return Object.assign({}, state, {
        primaryContactsStatus: "error",
        primaryContactsPage: null,
        primaryContactsLoadedAll: null,
        primaryContactsLoadingMoreStatus: null,
      });

    case ACTION_TYPES.MAKE_PRIMARY_CONTACTS:
      return Object.assign({}, state, {
        makePrimaryContactStatus: "loading",
      });

    case ACTION_TYPES.MAKE_PRIMARY_CONTACTS_SUCCESS:
      return Object.assign({}, state, {
        makePrimaryContactStatus: "success",
      });

    case ACTION_TYPES.MAKE_PRIMARY_CONTACTS_ERROR:
      return Object.assign({}, state, {
        makePrimaryContactStatus: "error",
      });

    case ACTION_TYPES.MAKE_PRIMARY_CONTACTS_CLEAR:
      return Object.assign({}, state, {
        makePrimaryContactStatus: "",
      });

    case ACTION_TYPES.GET_IGNORED_EMAILS: {
      const formData = convertFormdataToJson(action.data);
      return Object.assign({}, state, {
        ignoredEmailsStatus: "loading",
        ignoredEmailsLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      });
    }

    case ACTION_TYPES.GET_IGNORED_EMAILS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.ignoredEmails) {
        ignoredEmails = action.payload.data;
      } else {
        ignoredEmails = state.ignoredEmails.concat(action.payload.data);
      }
      return Object.assign({}, state, {
        ignoredEmailsStatus: "success",
        ignoredEmails,
        ignoredEmailsPage: formData.page,
        ignoredEmailsLoadingMoreStatus: formData.page > 1 ? "success" : null,
        ignoredEmailsLoadedAll: action.payload.data.length === 0,
      });
    }

    case ACTION_TYPES.GET_IGNORED_EMAILS_ERROR:
      return Object.assign({}, state, {
        ignoredEmailsStatus: "error",
        ignoredEmailsPage: null,
        ignoredEmailsLoadedAll: null,
        ignoredEmailsLoadingMoreStatus: null,
      });

    case ACTION_TYPES.UNIGNORE_EMAIL:
      return Object.assign({}, state, {
        unignoreEmailStatus: "loading",
      });

    case ACTION_TYPES.UNIGNORE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        unignoreEmailStatus: "success",
      });

    case ACTION_TYPES.UNIGNORE_EMAIL_ERROR:
      return Object.assign({}, state, {
        unignoreEmailStatus: "error",
      });

    case ACTION_TYPES.UNIGNORE_EMAIL_CLEAR:
      return Object.assign({}, state, {
        unignoreEmailStatus: "",
      });

    case ACTION_TYPES.GET_CLEANUP_UNSYNC_PAIRS:
      return Object.assign({}, state, {
        cleanupUnsyncPairsStatus: "loading",
      });

    case ACTION_TYPES.GET_CLEANUP_UNSYNC_PAIRS_SUCCESS:
      const syncPairs = [
        ...action.payload?.ccbGroups,
        ...action.payload?.savedSearches,
      ];
      return Object.assign({}, state, {
        cleanupUnsyncPairsStatus: "success",
        cleanupUnsyncPairs: syncPairs,
      });

    case ACTION_TYPES.GET_CLEANUP_UNSYNC_PAIRS_ERROR:
      return Object.assign({}, state, {
        cleanupUnsyncPairsStatus: "error",
      });

    case ACTION_TYPES.DELETE_MC:
      return Object.assign({}, state, {
        deleteMcSyncPairStatus: "loading",
      });

    case ACTION_TYPES.DELETE_MC_SUCCESS:
      return Object.assign({}, state, {
        deleteMcSyncPairStatus: "success",
      });

    case ACTION_TYPES.DELETE_MC_ERROR:
      return Object.assign({}, state, {
        deleteMcSyncPairStatus: "error",
      });

    case ACTION_TYPES.DELETE_MC_CLEAR:
      return Object.assign({}, state, {
        deleteMcSyncPairStatus: "",
      });

    case ACTION_TYPES.DISMISS_DELETE_MC:
      return Object.assign({}, state, {
        dismissDeleteMcStatus: "loading",
      });

    case ACTION_TYPES.DISMISS_DELETE_MC_SUCCESS:
      return Object.assign({}, state, {
        dismissDeleteMcStatus: "success",
      });

    case ACTION_TYPES.DISMISS_DELETE_MC_ERROR:
      return Object.assign({}, state, {
        dismissDeleteMcStatus: "error",
      });

    case ACTION_TYPES.DISMISS_DELETE_MC_CLEAR:
      return Object.assign({}, state, {
        dismissDeleteMcStatus: "",
      });

    case ACTION_TYPES.GET_HEADER_MAPPING:
      return Object.assign({}, state, {
        headerMappingStatus: "loading",
      });

    case ACTION_TYPES.GET_HEADER_MAPPING_SUCCESS: {
      const {
        userHeaderMapping,
        mcFields,
        default_country,
        address_options,
        default_address_type,
      } = action.payload;
      const newEmailObject = {
        code: "CCB_EMAIL",
        isEditable: false,
        isEnabled: true,
        mcTagName: "CCB_EMAIL",
        name: "CCB Email",
        isTwoWay: true,
        plan: "starter",
      };

      const updatedHeaderMapping = userHeaderMapping?.map((item) => {
        if (item.code === "CCB_ID") {
          return {
            ...item, // Spread operator to copy properties of the current item
            mcTagName: "CCB_ID",
            isEditable: false,
            isEnabled: true,
            isTwoWay: false,
            plan: "starter",
          };
        }
        if (item.code === "FIRST_NAME") {
          return {
            ...item,
            isTwoWay: true,
            plan: "starter",
          };
        }

        if (item.code === "LAST_NAME") {
          return {
            ...item,
            isTwoWay: true,
            plan: "starter",
          };
        }

        if (item.code === "MOBILE_PHONE") {
          return {
            ...item,
            isTwoWay: true,
            plan: "pro",
          };
        }

        if (item.code === "ADDRESS") {
          return {
            ...item,
            isTwoWay: true,
            plan: "pro",
          };
        }

        return {
          ...item,
          isTwoWay: false,
          plan: "medium",
        }; // Return the item unmodified if it's not the one we're looking for
      });
      updatedHeaderMapping.unshift(newEmailObject);

      const sortedHeaderMapping = updatedHeaderMapping.sort((a, b) => {
        if (a.code === "CCB_ID") {
          return -1; // a comes first
        } else if (b.code === "CCB_ID") {
          return 1; // b comes first
        }
        return 0; // no change in order for other elements
      });

      const sortedHeaderMappingByPlans = sortArrayByPlans(sortedHeaderMapping);

      const ccbIdMcField = { name: "CCB ID", code: "CCB_ID" };
      const mcPhoneFields = {
        name: "MC Phone",
        code: "PHONE",
      };
      const mcEmailField = { name: "MC Email", code: "CCB_EMAIL" };
      const updatedMcFields =
        mcFields && Array.isArray(mcFields)
          ? [ccbIdMcField, mcPhoneFields, mcEmailField, ...mcFields]
          : [];

      return Object.assign({}, state, {
        headerMappingStatus: "success",
        mcFields: [
          { name: "Please Select", code: "Please Select" },
          ...updatedMcFields,
        ],
        defaultCountry: default_country || state.defaultCountry,
        defaultAddressType: default_address_type || state.defaultAddressType,
        addressOptions: address_options || state.addressOptions,
        userHeaderMapping: sortedHeaderMappingByPlans,
      });
    }

    case ACTION_TYPES.GET_HEADER_MAPPING_ERROR:
      return Object.assign({}, state, {
        headerMappingStatus: "error",
      });

    case ACTION_TYPES.SAVE_HEADER_MAPPING:
      return Object.assign({}, state, {
        saveHeaderMappingStatus: "loading",
      });

    case ACTION_TYPES.SAVE_HEADER_MAPPING_SUCCESS:
      return Object.assign({}, state, {
        saveHeaderMappingStatus: "success",
      });

    case ACTION_TYPES.SAVE_HEADER_MAPPING_ERROR:
      return Object.assign({}, state, {
        saveHeaderMappingStatus: "error",
      });

    case ACTION_TYPES.SAVE_HEADER_MAPPING_CLEAR:
      return Object.assign({}, state, {
        saveHeaderMappingStatus: "",
      });

    case ACTION_TYPES.GET_MC_TO_CCB_CUSTOM_LIST:
      return Object.assign({}, state, {
        getMcToCcbCustomListStatus: "loading",
      });

    case ACTION_TYPES.GET_MC_TO_CCB_CUSTOM_LIST_SUCCESS:
      return Object.assign({}, state, {
        getMcToCcbCustomListStatus: "success",
        mcToCcbCustomList: action.payload?.data || [],
      });

    case ACTION_TYPES.GET_MC_TO_CCB_CUSTOM_LIST_ERROR:
      return Object.assign({}, state, {
        getMcToCcbCustomListStatus: "error",
      });

    case ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST:
      return Object.assign({}, state, {
        deleteMcToCcbCustomListStatus: "loading",
        deleteMcToCcbListId: action?.requestId || null,
      });

    case ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST_SUCCESS: {
      const updatedMcToCcbCustomList = state.mcToCcbCustomList.filter(
        (item) => item.id !== action?.requestId
      );
      return Object.assign({}, state, {
        deleteMcToCcbCustomListStatus: "success",
        deleteMcToCcbListId: action?.requestId || null,
        mcToCcbCustomList: updatedMcToCcbCustomList,
      });
    }

    case ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST_ERROR:
      return Object.assign({}, state, {
        deleteMcToCcbCustomListStatus: "error",
        deleteMcToCcbListId: null,
      });

    case ACTION_TYPES.DELETE_MC_TO_CCB_CUSTOM_LIST_CLEAR:
      return Object.assign({}, state, {
        deleteMcToCcbCustomListStatus: "",
        deleteMcToCcbListId: null,
      });

    case ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST:
      return Object.assign({}, state, {
        addMcToCcbCustomListStatus: "loading",
      });

    case ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST_SUCCESS: {
      return Object.assign({}, state, {
        addMcToCcbCustomListStatus: "success",
      });
    }

    case ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST_ERROR:
      return Object.assign({}, state, {
        addMcToCcbCustomListStatus: "error",
      });

    case ACTION_TYPES.ADD_MC_TO_CCB_CUSTOM_LIST_CLEAR:
      return Object.assign({}, state, {
        addMcToCcbCustomListStatus: "",
      });

    default:
      return state;
  }
};

export default toolsReducer;
