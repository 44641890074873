import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Info } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { formatDateAndTime } from "../../helpers";
import { Refresh, Sync } from "@material-ui/icons";
import SpanWithTooltip from "../SpanWithTooltip/SpanWithTooltip";

const LogsTableRow = (props) => {
  const {
    data,
    onDetailsClick,
    rollbackLogsIds,
    handleRevertLogs,
    index,
    onClickFiltering,
  } = props;

  const getTableRowClassName = useMemo(() => {
    let className = "";
    if (
      ["Automatic Cleanup Sync", "Manual Rollback Sync (Cleanup)"].includes(
        data.type
      )
    ) {
      className += `hide-table-border`;
    }
    if (data?.hasMcSyncError) {
      className += ` error-log-row`;
    }
    return className;
  }, [data]);

  const renderRefreshBtn = useCallback(() => {
    if (data.isRevertedAlready === 1) {
      return (
        <Tooltip
          title={formatDateAndTime(data.date)}
          arrow={true}
          placement="top"
        >
          <Sync className="cursor-pointer ml-2 " fontSize="small" />
        </Tooltip>
      );
    } else if (
      ["Manual Rollback Sync", "Automatic Sync", "Manual Sync"].includes(
        data.type
      )
    ) {
      if (rollbackLogsIds.includes(data.id)) {
        return (
          <Tooltip title="Rollback" arrow={true} placement="top">
            <Refresh
              onClick={() => handleRevertLogs(data)}
              className="cursor-pointer ml-2 "
              fontSize="small"
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Rollback disabled" arrow={true} placement="top">
            <Refresh className="ml-2 icon-muted" fontSize="small" />
          </Tooltip>
        );
      }
    }
  }, [data, handleRevertLogs, rollbackLogsIds]);

  return (
    <tr className={getTableRowClassName}>
      <td></td>
      <td className="w-25">
        {data?.hasMcSyncError && (
          <SpanWithTooltip
            title="One or more of your CCB groups syncing were skipped. Click this list for more details."
            arrow={true}
            className={"mr-1"}
          >
            <Info className="mb-1" fontSize="small" />
          </SpanWithTooltip>
        )}
        {formatDateAndTime(data.date)} {renderRefreshBtn()}
      </td>
      <td>{data.type}</td>
      <td style={{ width: "45%" }}>{data.summary}</td>
      <td className="details" onClick={() => onDetailsClick(data, index)}>
        Details
      </td>
      {Boolean(data?.hasFilteringData) ? (
        <td className="details" onClick={onClickFiltering(data)}>
          Filtering
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

LogsTableRow.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

LogsTableRow.defaultProps = {
  isActive: false,
};

export default LogsTableRow;
