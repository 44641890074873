import lodash from "lodash";
import md5 from "md5";
import services from "./services";
import { breakpoints } from "./utils/breakpoints";
import {
  CCB_CAMPUSES,
  CCB_GROUPS,
  CCB_TO_MC,
  MAILCHIMP_AUDIENCE,
  MAILCHIMP_GROUPS,
  MC_TO_CCB,
} from "./utils/integrationsConstants";
const {
  storageManager: { local },
} = services;

export const validateEmptyFields = (fields) => {
  if (lodash.isEmpty(fields)) return {};
  const emptyFields = lodash
    .map(fields, (value, key) => {
      if (!value) {
        return key;
      }
    })
    .filter((item) => Boolean(item));
  return emptyFields;
};

export const getUserName = (userData) => {
  return userData.firstName || userData.lastName || userData.email;
};

export const getFullName = (userData = {}) => {
  if (userData.firstName || userData.lastName) {
    return `${userData.firstName} ${userData.lastName}`;
  } else if (userData.email) {
    return userData.email;
  } else {
    return "Unknown";
  }
};

export const getSyncedIds = (data = [], key) => {
  return data.filter((item) => item.isSync === 1).map((el) => el[key]);
};

export const getNewSyncPairs = (data = [], key) => {
  return data.filter((item) => item.isNew === 1).map((el) => el[key]);
};

export const getSyncedData = (data = []) => {
  return data.filter((item) => item.isSync === 1);
};

export const getSyncedOrNewPairs = (data = []) => {
  return data.filter((item) => item.isSync === 1 || item.isNew === 1);
};

export const getIntegrationListName = (item, identity, nameByDisplayKey) => {
  switch (identity) {
    case CCB_CAMPUSES:
      return item.searchId ? "Saved Search" : nameByDisplayKey;
    case CCB_GROUPS:
      return nameByDisplayKey;
    case MAILCHIMP_AUDIENCE:
      return nameByDisplayKey;
    case MAILCHIMP_GROUPS:
      return item.sync_type === 0
        ? item.mcInterestGroupName
        : item?.mcTags?.join(" ,");
    default:
      return "";
  }
};

export const getSearchKeyWhileSearching = (currentColumn) => {
  switch (currentColumn) {
    case "first":
      return "campusName";
    case "second":
      return "ccbGroupName";
    case "third":
      return "mailchimpAudience";
    default:
      return "";
  }
};

export const findValueRecursively = (array, value) => {
  const result = array.reduce((option, item) => {
    if (option) return option;
    if (item.value === value) return item;
    if (item.children) return findValueRecursively(item.children, value);
    return null;
  }, null);
  return result;
};

export const convertFormdataToJson = (formData) => {
  if (!formData) return {};
  const object = {};
  formData.forEach((value, key) => (object[key] = value));
  return object;
};

export const formatPhoneNumber = (
  number,
  phonecode,
  allowNonDigit = false,
  useShorterVersion = false
) => {
  number = number ? String(number) : "";
  if (allowNonDigit && number.replace(/[^0-9]/g, "") !== number) {
    return number;
  }
  if (number.match(/[a-zA-Z]+/)) {
    return number;
  }
  number = number.replace(/[^0-9]/g, "");
  if (phonecode) {
    if (useShorterVersion) {
      number = `+${phonecode} ${number}`;
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length === 10 && phonecode) {
      number = `+${phonecode} ${number.replace(
        /([0-9]{3})([0-9]{3})([0-9]{4})/,
        "($1) $2-$3"
      )}`;
    } else {
      number = `+${phonecode} ${number}`;
    }
  } else {
    if (useShorterVersion) {
      number = number.length <= 4 ? number : `...${number.substr(-4)}`;
    } else if (number.length >= 7 && number.length <= 9) {
      number = number.replace(/([0-9]{3})([0-9]{4})/, "$1-$2");
    } else if (number.length === 10) {
      number = number.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "($1) $2-$3");
    } else if (number.length === 11) {
      number = number.replace(
        /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/,
        "+$1 ($2) $3-$4"
      );
    }
  }
  return number;
};

export const formDataWithApiKey = () => {
  const userdataInLocal = JSON.parse(local?.get("user"));
  const formData = new FormData();
  if (!lodash.isEmpty(userdataInLocal)) {
    if (userdataInLocal.apiKey) {
      formData.append("apiKey", userdataInLocal.apiKey);
    }
  }
  return formData;
};

export const simplifyAvatarUrl = (imageUrl) => {
  return imageUrl.replace(imageUrl.substring(0, imageUrl.indexOf(",") + 1), "");
};

export const wordOccurenceCount = (str, find) => {
  if (!str) return 0;
  return str?.split(find).length - 1;
};

const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const getCurrentTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "-" + mm + "-" + yyyy;

  return formattedToday;
};

export const formatDateAndTime = (
  dateAndTime,
  returnOnlyTime = false,
  withTimezone = false
) => {
  if (!dateAndTime) return "Unknown";
  const date = dateAndTime?.replace("(UTC +0)", "UTC");
  const normalizeDate = date.split(/\D/);
  const splitTime = date.split(" ");
  const time24hr = convertTime12to24(
    `${splitTime[1]} ${splitTime[2].toUpperCase()}`
  );
  const split24hrTime = time24hr.split(":");
  const newDateAndTime = new Date(
    Date.UTC(
      normalizeDate[2],
      normalizeDate[0] - 1,
      normalizeDate[1],
      split24hrTime[0],
      split24hrTime[1]
    )
  );

  let result = "";
  if (returnOnlyTime) {
    result = `${newDateAndTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}${withTimezone ? ` ${getCurrentTimezone()}` : ""}`;
  } else {
    result = `${newDateAndTime.toDateString()} at ${newDateAndTime.toLocaleString(
      "en-US",
      {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }
    )}${withTimezone ? ` ${getCurrentTimezone()}` : ""}`;
  }
  return result;
};

export const capitalizeFirstLetter = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getSearchStepTwoType = (type) => {
  if (type === "searchResultsCcbToMc") {
    return "ccbToMc";
  } else if (type === "searchResultsMcToCcb") {
    return "mcToCcb";
  }
};

export const emailIsValid = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const getIntegrationActiveTabType = (currentSync) => {
  if (currentSync === CCB_TO_MC) return "CCB";
  if (currentSync === MC_TO_CCB) return "MailChimp";
};

export const handleDisabledIntegration = (currentSync, ccbState, mcState) => {
  if (currentSync === CCB_TO_MC && !ccbState) return true;
  if (currentSync === MC_TO_CCB && !mcState) return true;
  return false;
};

export const getRecentLogIds = (logs = [], size = 3) => {
  const filterLogs = logs
    .filter((log) =>
      ["Manual Rollback Sync", "Automatic Sync", "Manual Sync"].includes(
        log.type
      )
    )
    .map((item) => item.id);
  const recentLogIds = filterLogs.slice(0, size);
  return recentLogIds;
};

export const addNewKeyPairInArray = (data = [], keyPair) => {
  return data.map((item) => ({ ...item, ...keyPair }));
};

export const getValueFromArrayOfKeys = (obj, keysArr) => {
  let value = "";
  Object.keys(obj).forEach((item) => {
    if (keysArr.includes(item)) {
      value = obj[item];
    }
  });
  return value;
};

export const deepClone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const getUniqueId = (maxNum) => {
  return Math.floor(Math.random() * maxNum);
};

export const getElementByGroupBy = (data = [], key) => {
  return data.filter((item) => item?.groupBy === key);
};

export const getMcInterestedGroupByUniqueId = (mcGroups = [], mcData = {}) => {
  if (lodash.isEmpty(mcGroups) || lodash.isEmpty(mcData)) return {};
  const uniqueId = `${mcData.mailchimpListInterestCategoryId}:::::${mcData.mailchimpListGroupId}`;
  const mcGroup = mcGroups.find((item) => item.uniqueId === uniqueId);
  return mcGroup ? mcGroup : {};
};

export const filterMcInterestedGroupByUniqueId = (allMcGroups, mcData) => {
  const uniqueId = `${mcData.mailchimpListInterestCategoryId}`;
  const mcGroups = allMcGroups.filter((item) =>
    item.uniqueId.includes(uniqueId)
  );
  return mcGroups;
};

export const getSearchingFilters = (identity) => {
  switch (identity) {
    case CCB_CAMPUSES:
      return (element) => `${element?.campusName} ${element?.listName}`;

    case CCB_GROUPS:
      return (element) =>
        `${element?.name} ${element?.ccbGroupName} ${element?.listGroupName} ${element?.queueName}`;

    case MAILCHIMP_AUDIENCE:
      return (element) => `${element?.mailchimpAudience}`;

    case MAILCHIMP_GROUPS:
      return (element) => `${element?.mcInterestGroupName}`;

    default:
      return () => {};
  }
};

export const getFitlerConditionByKey = (key) => {
  if (key === "campuses") return (element) => element?.campusId;
  if (key === "savedSearches") return (element) => element?.searchId;
  if (key === "tags") return (element) => element?.sync_type === 1;
  if (key === "groups") return (element) => element?.sync_type === 0;
};

export const checkForActiveFilter = (filters) => {
  return filters.filter((item) => !item.active);
};

export const filterDataById = (data = [], id) => {
  const filterData = data.filter((item) => item.id !== Number(id));
  return filterData;
};

export const getCampusGroupByid = (data = [], id) => {
  const filterData = data.find(
    (item) => Number(item.ccbGroupId) === Number(id)
  );
  return lodash.isEmpty(filterData) ? {} : filterData;
};

export const setArrayValuesInFormData = (data = [], formData, key) => {
  data.forEach((item, index) =>
    formData.append(`params[${index}][${key}]`, item)
  );
};

export const changeAllKeyValues = (data = [], key, value) => {
  data.forEach((item) => (item[key] = value));
};

export const benefitsKeys = [
  "manualSyncing",
  "automaticSyncing",
  "mappedCustomFields",
  "rollbackRestoreOptions",
];
export const benefitLabels = {
  manualSyncing: "Manual Syncing",
  automaticSyncing: "Automatic Syncing",
  mappedCustomFields: "Mapped Custom Fields (e.g birthdate)",
  rollbackRestoreOptions: "Rollback/restore options",
};

export const truncateStringWithDots = (string = "", limit) => {
  if (!limit || string.length < limit) return string;
  return string.substring(0, limit).concat("...");
};

export const checkForDuplicateValue = (arr = [], key, value) => {
  return arr.some((item) => item[key] === value);
};

export const filterArrByValue = (arr = [], key, value) => {
  return arr.filter((item) => item[key] !== value);
};

export const getMobileHeaderProps = (currentRoute) => {
  const headerProps = {
    title: "",
    backRoute: "/dashboard",
  };
  switch (currentRoute) {
    case "/integrations":
      return Object.assign(headerProps, { title: "Integrations" });
    case "/settings":
      return Object.assign(headerProps, { title: "Settings" });
    case "/billing":
      return Object.assign(headerProps, { title: "Billing" });
    case "/logs":
      return Object.assign(headerProps, { title: "Logs" });
    case "/tools":
      return Object.assign(headerProps, { title: "Tools" });
    case "/notifications":
      return Object.assign(headerProps, { title: "Notifications" });
    default:
      return headerProps;
  }
};

export const returnToAdmin = () => {
  const adminData = JSON.parse(local.get("admin"));
  local.set("user", JSON.stringify(adminData));
  local.set("token", adminData.apiKey);
  local.remove("admin");
  window.location = "dashboard";
};

export const hasAdminData = () => {
  const adminData = JSON.parse(local.get("admin"));
  return !lodash.isEmpty(adminData);
};

export const checkSubscribePlanAmount = (
  amountDisplay,
  amountToCheck,
  planName = ""
) => {
  if (!amountDisplay) return false;
  const splitAmountDisplay = amountDisplay.split(" ");
  if (splitAmountDisplay[2] === "month") {
    return Number(splitAmountDisplay[0]) >= amountToCheck;
  }
  if (splitAmountDisplay[2] === "year") {
    return (
      Number(splitAmountDisplay[0] / 12) >= amountToCheck ||
      ["Pro", "Med"].includes(planName)
    );
  }
  return false;
};

export const getUrlParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getLegacyUrl = (userData = {}) => {
  return `${`${process.env.REACT_APP_V1_APP_URL}/site/sso`}?id=${md5(
    userData.id + ""
  )}&key=${userData.apiKey}`;
};

export const transferToLegacy = (userData) => {
  if (userData.apiKey) {
    window.location.href = getLegacyUrl(userData);
  }
};

export const checkIfCcbGroupExistInMcGroups = (ccbGroup, mcAudience = []) => {
  return mcAudience.some(
    (item) =>
      (!item?.groupBy || item.groupBy === "Sync to Existing Group") &&
      item.groupName.includes(ccbGroup)
  );
};

export const hasPendingHoldContacts = (pairs = []) => {
  return pairs.filter((pair) => pair.hasPendingHoldContacts);
};

export const findLogByDetailsId = (logs = [], id) => {
  return logs.find((log) => Number(log.removed) !== 0 && log.detailId === id);
};

const populateCampusGroup = (campusId, ccbGroups, setCcbGroups) => {
  const filterCcbGroupByCampusId = lodash.filter(
    ccbGroups,
    (group) => group != null && group.campusId === campusId
  );
  if (lodash.isEmpty(filterCcbGroupByCampusId)) {
    const formData = formDataWithApiKey();
    formData.append("campusId", campusId);
    setCcbGroups(formData);
  } else if (!lodash.isEmpty(filterCcbGroupByCampusId)) {
    return filterCcbGroupByCampusId;
  } else {
    return null;
  }
};

const populateCampusProcessQueue = (
  campusId,
  ccbProcessQueues,
  setCcbProcessQueue
) => {
  const filteredPqByCampusId = lodash.filter(
    ccbProcessQueues,
    (pq) => pq != null && pq?.processList?.campusId === campusId
  );
  if (lodash.isEmpty(filteredPqByCampusId)) {
    const formData = formDataWithApiKey();
    formData.append("campusId", campusId);
    setCcbProcessQueue(formData);
  } else if (!lodash.isEmpty(filteredPqByCampusId)) {
    return filteredPqByCampusId;
  } else {
    return null;
  }
};

const populateMcGroup = (
  mailchimpListId,
  mailchimpInterestedGroups,
  setMailchimpInterestedGroups,
  data,
  mailchimplistIdForInterestedGroups = []
) => {
  const mcGroups = filterMcInterestedGroupByUniqueId(
    mailchimpInterestedGroups,
    data
  );
  if (lodash.isEmpty(mcGroups)) {
    if (!mailchimplistIdForInterestedGroups.includes(mailchimpListId)) {
      const formData = formDataWithApiKey();
      formData.append("mailchimpListId", mailchimpListId);
      setMailchimpInterestedGroups(formData);
    }
    return null;
  } else if (!lodash.isEmpty(mcGroups)) {
    const addkeyForGrouping = addNewKeyPairInArray(mcGroups, {
      groupBy: "Sync to Existing Group",
    });
    return addkeyForGrouping;
  }
  return null;
};

const populateMcTags = (syncPair, mailchimpTags) => {
  if (!lodash.isEmpty(mailchimpTags)) {
    if (syncPair.sync_type === 1) {
      const activeTags = mailchimpTags.map((item) => ({
        ...item,
        active: syncPair?.mcTags?.includes(item.name) ? true : false,
      }));
      const notIncludedTags = syncPair?.mcTags?.filter(
        (item) => !mailchimpTags.map((tag) => tag.name).includes(item)
      );
      let notIncludedTagsObj = [];
      if (!lodash.isEmpty(notIncludedTags)) {
        notIncludedTagsObj = notIncludedTags.map((tag) => ({
          name: tag,
          active: true,
        }));
      }
      const mergeTags = [...activeTags, ...notIncludedTagsObj];
      const filterTags = lodash.uniqBy(mergeTags, (e) => e.name);
      return filterTags;
    } else {
      const filterTags = lodash.uniqBy(mailchimpTags, (e) => e.name);
      return filterTags;
    }
  }
  return [];
};

const populateTagsForCustomList = (syncPair, mailchimpTags) => {
  if (!lodash.isEmpty(mailchimpTags)) {
    if (syncPair.sync_type === 1) {
      const activeTags = mailchimpTags.map((item) => ({
        ...item,
        active: syncPair?.tags?.includes(item.name) ? true : false,
      }));
      const filterTags = lodash.uniqBy(activeTags, (e) => e.name);
      return filterTags;
    } else {
      const filterTags = lodash.uniqBy(mailchimpTags, (e) => e.name);
      return filterTags;
    }
  }
  return [];
};

export const hasActiveTag = (tags = []) => {
  return tags.some((tag) => tag.active);
};

export const resolvedNumberOfPairs = (modalData) => {
  if (lodash.isEmpty(modalData)) return 0;
  return modalData.reduce(
    (acc, obj) =>
      Object.values(obj).every(
        (item) =>
          item.selectedId != null ||
          item.isSavedSearchSelected === 1 ||
          (Number(item.syncType) === 1 &&
            !lodash.isEmpty(item.tags) &&
            hasActiveTag(item.tags))
      )
        ? acc + 1
        : acc,
    0
  );
};

export const filterResolvedPairs = (modalData) => {
  if (lodash.isEmpty(modalData)) return [];
  return modalData.filter((item) =>
    Object.keys(item).every(
      (el) =>
        item[el].selectedId != null ||
        item[el]?.isSavedSearchSelected === 1 ||
        (Number(item[el].syncType) === 1 &&
          !lodash.isEmpty(item[el].tags) &&
          hasActiveTag(item[el].tags))
    )
  );
};

export const populateSyncpairs = (
  modalData,
  syncPairsArr,
  ccbCampuses,
  mailChimpAudience,
  isOnBrokenSyncPairs = false,
  mailchimpInterestedGroups,
  setMailchimpInterestedGroups,
  setCcbGroups,
  ccbGroups,
  mailchimpTags,
  setMailchimpTags,
  mailchimpListIdForTags,
  mailchimplistIdForInterestedGroups,
  ccbSavedSearches,
  ccbProcessQueues,
  setCcbProcessQueue,
  isWithPopulateForBoth = false,
  currentSync = ""
) => {
  if (lodash.isEmpty(syncPairsArr)) return [];
  const populatePair = [];
  try {
    for (let i = 0; i < syncPairsArr.length; i++) {
      const cloneModalData = deepClone(modalData);
      // POPULATE CCB CAMPUS AND OPTIONS
      cloneModalData[CCB_CAMPUSES].options = ccbCampuses;
      cloneModalData[CCB_CAMPUSES].selectedId = syncPairsArr[i].campusId;
      cloneModalData[CCB_CAMPUSES].value = syncPairsArr[i].campusName;
      cloneModalData[CCB_CAMPUSES].id = syncPairsArr[i].id;

      // POPULATE CCB GROUPS OR SAVED SAARCHES OPTIONS
      if (syncPairsArr[i].campusId) {
        const campusGroup = populateCampusGroup(
          syncPairsArr[i].campusId,
          ccbGroups,
          setCcbGroups
        );
        if (
          syncPairsArr[i] &&
          syncPairsArr[i].processId &&
          (isOnBrokenSyncPairs || isWithPopulateForBoth)
        ) {
          const processQueues = populateCampusProcessQueue(
            syncPairsArr[i].campusId,
            ccbProcessQueues,
            setCcbProcessQueue
          );
          if (!lodash.isEmpty(processQueues)) {
            cloneModalData[CCB_GROUPS].processQueueOptions = processQueues;
            cloneModalData[CCB_GROUPS].isProcessQueueSelected = 1;
            cloneModalData[CCB_GROUPS].selectedId = syncPairsArr[i].id;
            cloneModalData[CCB_GROUPS].value = syncPairsArr[i].queueName;
          }
        } else if (campusGroup) {
          cloneModalData[CCB_GROUPS].options = campusGroup;
          if (
            !syncPairsArr[i].ccbGroupName
              ?.toLowerCase()
              .includes("all members of", 0) ||
            isWithPopulateForBoth
          ) {
            cloneModalData[CCB_GROUPS].selectedId =
              isWithPopulateForBoth && currentSync === CCB_TO_MC
                ? syncPairsArr[i].id
                : syncPairsArr[i].ccbGroupId;
            cloneModalData[CCB_GROUPS].value = syncPairsArr[i].ccbGroupName;
          }
        }
      } else if (syncPairsArr[i].searchId) {
        cloneModalData[CCB_GROUPS].options = ccbSavedSearches;
        cloneModalData[CCB_GROUPS].selectedId = syncPairsArr[i].id;
        cloneModalData[CCB_GROUPS].value = syncPairsArr[i].name;
        cloneModalData[CCB_GROUPS].headerTitle = "CCB Saved Search";
        cloneModalData[CCB_GROUPS].placeholder = "Select Saved Searches";
        cloneModalData[CCB_CAMPUSES].isSavedSearchSelected = 1;
        cloneModalData[CCB_CAMPUSES].value = "Saved Searches";
        cloneModalData[CCB_CAMPUSES].selectedId = "Saved Searches";
      }

      // POPULATE MC AUDIENCE AND OPTIONSS
      cloneModalData[MAILCHIMP_AUDIENCE].options = mailChimpAudience;
      cloneModalData[MAILCHIMP_AUDIENCE].selectedId = !isOnBrokenSyncPairs
        ? syncPairsArr[i].mailchimpListId
        : null;
      cloneModalData[MAILCHIMP_AUDIENCE].value = !isOnBrokenSyncPairs
        ? syncPairsArr[i].mailchimpAudience
        : "";

      // POPULATE MC GROUPS OR TAGS AND THEIR OPTIONS
      cloneModalData[MAILCHIMP_GROUPS].syncType = syncPairsArr[i].sync_type;
      const mailchimpGroup = populateMcGroup(
        syncPairsArr[i].mailchimpListId,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        syncPairsArr[i],
        mailchimplistIdForInterestedGroups
      );
      if (mailchimpGroup) {
        let filterCreatedGroup = [];
        const isDuplicateGroupOption = checkIfCcbGroupExistInMcGroups(
          cloneModalData[CCB_GROUPS].value,
          mailchimpGroup
        );
        filterCreatedGroup.push({
          groupBy: `Create a ${
            isDuplicateGroupOption ? "Duplicate" : "new"
          } Group`,
          groupName: `${cloneModalData[CCB_GROUPS].value} ${
            isDuplicateGroupOption ? "- Copy" : ""
          }`,
          uniqueId: getUniqueId(1000) + "",
        });
        cloneModalData[MAILCHIMP_GROUPS].options = !isOnBrokenSyncPairs
          ? [...filterCreatedGroup, ...mailchimpGroup]
          : [];
        if (syncPairsArr[i].sync_type === 0) {
          const mailchimpGroupId = getMcInterestedGroupByUniqueId(
            mailchimpGroup,
            syncPairsArr[i]
          );
          cloneModalData[MAILCHIMP_GROUPS].value = !isOnBrokenSyncPairs
            ? syncPairsArr[i].mcInterestGroupName
            : "";
          cloneModalData[MAILCHIMP_GROUPS].selectedId = !isOnBrokenSyncPairs
            ? mailchimpGroupId?.uniqueId
            : null;
        }
      }
      let mcTags = [];
      if (isOnBrokenSyncPairs) {
        cloneModalData[MAILCHIMP_GROUPS].tags = mcTags;
        populatePair.push(cloneModalData);
      } else if (cloneModalData[MAILCHIMP_AUDIENCE].selectedId) {
        if (!mailchimpListIdForTags.includes(syncPairsArr[i].mailchimpListId)) {
          const formData = formDataWithApiKey();
          formData.append("mailchimpListId", syncPairsArr[i].mailchimpListId);
          setMailchimpTags(formData);
          break;
        } else {
          mcTags = populateMcTags(
            syncPairsArr[i],
            mailchimpTags[syncPairsArr[i].mailchimpListId]
          );
          cloneModalData[MAILCHIMP_GROUPS].tags = mcTags;
          populatePair.push(cloneModalData);
        }
      }
    }
  } catch (error) {
    return [];
  }
  return populatePair;
};

export const populateMcToCcbSyncPairsOnly = (
  modalData,
  mcToCcbSyncPair,
  mailChimpAudience,
  mailchimpInterestedGroups,
  setMailchimpInterestedGroups,
  mailchimpTags,
  setMailchimpTags,
  mailchimpListIdForTags,
  mailchimplistIdForInterestedGroups
) => {
  if (lodash.isEmpty(mcToCcbSyncPair)) return [];
  const populatePair = [];
  try {
    for (let i = 0; i < mcToCcbSyncPair.length; i++) {
      const cloneModalData = deepClone(modalData);

      // POPULATE MC AUDIENCE AND OPTIONSS
      cloneModalData[MAILCHIMP_AUDIENCE].options = mailChimpAudience;
      cloneModalData[MAILCHIMP_AUDIENCE].selectedId =
        mcToCcbSyncPair[i].mailchimpListId;
      cloneModalData[MAILCHIMP_AUDIENCE].isExistingPair = true;
      cloneModalData[MAILCHIMP_AUDIENCE].id = mcToCcbSyncPair[i].id;

      // POPULATE MC GROUPS OR TAGS AND THEIR OPTIONS
      cloneModalData[MAILCHIMP_GROUPS].syncType = mcToCcbSyncPair[i].sync_type;
      const mailchimpGroup = populateMcGroup(
        mcToCcbSyncPair[i].mailchimpListId,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        mcToCcbSyncPair[i],
        mailchimplistIdForInterestedGroups
      );
      if (mailchimpGroup) {
        cloneModalData[MAILCHIMP_GROUPS].options = mailchimpGroup;
        cloneModalData[MAILCHIMP_GROUPS].isExistingPair = true;
        cloneModalData[MAILCHIMP_GROUPS].id = mcToCcbSyncPair[i].id;
        if (mcToCcbSyncPair[i].sync_type === 0) {
          const mailchimpGroupId = getMcInterestedGroupByUniqueId(
            mailchimpGroup,
            mcToCcbSyncPair[i]
          );
          cloneModalData[MAILCHIMP_GROUPS].selectedId =
            mailchimpGroupId?.uniqueId;
        }
      }
      let mcTags = [];
      if (cloneModalData[MAILCHIMP_AUDIENCE].selectedId) {
        if (
          !mailchimpListIdForTags.includes(mcToCcbSyncPair[i].mailchimpListId)
        ) {
          const formData = formDataWithApiKey();
          formData.append(
            "mailchimpListId",
            mcToCcbSyncPair[i].mailchimpListId
          );
          setMailchimpTags(formData);
          break;
        } else {
          mcTags = populateTagsForCustomList(
            mcToCcbSyncPair[i],
            mailchimpTags[mcToCcbSyncPair[i].mailchimpListId]
          );
          cloneModalData[MAILCHIMP_GROUPS].tags = mcTags;
          populatePair.push(cloneModalData);
        }
      }
    }
  } catch (error) {
    return [];
  }
  return populatePair;
};

export const removeSameSyncPairs = (ccbToMcPairs = [], mcToCcbPairs = []) => {
  if (lodash.isEmpty(mcToCcbPairs)) return [];
  const filteredPairs = lodash.differenceBy(
    ccbToMcPairs,
    mcToCcbPairs,
    (data) =>
      data.ccbGroupId ||
      data.campusId ||
      data.mailchimpListGroupId ||
      data.mailchimpListId
  );
  return filteredPairs;
};

export const filterBrokenPairs = (syncPairs = []) => {
  return syncPairs.filter((item) => item.hasMcConflict === 1);
};

export const filterResolvedBrokenPair = (syncPairs = [], ids = []) => {
  return syncPairs.filter((item) => !ids.includes(item.id));
};

export const parsingHoldContactsForCsv = (data = []) => {
  if (lodash.isEmpty(data)) return [];
  const dataForCsv = [];
  for (let [index, obj] of data.entries()) {
    dataForCsv.push({ ...obj, type: `Conflict (${index + 1})` });
    if (obj.possibleMatches && Array.isArray(obj.possibleMatches)) {
      obj.possibleMatches.forEach((element) => {
        dataForCsv.push({
          ...element,
          type: `Possible Matches (${index + 1})`,
        });
      });
    }
  }
  return dataForCsv;
};

export const getBreakPoints = (deviceWidth) => {
  if (0 < deviceWidth && deviceWidth < 468) {
    return breakpoints[468];
  } else if (468 <= deviceWidth && deviceWidth <= 768) {
    return breakpoints[768];
  } else if (768 < deviceWidth && deviceWidth <= 992) {
    return breakpoints[992];
  } else if (992 < deviceWidth && deviceWidth <= 1200) {
    return breakpoints[1200];
  } else if (deviceWidth >= 1200) {
    return breakpoints[1500];
  }
  return "";
};

export const sortArrayByPlans = (data = []) => {
  const planOrder = {
    starter: 1,
    medium: 2,
    pro: 3,
  };

  const sortedArrayByPlans = data.sort(
    (a, b) => planOrder[a.plan] - planOrder[b.plan]
  );
  return sortedArrayByPlans;
};

export const filterRequiredMergeFieldsError = (data = []) => {
  return data.filter(
    (item) =>
      Number(item?.hasError) === 1 &&
      item?.errorMessage?.includes("merge fields")
  );
};

export const stringToBoolean = (str = "") => {
  return str.toLowerCase() === "true";
};

export const isEmptyObject = (object = {}) => {
  if (!object) return true;
  return Object.keys(object).length === 0;
};

export const getCurrentSubscriptionPlan = (data = []) => {
  return data.find((item) => item.isCurrent);
};

export const parseProcessQueueData = (data = []) => {
  const parsedPQ = [];
  if (!lodash.isEmpty(data)) {
    data.forEach((item) => {
      parsedPQ.push(
        ...addNewKeyPairInArray(item.queues, { groupBy: item.processList.name })
      );
    });
  }
  return parsedPQ;
};

export const normalizeProcessQueueData = (data = []) => {
  const parsedPQ = [];
  if (!lodash.isEmpty(data)) {
    data.forEach((item) => {
      const processListWithQueues = [];
      if (Array.isArray(item?.queues)) {
        item.queues.forEach((queue) => {
          processListWithQueues.push({
            ...queue,
            processList: item?.processList,
          });
        });
      }
      parsedPQ.push(...processListWithQueues);
    });
  }
  return parsedPQ;
};

export const getCurrentException = (mcToCcbCustomList = []) => {
  if (!mcToCcbCustomList || !mcToCcbCustomList?.length) return "";
  let exception = "";
  if (
    mcToCcbCustomList &&
    Array.isArray(mcToCcbCustomList) &&
    mcToCcbCustomList.length > 0
  ) {
    const groupExcemption = mcToCcbCustomList.filter(
      (item) => item.sync_type === 0
    );
    const tagsException = mcToCcbCustomList.filter(
      (item) => item.sync_type === 1
    );
    exception = `${groupExcemption?.length} Group, ${tagsException?.length} Tags`;
  }
  return exception;
};

const populateMcGroupForBrokenPairs = async (
  mailchimpListId,
  mailchimpInterestedGroups,
  setMailchimpInterestedGroups,
  data,
  mailchimplistIdForInterestedGroups = []
) => {
  const mcGroups = filterMcInterestedGroupByUniqueId(
    mailchimpInterestedGroups,
    data
  );
  if (lodash.isEmpty(mcGroups)) {
    if (!mailchimplistIdForInterestedGroups.includes(mailchimpListId)) {
      const formData = formDataWithApiKey();
      formData.append("mailchimpListId", mailchimpListId);
      await setMailchimpInterestedGroups(formData);
    }
    return null;
  } else if (!lodash.isEmpty(mcGroups)) {
    const addkeyForGrouping = addNewKeyPairInArray(mcGroups, {
      groupBy: "Sync to Existing Group",
    });
    return addkeyForGrouping;
  }
  return null;
};

export const populateSyncpairsForBrokenPairs = async (
  modalData,
  syncPairsArr,
  ccbCampuses,
  mailChimpAudience,
  isOnBrokenSyncPairs = false,
  mailchimpInterestedGroups,
  setMailchimpInterestedGroups,
  setCcbGroups,
  ccbGroups,
  mailchimpTags,
  setMailchimpTags,
  mailchimpListIdForTags,
  mailchimplistIdForInterestedGroups,
  ccbSavedSearches,
  ccbProcessQueues,
  setCcbProcessQueue,
  isWithPopulateForBoth = false,
  currentSync = ""
) => {
  if (lodash.isEmpty(syncPairsArr)) return [];
  const populatePair = [];
  try {
    for (let i = 0; i < syncPairsArr.length; i++) {
      const cloneModalData = deepClone(modalData);
      // POPULATE CCB CAMPUS AND OPTIONS
      cloneModalData[CCB_CAMPUSES].options = ccbCampuses;
      cloneModalData[CCB_CAMPUSES].selectedId = syncPairsArr[i].campusId;
      cloneModalData[CCB_CAMPUSES].value = syncPairsArr[i].campusName;
      cloneModalData[CCB_CAMPUSES].id = syncPairsArr[i].id;

      // POPULATE CCB GROUPS OR SAVED SAARCHES OPTIONS
      if (syncPairsArr[i].campusId) {
        const campusGroup = populateCampusGroup(
          syncPairsArr[i].campusId,
          ccbGroups,
          setCcbGroups
        );
        if (
          syncPairsArr[i] &&
          syncPairsArr[i].processId &&
          (isOnBrokenSyncPairs || isWithPopulateForBoth)
        ) {
          const processQueues = populateCampusProcessQueue(
            syncPairsArr[i].campusId,
            ccbProcessQueues,
            setCcbProcessQueue
          );
          if (!lodash.isEmpty(processQueues)) {
            cloneModalData[CCB_GROUPS].processQueueOptions = processQueues;
            cloneModalData[CCB_GROUPS].isProcessQueueSelected = 1;
            cloneModalData[CCB_GROUPS].selectedId = syncPairsArr[i].id;
            cloneModalData[CCB_GROUPS].value = syncPairsArr[i].queueName;
          }
        } else if (campusGroup) {
          cloneModalData[CCB_GROUPS].options = campusGroup;
          if (
            !syncPairsArr[i].ccbGroupName
              ?.toLowerCase()
              .includes("all members of", 0) ||
            isWithPopulateForBoth
          ) {
            cloneModalData[CCB_GROUPS].selectedId =
              isWithPopulateForBoth && currentSync === CCB_TO_MC
                ? syncPairsArr[i].id
                : syncPairsArr[i].ccbGroupId;
            cloneModalData[CCB_GROUPS].value = syncPairsArr[i].ccbGroupName;
          }
        }
      } else if (syncPairsArr[i].searchId) {
        cloneModalData[CCB_GROUPS].options = ccbSavedSearches;
        cloneModalData[CCB_GROUPS].selectedId = syncPairsArr[i].id;
        cloneModalData[CCB_GROUPS].value = syncPairsArr[i].name;
        cloneModalData[CCB_GROUPS].headerTitle = "CCB Saved Search";
        cloneModalData[CCB_GROUPS].placeholder = "Select Saved Searches";
        cloneModalData[CCB_CAMPUSES].isSavedSearchSelected = 1;
        cloneModalData[CCB_CAMPUSES].value = "Saved Searches";
        cloneModalData[CCB_CAMPUSES].selectedId = "Saved Searches";
      }

      // POPULATE MC AUDIENCE AND OPTIONSS
      cloneModalData[MAILCHIMP_AUDIENCE].options = mailChimpAudience;
      cloneModalData[MAILCHIMP_AUDIENCE].selectedId = !isOnBrokenSyncPairs
        ? syncPairsArr[i].mailchimpListId
        : null;
      cloneModalData[MAILCHIMP_AUDIENCE].value = !isOnBrokenSyncPairs
        ? syncPairsArr[i].mailchimpAudience
        : "";

      // POPULATE MC GROUPS OR TAGS AND THEIR OPTIONS
      cloneModalData[MAILCHIMP_GROUPS].syncType = syncPairsArr[i].sync_type;
      const mailchimpGroup = await populateMcGroupForBrokenPairs(
        syncPairsArr[i].mailchimpListId,
        mailchimpInterestedGroups,
        setMailchimpInterestedGroups,
        syncPairsArr[i],
        mailchimplistIdForInterestedGroups
      );
      if (mailchimpGroup) {
        let filterCreatedGroup = [];
        const isDuplicateGroupOption = checkIfCcbGroupExistInMcGroups(
          cloneModalData[CCB_GROUPS].value,
          mailchimpGroup
        );
        filterCreatedGroup.push({
          groupBy: `Create a ${
            isDuplicateGroupOption ? "Duplicate" : "new"
          } Group`,
          groupName: `${cloneModalData[CCB_GROUPS].value} ${
            isDuplicateGroupOption ? "- Copy" : ""
          }`,
          uniqueId: getUniqueId(1000) + "",
        });
        cloneModalData[MAILCHIMP_GROUPS].options = !isOnBrokenSyncPairs
          ? [...filterCreatedGroup, ...mailchimpGroup]
          : [];
        if (syncPairsArr[i].sync_type === 0) {
          const mailchimpGroupId = getMcInterestedGroupByUniqueId(
            mailchimpGroup,
            syncPairsArr[i]
          );
          cloneModalData[MAILCHIMP_GROUPS].value = !isOnBrokenSyncPairs
            ? syncPairsArr[i].mcInterestGroupName
            : "";
          cloneModalData[MAILCHIMP_GROUPS].selectedId = !isOnBrokenSyncPairs
            ? mailchimpGroupId?.uniqueId
            : null;
        }
      }
      let mcTags = [];
      if (isOnBrokenSyncPairs) {
        cloneModalData[MAILCHIMP_GROUPS].tags = mcTags;
        populatePair.push(cloneModalData);
      } else if (cloneModalData[MAILCHIMP_AUDIENCE].selectedId) {
        if (!mailchimpListIdForTags.includes(syncPairsArr[i].mailchimpListId)) {
          const formData = formDataWithApiKey();
          formData.append("mailchimpListId", syncPairsArr[i].mailchimpListId);
          setMailchimpTags(formData);
          break;
        } else {
          mcTags = populateMcTags(
            syncPairsArr[i],
            mailchimpTags[syncPairsArr[i].mailchimpListId]
          );
          cloneModalData[MAILCHIMP_GROUPS].tags = mcTags;
          populatePair.push(cloneModalData);
        }
      }
    }
  } catch (error) {
    return [];
  }
  return populatePair;
};

export const confirmationModalContentWhenSelectAllMembersGroup = (
  groupName = "",
  numOfParticipants = ""
) => {
  return `Are you sure you want to sync ${groupName} (${numOfParticipants}) to MailChimp as one group (proceed) OR do you want to select specific groups to sync (nevermind)? 
  <br /> <br /> If you proceed, this can increase your MailChimp subscriber's count and cost. Additionally, if this system group with ${groupName} (${numOfParticipants}) is synced incorrectly to a group that syncs back to CCB, this can lead to a cross in your people groups data.
  <br /> <br /> Note: You also would NOT be able to sync back to this CCB system group. In most cases, if you want to sync all members of a campus, it might be better to create and use a saved search instead.`;
};

export const integrationsCardInfoText = {
  ccb: `You will be able to sync your Church Community Builder (CCB) account with Mailchimp (MC). <br/>
  You will be able to choose which campuses, groups, or saved searches you need to import.`,
  mailchimp: `You will be able to use a free or paid MailChimp account.
  During the setup, you will be able to select how you would
  like to sync the groups or saved searches. You can create a
  new list or sync to an existing list. You may not have more
  than 60 groups or saved searches per list. Remember, you may
  be required to pay more if your subscriber count increases in
  Mailchimp. To begin, you will need your API key. Login to
  mailchimp.com → Profile → Account → Extras → API keysYou will be able to use a free or paid MailChimp account.
  During the setup, you will be able to select how you would
  like to sync the groups or saved searches. You can create a
  new list or sync to an existing list. You may not have more
  than 60 groups or saved searches per list. Remember, you may
  be required to pay more if your subscriber count increases in
  Mailchimp. To begin, you will need your API key. Login to
  mailchimp.com → Profile → Account → Extras → API keys{" "}
  <a
    href="http://kb.mailchimp.com/integrations/api-integrations/about-api-keys#Find-or-Generate-Your-API-Key"
    target="__blank"
  >
    Learn more
  </a>`,
};
