import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { Refresh, Done, ErrorOutline } from "@material-ui/icons";

const defaultLabels = {
  default: "Save",
  loading: "Saving",
  success: "Saved",
  error: "Failed!",
};

const labelColors = {
  default: "#2db8e8",
  loading: "gray",
  success: "#35d69b",
  error: "red",
};

const TextWithRequest = (props) => {
  const {
    labels,
    status,
    labelColors,
    onClick,
    resetStatus,
    onSuccessCall,
  } = props;

  useEffect(() => {
    if (status === "success") {
      onSuccessCall();
    }
  }, [onSuccessCall, status]);

  useEffect(() => {
    if (["success", "error"].includes(status)) {
      setTimeout(() => {
        resetStatus();
      }, 3000);
    }
  }, [resetStatus, status]);

  const renderIcons = useCallback(() => {
    if (status === "loading") {
      return <CircularProgress className="mr-1" color="inherit" size={20} />;
    } else if (status === "success") {
      return <Done />;
    } else if (status === "error") {
      return <ErrorOutline />;
    }
    return <Refresh />;
  }, [status]);

  return (
    <div
      onClick={onClick}
      style={{ color: labelColors[status] }}
      className="cursor-pointer flexer request-text"
    >
      {renderIcons()} <span className="ml-1">{labels[status]}</span>
    </div>
  );
};

TextWithRequest.propType = {
  labels: PropTypes.object,
  labelColors: PropTypes.object,
  onClick: PropTypes.func,
  resetStatus: PropTypes.func.isRequired,
  onSuccessCall: PropTypes.func,
};

TextWithRequest.defaultProps = {
  labels: defaultLabels,
  labelColors,
};

export default TextWithRequest;
