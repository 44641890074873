import React from "react";
import PropTypes from "prop-types";
import LogsTableRow from "./LogsTableRow";

const LogsTableBody = (props) => {
  const {
    data,
    isLoading,
    onDetailsClick,
    rollbackLogsIds,
    handleRevertLogs,
    onClickFiltering,
  } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <LogsTableRow
          data={elementData}
          index={index}
          onDetailsClick={onDetailsClick}
          rollbackLogsIds={rollbackLogsIds}
          handleRevertLogs={handleRevertLogs}
          onClickFiltering={onClickFiltering}
        />
      ))}
    </tbody>
  );
};

LogsTableBody.propTypes = {
  onDetailsClick: PropTypes.func.isRequired,
  handleRevertLogs: PropTypes.func.isRequired,
  onClickFiltering: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rollbackLogsIds: PropTypes.array,
};

LogsTableBody.defaultProps = {
  data: [],
  rollbackLogsIds: [],
};

export default LogsTableBody;
