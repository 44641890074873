import { useCallback, useState } from "react";

export const useWithHover = () => {
  const [hoverActive, setHoverActive] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHoverActive(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHoverActive(false);
  }, []);

  return {
    hoverActive,
    onMouseEnter,
    onMouseLeave,
  };
};
