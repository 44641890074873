import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { mcGroupsAndTagsTab } from "./addNewSyncPairModalData";
import { Tooltip } from "@material-ui/core";
import { RESTRICTED_FEATURE_TOOLTIP } from "../../../../utils/integrationsConstants";

const McTagsAndGroupTabBox = (props) => {
  const {
    handleMcGroupAndTags,
    syncType,
    index,
    className,
    isTagsDisabled,
    children,
    isSubscribeHigherPlan,
    isOnEdit,
  } = props;

  useEffect(() => {
    if (isTagsDisabled && syncType === 1) {
      handleMcGroupAndTags(0, index);
    }
  }, [handleMcGroupAndTags, index, isTagsDisabled, syncType]);

  const isTagsDisable = useCallback(
    (tab) => {
      if (tab.title === "Tags" && isTagsDisabled) {
        return true;
      }
      return false;
    },
    [isTagsDisabled]
  );

  const getTagsTooltipTitle = useCallback(
    (item) => {
      if (isOnEdit) return "";
      if (item.title === "Tags" && !isSubscribeHigherPlan) {
        return RESTRICTED_FEATURE_TOOLTIP;
      } else if (isTagsDisable(item)) {
        return "Please select MailChimp Audience.";
      }
      return "";
    },
    [isSubscribeHigherPlan, isTagsDisable, isOnEdit]
  );

  const handleTabClick = (tab) => {
    if (isTagsDisable(tab)) {
      return null;
    } else {
      handleMcGroupAndTags(tab.value, index);
    }
  };

  return (
    <div className={`mc-tags-group-box-container ${className}`}>
      <div className="mc-tabs">
        {mcGroupsAndTagsTab.map((item, idx) => (
          <Tooltip
            arrow={true}
            placement={"top"}
            title={getTagsTooltipTitle(item)}
          >
            <div
              key={idx}
              onClick={() => handleTabClick(item)}
              className={`tab ${syncType === item.value ? "active" : ""} ${
                isTagsDisable(item) ? "disable" : ""
              }`}
            >
              <item.icon style={{ fontSize: 14 }} className="mr-2" />{" "}
              {item.title}
            </div>
          </Tooltip>
        ))}
      </div>
      {children}
    </div>
  );
};

McTagsAndGroupTabBox.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  syncType: PropTypes.number.isRequired,
  handleMcGroupAndTags: PropTypes.func.isRequired,
  isTagsDisabled: PropTypes.bool,
  isOnEdit: PropTypes.bool,
};

export default McTagsAndGroupTabBox;
