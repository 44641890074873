import React from "react";
import PropTypes from "prop-types";
import { ArrowForward } from "@material-ui/icons";
import ButtonWithSpinner from "../ButtonWithSpinner/ButtonWIthSpinner";
import { ReactComponent as CcbIcon } from "../../assets/icons/ccb/ccb-black.svg";

const ToolsTableRow = (props) => {
  const { data, handleItemClicked, tableIdentity } = props;

  return (
    <tr className={data.isPrimary ? "bg-green" : ""}>
      <td></td>
      {["primaryContacts", "ignoredEmail"].includes(tableIdentity) && (
        <>
          <td>{data.firstName}</td>
          <td>{data.lastName}</td>
          <td>{data.email}</td>
        </>
      )}
      {tableIdentity === "primaryContacts" && <td>{data.familyPosition}</td>}
      {tableIdentity === "primaryContacts" && (
        <td className="flexer-start">
          {!data.isPrimary && (
            <ButtonWithSpinner
              onClick={() => handleItemClicked(data)}
              className="btn-danger p-0 mr-2"
            >
              Make primary{" "}
            </ButtonWithSpinner>
          )}
          <a
            href={data.ccbProfile}
            target="__blank"
            className="text-primary cursor-pointer"
          >
            #{data.ccbParticipantId}
          </a>
          <CcbIcon />
        </td>
      )}
      {tableIdentity === "ignoredEmail" && <td>{data.ccbGroupName}</td>}
      {tableIdentity === "ignoredEmail" && (
        <td>
          {" "}
          <ButtonWithSpinner
            onClick={() => handleItemClicked(data)}
            className="btn-danger p-0 mr-2"
          >
            Unignore{" "}
          </ButtonWithSpinner>
        </td>
      )}

      {tableIdentity === "cleanupUnsyncPair" && (
        <>
          <td>{data.ccbCampusName}</td>
          <td>
            <div className="flexer-space-between">
              {data.name} <ArrowForward className="mr-5" color="black" />
            </div>
          </td>
          <td>{data.mcListName}</td>
          <td>{data.mcGroupTagDisplay}</td>
          <td className="flexer">
            {data.showDismiss && (
              <ButtonWithSpinner
                onClick={() => handleItemClicked(data, true)}
                className="p-0 mr-2"
              >
                Dismiss{" "}
              </ButtonWithSpinner>
            )}
            {data.showDelete && (
              <ButtonWithSpinner
                onClick={() => handleItemClicked(data, false)}
                className="btn-danger p-0"
              >
                Delete{" "}
              </ButtonWithSpinner>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

ToolsTableRow.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

ToolsTableRow.defaultProps = {
  isActive: false,
};

export default ToolsTableRow;
