import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as globalActions from "../actions/globalAction";

export function useGlobalStore() {
  const dispatch = useDispatch();
  const _global = useSelector((store) => store.globalReducer);

  const setSnackbarData = useCallback(
    async (data) => {
      await dispatch(globalActions.setSnackbarData(data));
    },
    [dispatch]
  );

  const toggleSidebarExpanded = useCallback(
    async (isSidebarExpanded) => {
      await dispatch(globalActions.toggleSidebarExpanded(isSidebarExpanded));
    },
    [dispatch]
  );

  return {
    global: _global,
    setSnackbarData,
    toggleSidebarExpanded,
  };
}

export default useGlobalStore;
