import {
  convertFormdataToJson,
  getCurrentSubscriptionPlan,
} from "../../helpers";
import { ACTION_TYPES } from "../actions/types";
import lodash from "lodash";

const initialState = {
  isLoading: false,

  errorMessage: "",
  successMessage: "",

  // Invoices
  invoices: [],
  invoicesStatus: "",
  invoicesPage: null,
  invoicesLoadedAll: null,
  invoicesLoadingMoreStatus: null,

  // Subscription plans
  subscriptionPlans: {},
  subscriptionPlansStatus: "",
  currentSubscriptionPlan: {},

  // Current plan
  currentPlan: null,
  hasCurrentPlan: null,
  currentPlanStatus: "",

  // CHECK COUPON
  checkCouponStatus: "",
  isValidCoupon: null,
  coupon: "",
  couponPlan: {},
  couponTrialDays: null,

  // CANCEL PLAN
  cancelPlanStatus: "",

  // CARD STATE
  getCardStatus: "",
  currentCard: [],
  hasCard: null,

  cardErrorMessage: "",
  cardSuccessMessage: "",
  editCardStatus: "",

  // STRIPE PUBLISHABLE KEY
  stripePublishableKey: "",
  striplePublishableStatus: "",

  // PURCHASE PLAN STATUS
  purchasePlanStatus: "",

  // Decline card modal
  showCardDeclineModal: false,

  showClearOverdueInvoiceModal: false,
};

const billingReducer = (state = initialState, action) => {
  let invoices = null;
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_INVOICES: {
      const formData = convertFormdataToJson(action.data);
      return Object.assign({}, state, {
        invoicesStatus: "loading",
        invoicesLoadingMoreStatus: formData.page > 1 ? "loading" : null,
      });
    }

    case ACTION_TYPES.GET_ALL_INVOICES_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      if (Number(formData.page) === 1 || !state.invoices) {
        invoices = action.payload.data;
      } else {
        invoices = state.invoices.concat(action.payload.data);
      }
      return Object.assign({}, state, {
        invoicesStatus: "success",
        invoices,
        invoicesPage: formData.page,
        invoicesLoadingMoreStatus: formData.page > 1 ? "success" : null,
        invoicesLoadedAll: action.payload.data.length === 0,
      });
    }

    case ACTION_TYPES.GET_ALL_INVOICES_ERROR: {
      const formData = convertFormdataToJson(action.data);
      return Object.assign({}, state, {
        invoicesStatus: "error",
        invoicesLoadedAll: null,
        invoicesPage: null,
        invoicesLoadingMoreStatus: formData.page > 1 ? "error" : null,
      });
    }

    case ACTION_TYPES.GET_ALL_AVAILABLE_PLANS: {
      return Object.assign({}, state, {
        subscriptionPlansStatus: "loading",
      });
    }

    case ACTION_TYPES.GET_ALL_AVAILABLE_PLANS_SUCCESS: {
      const subscriptionPlan = [];
      if (action.payload?.monthly && Array.isArray(action.payload?.monthly)) {
        subscriptionPlan.push(...action.payload?.monthly);
      }
      if (action.payload?.yearly && Array.isArray(action.payload?.yearly)) {
        subscriptionPlan.push(...action.payload?.yearly);
      }
      const currrentPlan = getCurrentSubscriptionPlan(subscriptionPlan);
      return Object.assign({}, state, {
        subscriptionPlansStatus: "success",
        subscriptionPlans: action.payload,
        currentSubscriptionPlan: currrentPlan,
      });
    }

    case ACTION_TYPES.GET_ALL_AVAILABLE_PLANS_ERROR: {
      return Object.assign({}, state, {
        subscriptionPlansStatus: "error",
      });
    }

    case ACTION_TYPES.GET_CURRENT_PLAN: {
      return Object.assign({}, state, {
        currentPlanStatus: "loading",
      });
    }

    case ACTION_TYPES.GET_CURRENT_PLAN_SUCCESS: {
      return Object.assign({}, state, {
        currentPlanStatus: "success",
        currentPlan: action.payload.currentPlan,
        hasCurrentPlan: action.payload.hasCurrentPlan,
      });
    }

    case ACTION_TYPES.GET_CURRENT_PLAN_ERROR: {
      return Object.assign({}, state, {
        currentPlanStatus: "error",
      });
    }

    case ACTION_TYPES.CHECK_COUPON: {
      return Object.assign({}, state, {
        checkCouponStatus: "loading",
      });
    }

    case ACTION_TYPES.CHECK_COUPON_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      return Object.assign({}, state, {
        checkCouponStatus: "success",
        isValidCoupon: action.payload.status === 1,
        coupon: action.payload.status === 1 ? formData.code : "",
        couponPlan: action.subscriptionPlan,
        couponTrialDays: action?.payload?.numOfDays,
      });
    }

    case ACTION_TYPES.CHECK_COUPON_ERROR: {
      return Object.assign({}, state, {
        checkCouponStatus: "error",
      });
    }

    case ACTION_TYPES.CHECK_COUPON_CLEAR: {
      return Object.assign({}, state, {
        checkCouponStatus: "",
        isValidCoupon: null,
        coupon: "",
        couponPlan: {},
        couponTrialDays: null,
      });
    }

    case ACTION_TYPES.CANCEL_PLAN: {
      return Object.assign({}, state, {
        cancelPlanStatus: "loading",
      });
    }

    case ACTION_TYPES.CANCEL_PLAN_SUCCESS: {
      return Object.assign({}, state, {
        cancelPlanStatus: "success",
      });
    }

    case ACTION_TYPES.CANCEL_PLAN_ERROR: {
      return Object.assign({}, state, {
        cancelPlanStatus: "error",
      });
    }

    case ACTION_TYPES.CANCEL_PLAN_CLEAR: {
      return Object.assign({}, state, {
        cancelPlanStatus: "",
      });
    }

    case ACTION_TYPES.GET_CURRENT_CARD_DETAILS: {
      return Object.assign({}, state, {
        getCardStatus: "loading",
      });
    }

    case ACTION_TYPES.GET_CURRENT_CARD_DETAILS_SUCCESS: {
      let cards = [];
      if (action.payload.status === 1 && !lodash.isEmpty(action.payload.data)) {
        cards.push(action.payload.data);
      }
      return Object.assign({}, state, {
        getCardStatus: "success",
        currentCard: cards.length ? cards : action.payload.data,
        hasCard: action.payload.hasCard,
      });
    }

    case ACTION_TYPES.GET_CURRENT_CARD_DETAILS_ERROR: {
      return Object.assign({}, state, {
        getCardStatus: "error",
      });
    }

    case ACTION_TYPES.UPDATE_CURRENT_CARD: {
      return Object.assign({}, state, {
        editCardStatus: "loading",
        cardErrorMessage: "",
        cardSuccessMessage: "",
      });
    }

    case ACTION_TYPES.UPDATE_CURRENT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        editCardStatus: "success",
        cardErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        cardSuccessMessage:
          action.payload.status === 1 ? action.payload.message : "",
      });
    }

    case ACTION_TYPES.UPDATE_CURRENT_CARD_ERROR: {
      return Object.assign({}, state, {
        editCardStatus: "error",
        cardErrorMessage: "",
        cardSuccessMessage: "",
      });
    }

    case ACTION_TYPES.ADD_NEW_CARD: {
      return Object.assign({}, state, {
        editCardStatus: "loading",
        cardErrorMessage: "",
        cardSuccessMessage: "",
      });
    }

    case ACTION_TYPES.ADD_NEW_CARD_SUCCESS: {
      return Object.assign({}, state, {
        editCardStatus: "success",
        cardErrorMessage:
          action.payload.status === 0 ? action.payload.message : "",
        cardSuccessMessage:
          action.payload.status === 1 ? action.payload.message : "",
      });
    }

    case ACTION_TYPES.ADD_NEW_CARD_ERROR: {
      return Object.assign({}, state, {
        editCardStatus: "error",
        cardErrorMessage: "",
        cardSuccessMessage: "",
      });
    }

    case ACTION_TYPES.EDIT_CARD_STATE_CLEAR: {
      return Object.assign({}, state, {
        editCardStatus: "",
        cardErrorMessage: "",
        cardSuccessMessage: "",
      });
    }

    case ACTION_TYPES.GET_STRIPE_PUBLISHABLE_KEY: {
      return Object.assign({}, state, {
        striplePublishableStatus: "loading",
      });
    }

    case ACTION_TYPES.GET_STRIPE_PUBLISHABLE_KEY_SUCCESS: {
      return Object.assign({}, state, {
        striplePublishableStatus: "success",
        stripePublishableKey: action.payload.publishableKey,
      });
    }

    case ACTION_TYPES.GET_STRIPE_PUBLISHABLE_KEY_ERROR: {
      return Object.assign({}, state, {
        striplePublishableStatus: "error",
      });
    }

    case ACTION_TYPES.PURCHASE_PLAN: {
      return Object.assign({}, state, {
        purchasePlanStatus: "loading",
      });
    }

    case ACTION_TYPES.PURCHASE_PLAN_SUCCESS: {
      return Object.assign({}, state, {
        purchasePlanStatus: "success",
        errorMessage: action.payload.status === 0 ? action.payload.message : "",
        successMessage:
          action.payload.status === 1 ? action.payload.message : "",
      });
    }

    case ACTION_TYPES.PURCHASE_PLAN_ERROR: {
      return Object.assign({}, state, {
        purchasePlanStatus: "error",
      });
    }

    case ACTION_TYPES.PURCHASE_PLAN_CLEAR: {
      return Object.assign({}, state, {
        purchasePlanStatus: "",
        errorMessage: "",
        successMessage: "",
      });
    }

    case ACTION_TYPES.TOGGLE_BILLING_DECLINE_CARD: {
      return Object.assign({}, state, {
        showCardDeclineModal: action.payload,
      });
    }

    case ACTION_TYPES.TOGGLE_CLEAR_OVERDUE_INVOICE_MODAL: {
      return Object.assign({}, state, {
        showClearOverdueInvoiceModal: action.payload,
      });
    }

    default:
      return state;
  }
};

export default billingReducer;
