import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userReducer";
import globalReducer from "./globalReducer";
import logsReducer from "./logsReducer";
import integrationReducer from "./integrationReducer";
import toolsReducer from "./toolsReducer";
import billingReducer from "./billingReducer";
import adminUsersReducer from "./AdminReducers/adminUsersReducer";
import countriesReducer from "./countriesReducer";

const config = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const appReducer = combineReducers({
  // USER REDUCER
  userReducer,
  globalReducer,
  logs: logsReducer,
  integrations: integrationReducer,
  tools: toolsReducer,
  billing: billingReducer,
  // ADMIN REDUCER
  admin: adminUsersReducer,
  countries: countriesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") state = undefined;
  return appReducer(state, action);
};

export default persistReducer(config, rootReducer);
