import React from "react";
import PropTypes from "prop-types";
import {
  SupervisorAccountRounded,
  CheckCircleOutline,
} from "@material-ui/icons";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";

const PendingUsersTableRow = (props) => {
  const {
    data,
    onLoginClick,
    onClickApproveUser,
    approveUserStatus,
    approveUserId,
  } = props;

  return (
    <tr>
      <td></td>
      <td className="w-25">
        <div>
          <span className="flexer-start">
            {data.email}{" "}
            {data.role === 1 && (
              <SupervisorAccountRounded fontSize="small" className="ml-1" />
            )}
          </span>
          {data.role === 2 && (
            <span onClick={() => onLoginClick(data)} className="link-text">
              Login
            </span>
          )}
        </div>
      </td>
      <td className="pl-5">{data.appVersion}</td>
      <td>{data.apiKey || "Unknown"}</td>
      <td className="w-25">
        <ButtonWithSpinner
          bootstrapButtonClassName={"approve-user-button"}
          onClick={onClickApproveUser(data)}
          disabled={approveUserStatus === "loading"}
          loading={
            approveUserStatus === "loading" && approveUserId === data?.id
          }
        >
          <CheckCircleOutline htmlColor="#fff" /> Approve User
        </ButtonWithSpinner>
      </td>
    </tr>
  );
};

PendingUsersTableRow.propTypes = {
  data: PropTypes.object,
};

PendingUsersTableRow.defaultProps = {
  data: {},
};

export default PendingUsersTableRow;
