import React, { useCallback, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropType from "prop-types";
import validator from "validator";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ListSubheader } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import InputField from "../../InputField/InputField";
import {
  createUser,
  getAllUsers,
} from "../../../store/middlewares/adminMiddlewares/adminUsersMiddleware";
import { getAllAvailablePlans } from "../../../store/middlewares/billingMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import { clearCreateUser } from "../../../store/actions/adminActions/adminUserActions";
import { setSnackbarData } from "../../../store/actions/globalAction";
import "../../../styles/views/login.scss";

const CreateUserModal = (props) => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [showPassword, setShowPassword] = useState(false);
  const [selectedPackageAmount, setSelectedPackageAmount] = useState("none");
  const isCreateButtonDisabled = useMemo(() => {
    return (
      !inputs.email ||
      !inputs.firstName ||
      !inputs.lastName ||
      !inputs.password ||
      !validator.isEmail(inputs.email) ||
      selectedPackageAmount === "none"
    );
  }, [
    inputs.email,
    inputs.firstName,
    inputs.lastName,
    inputs.password,
    selectedPackageAmount,
  ]);
  const {
    // Component props
    showModal,
    toggleModal,

    // Redux props
    subscriptionPlans,
    createUserStatus,
    errorMessage,
    successMessage,

    // Redux functions
    getAllAvailablePlans,
    createUser,
    clearCreateUser,
    getAllUsers,
    setSnackbarData,
  } = props;

  const getUsers = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    formData.append("firstName", "");
    formData.append("lastName", "");
    formData.append("email", "");
    formData.append("ccbAccount", "");
    getAllUsers(formData);
  }, [getAllUsers]);

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  useEffect(() => {
    if (successMessage) {
      getUsers();
      showSnackbar(successMessage, "success");
      clearCreateUser();
      toggleModal();
    }
  }, [
    clearCreateUser,
    createUserStatus,
    errorMessage,
    getUsers,
    showSnackbar,
    successMessage,
    toggleModal,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    getAllAvailablePlans(formData);
  }, [getAllAvailablePlans]);

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const handlePackageChange = useCallback((event) => {
    setSelectedPackageAmount(event.target.value);
  }, []);

  const getCurrentPackageId = useCallback(() => {
    let packages;
    const isYearly = selectedPackageAmount > 27;
    if (isYearly) {
      packages = subscriptionPlans?.yearly;
    } else {
      packages = subscriptionPlans?.monthly;
    }
    const packageKey = isYearly ? "annualAmount" : "monthlyAmount";
    const currentPackageKey = packages.find(
      (plan) => plan[packageKey] === selectedPackageAmount
    );
    return { id: currentPackageKey.id, isYearly };
  }, [
    selectedPackageAmount,
    subscriptionPlans?.monthly,
    subscriptionPlans?.yearly,
  ]);

  const handleCreateUsesr = useCallback(() => {
    const currentPackage = getCurrentPackageId();
    const formData = formDataWithApiKey();
    formData.append("email", inputs.email);
    formData.append("firstName", inputs.firstName);
    formData.append("lastName", inputs.lastName);
    formData.append("password", inputs.password);
    formData.append("currentPackageId", currentPackage.id);
    formData.append("isYearly", currentPackage.isYearly ? 1 : 0);
    createUser(formData);
  }, [
    createUser,
    getCurrentPackageId,
    inputs.email,
    inputs.firstName,
    inputs.lastName,
    inputs.password,
  ]);

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
      >
        <DialogTitle id="form-dialog-title" className="pb-0">
          <strong>Create User</strong>
          {errorMessage && <h6 className="text-danger">{errorMessage}</h6>}
        </DialogTitle>
        <DialogContent>
          <FormControl className="w-100">
            <InputField
              name="email"
              type="email"
              label="Email"
              inputClass="text-field-input"
              handleChange={handleChange}
              value={inputs.email}
            />
            <InputField
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              inputClass="text-field-input"
              showPassword={showPassword}
              handlePasswordVisibility={handlePasswordVisibility}
              handleChange={handleChange}
              value={inputs.password}
            />
            <InputField
              name="firstName"
              type="text"
              label="First Name"
              inputClass="text-field-input"
              handleChange={handleChange}
              value={inputs.firstName}
            />
            <InputField
              name="lastName"
              type="text"
              inputClass="text-field-input"
              label="Last Name"
              handleChange={handleChange}
              value={inputs.lastName}
            />
            <Select
              className="mt-2"
              name="package"
              onChange={handlePackageChange}
              style={{ height: 45 }}
              value={selectedPackageAmount}
              id="grouped-select"
              label="Grouping"
            >
              <MenuItem value="none">
                <em>Please Select Package</em>
              </MenuItem>
              <ListSubheader>Monthly Packages</ListSubheader>
              {Boolean(subscriptionPlans?.monthly?.length) &&
                subscriptionPlans?.monthly.map((monthlyPlan) => (
                  <MenuItem value={monthlyPlan.monthlyAmount}>
                    {monthlyPlan.name} - {monthlyPlan.monthlyAmount}$
                  </MenuItem>
                ))}
              <ListSubheader>Yearly Packages</ListSubheader>
              {Boolean(subscriptionPlans?.yearly?.length) &&
                subscriptionPlans?.yearly.map((yearlyPlan) => (
                  <MenuItem value={yearlyPlan.annualAmount}>
                    {yearlyPlan.name} - {yearlyPlan.annualAmount}$
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <div className="flexer-space-between w-100 p-3">
          <ButtonWithSpinner
            onClick={toggleModal}
            className="dark-gray-button w-25"
          >
            Cancel
          </ButtonWithSpinner>
          <ButtonWithSpinner
            onClick={handleCreateUsesr}
            loading={createUserStatus === "loading"}
            disabled={isCreateButtonDisabled}
            className="bg-yellow w-25"
          >
            Create
          </ButtonWithSpinner>
        </div>
      </Dialog>
    </div>
  );
};

CreateUserModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    subscriptionPlans: store.billing.subscriptionPlans,
    createUserStatus: store.admin.createUserStatus,
    successMessage: store.admin.successMessage,
    errorMessage: store.admin.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (data) => dispatch(createUser(data)),
    getAllAvailablePlans: (data) => dispatch(getAllAvailablePlans(data)),
    clearCreateUser: () => dispatch(clearCreateUser()),
    getAllUsers: (data) => dispatch(getAllUsers(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
