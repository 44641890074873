import { useEffect } from "react";

export const useComponentWillUnmount = (func) => {
  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      func();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
