import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PhoneInput from "react-phone-input-2";
import { Typography, Grid } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import ListGroupItem from "../../components/ListGroupItem/ListGroupItem";
import CustomSelect from "../../HelperComponents/CustomSelect";
import { usStates } from "../../utils/USstates";
import {
  clearUpdateProfile,
  updateUserProfile,
} from "../../store/middlewares/userMiddleware";
import { formatPhoneNumber } from "../../helpers";
import ChangePasswordModal from "../../components/modals/ChangePasswordModal/ChangePasswordModal";
import { useWithHover } from "../../utils/hooks/useWithHover";
import UserAvatar from "../../HelperComponents/UserAvatar";
import useIsSidebarExpanded from "../../utils/hooks/useIsSidebarExpanded";
import { clearUpdateProfileErrorMessage } from "../../store/actions/userActions";
import { setSnackbarData } from "../../store/actions/globalAction";
import "../../styles/components/more/user-settings.scss";

const Settings = (props) => {
  const {
    // Redux props
    userData,
    updateProfileStatus,
    updateProfileErrorMessage,

    // Redux functions
    updateUserProfile,
    clearUpdateProfile,
    clearUpdateProfileErrorMessage,
    setSnackbarData,
  } = props;
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [state, setState] = useState("");
  const { hoverActive, onMouseLeave, onMouseEnter } = useWithHover();

  const isSidebarExpanded = useIsSidebarExpanded();

  useEffect(() => {
    if (updateProfileErrorMessage) {
      clearUpdateProfileErrorMessage();
      setSnackbarData({
        snackbarSeverity: "error",
        showSnackbar: true,
        snackbarMessage: updateProfileErrorMessage,
      });
    }
  }, [
    clearUpdateProfileErrorMessage,
    updateProfileErrorMessage,
    setSnackbarData,
  ]);

  const onSave = useCallback(
    (profileProperty) => (text) => {
      if (text) {
        const formData = new FormData();
        formData.append("apiKey", userData.apiKey);
        formData.append(profileProperty, text);
        updateUserProfile(formData, profileProperty);
      }
    },
    [updateUserProfile, userData.apiKey]
  );

  const resetStatus = useCallback(
    (requestId) => () => {
      clearUpdateProfile(requestId);
    },
    [clearUpdateProfile]
  );

  const uploadAvatar = useCallback(
    (event) => {
      let formData = new FormData();
      let file = event.target.files[0];
      if (file) {
        const imageType = file.type;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          formData.append("apiKey", userData.apiKey);
          formData.append("imageFileBase64", reader.result);
          formData.append("imageType", imageType);
          updateUserProfile(formData, "imageFileBase64");
        };
      }
    },
    [updateUserProfile, userData.apiKey]
  );

  return (
    <div
      className={clsx("page-wrapper my-profile", {
        "with-page-sub-menu-expanded": isSidebarExpanded,
      })}
    >
      <div className="desktop-design">
        <Typography className="desktop-title" component={"h2"}>
          User Settings
        </Typography>
        <Grid container className="user-detail">
          <Typography className="title" component={"h2"}>
            You
          </Typography>
          <Grid item md={4} xs={12} className="item">
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="avatar">
                <UserAvatar userData={userData} />
              </div>
              {hoverActive && (
                <button className="upload-avatar-image">
                  <span className="cursor-pointer">Edit Avatar</span>
                  <input type="file" accept="image/*" onChange={uploadAvatar} />
                </button>
              )}
            </div>
            <div className="name list-group contact">
              <ListGroupItem
                label="First Name"
                currentValue={userData.firstName}
                currentValueToShow={userData.firstName}
                onSave={onSave("firstName")}
                apiStatus={updateProfileStatus["firstName"]}
                successCallback={resetStatus("firstName")}
                charactersLimit={30}
              />
              <ListGroupItem
                label="City"
                currentValue={userData.city}
                currentValueToShow={userData.city}
                onSave={onSave("city")}
                apiStatus={updateProfileStatus["city"]}
                successCallback={resetStatus("city")}
                charactersLimit={30}
              />
              <ListGroupItem
                label="Phone number"
                currentValue={userData?.phoneNumber}
                currentValueToShow={formatPhoneNumber(userData?.phoneNumber)}
                customEditElements={
                  <div className="input-wrapper phone">
                    <span className="icon flexer">
                      <Phone color="white" fontSize="small" />
                    </span>
                    <PhoneInput
                      name="mobile_phone"
                      country={"us"}
                      value={userData?.phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </div>
                }
                onSave={() => onSave("phoneNumber")(phoneNumber)}
                apiStatus={updateProfileStatus["phoneNumber"]}
                successCallback={resetStatus("phoneNumber")}
              />
            </div>
          </Grid>
          <Grid item md={4} xs={12} className="item">
            <div className="contact list-group">
              <ListGroupItem
                label="Last Name"
                currentValue={userData.lastName}
                currentValueToShow={userData.lastName}
                className=""
                onSave={onSave("lastName")}
                apiStatus={updateProfileStatus["lastName"]}
                successCallback={resetStatus("lastName")}
                charactersLimit={50}
              />
              <ListGroupItem
                label="State"
                currentValue={userData.state}
                currentValueToShow={userData.state}
                customEditElements={
                  <div className="timezone-wrapper ml-2">
                    <CustomSelect
                      onChange={(event) => setState(event.target.value)}
                      value={state || userData?.state || undefined}
                      options={usStates}
                    />
                  </div>
                }
                onSave={() => onSave("state")(state)}
                apiStatus={updateProfileStatus["state"]}
                successCallback={resetStatus("state")}
              />
            </div>
          </Grid>
          <Grid item md={4} xs={12} className="item">
            <div className="contact list-group">
              <ListGroupItem
                label="Street address"
                currentValue={userData.address}
                currentValueToShow={userData.address}
                className=""
                onSave={onSave("address")}
                apiStatus={updateProfileStatus["address"]}
                successCallback={resetStatus("address")}
                charactersLimit={40}
              />
              <ListGroupItem
                label="Postal code"
                currentValue={userData?.zip}
                currentValueToShow={userData?.zip}
                className=""
                onSave={onSave("zip")}
                apiStatus={updateProfileStatus["zip"]}
                successCallback={resetStatus("zip")}
                charactersLimit={30}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className="user-detail login mt-4">
          <Typography className="title" component={"h2"}>
            Your Login Credentials
          </Typography>
          <Grid item xs={12}>
            <div className="contact list-group">
              <ListGroupItem
                label="Email"
                currentValue={userData.email}
                currentValueToShow={userData.email}
                className=""
                onSave={onSave("email")}
                apiStatus={updateProfileStatus["email"]}
                successCallback={resetStatus("email")}
                charactersLimit={100}
              />
              <ListGroupItem
                label="Password"
                currentValueToShow={"******"}
                editText="Change"
                onClickEditButton={() => setShowEditPasswordModal(true)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container className="user-detail login mt-4">
          <Typography className="title" component={"h2"}>
            Treasurer's Info
          </Typography>
          <Grid item xs={12}>
            <div className="contact list-group">
              <ListGroupItem
                label="Treasurer Email"
                currentValue={userData.treasurerEmail || userData.email}
                currentValueToShow={userData.treasurerEmail || userData.email}
                className=""
                onSave={onSave("treasurerEmail")}
                apiStatus={updateProfileStatus["treasurerEmail"]}
                successCallback={resetStatus("treasurerEmail")}
                charactersLimit={100}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {showEditPasswordModal && (
        <ChangePasswordModal
          open={showEditPasswordModal}
          toggleModal={() => setShowEditPasswordModal(!showEditPasswordModal)}
          status={updateProfileStatus["password"]}
          updateUserProfile={updateUserProfile}
          userData={userData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    updateProfileStatus: store.userReducer.updateProfileStatus,
    updateProfileErrorMessage: store.userReducer.updateProfileErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (data, requestId) =>
      dispatch(updateUserProfile(data, requestId)),
    clearUpdateProfile: (requestId) => dispatch(clearUpdateProfile(requestId)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearUpdateProfileErrorMessage: () =>
      dispatch(clearUpdateProfileErrorMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
