import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ArrowForward, Close } from "@material-ui/icons";
import { getValueFromArrayOfKeys } from "../../../helpers";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoint";
import "../../../styles/components/integrations/remove-sync-pair.scss";

const SyncNewPairConfirmationModal = (props) => {
  const {
    open,
    handleClose,
    handleSyncPairs,
    syncPairs,
    syncNewPairsStatus,
    syncManualStatus,
    showSyncNewPairSkipRoute,
  } = props;
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const filterNewSyncPairs = syncPairs.filter(
    (item) => Number(item.isNew) === 1 && Number(item?.hasMcConflict) === 0
  );

  useEffect(() => {
    if (syncManualStatus === "success") {
      handleClose();
    }
  }, [handleClose, syncManualStatus]);

  const getCcbCampuses = useCallback((pair) => {
    if (pair?.searchId) {
      return "Saved Search";
    } else {
      return getValueFromArrayOfKeys(pair, ["campusName", "listName"]);
    }
  }, []);

  const renderMailchimpGroupAndTag = useCallback((pair) => {
    if (pair.sync_type === 1) {
      if (pair.mcTags) {
        return Object.values(pair.mcTags).join();
      }
    } else {
      return getValueFromArrayOfKeys(pair, ["mcInterestGroupName"]);
    }
  }, []);

  const getConfirmationText = useMemo(() => {
    if (showSyncNewPairSkipRoute) {
      return `The newly added sync ${
        filterNewSyncPairs.length === 1 ? "pair" : "pairs"
      } have been saved and ${
        filterNewSyncPairs.length === 1 ? "is" : "are"
      } now available to sync either manually or automatically at a later time.`;
    }
    return `Are you sure want to Sync just New Pairs? (${filterNewSyncPairs.length} new sync pairs)`;
  }, [filterNewSyncPairs, showSyncNewPairSkipRoute]);

  const onClickedSkipBtn = useCallback(() => {
    history.push(showSyncNewPairSkipRoute);
    handleClose();
  }, [history, showSyncNewPairSkipRoute, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: isMdOrBelowBreakpoint(breakpoint) ? "m-0" : "",
      }}
    >
      <DialogTitle className="flexer-space-between" id="alert-dialog-title">
        <span className="modal-main-heading">
          {showSyncNewPairSkipRoute ? "Confirm?" : "Sync Just New Pairs?"}
        </span>
        <Close onClick={handleClose} className="close-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getConfirmationText}
          <br />
          <>
            <p className="mt-2">These are the list of New Sync</p>
            {filterNewSyncPairs.map((item) => (
              <div className="remove-pair-container" key={item.key}>
                {getCcbCampuses(item) || "Unknown"} (
                {getValueFromArrayOfKeys(item, [
                  "name",
                  "ccbGroupName",
                  "listGroupName",
                  "queueName",
                ]) || "Unknown"}
                ) <ArrowForward color={"white"} fontSize={"default"} />{" "}
                {getValueFromArrayOfKeys(item, ["mailchimpAudience"]) ||
                  "Unknown"}{" "}
                ({renderMailchimpGroupAndTag(item) || "Unknown"})
              </div>
            ))}
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-4 mr-3">
        <div className="d-flex justify-content-between w-100">
          {showSyncNewPairSkipRoute && (
            <ButtonWithSpinner
              className={"ml-4 rounded-button"}
              onClick={onClickedSkipBtn}
            >
              Skip For Now
            </ButtonWithSpinner>
          )}
          <div
            className={`d-flex ${
              !showSyncNewPairSkipRoute ? "w-100 justify-content-between" : ""
            }`}
          >
            <ButtonWithSpinner
              onClick={handleSyncPairs}
              className={"ml-3 mr-4 dark-gray-button"}
              loading={syncManualStatus === "loading"}
              disabled={syncManualStatus === "loading"}
            >
              Sync All Instead
            </ButtonWithSpinner>

            <ButtonWithSpinner
              onClick={(event) => handleSyncPairs(event, true)}
              className={"bg-yellow"}
              loading={syncNewPairsStatus === "loading"}
              disabled={syncNewPairsStatus === "loading"}
            >
              Yes, Sync New
            </ButtonWithSpinner>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SyncNewPairConfirmationModal;
