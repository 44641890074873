import React from "react";
import PropTypes from "prop-types";
import ToolsTableRow from "./ToolsTableRow";

const ToolsTableBody = (props) => {
  const { data, isLoading, handleItemClicked, tableIdentity } = props;

  return (
    <tbody
      className="user-settings-greetings-table-body"
      style={isLoading ? { opacity: "0.5" } : {}}
    >
      {data.map((elementData, index) => (
        <ToolsTableRow
          data={elementData}
          handleItemClicked={handleItemClicked}
          index={index}
          tableIdentity={tableIdentity}
        />
      ))}
    </tbody>
  );
};

ToolsTableBody.propTypes = {
  onDetailsClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleItemClicked: PropTypes.func.isRequired,
  tableIdentity: PropTypes.string,
};

ToolsTableBody.defaultProps = {
  data: [],
  tableIdentity: "",
};

export default ToolsTableBody;
