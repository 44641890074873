import config from "../../config";
import { postRequest } from "../../utils/request";
import { ACTION_TYPES } from "../actions/types";

export function getLogs(data, requestId) {
  return (dispatch) =>
    postRequest(dispatch, ACTION_TYPES.GET_LOGS, config.Path.GET_LOGS, data, {
      requestDispatchData: { requestId, data },
      successDispatchData: { requestId, data },
      errorDispatchData: { requestId },
    });
}

export function getLogDetails(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_LOGS_DETAILS,
      config.Path.GET_LOG_DETAILS,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function getLogStatistics(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_LOG_STATISTICS,
      config.Path.GET_LOG_STATISTICS,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function searchLogs(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SEARCH_LOGS,
      config.Path.SEARCH_LOGS,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function searchCcbToMc(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SEARCH_STEP_TWO,
      config.Path.SEARCH_CCB_TO_MC,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function searchMcToCcb(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.SEARCH_STEP_TWO,
      config.Path.SEARCH_MC_TO_CCB,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function revertLogs(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.REVERT_LOGS,
      config.Path.REVERT_LOGS,
      data,
      {
        requestDispatchData: { requestId, data },
        successDispatchData: { requestId, data },
        errorDispatchData: { requestId },
      }
    );
}

export function getMcRequiredMergeFields(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      ACTION_TYPES.GET_MC_REQUIRED_MERGE_FIELDS,
      config.Path.GET_MC_REQUIRED_MERGE_FIELDS,
      data,
      {
        requestDispatchData: { data },
        successDispatchData: { data },
        errorDispatchData: {},
      }
    );
}
