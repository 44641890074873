import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dialog, Typography, Slide, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import TableInfinityScroll from "../../Table/TableInfinityScroll/TableInfinityScroll";
import TableHeader from "../../Table/TableHeader/TableHeader";
import ToolsBreadCrumb from "../ToolsBreadCrumb/ToolsBreadCrumb";
import ToolsTableBody from "../ToolsTableBody";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import {
  getIgnoredEmails,
  unignoreEmails,
} from "../../../store/middlewares/toolsMiddleware";
import { formDataWithApiKey } from "../../../helpers";
import Loader from "../../Loader/Loader";
import EmptyMessageBox from "../../EmptyMessageBox/EmptyMessageBox";
import ConfirmationModal from "../../modals/ConfirmationModal/ConfirmationModal";
import { setSnackbarData } from "../../../store/actions/globalAction";
import { clearUnignoredEmailStatus } from "../../../store/actions/toolsActions";
import "../../../styles/components/more/user-settings.scss";
import "../../../styles/components/more/billing.scss";
import "../../../styles/components/payment-form.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cells = [
  {
    text: "First Name",
  },
  {
    text: "Last Name",
  },
  {
    text: "Email",
  },
  {
    text: "CCB Group",
  },
  {
    text: "Action",
  },
];

const breadcrumbs = [
  {
    text: `Ignored Emails`,
    route: `/tools/ignored-emails`,
  },
];

const IgnoredEmails = (props) => {
  const {
    // component props
    history,

    // Redux funcions
    getIgnoredEmails,
    unignoreEmail,
    setSnackbarData,
    clearUnignoredEmailStatus,

    // Redux props
    ignoredEmails,
    ignoredEmailsStatus,
    ignoredEmailsPage,
    ignoredEmailsLoadedAll,
    ignoredEmailsLoadingMoreStatus,
    unignoreEmailStatus,
  } = props;
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [unignoreEmailItem, setUnignoreEmailItem] = useState({});

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setInputs((state) => ({ ...state, [name]: value }));
    },
    [setInputs]
  );

  const toggleModal = useCallback(() => {
    history.goBack();
  }, [history]);

  const appendFiltersInFormdata = useCallback(
    (formData) => {
      formData.append("filter[firstName]", inputs.firstName);
      formData.append("filter[lastName]", inputs.lastName);
      formData.append("filter[email]", inputs.email);
    },
    [inputs.email, inputs.firstName, inputs.lastName]
  );

  useEffect(() => {
    const formData = formDataWithApiKey();
    if (unignoreEmailStatus === "success") {
      closeConfirmationModal();
      showSnackbar(`This contact has been successfully updated.`, "success");
      formData.append("page", Number(ignoredEmailsPage || 1));
      appendFiltersInFormdata(formData);
      getIgnoredEmails(formData);
      clearUnignoredEmailStatus();
    }
  }, [
    appendFiltersInFormdata,
    clearUnignoredEmailStatus,
    closeConfirmationModal,
    getIgnoredEmails,
    ignoredEmailsPage,
    showSnackbar,
    unignoreEmailStatus,
  ]);

  useEffect(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    getIgnoredEmails(formData);
  }, [getIgnoredEmails]);

  const handleUnignoreEmail = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("id", unignoreEmailItem.id);
    formData.append("email", unignoreEmailItem.email);
    unignoreEmail(formData);
  }, [unignoreEmail, unignoreEmailItem.email, unignoreEmailItem.id]);

  const handleSearch = useCallback(() => {
    const formData = formDataWithApiKey();
    formData.append("page", 1);
    appendFiltersInFormdata(formData);
    getIgnoredEmails(formData);
  }, [appendFiltersInFormdata, getIgnoredEmails]);

  const loadMore = useCallback(() => {
    if (
      !ignoredEmailsLoadedAll &&
      ignoredEmailsStatus !== "loading" &&
      ignoredEmailsStatus !== "error" &&
      ignoredEmails
    ) {
      const formData = formDataWithApiKey();
      const pageNum = Number(ignoredEmailsPage) + 1;
      formData.append("page", pageNum);
      appendFiltersInFormdata(formData);
      getIgnoredEmails(formData);
    }
  }, [
    appendFiltersInFormdata,
    getIgnoredEmails,
    ignoredEmails,
    ignoredEmailsLoadedAll,
    ignoredEmailsPage,
    ignoredEmailsStatus,
  ]);

  const handleItemClicked = useCallback((item) => {
    const modalContent = `Are you sure you want to unignore '${item.email}' from syncing to the corresponding CCB group?`;
    setModalContent(modalContent);
    setUnignoreEmailItem(item);
    setShowConfirmationModal(true);
  }, []);

  const loadingMore = ignoredEmailsLoadingMoreStatus === "loading";

  const renderSearchFields = useCallback(() => {
    return (
      <div className="flexer">
        <form className="form flexer">
          <fieldset className="mr-4">
            <label htmlFor="first-name">First Name</label>
            <input
              name="firstName"
              onChange={handleChange}
              value={inputs.firstName}
              type="text"
              id="first-name"
            />
          </fieldset>
          <fieldset className="mr-4">
            <label htmlFor="last-name">Last Name</label>
            <input
              name="lastName"
              onChange={handleChange}
              value={inputs.lastName}
              type="text"
              id="last-name"
            />
          </fieldset>
          <fieldset>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              onChange={handleChange}
              value={inputs.email}
              type="text"
              id="email"
            />
          </fieldset>
        </form>
        <ButtonWithSpinner
          onClick={handleSearch}
          className="bg-yellow mt-3 ml-4"
        >
          Search
        </ButtonWithSpinner>
      </div>
    );
  }, [
    handleChange,
    handleSearch,
    inputs.email,
    inputs.firstName,
    inputs.lastName,
  ]);

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={toggleModal}
        TransitionComponent={Transition}
        classes={{
          root: "logs-list-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent>
          <div>
            <Typography variant="h4">Contacts</Typography>
            <Close className="close-icon mr-2" onClick={toggleModal} />
          </div>

          <div className="flexer-column">
            <ToolsBreadCrumb breadcrumbs={breadcrumbs} />
          </div>
          {renderSearchFields()}
          <div className="flexer-column mt-4">
            <div className="user-settings-greetings">
              {ignoredEmailsStatus === "loading" && !loadingMore ? (
                <div className="d-flex justify-content-center spinner-container">
                  <Loader />
                </div>
              ) : (
                <TableInfinityScroll
                  className="logs-modal-table"
                  loadMore={loadMore}
                  page={1}
                  fetchFunction={() => {}}
                >
                  <table className="table">
                    <TableHeader
                      className="user-settings-greetings-table-header"
                      cells={cells}
                    />
                    <ToolsTableBody
                      data={ignoredEmails}
                      handleItemClicked={handleItemClicked}
                      tableIdentity="ignoredEmail"
                    />
                  </table>
                  {!Boolean(ignoredEmails.length) && (
                    <EmptyMessageBox
                      message="No data found"
                      hasBorder={false}
                    />
                  )}
                </TableInfinityScroll>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          modalHeading={"Confirmation"}
          handleClose={closeConfirmationModal}
          modalContent={modalContent}
          btnLabel={"Confirm"}
          btnClassName={"bg-yellow w-30"}
          onClick={handleUnignoreEmail}
          loading={unignoreEmailStatus === "loading"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userData: store.userReducer.userData,
    ignoredEmails: store.tools.ignoredEmails,
    ignoredEmailsStatus: store.tools.ignoredEmailsStatus,
    ignoredEmailsPage: store.tools.ignoredEmailsPage,
    ignoredEmailsLoadedAll: store.tools.ignoredEmailsLoadedAll,
    ignoredEmailsLoadingMoreStatus: store.tools.ignoredEmailsLoadingMoreStatus,
    unignoreEmailStatus: store.tools.unignoreEmailStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIgnoredEmails: (data) => dispatch(getIgnoredEmails(data)),
    unignoreEmail: (data) => dispatch(unignoreEmails(data)),
    setSnackbarData: (snackbarData) => dispatch(setSnackbarData(snackbarData)),
    clearUnignoredEmailStatus: () => dispatch(clearUnignoredEmailStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IgnoredEmails));
