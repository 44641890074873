import React, { useCallback, useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  ListItem,
  Checkbox,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import ButtonWithSpinner from "../../ButtonWithSpinner/ButtonWIthSpinner";
import LightTooltip from "../../../HelperComponents/LightTooltip";
import useUserStore from "../../../store/reduxStore/userStore";
import { membersSettings } from "../../../pages/Tools/toolsData";
import { deepClone, formDataWithApiKey } from "../../../helpers";
import useToolsStore from "../../../store/reduxStore/toolsStore";
import useGlobalStore from "../../../store/reduxStore/globalStore";
import "../../../styles/components/integrations/remove-sync-pair.scss";
import "../../../styles/components/services/log-list.scss";

const FilterGroupMembershipList = (props) => {
  const { open, handleClose } = props;
  const [list, setList] = useState([]);
  const [simpleError, setSimpleError] = useState("");
  const [simpleMsg, setSimpleMsg] = useState("");

  // Redux store
  const { user } = useUserStore();
  const { tools, saveMemberSettings, clearToolPageState } = useToolsStore();
  const { setSnackbarData } = useGlobalStore();
  const { userData } = user;
  const { isLoading, errorMessage, successMessage } = tools;

  const setListELements = useCallback(() => {
    if (!list.length) {
      const userDataKeys = Object.keys(userData);
      membersSettings.forEach((element) => {
        userDataKeys.forEach((item) => {
          if (element.id === item) {
            element.checked = Number(userData[item]);
          }
        });
      });
      setList(deepClone(membersSettings));
    }
  }, [list, userData]);

  const showSnackbar = useCallback(
    (message, severity) => {
      setSnackbarData({
        snackbarSeverity: severity,
        showSnackbar: true,
        snackbarMessage: message,
      });
    },
    [setSnackbarData]
  );

  useEffect(() => {
    // SAVE LIST
    setListELements();
  }, [setListELements]);

  useEffect(() => {
    if (errorMessage) {
      setSimpleError(errorMessage);
      clearToolPageState();
    } else if (successMessage) {
      showSnackbar(successMessage, "success");
      clearToolPageState();
      handleClose();
    }
  }, [
    clearToolPageState,
    errorMessage,
    showSnackbar,
    successMessage,
    handleClose,
  ]);

  const handleCheckboxClicked = useCallback(
    (listIndex, value) => () => {
      const cloneList = deepClone(list);
      cloneList[listIndex].checked = value;
      setList(cloneList);
      setSimpleMsg("This changes will be applied on the next sync");
      setSimpleError("");
    },
    [list]
  );

  const handleSaveMembersSettings = useCallback(() => {
    const formData = formDataWithApiKey();
    if (list.some((item) => item.checked)) {
      list.forEach((item) => formData.append(item.id, item.checked));
      setSimpleError("");
      saveMemberSettings(formData);
    } else {
      setSimpleError("At least one membership status type must be selected!");
      setSimpleMsg("");
    }
  }, [list, saveMemberSettings]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <div className="p-3">
        <div className="mt-2">
          <Close className="close-icon mb-2" onClick={handleClose} />
          <Typography className="font-weight-bold" variant="subtitle2">
            Include the following membership status types when syncing{" "}
            <span>
              {" "}
              <LightTooltip
                arrow={true}
                placement="top"
                title={`The status is the current relationship the person has to the group. The "Requesting to join the group" participants are not yet members (because they have either not yet accepted the invitation to join the group or were not accepted into the group). We will sync the participants for all group based on the statuses choosen. The default and typical options are to sync just leader/assistant leaders and group members.`}
              >
                <Help className="ml-2" fontSize="small" />
              </LightTooltip>
            </span>
          </Typography>
        </div>
        <DialogContent className="p-0 mt-2">
          <DialogContentText>
            <div>
              {list &&
                Array.isArray(list) &&
                Boolean(list?.length) &&
                list.map((item, listIndex) => (
                  <ListItem>
                    <div className="flexer-start">
                      <Checkbox
                        value={item.title}
                        checked={Number(item.checked) === 1}
                        name={item.title}
                        onChange={handleCheckboxClicked(
                          listIndex,
                          item.checked === 1 ? 0 : 1
                        )}
                        style={{ color: "#2db8e8" }}
                        className="p-0"
                      />
                      <Typography
                        className="text-dark font-weight-bold ml-2"
                        variant="subtitle2"
                      >
                        {item.title}
                      </Typography>
                    </div>
                  </ListItem>
                ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flexer-column p-0">
          {simpleMsg && (
            <Typography className="mb-3" variant="subtitle2">
              {simpleMsg}
            </Typography>
          )}
          {simpleError && (
            <Typography className="mb-3 text-orange-danger" variant="subtitle2">
              {simpleError}
            </Typography>
          )}
          <div className={`d-flex justify-content-center w-100`}>
            <ButtonWithSpinner
              onClick={handleSaveMembersSettings}
              className={"bg-yellow"}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </ButtonWithSpinner>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FilterGroupMembershipList;
